import React, { useRef, useMemo } from 'react';
import { 
  useHistory, 
  useLocation 
} from 'react-router-dom';
import moment from 'moment';
import extractQuery from '../../utils/useQuery';
import Modal from '../organisms/Modal';
import TeeSheetHolesFormTemplate from '../templates/TeeSheetHolesForm';
import QueryGql from '../../constants/QueryGql';
import { client } from '../../App';

const TeeSheetHolesGroupForm = () => {
  const history = useHistory();
  const location = useLocation();
  const queryString = extractQuery(location.search);
  const dateTime = queryString.get('date-time');
  const courseId = queryString.get('course-id');
  const reservationGroupId = queryString.get('reservation-group-id');
  const refTeeSheetHolesFormTemplate = useRef();

  const date = moment(dateTime, 'YYYY-MM-DDTHH:mm:ss.SSS[Z]').format('YYYY-MM-DD');

  const reservations = useMemo(() => {
    return client.readQuery({
      query: QueryGql.GET_RESERVATIONS,
      variables: {
        getReservationsData: {
          date,
          course_id: courseId,
          statuses: [
            'no_show',
            'reserved',
            'checked_in',
            'paid'
          ]
        }
      }
    });
  }, [courseId, dateTime]);

  const initialHoles = useMemo(() => {
    const data = [{
      id: '',
      name: 'Hole #1',
      disabled: true,
    }];
    return data;
  }, []);

  const handleSubmit = async () => {
    const values = refTeeSheetHolesFormTemplate.current.handleSubmit();
    let prepareDataReservation = reservations.getReservations.data;
    
    const create_UUID = () => {
      let dt = new Date().getTime();
      let uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
        let r = (dt + Math.random()*16)%16 | 0;
        dt = Math.floor(dt/16);
        return (c=='x' ? r :(r&0x3|0x8)).toString(16);
      });
      return uuid;
    };
    if (values.length > 0) {
      const teeTimesGroup = prepareDataReservation.filter(x => x.group_id === reservationGroupId).map(x => x.date_tee_times);
      teeTimesGroup.forEach(x => {
        const newReservations = values.map(hole => ({
          date,
          date_tee_times: x,
          reservation_holes: {
            name: hole.name,
            date_time: date,
            __typename: 'ReservationHoleOnly'
          },
          rainchecks: [],
          reservation_customers: [],
          reservation_operators: [],
          rate_cards: null,
          tee_times: null,
          course: null,
          deleted_at: '',
          updated_at: '',
          created_at: '',
          checkin_at: '',
          location_id: '',
          block_tee_time_by: null,
          block_tee_times_note: '',
          is_block_tee_time: '',
          group_id: '',
          is_group: false,
          payment_status: '',
          total_amount: 0,
          reservation_message: '',
          reservation_note: '',
          name: '',
          number: '',
          prefix: '',
          id: create_UUID(),
          __typename: 'Reservation',
        }));
        prepareDataReservation = [
          ...prepareDataReservation,
          ...newReservations,
        ];
      });
    }
    client.writeQuery({
      query: QueryGql.GET_RESERVATIONS,
      variables: {
        getReservationsData: {
          date,
          course_id: courseId,
          statuses: [
            'no_show',
            'reserved',
            'checked_in',
            'paid'
          ]
        }
      },
      data: {
        getReservations: {
          ...reservations.getReservations,
          data: prepareDataReservation
        }
      },
    });
    history.goBack();
  };

  return (
    <Modal
      title="Add new holes"
      hiddenLeftFooterButton
      rightFooterButton={{
        label: 'Add holes',
        onClick: handleSubmit
      }}
      width={540}
    >
      <TeeSheetHolesFormTemplate initialHoles={initialHoles} ref={refTeeSheetHolesFormTemplate} />
    </Modal>
  );
};

export default TeeSheetHolesGroupForm;