import React, { useMemo } from 'react';
import { Space, Tabs } from 'antd';
import RevenueLineChart from '../organisms/RevenueLineChart';
import RevenueCards from '../organisms/RevenueCards';
import PieChartList from '../organisms/PieChartList';
import RevenueDetailed from '../organisms/RevenueDetailed';
import generateColor, { COLORS } from '../../utils/generateColor';
import formatter from '../../utils/formatter';
import moment from 'moment';

const { TabPane } = Tabs;

export default function RevenueReportTemplate({ course, isDaily, dataReport, isPrint }) {
  const summary = dataReport.summary || {};

  const lineChartData = useMemo(() => {
    const items = [];

    dataReport.chart?.revenue_by_datetime?.map(item => {
      items.push({
        name: moment(item.date, 'YYYY-MM-DD').format('L'),
        value: item.revenue,
      });
    });
    return items;
  }, [dataReport]);

  const lineChartDataDetail = useMemo(() => {
    const items = [];

    dataReport.chart?.revenue_by_datetime?.[0]?.details.map(item => {
      items.push({
        name:
          moment(dataReport.chart?.revenue_by_datetime?.[0].date, 'YYYY-MM-DD').format('L') +
          ' - ' +
          moment(item.time, 'HH').format('hh A'),
        value: item.revenue,
      });
    });
    return items.sort((a, b) => {
      return a.time - b.time;
    });
  }, [dataReport]);

  const pieChartData = useMemo(() => {
    const getPercentage = (value, total) => {
      if (!value) {
        return 0;
      }
      return formatter.roundToTwoDigitsAfterComma((value / total) * 100);
    };
    const chart = dataReport.chart || {};

    const revenueByCourse = [];
    const totalRevenueByCourse = chart?.revenue_by_courses?.total;

    chart?.revenue_by_courses?.courses?.map((item, index) => {
      revenueByCourse.push({
        name: `${item.course_name} (${item.holes})`,
        color: COLORS[index] || generateColor(`${item.course_name} (${item.holes})`),
        detail: `${formatter.currency(item.revenue)} (${getPercentage(
          item.revenue,
          totalRevenueByCourse,
        )}%)`,
        value: item.revenue,
      });
    });

    const revenueByAddons = [];
    const totalRevenueByAddons = chart?.revenue_by_addons?.total;

    chart?.revenue_by_addons?.addons?.map((item, index) => {
      revenueByAddons.push({
        name: item.add_ons_name,
        color: COLORS[index] || generateColor(item.add_ons_name),
        detail: `${formatter.currency(item.revenue)} (${getPercentage(
          item.revenue,
          totalRevenueByAddons,
        )}%)`,
        value: item.revenue,
      });
    });

    const revenueByCustomer = [];
    const totalRevenueByCustomer = chart?.revenue_by_customer_memberships?.total;

    chart?.revenue_by_customer_memberships?.customer_memberships?.map((item, index) => {
      revenueByCustomer.push({
        name: item.name,
        color: COLORS[index] || generateColor(item.name),
        detail: `${formatter.currency(item.revenue)} (${getPercentage(
          item.revenue,
          totalRevenueByCustomer,
        )}%)`,
        value: item.revenue,
      });
    });

    const revenueByPaymentMethods = [];
    const totalRevenueByPaymentMethods = chart?.revenue_by_payment_methods?.total;

    chart?.revenue_by_payment_methods?.payment_methods?.map((item, index) => {
      revenueByPaymentMethods.push({
        name: item.payment_method,
        color: COLORS[index] || generateColor(item.payment_method),
        detail: `${formatter.currency(item.revenue)} (${getPercentage(
          item.revenue,
          totalRevenueByPaymentMethods,
        )}%)`,
        value: item.revenue,
      });
    });
    const revenueBreakdown = [];
    const totalRevenueBreakdown = summary?.total_green_fee + summary?.total_cancellation_fee + summary?.total_add_ons;

    [
      {
        name: 'green_fee',
        total: summary?.total_green_fee
      }, {
        name: 'cancellation_fee',
        total: summary?.total_cancellation_fee
      }, {
        name: 'add_ons',
        total: summary?.total_add_ons
      },
    ]?.map((item, index) => {
      revenueBreakdown.push({
        name: item.name,
        color: COLORS[index] || generateColor(item.name),
        detail: `${formatter.currency(item.total)} (${getPercentage(
          item.total,
          totalRevenueBreakdown,
        )}%)`,
        value: item.total,
      });
    });

    const revenuePaidVsUnpaid = [];
    const totalRevenuePaidVsUnpaid = chart?.revenue_by_paid_unpaid?.total;

    chart?.revenue_by_paid_unpaid?.paid_unpaid?.map((item, index) => {
      revenuePaidVsUnpaid.push({
        name: item.status,
        color: COLORS[index] || generateColor(item.payment_method),
        detail: `${formatter.currency(item.total_revenue)} (${getPercentage(
          item.total_revenue,
          totalRevenueByPaymentMethods,
        )}%)`,
        value: item.total_revenue,
      });
    });
    return [
      {
        id: `${isPrint ? 'print-' : 'item-'}` + 'golf-course',
        title: 'Revenue by golf courses',
        total: formatter.currency(totalRevenueByCourse),
        items: revenueByCourse,
      },
      {
        id: `${isPrint ? 'print-' : 'item-'}` + 'add-ons',
        title: 'Revenue by add-ons',
        total: formatter.currency(totalRevenueByAddons),
        items: revenueByAddons,
      },
      {
        id: `${isPrint ? 'print-' : 'item-'}` + 'customer-memberships',
        title: 'Revenue by customer type & memberships',
        total: formatter.currency(totalRevenueByCustomer),
        items: revenueByCustomer,
      },
      {
        id: `${isPrint ? 'print-' : 'item-'}` + 'payment-methods',
        title: 'Revenue by payment methods',
        total: formatter.currency(totalRevenueByPaymentMethods),
        items: revenueByPaymentMethods,
      },
      {
        id: `${isPrint ? 'print-' : 'item-'}` + 'revenue-breakdown',
        title: 'Revenue breakdown (green fees, cancelled, add ons)',
        total: formatter.currency(totalRevenueBreakdown),
        items: revenueBreakdown,
      },
      {
        id: `${isPrint ? 'print-' : 'item-'}` + 'paid-unpaid',
        title: 'Paid vs unpaid revenue',
        total: formatter.currency(totalRevenuePaidVsUnpaid),
        items: revenuePaidVsUnpaid,
      },
    ];
  }, [dataReport]);

  const dataRevenueByCustomer = useMemo(() => {
    const items = [];
    const data = dataReport?.details?.revenue_by_customer_memberships || {};
    let average = 0;
    data.customer_memberships?.forEach(item => {
      average += item.average;
      items.push({
        name: item.name,
        quantity: item.qty,
        average: formatter.currency(item.average),
        total: formatter.currency(item.revenue),
      });
    });

    return {
      dataSource: items,
      average: formatter.currency(average),
      quantity: data.total_qty,
      total: formatter.currency(data.total_revenue),
    };
  }, [dataReport]);

  const dataRevenueByPayment = useMemo(() => {
    const items = [];
    const data = dataReport?.details?.revenue_by_payment_methods || {};

    let average = 0;
    data?.payment_methods?.forEach(item => {
      average += item.average;
      items.push({
        name: item.payment_method,
        quantity: item.qty,
        average: formatter.currency(item.average),
        total: formatter.currency(item.revenue),
      });
    });

    return {
      dataSource: items,
      average: formatter.currency(average),
      quantity: data.total_qty,
      total: formatter.currency(data.total_revenue),
    };
  }, [dataReport]);

  const dataRevenueByGreenFees = useMemo(() => {
    const items = [];
    const data = dataReport?.details?.revenue_by_green_fee_addons?.green_fee || [];
    const dataCancellation = dataReport?.details?.cancellation_fee || {};

    let average = 0;
    let quantity = 0;
    let total = 0;
    data?.forEach(item => {
      items.push({
        name: item.name,
        quantity: item.total_qty,
        average: formatter.currency(item.subtotal_revenue),
        total: formatter.currency(item.total_revenue),
        children: item.memberships.map(member => ({
          name: member.name,
          average: formatter.currency(member.price),
          quantity: member.qty,
          total: formatter.currency(member.total),
        })),
      });

      average += item.subtotal_revenue;
      quantity += item.total_qty;
      total += item.total_revenue;
    });

    items.push({
      name: 'Total green fee revenue',
      average: formatter.currency(average),
      total: formatter.currency(total),
      quantity: quantity,
      isTotal: true,
    });

    return {
      average,
      quantity,
      total,
      dataSource: items,
      extra: {
        average: formatter.currency(dataCancellation.subtotal_cancellation_fee),
        total: formatter.currency(dataCancellation.total_cancellation_fee),
        quantity: dataCancellation.total_qty,
      },
    };
  }, [dataReport]);

  const dataRevenueByAddOns = useMemo(() => {
    const items = [];

    const data = dataReport?.details?.revenue_by_green_fee_addons?.add_ons || {};
    const dataCancellation = dataReport?.details?.cancellation_fee || {};

    let average = 0;
    data?.addons?.forEach(item => {
      average += item.average;
      items.push({
        name: item.add_ons_name,
        quantity: item.qty,
        average: formatter.currency(item.average),
        total: formatter.currency(item.revenue),
      });
    });

    items.push({
      name: 'Total add-ons revenue',
      average: formatter.currency(average),
      total: formatter.currency(data.total_revenue),
      quantity: data.total_qty,
      isTotal: true,
    });

    if (isPrint) {
      items.push({ name: '', average: '', total: '', quantity: '', isTotal: true });

      items.push({
        name: 'Cancelaton free',
        average: formatter.currency(dataCancellation.subtotal_cancellation_fee),
        total: formatter.currency(dataCancellation.total_cancellation_fee),
        quantity: dataCancellation.total_qty,
        isTotal: true,
      });
    }

    return {
      average,
      quantity: data.total_qty,
      total: data.total_revenue,
      dataSource: items,
    };
  }, [dataReport]);
  return (
    <Space size={32} direction='vertical'>
      <RevenueCards
        isPrint={isPrint}
        totalGreenFee={summary.total_green_fee}
        totalAddOns={summary.total_add_ons}
        totalRevenue={summary.total_revenue}
        totalCancellationFee={summary.total_cancellation_fee}
        averageGreenFee={summary.average_green_fee}
        averageAddOns={summary.average_add_ons}
        averageRevenue={summary.average_revenue}
      />
      <RevenueLineChart
        course={course?.label}
        data={isDaily ? lineChartDataDetail : lineChartData}
        isPrint={isPrint}
      />
      {isPrint ? (
        <div id='print-revenue'>
          <PieChartList isPrint data={pieChartData} />
          <RevenueDetailed
            isPrint
            dataRevenueByCustomer={dataRevenueByCustomer}
            dataRevenueByPayment={dataRevenueByPayment}
            dataRevenueByGreenFees={dataRevenueByGreenFees}
            dataRevenueByAddOns={dataRevenueByAddOns}
          />
        </div>
      ) : (
        <Tabs
          defaultActiveKey='1'
          tabBarStyle={{
            fontWeight: 600,
            color: '#8F8F8F',
          }}>
          <TabPane tab='Summary' key='1'>
            <PieChartList data={pieChartData} />
          </TabPane>
          <TabPane tab='Detailed' key='2'>
            <RevenueDetailed
              dataRevenueByCustomer={dataRevenueByCustomer}
              dataRevenueByPayment={dataRevenueByPayment}
              dataRevenueByGreenFees={dataRevenueByGreenFees}
              dataRevenueByAddOns={dataRevenueByAddOns}
            />
          </TabPane>
        </Tabs>
      )}
    </Space>
  );
}
