import SvgCalendar from '../components/atoms/SvgCalendar';
import SvgCustomer from '../components/atoms/SvgCustomer';
import SvgHome from '../components/atoms/SvgHome';
// import SvgPromotions from '../components/atoms/SvgPromotions';
import SvgRateCards from '../components/atoms/SvgRateCards';
import SvgReports from '../components/atoms/SvgReports';
import SvgSettings from '../components/atoms/SvgSettings';
import SvgTeeSheet from '../components/atoms/SvgTeeSheet';
import SvgTeeTimes from '../components/atoms/SvgTeeTimes';
import Pathname from './Pathname';

const drawerMenu = [
  {
    label: 'Dashboard',
    icon: SvgHome,
    pathname: Pathname.HOME,
    isParent: false,
  },
  {
    label: 'Tee sheet',
    icon: SvgTeeSheet,
    pathname: Pathname.TEE_SHEET,
    isParent: false,
  },
  {
    label: 'Calendar',
    icon: SvgCalendar,
    pathname: Pathname.CALENDAR,
    isParent: false,
  },
  {
    label: 'Rate Cards',
    icon: SvgRateCards,
    pathname: Pathname.RATE_CARDS,
    isParent: false,
  },
  {
    label: 'Tee times',
    icon: SvgTeeTimes,
    pathname: Pathname.TEE_TIMES,
    isParent: false,
  },
  {
    label: 'Customers',
    icon: SvgCustomer,
    pathname: Pathname.CUSTOMERS,
    isParent: true,
  },
  {
    label: 'Customer list',
    icon: null,
    pathname: Pathname.CUSTOMER_LIST,
    children: Pathname.CUSTOMERS,
    isParent: false,
  },
  {
    label: 'Types & memberships',
    icon: null,
    pathname: Pathname.TYPES_MEMBERSHIP,
    children: Pathname.CUSTOMERS,
    isParent: false,
  },
  // {
  //   label: 'Feedbacks',
  //   icon: null,
  //   pathname: Pathname.FEEDBACKS,
  //   children: Pathname.CUSTOMERS,
  //   isParent: false,
  // },
  // {
  //   label: 'Promotions',
  //   icon: SvgPromotions,
  //   pathname: Pathname.PROMOTIONS,
  //   isParent: false,
  // },
  {
    label: 'Reports',
    icon: SvgReports,
    pathname: Pathname.REPORTS,
    isParent: true,
  },
  {
    label: 'Customer check-ins',
    icon: null,
    pathname: Pathname.CUSTOMER_CHECK_INS,
    children: Pathname.REPORTS,
    isParent: false,
  },
  {
    label: 'Reservations report',
    icon: null,
    pathname: Pathname.RESERVATIONS_REPORT,
    children: Pathname.REPORTS,
    isParent: false,
  },
  {
    label: 'Revenue report',
    icon: null,
    pathname: Pathname.REVENUE_REPORT,
    children: Pathname.REPORTS,
    isParent: false,
  },
  // {
  //   label: 'Promotions usage',
  //   icon: null,
  //   pathname: Pathname.PROMOTIONS_USAGE,
  //   children: Pathname.REPORTS,
  //   isParent: false,
  // },
  {
    label: 'Raincheck list',
    icon: null,
    pathname: Pathname.RAINCHECK_LIST,
    children: Pathname.REPORTS,
    isParent: false,
  },
  {
    label: 'Cancellation report',
    icon: null,
    pathname: Pathname.CANCELATION_REPORT,
    children: Pathname.REPORTS,
    isParent: false,
  },
  {
    label: 'Billing statements',
    icon: null,
    pathname: Pathname.BILLING_STATEMENTS,
    children: Pathname.REPORTS,
    isParent: false,
  },
  {
    label: 'Settings',
    icon: SvgSettings,
    pathname: Pathname.SETTINGS,
    isParent: true,
  },
  {
    label: 'Golf course settings',
    icon: null,
    pathname: Pathname.GOLF_COURSE_SETTINGS,
    children: Pathname.SETTINGS,
    isParent: false,
  },
  {
    label: 'Add-ons & inclusions',
    icon: null,
    pathname: Pathname.ADD_ONS_INCLUSIONS,
    children: Pathname.SETTINGS,
    isParent: false,
  },
  {
    label: 'Raincheck settings',
    icon: null,
    pathname: Pathname.RAINCHECK_SETTINGS,
    children: Pathname.SETTINGS,
    isParent: false,
  },
  {
    label: 'Role access',
    icon: null,
    pathname: Pathname.ROLE_ACCESS,
    children: Pathname.SETTINGS,
    isParent: false,
  },
  {
    label: 'Course profile',
    icon: null,
    pathname: Pathname.COURSE_PROFILE,
    children: Pathname.SETTINGS,
    isParent: false,
  },
];

export default drawerMenu;
