import { Checkbox, Divider } from 'antd';
import React, { useCallback, useMemo } from 'react';
import Card from '../atoms/Card';
import './CustomerFilterGender.less';

const CustomerFilterGender = ({ valueAll, setValueAll, valueSpecific, setValueSpecific }) => {
  const optionsSpecificGender = useMemo(
    () => [
      { label: 'Male', value: 'm' },
      { label: 'Female', value: 'f' },
      { label: 'Other', value: 'o' },
    ],
    [],
  );

  // const [valueSpecific, setValueSpecific] = useState(
  //   optionsSpecificGender.map(option => option.value),
  // );

  useMemo(() => {
    setValueSpecific(optionsSpecificGender.map(option => option.value));
  }, [optionsSpecificGender]);

  const handleChangeAll = useCallback(e => {
    setValueAll(e.target.checked);
    if (e.target.checked) {
      setValueSpecific(optionsSpecificGender.map(option => option.value));
    } else {
      setValueSpecific([]);
    }
  });

  const handleChangeSpecific = useCallback(e => {
    setValueSpecific(e);
    if (e.length === optionsSpecificGender.length) {
      setValueAll(true);
    } else {
      setValueAll(false);
    }
  });

  return (
    <div id='customer-filter-gender'>
      <Card>
        <Checkbox onChange={handleChangeAll} checked={valueAll}>
          All genders
        </Checkbox>
        <Divider />
        <Checkbox.Group
          options={optionsSpecificGender}
          value={valueSpecific}
          onChange={handleChangeSpecific}
          className='checkbox-group'
        />
      </Card>
    </div>
  );
};

export default CustomerFilterGender;
