import React from 'react';
import PropTypes from 'prop-types';
import Text from 'antd/lib/typography/Text';
import './ItemDetailText.less';

const ItemDetailText = (props) => {
  const { label, value } = props;
  const renderValue = () => {
    if (value) {
      if (typeof value === 'string') {
        return <Text className="value">{value}</Text>;
      } 
      return value;
    }
    return null;
  };
  return (
    <div id="item-detail-text">
      <Text className="label">{label}</Text>
      <div className="container-value">
        {renderValue()}
      </div>
    </div>
  );
};

export default ItemDetailText;

ItemDetailText.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
};