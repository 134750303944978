function hashCode(str) { // java String#hashCode
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  return hash;
}

function intToRGB(i){
  const c = (i & 0x00FFFFFF)
    .toString(16)
    .toUpperCase();

  return '00000'.substring(0, 6 - c.length) + c;
}

export default function generateColor(value) {

  return `#${intToRGB(hashCode(value))}`;
}

export const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];