import React from 'react';

function SvgUpArrow() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        fill="currentColor"
        d="M10.417 7.167l4.666 4.75a.806.806 0 010 1.166.806.806 0 01-1.166 0L9.75 9l-4.167 4.083a.806.806 0 01-1.166 0 .757.757 0 01-.25-.583c0-.25.083-.417.25-.584l4.666-4.75c.417-.333.917-.333 1.334 0-.084 0-.084 0 0 0z"
      ></path>
    </svg>
  );
}

export default SvgUpArrow;
