import { Col, Row, Space } from 'antd';
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import MultipleSelect from '../../molecules/MultipleSelect';
import { useQuery } from '@apollo/client';
import QueryGql from '../../../constants/QueryGql';
import BillingStatementsTemplate from './BillingList';

const BillingData = () => {
  const history = useHistory();

  const [statuses, setStatuses] = useState(['all']);

  const [paymentStatus] = useState([
    { key: 'all', value: 'all', label: 'All Status' },
    { key: 'Settled', value: 'settled', label: 'Settled' },
    { key: 'Unsettlet', value: 'unsettlet', label: 'Unsettlet' },
    { key: 'Processing', value: 'processing', label: 'Processing' },
  ]);

  const {
    loading: laodingBillingDataSummary,
    data: dataSearchBillingStatement,
    refetch: refetchSummary,
  } = useQuery(QueryGql.SEARCH_BILLING_STATEMENT, {
    variables: {
      data: {
        status: statuses.includes('all') ? null : statuses,
      },
    },
  });
  useEffect(() => {
    if (history.location.search.includes('refetch')) {
      refetchSummary();
    }
  }, [history.location.key]);

  const dataReport = dataSearchBillingStatement?.searchBillingStatement?.data || [];

  const isLoading = laodingBillingDataSummary;

  const handleChangeStatuses = values => {
    setStatuses(values);
  };

  return (
    <Space style={{ marginTop: 32, paddingBottom: 32 }} direction='vertical' size={0}>
      <Row justify='space-between'>
        <Col>
          <Space>
            <MultipleSelect
              items={paymentStatus}
              value={statuses.includes('all') ? 'All Status' : statuses.join(', ')}
              values={statuses}
              handleChange={handleChangeStatuses}
            />
          </Space>
        </Col>
      </Row>
      <BillingStatementsTemplate dataReport={dataReport} isLoading={isLoading} />
    </Space>
  );
};

export default BillingData;