import { Checkbox, Divider } from 'antd';
import React, { useCallback, useMemo, useState } from 'react';
import Card from '../atoms/Card';
import './CustomerFilterCreditCard.less';

const CustomerFilterCreditCard = () => {

  const optionsSpecificCreditCard = useMemo(() => [
    { label: 'Owns credit card', value: 'owns_credit_card' },
    { label: 'Does not own credit card', value: 'does_not_own_credit_card' },
  ],[]);

  const [valueAll, setValueAll] = useState(true);
  const [valueSpecific, setValueSpecific] = useState(optionsSpecificCreditCard.map(option => option.value));

  const handleChangeAll = useCallback(e => {
    setValueAll(e.target.checked);
    if (e.target.checked) {
      setValueSpecific(optionsSpecificCreditCard.map(option => option.value));
    } else {
      setValueSpecific([]);
    }
  });

  const handleChangeSpecific = useCallback(e => {
    setValueSpecific(e);
    if (e.length === optionsSpecificCreditCard.length) {
      setValueAll(true);
    } else {
      setValueAll(false);
    }
  });

  return (
    <div id="customer-filter-credit-card">
      <Card>
        <Checkbox onChange={handleChangeAll} checked={valueAll} >All credit card ownership</Checkbox>
        <Divider />
        <Checkbox.Group
          options={optionsSpecificCreditCard}
          value={valueSpecific}
          onChange={handleChangeSpecific}
          className="checkbox-group"
        />
      </Card>
    </div>
  );
};

export default CustomerFilterCreditCard;