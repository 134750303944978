import Icon from '@ant-design/icons';
import { useQuery } from '@apollo/client';
import { Menu } from 'antd';
import React, { useCallback, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Pathname from '../../constants/Pathname';
import QueryGql from '../../constants/QueryGql';
import SvgDelete from '../atoms/SvgDelete';
import SvgDuplicate from '../atoms/SvgDuplicate';
import SvgEdit from '../atoms/SvgEdit';
import SvgIllustration from '../atoms/SvgIllustration';
import AppWrapper from '../templates/AppWrapper';
import CardList from '../templates/CardList';
import PageEmpty from '../templates/PageEmpty';

const RateCards = () => {
  const history = useHistory();
  const [searchRateCardSearch, setSearchRateCardSearch] = useState('');

  const variablesSearchRateCard = useMemo(() => {
    return {
      searchRateCardSearch
    };
  }, [searchRateCardSearch]);

  const { loading: loadingSearchRateCard, data: dataSearchRateCard, error } = useQuery(
    QueryGql.SEARCH_RATE_CARD,
    {
      variables: variablesSearchRateCard,
      fetchPolicy: 'cache-and-network'
    }
  );

  const data = useMemo(() => dataSearchRateCard?.searchRateCard.data.map(item => ({
    ...item,
    key: item.id,
    title: item.name
  })), [dataSearchRateCard]);

  const contextMenu = useCallback((item) => {
    return (
      <Menu>
        <Menu.Item
          key="0"
          onClick={() => history.push(
            Pathname.RATE_CARDS+'/'+
          Pathname.RATE_CARDS_FORM+
          `?act=edit&id=${item.id}`,
            {
              background: true
            }
          )}
        >
          <Icon component={SvgEdit} style={{ fontSize: 14, color: '#000D23'}} /> Edit rate card
        </Menu.Item>
        <Menu.Item
          key="1"
          onClick={() => history.push(
            Pathname.RATE_CARDS+'/'+
          Pathname.RATE_CARDS_FORM+
          `?act=duplicate&id=${item.id}`,
            {
              background: true
            }
          )}
        >
          <Icon component={SvgDuplicate} style={{ fontSize: 14, color: '#000D23'}} /> Duplicate rate card
        </Menu.Item>
        <Menu.Item
          key="2"
          className="second-menu-edit"
          onClick={() => history.push(
            Pathname.RATE_CARDS+'/'+
          Pathname.RATE_CARDS_DELETE+
          `?id=${item.id}&name=${item.title}`,
            {
              background: true
            }
          )}
        >
          <Icon component={SvgDelete} style={{ fontSize: 14}} /> Delete rate card
        </Menu.Item>
      </Menu>
    );},[]);

  return (
    <AppWrapper>
      <CardList
        data={data}
        contextMenuItem={contextMenu}
        loading={loadingSearchRateCard}
        onClickItem={() => {}}
        buttonAdd={{
          label: 'Add new rate card',
          onClick:() => history.push(
            Pathname.RATE_CARDS+'/'+
              Pathname.RATE_CARDS_FORM+
              '?act=add',
            {
              background: true
            }
          )
        }}
        searchInput={{
          placeholder: 'Search rate cards',
          onChange: (e) => setSearchRateCardSearch(e.target.value)
        }}
      />
      {(dataSearchRateCard?.length === 0 && !loadingSearchRateCard || (error?.message))
        ? (
          <PageEmpty
            title="Create rate cards for your golf courses"
            desc="Create a pricing scheme or rules to be implemented on your tee times. 
            You can create them based on specific time range and customer types"
            buttonLabel="Add new rate card"
            illustration={SvgIllustration}
            buttonOnClick={() => history.push(
              Pathname.RATE_CARDS_FORM+'?act=add',
              {
                background: true
              }
            )}
          />
        )
        : null}
    </AppWrapper>
  );
};

export default RateCards;