import React from 'react';

export default function SvgLogout() {
  return (
    <svg width='14' height='11' viewBox='0 0 14 11' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M10.3333 6.33333L2.60668 6.33333L4.14001 7.86C4.20217 7.92216 4.25148 7.99595 4.28512 8.07717C4.31876 8.15838 4.33607 8.24543 4.33607 8.33333C4.33607 8.42124 4.31876 8.50829 4.28512 8.5895C4.25148 8.67071 4.20217 8.74451 4.14001 8.80667C4.07785 8.86883 4.00406 8.91813 3.92285 8.95177C3.84163 8.98541 3.75459 9.00273 3.66668 9.00273C3.57877 9.00273 3.49173 8.98541 3.41051 8.95177C3.3293 8.91813 3.2555 8.86883 3.19335 8.80667L0.526679 6.14C0.465985 6.0766 0.418409 6.00183 0.386679 5.92C0.32 5.75769 0.32 5.57564 0.386679 5.41333C0.418409 5.3315 0.465985 5.25674 0.526679 5.19333L3.19335 2.52667C3.25532 2.46418 3.32906 2.41459 3.41029 2.38074C3.49153 2.34689 3.57867 2.32947 3.66668 2.32947C3.75469 2.32947 3.84182 2.34689 3.92306 2.38074C4.0043 2.41459 4.07804 2.46418 4.14001 2.52667C4.2025 2.58864 4.25209 2.66238 4.28594 2.74362C4.31979 2.82486 4.33721 2.91199 4.33721 3C4.33721 3.08801 4.31979 3.17515 4.28594 3.25638C4.25209 3.33762 4.2025 3.41136 4.14001 3.47333L2.60668 5L10.3333 5C10.5102 5 10.6797 5.07024 10.8048 5.19526C10.9298 5.32029 11 5.48986 11 5.66667C11 5.84348 10.9298 6.01305 10.8048 6.13807C10.6797 6.2631 10.5102 6.33333 10.3333 6.33333ZM13 11C12.8232 11 12.6536 10.9298 12.5286 10.8047C12.4036 10.6797 12.3333 10.5101 12.3333 10.3333L12.3333 1C12.3333 0.82319 12.4036 0.65362 12.5286 0.528596C12.6536 0.403572 12.8232 0.333334 13 0.333334C13.1768 0.333334 13.3464 0.403572 13.4714 0.528596C13.5964 0.65362 13.6667 0.82319 13.6667 1L13.6667 10.3333C13.6667 10.5101 13.5964 10.6797 13.4714 10.8047C13.3464 10.9298 13.1768 11 13 11Z'
        fill='#000D23'
      />
    </svg>
  );
}
