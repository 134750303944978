import Icon, { CloseCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Col, DatePicker, Row, Tabs, TimePicker } from 'antd';
import Text from 'antd/lib/typography/Text';
import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import moment from 'moment';
import numeral from 'numeral';
import * as Yup from 'yup';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import Button from '../atoms/Button';
import Card from '../atoms/Card';
import TotalCard from '../molecules/TotalCard';
import HorizontalSelectOptions from '../molecules/HorizontalSelectOptions';
import HorizontalTextInput from '../molecules/HorizontalTextInput';
import HorizontalTextInputArea from '../molecules/HorizontalTextInputArea';
import TeeSheetReservationCustomerForm from '../molecules/TeeSheetReservationCustomerForm';
import './TeeSheetReservationForm.less';
import SvgTag from '../atoms/SvgTag';
import { useQuery } from '@apollo/client';
import QueryGql from '../../constants/QueryGql';

const TeeSheetReservationGroupFormValidations = Yup.object({
  date: Yup.mixed().required().label('Date'),
  name: Yup.string().required().label('Reservation name'),
  reservations: Yup.array().of(Yup.object().shape({
    date: Yup.mixed().required().label('Date'),
    date_tee_time: Yup.mixed().required().label('Tee time'),
    rate_card_id: Yup.mixed().required().label('Active rate card'),
    customers: Yup.array().of(Yup.object().shape({
      customer_id: Yup.string(),
      name: Yup.string().required().label('Customer name'),
      membership_id: Yup.mixed().required().label('Customer type'),
      phone_number: Yup.string().when('name', {
        is: value => {
          return value !== 'guest';
        },
        then: Yup.string().required(
          'Required'
        ),
        otherwise: Yup.string()
      }),
      voucher_code: Yup.string().when('voucher_code_error', {
        is: value => {
          return value;
        },
        then: Yup.string().min(1000),
        otherwise: Yup.string()
      }),
    }))
  }))
});

const { TabPane } = Tabs;

const TeeSheetReservationGroupForm = (props, ref) => {
  const { date, initialTeeTimes, rateCards, initialValues, teeTimes } = props;
  const refFormik = useRef();
  const [activeTabKey, setActiveTabKey] = useState('0');

  const { data: dataFormCustomerReservation } = useQuery(QueryGql.FORM_CUSTOMER_RESERVATION);
  const { data: dataLowerPriceAfterRaincheck } = useQuery(
    QueryGql.GET_LOWER_PRICE_AFTER_RAINCHECK,
    { fetchPolicy: 'cache-and-network' },
  );

  const maximumPlayers = dataFormCustomerReservation?.getMaximumPlayers?.data.value ? Number(dataFormCustomerReservation?.getMaximumPlayers?.data.value) : 0;

  const optionsTeeTime = useMemo(() => {
    return teeTimes.map(x => x.value) || [];
  }, [teeTimes]);

  useEffect(() => {
    // initial for create
    if (initialTeeTimes) {
      refFormik.current.setFieldValue('reservations', initialTeeTimes.map(x => {
        const time = moment(x.dateTime, 'YYYY-MM-DDTHH:mm:ss.SSS[Z]').format('HH:mm:ss');
        const idTeeTime = x.optionsTeeTime.find(x => x.value === time)?.id;
        return {
          date,
          date_tee_time: time,
          course_id: x.courseId,
          tee_time_id: idTeeTime,
          rate_card_id: x.rateCardId,
          hole_name: x.startingHoleName,
          customers: []
        };
      }));
    }
  }, [initialTeeTimes]);

  useImperativeHandle(ref, () => ({
    handleSubmit: async () => {
      const validateForm = await refFormik.current.validateForm();
      refFormik.current.handleSubmit();
      if (Object.keys(validateForm).length === 0) {
        return refFormik.current.values;  
      }
      return null;
    }
  }));

  const getTotal = useCallback(() => {
    let total = 0;
    let isRaincheckCode = false;
    const reservations = refFormik.current?.values?.reservations;
    if ( reservations && 
      dataFormCustomerReservation?.getAllMembership?.data && 
      dataFormCustomerReservation?.getAllAddOns?.data) {
      reservations.forEach((reservation, index) => {
        const dateTeeTime = reservation.date_tee_time;
        reservation.customers.forEach(customer => {
          let membershipTypePrice = 0;
          const membership = memberships(index).find(x => x.id === customer.membership_id);
          membership?.time_based_rates?.forEach(x => {
            if (moment(dateTeeTime, 'HH:mm:ss').isBetween(moment(x.start_time, 'HH:mm:ss'), moment(x.end_time, 'HH:mm:ss')) ||
              moment(dateTeeTime, 'HH:mm:ss').isSame(moment(x.start_time, 'HH:mm:ss')) || 
              moment(dateTeeTime, 'HH:mm:ss').isSame(moment(x.end_time, 'HH:mm:ss'))) {
              membershipTypePrice = x.price;
            }
          });
          let addOnsPrice = 0;
          dataFormCustomerReservation.getAllAddOns.data.forEach(x => {
            customer.add_ons_id.forEach(y => {
              if (x.id === y) {
                addOnsPrice = addOnsPrice + x.price;
              }
            });
          });
          total = total + (membershipTypePrice + addOnsPrice);
          if (customer?.discount) {
            total -= customer.discount;
          }
          if (customer.voucher_code_data?.is_raincheck) {
            isRaincheckCode = true;
          }
        });
      });
    }

    if (isRaincheckCode) {
      const lowerPriceAfterRaincheck = dataLowerPriceAfterRaincheck?.getLowestPriceAfterRainCheck
        ?.data?.value
        ? Number(dataLowerPriceAfterRaincheck?.getLowestPriceAfterRainCheck?.data?.value)
        : 0;
      if (lowerPriceAfterRaincheck && total < lowerPriceAfterRaincheck) {
        return lowerPriceAfterRaincheck;
      }
    }
    return total;
  }, [refFormik.current?.values?.reseervations, dataFormCustomerReservation, rateCards, dataLowerPriceAfterRaincheck]);

  const optionsRateCard = useMemo(() => rateCards.map(item => ({
    key: item.value,
    value: item.value,
    label: (
      <div>
        <Icon component={SvgTag} style={{ color: item.color }} />
        <Text className="label-option-tee-time">{item.label}</Text>
      </div>
    )
  }))
  , [rateCards]);

  const memberships = useCallback((index) => {
    const reservation = refFormik.current?.values?.reservations?.[index];
    if (rateCards?.length > 0) {
      return rateCards.find(x => x.value === reservation?.rate_card_id)
        ?.courses.find(x => x.id === reservation?.course_id)?.memberships || [];
    }
    return [];
  }, [refFormik.current?.values, rateCards?.length, initialTeeTimes]);

  const handleDeleteCustomer = useCallback((indexReservation, indexCustomer) => {
    const customers = refFormik.current.values.reservations?.[indexReservation]?.customers;
    customers.splice(indexCustomer, 1);
    refFormik.current.setFieldValue(`reservations[${indexReservation}].customers`, customers);
  },[refFormik.current?.values]);

  const handleDeleteTeeTime = (time) => {
    const newReservations = refFormik.current.values.reservations.filter(reservation => reservation.date_tee_time !== time);
    refFormik.current.setFieldValue('reservations', newReservations);
  };

  const handleAddTeeTime = () => {
    const reservations = refFormik.current.values.reservations;
    if (reservations.length > 0) {
      const lastReservation = reservations[reservations.length - 1];
      const lastReservationTime = lastReservation.date_tee_time;
      const indexTeeTime = optionsTeeTime.indexOf(lastReservationTime);
      if (indexTeeTime + 1 < optionsTeeTime.length) {
        const firstTeeTime = initialTeeTimes?.[0];
        const nextTeeTime = optionsTeeTime[indexTeeTime + 1];
        const courseId = firstTeeTime?.courseId;
        const rateCardId = firstTeeTime?.rateCardId;
        const startingHoleName = firstTeeTime?.startingHoleName;
        const idTeeTime = firstTeeTime?.optionsTeeTime.find(x => x.value === nextTeeTime)?.id;
        refFormik.current.setFieldValue('reservations', [
          ...reservations,
          {
            date,
            date_tee_time: optionsTeeTime[indexTeeTime + 1],
            course_id: courseId,
            tee_time_id: idTeeTime,
            rate_card_id: rateCardId,
            hole_name: startingHoleName,
            customers: []
          }
        ]);
      }
    }
  };

  return (
    <Formik
      innerRef={refFormik}
      enableReinitialize
      validationSchema={TeeSheetReservationGroupFormValidations}
      initialValues={initialValues || {
        name: '',
        date: date,
        reservations: []
      }}
    >
      {({ 
        values,
        errors,
        handleChange,
        submitCount,
        setFieldValue 
      }) => {
        return (
          <form id="tee-sheet-reservation-form">
            <Card>
              <Row gutter={16}>
                <Col xs={12}>
                  <HorizontalTextInput
                    label="Reservation date"
                    right={(
                      <DatePicker
                        className="date-picker"
                        size="large"
                        value={values.date ? moment(values.date, 'YYYY-MM-DD') : ''}
                        onSelect={value => {
                          handleChange('date')(moment(value).format('YYYY-MM-DD'));
                        }}
                      />
                    )}
                  />
                  {(submitCount > 0 && errors.date) && <Text className="error-message" type="danger">{errors.date}</Text>}
                </Col>
                <Col xs={12}>
                  <HorizontalTextInput
                    label="Reservation name"
                    value={values.name}
                    errorMessage={submitCount > 0 ? errors?.name : null}
                    onChange={e => {
                      handleChange('name')(e.target.value);
                    }}
                  />
                </Col>
              </Row>
            </Card>
            <Card className="card-item">
              {values.reservations.length > 0 && (
                <Tabs defaultActiveKey={activeTabKey} onChange={setActiveTabKey}>
                  {values.reservations.map((item, index) => (
                    <TabPane
                      key={index.toString()}
                      active={activeTabKey}
                      tab={
                        <span>
                          <CloseCircleOutlined onClick={() => handleDeleteTeeTime(item.date_tee_time)} />
                          {moment(item.date_tee_time, 'HH:mm:ss').format('hh:mm A')}
                        </span>
                      }
                    >
                      <>
                        <Row gutter={16} className="section-field">
                          <Col xs={12}>
                            <HorizontalTextInput
                              label="Tee time"
                              right={(
                                <TimePicker
                                  className="date-picker"
                                  use12Hours
                                  format="hh:mm A"
                                  size="large"
                                  disabled
                                  value={moment(item.date_tee_time, 'HH:mm:ss')}
                                />
                              )}
                            />
                          </Col>
                          <Col xs={12}>
                            <HorizontalSelectOptions
                              options={optionsRateCard}
                              placeholder={(
                                <div>
                                  <Icon component={SvgTag} className="icon-tee-time" />
                                  <Text className="placeholder">Select rate card</Text>
                                </div>
                              )}
                              value={values.reservations?.[index]?.rate_card_id}
                              errorMessage={submitCount > 0 ? errors?.reservations?.[index]?.rate_card_id : null}
                              onSelect={(value) => {
                                handleChange(`reservations[${index}]rate_card_id`)(value);
                              }}
                              label="Active rate card"
                            />
                          </Col>
                        </Row>
                        {item.customers.map((x, i) => {
                          return (
                            <>
                              <TeeSheetReservationCustomerForm
                                key={i}
                                values={item.customers[i]}
                                customersSelected={item.customers}
                                errors={submitCount > 0 ? errors?.reservations?.[index]?.customers?.[i] : null}
                                onChange={(values) => {
                                  setFieldValue(`reservations[${index}]customers[${i}]`, values);
                                }}
                                onDelete={() => handleDeleteCustomer(index, i)}
                                memberships={memberships(index)}
                                valueDateTeeTime={item.date_tee_time}
                              />
                              <div className="spacer-lg" />
                            </>
                          );
                        })}
                        <div className="button-add-container">
                          <Button
                            onClick={() => {
                              setFieldValue(`reservations[${index}]customers[${values.reservations[index].customers.length}]`, {
                                customer_id: '',
                                name: '',
                                email: '',
                                phone_number: '',
                                membership_id: null,
                                add_ons_id: [],
                                voucher_code: ''
                              });
                            }}
                            state={values.reservations[index].customers.length >=  maximumPlayers ? 'disabled' : 'default'}
                            disabled={values.reservations[index].customers.length >= maximumPlayers}
                            type="guide"
                          >
                            + Add customer
                          </Button>
                        </div>
                      </>
                    </TabPane>
                  ))}
                  <TabPane
                    disabled
                    style={{ cursor: 'pointer'}}
                    tab={
                      <span onClick={handleAddTeeTime}>
                        <PlusOutlined />
                        Add tee time
                      </span>
                    }
                  />
                </Tabs>
              )}
            </Card>
            <Card className="card-item">
              <HorizontalTextInputArea
                value={values.reservations?.[Number(activeTabKey)]?.note}
                onChange={handleChange(`reservations[${Number(activeTabKey)}].note`)}
                label="Reservation notes (for internal reference)"
              />
              <div className="spacer"/>
              <HorizontalTextInputArea
                value={values.reservations?.[Number(activeTabKey)]?.message}
                onChange={handleChange(`reservations[${Number(activeTabKey)}].message`)}
                label="Reservation message (sent to customer)"
              />
            </Card>
            <div className="card-item">
              <TotalCard total={numeral(getTotal()).format('$0,0[.]00')} />
            </div>
          </form>
        );
      }}
    </Formik>
  );
};

export default forwardRef(TeeSheetReservationGroupForm);

TeeSheetReservationGroupForm.propTypes = {
  date: PropTypes.string,
  initialTeeTimes: PropTypes.arrayOf(PropTypes.string),
  initialValues: PropTypes.object,
  teeTimes: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string
  })).isRequired,
  rateCards: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string,
    color: PropTypes.string,
    label: PropTypes.string
  }))
};