/* eslint-disable max-len */
import React from 'react';

function SvgRateCards() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="none"
      viewBox="0 0 14 14"
    >
      <path
        fill="currentColor"
        d="M3.667 3a.667.667 0 100 1.333.667.667 0 000-1.333zm9.806 3.853L7.153.547a.667.667 0 00-.486-.214h-4a.667.667 0 00-.474.194L.527 2.187a.667.667 0 00-.194.473v4a.7.7 0 00.194.473l6.326 6.334a.7.7 0 00.474.193.666.666 0 00.473-.193l5.673-5.674a.665.665 0 000-.94zM7.327 12.06l-5.66-5.667v-3.46l1.266-1.266h3.454l5.666 5.66-4.726 4.733z"
      />
    </svg>
  );
}

export default SvgRateCards;
