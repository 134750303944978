import React from 'react';
import { Tooltip } from 'antd';
import PropTypes from 'prop-types';
import Card from '../atoms/Card';
import Title from 'antd/lib/typography/Title';
import Icon from '@ant-design/icons/lib/components/Icon';
import SvgInformation from '../atoms/SvgInformation';
import './CardWithTitle.less';

const CardWithTitle = (props) => {
  const { title, subtitle, children } = props;
  return (
    <Card className={['card-with-title', props.className].join(' ')} bodyStyle={{ padding: 48 }}>
      <Title level={4}>{title}
        <Tooltip placement="rightTop" title={subtitle}>
          <Icon className="icon-svg-information" component={SvgInformation} />
        </Tooltip>
      </Title>
      {children}
    </Card>
  );
};

export default CardWithTitle;

CardWithTitle.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  children: PropTypes.node.isRequired
};

CardWithTitle.defaultProps = {
  subtitle: null
};

