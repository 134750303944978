import React, { useMemo } from 'react';
import { Col, Row } from 'antd';
import PropTypes from 'prop-types';
import ReportCard from '../molecules/ReportCard';
import formatter from 'utils/formatter';

const CustomerCheckInsCards = ({
  isPrint,
  totalCancellationFee,
  totalCancellation,
}) => {
  const items = useMemo(() => ([
    {
      title: 'Total cancellations',
      value: `${totalCancellation} cancellation`
    },
    {
      title: 'Total cancellation fees',
      value: `${formatter.currency(totalCancellationFee)}`
    },
  ]), [
    totalCancellationFee,
    totalCancellation,
  ]);

  return(
    <div id={`${isPrint ? 'print-' : ''}check-ins-cards`}>
      <Row gutter={[16, 16]}>
        {
          items.map(item => (
            <Col
              key={item.title}
              span={12}
            >
              <ReportCard
                title={item.title}
                value={item.value}
              />
            </Col>
          ))
        }
      </Row>
    </div>
  );
};

CustomerCheckInsCards.propTypes = {
  totalCancellationFee: PropTypes.number,
  totalCancellation: PropTypes.number,
};

CustomerCheckInsCards.defaultProps = {
  totalCancellationFee: 0,
  totalCancellation: 0,
};

export default CustomerCheckInsCards;