import { Col, Row, Timeline } from 'antd';
import Text from 'antd/lib/typography/Text';
import React, { useMemo } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import moment from 'moment';
import numeral from 'numeral';
import QueryGql from '../../constants/QueryGql';
import extractQuery from '../../utils/useQuery';
import Card from '../atoms/Card';
import Lozenges from '../atoms/Lozenges';
import Modal from '../organisms/Modal';
import CustomerDetailReservation from '../templates/CustomerDetailReservation';
import '../templates/TeeSheetCustomersCheckInDetail.less';
import Loading from '../atoms/Loading';
import Pathname from '../../constants/Pathname';
import stepFormatter from '../../utils/stepFormatter';

const TeeSheetReservationDetail = () => {
  const history = useHistory();
  const location = useLocation();
  const queryString = extractQuery(location.search);
  const id = queryString.get('id');
  
  const optionsTeeTime = location.state?.optionsTeeTime;

  const { loading: loadingGetReservation, data: dataGetReservation } = useQuery(
    QueryGql.GET_RESERVATION, {
      variables: {
        getReservationId: id
      }
    }
  );

  const detail = useMemo(() => dataGetReservation?.getReservation.data, [dataGetReservation]);

  const reservationCustomers = useMemo(() => {
    let customers = [];
    if (detail?.reservation_customers) {
      detail.reservation_customers.forEach(item => {
        const reservations = [
          {
            name: `${detail.tee_times.name} - ${item.membership_name}`,
            amount: item.membership_price,
            status: item.status
          },
          ...item.add_ons.map(x => ({
            name: x.name,
            amount: x.price,
            status: item.status
          }))
        ];

        if (item.raincheck?.code) {
          reservations.push({
            name: 'Raincheck',
            amount: - item.raincheck.amount,
            status: item.status
          });
        }


        customers = [
          ...customers,
          {
            name: item.customer.full_name,
            reservations
          }
        ];
      });
    }
    return customers;
  }, [detail]);

  const handleCancelReservation = () => {
    history.push(
      Pathname.TEE_SHEET + '/' +
        Pathname.TEE_SHEET_CANCEL_RESERVATION+
        `?id=${id}`,
      {
        background: true
      }
    );
  };

  const isShowCancelReservation = detail?.reservation_customers?.filter(item => item.status === 'reserved').length > 0;

  return (
    <Modal
      title="Reservation details"
      hiddenLeftFooterButton={!isShowCancelReservation}
      leftFooterButton={{
        label: 'Cancel reservation',
        type: 'link',
        onClick: handleCancelReservation
      }}
      rightFooterButtonExtra={{
        label: 'Edit reservation',
        type: 'outline',
        onClick: () => history.push(
          Pathname.TEE_SHEET+'/'+
          Pathname.TEE_SHEET_RESERVATION_FORM+
          `?act=edit&id=${detail.id}`,
          {
            background: true,
            optionsTeeTime
          }
        )
      }}
      rightFooterButton={{
        label: 'Check-in',
        onClick: () => history.push(
          Pathname.TEE_SHEET+'/'+
      Pathname.TEE_SHEET_CUSTOMERS_CHECKIN_DETAIL+
      `?id=${detail.id}`,
          {
            background: true
          }
        )
      }}
      width={540}
    >
      <div id="tee-sheet-detail-reservation">
        {loadingGetReservation && !dataGetReservation
          ? <Loading />
          : (
            <>
              <CustomerDetailReservation
                detail={{
                  number: `${detail.prefix}-${detail.number}`,
                  courseNumber: `${detail?.course?.name} (${detail?.course?.holes} Hole)`,
                  teeTime: moment(detail.date_tee_times, 'HH:mm:ss').format('hh:mm A'),
                  date: moment(detail.date).format('dddd, DD/MM/YYYY'),
                  status: detail.payment_status,
                  total: numeral(detail.total_amount).format('$0,0[.]00'),
                  type: 'detail-header'
                }}
                reservationCustomers={reservationCustomers}
              />
              <Card className="card-item">
                <Row justify="space-between" align="middle">
                  <Col>
                    <Text className="text-total">Total</Text>
                  </Col>
                  <Col>
                    <Row wrap={false}>
                      <Lozenges status={detail.payment_status} />
                      <Text level={4} className="price-total">{numeral(detail.total_amount).format('$0,0[.]00')}</Text>
                    </Row>
                  </Col>
                </Row>
              </Card>
              <Card className="card-item" bodyStyle={{ paddingBottom: 10 }}>
                <Timeline>
                  {detail?.reservation_operators?.map(operator => (
                    <Timeline.Item key={operator.id} color="gray">
                      <Row justify="space-between" align="middle">
                        <Col>
                          <Text type="secondary" className="footer-text-timeline">{stepFormatter(operator.step)} by {operator.full_name} (Operator)</Text>
                        </Col>
                        <Col>
                          <Text type="secondary" className="footer-text-timeline">{moment(operator.created_at).format('hh:mm A, DD/MM/YYYY')}</Text>
                        </Col>
                      </Row>
                    </Timeline.Item>
                  ))}
                </Timeline>
              </Card>
            </>
          )
        }
      </div>
    </Modal>
  );
};

export default TeeSheetReservationDetail;