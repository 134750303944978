import Layout from 'antd/lib/layout/layout';
import React from 'react';
import PropTypes from 'prop-types';
import AppBar from '../organisms/AppBar';
import AppContent from '../organisms/AppContent';

const AppWrapper = ({ children }) => {
  return (
    <Layout style={{ minWidth: 800 }}>
      <AppBar/>
      <AppContent>
        {children}
      </AppContent>
    </Layout>
  );
};

export default AppWrapper;

AppWrapper.propTypes = {
  children: PropTypes.node.isRequired
};