import Text from 'antd/lib/typography/Text';
import React from 'react';
import PropTypes from 'prop-types';
import DropFile from '../atoms/DropFile';
import './CustomerImport.less';

const CustomerImport = (props) => {
  const { onChange } = props;
  return (
    <div id="customer-import">
      <div className="wrapper-text">
        <Text className="download-text">Download the xlsx template </Text>
        <Text>then fill in the information and details of each customers. Make sure that customer types and memberships are added before importing the customers to avoid any errors.</Text>
      </div>
      <DropFile onChange={onChange} />
    </div>
  );
};

export default CustomerImport;

CustomerImport.propTypes = {
  onChange: PropTypes.func
};
