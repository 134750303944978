import React from 'react';

function SvgEmptyTypesMemberships() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="408"
      height="396"
      fill="none"
      viewBox="0 0 408 396"
    >
      <g clipPath="url(#clip0)">
        <path
          fill="url(#paint0_linear)"
          d="M60.942 124.777c33.418 0 60.508-27.09 60.508-60.509 0-33.418-27.09-60.508-60.508-60.508C27.524 3.76.433 30.85.433 64.268c0 33.418 27.09 60.509 60.509 60.509z"
          opacity="0.5"
        ></path>
        <path
          fill="#FF8589"
          d="M60.942 123.169c32.711 0 59.229-26.517 59.229-59.229 0-32.711-26.518-59.23-59.23-59.23-32.71 0-59.228 26.519-59.228 59.23 0 32.712 26.517 59.229 59.229 59.229z"
        ></path>
        <path
          fill="#fff"
          d="M61.109 123.336a58.955 58.955 0 0032.56-9.742c-2.183-11.926-10.49-12.61-10.49-12.61H37.384s-7.832.643-10.286 11.618a58.96 58.96 0 0034.01 10.734z"
        ></path>
        <path
          fill="#333"
          d="M60.488 88.892c14.501 0 26.257-11.756 26.257-26.258 0-14.501-11.756-26.257-26.257-26.257-14.502 0-26.258 11.756-26.258 26.257 0 14.502 11.756 26.258 26.258 26.258z"
        ></path>
        <path
          fill="#000D23"
          d="M51.262 86.45h18.32v14.655a9.16 9.16 0 01-15.633 6.476 9.16 9.16 0 01-2.682-6.476V86.449h-.005z"
          opacity="0.1"
        ></path>
        <path
          fill="#BE7C5E"
          d="M53.01 85.77h14.824a1.75 1.75 0 011.749 1.749v12.906a9.157 9.157 0 11-18.316 0V87.519a1.749 1.749 0 011.744-1.75z"
        ></path>
        <path
          fill="#000D23"
          d="M51.298 93.366a26.367 26.367 0 0018.321.052v-2.25H51.3v2.198z"
          opacity="0.1"
        ></path>
        <path
          fill="#BE7C5E"
          d="M60.488 94.593c14.501 0 26.257-11.756 26.257-26.258 0-14.501-11.756-26.257-26.257-26.257-14.502 0-26.258 11.756-26.258 26.257 0 14.502 11.756 26.258 26.258 26.258z"
        ></path>
        <path
          fill="#333"
          d="M44.579 57.508c.308-1.133.694-2.38 1.712-2.97.804-.47 1.801-.397 2.73-.35 7.258.396 14.453-1.259 21.512-2.987 4.036-.987 8.354-2.188 10.965-5.44 2.61-3.253-.794-13.476-4.767-14.683-2.61-.798-5.64.69-8.114-.48-1.102-.522-1.911-1.498-2.861-2.26-1.948-1.567-4.449-2.199-6.871-2.794-2.83-.7-5.89-1.384-8.579-.261-2.088.867-3.613 2.7-5.592 3.785-3.764 2.089-8.354 1.118-12.666 1.107-1.457 0-3.196.293-3.775 1.63-.392.908-.079 1.941-.037 2.928.125 3.133-2.459 5.571-4.626 7.832-2.167 2.26-4.213 5.488-2.845 8.297.522 1.07 1.477 1.88 2.02 2.94.935 1.837.465 4.056.804 6.087.34 1.541.916 3.02 1.708 4.386 1.608 3.133 9.685 19.997 13.397 15.71 1.796-2.088 2.089-8.124 2.814-10.839a929.604 929.604 0 013.07-11.638z"
        ></path>
        <path
          fill="#BE7C5E"
          d="M41.806 71.96c1.35 0 2.444-2.05 2.444-4.578 0-2.53-1.094-4.58-2.444-4.58-1.35 0-2.444 2.05-2.444 4.58 0 2.529 1.094 4.579 2.444 4.579z"
        ></path>
        <path
          fill="url(#paint1_linear)"
          d="M201.058 256.472c33.418 0 60.508-27.091 60.508-60.508 0-33.418-27.09-60.509-60.508-60.509-33.418 0-60.509 27.091-60.509 60.509 0 33.417 27.091 60.508 60.509 60.508z"
          opacity="0.5"
        ></path>
        <path
          fill="#62F8B0"
          d="M201.173 254.806c32.711 0 59.229-26.518 59.229-59.229 0-32.712-26.518-59.229-59.229-59.229-32.712 0-59.23 26.517-59.23 59.229 0 32.711 26.518 59.229 59.23 59.229z"
        ></path>
        <path
          fill="#fff"
          d="M201.225 255.041a58.953 58.953 0 0032.538-9.742c-2.182-11.926-10.489-12.61-10.489-12.61h-45.779s-7.832.643-10.286 11.618a58.96 58.96 0 0034.016 10.734z"
        ></path>
        <path
          fill="#F55F44"
          d="M200.598 222.921c15.791 0 28.592-12.801 28.592-28.591 0-15.791-12.801-28.592-28.592-28.592-15.79 0-28.591 12.801-28.591 28.592 0 15.79 12.801 28.591 28.591 28.591z"
        ></path>
        <path
          fill="#000D23"
          d="M191.373 218.143h18.321v14.655a9.156 9.156 0 01-9.158 9.158 9.157 9.157 0 01-9.158-9.158v-14.655h-.005z"
          opacity="0.1"
        ></path>
        <path
          fill="#FDA57D"
          d="M193.122 217.465h14.823a1.747 1.747 0 011.749 1.749v12.907a9.161 9.161 0 01-9.158 9.158 9.162 9.162 0 01-6.476-2.683 9.157 9.157 0 01-2.682-6.475v-12.907a1.75 1.75 0 011.749-1.749h-.005z"
        ></path>
        <path
          fill="#000D23"
          d="M191.409 225.061a26.366 26.366 0 0018.321.053v-2.251h-18.321v2.198z"
          opacity="0.1"
        ></path>
        <path
          fill="#FDA57D"
          d="M200.598 226.288c14.502 0 26.258-11.756 26.258-26.257 0-14.502-11.756-26.258-26.258-26.258-14.501 0-26.257 11.756-26.257 26.258 0 14.501 11.756 26.257 26.257 26.257z"
        ></path>
        <path
          fill="#F55F44"
          d="M200.562 175.815c5.836 0 10.568-4.731 10.568-10.568 0-5.836-4.732-10.567-10.568-10.567-5.837 0-10.568 4.731-10.568 10.567 0 5.837 4.731 10.568 10.568 10.568z"
        ></path>
        <path
          fill="#F55F44"
          d="M200.562 161.78a6.939 6.939 0 100-13.877 6.939 6.939 0 000 13.877z"
        ></path>
        <path
          fill="#F55F44"
          d="M195.978 171.042a10.567 10.567 0 01-5.509-13.513 10.572 10.572 0 009.124 15.34 10.568 10.568 0 0010.142-6.699 10.566 10.566 0 01-13.757 4.872z"
        ></path>
        <path
          fill="#000D23"
          d="M181.901 176.149s11.064 22.451 42.292 9.398l-7.268-11.398-12.881-4.626-22.143 6.626z"
          opacity="0.1"
        ></path>
        <path
          fill="#F55F44"
          d="M181.901 175.815s11.064 22.451 42.292 9.398l-7.268-11.398-12.881-4.626-22.143 6.626z"
        ></path>
        <path
          fill="#333"
          d="M190.427 167.638a130.328 130.328 0 0120.457 0s-15.11 1.404-20.457 0z"
        ></path>
        <path
          fill="#F55F44"
          d="M202.149 147.479a6.97 6.97 0 00-1.566.12h.402a6.934 6.934 0 011.044 13.748 6.934 6.934 0 00.125-13.868h-.005z"
        ></path>
        <path
          fill="#FDA57D"
          d="M174.336 204.584c1.349 0 2.443-2.05 2.443-4.579s-1.094-4.579-2.443-4.579c-1.35 0-2.444 2.05-2.444 4.579s1.094 4.579 2.444 4.579zM226.856 204.584c1.349 0 2.443-2.05 2.443-4.579s-1.094-4.579-2.443-4.579c-1.35 0-2.444 2.05-2.444 4.579s1.094 4.579 2.444 4.579z"
        ></path>
        <path
          fill="#F55F44"
          d="M180.246 176.478l5.122-2.642 1.154 4.234-3.138 2.136-3.138-3.728z"
        ></path>
        <path
          fill="url(#paint2_linear)"
          d="M201 121.806c33.418 0 60.509-27.09 60.509-60.508C261.509 27.88 234.418.788 201 .788c-33.417 0-60.508 27.09-60.508 60.508 0 33.418 27.091 60.509 60.508 60.509z"
          opacity="0.5"
        ></path>
        <path
          fill="#859DFF"
          d="M201 120.199c32.712 0 59.23-26.518 59.23-59.23 0-32.71-26.518-59.229-59.23-59.229-32.711 0-59.229 26.518-59.229 59.23 0 32.71 26.518 59.229 59.229 59.229z"
        ></path>
        <path
          fill="#333"
          d="M177.897 61.496s-6.715 33.082-10.38 31.249c0 0 35.347 30.534 66.56 0 0 0-5.498-23.313-13.434-31.249h-42.746z"
        ></path>
        <path
          fill="#fff"
          d="M201.167 120.375a58.957 58.957 0 0032.56-9.742c-2.183-11.926-10.489-12.61-10.489-12.61h-45.801s-7.832.643-10.286 11.618a58.962 58.962 0 0034.016 10.734z"
        ></path>
        <path
          fill="#333"
          d="M200.541 85.921c14.502 0 26.257-11.756 26.257-26.257 0-14.502-11.755-26.258-26.257-26.258s-26.257 11.756-26.257 26.258c0 14.501 11.755 26.257 26.257 26.257z"
        ></path>
        <path
          fill="#000D23"
          d="M191.315 83.478h18.321v14.656a9.155 9.155 0 01-9.142 9.158 9.157 9.157 0 01-9.158-9.158V83.478h-.021z"
          opacity="0.1"
        ></path>
        <path
          fill="#BE7C5E"
          d="M193.064 82.799h14.823a1.75 1.75 0 011.749 1.749v12.907a9.155 9.155 0 01-9.142 9.158 9.158 9.158 0 01-9.158-9.158V84.543a1.748 1.748 0 011.728-1.744z"
        ></path>
        <path
          fill="#000D23"
          d="M191.352 90.395a26.367 26.367 0 0018.321.053v-2.25h-18.321v2.197z"
          opacity="0.1"
        ></path>
        <path
          fill="#BE7C5E"
          d="M200.541 91.618c14.502 0 26.257-11.755 26.257-26.257s-11.755-26.258-26.257-26.258-26.257 11.756-26.257 26.258c0 14.501 11.755 26.257 26.257 26.257z"
        ></path>
        <path
          fill="#333"
          d="M175.443 55.388h50.071s-4.276-20.216-23.203-18.926c-18.927 1.29-26.868 18.926-26.868 18.926z"
        ></path>
        <path
          fill="#BE7C5E"
          d="M174.827 68.822c1.349 0 2.443-2.05 2.443-4.579s-1.094-4.579-2.443-4.579c-1.35 0-2.444 2.05-2.444 4.58 0 2.528 1.094 4.578 2.444 4.578zM226.12 68.822c1.349 0 2.443-2.05 2.443-4.579s-1.094-4.579-2.443-4.579c-1.35 0-2.444 2.05-2.444 4.58 0 2.528 1.094 4.578 2.444 4.578z"
        ></path>
        <path
          fill="#000D23"
          d="M175.443 56.066h50.071s-4.276-20.216-23.203-18.927c-18.927 1.29-26.868 18.927-26.868 18.927z"
          opacity="0.1"
        ></path>
        <path
          fill="url(#paint3_linear)"
          d="M345.241 121.54c33.418 0 60.509-27.09 60.509-60.508 0-33.418-27.091-60.509-60.509-60.509s-60.508 27.091-60.508 60.509 27.09 60.508 60.508 60.508z"
          opacity="0.5"
        ></path>
        <path
          fill="#62F8B0"
          d="M345.356 119.869c32.711 0 59.229-26.518 59.229-59.23 0-32.711-26.518-59.229-59.229-59.229s-59.229 26.518-59.229 59.23c0 32.71 26.518 59.229 59.229 59.229z"
        ></path>
        <path
          fill="#fff"
          d="M345.408 120.109a58.95 58.95 0 0032.56-9.743c-2.188-11.93-10.49-12.614-10.49-12.614h-45.795s-7.832.642-10.286 11.617a58.958 58.958 0 0034.011 10.74z"
        ></path>
        <path
          fill="#72351C"
          d="M344.787 87.983c15.791 0 28.591-12.8 28.591-28.59 0-15.791-12.8-28.592-28.591-28.592s-28.591 12.8-28.591 28.591c0 15.79 12.8 28.591 28.591 28.591z"
        ></path>
        <path
          fill="#000D23"
          d="M335.556 83.207h18.321v14.656a9.145 9.145 0 01-.693 3.509 9.147 9.147 0 01-4.957 4.966 9.16 9.16 0 01-9.984-1.984 9.159 9.159 0 01-2.682-6.475V83.207h-.005z"
          opacity="0.1"
        ></path>
        <path
          fill="#FDA57D"
          d="M337.289 82.527h14.823a1.75 1.75 0 011.749 1.75v12.906a9.157 9.157 0 01-15.633 6.476 9.158 9.158 0 01-2.683-6.476V84.276a1.75 1.75 0 011.75-1.749h-.006z"
        ></path>
        <path
          fill="#000D23"
          d="M335.577 90.124a26.367 26.367 0 0018.321.052v-2.25h-18.321v2.198z"
          opacity="0.1"
        ></path>
        <path
          fill="#FDA57D"
          d="M344.787 91.35c14.501 0 26.257-11.755 26.257-26.257 0-14.501-11.756-26.257-26.257-26.257-14.502 0-26.257 11.756-26.257 26.257 0 14.502 11.755 26.258 26.257 26.258z"
        ></path>
        <path
          fill="#000D23"
          d="M326.085 41.212s11.063 22.45 42.291 9.398l-7.268-11.398-12.88-4.626-22.143 6.626z"
          opacity="0.1"
        ></path>
        <path
          fill="#72351C"
          d="M326.085 40.883s11.063 22.452 42.291 9.399l-7.268-11.398-12.88-4.626-22.143 6.625z"
        ></path>
        <path
          fill="#72351C"
          d="M325.985 39.363a15.161 15.161 0 013.76-6.114c5.163-5.106 13.632-6.182 17.976-12.009a3.399 3.399 0 01-1.566 4.793c4.177-.026 8.986-.391 11.335-3.816a7.78 7.78 0 01-1.31 8.218c3.686.167 7.623 2.668 7.863 6.354.156 2.454-1.379 4.777-3.399 6.187-2.021 1.41-4.454 2.042-6.856 2.564-7.007 1.566-32.376 8.046-27.803-6.177zM371.044 65.324h-.694L356.806 77.39c-13.053-6.108-22.629 0-22.629 0l-14.201-11.56-1.431.178a26.258 26.258 0 0052.504-.673l-.005-.01zM344.75 82.006c-5.106 0-9.246-1.044-9.246-2.313 0-1.269 4.14-2.313 9.246-2.313 5.107 0 9.247 1.044 9.247 2.313 0 1.269-4.14 2.313-9.252 2.313h.005z"
        ></path>
        <path
          fill="#FDA57D"
          d="M318.519 69.646c1.35 0 2.444-2.05 2.444-4.579s-1.094-4.579-2.444-4.579c-1.349 0-2.443 2.05-2.443 4.58 0 2.528 1.094 4.578 2.443 4.578zM371.039 69.646c1.35 0 2.444-2.05 2.444-4.579s-1.094-4.579-2.444-4.579c-1.349 0-2.443 2.05-2.443 4.58 0 2.528 1.094 4.578 2.443 4.578z"
        ></path>
        <path
          fill="url(#paint4_linear)"
          d="M60.508 252.509c33.418 0 60.509-27.09 60.509-60.508 0-33.418-27.09-60.509-60.508-60.509C27.09 131.492 0 158.583 0 192.001s27.09 60.508 60.508 60.508z"
          opacity="0.5"
        ></path>
        <path
          fill="#859DFF"
          d="M60.623 250.843c32.712 0 59.23-26.518 59.23-59.229s-26.518-59.229-59.23-59.229c-32.711 0-59.229 26.518-59.229 59.229s26.518 59.229 59.23 59.229z"
        ></path>
        <path
          fill="#fff"
          d="M60.675 251.078a58.947 58.947 0 0032.555-9.742c-2.183-11.925-10.49-12.609-10.49-12.609H36.924s-7.831.642-10.285 11.617a58.95 58.95 0 0034.037 10.734z"
        ></path>
        <path
          fill="#333"
          d="M60.049 218.958c15.79 0 28.591-12.801 28.591-28.591 0-15.791-12.8-28.592-28.591-28.592-15.79 0-28.591 12.801-28.591 28.592 0 15.79 12.8 28.591 28.591 28.591z"
        ></path>
        <path
          fill="#000D23"
          d="M50.823 214.18h18.321v14.656a9.157 9.157 0 01-18.316 0V214.18h-.005z"
          opacity="0.1"
        ></path>
        <path
          fill="#FDB797"
          d="M52.572 213.501h14.823a1.75 1.75 0 011.75 1.749v12.918a9.156 9.156 0 01-9.159 9.158 9.17 9.17 0 01-6.479-2.681 9.148 9.148 0 01-2.684-6.477v-12.923a1.75 1.75 0 011.75-1.749v.005z"
        ></path>
        <path
          fill="#000D23"
          d="M50.86 221.099a26.372 26.372 0 0018.32.052v-2.266H50.86v2.214z"
          opacity="0.1"
        ></path>
        <path
          fill="#FDB797"
          d="M60.049 222.325c14.502 0 26.257-11.756 26.257-26.257 0-14.502-11.755-26.257-26.257-26.257s-26.257 11.755-26.257 26.257c0 14.501 11.755 26.257 26.257 26.257z"
        ></path>
        <path
          fill="#000D23"
          d="M41.352 172.18s11.064 22.452 42.292 9.399l-7.268-11.398-12.881-4.626-22.143 6.625z"
          opacity="0.1"
        ></path>
        <path
          fill="#333"
          d="M41.352 171.852s11.064 22.451 42.292 9.398l-7.268-11.397-12.881-4.626-22.143 6.625z"
        ></path>
        <path
          fill="#333"
          d="M41.253 170.328a15.164 15.164 0 013.759-6.114c5.164-5.107 13.633-6.182 17.977-12.009a3.398 3.398 0 01-1.567 4.793c4.177-.026 8.986-.391 11.335-3.817a7.786 7.786 0 01-1.31 8.219c3.686.167 7.623 2.668 7.863 6.354.157 2.454-1.378 4.777-3.399 6.187-2.02 1.41-4.459 2.052-6.86 2.584-7.008 1.556-32.372 8.036-27.798-6.197z"
        ></path>
        <path
          fill="#FDB797"
          d="M33.781 200.621c1.35 0 2.444-2.05 2.444-4.579s-1.094-4.579-2.444-4.579c-1.35 0-2.443 2.05-2.443 4.579s1.094 4.579 2.443 4.579zM86.301 200.621c1.35 0 2.444-2.05 2.444-4.579s-1.094-4.579-2.444-4.579c-1.35 0-2.443 2.05-2.443 4.579s1.094 4.579 2.443 4.579z"
        ></path>
        <path
          fill="url(#paint5_linear)"
          d="M347.497 259.218c33.418 0 60.508-27.09 60.508-60.508 0-33.418-27.09-60.509-60.508-60.509-33.418 0-60.509 27.091-60.509 60.509s27.091 60.508 60.509 60.508z"
          opacity="0.5"
        ></path>
        <path
          fill="#859DFF"
          d="M347.497 257.611c32.711 0 59.229-26.518 59.229-59.229 0-32.712-26.518-59.23-59.229-59.23-32.712 0-59.229 26.518-59.229 59.23 0 32.711 26.517 59.229 59.229 59.229z"
        ></path>
        <path
          fill="#fff"
          d="M347.664 257.788a58.955 58.955 0 0032.559-9.743c-2.182-11.925-10.489-12.609-10.489-12.609h-45.801s-7.831.642-10.285 11.617a58.968 58.968 0 0034.016 10.735z"
        ></path>
        <path
          fill="#000D23"
          d="M337.812 220.889h18.321v14.656a9.155 9.155 0 01-2.683 6.475 9.154 9.154 0 01-9.981 1.987 9.15 9.15 0 01-4.96-4.957 9.162 9.162 0 01-.697-3.505v-14.656z"
          opacity="0.1"
        ></path>
        <path
          fill="#FDB797"
          d="M339.561 220.211h14.823a1.749 1.749 0 011.749 1.749v12.907a9.158 9.158 0 01-12.664 8.462 9.15 9.15 0 01-4.96-4.956 9.171 9.171 0 01-.697-3.506V221.96a1.75 1.75 0 011.749-1.749z"
        ></path>
        <path
          fill="#000D23"
          d="M337.848 227.807a26.366 26.366 0 0018.321.053v-2.251h-18.321v2.198z"
          opacity="0.1"
        ></path>
        <path
          fill="#FDB797"
          d="M347.037 229.034c14.502 0 26.258-11.756 26.258-26.257 0-14.502-11.756-26.257-26.258-26.257-14.501 0-26.257 11.755-26.257 26.257 0 14.501 11.756 26.257 26.257 26.257z"
        ></path>
        <path
          fill="#FDB797"
          d="M321.323 206.234c1.349 0 2.443-2.05 2.443-4.579s-1.094-4.579-2.443-4.579c-1.35 0-2.444 2.05-2.444 4.579s1.094 4.579 2.444 4.579zM372.616 206.234c1.349 0 2.443-2.05 2.443-4.579s-1.094-4.579-2.443-4.579c-1.35 0-2.444 2.05-2.444 4.579s1.094 4.579 2.444 4.579z"
        ></path>
        <path
          stroke="#333"
          strokeMiterlimit="10"
          strokeWidth="2"
          d="M339.999 202.902h-6.688a2.27 2.27 0 00-2.271 2.272v1.075a2.27 2.27 0 002.271 2.271h6.688a2.271 2.271 0 002.271-2.271v-1.075a2.272 2.272 0 00-2.271-2.272zM354.081 208.516h6.688a2.272 2.272 0 002.271-2.272v-1.075a2.271 2.271 0 00-2.271-2.271h-6.688a2.272 2.272 0 00-2.272 2.271v1.075a2.272 2.272 0 002.272 2.272zM341.842 203.445s3.561-1.932 10.181 0"
        ></path>
        <path
          fill="#858585"
          d="M365.191 183.746l6.443 14.969s7.879-9.93-6.443-14.969z"
        ></path>
        <path
          stroke="#333"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M363.04 205.712l9.618-8.589"
        ></path>
        <path
          fill="#858585"
          d="M328.894 183.746l-6.443 14.969s-7.879-9.93 6.443-14.969z"
        ></path>
        <path
          stroke="#333"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M331.04 205.712l-9.748-8.589"
        ></path>
        <path
          fill="url(#paint6_linear)"
          d="M60.942 388.536c33.418 0 60.508-27.09 60.508-60.508 0-33.418-27.09-60.508-60.508-60.508-33.418 0-60.509 27.09-60.509 60.508 0 33.418 27.09 60.508 60.509 60.508z"
          opacity="0.5"
        ></path>
        <path
          fill="#FF8589"
          d="M60.942 386.929c32.711 0 59.229-26.518 59.229-59.229s-26.518-59.229-59.23-59.229c-32.71 0-59.228 26.518-59.228 59.229s26.517 59.229 59.229 59.229z"
        ></path>
        <path
          fill="#FDD835"
          d="M93.705 333.024c0 21.815-14.233 46.098-31.792 46.098s-31.776-24.283-31.776-46.098c0-21.814 14.233-32.893 31.792-32.893 17.558 0 31.776 11.084 31.776 32.893z"
        ></path>
        <path
          fill="#fff"
          d="M61.109 387.112a58.956 58.956 0 0032.56-9.743c-2.183-11.925-10.49-12.609-10.49-12.609H37.384s-7.832.642-10.286 11.617a58.961 58.961 0 0034.01 10.735z"
        ></path>
        <path
          fill="#000D23"
          d="M51.262 350.207h18.32v14.656a9.16 9.16 0 01-15.633 6.476 9.16 9.16 0 01-2.682-6.476v-14.656h-.005z"
          opacity="0.1"
        ></path>
        <path
          fill="#FDB797"
          d="M53.01 349.529h14.824a1.75 1.75 0 011.749 1.749v12.907a9.16 9.16 0 01-15.634 6.476 9.16 9.16 0 01-2.682-6.476v-12.907a1.75 1.75 0 011.744-1.749z"
        ></path>
        <path
          fill="#000D23"
          d="M51.298 357.126a26.367 26.367 0 0018.321.052v-2.25H51.3v2.198z"
          opacity="0.1"
        ></path>
        <path
          fill="#FDB797"
          d="M60.488 358.353c14.501 0 26.257-11.756 26.257-26.258 0-14.501-11.756-26.257-26.257-26.257-14.502 0-26.258 11.756-26.258 26.257 0 14.502 11.756 26.258 26.258 26.258z"
        ></path>
        <path
          fill="#FDB797"
          d="M34.773 335.553c1.35 0 2.444-2.051 2.444-4.579 0-2.529-1.094-4.579-2.444-4.579-1.35 0-2.443 2.05-2.443 4.579 0 2.528 1.094 4.579 2.443 4.579zM86.066 335.553c1.35 0 2.444-2.051 2.444-4.579 0-2.529-1.094-4.579-2.444-4.579-1.35 0-2.443 2.05-2.443 4.579 0 2.528 1.094 4.579 2.443 4.579z"
        ></path>
        <path
          fill="#000D23"
          d="M37.222 317.851s14.844 20.316 48.886 13.377c0 0 3.801-4.626-5.45-18.828-9.253-14.201-35.014-11.888-43.436 5.451z"
          opacity="0.1"
        ></path>
        <path
          fill="#FDD835"
          d="M37.222 317.523s14.844 20.316 48.886 13.377c0 0 3.801-4.626-5.45-18.828-9.253-14.201-35.014-11.888-43.436 5.451z"
        ></path>
        <path
          fill="url(#paint7_linear)"
          d="M204.687 395.476c33.417 0 60.508-27.091 60.508-60.509 0-33.417-27.091-60.508-60.508-60.508-33.418 0-60.509 27.091-60.509 60.508 0 33.418 27.091 60.509 60.509 60.509z"
          opacity="0.5"
        ></path>
        <path
          fill="#DA85FF"
          d="M204.807 393.81c32.711 0 59.229-26.518 59.229-59.229 0-32.712-26.518-59.229-59.229-59.229-32.712 0-59.23 26.517-59.23 59.229 0 32.711 26.518 59.229 59.23 59.229z"
        ></path>
        <path
          fill="#333"
          d="M237.392 340.419c0 19.704-14.155 29.4-32.538 29.4-18.384 0-34.022-9.696-34.022-29.4 0-19.705 14.902-35.677 33.28-35.677 18.379 0 33.28 15.956 33.28 35.677z"
        ></path>
        <path
          fill="#fff"
          d="M204.854 394.045a58.954 58.954 0 0032.564-9.742c-2.182-11.926-10.489-12.61-10.489-12.61h-45.8s-7.832.643-10.286 11.618a58.96 58.96 0 0034.011 10.734z"
        ></path>
        <path
          fill="#000D23"
          d="M195.007 357.131h18.321v14.656a9.153 9.153 0 01-2.684 6.493 9.165 9.165 0 01-6.495 2.68 9.157 9.157 0 01-9.158-9.158v-14.671h.016z"
          opacity="0.1"
        ></path>
        <path
          fill="#FDA57D"
          d="M196.75 356.468h14.823a1.75 1.75 0 011.749 1.749v12.907a9.16 9.16 0 01-5.664 8.465 9.145 9.145 0 01-3.509.693 9.161 9.161 0 01-9.158-9.158v-12.912a1.75 1.75 0 011.749-1.749l.01.005z"
        ></path>
        <path
          fill="#000D23"
          d="M195.043 364.065a26.366 26.366 0 0018.321.053v-2.251h-18.321v2.198z"
          opacity="0.1"
        ></path>
        <path
          fill="#FDA57D"
          d="M204.232 365.286c14.502 0 26.258-11.756 26.258-26.257 0-14.502-11.756-26.258-26.258-26.258-14.501 0-26.257 11.756-26.257 26.258 0 14.501 11.756 26.257 26.257 26.257z"
        ></path>
        <path
          fill="#000D23"
          d="M173.97 340.747s16.353-1.984 28.717-17.836c0 0 16.353 18.003 27.745 16.019 0 0 6.605-6.939 0-13.873-6.605-6.934-11.727-15.69-20.477-14.536-8.751 1.154-19.491 1.65-24.112 5.78-4.621 4.13-11.873 24.446-11.873 24.446z"
          opacity="0.1"
        ></path>
        <path
          fill="#333"
          d="M173.97 340.419s16.353-1.984 28.717-17.836c0 0 16.353 18.003 27.745 16.019 0 0 6.605-6.939 0-13.873-6.605-6.934-11.727-15.69-20.477-14.536-8.751 1.154-19.47 1.629-24.091 5.806-4.621 4.177-11.894 24.42-11.894 24.42z"
        ></path>
        <path
          fill="#FDA57D"
          d="M177.965 343.588c1.349 0 2.443-2.05 2.443-4.579s-1.094-4.579-2.443-4.579c-1.35 0-2.444 2.05-2.444 4.579s1.094 4.579 2.444 4.579zM230.485 343.588c1.349 0 2.443-2.05 2.443-4.579s-1.094-4.579-2.443-4.579c-1.35 0-2.444 2.05-2.444 4.579s1.094 4.579 2.444 4.579z"
        ></path>
      </g>
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="60.942"
          x2="60.942"
          y1="124.777"
          y2="3.765"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="gray" stopOpacity="0.25"></stop>
          <stop offset="0.54" stopColor="gray" stopOpacity="0.12"></stop>
          <stop offset="1" stopColor="gray" stopOpacity="0.1"></stop>
        </linearGradient>
        <linearGradient
          id="paint1_linear"
          x1="46741.7"
          x2="46741.7"
          y1="59459.2"
          y2="31411.1"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="gray" stopOpacity="0.25"></stop>
          <stop offset="0.54" stopColor="gray" stopOpacity="0.12"></stop>
          <stop offset="1" stopColor="gray" stopOpacity="0.1"></stop>
        </linearGradient>
        <linearGradient
          id="paint2_linear"
          x1="46728.4"
          x2="46728.4"
          y1="28111.8"
          y2="62.508"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="gray" stopOpacity="0.25"></stop>
          <stop offset="0.54" stopColor="gray" stopOpacity="0.12"></stop>
          <stop offset="1" stopColor="gray" stopOpacity="0.1"></stop>
        </linearGradient>
        <linearGradient
          id="paint3_linear"
          x1="80304.7"
          x2="80304.7"
          y1="28048.6"
          y2="0.523"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="gray" stopOpacity="0.25"></stop>
          <stop offset="0.54" stopColor="gray" stopOpacity="0.12"></stop>
          <stop offset="1" stopColor="gray" stopOpacity="0.1"></stop>
        </linearGradient>
        <linearGradient
          id="paint4_linear"
          x1="14024.6"
          x2="14024.6"
          y1="58536.7"
          y2="30488.6"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="gray" stopOpacity="0.25"></stop>
          <stop offset="0.54" stopColor="gray" stopOpacity="0.12"></stop>
          <stop offset="1" stopColor="gray" stopOpacity="0.1"></stop>
        </linearGradient>
        <linearGradient
          id="paint5_linear"
          x1="80829.8"
          x2="80829.8"
          y1="60098.4"
          y2="32050.4"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="gray" stopOpacity="0.25"></stop>
          <stop offset="0.54" stopColor="gray" stopOpacity="0.12"></stop>
          <stop offset="1" stopColor="gray" stopOpacity="0.1"></stop>
        </linearGradient>
        <linearGradient
          id="paint6_linear"
          x1="14125.5"
          x2="14125.5"
          y1="90201.2"
          y2="62153.1"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="gray" stopOpacity="0.25"></stop>
          <stop offset="0.54" stopColor="gray" stopOpacity="0.12"></stop>
          <stop offset="1" stopColor="gray" stopOpacity="0.1"></stop>
        </linearGradient>
        <linearGradient
          id="paint7_linear"
          x1="47586.4"
          x2="47586.4"
          y1="91816.5"
          y2="63768.4"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="gray" stopOpacity="0.25"></stop>
          <stop offset="0.54" stopColor="gray" stopOpacity="0.12"></stop>
          <stop offset="1" stopColor="gray" stopOpacity="0.1"></stop>
        </linearGradient>
        <clipPath id="clip0">
          <path
            fill="#fff"
            d="M0 0H408V394.952H0z"
            transform="translate(0 .523)"
          ></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgEmptyTypesMemberships;
