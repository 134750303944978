import { useLazyQuery, useMutation } from '@apollo/client';
import Text from 'antd/lib/typography/Text';
import { Formik } from 'formik';
import React, { useEffect, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import * as Yup from 'yup';
import QueryGql from '../../constants/QueryGql';
import useQuery from '../../utils/useQuery';
import Card from '../atoms/Card';
import HorizontalTextInput from '../molecules/HorizontalTextInput';
import Modal from '../organisms/Modal';

const CourseSchemaValidations = Yup.object().shape({
  name: Yup.string().required('Required'),
  holes: Yup.number().required('Required'),
});

const GolfCourseSettingsCourse = () => {
  const history = useHistory();
  const location = useLocation();
  const refFormik = useRef();
  const queryString = useQuery(location.search);
  const act = queryString.get('act');
  const [findCourseById, {
    loading: loadingFindCourseById,
    data: dataFindCourseById 
  }] = useLazyQuery(QueryGql.FIND_COURSE_BY_ID);
  const [updateCourse, {
    loading: loadingUpdateCourse
  }] = useMutation(QueryGql.UPDATE_COURSE, {
    onCompleted: () => {
      history?.goBack();
    },
    onError: () => {
      history?.goBack();
    }
  });
  const [createCourse, {
    loading: loadingCreateCourse
  }] = useMutation(QueryGql.CREATE_COURSE, {
    onCompleted: () => {
      history?.goBack();
    },
    onError: () => {
      history?.goBack();
    },
    update(cache, { data: { createCourse }}) {
      cache.modify({
        fields: {
          getAllCourses(existingCourses = []) {
            const newCoursesRef = cache.writeFragment({
              data: createCourse.data,
              fragment: QueryGql.FRAGMENT_COURSE
            });
            return {
              ...existingCourses,
              data: [
                ...existingCourses.data,
                newCoursesRef,
              ]
            };
          }
        }
      });
    }
  });
  const [deleteCourse, {
    loading: loadingDeleteCourse
  }] = useMutation(QueryGql.DELETE_COURSE, {
    onCompleted: () => {
      history?.goBack();
    },
    onError: () => {
      history?.goBack();
    },
    update(cache, { data: { deleteCourse }}) {
      cache.modify({
        fields: {
          getAllCourses(existingCourses = []) {
            return {
              ...existingCourses,
              data: existingCourses?.data.filter(item => !item.__ref.includes(deleteCourse.data?.id))
            };
          }
        }
      });
    }
  });

  useEffect(() => {
    if (act === 'edit') {
      const id = queryString.get('id');
      findCourseById({
        variables: {
          findCourseByIdId: id
        }
      });
    }
  }, []);

  const handleSubmit = (values) => {
    const { holes, name } = values;
    if (act === 'edit') {
      const { id } = dataFindCourseById.findCourseById?.data;
      updateCourse({
        variables: {
          updateCourseId: id,
          updateCourseName: name,
          updateCourseHoles: Number(holes),
          updateCourseIsActive: true
        }
      });
    } else {
      createCourse({
        variables: {
          createCourseName: name,
          createCourseHoles: Number(holes),
          createCourseIsActive: true
        }
      });
    }
  };

  const handleDelete = () => {
    const { id } = dataFindCourseById.findCourseById?.data;
    deleteCourse({
      variables: {
        deleteCourseId: id
      }
    });
  };

  return (
    <Modal
      title={act === 'add' ? 'Add course' : 'Edit course'}
      hiddenLeftFooterButton={act === 'add'}
      leftFooterButton={{
        label: 'Delete golf course',
        type: 'danger',
        state: 'hint',
        loading: loadingDeleteCourse,
        onClick: handleDelete
      }}
      rightFooterButton={{
        label: 'Save changes',
        state: (
          loadingFindCourseById || 
          loadingUpdateCourse || 
          loadingCreateCourse || 
          loadingDeleteCourse ) ? 'disabled' : 'default',
        loading: loadingUpdateCourse || loadingCreateCourse,
        onClick: () => refFormik.current.handleSubmit()
      }}
      bodyStyle={{ textAlign: 'right' }}
      width={540}
    >
      <Card>
        <Formik
          enableReinitialize
          innerRef={refFormik}
          validationSchema={CourseSchemaValidations}
          initialValues={dataFindCourseById?.findCourseById?.data || {
            name: '',
            holes: 0
          }}
          onSubmit={handleSubmit}
        >
          {({
            handleChange,
            values,
            errors
          }) => {
            return (
              <>
                <HorizontalTextInput
                  label="Course name"
                  value={values?.name}
                  disabled={loadingFindCourseById || loadingCreateCourse || loadingUpdateCourse}
                  onChange={handleChange('name')}
                  errorMessage={errors?.name}
                />
                <div className="spacer" />
                <HorizontalTextInput
                  label=""
                  value={values?.holes}
                  disabled={loadingFindCourseById || loadingCreateCourse || loadingUpdateCourse}
                  onChange={handleChange('holes')}
                  errorMessage={errors?.holes}
                  suffix={<Text type="secondary">hole</Text>}
                />
              </>
            );
          }}
        </Formik>
      </Card>
    </Modal>
  );
};

export default GolfCourseSettingsCourse;
