import Icon from '@ant-design/icons';
import { Col, Row } from 'antd';
import React from 'react';
import PropTypes from 'prop-types';
import Button from '../atoms/Button';
import InputText from '../atoms/InputText';
import SvgSearch from '../atoms/SvgSearch';
import SvgFilter from '../atoms/SvgFilter';
import './HeaderContent.less';

const HeaderContent = (props) => {
  const {
    buttonAdd,
    buttonFilter,
    searchInput,
    extraLeft,
    extraRight,
    content
  } = props;

  return (
    <div id="header-content">
      <Row justify="space-between" className="row-header-accounts">
        {content ? content : (
          <>
            <Col>
              <Row gutter={4}>
                {searchInput && (
                  <Col>
                    <InputText
                      {...searchInput}
                      className={['search-input', searchInput?.className].join(' ')}
                      placeholder={searchInput.placeholder}
                      onChange={searchInput.onChange}
                      prefix={<Icon component={SvgSearch} />}
                    />
                  </Col>
                )}
                {buttonFilter && (
                  <Col>
                    <Button
                      type="default"
                      icon={<Icon component={SvgFilter} />}
                      onClick={buttonFilter?.onClick}
                    >
                    Filter
                    </Button>
                  </Col>
                )}
                {extraLeft?.map((left, index) => (
                  <Col key={index}>
                    {left}
                  </Col>
                ))}
              </Row>
              <div>
              </div>
            </Col>
            <Col>
              <Row gutter={4}>
                {extraRight?.map((left, index) => (
                  <Col key={index}>
                    {left}
                  </Col>
                ))}
                {buttonAdd && <Button onClick={buttonAdd?.onClick}>{buttonAdd.label}</Button>}
              </Row>
            </Col>
          </>
        )}
      </Row>
    </div>
  );
};

export default HeaderContent;

HeaderContent.propTypes = {
  buttonAdd: PropTypes.shape({
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func
  }),
  buttonFilter: PropTypes.shape({
    onClick: PropTypes.func
  }),
  searchInput: PropTypes.shape({
    placeholder: PropTypes.string,
    onChange: PropTypes.func,
    className: PropTypes.string,
    onFocus: PropTypes.func
  }),
  extraLeft: PropTypes.arrayOf(PropTypes.node),
  extraRight: PropTypes.arrayOf(PropTypes.node),
  content: PropTypes.node
};