import { useQuery } from '@apollo/client';
import { useMemo } from 'react';
import QueryGql from '../constants/QueryGql';

export default function useGetReservation(id) {
  const { loading: loadingGetReservation, data: dataGetReservation } = useQuery(
    QueryGql.GET_RESERVATION,
    {
      variables: {
        getReservationId: id,
      },
      fetchPolicy: 'network-only',
    },
  );

  const total = useMemo(
    () => dataGetReservation?.getReservation?.data?.total_amount || 0,
    [dataGetReservation],
  );
  const status = useMemo(
    () => dataGetReservation?.getReservation?.data?.payment_status || 0,
    [dataGetReservation],
  );
  const operators = useMemo(
    () => dataGetReservation?.getReservation?.data?.reservation_operators || [],
    [dataGetReservation],
  );
  const reservation = useMemo(() => {
    if (dataGetReservation?.getReservation?.data) {
      const reservation = dataGetReservation.getReservation?.data;
      return dataGetReservation.getReservation?.data.reservation_customers
        .filter(item => item.status === 'paid')
        .map(item => ({
          id: item.customer_id,
          status: item.status,
          bag_id: item.bag_id,
          customer: {
            name: item.customer.full_name,
          },
          reservations: [
            {
              name: `${reservation.tee_times.name} - ${item.membership_name}`,
              price: item.amount,
              status: item.status,
            },
            ...item.add_ons.map(add_on => ({
              name: add_on.name,
              price: add_on.price,
              status: item.status,
            })),
          ],
        }));
    }
    return [];
  }, [dataGetReservation]);

  return {
    total,
    status,
    operators,
    reservation,
    loadingGetReservation,
    detailReservation: dataGetReservation?.getReservation?.data || {},
  };
}
