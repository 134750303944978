import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import moment from 'moment';
import { client } from '../../App';
import QueryGql from '../../constants/QueryGql';
import extractQuery from '../../utils/useQuery';
import Modal from '../organisms/Modal';
import TeeSheetReservationFormTemplate from '../templates/TeeSheetReservationForm';
import Pathname from 'constants/Pathname';

const TeeSheetReservationForm = () => {
  const history = useHistory(); 
  const location = useLocation();
  const refTemplateTeeSheetReservationForm = useRef();
  const queryString = extractQuery(location.search);
  const act = queryString.get('act');
  const date = queryString.get('date');
  const rateCardId = queryString.get('rate-card-id');
  
  const teeTimes = location.state?.optionsTeeTime;
  const courseId = location.state?.courseId;
  const time = location.state?.time;
  const startingHoleName = location.state?.startingHoleName || 'Hole #1';
  const isSelectionHoles = location.state?.isSelectionHoles;
  const idTeeTime = useMemo(() => teeTimes.filter(x => x.value === time)?.[0]?.id, []);

  let [isLoadingSaveAndCheckin, setIsLoadingSaveAndCheckin] = useState(false);

  const localReservations = useMemo(() => {
    return client.readQuery({
      query: QueryGql.GET_RESERVATIONS,
      variables: {
        getReservationsData: {
          date,
          course_id: courseId,
          statuses: [
            'no_show',
            'reserved',
            'checked_in',
            'paid'
          ]
        }
      }
    });
  }, [courseId, date]);

  const holes = useMemo(() => {
    const reservations = localReservations?.getReservations?.data;
    if (reservations) {
      return reservations.filter(reservation => reservation.created_at === '')
        .map(reservation => ({
          label: reservation.reservation_holes.name,
          value: reservation.reservation_holes.name,
        }));
    }
    return [];
  }, [localReservations]);

  const [getReservation, { loading: loadingGetReservation, data: dataGetReservation }] = useLazyQuery(
    QueryGql.GET_RESERVATION
  );

  const initialValues = useMemo(() => {
    const reservation = dataGetReservation?.getReservation.data;
    if (reservation) {
      return {
        id: reservation.id,
        date: reservation.date,
        date_tee_time: reservation.date_tee_times,
        course_id: reservation.course.id,
        tee_time_id: reservation.tee_times.id,
        rate_card_id: reservation.rate_cards.id,
        note: reservation.reservation_note,
        message: reservation.reservation_message,
        hole_name: reservation.reservation_holes?.name,
        customers: reservation?.reservation_customers?.map(x => ({
          customer_id: x.customer.id,
          name: x.customer.full_name,
          email: x.customer.email,
          phone_number: x.customer.phone_number,
          membership_id: x.membership_id,
          add_ons_id: x.add_ons.map(ax => ax.id),
          voucher_code: x.raincheck?.code ? x.raincheck.code : x.promotion?.code,
          voucher_code_data: x.raincheck?.code ? { ...x.raincheck, is_raincheck: true } : null,
          status: x.status,
          act: act,
          discount: x.discount,
        })) || []
      };
    }
    return {
      date,
      course_id: '',
      tee_time_id: '',
      rate_card_id: rateCardId,
      date_tee_time: time,
      hole_name: startingHoleName,
      note: '',
      message: '',
      customers: [
        {
          customer_id: '',
          name: '',
          email: '',
          phone_number: '',
          membership_id: null,
          add_ons_id: [],
          voucher_code: ''
        }
      ]
    };
  }, [dataGetReservation]);

  const isShowCancelReservation = initialValues?.customers?.filter(item => item.status === 'reserved').length > 0;

  const { loading: loadingSearchRateCard, data: dataSearchRateCard } = useQuery(
    QueryGql.SEARCH_RATE_CARD,
    {
      variables: {
        searchRateCardSearch: ''
      },
      fetchPolicy: 'no-cache'
    }
  );

  const [checkinReservation, { loading: loadingCheckinReservation }] = useMutation(
    QueryGql.CHECKIN_RESERVATION,
    {
      onCompleted: () => {
        history.goBack();
      },
      onError: () => {
        history.goBack();
      }
    }
  );

  const [createReservation, {
    loading: loadingCreateReservation
  }] = useMutation(QueryGql.CREATE_RESERVATION, {
    onCompleted: (res) => {
      if (res && isLoadingSaveAndCheckin === true) {
        const id = res.createReservation.data.id;
        const reservation = res.createReservation.data.reservation_customers;
        checkinReservation({
          variables: {
            checkinReservationId: id,
            checkinReservationCustomers: reservation.map(x => {
              return {
                id: x.customer_id,
                bag_id: '',
                status: 'checked_in'
              };
            }),
          },
        });
      } else {
        history?.goBack();
      }
    },
    onError: () => {},
    update: async (cache, { data: { createReservation } }) => {
      cache.modify({
        fields: {
          getReservations(existingReservations = []) {
            const newReservationsRef = cache.writeFragment({
              data: createReservation.data,
              fragment: QueryGql.FRAGMENT_RESERVATION
            });
            return {
              ...existingReservations,
              data: [
                ...existingReservations.data,
                newReservationsRef,
              ]
            };
          }
        }
      });
    }
  });

  const [updateReservation, {
    loading: loadingUpdateReservation
  }] = useMutation(QueryGql.UPDATE_RESERVATION, {
    onCompleted: () => {
      history?.goBack();
    },
    onError: () => {},
  });

  useEffect(() => {
    if (act === 'edit') {
      const id = queryString.get('id');
      getReservation({
        variables: {
          getReservationId: id
        }
      });
    }
  }, []);

  const handleSubmit = async (isAndCheckinAll) => {
    const values = await refTemplateTeeSheetReservationForm.current.handleSubmit();
    if (isAndCheckinAll === true) {
      isLoadingSaveAndCheckin = true;
      setIsLoadingSaveAndCheckin(isLoadingSaveAndCheckin);
    }
    if (values) {
      const rateCards = dataSearchRateCard.searchRateCard.data;
      const membership = rateCards.find(x => x.id === values.rate_card_id)
        .courses.find(x => x.id === values?.course_id || courseId)
        .memberships?.[0];
      let timeBasedRatesId = '';
      membership.time_based_rates.forEach(x => {
        if (moment(values.date_tee_time, 'HH:mm:ss').isBetween(moment(x.start_time, 'HH:mm:ss'), moment(x.end_time, 'HH:mm:ss')) ||
          moment(values.date_tee_time, 'HH:mm:ss').isSame(moment(x.start_time, 'HH:mm:ss')) || 
          moment(values.date_tee_time, 'HH:mm:ss').isSame(moment(x.end_time, 'HH:mm:ss'))) {
          timeBasedRatesId = x.id;
        }
      });

      values?.customers?.forEach((item, index) => {
        delete values.customers[index].act;
        delete values.customers[index].voucher_code_error;
        delete values.customers[index].voucher_code_data;
        delete values.customers[index].discount;
      });
      
      if (act === 'add') {
        createReservation({
          variables: {
            createReservationData: {
              ...values,
              time_based_rate_id: timeBasedRatesId,
              course_id: courseId,
              tee_time_id: values?.tee_time_id || idTeeTime,
              date: values.date,
            },
          },
        });
      } else {
        const id = queryString.get('id');
        // remove field customers.status
        const customerWithoutStatus = values.customers.map(x => {
          delete x?.status;
          return x;
        });
        values.customers = customerWithoutStatus;
        updateReservation({
          variables: {
            updateReservationData: {
              id,
              ...values,
              time_based_rate_id: timeBasedRatesId,
              date: values.date,
            },
          },
        });
      }
    }
  };

  const handleCancelReservation = () => {
    const id = queryString.get('id');
    history.push(
      Pathname.TEE_SHEET + '/' +
        Pathname.TEE_SHEET_CANCEL_RESERVATION+
        `?id=${id}`,
      {
        background: true
      }
    );
  };
  
  return (
    <Modal
      title={act === 'add' ? 'Add new reservation' : 'Edit reservation'}
      hiddenLeftFooterButton={act === 'edit' ? !isShowCancelReservation : false}
      leftFooterButton={act === 'edit' ?  {
        label: 'Cancel reservation',
        type: 'link',
        onClick: handleCancelReservation
      }
        : {
          label: 'Save and check-in',
          type: 'outline',
          loading: isLoadingSaveAndCheckin || loadingCheckinReservation || loadingCreateReservation,
          disabled: loadingCreateReservation,
          onClick: () => handleSubmit(true)
        }}
      rightFooterButton={{
        label: 'Save reservation',
        loading: loadingCreateReservation || loadingUpdateReservation,
        state: isLoadingSaveAndCheckin || loadingGetReservation ? 'disabled' : 'default',
        onClick: () => handleSubmit()
      }}
      width={964}
    >
      <TeeSheetReservationFormTemplate
        ref={refTemplateTeeSheetReservationForm}
        time={time}
        holes={holes}
        courseId={courseId || initialValues?.course_id}
        teeTimes={teeTimes}
        initialValues={initialValues}
        isSelectionHoles={isSelectionHoles}
        rateCards={(!loadingSearchRateCard && dataSearchRateCard?.searchRateCard?.data?.length > 0)
          ? dataSearchRateCard.searchRateCard.data.map(item => ({
            value: item.id,
            color: item.color,
            label: item.name,
            courses: item.courses,
          }))
          : []}
      />
    </Modal>
  );
};

export default TeeSheetReservationForm;