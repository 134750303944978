import React from 'react';
import { Card as AntdCard } from 'antd';
import PropTypes from 'prop-types';
import './Card.less';

const Card = (props) => {
  const { children, greenBorder } = props;
  let className = 'antd-card container';
  if (greenBorder) {
    className = 'antd-card container-greenBorder';
  }

  return (
    <AntdCard {...props} className={[className, props.className].join(' ')} greenBorder={greenBorder}>
      {children}
    </AntdCard>
  );
};

export default Card;

Card.propTypes = {
  children: PropTypes.node.isRequired,
  greenBorder: PropTypes.bool
};

Card.defaultProps = {
  greenBorder: false,
};



