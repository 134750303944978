import Icon, { DownOutlined } from '@ant-design/icons';
import Text from 'antd/lib/typography/Text';
import { PlusCircleOutlined } from '@ant-design/icons';
// import Title from 'antd/lib/typography/Title';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Col, Row, Button, Menu, Dropdown, Checkbox, Select, DatePicker } from 'antd';
import React, { useMemo, useState, forwardRef, useImperativeHandle, useRef } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useLocation } from 'react-router-dom';
import extractQuery from '../../utils/useQuery';
import Card from '../atoms/Card';
import SvgTag from '../atoms/SvgTag';
import SvgTeeTimeFilled from '../atoms/SvgTeeTimeFilled';
import SvgTeeTimes from '../atoms/SvgTeeTimes';
import IconButton from '../atoms/IconButton';
import SvgDelete from '../atoms/SvgDelete';
import HorizontalRadioButton from '../molecules/HorizontalRadioButton';
import HorizontalSelectOptions from '../molecules/HorizontalSelectOptions';
import HorizontalTimePickerRange from '../molecules/HorizontalTimePickerRange';
import HorizontalColorPicker from '../molecules/HorizontalColorPicker';
import HorizontalTextInput from '../molecules/HorizontalTextInput';
// import MultipleSelectDate from '../molecules/MultipleSelectDate';
import './CalendarTeeTimeForm.less';
import RateCardsFormTable from '../organisms/RateCardsFormTable';
import { holesShotgunName } from '../pages/TeeTimesSettings';
import ListInputAdditional from '../molecules/ListInputAdditional';

const CalendarTeeTimeParticularForm = (props, ref) => {
  const location = useLocation();
  const queryString = extractQuery(location.search);
  const act = queryString.get('act');
  const { teeTimes,
    rateCards,
    courses,
    memberships,
    inclusions,
    timeBaseRates,
    initialValues,
    onChangeDate,
    isBulk,
    disabledRangeHours } = props;
  const [tabActive, setTabActive] = useState('saved');
  const refFormik = useRef();
  const refRateCardsFormTable = useRef();
  const optionsTab = useMemo(() => [
    { label: 'Saved', value: 'saved' },
    { label: 'Custom', value: 'custom' },
  ], []);
  const optionsCustomTab = useMemo(() => [
    { label: 'Standard', value: 'standard' },
    { label: 'Crossover', value: 'crossover' },
    { label: 'Shotgun', value: 'shotgun' },
    { label: 'Custom', value: 'custom' },
  ], []);
  const optionsInclusion = useMemo(() => inclusions.map(item => ({
    value: item.id,
    label: item.name
  })), []);
  moment.updateLocale('en', {
    weekdaysMin : ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT']
  });
  const optionsTeeTime = useMemo(() => teeTimes.map(item => ({
    key: item.id,
    value: item.id,
    label: (
      <div>
        <Icon component={SvgTeeTimeFilled} style={{ color: item.color }} />
        <Text className="label-option-tee-time">{item.name}</Text>
      </div>
    )
  }))
  , [teeTimes]);
  const optionsRateCard = useMemo(() => rateCards.map(item => ({
    key: item.id,
    value: item.id,
    label: (
      <div>
        <Icon component={SvgTag} style={{ color: item.color }} />
        <Text className="label-option-tee-time">{item.name}</Text>
      </div>
    )
  }))
  , [rateCards]);
  const optionsCustomActionTeeTime = useMemo(() => (
    <Menu>
      <Menu.Item onClick={() => {
        refFormik.current.setFieldValue('new_tee_time', true);
      }}>
        <Text>Create new tee time</Text>
      </Menu.Item>
      <Menu.Item onClick={() => {
        refFormik.current.setFieldValue('new_tee_time', false);
      }}>
        <Text>Select from saved</Text>
      </Menu.Item>
    </Menu>
  ),[refFormik]);

  const optionsCustomActionRateCard = useMemo(() => (
    <Menu>
      <Menu.Item onClick={() => {
        refFormik.current.setFieldValue('new_rate_card', true);
      }}>
        <Text>Create new rate card</Text>
      </Menu.Item>
      <Menu.Item onClick={() => {
        refFormik.current.setFieldValue('new_rate_card', false);
      }}>
        <Text>Select from saved</Text>
      </Menu.Item>
    </Menu>
  ),[refFormik]);

  const CalendarTeeTimeParticularFormValidations = useMemo(() => {
    return Yup.object({
      date: isBulk ?  Yup.mixed() : Yup.mixed().required().label('Date'),
      tee_time_id: Yup.mixed().when('new_tee_time', {
        is: true,
        then: schema => schema.label('Tee time'),
        otherwise: schema => schema.required().label('Tee time')
      }),
      rate_card_id: Yup.mixed().when('new_rate_card', {
        is: true,
        then: schema => schema.label('Rate card'),
        otherwise: schema => schema.required().label('Rate card')
      }),
      start_time: Yup.mixed().label('Start time'),
      end_time: Yup.mixed().label('End time'),
      teeTime: Yup.object().when('new_tee_time', {
        is: true,
        then: schema => schema.shape({
          name: Yup.string().required().label('Tee time name'),
          start_time: Yup.mixed().label('Start time'),
          end_time: Yup.mixed().label('End time'),
          interval_time: Yup.number().min(1).required().label('Time interval'),
        }),
        otherwise: schema => schema
      }),
      rateCard: Yup.object().when('new_rate_card', {
        is: true,
        then: schema => schema.shape({
          name: Yup.string().required().label('Rate card name')
        }),
        otherwise: schema => schema
      })
    });
  }, []);

  useImperativeHandle(ref, () => ({
    handleSubmit: async () => {
      const validateForm = await refFormik.current.validateForm();
      refFormik.current.handleSubmit();
      if (Object.keys(validateForm).length === 0) {
        let values;
        const valuesForm = refFormik.current.values;
        if (tabActive === 'saved') {
          values = {
            date: valuesForm.date,
            tee_time_id: valuesForm.tee_time_id,
            rate_card_id: valuesForm.rate_card_id,
            start_time: valuesForm.start_time,
            end_time: valuesForm.end_time,
          };
        } else {
          if (valuesForm.new_tee_time) {
            values = {
              date: valuesForm.date,
              new_tee_time: valuesForm.new_tee_time,
              teeTime: valuesForm.teeTime.tee_time_types === 'shotgun'
                ? {
                  ...valuesForm.teeTime,
                  starting_holes: holesShotgunName
                } 
                : valuesForm.teeTime,
            };
          } else {
            values = {
              date: valuesForm.date,
              start_time: valuesForm.start_time,
              end_time: valuesForm.end_time,
              tee_time_id: valuesForm.tee_time_id,
              new_tee_time: valuesForm.new_tee_time,
            };
          }
          if (valuesForm?.new_rate_card) {
            const data = refRateCardsFormTable.current.handleSubmit();
            values = {
              ...values,
              rateCard: {
                ...valuesForm.rateCard,
                data: data.map(item => ({
                  course_id: item.courseId,
                  membership_id: item.membershipId,
                  time_based_rate_id: item.timeBaseRateId,
                  price: Number(item.price)
                })),
              },
              new_rate_card: valuesForm.new_rate_card
            };
          } else {
            values = {
              ...values,
              rate_card_id: valuesForm.rate_card_id,
              new_rate_card: valuesForm.new_rate_card
            };
          }
        }
        return {
          ...values,
          createType: tabActive
        };
      }
      return null;
    }
  }));

  const handleTimeChange = (id, value) => {
    refFormik.current.setFieldValue('teeTime.custom_inverval',
      refFormik.current?.values?.teeTime.custom_inverval.map((customTime, i) => {
        if (i === id) {
          return {
            ...customTime,
            time: value,
          };
        }
        return customTime;
      })
    );
  };

  return (
    <div id="calendar-tee-time-form">
      <Formik
        innerRef={refFormik}
        enableReinitialize
        validationSchema={CalendarTeeTimeParticularFormValidations}
        initialValues={{
          date: null,
          tee_time_id: null,
          rate_card_id: null,
          start_time: '',
          end_time: '',
          // specific for custom
          new_tee_time: false,
          new_rate_card: false,
          teeTime: {
            tee_time_types: 'standard',
            name: '',
            start_time: '',
            end_time: '',
            interval_time: 10,
            color: '#06884A',
            number_starting_holes: 0,
            starting_holes: [{
              name: 'Hole #1',
              value: 'Hole #1'
            }],
            custom_inverval: [
              {
                time: '00:00:00'
              },
              {
                time: '00:10:00'
              },
            ]
          },
          rateCard: {
            name: '',
            color: '#06884A',
            data: [],
            inclusions: [],
          },
          ...initialValues
        }}
      >
        {(propsFormik) => {
          const { values, errors, handleChange, setFieldValue, submitCount} = propsFormik;
          return (
            <>
              {/* <Card> */}
              <HorizontalRadioButton
                label="Slot type"
                labelInfo="Tee times can be inputted from the list you have previously saved or simply create a new one here."
                options={optionsTab}
                value={tabActive}
                onChange={(e) => {
                  setTabActive(e.target.value);
                  if (e.target.value === 'saved') {
                    setFieldValue('new_rate_card', false);
                    setFieldValue('new_tee_time', false);
                  } else {
                    setFieldValue('new_rate_card', true);
                    setFieldValue('new_tee_time', true);
                  }
                }}
              />
              {/* </Card> */}
              <div className="spacer-lg" />
              {tabActive === 'saved'
                ? (
                  <>
                    {/* <Card> */}
                    {(act !== 'edit' && !isBulk) && (
                      <>
                        <HorizontalTextInput
                          label="Date"
                          right={(
                            <DatePicker
                              size="large"
                              className="date-picker"
                              value={moment(values.date, 'YYYY-MM-DD')}
                              onSelect={value => {
                                onChangeDate(moment(value).format('YYYY-MM-DD'));
                                handleChange('date')(moment(value).format('YYYY-MM-DD'));
                              }}
                            />
                          )}
                        />
                        {(submitCount > 0 && errors.date) && <Text className="error-message" type="danger">{errors.date}</Text>}
                        <div className="spacer" />
                      </>
                    )}
                    <HorizontalSelectOptions
                      options={optionsTeeTime}
                      placeholder={(
                        <div>
                          <Icon component={SvgTeeTimes} className="icon-tee-time" />
                          <Text className="placeholder">Select tee time</Text>
                        </div>
                      )}
                      value={values.tee_time_id}
                      errorMessage={submitCount > 0 ? errors.tee_time_id : null}
                      onSelect={(value) => {
                        handleChange('tee_time_id')(value);
                        const { start_time, end_time, rate_card } = teeTimes.filter(item => item.id === value)?.[0];
                        const startTime = moment(start_time, 'h:mm:ss A');
                        const endTime = moment(end_time, 'h:mm:ss A');
                        const timeIsDisabled = disabledRangeHours.filter(hour => hour === startTime.hour() || hour === endTime.hour())?.length > 0;
                        if (!timeIsDisabled) {
                          handleChange('start_time')(start_time || '');
                          handleChange('end_time')(end_time || '');
                        }
                        handleChange('rate_card_id')(rate_card?.id || '');
                      }}
                      label="Tee time"
                    />
                    <div className="spacer" />
                    <HorizontalTimePickerRange
                      valueFrom={!values?.start_time ? null : moment(values?.start_time, 'HH:mm:ss')}
                      onChangeFrom={(e) => handleChange('start_time')(moment(e).format('HH:mm:ss'))}
                      valueTo={!values?.end_time ? null : moment(values?.end_time, 'HH:mm:ss')}
                      onChangeTo={(e) => handleChange('end_time')(moment(e).format('HH:mm:ss'))}
                      label="Time range"
                      disabledHours={disabledRangeHours}
                    />
                    {(submitCount > 0 && errors.start_time) && <Text className="error-message" type="danger">{errors.start_time}, &nbsp;&nbsp;</Text>}
                    {(submitCount > 0 && errors.end_time) && <Text className="error-message" type="danger">{errors.end_time}</Text>}
                    <div className="spacer" />
                    <HorizontalSelectOptions
                      options={optionsRateCard}
                      placeholder={(
                        <div>
                          <Icon component={SvgTag} className="icon-tee-time" />
                          <Text className="placeholder">Select rate card</Text>
                        </div>
                      )}
                      value={values.rate_card_id}
                      errorMessage={submitCount > 0 ? errors.rate_card_id : null}
                      onSelect={(value) => {
                        handleChange('rate_card_id')(value);
                      }}
                      label="Rate card"
                      labelInfo="By selecting a tee time, that tee time’s default rate card will be inserted as well, but you can change it if you want to."
                    />
                    {/* </Card> */}
                  </>
                )
                : (
                  <>
                    <Card>
                      <Row justify="space-between" align="middle">
                        <Col>
                          <Dropdown trigger={['hover', 'click']} overlay={optionsCustomActionTeeTime}>
                            <Button size="small" type="link" className="button-custom-action">
                              {values.new_tee_time
                                ? 'Create new tee time'
                                : 'Select from saved'}
                              <DownOutlined color="primary" />
                            </Button>
                          </Dropdown>
                        </Col>
                        <Col>
                          <Checkbox 
                            disabled={!values.new_tee_time}
                            checked={values.new_tee_time}
                            onChange={(e) => setFieldValue('new_tee_time', e.target.checked)}
                            className="checkbox-save-tee-time"
                          >
                            Save this tee time
                          </Checkbox>
                        </Col>
                      </Row>
                      <div className="border-divider" />
                      {values.new_tee_time
                        ? (
                          <>
                            <div className="custom-tabs">
                              <HorizontalRadioButton
                                options={optionsCustomTab}
                                value={values.teeTime.tee_time_types}
                                onChange={(e) => handleChange('teeTime.tee_time_types')(e.target.value)}
                              />
                            </div>
                            <div className="spacer" />
                            <HorizontalTextInput
                              label="Tee time name"
                              value={values.teeTime.name}
                              errorMessage={errors?.teeTime?.name}
                              onChange={(e) => handleChange('teeTime.name')(e.target.value)}
                            />
                            <div className="spacer" />
                            {values.teeTime.tee_time_types !== 'custom' && (
                              <>
                                <HorizontalTimePickerRange
                                  valueFrom={!values.teeTime.start_time ? null : moment(values.teeTime.start_time, 'HH:mm:ss')}
                                  onChangeFrom={(e) => handleChange('teeTime.start_time')(moment(e).format('HH:mm:ss'))}
                                  valueTo={!values.teeTime.end_time ? null : moment(values.teeTime.end_time, 'HH:mm:ss')}
                                  onChangeTo={(e) => handleChange('teeTime.end_time')(moment(e).format('HH:mm:ss'))}
                                  label="Time range"
                                />
                                {(submitCount > 0 && errors?.teeTime?.start_time) && <Text className="error-message" type="danger">{errors?.teeTime?.start_time}, &nbsp;&nbsp;</Text>}
                                {(submitCount > 0 && errors?.teeTime?.end_time) && <Text className="error-message" type="danger">{errors?.teeTime?.end_time}</Text>}
                                <div className="spacer" />
                              </>
                            )}
                            {(values.teeTime.tee_time_types !== 'shotgun' && values.teeTime.tee_time_types !== 'custom') && (
                              <>
                                <HorizontalTextInput
                                  label="Time interval"
                                  type="number"
                                  max={60}
                                  value={values.teeTime.interval_time}
                                  onChange={(e) => handleChange('teeTime.interval_time')(e.target.value)}
                                  suffix={<Text type="secondary" >minutes</Text>}
                                  errorMessage={errors?.teeTime?.interval_time}
                                />
                                <div className="spacer" />
                              </>
                            )}
                            <HorizontalColorPicker
                              label="Color"
                              value={values.teeTime.color}
                              onChange={e => handleChange('teeTime.color')(e.value)}
                            />
                            <div className="spacer" />
                            {values.teeTime.tee_time_types === 'crossover' && values.teeTime.starting_holes.map((hole, index) => {
                              if (index === 0) {
                                return (
                                  <Row key={index} wrap={false} align="bottom">
                                    <HorizontalTextInput
                                      value={hole.name}
                                      onChange={(e) => {
                                        const value = e.target.value;
                                        setFieldValue(`teeTime.starting_holes[${index}]`, {
                                          name: value,
                                          value: value
                                        });
                                      }}
                                      label="Hole numbers"
                                      styleContainer={{ width: 416}}
                                    />
                                    <IconButton
                                      disabled
                                      onClick={() => {
                                        values.teeTime.starting_holes.splice(index, 1);
                                        setFieldValue('teeTime.starting_holes', values.teeTime.starting_holes);
                                      }}
                                      iconSvg={SvgDelete}
                                    />
                                    <Button
                                      onClick={() => {
                                        values.teeTime.starting_holes.splice(index + 1, 0, {
                                          name: 'Hole #',
                                          value: 'Hole #'
                                        });
                                        setFieldValue('teeTime.starting_holes', values.teeTime.starting_holes);
                                      }}
                                      type="text"
                                      icon={<PlusCircleOutlined style={{ color: '#8F8F8F'}} />}
                                    />
                                  </Row>
                                );
                              }
                              return (
                                <Row key={index} wrap={false} align="bottom">
                                  <HorizontalTextInput
                                    value={hole.name}
                                    onChange={(e) => {
                                      const value = e.target.value;
                                      setFieldValue(`teeTime.starting_holes[${index}]`, {
                                        name: value,
                                        value: value
                                      });
                                    }}
                                    styleContainer={{ width: 416}}/>
                                  <IconButton
                                    onClick={() => {
                                      values.teeTime.starting_holes.splice(index, 1);
                                      setFieldValue('teeTime.starting_holes', values.teeTime.starting_holes);
                                    }}
                                    iconSvg={SvgDelete}
                                  />
                                  <Button
                                    onClick={() => {
                                      values.teeTime.starting_holes.splice(index + 1, 0, {
                                        name: 'Hole #',
                                        value: 'Hole #'
                                      });
                                      setFieldValue('teeTime.starting_holes', values.teeTime.starting_holes);
                                    }}
                                    type="text"
                                    icon={<PlusCircleOutlined style={{ color: '#8F8F8F'}} />}
                                  />
                                </Row>
                              );
                            })}
                            {values.teeTime.tee_time_types === 'custom' && (
                              <ListInputAdditional
                                label="Times"
                                componentsArr={values.teeTime.custom_inverval}
                                handleChange={handleTimeChange}
                                addNewInput={(e) => {
                                  const current = values.teeTime.custom_inverval[e];
                                  const next = values.teeTime.custom_inverval[e + 1];
                                  let time = moment(current.time, 'HH:mm:ss');
                                  const times = [...values.teeTime.custom_inverval];
                                  
                                  if (next) {
                                    const nextTime = moment(next.time, 'HH:mm:ss');
                                    const diff = nextTime.diff(time, 'minute');
                                    time = time.add(Math.floor(diff / 2), 'minute');

                                    const diffSecond = nextTime.diff(time, 'second');
                                    if (diffSecond > 1) {
                                      times.splice(e + 1, 0, { time: time.format('HH:mm:ss') });
                                    }
                                  } else {
                                    time = time.add(10, 'minute');
                                    times.push({ time: time.format('HH:mm:ss') });
                                  }

                                  setFieldValue('teeTime.custom_inverval', times);
                                }}
                                delNewInput={(e) => {
                                  setFieldValue('teeTime.custom_inverval', values.teeTime.custom_inverval.filter((_, index) => index !== parseInt(e)));
                                }}
                              />
                            )}
                          </>
                        )
                        : (
                          <div className="custom-select">
                            <HorizontalSelectOptions
                              options={optionsTeeTime}
                              placeholder={(
                                <div>
                                  <Icon component={SvgTeeTimes} className="icon-tee-time" />
                                  <Text className="placeholder">Select tee time</Text>
                                </div>
                              )}
                              value={values.tee_time_id}
                              errorMessage={submitCount > 0 ? errors.tee_time_id : null}
                              onSelect={(value) => {
                                handleChange('tee_time_id')(value);
                                const { start_time, end_time, rate_card } = teeTimes.filter(item => item.id === value)?.[0];
                                const startTime = moment(start_time, 'h:mm:ss A');
                                const endTime = moment(end_time, 'h:mm:ss A');
                                const timeIsDisabled = disabledRangeHours.filter(hour => hour === startTime.hour() || hour === endTime.hour())?.length > 0;
                                if (!timeIsDisabled) {
                                  handleChange('start_time')(start_time || '');
                                  handleChange('end_time')(end_time || '');
                                }
                                handleChange('rate_card_id')(rate_card?.id || '');
                              }}
                              label="Tee time"
                            />
                            <div className="spacer" />
                            <HorizontalTimePickerRange
                              valueFrom={!values?.start_time ? null : moment(values?.start_time, 'HH:mm:ss')}
                              onChangeFrom={(e) => handleChange('start_time')(moment(e).format('HH:mm:ss'))}
                              valueTo={!values?.end_time ? null : moment(values?.end_time, 'HH:mm:ss')}
                              onChangeTo={(e) => handleChange('end_time')(moment(e).format('HH:mm:ss'))}
                              label="Time range"
                              disabledHours={disabledRangeHours}
                            />
                            {(submitCount > 0 && errors.start_time) && <Text className="error-message" type="danger">{errors.start_time}, &nbsp;&nbsp;</Text>}
                            {(submitCount > 0 && errors.end_time) && <Text className="error-message" type="danger">{errors.end_time}</Text>}
                          </div>
                        )}
                    </Card>
                    <div className="spacer-lg" />
                    <Card>
                      <Row justify="space-between" align="middle">
                        <Col>
                          <Dropdown trigger={['hover', 'click']} overlay={optionsCustomActionRateCard}>
                            <Button size="small" type="link" className="button-custom-action">
                              {values.new_rate_card
                                ? 'Create new rate card'
                                : 'Select from saved'}
                              <DownOutlined color="primary" />
                            </Button>
                          </Dropdown>
                        </Col>
                        <Col>
                          <Checkbox
                            disabled={!values.new_rate_card}
                            checked={values.new_rate_card}
                            onChange={(e) => setFieldValue('new_rate_card', e.target.checked)}
                            className="checkbox-save-tee-time"
                          >
                            Save this rate card
                          </Checkbox>
                        </Col>
                      </Row>
                      <div className="border-divider" />
                      {values.new_rate_card
                        ? (
                          <div className="custom-select">
                            <HorizontalTextInput
                              label="Rate card name"
                              value={values.rateCard.name}
                              errorMessage={errors?.rateCard?.name}
                              onChange={(e) => handleChange('rateCard.name')(e.target.value)}
                            />
                            <div className="spacer" />
                            <HorizontalColorPicker
                              label="Color"
                              value={values.rateCard.color}
                              onChange={e => handleChange('rateCard.color')(e.value)}
                            />
                            <div className="spacer" />
                            <RateCardsFormTable
                              withoutTitle
                              ref={refRateCardsFormTable}
                              // initialValues={dataGetByIdRateCard?.getByIdRateCard.data.courses}
                              className="rate-cards-form rate-cards-form-table"
                              courses={courses}
                              memberships={memberships}
                              timeBaseRates={timeBaseRates}
                            />
                            <div className="spacer" />
                            <Text className="label-inclusions">Inclusions</Text>
                            <Select
                              placeholder="Select inclusions"
                              className="select-inclusions"
                              dropdownClassName="dropdown-inclusions"
                              size="large"
                              value={`${values.rateCard.inclusions.length === optionsInclusion.length 
                                ? 'All' 
                                : values.rateCard.inclusions.length} inclusions selected`}
                              dropdownRender={() => {
                                return (
                                  <Checkbox.Group value={values.rateCard.inclusions} onChange={(e) => setFieldValue('rateCard.inclusions', e)}>
                                    {optionsInclusion.map(item =>
                                      <Col span={24} key={item.value}>
                                        <Checkbox value={item.value}>{item.label}</Checkbox>
                                      </Col>
                                    )}
                                  </Checkbox.Group>
                                );
                              }}
                            />
                          </div>
                        )
                        : (
                          <div className="custom-select">
                            <HorizontalSelectOptions
                              options={optionsRateCard}
                              placeholder={(
                                <div>
                                  <Icon component={SvgTag} className="icon-tee-time" />
                                  <Text className="placeholder">Select rate card</Text>
                                </div>
                              )}
                              value={values.rate_card_id}
                              errorMessage={submitCount > 0 ? errors.rate_card_id : null}
                              onSelect={(value) => {
                                handleChange('rate_card_id')(value);
                              }}
                              label="Rate card"
                              labelInfo="By selecting a tee time, that tee time’s default rate card will be inserted as well, but you can change it if you want to."
                            />
                          </div>
                        )}
                    </Card>
                  </>
                )}
              {/* <div className="spacer-lg" />
              <Card>
                <Title level={4} className="title-select-date">Select the days to insert the slot</Title>
                <MultipleSelectDate
                  onChange={(values) => {
                    setFieldValue('dates', values);
                  }}
                />
              </Card> */}
            </>
          );
        }}
      </Formik>
    </div>
  );
};

export default forwardRef(CalendarTeeTimeParticularForm);

CalendarTeeTimeParticularForm.propTypes = {
  teeTimes: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    color: PropTypes.string,
    end_time: PropTypes.string,
    start_time: PropTypes.string,
    rate_card: PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      color: PropTypes.string,
      updated_at: PropTypes.string.isRequired,
      created_at: PropTypes.string.isRequired,
    })
  })).isRequired,
  rateCards: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    color: PropTypes.string,
    is_active: PropTypes.bool,
    updated_at: PropTypes.string.isRequired,
    created_at: PropTypes.string.isRequired,
  })),
  initialValues: PropTypes.object,
  onChangeDate: PropTypes.func,
  disabledRangeHours: PropTypes.arrayOf(PropTypes.number),
  isBulk: PropTypes.bool,
};

CalendarTeeTimeParticularForm.defaultProps = {
  teeTimes: [],
  rateCards: [],
  isBulk: false
};