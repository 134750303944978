import React, { useMemo, useState, useCallback, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import Promise from 'bluebird';
import Pathname from '../../constants/Pathname';
import IconButtonNavBar from '../atoms/IconButtonNavBar';
import extractQuery from '../../utils/useQuery';
import Modal from '../organisms/Modal';
import TeeSheetTakePaymentTemplate from '../templates/TeeSheetTakePayment';
import QueryGql from '../../constants/QueryGql';
import PrintReceipt from 'components/templates/PrintReceipt';
import { useReactToPrint } from 'react-to-print';

const TeeSheetTakePaymentGroup = () => {
  const history = useHistory();
  const location = useLocation();
  const queryString = extractQuery(location.search);
  const type = queryString.get('type');
  const id = queryString.get('id');
  const [customers, setCustomers] = useState([]);
  const [paymentType, setPaymentType] = useState('split');

  const printComponentRef = useRef();

  const handlePrintReceipt = useReactToPrint({
    content: () => printComponentRef.current,
  });


  const { loading: loadingGetReservationGroup, data: dataGetReservationGroup } = useQuery(
    QueryGql.GET_RESERVATION_GROUP,
    {
      variables: {
        getReservationGroupId: id,
      },
      fetchPolicy: 'network-only',
    },
  );

  const { loading: loadingGetAllPaymentMethods, data: dataGetAllPaymentMethods } = useQuery(
    QueryGql.GET_ALL_PAYMENT_METHODS,
  );

  const [createPayment, { loading: loadingCreatePayment }] = useMutation(QueryGql.CREATE_PAYMENT);

  const optionsPaymentMethods = useMemo(() => {
    return dataGetAllPaymentMethods?.getAllPaymentMethods.data.map(item => ({
      value: item.code,
      label: item.name,
    }));
  }, [dataGetAllPaymentMethods]);

  const dataReservation = useMemo(() => {
    const reservations = [];
    if (dataGetReservationGroup) {
      dataGetReservationGroup?.getReservationGroup.data.reservations.forEach(reservation => {
        reservation.reservation_customers
          .filter(customer => customer.status === 'checked_in')
          .forEach(customer => {
            reservations.push({
              ...customer,
              total_amount: customer.amount,
            });
          });
      });
    }
    return reservations;
  }, [dataGetReservationGroup]);

  const handleChange = useCallback(
    values => {
      setCustomers(
        values.map(x => {
          const reservationId = dataReservation.find(y => y.customer_id === x.id)?.reservation_id;
          return {
            ...x,
            reservation_id: reservationId,
          };
        }),
      );
    },
    [customers],
  );

  const handleChangeType = useCallback(
    value => {
      setPaymentType(value);
    },
    [paymentType],
  );

  const handleSubmit = useCallback(() => {
    const groupBy = (xs, key) => {
      return xs.reduce((rv, x) => {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
      }, {});
    };
    const varCreatePayments = [];
    const reservationsPayments = groupBy(customers, 'reservation_id');
    Object.keys(reservationsPayments).forEach(key => {
      if (reservationsPayments?.[key].length > 0) {
        varCreatePayments.push({
          variables: {
            createPaymentData: {
              payment_type: paymentType,
              reservation_id: key,
              customers: reservationsPayments[key].map(x => ({
                id: x.customer_id,
                payment_methods: x.payment_methods,
              })),
            },
          },
        });
      }
    });
    if (varCreatePayments.length > 0) {
      Promise.map(varCreatePayments, varCreatePayment => {
        // Promise.map awaits for returned promises as well.
        return createPayment(varCreatePayment);
      })
        .then(() => {
          if (type === 'raincheck') {
            history.push(
              Pathname.TEE_SHEET +
                '/' +
                Pathname.TEE_SHEET_PAYMENT_SUCCESS_GROUP +
                `?type=raincheck&id=${id}`,
              {
                background: true,
              },
            );
          } else {
            history.push(
              Pathname.TEE_SHEET + '/' + Pathname.TEE_SHEET_PAYMENT_SUCCESS_GROUP + `?id=${id}&customers=${customers.map(item => item.customer_id).join(',')}`,
              {
                background: true,
              },
            );
          }
        })
        .catch(() => {});
    } else {
      history.goBack();
    }
  }, [customers, type, paymentType]);

  // rebuild print
  const detailReservation = useMemo(() => {
    const reservation_customers = [];
    const reservation_payments = [];
    let  tee_times = {};
    let  course = {};
    let  date_tee_times = '';
    let date = '';
    const data = dataGetReservationGroup?.getReservationGroup?.data || {};

    data.reservations?.forEach(item => {
      tee_times = item.tee_times;
      date_tee_times = item.date_tee_times;
      course = item.course;
      date = item.date;

      item.reservation_customers?.forEach(rc => {
        reservation_customers.push(rc);
      });
    });

    data.reservations?.forEach(item => {
      item.reservation_payments?.forEach(rp => {
        reservation_payments.push(rp);
      });
    });

    return {
      date_tee_times,
      date,
      course,
      tee_times,
      reservation_customers,
      reservation_payments,
    };
  }, [dataGetReservationGroup]);

  return (
    <Modal
      title='Take payment'
      closeIcon={<IconButtonNavBar type='back' />}
      leftFooterButton={
        type === 'raincheck'
          ? {
            label: 'Cancel payment',
            type: 'link',
            onClick: () => history.goBack(),
          }
          : {
            label: 'Take payment later',
            type: 'outline',
            onClick: () =>
              history.replace(Pathname.TEE_SHEET, {
                background: true,
              }),
          }
      }
      rightFooterButtonExtra={
        type === 'raincheck'
          ? null
          : {
            label: 'Print receipt preview',
            type: 'outline',
            state: customers.length === 0 ? 'disabled' : 'default',
            onClick: customers.length === 0 ? null : handlePrintReceipt,
          }
      }
      rightFooterButton={{
        label: 'Take payments',
        state:
          loadingGetReservationGroup || loadingGetAllPaymentMethods || customers.length === 0
            ? 'disabled'
            : 'default',
        onClick: handleSubmit,
        loading: loadingCreatePayment,
      }}
      width={964}>
      <TeeSheetTakePaymentTemplate
        optionsPaymentMethods={optionsPaymentMethods}
        dataReservation={dataReservation}
        onChange={handleChange}
        onChangeType={handleChangeType}
      />
      <PrintReceipt
        data={{
          ...(detailReservation || {}),
          reservation_payments: customers.map(item => ({
            id: item.id,
            paid_by: item.customer_id,
            payment_methods: item.payment_methods?.map(pm => ({
              ...pm,
              name: pm.type,
            })),
          })),
        }}
        isPreview
        printComponentRef={printComponentRef}
      />
    </Modal>
  );
};

export default TeeSheetTakePaymentGroup;
