import React from 'react';
import MoleculeHorizontalTimePickerRange from '../components/molecules/HorizontalTimePickerRange';

export default {
  title: 'Molecules/Horizontal Time Picker Range',
  component: MoleculeHorizontalTimePickerRange,
};

const Template = (args) => <MoleculeHorizontalTimePickerRange {...args} />;

export const HorizontalTimePickerRange = Template.bind({});

HorizontalTimePickerRange.args = {
  label: 'Text field label'
};