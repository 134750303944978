import { Progress, Row } from 'antd';
import Title from 'antd/lib/typography/Title';
import Text from 'antd/lib/typography/Text';
import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import Card from '../atoms/Card';
import SvgCalendar from '../atoms/SvgCalendar';
import Slider from 'react-slick';

import './DashboardPrepare.less';
import { CheckCircleOutlined, ClockCircleOutlined, PlusCircleOutlined, TagOutlined } from '@ant-design/icons';
import SvgRaincheck from '../atoms/SvgRaincheck';
import 'slick-carousel/slick/slick.css'; 
import 'slick-carousel/slick/slick-theme.css';
import Pathname from '../../constants/Pathname';
import { Link } from 'react-router-dom';

DashboardPrepare.propTypes = {
  teeSheet: PropTypes.bool,
  rateCard: PropTypes.bool,
  teeTime: PropTypes.bool,
  addOns: PropTypes.bool,
  inclusions: PropTypes.bool,
  raincheck: PropTypes.bool,
};

const Item = ({ title, desc, icon, done }) => {

  return(
    <div style={{ width: 323 }} className="item">
      <Card>
        <Row style={{ marginBottom: 28 }} justify="space-between">
          {icon}
          <CheckCircleOutlined style={{ fontSize:20, color: done ? '#0D9B54' : '#C2C2C2' }} />
        </Row>
        <Text className="title" strong>{title}</Text>
        <div>
          <Text className="text">{desc}</Text>
        </div>
      </Card>
    </div>
  );
};

export default function DashboardPrepare({ teeSheet, rateCard, teeTime, addOns, inclusions, raincheck }) {
  const percent = useMemo(() => {
    let total = 0;
    if (teeSheet) {
      total += 20;
    }

    if (rateCard) {
      total += 20;
    }

    if (teeTime) {
      total += 20;
    }

    if (addOns) {
      total += 10;
    }

    if (inclusions) {
      total += 10;
    }

    if (raincheck) {
      total += 20;
    }
    return total;
  }, [teeSheet, rateCard, teeTime, addOns, inclusions, raincheck]);
  
  const items = [
    {
      done: teeTime,
      icon: <ClockCircleOutlined className="icon" />,
      title: '1. Add a tee time',
      desc: 'Add tee times for your golf course to start receiving reservations and keep track of them',
      to: Pathname.TEE_TIMES
    },
    {
      done: rateCard,
      icon: <TagOutlined style={{ transform: 'rotate(260deg)' }} className="icon" />,
      title: '2. Add a rate card',
      desc: 'Once you have your tee time, create a pricing scheme to be implemented on your tee times.',
      to: Pathname.RATE_CARDS,
    },
    {
      done: teeSheet,
      icon: <div className="svg-icon"><SvgCalendar /></div>,
      title: '3. Insert tee time slots',
      desc: 'With tee time and rate card, you can insert tee time slots to start receiving reservations.',
      to: Pathname.CALENDAR,
    },
    {
      done: addOns && inclusions,
      icon: <PlusCircleOutlined className="icon"  />,
      title: '4. Add-ons & inclusions',
      desc: 'Add add-ons and inclusions to be provided for your customers when they make reservations.',
      to: Pathname.ADD_ONS_INCLUSIONS,
    },
    {
      done: raincheck,
      icon: <div className="svg-icon"><SvgRaincheck /></div>,
      title: '5. Manage raincheck',
      desc: 'Prepare your raincheck along with its configurations for when it rains.',
      to: Pathname.RAINCHECK_SETTINGS,
    },
  ];

  const settings = {
    dots: false,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true
  };

  const [state, setState] = useState({
    clientXonMouseDown: null,
    clientYonMouseDown: null
  });

  const handleOnMouseDown = (e) => {
    setState({
      clientXonMouseDown: e.clientX,
      clientYonMouseDown: e.clientY
    });
    e.preventDefault(); // stops weird link dragging effect
  };

  const handleOnClick = (e) => {
    e.stopPropagation();
    if (state.clientXonMouseDown !== e.clientX || 
        state.clientYonMouseDown !== e.clientY) {
      // prevent link click if the element was dragged
      e.preventDefault();
    }
  };


  return(
    <div className="dashboard-prepare">
      <Title level={2}>Prepare your golf clubs and courses with Dalenta</Title>
      <div style={{ width:'calc(100% - 60px)' }}>
        <Progress percent={percent} showInfo={false} />
        <Text className="text">{percent}% Done</Text>
      </div>
      <div style={{ marginTop: 24, width:'calc(100% - 20px)' }}>
        <Slider {...settings}>
          {
            items.map(item => (
              <Link
                key={item.title}
                to={item.to}
                onMouseDown={e => handleOnMouseDown(e)}
                onClick={e => handleOnClick(e)}
              >
                <Item  {...item} />
              </Link>
            ))
          }
        </Slider>
      </div>
    </div>
  );
}