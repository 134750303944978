class Pathname {
  static TEE_SHEET = '/tee-sheet';
  static TEE_SHEET_RESERVATION_FORM = 'tee-sheet-reservation-form';
  static TEE_SHEET_RESERVATION_GROUP_FORM = 'tee-sheet-reservation-group-form';
  static TEE_SHEET_RESERVATION_DETAIL = 'tee-sheet-reservation-detail';
  static TEE_SHEET_RESERVATION_GROUP_DETAIL = 'tee-sheet-reservation-group-detail';
  static TEE_SHEET_TAKE_PAYMENT = 'tee-sheet-take-payment';
  static TEE_SHEET_TAKE_PAYMENT_GROUP = 'tee-sheet-take-payment-group';
  static TEE_SHEET_PAYMENT_SUCCESS = 'tee-sheet-payment-success';
  static TEE_SHEET_PAYMENT_SUCCESS_GROUP = 'tee-sheet-payment-success-group';
  static TEE_SHEET_CUSTOMER_CHECKIN_DETAIL = 'tee-sheet-customer-checkin-detail';
  static TEE_SHEET_CUSTOMERS_CHECKIN_DETAIL = 'tee-sheet-customers-checkin-detail';
  static TEE_SHEET_CUSTOMERS_CHECKIN_GROUP_DETAIL = 'tee-sheet-customers-checkin-group-detail';
  static TEE_SHEET_BLOCK_TEE_TIMES = 'tee-sheet-block-tee-times';
  static TEE_SHEET_HOLES_FORM = 'tee-sheet-holes-form';
  static TEE_SHEET_HOLES_GROUP_FORM = 'tee-sheet-holes-group-form';
  static TEE_SHEET_RAINCHECK = 'tee-sheet-raincheck';
  static TEE_SHEET_EDIT_CART_SIGNS = 'tee-sheet-edit-cart-signs';
  static TEE_SHEET_RAINCHECK_ISSUE = 'tee-sheet-raincheck-issue';
  static TEE_SHEET_RAINCHECK_GROUP_ISSUE = 'tee-sheet-raincheck-group-issue';
  static TEE_SHEET_CANCEL_RESERVATION = 'tee-sheet-cancel-reservation';
  static COLLECT_CANCELLATION_FEE = 'collect-cancellation-fee';

  static CALENDAR = '/calendar';
  static CALENDAR_TEE_TIME_FORM = 'calendar-tee-time-form';
  static CALENDAR_TEE_TIME_PARTICULAR_FORM = 'calendar-tee-time-particular-form';
  static CALENDAR_TEE_TIME_PARTICULAR_DELETE = 'calendar-tee-time-particular-delete';
  static CALENDAR_DAILY_VIEW = 'calendar-daily-view';

  static RATE_CARDS = '/rate-cards';
  static RATE_CARDS_FORM = 'rate-cards-form';
  static RATE_CARDS_DELETE = 'rate-cards-delete';
  static RATE_CARDS_CANCEL_ACTION = 'rate-cards-cancel';
  static RATE_CARDS_SELECT_INCLUSIONS = '/rate-cards-select-inclusions';
  static RATE_CARDS_FORM_INCLUSIONS = '/rate-cards-form-inclusions';

  static TEE_TIMES = '/tee-times';
  static TEE_TIMES_SETTINGS = 'tee-times-settings';

  static CUSTOMERS = '/customers';

  static CUSTOMER_LIST = '/customer-list';
  static CUSTOMER_LIST_FORM = 'customer-list-form';
  static CUSTOMER_LIST_EDIT = '/customer-list-edit';
  static CUSTOMER_LIST_DELETE = 'customer-list-delete';
  static CUSTOMER_LIST_DETAIL = 'customer-list-detail';
  static CUSTOMER_LIST_DETAIL_RESERVATION = 'customer-detail-reservation';
  static CUSTOMER_LIST_FILTER = 'customer-list-filter';
  static CUSTOMER_LIST_FILTER_TYPE = 'customer-list-filter/type';
  static CUSTOMER_LIST_FILTER_NATIONALITY = 'customer-list-filter/nationality';
  static CUSTOMER_LIST_FILTER_GENDER = 'customer-list-filter/gender';
  static CUSTOMER_LIST_FILTER_CREDIT_CARD = 'customer-list-filter/credit-card';
  static CUSTOMER_LIST_FILTER_BIRTHDAY = 'customer-list-filter/birthday';
  static CUSTOMER_LIST_IMPORT = 'customer-list-import';
  static CUSTOMER_LIST_EXPORT = 'customer-list-export';
  static CUSTOMER_LIST_IMPORT_CONFIRM = '/customer-list-import-confirm';

  static TYPES_MEMBERSHIP = '/types-membership';
  static TYPES_MEMBERSHIP_CREATE = 'types-membership-create';
  static TYPES_MEMBERSHIP_MEMBER = 'types-membership-member';
  static TYPES_MEMBERSHIP_DELETE = 'types-membership-delete';
  static TYPES_MEMBERSHIP_MEMBER_DETAIL = 'member-detail';
  static TYPES_MEMBERSHIP_MEMBER_DETAIL = 'member-detail';

  static FEEDBACKS = '/feedbacks';

  static PROMOTIONS = '/promotions';

  static REPORTS = '/reports';

  static CUSTOMER_CHECK_INS = '/customer-check-ins';
  static EXPORT_CUSTOMER_CHECK_INS = '/export-customer-check-ins';
  static EXPORT_CANCELLATION_REPORT = '/export-cancellation-report';
  static RESERVATION_DETAIL = '/reservation-detail';
  static SEND_EMAIL = '/send-email';
  static VOID_CHECK_IN = '/void-check-in';

  static RESERVATIONS_REPORT = '/reservations-report';
  static EXPORT_RESERVATIONS_REPORT = 'export-reservations-report';

  static REVENUE_REPORT = '/revenue-report';
  static EXPORT_REVENUE_REPORT = 'export-revenue-report';

  static CANCELATION_REPORT = '/cancellation-report';

  static PROMOTIONS_USAGE = '/promotions-usage';

  static RAINCHECK_LIST = '/raincheck-list';
  static RAINCHECK_DETAIL = '/raincheck-detail';
  static VOID_RAINCHECK = '/void-raincheck';
  static REDEEM_RAINCHECK = '/redeem-raincheck';
  static PRINT_RAINCHECK = '/print-raincheck';
  static EXPORT_RAINCHECK_LIST = '/export-raincheck-list';

  static BILLING_STATEMENTS = '/billing-statements';

  static SETTINGS = '/settings';
  static COURSE_PROFILE = '/course-profile';

  static GOLF_COURSE_SETTINGS = '/golf-cource-settings';
  static GOLF_COURSE_SETTINGS_CLUB = 'club';
  static GOLF_COURSE_SETTINGS_COURSE = 'course';
  static GOLF_COURSE_SETTINGS_TIME = 'time';
  static GOLF_COURSE_SETTINGS_PLAYER = 'player';
  static CANCELLATION_POLICY = 'cancellation-policy';

  static ADD_ONS_INCLUSIONS = '/add-ons-inclusions';
  static ADD_ONS_INCLUSIONS_ADD_ONS = 'add-ons';
  static ADD_ONS_INCLUSIONS_INCLUSIONS = 'inclusions';

  static RAINCHECK_SETTINGS = '/raincheck-settings';
  static RAINCHECK_SETTINGS_DURATION = 'duration';
  static RAINCHECK_SETTINGS_TERM_CONDITION = 'term-condition';
  static RAINCHECK_SETTINGS_LOWEST_PRICE = 'lowest-price';

  static ROLE_ACCESS = '/role-access';
  static ROLE_ACCESS_ACCOUNTS = '/accounts';
  static ROLE_ACCESS_ACCOUNTS_SETTINGS = 'accounts-settings';
  static ROLE_ACCESS_SETTINGS = 'role-access-settings';
  static ROLE_ACCESS_ROLES = '/roles-and-permission';

  static LOGIN = '/login';

  static SPLASH = '/splash';

  static HOME = '/';
}

export default Pathname;
