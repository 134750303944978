import React, { useCallback, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import IconButtonNavBar from '../atoms/IconButtonNavBar';
import HorizontalTextInput from '../molecules/HorizontalTextInput';
import Modal from '../organisms/Modal';

const RateCardsCreateInclusions = () => {
  const history = useHistory();
  const location = useLocation();
  const [valueName, setValueName] = useState('');
  const onAdded = location.state?.onAdded;

  const handleChangeName = useCallback((e) => {
    const name = e.target.value;
    setValueName(name);
  }, []);

  const handleAdded = useCallback(() => {
    onAdded({label: valueName, checked: false});
    history?.goBack();
  }, []);

  return (
    <Modal
      title="Add new inclusion"
      closeIcon={<IconButtonNavBar type="back" />}
      hiddenLeftFooterButton
      rightFooterButton={{
        label: 'Save inclusion',
        state: valueName === '' ? 'disabled' : 'default',
        onClick: handleAdded
      }}
      bodyStyle={{ textAlign: 'right' }}
      width={540}
    >
      <HorizontalTextInput
        label="Inclusion name"
        value={valueName}
        onChange={handleChangeName}
      />
    </Modal>
  );
};

export default RateCardsCreateInclusions;
