import React, { useMemo, useState } from 'react';
import { Menu, Dropdown } from 'antd';
import Text from 'antd/lib/typography/Text';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import Pathname from '../../constants/Pathname';
import SvgMoreOutline from '../atoms/SvgMoreOutline';
import IconButton from '../atoms/IconButton';
import MultipleSelect from '../molecules/MultipleSelect';
import './AccountsTabs.less';
import TableContent from '../templates/TableContent';
import { useQuery, useMutation } from '@apollo/client';
import QueryGql from '../../constants/QueryGql';

const AccountsTabs = () => {
  const history = useHistory();
  const [searchValue, setSearchValue] = useState('');
  const [rolesValue, setRolesValue] = React.useState([]);

  const searchAccounts = (e) => {
    setSearchValue(e.target.value);
  };

  const handleRolesFilter = (e) => {
    setRolesValue(e);
  };

  let objVariables = {
    search: searchValue
  };

  if (rolesValue.length > 0) {
    if (rolesValue.includes('all')) {
      objVariables = {
        search: searchValue,
      };
    } else {
      objVariables = {
        search: searchValue,
        roles: rolesValue
      };
    }
  }

  const { 
    loading: loadingGetAllRoles,
    data: dataGetAllRoles = {},
  } = useQuery(QueryGql.GET_ALL_ROLES);

  const {
    loading: loadingSearchAccount,
    data: dataSearchAccount,
    refetch: refetchGetAllAccount,
  } = useQuery(QueryGql.SEARCH_ACCOUNT, {
    variables: objVariables,
  });

  const [deleteAccount] = useMutation(QueryGql.DELETE_ACCOUNT, {
    onCompleted: () => {
      refetchGetAllAccount();
    },
    onError: () => {
    }
  });

  const data = useMemo(() => {
    return dataSearchAccount?.searchAccount?.data?.map(item => ({
      key: item.id,
      role_name: item.role.name,
      ...item
    }));
  }, [dataSearchAccount]);

  const roles = useMemo(() => {
    const getAllRoles = dataGetAllRoles?.getAllRoles?.data?.map(item => ({
      key: item.id,
      value: item.code,
      label: item.name
    })) || [];
    return [
      { value: 'all', key: 1, label: 'All role access' },
      ...getAllRoles
    ];
  }, [dataGetAllRoles]);

  const menu = (id) => {
    return (
      <Menu>
        <Menu.Item
          key="1"
          onClick={() => history.push(
            Pathname.ROLE_ACCESS + '/' +
            Pathname.ROLE_ACCESS_ACCOUNTS_SETTINGS +
            `?act=edit&id=${id}`,
            {
              background: true
            }
          )}
        >
          <EditOutlined /> Edit account
        </Menu.Item>
        <Menu.Item
          key="2"
          className="second-menu-edit"
          onClick={() => deleteAccount({
            variables: {
              deleteAccountId: id
            }
          })}
        >
          <DeleteOutlined /> Remove account
        </Menu.Item>
      </Menu>
    );
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'full_name',
      render: text => <Text type="primary" >{text}</Text>,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      render: text => <Text type="primary" >{text}</Text>,
    },
    {
      title: 'Role access',
      dataIndex: 'role_name',
      render: text => <Text type="primary" >{text}</Text>,
    },
    {
      title: 'Last active',
      dataIndex: 'last_activity',
      render: text => <Text type="primary" >{text}</Text>,
    },
    {
      title: '',
      dataIndex: 'action',
      align: 'right',
      render: (text, record) => {
        return (
          <Dropdown trigger={['hover', 'click']} overlay={menu(record.id)}>
            <div><IconButton iconSvg={SvgMoreOutline} iconColor="primary" /></div>
          </Dropdown>
        );
      },
    },
  ];

  return (
    <>
      <TableContent
        columns={columns}
        data={data}
        loading={loadingSearchAccount || loadingGetAllRoles}
        buttonAdd={{
          label: 'Add new account',
          onClick: () => history.push(
            Pathname.ROLE_ACCESS + '/' +
            Pathname.ROLE_ACCESS_ACCOUNTS_SETTINGS +
            '?act=add',
            {
              background: true
            }
          )
        }}
        searchInput={{
          placeholder: 'Search account',
          onChange: searchAccounts,
          loading: loadingSearchAccount
        }}
        extraLeft={[<MultipleSelect key="select-role" handleChange={handleRolesFilter} defaultValue="All Roles" items={roles} />]}
      />
    </>
  );
};

export default AccountsTabs;