import { useQuery, useMutation } from '@apollo/client';
import { Button, Switch } from 'antd';
import Text from 'antd/lib/typography/Text';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Pathname from '../../constants/Pathname';
import QueryGql from '../../constants/QueryGql';
import ButtonCustom from '../atoms/Button';
import Loading from '../atoms/Loading';
import CardWithTitle from '../molecules/CardWithTitle';
import HorizontalCardInput from '../molecules/HorizontalCardInput';
import AppWrapper from '../templates/AppWrapper';
import './GolfCourseSettings.less';

const GolfCourseSettings = () => {
  const history = useHistory();
  const { loading: loadingGetAllCourses, data: dataGetAllCourses } = useQuery(
    QueryGql.GET_ALL_COURSES,
  );

  

  const {
    loading: loadingGetAutoSend,
    data: dataGetAutoSend,
    refetch: refectGetAutoSend,
  } = useQuery(QueryGql.GET_SETTING_AUTO_SEND);

  const [setAutoSendReservation] = useMutation(QueryGql.CREATE_SETTING_AUTO_SEND, {
    onCompleted: () => {
      refectGetAutoSend();
    },
  });
  const { data: dataGetClubName, refetch: refetchDataGetClubName } = useQuery(QueryGql.GET_CLUB_NAME);

  const { data: dataMaximumPlayer } = useQuery(QueryGql.GET_MAXIMUM_PLAYER);
  const maximumPlayers = dataMaximumPlayer?.getMaximumPlayers.data.value;

  const { data: dataCancelationPolicy } = useQuery(QueryGql.GET_CANCELATION_POLICY, { fetchPolicy: 'cache-and-network' });
  const cancellationPolicy = dataCancelationPolicy?.getCancellationPolicy?.data?.cancellation_policy;

  const { loading: loadingGetAllTimeBaseRate, data: dataGetAllTimeBaseRate } = useQuery(
    QueryGql.GET_ALL_TIME_BASE_RATE,
  );

  const handleSwicthAuto = (key, value) => {
    setAutoSendReservation({
      variables: {
        setAutoSendReservationInput: [
          {
            key: key,
            value: value,
          },
        ],
      },
    });
  };
  useEffect(() => {
    refetchDataGetClubName();
  }, []);

  return (
    <AppWrapper>
      <div className="golf-cource-settings-container">
        <CardWithTitle
          title="Golf courses"
          subtitle={
            'Insert the name of your golf course and the courses available for players to\nchoose from.'
          }
          className="golf-cource-settings-card">
          <div className="golf-cource-settings">
            {loadingGetAllCourses ? (
              <Loading />
            ) : (
              <>
                <HorizontalCardInput
                  label="Golf club name"
                  width="100%"
                  left={<Text className="value">{dataGetClubName?.getClubName?.data?.value}</Text>}
                  right={
                    <Button
                      type="link"
                      onClick={() =>
                        history.push(
                          Pathname.GOLF_COURSE_SETTINGS + '/' + Pathname.GOLF_COURSE_SETTINGS_CLUB,
                          {
                            background: true,
                          },
                        )
                      }>
                      Edit
                    </Button>
                  }
                />
                <div className="spacer" />
                {dataGetAllCourses?.getAllCourses?.data?.map((item, index) => {
                  if (index === 0) {
                    return (
                      <div key={item.id}>
                        <HorizontalCardInput
                          label="List of courses"
                          width={516}
                          left={
                            <Text className="value">
                              {item.name} ({item.holes} hole)
                            </Text>
                          }
                          right={
                            <Button
                              type="link"
                              onClick={() =>
                                history.push(
                                  Pathname.GOLF_COURSE_SETTINGS +
                                    '/' +
                                    Pathname.GOLF_COURSE_SETTINGS_COURSE +
                                    '?act=edit&id=' +
                                    item.id,
                                  {
                                    background: true,
                                  },
                                )
                              }>
                              Edit
                            </Button>
                          }
                        />
                        <div className="spacer" />
                      </div>
                    );
                  }
                  return (
                    <div key={item.id}>
                      <HorizontalCardInput
                        // label=""
                        width={516}
                        left={
                          <Text className="value">
                            {item.name} ({item.holes} hole)
                          </Text>
                        }
                        right={
                          <Button
                            type="link"
                            onClick={() =>
                              history.push(
                                Pathname.GOLF_COURSE_SETTINGS +
                                  '/' +
                                  Pathname.GOLF_COURSE_SETTINGS_COURSE +
                                  '?act=edit&id=' +
                                  item.id,
                                {
                                  background: true,
                                },
                              )
                            }>
                            Edit
                          </Button>
                        }
                      />
                      <div className="spacer" />
                    </div>
                  );
                })}
                <ButtonCustom
                  type="guide"
                  state="text"
                  size="large"
                  className="button-add-setting"
                  onClick={() =>
                    history.push(
                      Pathname.GOLF_COURSE_SETTINGS +
                        '/' +
                        Pathname.GOLF_COURSE_SETTINGS_COURSE +
                        '?act=add',
                      {
                        background: true,
                      },
                    )
                  }>
                  + Add new course
                </ButtonCustom>
              </>
            )}
          </div>
        </CardWithTitle>
        <CardWithTitle
          title="Cancellation policy"
          subtitle={
            'This policy will be used to add a policy text that will be sent to the customer email when canceling an reservation'
          }
          className="golf-cource-settings-card">
          <div className="golf-cource-settings">
            <HorizontalCardInput
              width={516}
              left={<Text className="value">{cancellationPolicy}</Text>}
              right={
                <Button
                  type="link"
                  onClick={() =>
                    history.push(
                      Pathname.GOLF_COURSE_SETTINGS + '/' + Pathname.CANCELLATION_POLICY,
                      {
                        background: true,
                      },
                    )
                  }>
                  Edit
                </Button>
              }
            />
          </div>
        </CardWithTitle>
        <CardWithTitle
          title="Maximum number of players"
          subtitle={
            'By international standards, there can only be 4 players per flight. However, you\ncan allow your courses to accept more should it be necessary.'
          }
          className="golf-cource-settings-card">
          <div className="golf-cource-settings">
            <HorizontalCardInput
              label="Maximum players"
              width={516}
              left={<Text className="value">{maximumPlayers} players</Text>}
              right={
                <Button
                  type="link"
                  onClick={() =>
                    history.push(
                      Pathname.GOLF_COURSE_SETTINGS + '/' + Pathname.GOLF_COURSE_SETTINGS_PLAYER,
                      {
                        background: true,
                      },
                    )
                  }>
                  Edit
                </Button>
              }
            />
          </div>
        </CardWithTitle>
        <CardWithTitle
          title="Time-based rates"
          subtitle={
            'Provide flexible rate cards based on the specific times for your golf courses. For\nexample. Twilight rates: 11:00 AM - 02:00 PM.'
          }
          className="golf-cource-settings-card">
          <div className="golf-cource-settings">
            {loadingGetAllTimeBaseRate ? (
              <Loading />
            ) : (
              <>
                {dataGetAllTimeBaseRate?.getAllTimeBaseRate?.data.map((item, index) => {
                  if (index === 0) {
                    return (
                      <>
                        <HorizontalCardInput
                          label="List of time-based rates"
                          width={516}
                          left={
                            <div className="time-based">
                              <Text className="value">{item.name}</Text>
                              <Text type="secondary">
                                {item.start_time} - {item.end_time}
                              </Text>
                            </div>
                          }
                          right={
                            <Button
                              type="link"
                              onClick={() =>
                                history.push(
                                  Pathname.GOLF_COURSE_SETTINGS +
                                    '/' +
                                    Pathname.GOLF_COURSE_SETTINGS_TIME +
                                    '?act=edit&id=' +
                                    item.id,
                                  {
                                    background: true,
                                  },
                                )
                              }>
                              Edit
                            </Button>
                          }
                        />
                        <div className="spacer" />
                      </>
                    );
                  }
                  return (
                    <div key={index}>
                      <HorizontalCardInput
                        width={516}
                        left={
                          <div className="time-based">
                            <Text className="value">{item.name}</Text>
                            <Text type="secondary">
                              {item.start_time} - {item.end_time}
                            </Text>
                          </div>
                        }
                        right={
                          <Button
                            type="link"
                            onClick={() =>
                              history.push(
                                Pathname.GOLF_COURSE_SETTINGS +
                                  '/' +
                                  Pathname.GOLF_COURSE_SETTINGS_TIME +
                                  '?act=edit&id=' +
                                  item.id,
                                {
                                  background: true,
                                },
                              )
                            }>
                            Edit
                          </Button>
                        }
                      />
                      <div className="spacer" />
                    </div>
                  );
                })}
              </>
            )}

            <ButtonCustom
              type="guide"
              state="text"
              size="large"
              className="button-add-setting-time-based"
              onClick={() =>
                history.push(
                  Pathname.GOLF_COURSE_SETTINGS +
                    '/' +
                    `${Pathname.GOLF_COURSE_SETTINGS_TIME}?act=add`,
                  {
                    background: true,
                  },
                )
              }>
              + Add new time-based rate
            </ButtonCustom>
          </div>
        </CardWithTitle>
        <CardWithTitle
          title="Auto send reservation confirmation"
          subtitle={
            'Once a reservation has been made, send a confirmation message either through\nEmail or WhatsApp to your customers (if provided within the details).'
          }
          className="golf-cource-settings-card last">
          {loadingGetAutoSend ? (
            <Loading />
          ) : (
            <div className="golf-cource-settings">
              {dataGetAutoSend?.getAutoSendReservation?.data?.map((item, index) => {
                return (
                  <span key={index}>
                    <HorizontalCardInput
                      width={710}
                      left={
                        <Text className="value">
                          {item.key === 'auto_send_by_whatsapp'
                            ? 'Auto send by WhatsApp'
                            : 'Auto send by Email'}
                        </Text>
                      }
                      right={
                        <Switch
                          checked={item.value !== '0'}
                          onChange={() => handleSwicthAuto(item.key, item.value === '0' ? 1 : 0)}
                        />
                      }
                    />
                    <div className="spacer" />
                  </span>
                );
              })}
            </div>
          )}
        </CardWithTitle>
      </div>
    </AppWrapper>
  );
};

export default GolfCourseSettings;
