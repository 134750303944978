import { featuresUserFromLocal } from './function';

const checkFeature = ({ 
  // featureCode, 
  minAccess, 
  featureCategoryCode 
}) => {
  const featuresUser = featuresUserFromLocal();
  let isGrantAccess = false;
  // FOR SPECIFIC FEATURE
  // const activeFeatures = featuresUser?.filter(feature => feature.code === featureCode);
  // activeFeatures?.forEach(activeFeature => {
  //   if (minAccess === 'is_editable') {
  //     if (activeFeature.is_editable) {
  //       isGrantAccess = true;
  //     }
  //   }
  //   if (minAccess === 'is_see_only') {
  //     if (activeFeature.is_see_only || activeFeature.is_editable) {
  //       isGrantAccess = true;
  //     }
  //   }
  // });
  // FOR CATEGORY FEATURE
  const activeFeatures = featuresUser?.filter(feature => {
    return feature.category_code === featureCategoryCode;
  });
  activeFeatures?.forEach(activeFeature => {
    if (minAccess === 'is_editable') {
      if (activeFeature.is_editable) {
        isGrantAccess = true;
      }
    }
    if (minAccess === 'is_see_only') {
      if (activeFeature.is_see_only || activeFeature.is_editable) {
        isGrantAccess = true;
      }
    }
  });
  return isGrantAccess;
};

export default checkFeature;