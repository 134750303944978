import React, { useMemo } from 'react';
import { DownOutlined } from '@ant-design/icons';
import HorizontalTextInput from '../molecules/HorizontalTextInput';
import { Button, Dropdown, Menu } from 'antd';

import PropTypes from 'prop-types';

const FileSelectExport = ({ value, onChange, items }) => {

  const handleSelect = (item) => {
    onChange({
      ...value,
      ...item,
    });
  };

  const fileTypes = useMemo(() => (
    <Menu>
      {
        items.map(item => (
          <Menu.Item key={item.key} onClick={() => handleSelect(item)}>
            {item.label}
          </Menu.Item>
        ))
      }
    </Menu>
  ), []);

  const handleChange = (e) => {
    onChange({
      ...value,
      name: e.target.value
    });
  };

  return (
    <HorizontalTextInput
      label="File name"
      onChange={handleChange}
      value={value?.name}
      suffix={(
        <Dropdown trigger={['hover', 'click']} overlay={fileTypes}>
          <Button type="link" size="small">
            {value?.label} <DownOutlined />
          </Button>
        </Dropdown>
      )} />
  );
};

FileSelectExport.propTypes = {
  value: PropTypes.objectOf(PropTypes.shape({
    type: PropTypes.string,
    name: PropTypes.string,
  })),
  onSelect: PropTypes.func
};

FileSelectExport.defaultProps = {
  value: {
    name: 'File',
    label: 'PDF'
  },
  items: [
    {
      key: 'pdf',
      label: 'PDF'
    },
    {
      key: 'csv',
      label: 'CSV'
    }
  ]
};

export default FileSelectExport;