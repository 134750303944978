import { notification, Button } from 'antd';
import React from 'react';

export default function useNotification() {
  const successCreate = ({ title, name }) => {
    const key = `open${Date.now()}`;
    notification.success({
      message: `${title} successfully added`,
      description: `${name} has been successfully added to the ${title} list.`,
      btn: <Button type="text" className="notification-button-dismiss-success" onClick={() => notification.close(key)}>Dismiss</Button>,
      key,
      closeIcon: <div />,
      placement: 'bottomLeft'
    });
  };

  const successRemove = ({ title, name }) => {
    const key = `open${Date.now()}`;
    notification.success({
      message: `${title} successfully deleted`,
      description: `${name} has been successfully removed from the ${title} list.`,
      btn: <Button type="text" className="notification-button-dismiss-info" onClick={() => notification.close(key)}>Dismiss</Button>,
      key,
      closeIcon: <div />,
      placement: 'bottomLeft'
    });
  };

  const successCancelReservation = ({ description }) => {
    const key = `open${Date.now()}`;
    notification.info({
      message: 'Reservation has been cancelled',
      description: description,
      btn: <Button type="text" className="notification-button-dismiss-info" onClick={() => notification.close(key)}>Dismiss</Button>,
      key,
      closeIcon: <div />,
      placement: 'bottomLeft'
    });
  };

  return {
    successCreate,
    successRemove,
    successCancelReservation
  };
}