import KeyStorage from '../constants/KeyStorage';

export const authToLocal = (data) => {
  localStorage.setItem(KeyStorage.AUTH, JSON.stringify(data));
};

export const authFromLocal = () => {
  const auth = localStorage.getItem(KeyStorage.AUTH);
  return JSON.parse(auth);
};

export const featuresUserToLocal = (data) => {
  localStorage.setItem(KeyStorage.FEATURES_USER, JSON.stringify(data));
};

export const featuresUserFromLocal = () => {
  const featuresUser = localStorage.getItem(KeyStorage.FEATURES_USER);
  return JSON.parse(featuresUser);
};