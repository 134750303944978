import React from 'react';
import Modal from '../organisms/Modal';
import QueryGql from 'constants/QueryGql';
import { useQuery } from '@apollo/client';
import CustomerRadio from '../../components/templates/CustomerExportRadio';
import jsPDF from 'jspdf';
import { useHistory } from 'react-router-dom';
import { useState, useContext } from 'react';
import { AppContext } from 'App';
import KeyStorage from 'constants/KeyStorage';
import { downloadCSV } from '../../utils/csv';

import CustomerExport from 'components/templates/CustomerExport';

const CustomerListExport = () => {
  const [file, setFile] = useState(['PDF']);
  const [valueSpecific, setValueSpecific] = useState([]);
  const [valueAll, setValueAll] = useState('all_customer');
  const [name, setName] = useState('File');
  const history = useHistory();

  const { refetch } = useQuery(QueryGql.SEARCH_CUSTOMER, {
    skip: true,
  });
  const useFilterBuilder = () => {
    const { actions } = useContext(AppContext);
    const existence = localStorage.getItem(KeyStorage.FILTER_CUSTOMER) || null;

    const applyFilter = () => {
      actions.createCustomerFilter(JSON.parse(existence));
    };

    const resetFilter = () => {
      localStorage.removeItem(KeyStorage.FILTER_CUSTOMER);

      actions.createCustomerFilter({
        customer_types: null,
        birthday: {
          from: null,
          to: null,
        },
        nationality: null,
        gender: null,
      });
    };

    const createFilter = (field, value) => {
      const data = { ...JSON.parse(existence), [field]: value };
      localStorage.setItem(KeyStorage.FILTER_CUSTOMER, JSON.stringify(data));
    };

    return { createFilter, resetFilter, applyFilter };
  };
  const { createFilter } = useFilterBuilder();
  const filter = () => {
    createFilter('customer_types', valueAll === 'all_customer' ? null : valueSpecific);
    history.goBack();
  };

  async function handleExport() {
    const res = await refetch({
      filter: {
        customer_types: valueAll === 'specific_customer' ? valueSpecific : null,
      },
    });

    const dataUser = res?.data?.searchCustomer?.data;

    const body = dataUser.map(item => {
      return {
        name: item.full_name,
        type: item.subscription_memberships[0].membership_name,
        phone: item.prefix_phone_number + item.phone_number,
        email: item.email,
      };
    });
    if (file == 'CSV') {
      const headers = [
        { label: 'Customer name', key: 'name' },
        { label: 'Type / membership', key: 'type' },
        { label: 'Phone number', key: 'phone' },
        { label: 'Email', key: 'email' },
      ];
      downloadCSV({ headers, body, name: name });
      return false;
    }

    const doc = new jsPDF('p', 'mm', 'a4');
    doc.autoTable({
      headStyles: {
        fillColor: [245, 245, 245],
        textColor: [0, 13, 35],
        fontStyle: 'bold',
        minCellHeight: 12,
        valign: 'middle',
        cellPadding: { left: 2 },
      },
      alternateRowStyles: {
        fillColor: [255, 255, 255],
        cellPadding: { left: 2, top: 2, bottom: 2 },
      },
      margin: { top: 5, left: 5, right: 5 },
      columns: [
        { header: 'Customer name', dataKey: 'name' },
        { header: 'Type / membership', dataKey: 'type' },
        { header: 'Phone number', dataKey: 'phone' },
        { header: 'Email', dataKey: 'email' },
      ],
      body,
    });
    doc.save(`${name}.pdf`);
  }

  return (
    <Modal
      title='Export customers'
      leftFooterButton={{
        label: 'Cancel',
        state: 'hint',
      }}
      rightFooterButton={{
        label: 'Export customers',
        onClick: () => {
          handleExport();
          filter();
        },
      }}
      width={540}>
      <CustomerExport setName={setName} file={file} setFile={setFile} name={name} />
      <CustomerRadio
        valueSpecific={valueSpecific}
        setValueSpecific={setValueSpecific}
        valueAll={valueAll}
        setValueAll={setValueAll}
      />
    </Modal>
  );
};

export default CustomerListExport;
