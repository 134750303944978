import React from 'react';

function SvgEmptyFeedback() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="408"
      height="308"
      fill="none"
      viewBox="0 0 408 308"
    >
      <g clipPath="url(#clip0)">
        <path
          fill="#859DFF"
          d="M379.969 126.727H0v180.281h379.969V126.727z"
        ></path>
        <path
          fill="#fff"
          d="M317.152 199.758H62.818v107.249h254.334V199.758z"
        ></path>
        <path
          fill="#5C5C5C"
          d="M317.152 206.301H62.818v1.021h254.334v-1.021zM67.402 205.15a1.83 1.83 0 100-3.658 1.83 1.83 0 000 3.658zM73.673 205.15a1.83 1.83 0 100-3.658 1.83 1.83 0 000 3.658zM79.944 205.15a1.83 1.83 0 100-3.658 1.83 1.83 0 000 3.658zM179.26 231H80.182v62.818h99.078V231z"
        ></path>
        <path
          fill="#000D23"
          d="M100.386 126.727L0 195.642v-35.305l48.957-33.61h51.429zM214.555 126.727L0 274.021v-35.31l163.126-111.984h51.429z"
          opacity="0.1"
        ></path>
        <path
          fill="#fff"
          d="M58.04 178.125l-6.877-7.373 7.372-6.877 6.877 7.373-7.373 6.877zm-5.781-7.335l5.818 6.238 6.239-5.818-5.819-6.239-6.238 5.819z"
        ></path>
        <path
          fill="#5C5C5C"
          d="M284.977 173.484h-10.082v-10.082h10.082v10.082zm-9.306-.775h8.53v-8.531h-8.53v8.531z"
        ></path>
        <path
          fill="#fff"
          d="M347.442 259.529a5.148 5.148 0 100-10.296 5.148 5.148 0 000 10.296z"
        ></path>
        <path
          fill="#000D23"
          d="M347.442 269.251a14.868 14.868 0 01-14.584-17.77 14.87 14.87 0 0129.454 2.901 14.883 14.883 0 01-14.87 14.869zm0-28.595a13.727 13.727 0 1013.726 13.726 13.74 13.74 0 00-13.726-13.726z"
        ></path>
        <path
          fill="#fff"
          d="M116.953 280.466c2.364 8.74 10.463 14.153 10.463 14.153s4.265-8.757 1.901-17.497c-2.365-8.74-10.463-14.153-10.463-14.153s-4.265 8.757-1.901 17.497z"
        ></path>
        <path
          fill="#859DFF"
          d="M120.427 278.593c6.488 6.315 7.28 16.024 7.28 16.024s-9.726-.531-16.214-6.847c-6.488-6.315-7.28-16.024-7.28-16.024s9.726.531 16.214 6.847z"
        ></path>
        <path
          fill="#fff"
          d="M138.462 280.466c-2.364 8.74-10.463 14.153-10.463 14.153s-4.265-8.757-1.9-17.497c2.364-8.74 10.462-14.153 10.462-14.153s4.265 8.757 1.901 17.497z"
        ></path>
        <path
          fill="#859DFF"
          d="M134.988 278.593c-6.488 6.315-7.28 16.024-7.28 16.024s9.726-.531 16.214-6.847c6.488-6.315 7.28-16.024 7.28-16.024s-9.726.531-16.214 6.847z"
        ></path>
        <path
          fill="#EBEEFF"
          d="M329.748 10.727h-40.099v46.717h40.099V10.727z"
        ></path>
        <path
          fill="#5C5C5C"
          d="M316.115 127.266l-.557-.544a28.456 28.456 0 005.708-9.986c1.894-5.701 2.819-14.704-3.53-25.22-6.497-10.761-6.79-19.501-5.891-24.939.978-5.916 3.473-9.232 3.579-9.37l.618.473c-.025.032-2.498 3.33-3.436 9.07-.867 5.3-.565 13.828 5.796 24.362 6.512 10.786 5.547 20.04 3.592 25.903-2.12 6.352-5.727 10.095-5.879 10.251z"
        ></path>
        <path
          fill="#5C5C5C"
          d="M345.321 57.83H289.26V1.77h56.061v56.06zm-55.283-.778h54.504V2.548h-54.504v54.504z"
        ></path>
        <path
          fill="#5C5C5C"
          d="M335.199 18.121h-34.26v.779h34.26v-.779zM335.199 26.688h-34.26v.778h34.26v-.779zM335.199 35.25h-34.26v.779h34.26v-.779z"
        ></path>
        <path
          fill="#EBEEFF"
          d="M392.427 44.594h-40.099V91.31h40.099V44.594z"
        ></path>
        <path
          fill="#5C5C5C"
          d="M378.794 161.137l-.557-.544a28.456 28.456 0 005.708-9.986c1.894-5.701 2.82-14.704-3.53-25.221-6.497-10.76-6.789-19.5-5.891-24.937.978-5.916 3.473-9.233 3.579-9.37l.619.472c-.025.032-2.499 3.33-3.437 9.071-.867 5.299-.564 13.827 5.796 24.362 6.513 10.785 5.548 20.039 3.592 25.902-2.119 6.352-5.726 10.095-5.879 10.251z"
        ></path>
        <path
          fill="#5C5C5C"
          d="M408 91.702h-56.061V35.64H408V91.7zm-55.282-.779h54.503V36.42h-54.503v54.504z"
        ></path>
        <path
          fill="#5C5C5C"
          d="M397.878 51.992h-34.26v.779h34.26v-.779zM397.878 60.559h-34.26v.778h34.26v-.778zM397.878 69.121h-34.26v.779h34.26v-.779z"
        ></path>
        <path fill="#EBEEFF" d="M94.214 9.945h-40.1v46.718h40.1V9.945z"></path>
        <path
          fill="#5C5C5C"
          d="M80.58 126.489l-.557-.544a28.47 28.47 0 005.708-9.986c1.895-5.701 2.82-14.704-3.53-25.221-6.497-10.76-6.79-19.5-5.89-24.938.977-5.916 3.472-9.232 3.578-9.37l.619.473c-.025.032-2.498 3.33-3.437 9.07-.866 5.3-.564 13.827 5.797 24.362 6.512 10.786 5.547 20.039 3.591 25.902-2.12 6.353-5.726 10.095-5.879 10.252z"
        ></path>
        <path
          fill="#5C5C5C"
          d="M109.786 57.053h-56.06V.993h56.06v56.06zm-55.282-.778h54.504V1.77H54.504v54.504z"
        ></path>
        <path
          fill="#5C5C5C"
          d="M99.664 17.344h-34.26v.778h34.26v-.778zM99.664 25.91h-34.26v.779h34.26v-.779zM99.664 34.473h-34.26v.778h34.26v-.778z"
        ></path>
        <path
          fill="#fff"
          d="M182.767 143.543l2.708 5.487 6.055.879-4.382 4.271 1.035 6.03-5.416-2.847-5.415 2.847 1.034-6.03-4.381-4.271 6.055-.879 2.707-5.487z"
        ></path>
        <path
          fill="#000D23"
          d="M192.555 19.84c9.356 0 16.941 8.417 16.941 18.8v13.192h-4.057l-2.349-4.888.587 4.888h-26.078l-2.135-4.444.533 4.444h-2.936V41.474c0-11.948 8.728-21.634 19.494-21.634zM211.966 106.027l10.401 4.421 36.145 29.384a10.639 10.639 0 012.34 5.72c.26 3.381 11.182 21.063 7.281 23.403-3.9 2.341-17.942 9.361-18.722 4.681-.78-4.681-4.941-24.703-4.941-24.703l-34.844-31.204 2.34-11.702z"
        ></path>
        <path
          fill="#000D23"
          d="M252.791 169.735l4.421 13.002s-4.681 4.941-2.34 9.101v14.302h3.64l1.04-7.541s4.147 7.446 6.241 7.541c4.39.2 13.084 1.761 13.344-.579.26-2.34-6.583-4.622-6.583-4.622l-7.281-20.022-3.121-12.222-9.361 1.04zM184.923 102.65s-2.341 22.882 4.42 27.043c6.761 4.16 36.665 17.422 36.665 17.422s-12.742 11.962-10.141 14.822c2.6 2.86 14.301 10.661 15.862 9.101 1.56-1.56 18.982-20.282 16.902-26.263-2.081-5.981-30.944-25.483-30.944-25.483l-3.381-12.222-1.04-9.881-10.921-7.021-17.422 12.482z"
        ></path>
        <path
          fill="#FF8589"
          d="M196.836 49.368c5.987 0 10.84-4.853 10.84-10.84s-4.853-10.84-10.84-10.84-10.84 4.853-10.84 10.84 4.853 10.84 10.84 10.84z"
        ></path>
        <path
          fill="#FF8589"
          d="M188.433 42.969s.52 9.881-7.541 13.001c-8.061 3.12 20.023 23.403 20.023 23.403V56.75s-3.641-3.9 0-9.88l-12.482-3.901zM171.141 89.387l2.08 10.141 6.241 34.844s-5.201 14.822 1.82 14.562c7.021-.26 3.641-16.122 3.641-16.122l-3.641-28.863V92.767l-10.141-3.38zM215.867 84.445l2.34 9.102 10.661 25.743s7.281 12.221 1.3 11.961c-5.98-.26-5.98-11.961-5.98-11.961l-12.222-20.803V86.786l3.901-2.34z"
        ></path>
        <path
          fill="#000D23"
          d="M217.687 160.375l-8.581 8.061s-5.981-1.3-8.321 5.721c-2.341 7.021-3.901 11.441-3.901 11.441l3.121 1.82 3.9-5.46s2.34 9.881 4.681 10.661c2.34.78 14.041 5.981 13.001 0l-5.72-8.321s-.781-9.361 1.56-10.141c2.34-.78 8.581-8.581 8.581-8.581l-8.321-5.201z"
        ></path>
        <path
          fill="#5C5C5C"
          d="M184.51 110.706s24.444-8.841 26.004-8.061c1.161.513 2.398.834 3.662.951l-.022-3.551s-8.061-2.86-12.481-1.04c-4.421 1.82-17.943 9.881-17.943 9.881l.78 1.82z"
        ></path>
        <path
          fill="#C2C2C2"
          d="M199.354 74.173s-7.02-8.06-9.101-13.002a12.765 12.765 0 00-6.749-6.636s-15.613 3.516-15.353 9.757c.26 6.24 9.881 15.862 9.881 15.862s-1.3 34.584 5.721 34.064a15.058 15.058 0 003.12-6.241c.78-3.64 24.963-7.541 24.963-7.541s2.08 3.641 2.34 2.081c.26-1.561-2.08-39.785-2.08-39.785l-12.4-8.197-.342 19.638z"
        ></path>
        <path
          fill="#C2C2C2"
          d="M170.154 61.78l-.174.097a3.588 3.588 0 00-1.832 3.129l.002 19.298a3.618 3.618 0 01-1.286 2.752c-1.002.852-1.93 2.08-.014 2.719 3.121 1.04 15.342 6.24 15.602 4.42.26-1.82.781-3.9 0-3.9-.61 0-4.249-18.647-7.173-26.62a3.58 3.58 0 00-2.125-2.132 3.586 3.586 0 00-3 .236zM208.586 61.82l3.64 1.04 4.161 17.943s4.94 1.04 3.38 2.86c-1.56 1.82-8.061 6.5-8.061 6.5l-3.12-28.343z"
        ></path>
        <path
          fill="#000D23"
          d="M187.739 25.019a8.891 8.891 0 017.011-3.578h.332c6.413 0 11.611 5.803 11.611 12.961h-2.149l-.347-2.641-.508 2.641h-12.744l-1.068-2.235.267 2.235h-2.507c-1.17 5.81.511 11.62 5.043 17.43h-4.271l-2.135-4.47.534 4.47h-8.141l-1.602-10.28c0-7.697 4.473-14.225 10.674-16.533z"
        ></path>
        <path
          fill="#06884A"
          d="M225.147 265.975a5.019 5.019 0 01-6.294 0 1.337 1.337 0 00-2.182 1.149c.032.352.203.678.476.904a7.57 7.57 0 009.706 0 1.333 1.333 0 10-1.706-2.053zm-6.867-4.92a1.335 1.335 0 001.88 0 1.336 1.336 0 000-1.88 4.108 4.108 0 00-5.653 0 1.328 1.328 0 00-.375 1.499 1.333 1.333 0 001.823.714 1.35 1.35 0 00.432-.333 1.323 1.323 0 011.459-.292c.163.067.31.167.434.292zm11.213-1.88a4.107 4.107 0 00-5.653 0 1.335 1.335 0 00.07 1.81 1.334 1.334 0 001.81.07 1.328 1.328 0 011.893 0 1.335 1.335 0 001.88 0 1.332 1.332 0 000-1.88zM222 249.668a13.336 13.336 0 00-12.318 8.231 13.323 13.323 0 00-.759 7.704 13.327 13.327 0 0010.476 10.475 13.328 13.328 0 0013.687-5.669 13.336 13.336 0 00-1.658-16.836 13.33 13.33 0 00-9.428-3.905zm0 24a10.666 10.666 0 110-21.332 10.666 10.666 0 010 21.332z"
        ></path>
        <path
          fill="#B80006"
          d="M270 261.668a1.332 1.332 0 001.308-1.593 1.339 1.339 0 00-1.048-1.048 1.338 1.338 0 00-1.593 1.308 1.332 1.332 0 001.333 1.333zm8-2.667a1.337 1.337 0 00-1.232.823 1.339 1.339 0 00.289 1.453 1.328 1.328 0 001.453.289 1.325 1.325 0 00.823-1.231 1.333 1.333 0 00-1.333-1.334zm-4-9.333a13.336 13.336 0 00-12.318 8.231 13.323 13.323 0 00-.759 7.704 13.327 13.327 0 0010.476 10.475 13.328 13.328 0 0013.687-5.669 13.336 13.336 0 00-1.658-16.836 13.33 13.33 0 00-9.428-3.905zm0 24a10.666 10.666 0 110-21.332 10.666 10.666 0 010 21.332z"
        ></path>
        <path
          fill="#B80006"
          d="M277.147 268.146a5.014 5.014 0 00-6.294 0 1.338 1.338 0 01-1.88-.173 1.336 1.336 0 01.174-1.88 7.57 7.57 0 019.706 0 1.336 1.336 0 01-1.706 2.053z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0">
          <path
            fill="#fff"
            d="M0 0H408V306.015H0z"
            transform="translate(0 .992)"
          ></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgEmptyFeedback;
