import Icon from '@ant-design/icons';
import { Menu } from 'antd';
import React, { useCallback, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Pathname from '../../constants/Pathname';
import SvgEmptyTypesMemberships from '../atoms/SvgEmptyTypesMemberships';
import SvgDelete from '../atoms/SvgDelete';
import SvgDuplicate from '../atoms/SvgDuplicate';
import SvgEdit from '../atoms/SvgEdit';
import AppWrapper from '../templates/AppWrapper';
import PageEmpty from '../templates/PageEmpty';
import CardList from '../templates/CardList';
import SvgCustomer from '../atoms/SvgCustomer';
import { useQuery } from '@apollo/client';
import QueryGql from 'constants/QueryGql';

const TypeMemberships = () => {
  const history = useHistory();
  const [search, setSearch] = useState('');

  const {
    data: allMemberships,
    loading,
    error,
  } = useQuery(QueryGql.SEARCH_MEMBERSHIPS, {
    variables: { search },
    fetchPolicy: 'cache-and-network',
  });

  const data = useMemo(
    () =>
      allMemberships?.searchMembership?.data?.map(item => ({
        ...item,
        key: item.id,
        sumOfCustomers: item.total_user,
        status: '',
        price: '',
        price_origin: item.price,
      })) || [],
    [allMemberships?.searchMembership?.data],
  );

  const contextMenu = useCallback(
    item => (
      <Menu>
        <Menu.Item
          key='0'
          onClick={() =>
            history.push(
              Pathname.TYPES_MEMBERSHIP +
                '/' +
                Pathname.TYPES_MEMBERSHIP_MEMBER +
                `?act=${item.name}&id=${item.id}`,
              {
                background: true,
              },
            )
          }>
          <Icon component={SvgCustomer} style={{ fontSize: 14, color: '#000D23' }} /> See{' '}
          {item.sumOfCustomers} customers
        </Menu.Item>
        <Menu.Item
          key='1'
          onClick={() =>
            history.push(
              Pathname.TYPES_MEMBERSHIP + '/' + Pathname.TYPES_MEMBERSHIP_CREATE + `?act=edit&id=${item.id}`,
              {
                background: true,
                item: { ...item, price: item.price_origin }
              },
            )
          }>
          <Icon component={SvgEdit} style={{ fontSize: 14, color: '#000D23' }} /> Edit customer type
        </Menu.Item>
        <Menu.Item
          key='2'
          onClick={() => history.push(
            Pathname.TYPES_MEMBERSHIP + '/' +
            Pathname.TYPES_MEMBERSHIP_CREATE + `?act=duplicate&id=${item.id}`,
            {
              background: true,
              item: { ...item, price: item.price_origin }
            }
          )}
        >
          <Icon component={SvgDuplicate} style={{ fontSize: 14, color: '#000D23' }} /> Duplicate
          customer type
        </Menu.Item>
        <Menu.Item
          key='3'
          className='second-menu-edit'
          onClick={() =>
            history.push(
              Pathname.TYPES_MEMBERSHIP +
                '/' +
                Pathname.TYPES_MEMBERSHIP_DELETE +
                `?id=${item.id}&name=${item.name}`,
              {
                background: true,
              },
            )
          }>
          <Icon component={SvgDelete} style={{ fontSize: 14 }} /> Delete customer type
        </Menu.Item>
      </Menu>
    ),
    [],
  );

  return (
    <AppWrapper>
      <CardList
        data={data}
        contextMenuItem={contextMenu}
        noIcon
        onClickItem={() => {}}
        buttonAdd={{
          label: 'Add new customer type',
          onClick: () =>
            history.push(
              Pathname.TYPES_MEMBERSHIP + '/' + Pathname.TYPES_MEMBERSHIP_CREATE + '?act=add',
              {
                background: true,
              },
            ),
        }}
        searchInput={{
          placeholder: 'Search customer types',
          onChange: e => setSearch(e.target.value),
        }}
      />
      {(data?.length === 0 && !loading) || error?.message ? (
        <PageEmpty
          title='Add customer types and memberships'
          desc='Make your customers feel that they are a part of something prestigious. By creating types and memberships you can also give different rate cards for different customer types.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;'
          buttonLabel='Add new customer type'
          illustration={SvgEmptyTypesMemberships}
          buttonOnClick={() =>
            history.push(
              Pathname.TYPES_MEMBERSHIP + '/' + Pathname.TYPES_MEMBERSHIP_CREATE + '?act=add',
              {
                background: true,
              },
            )
          }
        />
      ) : null}
    </AppWrapper>
  );
};

export default TypeMemberships;
