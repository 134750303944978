import { 
  // useLazyQuery,
  useQuery 
} from '@apollo/client';
import { Col, Row } from 'antd';
import AntdCalendar from 'antd/lib/calendar';
import Title from 'antd/lib/typography/Title';
import Text from 'antd/lib/typography/Text';
import moment from 'moment';
import React, { 
  useCallback, 
  useState,
  // useEffect 
} from 'react';
import { useHistory } from 'react-router-dom';
import Pathname from '../../constants/Pathname';
import QueryGql from '../../constants/QueryGql';
import Button from '../atoms/Button';
import IconButton from '../atoms/IconButton';
import SvgDownArrow from '../atoms/SvgDownArrow';
import SvgUpArrow from '../atoms/SvgUpArrow';
import AppWrapper from '../templates/AppWrapper';
import './Calendar.less';

const Calendar = () => {
  const history = useHistory();
  const [activeMonth, setActiveMonth] = useState(moment().toISOString());

  moment.updateLocale('en', {
    weekdaysMin : ['SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY']
  });

  const { 
    // loading: loadingGetAllCalendar,
    data: dataGetAllCalendar,
  } = useQuery(QueryGql.GET_ALL_CALENDAR, {
    variables: {
      getAllCalendarType: 'month',
      getAllCalendarDate: activeMonth
    },
    fetchPolicy: 'network-only'
  });

  // const [getAllCalendar, { 
  //   // loading: loadingGetAllCalendar,
  //   data: dataGetAllCalendar,
  // }] = useLazyQuery(QueryGql.GET_ALL_CALENDAR, {
  //   fetchPolicy: 'network-only'
  // });

  // useEffect(() => {
  //   getAllCalendar({
  //     variables: {
  //       getAllCalendarType: 'month',
  //       getAllCalendarDate: moment().toISOString()
  //     }
  //   });
  // }, []);

  const headerRender = ({ value, onChange }) => {
    return (
      <Row className="header" align="middle" justify="space-between">
        <Col>
          <Row align="middle" gutter={8}>
            <Col><Title className="title" level={4}>{moment(value).format('MMMM YYYY')}</Title></Col>
            <Col>
              <IconButton
                onClick={() => {
                  onChange(moment(value).add(1, 'M'));
                  setActiveMonth(moment(value).add(1, 'M'));
                  // getAllCalendar({
                  //   variables: {
                  //     getAllCalendarType: 'month',
                  //     getAllCalendarDate: moment(moment(value).add(1, 'M')).toISOString()
                  //   }
                  // });
                }}
                iconSvg={SvgUpArrow}
              />
            </Col>
            <Col>
              <IconButton
                onClick={() => {
                  onChange(moment(value).subtract(1, 'M'));
                  setActiveMonth(moment(value).subtract(1, 'M'));
                  // getAllCalendar({
                  //   variables: {
                  //     getAllCalendarType: 'month',
                  //     getAllCalendarDate: moment(moment(value).subtract(1, 'M')).toISOString()
                  //   }
                  // });
                }}
                iconSvg={SvgDownArrow}
              />
            </Col>
          </Row>
        </Col>
        <Col>
          <Button
            onClick={() => history.push(
              Pathname.CALENDAR+'/'+
              Pathname.CALENDAR_TEE_TIME_FORM,
              {
                background: true
              }
            )}
          >
            Insert tee time slots
          </Button>
        </Col>
      </Row>
    );
  };

  const dateCellRender = useCallback((date) => {
    const textColor = (hex) => {
      let r = 0; let g = 0; let b = 0; let a = 1;
      if (hex.length == 5) {
        r = '0x' + hex[1] + hex[1];
        g = '0x' + hex[2] + hex[2];
        b = '0x' + hex[3] + hex[3];
        a = '0x' + hex[4] + hex[4];
      } else if (hex.length == 9) {
        r = '0x' + hex[1] + hex[2];
        g = '0x' + hex[3] + hex[4];
        b = '0x' + hex[5] + hex[6];
        a = '0x' + hex[7] + hex[8];
      }
      a = +(a / 255).toFixed(3);
      let rgba = 'rgba(' + +r + ',' + +g + ',' + +b + ',' + a + ')';
      rgba = rgba.match(/\d+/g);
      if((rgba[0]*0.299)+(rgba[1]*0.587)+(rgba[2]*0.114)>186) {
        return 'black';
      } 
      return 'white';
    };

    const value = moment(date).format('YYYY-MM-DD');
    const dateCell = dataGetAllCalendar?.getAllCalendar.data.filter(item => item.date === value);
    // if (!dateCell) {
    //   return null;
    // }
    return (
      <div
        className="wrapper-date"
        onClick={() => history.push(
          Pathname.CALENDAR+'/'+
          Pathname.CALENDAR_DAILY_VIEW+
          `?date=${value}`,
          {
            background: true
          }
        )}
      >
        {dateCell?.map(item => (
          <Row key={item.id} wrap={false}>
            {item?.tee_times && (
              <Col xs={12}>
                <div className="card-cell" style={{backgroundColor: item.tee_times.color}}>
                  <Text className="text-card-cell" style={{ color: textColor(item.tee_times.color) }}>{item.tee_times.name}</Text>
                </div>
              </Col>
            )}
            {item?.rate_cards && (
              <Col xs={12}>
                <div className="card-cell" style={{backgroundColor: item.rate_cards.color}}>
                  <Text className="text-card-cell" style={{ color: textColor(item.rate_cards.color) }}>{item.rate_cards.name}</Text>
                </div>
              </Col>
            )}
          </Row>
        ))}
      </div>
    );
  }, [dataGetAllCalendar]);

  return (
    <AppWrapper>
      <div id="calendar" className="calendar-container">
        <AntdCalendar
          headerRender={headerRender}
          dateCellRender={dateCellRender}
          onChange={null}
          onSelect={null}
        />
      </div>
    </AppWrapper>
  );
};

export default Calendar;