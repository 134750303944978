import React from 'react';

function SvgCart() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="14"
      fill="none"
      viewBox="0 0 15 14"
    >
      <path
        fill="currentColor"
        d="M11.447 9.047a.508.508 0 00-.06-.12l-.08-.1-.1-.08a.505.505 0 00-.12-.06.667.667 0 00-.727.14 1.047 1.047 0 00-.08.1.5.5 0 00-.06.12.421.421 0 00-.04.12.9.9 0 000 .133.666.666 0 00.414.613c.076.033.157.05.24.054a.667.667 0 00.667-.667.927.927 0 000-.133.426.426 0 00-.054-.12zm1.227-3.007l-.907-4.707A2 2 0 009.874 0H5.127a2 2 0 00-1.893 1.333L2.327 6.04A2 2 0 00.834 7.967v2.666a2 2 0 001.333 1.88v.787a.667.667 0 101.334 0v-.667h8v.667a.667.667 0 101.333 0v-.787a2 2 0 001.333-1.88V7.967a2 2 0 00-1.493-1.927zm-8.18-4.287a.667.667 0 01.633-.453h4.747a.667.667 0 01.667.453l.7 4.214H3.76l.733-4.214zm8.34 8.88a.667.667 0 01-.667.667H2.834a.667.667 0 01-.667-.667V7.967a.667.667 0 01.667-.667h9.333a.667.667 0 01.667.667v2.666zm-4.667-2H6.834a.667.667 0 000 1.334h1.333a.667.667 0 000-1.334zm-3.386.414a.507.507 0 00-.06-.12l-.08-.1a.667.667 0 00-.727-.14.667.667 0 00-.22.14 1.055 1.055 0 00-.08.1.507.507 0 00-.06.12.427.427 0 00-.053.12.907.907 0 000 .133.667.667 0 001.333 0 .905.905 0 000-.133.427.427 0 00-.053-.12z"
      ></path>
    </svg>
  );
}

export default SvgCart;
