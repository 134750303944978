import React from 'react';

function SvgFilter() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="none"
      viewBox="0 0 14 14"
    >
      <path
        fill="currentColor"
        d="M12.333 4.454V1.001A.667.667 0 1011 1v3.453a2 2 0 000 3.76v4.787a.667.667 0 101.333 0V8.214a2 2 0 000-3.76zm-.666 2.547a.667.667 0 110-1.333.667.667 0 010 1.333zm-4 1.453V1.001a.667.667 0 00-1.334 0v7.453a2 2 0 000 3.76v.787a.667.667 0 001.334 0v-.787a2 2 0 000-3.76zM7 11.001a.666.666 0 110-1.333A.666.666 0 017 11zM3 3.12V1a.667.667 0 00-1.333 0v2.12a2 2 0 000 3.76V13A.667.667 0 003 13V6.88a2 2 0 000-3.76zm-.667 2.546a.667.667 0 110-1.333.667.667 0 010 1.333z"
      ></path>
    </svg>
  );
}

export default SvgFilter;
