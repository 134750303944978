import { Radio } from 'antd';
import React from 'react';
import PropTypes from 'prop-types';
import './RadioButtonTab.less';

const RadioButtonTab = (props) => {
  const { value, options, onChange } = props;
  return (
    <div id="radio-button-tab">
      <Radio.Group
        {...props}
        options={options}
        value={value}
        onChange={onChange}
        optionType="button"
      />
    </div>
  );
};

export default RadioButtonTab;

RadioButtonTab.propTypes = {
  value: PropTypes.any.isRequired,
  options: PropTypes.any.isRequired,
  onChange: PropTypes.func
};

RadioButtonTab.defaultProps = {
  onChange: null,
};