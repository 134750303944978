import React from 'react';
import formatter from 'utils/formatter';

function Receipt({
  printRef,
  paymentStatus,
  facebook,
  instagram,
  email,
  phoneNumber,
  total,
  subTotal,
  date,
  reservationId,
  courseAddress,
  courseName,
  website,
  items,
  logo,
  payments,
}) {
  return (
    <div ref={printRef} style={{ padding: '20px 30px', minHeight: '1057px' }}>
      {logo && (
        <div
          style={{
            height: '140px',
            width: '140px',
            border: 'black dashed 0.69px ',
            margin: 'auto',
            position: 'relative',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: '20px',
          }}>
          <img style={{ maxWidth: '100%', maxHeight: '100%' }} alt='' src={logo} />
        </div>
      )}
      <p
        style={{
          fontWeight: 700,
          fontSize: '20px',
          textAlign: 'center',
          fontFamily: 'monospace',
          marginBottom: 4,
        }}>
        {' '}
        {courseName}
      </p>
      <p
        style={{
          marginTop: 4,
          fontWeight: 400,
          fontSize: '12px',
          textAlign: 'center',
          fontFamily: 'monospace',
        }}>
        {' '}
        {courseAddress}{' '}
      </p>

      <div style={{ position: 'relative' }}>
        <div
          style={{
            fontFamily: 'monospace',
            fontWeight: 400,
            fontSize: '10px',
            marginTop: '20px',
          }}>
          <p>Date & time: {date} </p>
          <p>Reservation ID: {reservationId} </p>
        </div>
        <div
          style={{ width: '100%', borderBottom: '0.69px dashed black', margin: '20px 0px' }}></div>
        <table style={{ borderCollapse: 'collapse' }} width='100%'>
          {items?.map(item => (
            <tr
              key={item.name}
              style={{ fontFamily: 'monospace', fontSize: '14px', fontWeight: 700 }}>
              <th style={{ textAlign: 'left' }}> {item.name} </th>
              <th style={{ textAlign: 'left' }}>x{item.quantity}</th>
              <th style={{ textAlign: 'right' }}> {formatter.currency(item.price)} </th>
            </tr>
          ))}
        </table>
        <div
          style={{ width: '100%', borderBottom: '0.69px dashed black', margin: '20px 0px' }}></div>
        <table style={{ borderCollapse: 'collapse' }} width='100%'>
          <tr style={{ fontSize: '14px', fontFamily: 'monospace' }}>
            <th style={{ textAlign: 'left', fontSize: '14px', fontWeight: 700 }}>Subtotal</th>
            <th style={{ textAlign: 'right', fontSize: '14px', fontWeight: 700 }}>
              {' '}
              {formatter.currency(subTotal)}{' '}
            </th>
          </tr>
        </table>
        <p style={{ fontWeight: 400, fontSize: '10px', fontFamily: 'monospace' }}>
          Tax already included
        </p>
        <div
          style={{ width: '100%', borderBottom: '0.69px dashed black', margin: '20px 0px' }}></div>
        <table style={{ borderCollapse: 'collapse' }} width='100%'>
          <tr style={{ fontSize: '14px', fontFamily: 'monospace' }}>
            <th style={{ textAlign: 'left', fontSize: '13.71px', fontWeight: 700 }}>Total</th>
            <th style={{ textAlign: 'right', fontSize: '13.71px', fontWeight: 700 }}>
              {formatter.currency(total)}{' '}
            </th>
          </tr>
        </table>
        <div
          style={{ width: '100%', borderBottom: '0.69px dashed black', margin: '20px 0px' }}></div>
        <table style={{ borderCollapse: 'collapse' }} width='100%'>
          {payments?.map((item, i) => (
            <tr key={i} style={{ fontSize: '14px', fontFamily: 'monospace' }}>
              <th style={{ textAlign: 'left', fontSize: '10px', fontWeight: 400 }}>
                Paid with {item.name}{' '}
              </th>
              <th style={{ textAlign: 'right', fontSize: '10px', fontWeight: 400 }}>
                {formatter.currency(item.amount)}
              </th>
            </tr>
          ))}
        </table>
        <div
          style={{ width: '100%', borderBottom: '0.69px dashed black', margin: '20px 0px' }}></div>
        <div style={{ fontFamily: 'monospace', fontWeight: 400, fontSize: '10px' }}>
          {phoneNumber && <p>Phone number: {phoneNumber} </p>}
          {email && <p>Email: {email} </p>}
          {website && <p>Website: {website} </p>}
          {instagram && <p>Instagram: {instagram}</p>}
          {facebook && <p>Facebook: {facebook}</p>}
        </div>
        <div style={{ width: '100%', borderBottom: '0.69px dashed black' }}></div>
        <p
          style={{
            fontWeight: 700,
            fontSize: '26.67px',
            textAlign: 'left',
            fontFamily: 'monospace',
          }}>
          {paymentStatus}
        </p>
      </div>
    </div>
  );
}

export default Receipt;
