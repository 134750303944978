import Text from 'antd/lib/typography/Text';
import React, { useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import ModalCancelAction from '../templates/ModalCancelAction';
import extractQuery from '../../utils/useQuery';
import { useMutation } from '@apollo/client';
import Pathname from '../../constants/Pathname';
import QueryGql from '../../constants/QueryGql';

const VoidRaincheck = () => {
  const history = useHistory();
  const location = useLocation();
  const state = history.location.state || {};
  const queryString = extractQuery(location.search);
  const id = queryString.get('id') || '';

  const ids = id.split(',');

  const number = useMemo(() => {
    const items = [];
    if (Array.isArray(state.data)) {
      state.data.forEach(element => {
        items.push(element.code);
      });
    } else if (state.data) {
      items.push(state.data.code);
    }
    return items.join(', ');
  }, [state]);

  const [voidRaincheck, { loading }] = useMutation(QueryGql.VOID_RAINCHECK, {
    onCompleted: () => {
      history.replace(Pathname.RAINCHECK_LIST + '?refetch=true');
    },
    onError: () => {}
  });

  const handleVoid = () => {
    for (let index = 0; index < ids.length; index++) {
      const voidRaincheckId = ids[index];
      voidRaincheck({ variables: { voidRaincheckId } });
    }
  };
  

  return (
    <ModalCancelAction
      title={`Void ${ids.length} raincheck?`}
      leftFooterButton={{
        label: 'Cancel void',
        type: 'link',
      }}
      rightFooterButton={{
        label: 'Continue to void',
        state: 'default',
        loading,
        onClick: handleVoid,
      }}
    >
      <Text>Void the selected {number} raincheck? Once raincheck is voided, it cannot be unvoided again and will remain unusable.</Text>
    </ModalCancelAction>
  );
};

export default VoidRaincheck;