import { useMutation, useQuery } from '@apollo/client';
import Text from 'antd/lib/typography/Text';
import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import QueryGql from '../../constants/QueryGql';
import HorizontalTextInput from '../molecules/HorizontalTextInput';
import Modal from '../organisms/Modal';

const RaincheckSettingsLowestPrice = () => {
  const history = useHistory();
  const { data: dataGetLowestPriceAfterRaincheck } = useQuery(QueryGql.GET_LOWER_PRICE_AFTER_RAINCHECK);
  const lowestPriceAfterRaincheck = dataGetLowestPriceAfterRaincheck?.getLowestPriceAfterRainCheck.data.value;

  const [lowestPriceAfterRaincheckState, setLowestPriceAfterRaincheckState] = useState(lowestPriceAfterRaincheck);

  const [setLowestPriceAfterRaincheck, {
    loading: loadingLowestPriceAfterRaincheck
  }] = useMutation(QueryGql.SET_LOWER_PRICE_AFTER_RAINCHECK, {
    onCompleted: () => {
      history?.goBack();
    }
  });

  const handleChangeName = useCallback((e) => {
    const name = e.target.value;
    setLowestPriceAfterRaincheckState(name);
  }, []);

  const handleAdded = () => {
    setLowestPriceAfterRaincheck({
      variables: { 
        setLowestPriceAfterRainCheckValue: parseInt(lowestPriceAfterRaincheckState)
      }
    });
  };
  
  return (
    <Modal
      title="Lowest price after raincheck"
      hiddenLeftFooterButton
      rightFooterButton={{
        label: 'Save changes',
        state: !lowestPriceAfterRaincheckState || loadingLowestPriceAfterRaincheck ? 'disabled' : 'default',
        onClick: handleAdded
      }}
      bodyStyle={{ textAlign: 'right' }}
      width={540}
    >
      <HorizontalTextInput
        label="Lowest price"
        value={lowestPriceAfterRaincheckState}
        onChange={handleChangeName}
        prefix={<Text type="secondary">Rp. </Text>}
      />
    </Modal>
  );
};

export default RaincheckSettingsLowestPrice;
