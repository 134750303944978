import { useMutation, useQuery } from '@apollo/client';
import React, { useCallback, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Pathname from '../../constants/Pathname';
import QueryGql from '../../constants/QueryGql';
import extractQuery from '../../utils/useQuery';
import Modal from '../organisms/Modal';
import TeeSheetCustomersCheckInDetailTemplate from '../templates/TeeSheetCustomersCheckInDetail';

const TeeSheetCustomersCheckInDetail = () => {
  const history = useHistory();
  const location = useLocation();
  const queryString = extractQuery(location.search);
  const id = queryString.get('id');

  const [ loadingSaveChange, setLoadingSaveChange ] = useState(false);
  const [ loadingCheckinAll, setLoadingCheckinAll ] = useState(false);
  const [ loadingCheckinAllForPayment, setLoadingCheckinAllForPayment ] = useState(false);
  const [ checkinReservationCustomers, setCheckinReservationCustomers ] = useState([]);
  const [ checkinReservationCustomersLength, setCheckinReservationCustomersLength] = useState(0);

  const { loading: loadingGetReservation, data: dataGetReservation } = useQuery(
    QueryGql.GET_RESERVATION,
    {
      variables: {
        getReservationId: id
      },
      fetchPolicy: 'no-cache',
      
    }
  );

  const [checkinReservation, { loading: loadingCheckinReservation }] = useMutation(
    QueryGql.CHECKIN_RESERVATION,
    {
      onCompleted: () => {
        setLoadingCheckinAll(false);
        setLoadingSaveChange(false);
        setLoadingCheckinAllForPayment(false);
      },
      onError: () => {
        history.goBack();
        setLoadingCheckinAll(false);
        setLoadingSaveChange(false);
        setLoadingCheckinAllForPayment(false);
      }
    }
  );

  const total = useMemo(() => dataGetReservation?.getReservation.data.total_amount || 0, [dataGetReservation]);
  const status = useMemo(() => dataGetReservation?.getReservation.data.payment_status || 0, [dataGetReservation]);
  const operators = useMemo(() => dataGetReservation?.getReservation.data.reservation_operators || [], [dataGetReservation]);
  const reservation = useMemo(() => {
    if (dataGetReservation?.getReservation?.data) {
      const reservation = dataGetReservation.getReservation.data;
      return dataGetReservation.getReservation.data.reservation_customers.map(item => {
        const reservations = [
          {
            name: `${reservation.tee_times.name} - ${item.membership_name}`,
            price: item.membership_price,
            status: item.status
          },
          ...item.add_ons.map(add_on => ({
            name: add_on.name,
            price: add_on.price,
            status: item.status
          }))
        ];

        if (item.raincheck?.code) {
          reservations.push({
            name: 'Raincheck',
            price: - item.raincheck.amount,
            status: item.status
          });
        }

        return {
          id: item.customer_id,
          status: item.status,
          bag_id: item.bag_id,
          customer: {
            name: item.customer.full_name
          },
          reservations
        };
      });
    }
    return [];
  }, [dataGetReservation]);

  const handleSaveChange = useCallback(() => {
    if (checkinReservationCustomers.length > 0) {
      setLoadingSaveChange(true);
      const checkins = checkinReservationCustomers.filter(x => x.default_status !== 'checked_in' && x.default_status !== 'paid');
      if (checkins.length > 0) {
        checkinReservation({
          variables: {
            checkinReservationId: id,
            checkinReservationCustomers: checkins.map(x => {
              delete x.default_status;
              return x;
            }),
          }
        }).then(() => {
          history.goBack();
        });
      }
    } else {
      history.goBack();
    }
  }, [checkinReservationCustomers]);
  
  const handleContinueToPayment = useCallback(() => {
    setLoadingCheckinAllForPayment(true);
    const checkins = checkinReservationCustomers.filter(x => x.default_status !== 'checked_in' && x.default_status !== 'paid');
    if (checkins.length > 0) {
      checkinReservation({
        variables: {
          checkinReservationId: id,
          checkinReservationCustomers: checkins.map(x => {
            delete x.default_status;
            return {
              ...x,
              bag_id: x?.bag_id || '',
            };
          }),
        }
      }).then(() => {
        history.push(
          Pathname.TEE_SHEET + '/' +
            Pathname.TEE_SHEET_TAKE_PAYMENT+
            `?id=${id}`,
          {
            background: true
          }
        );
      });
    } else {
      history.push(
        Pathname.TEE_SHEET + '/' +
          Pathname.TEE_SHEET_TAKE_PAYMENT+
          `?id=${id}`,
        {
          background: true
        }
      );
    }
  }, [ checkinReservationCustomers]);

  const handleCancelReservation = () => {
    history.push(
      Pathname.TEE_SHEET + '/' +
        Pathname.TEE_SHEET_CANCEL_RESERVATION+
        `?id=${id}`,
      {
        background: true
      }
    );
  };

  const isShowCancelReservation = reservation?.filter(item => item.status === 'reserved').length > 0;

  return (
    <Modal
      title="Check-in customers"
      hiddenLeftFooterButton={!isShowCancelReservation}
      leftFooterButton={{
        label: 'Cancel reservation',
        type: 'link',
        disabled: loadingGetReservation || loadingCheckinReservation,
        onClick: handleCancelReservation
      }}
      rightFooterButtonExtra={{
        label: 'Save changes',
        type: 'outline',
        disabled: loadingGetReservation || loadingCheckinReservation || loadingCheckinAll,
        loading: loadingSaveChange,
        onClick: handleSaveChange
      }}
      rightFooterButton={{
        label: 'Continue to payment',
        state: loadingGetReservation || checkinReservationCustomersLength === 0 ? 'disabled' : 'default',
        disabled: loadingGetReservation,
        loading: loadingCheckinAllForPayment,
        onClick: handleContinueToPayment,
      }}
      width={540}
    >
      <TeeSheetCustomersCheckInDetailTemplate
        type="group"
        data={reservation}
        total={total}
        status={status}
        operators={operators}
        onChangeStatus={(values) => {
          setCheckinReservationCustomersLength(values.filter(x => x.status === 'checked_in').length);
          setCheckinReservationCustomers(values);
        }}
      />
    </Modal>
  );
};

export default TeeSheetCustomersCheckInDetail;