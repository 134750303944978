import Text from 'antd/lib/typography/Text';
import React from 'react';
import { Tooltip } from 'antd';
import Icon from '@ant-design/icons';
import PropTypes from 'prop-types';
import './HorizontalRadioButton.less';
import RadioButtonTab from '../atoms/RadioButtonTab';
import SvgInfo from '../atoms/SvgInfo';

const HorizontalRadioButton = (props) => {
  const { label, labelInfo, options, onChange, value } = props;

  return (
    <div className="antd-horizontal-select-options">
      {label && (
        <div className="container-label">
          <Text className="label">{label}</Text>
          {labelInfo && (
            <Tooltip placement="right" title={labelInfo}>
              <Icon component={SvgInfo} className="info"/>
            </Tooltip>
          )}
        </div>
      )}
      <RadioButtonTab
        options={options}
        value={value}
        onChange={onChange}
        className="radio-button"
      />
    </div>
  );
};

export default HorizontalRadioButton;

HorizontalRadioButton.propTypes = {
  label: PropTypes.string.isRequired,
  labelInfo: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired
    })
  ).isRequired,
};

HorizontalRadioButton.defaultProps = {
  onChange: null,
};

