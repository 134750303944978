import Text from 'antd/lib/typography/Text';
import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import extractQuery from '../../utils/useQuery';
import Pathname from '../../constants/Pathname';
import Modal from '../organisms/Modal';

const TeeSheetRaincheck = () => {
  const history = useHistory();
  const location = useLocation();
  const queryString = extractQuery(location.search);
  const id = queryString.get('id');

  return (
    <Modal
      title="Payment before raincheck"
      leftFooterButton={{
        label: 'Cancel',
        type: 'link',
        onClick:() => history.goBack()
      }}
      rightFooterButton={{
        label: 'Continue to payment',
        onClick: () => history.push(
          Pathname.TEE_SHEET + '/' +
          Pathname.TEE_SHEET_TAKE_PAYMENT+
          `?type=raincheck&id=${id}`,
          {
            background: true
          }
        )
      }}
      width={540}
    >
      <Text>Customers who have made payment are the ones eligible for rainchecks. Currently, all customers have not paid, please proceed to collect payment from checked-in customers and raincheck can then be issued.</Text>
    </Modal>
  );
};

export default TeeSheetRaincheck;