import React from 'react';
import Icon, { CheckCircleOutlined, LoginOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import PropTypes from 'prop-types';
import './ButtonStatusReservation.less';
import SvgTeeTimes from './SvgTeeTimes';
import SvgCancel from './SvgCancel';

const ButtonStatusReservation = props => {
  const { status, onClickStatus } = props;

  return (
    <div id='button-status-reservation'>
      {status === 'cancelled' ? (
        <Button
          onClick={onClickStatus}
          size='middle'
          type='text'
          className='button-status-cancel'
          icon={<Icon component={SvgCancel} />}>
          Canceled
        </Button>
      ) : status === 'reserved' ? (
        <Button
          onClick={onClickStatus}
          size='middle'
          type='text'
          className='button-status'
          icon={<Icon component={SvgTeeTimes} />}>
          Reserved
        </Button>
      ) : status === 'paid' ? (
        <Button
          onClick={onClickStatus}
          size='middle'
          type='text'
          className='button-status-paid'
          icon={<CheckCircleOutlined />}>
          Paid
        </Button>
      ) : (
        <Button
          onClick={onClickStatus}
          size='middle'
          type='text'
          className='button-status-checkedin'
          icon={<LoginOutlined />}>
          Checked-in
        </Button>
      )}
    </div>
  );
};

export default ButtonStatusReservation;

ButtonStatusReservation.propTypes = {
  status: PropTypes.oneOfType(['paid', 'reserved', 'checked_in']),
  onClickStatus: PropTypes.func,
};

ButtonStatusReservation.defaultProps = {};
