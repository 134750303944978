import React, { useMemo } from 'react';
import { Col, Row } from 'antd';
import PropTypes from 'prop-types';
import ReportCard from '../molecules/ReportCard';

const RaincheckCards = ({
  isPrint,
  totalRedeemed,
  totalRaincheck,
  reservationPrecentage,
}) => {
  const items = useMemo(() => ([
    {
      title: 'Total rainchecks',
      value: `${totalRaincheck} rainchecks`
    },
    {
      title: 'Redeemed raincheks',
      value: `${totalRedeemed} redeemed`
    },
    {
      title: 'Raincheck to reservation percentage',
      value: `${reservationPrecentage % 1 === 0 ? reservationPrecentage : Number(reservationPrecentage).toFixed(2)}%`,
      info: 'Shows how often it rains by calculating the number of rainchecks issued over the total reservation in a specified period.'
    },
  ]), [
    totalRedeemed,
    totalRaincheck,
    reservationPrecentage
  ]);

  return(
    <div id={`${isPrint ? 'print-' : '' }raincheck-cards`}>
      <Row gutter={[16, 16]}>
        {
          items.map(item => (
            <Col
              key={item.title}
              span={8}
            >
              <ReportCard
                title={item.title}
                value={item.value}
                info={item.info}
              />
            </Col>
          ))
        }
      </Row>
    </div>
  );
};

RaincheckCards.propTypes = {
  totalRedeemed: PropTypes.number,
  totalRaincheck: PropTypes.number,
  reservationPrecentage: PropTypes.number,
};

RaincheckCards.defaultProps = {
  totalRedeemed: 0,
  totalRaincheck: 0,
  reservationPrecentage: 0,
};

export default RaincheckCards;