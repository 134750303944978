import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'antd';
import Text from 'antd/lib/typography/Text';
import Card from '../atoms/Card';
import formatter from '../../utils/formatter';

import './TotalCard.less';
import Lozenges from '../atoms/Lozenges';

TotalCard.propTypes = {
  total: PropTypes.number,
  status: PropTypes.string,
};

export default function TotalCard({ total, status }) {

  return(
    <Card className="card-total">
      <Row justify="space-between" align="middle">
        <Col>
          <Text className="text-total">Total</Text>
        </Col>
        <Col>
          <Row>
            {status && <Lozenges status={status} />}
            <Text level={4} className="price-total">{formatter.currency(total)}</Text>
          </Row>
        </Col>
      </Row>
    </Card>
  );
}