import { PlusOutlined } from '@ant-design/icons';
import { Col, Row } from 'antd';
import Title from 'antd/lib/typography/Title';
import React from 'react';
import Button from '../atoms/Button';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import Pathname from '../../constants/Pathname';

DashboardHello.propTypes = {
  name: PropTypes.string,
};

export default function DashboardHello({ name }) {
  const { push } = useHistory();

  return(
    <Row justify="space-between" align="middle">
      <Col>
        <Title style={{ color: '#5C5C5C' }}>Hello {name}</Title>
        <Title style={{ marginTop: -14, color: '#5C5C5C', marginBottom: 0 }} level={5}>Here is today’s your golf report and activities in an overview</Title>
      </Col>
      <Col>
        <Button onClick={() => push(`${Pathname.TEE_SHEET}`)} icon={<PlusOutlined />}>Add new reservation</Button>
      </Col>
    </Row>
  );
}