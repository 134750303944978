import { Typography } from 'antd';
import React from 'react';
import PropTypes from 'prop-types';
import './TeeSheetDivider.less';

const TeeSheetDivider = (props) => {
  const { label } = props;
  return (
    <div id="divider-tee-time">
      <Typography className="time">{label}</Typography>
      <div className="divider" />
    </div>
  );
};

export default TeeSheetDivider;

TeeSheetDivider.propTypes = {
  label: PropTypes.string.isRequired
};