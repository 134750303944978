import React from 'react';

function SvgKebabMenu() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        fill="currentColor"
        d="M10 5.833A1.667 1.667 0 1010 2.5a1.667 1.667 0 000 3.333zm0 8.334a1.667 1.667 0 100 3.333 1.667 1.667 0 000-3.333zm0-5.834a1.667 1.667 0 100 3.334 1.667 1.667 0 000-3.334z"
      ></path>
    </svg>
  );
}

export default SvgKebabMenu;
