import React, { useMemo } from 'react';
import { Col, Row } from 'antd';
import PropTypes from 'prop-types';
import ReportCard from '../molecules/ReportCard';

const CustomerCheckInsCards = ({
  isPrint,
  totalPaidCheckIns,
  totalCheckIns,
  totalUnpaidCheckIns,
}) => {
  const items = useMemo(() => ([
    {
      title: 'Total check-ins',
      value: `${totalCheckIns} check-ins`
    },
    {
      title: 'Total paid check-ins',
      value: `${totalPaidCheckIns} check-ins`
    },
    {
      title: 'Total unpaid check-ins',
      value: `${totalUnpaidCheckIns} check-ins`
    },
  ]), [
    totalPaidCheckIns,
    totalCheckIns,
    totalUnpaidCheckIns,
  ]);

  return(
    <div id={`${isPrint ? 'print-' : ''}check-ins-cards`}>
      <Row gutter={[16, 16]}>
        {
          items.map(item => (
            <Col
              key={item.title}
              span={8}
            >
              <ReportCard
                title={item.title}
                value={item.value}
              />
            </Col>
          ))
        }
      </Row>
    </div>
  );
};

CustomerCheckInsCards.propTypes = {
  totalPaidCheckIns: PropTypes.number,
  totalCheckIns: PropTypes.number,
  totalUnpaidCheckIns: PropTypes.number,
};

CustomerCheckInsCards.defaultProps = {
  totalPaidCheckIns: 0,
  totalCheckIns: 0,
  totalUnpaidCheckIns: 0,
};

export default CustomerCheckInsCards;