import AddOnsInclusions from '../components/pages/AddOnsInclusions';
import AddOnsInclusionsAddOns from '../components/pages/AddOnsInclusionsAddOns';
import AddOnsInclusionsInclusions from '../components/pages/AddOnsInclusionsInclusions';
import BillingStatements from '../components/pages/BillingStatements';
import Calendar from '../components/pages/Calendar';
import CustomerCheckIns from '../components/pages/CustomerCheckIns';
import CustomerList from 'components/pages/CustomerList';
import CustomerListForm from '../components/pages/CustomerListForm';
import CustomerListDelete from '../components/pages/CustomerListDelete';
import CustomerListDetail from '../components/pages/CustomerListDetail';
import CustomerListDetailReservation from '../components/pages/CustomerListDetailReservation';
import Dashboard from '../components/pages/Dashboard';
import Feedbacks from '../components/pages/Feedbacks';
import GolfCourseSettings from '../components/pages/GolfCourseSettings';
import GolfCourseSettingsClub from '../components/pages/GolfCourseSettingsClub';
import GolfCourseSettingsCourse from '../components/pages/GolfCourseSettingsCourse';
import GolfCourseSettingsPlayer from '../components/pages/GolfCourseSettingsPlayer';
import GolfCourseSettingsTime from '../components/pages/GolfCourseSettingsTime';
import Promotions from '../components/pages/Promotions';
import PromotionsUsage from '../components/pages/PromotionsUsage';
import RaincheckList from '../components/pages/RaincheckList';
import RaincheckSettings from '../components/pages/RaincheckSettings';
import RaincheckSettingsDuration from '../components/pages/RaincheckSettingsDuration';
import RaincheckSettingsLowestPrice from '../components/pages/RaincheckSettingsLowestPrice';
import RaincheckSettingsTermCondition from '../components/pages/RaincheckSettingsTermCondition';
import RateCards from '../components/pages/RateCards';
import RateCardsCancelAction from '../components/pages/RateCardsCancelAction';
import RateCardsCreate from '../components/pages/RateCardsCreate';
import RateCardsCreateInclusions from '../components/pages/RateCardsCreateInclusions';
import RateCardsSelectInclusions from '../components/pages/RateCardsSelectInclusions';
import ReservationsReport from '../components/pages/ReservationsReport';
import RevenueReport from '../components/pages/RevenueReport';
import RoleAccess from '../components/pages/RoleAccess';
import AccountsTabs from '../components/pages/AccountsTabs';
import AccountsSettings from '../components/pages/AccountsSettings';
import RolePermissionsSettings from '../components/pages/RolePermissionsSettings';
import RolePermissions from '../components/pages/RolePermissions';
import SignIn from '../components/pages/SignIn';
import TeeSheet from '../components/pages/TeeSheet';
import TeeTimes from '../components/pages/TeeTimes';
import TypeMemberships from '../components/pages/TypeMemberships';
import TypeMembershipsAddMember from '../components/pages/TypeMembershipsAddMember';
import Pathname from './Pathname';
import CustomerListFilter, {
  CustomerListFilterBirthday,
  CustomerListFilterCreditCard,
  CustomerListFilterGender,
  CustomerListFilterNationality,
  CustomerListFilterType,
} from 'components/pages/CustomerList/CustomerListFilter';
import CustomerListImport from '../components/pages/CustomerListImport';
import CustomerListImportConfirm from '../components/pages/CustomerListImportConfirm';
import CustomerListExport from '../components/pages/CustomerListExport';
import RateCardsDelete from '../components/pages/RateCardsDelete';
import CalendarTeeTimeForm from '../components/pages/CalendarTeeTimeForm';
import TypeMembershipsMemberList from '../components/pages/TypeMembershipsMemberList';
import TypeMembershipsMemberDetail from '../components/pages/TypeMembershipsMemberDetail';
import Splash from '../components/pages/Splash';
import TeeSheetReservationForm from '../components/pages/TeeSheetReservationForm';
import TeeSheetReservationGroupForm from '../components/pages/TeeSheetReservationGroupForm';
import TeeSheetCustomersCheckInDetail from '../components/pages/TeeSheetCustomersCheckInDetail';
import TeeSheetCustomerCheckInDetail from '../components/pages/TeeSheetCustomerCheckInDetail';
import TeeSheetReservationDetail from '../components/pages/TeeSheetReservationDetail';
import TeeTimesSettings from '../components/pages/TeeTimesSettings';
import TeeSheetTakePayment from '../components/pages/TeeSheetTakePayment';
import TeeSheetPaymentSuccess from '../components/pages/TeeSheetPaymentSuccess';
import TeeSheetBlockTeeTimes from '../components/pages/TeeSheetBlockTeeTimes';
import TeeSheetHolesForm from '../components/pages/TeeSheetHolesForm';
import TeeSheetRaincheck from '../components/pages/TeeSheetRaincheck';
import TeeSheetRaincheckIssue from '../components/pages/TeeSheetRaincheckIssue';
import CalendarDailyView from '../components/pages/CalendarDailyView';
import TeeSheetEditCartSigns from '../components/pages/TeeSheetEditCartSigns';
import CalendarTeeTimeParticularForm from '../components/pages/CalendarTeeTimeParticularForm';
import CalendarTeeTimeParticularDelete from '../components/pages/CalendarTeeTimeParticularDelete';
import ExportReservationsReport from '../components/pages/ExportReservationsReport';
import ExportRevenueReport from '../components/pages/ExportRevenueReport';
import ExportCustomerCheckIns from '../components/pages/ExportCustomerCheckIns';
import ReservationDetail from '../components/pages/ReservationDetail';
import SendEmail from '../components/pages/SendEmail';
import VoidCheckIn from '../components/pages/VoidCheckIn';
import RaincheckDetail from '../components/pages/RaincheckDetail';
import VoidRaincheck from '../components/pages/VoidRaincheck';
import ExportRaincheckList from '../components/pages/ExportRaincheckList';
import TeeSheetReservationGroupDetail from '../components/pages/TeeSheetReservationGroupDetail';
import TeeSheetCustomersCheckInGroupDetail from '../components/pages/TeeSheetCustomersCheckInGroupDetail';
import TeeSheetTakePaymentGroup from '../components/pages/TeeSheetTakePaymentGroup';
import PrintRaincheck from '../components/pages/PrintRaincheck';
import TeeSheetPaymentSuccessGroup from '../components/pages/TeeSheetPaymentSuccessGroup';
import TeeSheetRaincheckGroupIssue from '../components/pages/TeeSheetRaincheckGroupIssue';
import RedeemRaincheck from '../components/pages/RedeemRaincheck';
import TeeSheetHolesGroupForm from '../components/pages/TeeSheetHolesGroupForm';
import CourseProfile from 'components/pages/CourseProfile';
import TypeMembershipsDelete from 'components/pages/TypeMembershipDelete';
import CancelReservation from 'components/pages/CancelReservation';
import CollectCancellationFee from 'components/pages/CancelReservation/ModalCancelFee';
import CancellationReport from 'components/pages/CancellationReport';
import ExportCancellationReport from '../components/pages/ExportCancellationReport';
import CancellationPolicy from 'components/pages/CancellationPolicy';

const routes = [
  {
    pathname: Pathname.TEE_SHEET,
    component: TeeSheet,
    featureCategoryCode: 'tee_sheet',
    minAccess: 'is_see_only',
  },
  {
    pathname: Pathname.TEE_SHEET_RESERVATION_FORM,
    component: TeeSheetReservationForm,
    featureCategoryCode: 'tee_sheet',
    minAccess: 'is_editable',
    isModal: true,
  },
  {
    pathname: Pathname.TEE_SHEET_RESERVATION_GROUP_FORM,
    component: TeeSheetReservationGroupForm,
    featureCategoryCode: 'tee_sheet',
    minAccess: 'is_editable',
    isModal: true,
  },
  {
    pathname: Pathname.TEE_SHEET_RESERVATION_DETAIL,
    component: TeeSheetReservationDetail,
    featureCategoryCode: 'tee_sheet',
    minAccess: 'is_editable',
    isModal: true,
  },
  {
    pathname: Pathname.TEE_SHEET_RESERVATION_GROUP_DETAIL,
    component: TeeSheetReservationGroupDetail,
    featureCategoryCode: 'tee_sheet',
    minAccess: 'is_editable',
    isModal: true,
  },
  {
    pathname: Pathname.TEE_SHEET_TAKE_PAYMENT,
    component: TeeSheetTakePayment,
    featureCategoryCode: 'tee_sheet',
    minAccess: 'is_editable',
    isModal: true,
  },
  {
    pathname: Pathname.TEE_SHEET_TAKE_PAYMENT_GROUP,
    component: TeeSheetTakePaymentGroup,
    featureCategoryCode: 'tee_sheet',
    minAccess: 'is_editable',
    isModal: true,
  },
  {
    pathname: Pathname.TEE_SHEET_PAYMENT_SUCCESS,
    component: TeeSheetPaymentSuccess,
    featureCategoryCode: 'tee_sheet',
    minAccess: 'is_editable',
    isModal: true,
  },
  {
    pathname: Pathname.TEE_SHEET_PAYMENT_SUCCESS_GROUP,
    component: TeeSheetPaymentSuccessGroup,
    featureCategoryCode: 'tee_sheet',
    minAccess: 'is_editable',
    isModal: true,
  },
  {
    pathname: Pathname.TEE_SHEET_CUSTOMERS_CHECKIN_DETAIL,
    component: TeeSheetCustomersCheckInDetail,
    featureCategoryCode: 'tee_sheet',
    minAccess: 'is_see_only',
    isModal: true,
  },
  {
    pathname: Pathname.TEE_SHEET_CUSTOMERS_CHECKIN_GROUP_DETAIL,
    component: TeeSheetCustomersCheckInGroupDetail,
    featureCategoryCode: 'tee_sheet',
    minAccess: 'is_see_only',
    isModal: true,
  },
  {
    pathname: Pathname.TEE_SHEET_CUSTOMER_CHECKIN_DETAIL,
    component: TeeSheetCustomerCheckInDetail,
    featureCategoryCode: 'tee_sheet',
    minAccess: 'is_see_only',
    isModal: true,
  },
  {
    pathname: Pathname.TEE_SHEET_BLOCK_TEE_TIMES,
    component: TeeSheetBlockTeeTimes,
    featureCategoryCode: 'tee_sheet',
    minAccess: 'is_see_only',
    isModal: true,
  },
  {
    pathname: Pathname.TEE_SHEET_HOLES_FORM,
    component: TeeSheetHolesForm,
    featureCategoryCode: 'tee_sheet',
    minAccess: 'is_see_only',
    isModal: true,
  },
  {
    pathname: Pathname.TEE_SHEET_HOLES_GROUP_FORM,
    component: TeeSheetHolesGroupForm,
    featureCategoryCode: 'tee_sheet',
    minAccess: 'is_see_only',
    isModal: true,
  },
  {
    pathname: Pathname.TEE_SHEET_RAINCHECK,
    component: TeeSheetRaincheck,
    featureCategoryCode: 'tee_sheet',
    minAccess: 'is_see_only',
    isModal: true,
  },
  {
    pathname: Pathname.TEE_SHEET_EDIT_CART_SIGNS,
    component: TeeSheetEditCartSigns,
    featureCategoryCode: 'tee_sheet',
    minAccess: 'is_see_only',
    isModal: true,
  },
  {
    pathname: Pathname.TEE_SHEET_RAINCHECK_ISSUE,
    component: TeeSheetRaincheckIssue,
    featureCategoryCode: 'tee_sheet',
    minAccess: 'is_see_only',
    isModal: true,
  },
  {
    pathname: Pathname.TEE_SHEET_RAINCHECK_GROUP_ISSUE,
    component: TeeSheetRaincheckGroupIssue,
    featureCategoryCode: 'tee_sheet',
    minAccess: 'is_see_only',
    isModal: true,
  },
  {
    pathname: Pathname.TEE_SHEET_CANCEL_RESERVATION,
    component: CancelReservation,
    featureCategoryCode: 'tee_sheet',
    minAccess: 'is_editable',
    isModal: true,
  },
  {
    pathname: Pathname.COLLECT_CANCELLATION_FEE,
    component: CollectCancellationFee,
    featureCategoryCode: 'tee_sheet',
    minAccess: 'is_editable',
    isModal: true,
  },
  {
    pathname: Pathname.CALENDAR,
    component: Calendar,
    featureCategoryCode: 'calendar',
    minAccess: 'is_see_only',
  },
  {
    pathname: Pathname.CALENDAR_TEE_TIME_PARTICULAR_FORM,
    component: CalendarTeeTimeParticularForm,
    isModal: true,
    featureCategoryCode: 'calendar',
    minAccess: 'is_editable',
  },
  {
    pathname: Pathname.CALENDAR_TEE_TIME_PARTICULAR_DELETE,
    component: CalendarTeeTimeParticularDelete,
    isModal: true,
    featureCategoryCode: 'calendar',
    minAccess: 'is_editable',
  },
  {
    pathname: Pathname.CALENDAR_TEE_TIME_FORM,
    component: CalendarTeeTimeForm,
    isModal: true,
    featureCategoryCode: 'calendar',
    minAccess: 'is_editable',
  },
  {
    pathname: Pathname.CALENDAR_DAILY_VIEW,
    component: CalendarDailyView,
    isModal: true,
    featureCategoryCode: 'calendar',
    minAccess: 'is_editable',
  },
  {
    pathname: Pathname.RATE_CARDS,
    component: RateCards,
    featureCategoryCode: 'rate_cards',
    minAccess: 'is_see_only',
  },
  {
    pathname: Pathname.TEE_TIMES,
    component: TeeTimes,
    featureCategoryCode: 'tee_times',
    minAccess: 'is_see_only',
  },
  {
    pathname: Pathname.TEE_TIMES_SETTINGS,
    component: TeeTimesSettings,
    featureCategoryCode: 'tee_times',
    isModal: true,
    minAccess: 'is_editable',
  },
  {
    pathname: Pathname.CUSTOMER_LIST,
    component: CustomerList,
    featureCategoryCode: 'customers',
    minAccess: 'is_see_only',
  },
  {
    pathname: Pathname.CUSTOMER_LIST_FORM,
    component: CustomerListForm,
    isModal: true,
    featureCategoryCode: 'customers',
    minAccess: 'is_editable',
  },
  {
    pathname: Pathname.CUSTOMER_LIST_DELETE,
    component: CustomerListDelete,
    isModal: true,
    featureCategoryCode: 'customers',
    minAccess: 'is_editable',
  },
  {
    pathname: Pathname.CUSTOMER_LIST_DETAIL,
    component: CustomerListDetail,
    isModal: true,
    featureCategoryCode: 'customers',
    minAccess: 'is_see_only',
  },
  {
    pathname: Pathname.CUSTOMER_LIST_DETAIL_RESERVATION,
    component: CustomerListDetailReservation,
    isModal: true,
    featureCategoryCode: 'customers',
    minAccess: 'is_see_only',
  },
  {
    pathname: Pathname.CUSTOMER_LIST_FILTER,
    component: CustomerListFilter,
    isModal: true,
    featureCategoryCode: 'customers',
    minAccess: 'is_see_only',
  },
  {
    pathname: Pathname.CUSTOMER_LIST_FILTER_TYPE,
    component: CustomerListFilterType,
    isModal: true,
    featureCategoryCode: 'customers',
    minAccess: 'is_see_only',
  },
  {
    pathname: Pathname.CUSTOMER_LIST_FILTER_NATIONALITY,
    component: CustomerListFilterNationality,
    isModal: true,
    featureCategoryCode: 'customers',
    minAccess: 'is_see_only',
  },
  {
    pathname: Pathname.CUSTOMER_LIST_FILTER_GENDER,
    component: CustomerListFilterGender,
    isModal: true,
    featureCategoryCode: 'customers',
    minAccess: 'is_see_only',
  },
  {
    pathname: Pathname.CUSTOMER_LIST_FILTER_CREDIT_CARD,
    component: CustomerListFilterCreditCard,
    isModal: true,
    featureCategoryCode: 'customers',
    minAccess: 'is_see_only',
  },
  {
    pathname: Pathname.CUSTOMER_LIST_FILTER_BIRTHDAY,
    component: CustomerListFilterBirthday,
    isModal: true,
    featureCategoryCode: 'customers',
    minAccess: 'is_see_only',
  },
  {
    pathname: Pathname.CUSTOMER_LIST_IMPORT,
    component: CustomerListImport,
    isModal: true,
    featureCategoryCode: 'customers',
    minAccess: 'is_editable',
  },
  {
    pathname: Pathname.CUSTOMER_LIST_EXPORT,
    component: CustomerListExport,
    isModal: true,
    featureCategoryCode: 'customers',
    minAccess: 'is_editable',
  },
  {
    pathname: Pathname.CUSTOMER_LIST_IMPORT_CONFIRM,
    component: CustomerListImportConfirm,
    featureCategoryCode: 'customers',
    minAccess: 'is_editable',
  },
  {
    pathname: Pathname.TYPES_MEMBERSHIP,
    component: TypeMemberships,
    featureCategoryCode: 'customers',
    minAccess: 'is_see_only',
  },
  {
    pathname: Pathname.TYPES_MEMBERSHIP_CREATE,
    component: TypeMembershipsAddMember,
    isModal: true,
    featureCategoryCode: 'customers',
    minAccess: 'is_editable',
  },
  {
    pathname: Pathname.TYPES_MEMBERSHIP_DELETE,
    component: TypeMembershipsDelete,
    isModal: true,
    featureCategoryCode: 'customers',
    minAccess: 'is_editable',
  },
  {
    pathname: Pathname.TYPES_MEMBERSHIP_MEMBER,
    component: TypeMembershipsMemberList,
    isModal: true,
    featureCategoryCode: 'customers',
    minAccess: 'is_see_only',
  },
  {
    pathname: Pathname.TYPES_MEMBERSHIP_MEMBER_DETAIL,
    component: TypeMembershipsMemberDetail,
    isModal: true,
    featureCategoryCode: 'customers',
    minAccess: 'is_see_only',
  },
  {
    pathname: Pathname.FEEDBACKS,
    component: Feedbacks,
    featureCategoryCode: 'customers',
    minAccess: 'is_see_only',
  },
  {
    pathname: Pathname.PROMOTIONS,
    component: Promotions,
    featureCategoryCode: 'promotions',
    minAccess: 'is_see_only',
  },
  {
    pathname: Pathname.CUSTOMER_CHECK_INS,
    component: CustomerCheckIns,
    featureCategoryCode: 'reports',
    minAccess: 'is_see_only',
  },
  {
    pathname: Pathname.EXPORT_CUSTOMER_CHECK_INS,
    component: ExportCustomerCheckIns,
    featureCategoryCode: 'reports',
    minAccess: 'is_see_only',
    isModal: true,
  },
  {
    pathname: Pathname.RESERVATIONS_REPORT,
    component: ReservationsReport,
    featureCategoryCode: 'reports',
    minAccess: 'is_see_only',
  },
  {
    pathname: Pathname.EXPORT_RESERVATIONS_REPORT,
    component: ExportReservationsReport,
    featureCategoryCode: 'reports',
    minAccess: 'is_see_only',
    isModal: true,
  },
  {
    pathname: Pathname.RESERVATION_DETAIL,
    component: ReservationDetail,
    featureCategoryCode: 'reports',
    minAccess: 'is_see_only',
    isModal: true,
  },
  {
    pathname: Pathname.SEND_EMAIL,
    component: SendEmail,
    featureCategoryCode: 'reports',
    minAccess: 'is_see_only',
    isModal: true,
  },
  {
    pathname: Pathname.VOID_CHECK_IN,
    component: VoidCheckIn,
    featureCategoryCode: 'reports',
    minAccess: 'is_see_only',
    isModal: true,
  },
  {
    pathname: Pathname.REVENUE_REPORT,
    component: RevenueReport,
    featureCategoryCode: 'reports',
    minAccess: 'is_see_only',
  },
  {
    pathname: Pathname.EXPORT_REVENUE_REPORT,
    component: ExportRevenueReport,
    featureCategoryCode: 'reports',
    minAccess: 'is_see_only',
    isModal: true,
  },
  {
    pathname: Pathname.PROMOTIONS_USAGE,
    component: PromotionsUsage,
    featureCategoryCode: 'reports',
    minAccess: 'is_see_only',
  },
  {
    pathname: Pathname.RAINCHECK_LIST,
    component: RaincheckList,
    featureCategoryCode: 'reports',
    minAccess: 'is_see_only',
  },
  {
    pathname: Pathname.RAINCHECK_DETAIL,
    component: RaincheckDetail,
    featureCategoryCode: 'reports',
    minAccess: 'is_see_only',
    isModal: true,
  },
  {
    pathname: Pathname.VOID_RAINCHECK,
    component: VoidRaincheck,
    featureCategoryCode: 'reports',
    minAccess: 'is_see_only',
    isModal: true,
  },
  {
    pathname: Pathname.REDEEM_RAINCHECK,
    component: RedeemRaincheck,
    featureCategoryCode: 'reports',
    minAccess: 'is_see_only',
    isModal: true,
  },
  {
    pathname: Pathname.PRINT_RAINCHECK,
    component: PrintRaincheck,
    featureCategoryCode: 'reports',
    minAccess: 'is_see_only',
    isModal: true,
  },
  {
    pathname: Pathname.EXPORT_RAINCHECK_LIST,
    component: ExportRaincheckList,
    featureCategoryCode: 'reports',
    minAccess: 'is_see_only',
    isModal: true,
  },
  {
    pathname: Pathname.BILLING_STATEMENTS,
    component: BillingStatements,
    featureCategoryCode: 'reports',
    minAccess: 'is_see_only',
  },
  {
    pathname: Pathname.CANCELATION_REPORT,
    component: CancellationReport,
    featureCategoryCode: 'reports',
    minAccess: 'is_see_only',
  },
  {
    pathname: Pathname.EXPORT_CANCELLATION_REPORT,
    component: ExportCancellationReport,
    featureCategoryCode: 'reports',
    minAccess: 'is_see_only',
    isModal: true,
  },
  {
    pathname: Pathname.GOLF_COURSE_SETTINGS,
    component: GolfCourseSettings,
    featureCategoryCode: 'settings',
    featureCode: 'golf_course_settings',
    minAccess: 'is_see_only',
  },
  {
    pathname: Pathname.GOLF_COURSE_SETTINGS_CLUB,
    component: GolfCourseSettingsClub,
    isModal: true,
    featureCategoryCode: 'settings',
    featureCode: 'golf_course_settings',
    minAccess: 'is_editable',
  },
  {
    pathname: Pathname.GOLF_COURSE_SETTINGS_COURSE,
    component: GolfCourseSettingsCourse,
    isModal: true,
    featureCategoryCode: 'settings',
    featureCode: 'golf_course_settings',
    minAccess: 'is_editable',
  },
  {
    pathname: Pathname.GOLF_COURSE_SETTINGS_PLAYER,
    component: GolfCourseSettingsPlayer,
    isModal: true,
    featureCategoryCode: 'settings',
    featureCode: 'golf_course_settings',
    minAccess: 'is_editable',
  },
  {
    pathname: Pathname.CANCELLATION_POLICY,
    component: CancellationPolicy,
    isModal: true,
    featureCategoryCode: 'settings',
    featureCode: 'golf_course_settings',
    minAccess: 'is_editable',
  },
  {
    pathname: Pathname.GOLF_COURSE_SETTINGS_TIME,
    component: GolfCourseSettingsTime,
    isModal: true,
    featureCategoryCode: 'settings',
    featureCode: 'golf_course_settings',
    minAccess: 'is_editable',
  },
  {
    pathname: Pathname.ADD_ONS_INCLUSIONS,
    component: AddOnsInclusions,
    featureCategoryCode: 'settings',
    minAccess: 'is_see_only',
  },
  {
    pathname: Pathname.ADD_ONS_INCLUSIONS_ADD_ONS,
    component: AddOnsInclusionsAddOns,
    isModal: true,
    featureCategoryCode: 'settings',
    minAccess: 'is_editable',
  },
  {
    pathname: Pathname.ADD_ONS_INCLUSIONS_INCLUSIONS,
    component: AddOnsInclusionsInclusions,
    isModal: true,
    featureCategoryCode: 'settings',
    minAccess: 'is_editable',
  },
  {
    pathname: Pathname.RAINCHECK_SETTINGS,
    component: RaincheckSettings,
    featureCategoryCode: 'settings',
    minAccess: 'is_see_only',
  },
  {
    pathname: Pathname.RAINCHECK_SETTINGS_DURATION,
    component: RaincheckSettingsDuration,
    isModal: true,
    featureCategoryCode: 'settings',
    minAccess: 'is_editable',
  },
  {
    pathname: Pathname.RAINCHECK_SETTINGS_TERM_CONDITION,
    component: RaincheckSettingsTermCondition,
    isModal: true,
    featureCategoryCode: 'settings',
    minAccess: 'is_editable',
  },
  {
    pathname: Pathname.RAINCHECK_SETTINGS_LOWEST_PRICE,
    component: RaincheckSettingsLowestPrice,
    isModal: true,
    featureCategoryCode: 'settings',
    minAccess: 'is_editable',
  },
  {
    pathname: Pathname.ROLE_ACCESS,
    component: RoleAccess,
    featureCategoryCode: 'settings',
    featureCode: 'role_access',
    minAccess: 'is_see_only',
  },
  {
    pathname: Pathname.ROLE_ACCESS_ACCOUNTS,
    component: AccountsTabs,
    featureCategoryCode: 'settings',
    featureCode: 'role_access',
    minAccess: 'is_editable',
  },
  {
    pathname: Pathname.ROLE_ACCESS_ACCOUNTS_SETTINGS,
    component: AccountsSettings,
    isModal: true,
    featureCategoryCode: 'settings',
    featureCode: 'role_access',
    minAccess: 'is_editable',
  },
  {
    pathname: Pathname.ROLE_ACCESS_SETTINGS,
    component: RolePermissionsSettings,
    isModal: true,
    featureCategoryCode: 'settings',
    featureCode: 'role_access',
    minAccess: 'is_editable',
  },
  {
    pathname: Pathname.ROLE_ACCESS_ROLES,
    component: RolePermissions,
    featureCategoryCode: 'settings',
    featureCode: 'role_access',
    minAccess: 'is_editable',
  },
  {
    pathname: Pathname.COURSE_PROFILE,
    component: CourseProfile,
    isModal: false,
    featureCategoryCode: 'settings',
    featureCode: 'course_profile',
    minAccess: 'is_editable',
  },
  {
    pathname: Pathname.LOGIN,
    component: SignIn,
    isPublic: true,
  },
  {
    pathname: Pathname.SPLASH,
    component: Splash,
    isPublic: true,
  },
  {
    pathname: Pathname.HOME,
    component: Dashboard,
  },
  {
    pathname: Pathname.RATE_CARDS_FORM,
    component: RateCardsCreate,
    isModal: true,
    featureCategoryCode: 'rate_cards',
    minAccess: 'is_editable',
  },
  {
    pathname: Pathname.RATE_CARDS_DELETE,
    component: RateCardsDelete,
    isModal: true,
    featureCategoryCode: 'rate_cards',
    minAccess: 'is_editable',
  },
  {
    pathname: Pathname.RATE_CARDS_CANCEL_ACTION,
    component: RateCardsCancelAction,
    isModal: true,
    featureCategoryCode: 'rate_cards',
    minAccess: 'is_editable',
  },
  {
    pathname: Pathname.RATE_CARDS_SELECT_INCLUSIONS,
    component: RateCardsSelectInclusions,
    isModal: true,
    featureCategoryCode: 'rate_cards',
    minAccess: 'is_editable',
  },
  {
    pathname: Pathname.RATE_CARDS_FORM_INCLUSIONS,
    component: RateCardsCreateInclusions,
    isModal: true,
    featureCategoryCode: 'rate_cards',
    minAccess: 'is_editable',
  },
];

export default routes;
