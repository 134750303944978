import React, { useRef, useMemo } from 'react';
import { 
  useHistory, 
  useLocation 
} from 'react-router-dom';
import moment from 'moment';
import { useMutation } from '@apollo/client';
import extractQuery from '../../utils/useQuery';
import Modal from '../organisms/Modal';
import TeeSheetHolesFormTemplate from '../templates/TeeSheetHolesForm';
import QueryGql from '../../constants/QueryGql';
import { client } from '../../App';

const TeeSheetHolesForm = () => {
  const history = useHistory();
  const location = useLocation();
  const queryString = extractQuery(location.search);
  const dateTime = queryString.get('date-time');
  const courseId = queryString.get('course-id');
  const statuses = queryString.get('statuses');
  const initialStartingHoles = location.state?.initialStartingHoles;
  const refTeeSheetHolesFormTemplate = useRef();

  const date = moment(dateTime, 'YYYY-MM-DDTHH:mm:ss.SSS[Z]').format('YYYY-MM-DD');
  const time = moment(dateTime, 'YYYY-MM-DDTHH:mm:ss.SSS[Z]').format('HH:mm:ss');

  const [ updateReservationTeeSheetHole, { loading: loadingUpdateReservationTeeSheetHole} ] = useMutation(
    QueryGql.UPDATE_RESERVATION_TEE_SHEET_HOLE,
  );

  const reservations = useMemo(() => {
    return client.readQuery({
      query: QueryGql.GET_RESERVATIONS,
      variables: {
        getReservationsData: {
          date,
          course_id: courseId,
          statuses: JSON.parse(statuses)
        }
      }
    });
  }, [courseId, date]);

  const initialHoles = useMemo(() => {
    let data = [];
    if (initialStartingHoles?.length > 0) {
      initialStartingHoles.forEach(item => {
        const isExistReservation = reservations.getReservations.data
          .find(x => x.date === date 
            && x.date_tee_times === time
            && x.reservation_holes.name === item.value);
        data.push({
          id: item?.id,
          name: item?.value,
          oldName: item?.value,
          disabled: !!isExistReservation,
        });
      });
    } else {
      if (reservations?.getReservations?.data) {
        reservations.getReservations.data.filter(item => item.date === date && item.date_tee_times === time).forEach(item => {
          data.push({
            id: item?.id,
            name: item?.reservation_holes?.name,
            oldName: item?.reservation_holes?.name,
            disabled: true,
          });
        });
      }
    }
    return data;
  }, [reservations]);

  const handleSubmit = async () => {
    const values = refTeeSheetHolesFormTemplate.current.handleSubmit();
    let prepareDataReservation = reservations?.getReservations.data;
    // check online data changed
    const existingHolesChanged = values.filter(value => value.disabled === true && value.oldName !== value.name);
    const newHoles = values.filter(value => !value.disabled);
    
    const create_UUID = () => {
      let dt = new Date().getTime();
      let uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
        let r = (dt + Math.random()*16)%16 | 0;
        dt = Math.floor(dt/16);
        return (c=='x' ? r :(r&0x3|0x8)).toString(16);
      });
      return uuid;
    };
    if (existingHolesChanged.length > 0) {
      try {
        for(const existingHole of existingHolesChanged) {
          const existingReservation = reservations.getReservations.data.find(item => item.reservation_holes.name === existingHole.oldName);
          if (existingReservation) {
            if(existingReservation.created_at !== '') {
              await updateReservationTeeSheetHole({
                variables: {
                  reservationId: existingReservation.id,
                  name: existingHole.name,
                  dateTime
                }
              });
            }
            prepareDataReservation = prepareDataReservation.map(x => {
              if (x.id === existingReservation.id) {
                return {
                  ...x,
                  reservation_holes: {
                    ...x.reservation_holes,
                    name: existingHole.name
                  }
                };
              }
              return x;
            });
          }
        }
      // eslint-disable-next-line no-empty
      } catch (error) {}
    }
    if (newHoles.length > 0) {
      const newReservations = newHoles.map(hole => ({
        date,
        date_tee_times: time,
        reservation_holes: {
          name: hole.name,
          date_time: date,
          __typename: 'ReservationHoleOnly'
        },
        rainchecks: [],
        reservation_customers: [],
        reservation_operators: [],
        reservation_payments: [],
        rate_cards: null,
        tee_times: null,
        course: null,
        deleted_at: '',
        updated_at: '',
        created_at: '',
        checkin_at: '',
        location_id: '',
        block_tee_time_by: null,
        block_tee_times_note: '',
        is_block_tee_time: '',
        group_id: '',
        is_group: false,
        payment_status: '',
        total_amount: 0,
        reservation_message: '',
        reservation_note: '',
        name: '',
        number: '',
        prefix: '',
        id: create_UUID(),
        __typename: 'Reservation',
      }));
      prepareDataReservation = [
        ...prepareDataReservation,
        ...newReservations,
      ];
    }
    client.writeQuery({
      query: QueryGql.GET_RESERVATIONS,
      variables: {
        getReservationsData: {
          date,
          course_id: courseId,
          statuses: JSON.parse(statuses)
        }
      },
      data: {
        getReservations: {
          ...reservations.getReservations,
          data: prepareDataReservation
        }
      },
    });
    history.goBack();
  };

  return (
    <Modal
      title="Add new holes"
      hiddenLeftFooterButton
      rightFooterButton={{
        label: 'Add holes',
        loading: loadingUpdateReservationTeeSheetHole,
        onClick: handleSubmit
      }}
      width={540}
    >
      <TeeSheetHolesFormTemplate initialHoles={initialHoles} ref={refTeeSheetHolesFormTemplate} />
    </Modal>
  );
};

export default TeeSheetHolesForm;