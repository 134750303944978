import { Col, Row, Checkbox, Tooltip } from 'antd';
import Icon from '@ant-design/icons';
import Text from 'antd/lib/typography/Text';
import Title from 'antd/lib/typography/Title';
import React, { useCallback, useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import Card from '../atoms/Card';
import './RateCardsFormInclusions.less';
import SvgInfo from '../atoms/SvgInfo';
import InputText from '../atoms/InputText';
import Button from '../atoms/Button';
import { useHistory } from 'react-router-dom';
import Pathname from '../../constants/Pathname';

const RateCardsFormInclusions = ({ initialValues, inclusions }, ref) => {

  const history = useHistory();
  const [visibleInclusion, setVisibleInclusion] = useState(inclusions.map(inclusion => ({ label: inclusion.name, value: inclusion.id})));
  const [values, setValues] = useState(initialValues);

  useImperativeHandle(ref, () => ({
    handleSubmit: () => values
  }));

  useEffect(() => {
    setVisibleInclusion(inclusions.map(inclusion => ({ label: inclusion.name, value: inclusion.id})));
  }, [inclusions]);

  const handleChangeSearch = useCallback(e => {
    if (e.target.value === '') {
      setVisibleInclusion(inclusions.map(inclusion => ({ label: inclusion.name, value: inclusion.id})));
    } else {
      let found = inclusions.filter((inclusion) => {
        return inclusion.name.toLocaleLowerCase().includes(e.target.value.toLocaleLowerCase());
      });
      setVisibleInclusion(found.map(inclusion => ({ label: inclusion.name, value: inclusion.id})));
    }
  });

  return (
    <div id="rate-cards-form-inclusions">
      <Row gutter={12}>
        <Col>
          <Title style={{ marginBottom: 10 }} level={4}>Inclusions</Title>
        </Col>
        <Col>
          <Tooltip placement="right" title="Items that will be included freely for golfers when they reserve a tee time using this rate card.">
            <Icon component={SvgInfo} className="icon-info" />
          </Tooltip>
        </Col>
      </Row>
      <InputText type="search" placeholder="Search for inclusions" onChange={handleChangeSearch} />
      <Checkbox.Group defaultValue={values} className="list-inclusion" onChange={setValues}>
        {visibleInclusion.map((inclusion) => (
          <Card key={inclusion.value} className="item-inclusion">
            <Row justify="space-between" align="middle">
              <Col>
                <Text className="label">{inclusion?.label}</Text>
              </Col>
              <Col>
                <Checkbox value={inclusion?.value} />
              </Col>
            </Row>
          </Card>
        ))}
      </Checkbox.Group>
      <Card className="item-inclusion">
        <Row justify="center" align="middle">
          <Col>
            <Button
              onClick={() => history.push(
                Pathname.RATE_CARDS + '/' +
                Pathname.RATE_CARDS_FORM + '/'+
                Pathname.ADD_ONS_INCLUSIONS_INCLUSIONS +
                '?act=add',
                {
                  background: true,
                }
              )}
              type="guide"
            >
              Add new inclusion item
            </Button>
          </Col>
        </Row>
      </Card>
    </div>
  );
};

export default forwardRef(RateCardsFormInclusions);

RateCardsFormInclusions.propTypes = {
  initialValues: PropTypes.arrayOf(PropTypes.string),
  inclusions: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired
  })).isRequired,
  // onChange: PropTypes.func
};

RateCardsFormInclusions.defaultProps = {
  initialValues: []
};