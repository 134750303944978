import { Col, Row, Space } from 'antd';
import React, { useMemo, useRef } from 'react';
import moment from 'moment';
import Text from 'antd/lib/typography/Text';
import Card from '../atoms/Card';
import Button from '../atoms/Button';
import Avatar from '../atoms/Avatar';

import ReservationSteps from '../organisms/ReservationSteps';
import ItemRaincheck from '../molecules/ItemRaincheck';
import Pathname from '../../constants/Pathname';
import { useHistory } from 'react-router-dom';
import stepFormatter from '../../utils/stepFormatter';
import PrintReceipt from './PrintReceipt';
import { useReactToPrint } from 'react-to-print';

export default function RaincheckDetailTemplate({ raincheck, reservation, isPrint }) {
  const { push } = useHistory();
  const printComponentRef = useRef();
  const handlePrintReceipt = useReactToPrint({
    content: () => printComponentRef.current,
  });

  const cards = useMemo(() => {
    const items = [];

    reservation?.reservation_customers?.map(item => {
      items.push({
        title: item.customer.full_name,
        description: item.bag_id,
        avatar: true,
        button: 'View customer',
        onClick: () => push(Pathname.CUSTOMER_LIST_DETAIL + '?id=' + item.customer.id, { background: true })
      });
    });

    const payments = [];
    let paymentCode = '';
    reservation?.reservation_payments?.map((item)=> {
      paymentCode = item.code;

      item.payment_methods?.map(pay => {
        if (!payments.includes(pay.name)) {
          payments.push(pay.name);
        }
      });
    });

    items.push({
      title: 'Receipt ' + paymentCode,
      description: 'Paid by ' + payments.join(', '),
      button: 'View receipt',
      onClick: handlePrintReceipt
    });

    items.push({
      title: 'Reservation ' + reservation?.prefix + '-' + reservation?.number,
      description: 'Tee time: ' + moment(reservation?.date_tee_times, 'HH:mm:ss').format('LT'),
      button: 'View reservation',
      onClick: () => push(Pathname.RAINCHECK_LIST + '/' + Pathname.TEE_SHEET_CUSTOMERS_CHECKIN_DETAIL + '?id=' + reservation.id, { background: true })
    });

    return items;
  }, [reservation]);



  const reservations = useMemo(() => {
    return reservation?.reservation_operators?.map(item => {
      let step = stepFormatter(item.step);

      return {
        title: `Reservation ${step} by ${item.full_name} (Operator)`,
        description: moment(item.created_at).format('hh:mm A, DD/MM/YYYY'),
      };
    });
  }, [reservation]);
  
  return (
    <div>
      <Space style={{ width: '100%' }} direction="vertical" size={24}>
        <ItemRaincheck
          issuedBy={raincheck?.issued_by}
          number={raincheck?.code}
          status={raincheck?.status}
          timeIssued={moment(raincheck?.created_at).format('L - LT')}
          courseNumber={`${raincheck?.course?.name} (${raincheck?.course?.holes})`}
          expiryDate={moment(raincheck?.expiration_date, 'YYYY-MM-DD').format('L')}
        />
        {
          cards.map(item => (
            <Card key={item.title}>
              <Row
                justify="space-between"
                align="middle"
              >
                <Col>
                  <Row gutter={24} align="middle">
                    {
                      item.avatar && (
                        <Col>
                          <Avatar title={item.title} />
                        </Col>
                      )
                    }
                    <Col>
                      <div>
                        <Text strong>{item.title}</Text>
                      </div>
                      <Text style={{ fontSize: 12 }}>{item.description}</Text>
                    </Col>
                  </Row>
                </Col>
                <Col>
                  { isPrint ? null : <Button
                    type="outline"
                    onClick={item.onClick}
                  >
                    {item.button}
                  </Button> }
                </Col>
              </Row>
            </Card>
          ))
        }
        <Card>
          <ReservationSteps
            data={reservations || []}
          />
        </Card>
      </Space>
      <PrintReceipt
        data={reservation}
        printComponentRef={printComponentRef}  
      />
    </div>
  );
}