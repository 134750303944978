import Layout from 'antd/lib/layout/layout';
import React from 'react';
import PropTypes from 'prop-types';
import Drawer from '../molecules/Drawer';
import './AppContent.less';

const AppContent = ({ children, collapsedDrawer }) => (
  <Layout className="app-content">
    <Drawer collapsed={collapsedDrawer} />
    <Layout className="app-body-content">
      {children}
    </Layout>
  </Layout>
);

export default AppContent;


AppContent.propTypes = {
  children: PropTypes.node.isRequired
};