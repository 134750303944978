import Title from 'antd/lib/typography/Title';
import React from 'react';
import AppWrapper from '../templates/AppWrapper';

const Promotions = () => (
  <AppWrapper>
    <Title level={1}>Promotions Page</Title>
  </AppWrapper>
);

export default Promotions;