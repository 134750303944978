/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import {
  Route,
  BrowserRouter as Router,
  Switch,
  useLocation,
  Redirect,
  useHistory,
} from 'react-router-dom';
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  HttpLink,
  ApolloLink,
  from,
  split,
} from '@apollo/client';
import { notification } from 'antd';
import { onError } from '@apollo/client/link/error';
import numeral from 'numeral';
import './styles/App.less';
import routes from './constants/routes';
import { authFromLocal } from './utils/function';
import Pathname from './constants/Pathname';
import checkFeature from './utils/checkFeature';
import config from './constants/config';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';

let locationPage;

const httpLink = new HttpLink({
  uri: config.graphql,
  credentials: 'same-origin',
});

const wsLink = new WebSocketLink({
  uri: config.ws,
  options: {
    reconnect: true,
    connectionParams: () => {
      const auth = authFromLocal();
      const token = auth?.login.token;
      return {
        Authorization: token ? `Bearer ${token}` : '',
        locationid: '02321053-9015-4554-aa01-8e0b559744a6',
      };
    }
  }
});

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  httpLink,
);

const excludeError = ['calendar doesn\'t exist'];
const errorLink = onError(res => {
  const { graphQLErrors, networkError } = res;

  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) => {
      console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);
      if (!excludeError.includes(message)) {
        notification.error({
          description: message,
        });
      }
    });
  }

  if (networkError) {
    console.log(`[Network error]: ${networkError}`);
    notification.error({
      message: 'Network error',
      description: networkError.toString(),
    });
  }
});

const authMiddleware = new ApolloLink((operation, forward) => {
  const auth = authFromLocal();
  const token = auth?.login.token;
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      Authorization: token ? `Bearer ${token}` : '',
      locationid: '02321053-9015-4554-aa01-8e0b559744a6',
    },
  }));

  return forward(operation);
});

export const client = new ApolloClient({
  link: from([errorLink, authMiddleware, splitLink]),
  cache: new InMemoryCache(),
  connectToDevTools: true
});

const PrivateRouter = ({ children, ...rest }) => {
  const history = useHistory();
  return (
    <Route
      {...rest}
      render={({ location }) => {
        const auth = authFromLocal();
        if (auth) {
          // FOR SPECIFIC FEATURE
          // if (rest.featureCode && rest.minAccess) {
          //   const isGrantAccess = checkFeature({
          //     featureCode: rest.featureCode,
          //     minAccess: rest.minAccess
          //   });
          //   if (!isGrantAccess) {
          //     history.goBack();
          //     return null;
          //   }
          // }
          // FOR CATEGORY FEATURE
          if (rest.featureCategoryCode && rest.minAccess) {
            const isGrantAccess = checkFeature({
              featureCategoryCode: rest.featureCategoryCode,
              minAccess: rest.minAccess,
            });
            if (!isGrantAccess) {
              notification.error({
                description: 'User&#8216;s access insufficient',
              });
              history.goBack();
              return null;
            }
          }
          return children;
        }
        return (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location },
            }}
          />
        );
      }}
    />
  );
};

const ConfigRouter = () => {
  const history = useHistory();
  const location = useLocation();
  const background = location.state && location.state.background;
  if (!background) {
    locationPage = location;
  }

  const auth = authFromLocal();

  useEffect(() => {
    numeral.register('locale', 'id', {
      delimiters: {
        thousands: '.',
        decimal: ',',
      },
      abbreviations: {
        thousand: 'k',
        million: 'm',
        billion: 'b',
        trillion: 't',
      },
      currency: {
        symbol: 'Rp',
      },
    });
    numeral.locale('id');
    if (auth && location.pathname !== '/login') {
      history.replace(Pathname.SPLASH, {
        from: location,
      });
    }
  }, []);

  return (
    <React.Fragment>
      <Switch location={locationPage}>
        {routes.map((route, index) => {
          if (route?.isPublic) {
            return (
              <Route
                key={index.toString()}
                path={route.pathname}
                exact={route.exact}
                render={props => <route.component {...props} />}
              />
            );
          }
          return (
            <PrivateRouter
              key={index.toString()}
              path={route.pathname}
              exact={route.exact}
              featureCode={route.featureCode}
              featureCategoryCode={route.featureCategoryCode}
              minAccess={route.minAccess}>
              <route.component />
            </PrivateRouter>
          );
        })}
      </Switch>

      {/* Show the modal when a background page is set */}
      {background &&
        routes
          .filter(route => route.isModal)
          .map(route => (
            <PrivateRouter
              key={route.pathname}
              path={`*/${route.pathname}`}
              featureCode={route.featureCode}
              featureCategoryCode={route.featureCategoryCode}
              minAccess={route.minAccess}>
              <route.component />
            </PrivateRouter>
          ))}
    </React.Fragment>
  );
};

export const AppContext = React.createContext();

function appReducer(state, action) {
  switch (action.type) {
    case 'DRAWER_SHOW': {
      return {
        drawer: 'show',
      };
    }
    case 'DRAWER_HIDE': {
      return {
        drawer: 'hide',
      };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function App() {
  const [store, setStore] = useState({
    searchCustomerFilter: {
      customer_types: null,
      birthday: {
        from: null,
        to: null,
      },
      nationality: null,
      gender: null,
    },
  });

  const actions = {
    createCustomerFilter: value => {
      setStore({ ...store, searchCustomerFilter: { ...store.searchCustomerFilter, ...value } });
    },
  };

  const [state, dispatch] = React.useReducer(appReducer, { drawer: 'show' });
  const value = { state, dispatch, store, actions };

  return (
    <AppContext.Provider value={value}>
      <Router>
        <ApolloProvider client={client}>
          <ConfigRouter />
        </ApolloProvider>
      </Router>
    </AppContext.Provider>
  );
}

export default App;
