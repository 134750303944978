import React from 'react';
import Icon from '@ant-design/icons';
import { Col, Dropdown, Row } from 'antd';
import Title from 'antd/lib/typography/Title';
import Text from 'antd/lib/typography/Text';
import PropTypes from 'prop-types';
import Card from '../atoms/Card';
import SvgTag from '../atoms/SvgTag';
import SvgMoreOutline from '../atoms/SvgMoreOutline';
import './CardListItem.less';
import IconButton from '../atoms/IconButton';

const CardListItem = props => {
  const { name, title, icon, onClick, contextMenu, color, start_time, end_time, tee_time_types, status, price, sumOfCustomers } =
    props;
  return (
    <div id="card-list-item">
      <Card hoverable onClick={onClick}>
        <Row justify="space-between">
          <Col>
            {icon === null ? (
              <>
                <Title className="title" level={5}>
                  {name}
                  {title}
                </Title>
                <div>
                  <Text className="status-text">{status}</Text>
                </div>
                <Text className="paid-desc">{price}</Text>
                <Title className="sum-customers" level={5}>
                  {sumOfCustomers} customers
                </Title>
              </>
            ) : (
              <div className="icon">
                <Icon component={icon} style={{ fontSize: 40, color: color }} />
              </div>
            )}
          </Col>
          <Col>
            <Dropdown trigger={['hover', 'click']} overlay={contextMenu}>
              <div>
                <IconButton iconSvg={SvgMoreOutline} iconColor="primary" />
              </div>
            </Dropdown>
          </Col>
        </Row>
        {icon !== null && (
          <Title className="title" level={5}>
            {name}
          </Title>
        )}
        {start_time && end_time && <Text className="desc">{`${start_time} - ${end_time}`}</Text>}
        {tee_time_types && <Text className="desc type-color">{tee_time_types}</Text>}
      </Card>
    </div>
  );
};

export default CardListItem;

CardListItem.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  status: PropTypes.string,
  color: PropTypes.string,
  start_time: PropTypes.string,
  end_time: PropTypes.string,
  tee_time_types: PropTypes.string,
  price: PropTypes.string,
  sumOfCustomers: PropTypes.string,
  icon: PropTypes.node,
  onClick: PropTypes.func,
  contextMenu: PropTypes.node,
};

CardListItem.defaultProps = {
  icon: SvgTag,
  color: '#06884A',
};
