import { Button, Input, Select, Table } from 'antd';
import Text from 'antd/lib/typography/Text';
import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import './TeeSheetTakePaymentPaymentMethodItem.less';

const { Option } = Select;

const TeeSheetTakePaymentPaymentMethodItem = (props) => {
  const { optionsPaymentMethods, totalAmount, onChange, disabledButtonAdd } = props;

  const [values, setValues] = useState([]);

  useEffect(() => {
    setValues([{
      type: 'cash',
      amount: totalAmount
    }]);
  }, [totalAmount]);

  const handleChangeAmount = (index) => (e) => {
    const value = e.target.value;
    setValues(values.map((x, i) => {
      if (i === index) {
        return {
          ...x,
          amount: Number(value),
        };
      }
      return x;
    }));
  };

  const data = useMemo(() => {
    return values.map((value, index) => ({
      '0': (
        <Select
          size="small"
          className="select-payment-type"
          defaultValue={value.type}
          onSelect={e => {
            setValues(values.map((x, i) => {
              if (i === index) {
                return {
                  ...x,
                  type: e,
                };
              }
              return x;
            }));
          }}
          bordered={false}
        >
          {optionsPaymentMethods.map(item => (
            <Option key={item.value} value={item.value}>{item.label}</Option>
          ))}
        </Select>
      ),
      '1': (
        <Input
          prefix={<Text type="secondary">Rp.</Text>}
          value={value.amount}
          onChange={handleChangeAmount(index)}
          type="number"
          bordered={false}
          className="input-price"
          size="small"
        />
      ),
    }));
  }, [values]);

  const columns = useMemo(() => [
    {
      dataIndex: '0',
      width: '50%'
    },
    {
      dataIndex: '1',
      width: '50%'
    },
  ], []);

  useEffect(() => {
    onChange(values);
  }, [values]);

  const handleAddPaymentType = () => {
    const currentLengthPayment = values.length;
    const updatedAmount = totalAmount / (currentLengthPayment + 1);
    const newValues = values.map(item => ({
      ...item,
      amount: updatedAmount
    }));
    setValues([
      ...newValues,
      {
        type: 'cash',
        amount: updatedAmount
      }
    ]);
  };

  return (
    <div id="tee-sheet-take-payment-payment-method-item">
      <Table
        columns={columns}
        dataSource={data}
        bordered
        showHeader={false}
        pagination={false}
        footer={() => (
          <Button disabled={disabledButtonAdd} onClick={handleAddPaymentType} type="text">+ Add another payment method</Button>
        )}
      />
    </div>
  );
};

export default TeeSheetTakePaymentPaymentMethodItem;

TeeSheetTakePaymentPaymentMethodItem.propTypes = {
  optionsPaymentMethods: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string
  })),
  totalAmount: PropTypes.number,
  onChange: PropTypes.func,
  disabledButtonAdd: PropTypes.bool
};

TeeSheetTakePaymentPaymentMethodItem.defaultProps = {
  optionsPaymentMethods: [],
  totalAmount: 0,
  disabledButtonAdd: false
};