/* eslint-disable max-len */
import React from 'react';

function SvgCalendar() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="none"
      viewBox="0 0 14 16"
    >
      <path
        fill="currentColor"
        d="M7 12.667a.667.667 0 100-1.334.667.667 0 000 1.334zm3.333 0a.667.667 0 100-1.335.667.667 0 000 1.335zm0-2.667a.667.667 0 100-1.334.667.667 0 000 1.334zM7 10a.667.667 0 100-1.333A.667.667 0 007 10zm4.667-8H11v-.667a.667.667 0 10-1.333 0V2H4.333v-.667a.667.667 0 10-1.333 0V2h-.667a2 2 0 00-2 2v9.333a2 2 0 002 2h9.334a2 2 0 002-2V4a2 2 0 00-2-2zm.666 11.333a.667.667 0 01-.666.667H2.333a.667.667 0 01-.666-.667v-6h10.666v6zm0-7.333H1.667V4a.667.667 0 01.666-.667H3V4a.667.667 0 001.333 0v-.667h5.334V4A.667.667 0 0011 4v-.667h.667a.667.667 0 01.666.667v2zm-8.666 4a.667.667 0 100-1.333.667.667 0 000 1.333zm0 2.667a.667.667 0 100-1.334.667.667 0 000 1.334z"
      />
    </svg>
  );
}

export default SvgCalendar;
