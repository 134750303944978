import { Col, Row, Space } from 'antd';
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import SingleSelect from '../molecules/SingleSelect';

import AppWrapper from '../templates/AppWrapper';
import { useHistory } from 'react-router-dom';
import Pathname from '../../constants/Pathname';
import DateRangePicker from '../molecules/DateRangePicker';
import ExportButton from '../molecules/ExportButton';
import { useQuery } from '@apollo/client';
import QueryGql from '../../constants/QueryGql';
import './RevenueReport.less';
import RevenueReportTemplate from '../templates/RevenueReport';
import useGetCourses from '../../hooks/useGetCourses';


const RevenueReport = () => {
  
  const history = useHistory();
  const [openDate, setOpenDate] = useState(true);
  const [date, setDate] = useState([moment().subtract(1, 'M'), moment()]);
  const [course, setCourse] = useState({ key: 'all', label: 'All golf courses', value: 'all' });

  const isDaily = moment(date[0]).diff(date[1], 'day') === 0;


  const { optionsCourses } = useGetCourses();

  const { refetch, data: dataRevenueReport } = useQuery(
    QueryGql.GET_REVENUE_REPORT, {
      variables: {
        data: {
          start_date: moment(date[0]).format('YYYY-MM-DD'),
          end_date: moment(date[1]).format('YYYY-MM-DD'),
          course_id: course.value === 'all' ? null : course.value,
          'revenue_by_green_fee': true,
          'revenue_by_customer_memberships': true,
          'revenue_by_payment_methods': true,
          'revenue_by_add_ons': true
        }
      },
      fetchPolicy: 'cache-and-network'
    }
  );

  const dataReport = dataRevenueReport?.getRevenueReport.data || {};

  const handleClickExport = () => {
    history.push(`${Pathname.REVENUE_REPORT}/${Pathname.EXPORT_REVENUE_REPORT}`, { background: true });
  };

  const handleChangeCourse = (item) => {
    setCourse(item);
  };
  
  const handleChangeDate = (item) => {
    setDate(item);
  };

  useEffect(() => {
    if (!openDate) {
      refetch();
    }
  }, [openDate]);


  return (
    <AppWrapper>
      <Space style={{ marginTop: 32, paddingBottom: 32 }} size={32} direction="vertical">
        <Row
          justify="space-between"
        >
          <Col>
            <Space>
              <DateRangePicker
                allowClear={false}
                defaultValue={date}
                onChange={handleChangeDate}
                onOpenChange={(v) => setOpenDate(v)}
              />
              <SingleSelect
                items={optionsCourses}
                value={course.value}
                valueLabel={course.label}
                onSelect={handleChangeCourse}
              />
            </Space>
          </Col>
          <Col>
            <ExportButton 
              onClick={handleClickExport}
            />
          </Col>
        </Row>
        <div>
          <RevenueReportTemplate course={course} isDaily={isDaily} dataReport={dataReport} />
        </div>
      </Space>
    </AppWrapper>
  );
};

export default RevenueReport;