import React from 'react';
import PropTypes from 'prop-types';
import Title from 'antd/lib/typography/Title';
import Text from 'antd/lib/typography/Text';
import { BarChart, Bar, YAxis, Legend, ResponsiveContainer, Tooltip, CartesianGrid } from 'recharts';
import TooltipChart from '../molecules/TooltipChart';

import './ReservationBarChart.less';

const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    return (
      <TooltipChart 
        title={payload[0].payload.name}
        value={`${payload[0].value} reservations`}
      />
    );
  }

  return null;
};

const ReservationBarChart = ({ data, isAnimationActive, course }) => {

  return (
    <div id="reservation-bar-chart" className="reservation-bar-chart">
      <Title className="title" level={4}>Number of reservations</Title>
      <div>
        <ResponsiveContainer width="100%" height={340}>
          <BarChart
            data={data}
            margin={{
              top: 30,
              right: 20,
              left: 20,
              bottom: 5,
            }}
            
          >
            <CartesianGrid
              vertical={false}
              stroke="#F5F5F5"
              strokeWidth={1}
            />
            <Tooltip
              cursor={false}
              content={<CustomTooltip />}
              allowEscapeViewBox={{ x: true, y: true }}
              position={{ y: -60, }}
              wrapperStyle={{ marginLeft: -106 }}
            />
            <YAxis
              tickLine={false}
              axisLine={false}
              tick={ { fill: '#8F8F8F', style: { fontSize: 14 } }}
            />
            <Legend
              align="left"
              iconType="circle"
              height={36}
              iconSize={10}
              wrapperStyle={{ height: 40, paddingTop: 30 }}
              formatter={() => <Text>{course || 'All golf courses'}</Text>}
            />
            <Bar
              radius={[8, 8, 0, 0]}
              maxBarSize={32}
              dataKey="value"
              fill="#1F4BFF"
              isAnimationActive={isAnimationActive}
            />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

ReservationBarChart.propTypes = {
  course: PropTypes.string,
  isAnimationActive: PropTypes.bool,
  data: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.number
  }))
};

ReservationBarChart.defaultProps = {
  data: [],
};

export default ReservationBarChart;