import { Col, Row } from 'antd';
import React, { useState } from 'react';
import HorizontalDateRange from '../molecules/HorizontalDateRange';
import SingleSelect from '../molecules/SingleSelect';
import Modal from '../organisms/Modal';
import FileSelectExport from '../molecules/FileSelectExport';
import { useFormik } from 'formik';
import useGetCourses from '../../hooks/useGetCourses';
import MultipleSelect from '../molecules/MultipleSelect';
import { useQuery } from '@apollo/client';
import QueryGql from '../../constants/QueryGql';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import moment from 'moment';
import RaincheckCards from '../organisms/RaincheckCards';
import { buildRaincheck } from './RaincheckList';
import sleep from '../../utils/sleep';
import { downloadCSV } from '../../utils/csv';

const ExportRaincheckList = () => {
  const { optionsCourses } = useGetCourses();
  const [reportStatuses] = useState([
    { key: 'all', value: 'all', label: 'All status' },
    { key: 'active', value: 'active', label: 'Active' },
    { key: 'void', value: 'void', label: 'Void' },
    { key: 'redeemed', value: 'redeemed', label: 'Redeemed' },
    { key: 'expired', value: 'expired', label: 'Expired' },
  ]);

  const [dataReport, setDataReport] = useState({});
  const summary = dataReport?.summary || {};

  const { refetch } = useQuery(QueryGql.GET_RAINCHECKS, {
    skip: true,
  });

  const { values, setFieldValue, isSubmitting, submitForm } = useFormik({
    initialValues: {
      statuses: ['all'],
      file: { name: 'File', key: 'pdf', label: 'PDF' },
      course: { key: 'all', label: 'All golf courses', value: 'all' }
    },
    onSubmit: async ({ date, file, statuses, course }, { setSubmitting }) => {

      try {
        const res = await refetch({
          data: {
            start_date: moment(date[0]).startOf('day').toISOString(),
            end_date: moment(date[1]).endOf('day').toISOString(),
            course_id: course.value === 'all' ? null : course.value,
            statuses: statuses.includes('all') ? null : statuses,
          }
        });

        const data = res.data.getRainchecks.data;
        setDataReport(data);

        const columns = [
          { header: 'Customer', dataKey: 'name' },
          { header: 'Date issues', dataKey: 'time' },
          { header: 'Status', dataKey: 'status' },
          { header: 'Code', dataKey: 'code' },
          { header: 'Expiry date', dataKey: 'expired' },
          { header: 'Amount', dataKey: 'amount' },
        ];

        const body = buildRaincheck(data.rainchecks || []);

        if (file.key === 'csv') {
          const headers = columns.map((item) => ({
            label: item.header,
            key: item.dataKey
          }));

          downloadCSV({ headers, body, name: file.name });
        }

        if (file.key === 'pdf') {

          await sleep(1000);
          const canvas = await html2canvas(document.getElementById('print-raincheck-cards'));

          const imgData = canvas.toDataURL('image/png');
          const doc = new jsPDF('p', 'mm', 'a4');

          const width = doc.internal.pageSize.getWidth();
          doc.addImage(imgData, 'PNG', 5, 5, width - 10, 0);



          autoTable(doc, {
            headStyles: {
              fillColor: [245, 245, 245],
              textColor: [0, 13, 35],
              fontStyle: 'bold',
              minCellHeight: 12,
              valign: 'middle',
              cellPadding: { left: 2 },
            },
            alternateRowStyles: {
              fillColor: [255, 255, 255],
              cellPadding: { left: 2, top: 2, bottom: 2 },
            },
            margin: { top: 28, left: 5, right: 5 },
            columns,
            body
          });

          doc.save(`${file.name}.pdf`);

        }
        setSubmitting(false);
      } catch (error) {
        setSubmitting(false);
      }
    }
  });

  return (
    <Modal
      title='Export raincheck list'
      width={600}
      hiddenLeftFooterButton
      rightFooterButton={{
        label: 'Export raincheck list',
        loading: isSubmitting,
        state: values.date ? 'default' : 'disabled',
        onClick: submitForm
      }}
    >
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <FileSelectExport
            value={values.file}
            onChange={(item) => setFieldValue('file', item)}
          />
        </Col>
        <Col span={24}>
          <SingleSelect
            label="Golf courses"
            items={optionsCourses}
            fullWidth
            type="text"
            size="large"
            value={values.course.value}
            valueLabel={values.course.label}
            onSelect={(v) => setFieldValue('course', v)}
          />
        </Col>
        <Col span={24}>
          <MultipleSelect
            label="Raincheck status"
            items={reportStatuses}
            values={values.statuses}
            value={values.statuses.includes('all') ? 'All status' : values.statuses.join(', ')}
            fullWidth
            type="text"
            size="large"
            handleChange={(v) => setFieldValue('statuses', v)}
          />

        </Col>
        <Col span={24}>
          <HorizontalDateRange
            label="Date"
            onChange={(v) => setFieldValue('date', v)}
          />
        </Col>
      </Row>
      <div style={{ position: 'fixed', bottom: -5000, width: '80%' }}>
        <RaincheckCards
          isPrint={true}
          totalRaincheck={summary.total_raincheck}
          totalRedeemed={summary.total_redeemed}
          reservationPrecentage={summary.raincheck_percentage}
        />
      </div>
    </Modal>
  );
};

export default ExportRaincheckList;