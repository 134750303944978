import { DownOutlined } from '@ant-design/icons';
import { Button, List, Table, Dropdown, Menu } from 'antd';
import Text from 'antd/lib/typography/Text';
import Title from 'antd/lib/typography/Title';
import PropTypes from 'prop-types';
import React, { useMemo, useState, useEffect } from 'react';
import Avatar from '../atoms/Avatar';
import './TeeSheetPaymentSuccess.less';

const TeeSheetPaymentSuccess = (props) => {

  const { receiptTypes, customers, onChange } = props;

  const [ values, setValues ] = useState([]);

  const columns = useMemo(() => [
    {
      dataIndex: 'user',
      width: 463
    },
    {
      dataIndex: 'type',
      width: 160
    },
    {
      dataIndex: 'value',
    },
  ], []);

  const optionsType = (index) => (
    <Menu>
      {receiptTypes.map(item => (
        <Menu.Item key={item.value} onClick={() => {
          setValues(values.map((x, i) => {
            if (i === index) {
              return {
                ...x,
                receipt_method: item.value,
              };
            }
            return x;
          }));
        }}>
          <Text>{item.label}</Text>
        </Menu.Item>
      ))}
    </Menu>
  );

  const data = useMemo(() => {
    return values.map((item, index) => ({
      'user': (
        <List.Item>
          <List.Item.Meta
            avatar={<Avatar title={item.name} style={{marginTop: 2}} />}
            title={item.name}
            description={item.number}
          />
        </List.Item>
      ),
      'type': (
        <Dropdown trigger={['hover', 'click']} overlay={optionsType(index)}>
          <Button size="small" type="link" block className="dropdown-type">
            {receiptTypes.filter(x => x.value === item.receipt_method)?.[0].label}
            <DownOutlined />
          </Button>
        </Dropdown>
      ),
      'value': item.receipt_method === 'email' ? item.email : item.phone_number 
    }));
  }
  , [values]);

  useEffect(() => {
    if (receiptTypes.length > 0) {
      setValues(customers.map(item => ({
        ...item,
        receipt_method: receiptTypes[0].value
      })));
    }
  }, [receiptTypes]);

  useEffect(() => {
    onChange(values);
  }, [values]);

  return (
    <div id="tee-sheet-payment-success">
      <Title className="title" level={4}>Select a method to send the receipt to the customers</Title>
      <Table
        columns={columns}
        dataSource={data}
        bordered
        pagination={false}
        showHeader={false}
      />
    </div>
  );
};

export default TeeSheetPaymentSuccess;

TeeSheetPaymentSuccess.propTypes = {
  customers: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    email: PropTypes.string,
    phone_number: PropTypes.string,
    id: PropTypes.string,
    number: PropTypes.string
  })),
  receiptTypes: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  })),
  onChange: PropTypes.func
};

TeeSheetPaymentSuccess.defaultProps = {
  customers: [],
  receiptTypes: []
};