import { Col, message, Row } from 'antd';
import React, { useState } from 'react';
import HorizontalDateRange from '../molecules/HorizontalDateRange';
import Modal from '../organisms/Modal';
import FileSelectExport from '../molecules/FileSelectExport';
import { useFormik } from 'formik';
import { useQuery } from '@apollo/client';
import QueryGql from '../../constants/QueryGql';
import moment from 'moment';
import CustomerCheckinsTemplate, { rebuildCustomerCheckIns } from '../templates/CustomerCheckins';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { columnsCustomerCheckIns } from '../organisms/CustomerCheckInsList';
import sleep from '../../utils/sleep';
import { downloadCSV } from '../../utils/csv';

const ExportCustomerCheckIns = () => {
  const [dataReport, setDataReport] = useState({});

  const { refetch } = useQuery(QueryGql.GET_CUSTOMER_CHECKINS, {
    skip: true,
  });

  const { values, isSubmitting, setFieldValue, submitForm } = useFormik({
    initialValues: {
      file: { name: 'File', key: 'pdf', label: 'PDF' },
    },
    onSubmit: async ({ file, date }, { setSubmitting }) => {
      try {
        const res = await refetch({
          data: {
            start_date: moment(date[0]).startOf('day').toISOString(),
            end_date: moment(date[1]).endOf('day').toISOString(),
          },
        });

        const data = res.data.getCustomerCheckins.data;
        setDataReport(data);
        const headers = columnsCustomerCheckIns.map(item => ({
          label: item.title,
          key: item.dataIndex,
        }));
        const body = rebuildCustomerCheckIns(data.customers);

        if (file.key === 'csv') {
          downloadCSV({ headers, body, name: file.name });
        }

        if (file.key === 'pdf') {
          await sleep(1000);
          const columns = columnsCustomerCheckIns.map(item => ({
            header: item.title,
            dataKey: item.dataIndex,
          }));
          const canvas = await html2canvas(document.getElementById('print-check-ins-cards'));

          const imgData = canvas.toDataURL('image/png');
          const doc = new jsPDF('p', 'mm', 'a4');
          const width = doc.internal.pageSize.getWidth();

          doc.addImage(imgData, 'PNG', 5, 5, width - 10, 0);

          autoTable(doc, {
            headStyles: {
              fillColor: [245, 245, 245],
              textColor: [0, 13, 35],
              fontStyle: 'bold',
              minCellHeight: 12,
              valign: 'middle',
              cellPadding: { left: 2 },
            },
            alternateRowStyles: {
              fillColor: [255, 255, 255],
              cellPadding: { left: 2, top: 2, bottom: 2 },
            },
            margin: { top: 28, left: 5, right: 5 },
            columns: columns,
            body,
          });

          doc.save(`${file.name}.pdf`);
        }

        setSubmitting(false);
      } catch (error) {
        setSubmitting(false);
        message.error('Failed export');
      }
    },
  });

  return (
    <Modal
      title='Export customer check-ins report'
      width={600}
      hiddenLeftFooterButton
      rightFooterButton={{
        label: 'Export customer check-ins report',
        loading: isSubmitting,
        state: values.date ? 'default' : 'disabled',
        onClick: submitForm,
      }}>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <FileSelectExport value={values.file} onChange={item => setFieldValue('file', item)} />
        </Col>
        <Col span={24}>
          <HorizontalDateRange label='Date' onChange={v => setFieldValue('date', v)} />
        </Col>
      </Row>
      <div style={{ position: 'fixed', bottom: -5000, width: '80%' }}>
        <CustomerCheckinsTemplate dataReport={dataReport} isPrint={true} />
      </div>
    </Modal>
  );
};

export default ExportCustomerCheckIns;
