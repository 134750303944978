import React from 'react';

function SvgBack() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="none"
      viewBox="0 0 10 12"
    >
      <path
        fill="currentColor"
        d="M5.592 10.758a.833.833 0 000-1.184l-2.75-2.741h6.325a.833.833 0 000-1.667H2.842l2.75-2.742A.837.837 0 104.41 1.241L.242 5.408a.833.833 0 00-.175.275.783.783 0 000 .633c.04.102.1.196.175.275l4.167 4.167a.833.833 0 001.183 0z"
      ></path>
    </svg>
  );
}

export default SvgBack;
