import { DownOutlined } from '@ant-design/icons';
import { Button, Dropdown, Menu } from 'antd';
import React, { useMemo } from 'react';
import Card from '../atoms/Card';
import HorizontalTextInput from '../molecules/HorizontalTextInput';
import './CustomerExport.less';

const CustomerExport = ({ name, setName, file, setFile }) => {
  function handleClick({ key }) {
    setFile(key);
  }
  const fileTypes = useMemo(
    () => (
      <Menu onClick={handleClick}>
        <Menu.Item key='PDF'>PDF</Menu.Item>
        <Menu.Item key='CSV'>CSV</Menu.Item>
      </Menu>
    ),
    [],
  );

  return (
    <div id='customer-export'>
      <Card>
        <HorizontalTextInput
          value={name}
          onChange={e => setName(e.target.value)}
          label='File name'
          suffix={
            <Dropdown overlay={fileTypes}>
              <Button type='link' size='small'>
                {file} <DownOutlined />
              </Button>
            </Dropdown>
          }
        />
      </Card>
      <div className='spacer' />
    </div>
  );
};

export default CustomerExport;
