import React, { useEffect, useRef } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { useLocation, useHistory } from 'react-router-dom';
import moment from 'moment';
import { HorizontalTimePickerRange } from '../../stories/HorizontalTimePickerRange.stories';
import useQuery from '../../utils/useQuery';
import * as Yup from 'yup';
import QueryGql from '../../constants/QueryGql';
import Card from '../atoms/Card';
import HorizontalTextInput from '../molecules/HorizontalTextInput';
import Modal from '../organisms/Modal';
import './GolfCourseSettingsTime.less';
import { Formik } from 'formik';

const TimeSchemaValidations = Yup.object().shape({
  name: Yup.string().required('Required'),
  start_time: Yup.string().required('Required'),
  end_time: Yup.string().required('Required')
});

const GolfCourseSettingsTime = () => {
  const history = useHistory();
  const location = useLocation();
  const refFormik = useRef();
  const queryString = useQuery(location.search);
  const act = queryString.get('act');
  const [getByIdTimeBaseRate, {
    loading: loadingGetByIdTimeBaseRateyId,
    data: dataGetByIdTimeBaseRateyId
  }] = useLazyQuery(QueryGql.GET_BY_ID_TIME_BASE_RATE);
  const [updateTimeBaseRate, {
    loading: loadingUpdateTimeBaseRate
  }] = useMutation(QueryGql.UPDATE_TIME_BASE_RATE, {
    onCompleted: () => {
      history?.goBack();
    },
    onError: () => {
      history?.goBack();
    }
  });
  const [createTimeBaseRate, {
    loading: loadingCreateTimeBaseRate
  }] = useMutation(QueryGql.CREATE_TIME_BASE_RATE, {
    onCompleted: () => {
      history?.goBack();
    },
    onError: () => {
      history?.goBack();
    },
    update(cache, { data: { createTimeBaseRate } }) {
      cache.modify({
        fields: {
          getAllTimeBaseRate(existingTimeBaseRate = { data: [] }) {
            const newTimeBaseRateRef = cache.writeFragment({
              data: createTimeBaseRate.data,
              fragment: QueryGql.FRAGMENT_TIME_BASE_RATE
            });
            return {
              ...existingTimeBaseRate,
              data: [
                ...existingTimeBaseRate.data,
                newTimeBaseRateRef,
              ]
            };
          }
        }
      });
    }
  });
  const [destroyTimeBaseRate, {
    loading: loadingDestroyTimeBaseRate
  }] = useMutation(QueryGql.DELETE_TIME_BASE_RATE, {
    onCompleted: () => {
      history?.goBack();
    },
    onError: () => {
      history?.goBack();
    },
    update(cache, { data: { destroyTimeBaseRate } }) {
      cache.modify({
        fields: {
          getAllTimeBaseRate(existingTimeBaseRate = []) {
            return {
              ...existingTimeBaseRate,
              data: existingTimeBaseRate?.data.filter(item => !item.__ref.includes(destroyTimeBaseRate.data?.id))
            };
          }
        }
      });
    }
  });

  useEffect(() => {
    if (act === 'edit') {
      const id = queryString.get('id');
      getByIdTimeBaseRate({
        variables: {
          getByIdTimeBaseRateId: id
        }
      });
    }
  }, []);

  const handleSubmit = (values) => {
    const { name, start_time, end_time } = values;
    if (act === 'edit') {
      const { id } = dataGetByIdTimeBaseRateyId?.getByIdTimeBaseRate?.data;
      updateTimeBaseRate({
        variables: {
          updateTimeBaseRateId: id,
          updateTimeBaseRateName: name,
          updateTimeBaseRateStartTime: start_time,
          updateTimeBaseRateEndTime: end_time
        }
      });
    } else {
      createTimeBaseRate({
        variables: {
          createTimeBaseRateName: name,
          createTimeBaseRateStartTime: start_time,
          createTimeBaseRateEndTime: end_time
        }
      });
    }
  };

  const handleDelete = () => {
    const { id } = dataGetByIdTimeBaseRateyId?.getByIdTimeBaseRate?.data;
    destroyTimeBaseRate({
      variables: {
        destroyTimeBaseRateId: id
      }
    });
  };

  return (
    <Modal
      title={act === 'add' ? 'Add time-based rate' : 'Edit time-based rate'}
      hiddenLeftFooterButton={act === 'add'}
      leftFooterButton={{
        label: 'Delete rate type',
        type: 'danger',
        state: 'hint',
        loading: loadingDestroyTimeBaseRate,
        onClick: handleDelete
      }}
      rightFooterButton={{
        label: 'Save changes',
        state: (
          loadingGetByIdTimeBaseRateyId ||
          loadingUpdateTimeBaseRate ||
          loadingDestroyTimeBaseRate
        ) ? 'disabled' : 'default',
        loading: loadingUpdateTimeBaseRate || loadingCreateTimeBaseRate,
        onClick: () => refFormik.current.handleSubmit()
      }}
      bodyStyle={{ textAlign: 'right' }}
      width={540}
    >
      <Card className="golf-course-settings-time">
        <Formik
          enableReinitialize
          innerRef={refFormik}
          validationSchema={TimeSchemaValidations}
          initialValues={dataGetByIdTimeBaseRateyId?.getByIdTimeBaseRate?.data || {
            name: '',
            start_time: '00:00:00',
            end_time: '00:00:00'
          }}
          onSubmit={handleSubmit}
        >
          {({
            handleChange,
            values,
            errors
          }) => {
            return (
              <>
                <HorizontalTextInput
                  label="Rate name"
                  value={values?.name}
                  disabled={loadingGetByIdTimeBaseRateyId}
                  onChange={handleChange('name')}
                  errorMessage={errors?.name}
                  style={{ width: 256 }}
                />
                <div className="spacer" />
                <HorizontalTimePickerRange
                  valueFrom={moment(values?.start_time, 'hh:mm a')}
                  onChangeFrom={(e) => handleChange('start_time')(e._d.toLocaleTimeString('it-IT'))}
                  valueTo={moment(values?.end_time, 'hh:mm a')}
                  onChangeTo={(e) => handleChange('end_time')(e._d.toLocaleTimeString('it-IT'))}
                  label="Text field label"
                  timeString />
              </>
            );
          }}
        </Formik>
      </Card>
    </Modal>
  );
};

export default GolfCourseSettingsTime;
