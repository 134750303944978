import { useMutation, useQuery } from '@apollo/client';
import React, { useEffect, useMemo, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import moment from 'moment';
import QueryGql from '../../constants/QueryGql';
import Modal from '../organisms/Modal';
import extractQuery from '../../utils/useQuery';
import TeeSheetBlockTeeTimesTemplate from '../templates/TeeSheetBlockTeeTimes';
import Loading from '../atoms/Loading';

const TeeSheetBlockTeeTimes = () => {
  const location = useLocation();
  const history = useHistory();
  const [valueNote, setValueNote] = useState('');
  const queryString = extractQuery(location.search);
  const teeTimeBlocks = location.state.teeTimeBlocks;
  const date = queryString.get('date');

  const { loading: loadingGetTeeTimeBlock, data: dataGetTeeTimeBlock } = useQuery(
    QueryGql.GET_TEE_TIME_BLOCK,
    {
      variables: {
        calendarId: teeTimeBlocks?.[0].calendarId
      }
    }
  );

  const titleBlockTeeTime = useMemo(() => {
    if (teeTimeBlocks?.length > 0) {
      const teeTimeBlock = teeTimeBlocks[0];
      return `${teeTimeBlock.blockedTimes.length} tee times on ${moment(date, 'YYYY-MM-DD').format('dddd, DD/MM/YYYY')} (${teeTimeBlock.blockedTimes.map(x => moment(x.time, 'HH:mm:ss').format('hh:mm A')).join(', ')}) will be blocked`;
    }
    return '';
  }, [teeTimeBlocks]);

  useEffect(() => {
    if (dataGetTeeTimeBlock?.getTeeTimeBlock) {
      setValueNote(dataGetTeeTimeBlock.getTeeTimeBlock.data?.note || '');
    }
  }, [dataGetTeeTimeBlock]);

  const [createTeeTimeBlock, { loading: loadingCreateTeeTimeBlock }] = useMutation(
    QueryGql.CREATE_TEE_TIME_BLOCK,
    {
      onCompleted: () => {
        history.goBack();
      },
      update(cache, { data: { createTeeTimeBlock } }) {
        cache.writeQuery({
          query: QueryGql.GET_TEE_TIME_BLOCK,
          variables: {
            calendarId: createTeeTimeBlock.data.calendar_id
          },
          data: {
            getTeeTimeBlock: createTeeTimeBlock
          }
        });
      }
    }
  );

  const [updateTeeTimeBlock, { loading: loadingUpdateTeeTimeBlock }] = useMutation(
    QueryGql.UPDATE_TEE_TIME_BLOCK,
    {
      onCompleted: () => {
        history.goBack();
      },
      update(cache, { data: { updateTeeTimeBlock } }) {
        cache.writeQuery({
          query: QueryGql.GET_TEE_TIME_BLOCK,
          variables: {
            calendarId: updateTeeTimeBlock.data.calendar_id
          },
          data: {
            getTeeTimeBlock: updateTeeTimeBlock
          }
        });
      }
    }
  );

  const handleSubmit = async () => {
    const varTeeTimeBlock = teeTimeBlocks[0];
    const data = dataGetTeeTimeBlock?.getTeeTimeBlock.data;
    if (data) {
      if (data.calendar_id === varTeeTimeBlock.calendarId) {
        const existTeeTimeBlocks = JSON.parse(data.blocked_times);
        let times = new Set(existTeeTimeBlocks.map(d => d.time));
        let mergedTeeTimeBlocks = [...existTeeTimeBlocks, ...varTeeTimeBlock.blockedTimes.filter(d => !times.has(d.time))];
        updateTeeTimeBlock({
          variables: {
            updateTeeTimeBlockId: data.id,
            calendarId: data.calendar_id,
            deletedTimes: JSON.parse(data.deleted_times),
            blockedTimes: mergedTeeTimeBlocks,
            note: valueNote
          }
        });
      } else {
        createTeeTimeBlock({
          variables: {
            calendarId: varTeeTimeBlock.calendarId,
            deletedTimes: [],
            blockedTimes: varTeeTimeBlock.blockedTimes,
            note: valueNote
          }
        });
      }
    }
  };

  return (
    <Modal
      title="Block tee time"
      leftFooterButton={{
        label: 'Cancel',
        type: 'link',
        onClick: history.goBack,
      }}
      rightFooterButton={{
        label: 'Block tee time',
        onClick: handleSubmit,
        loading: loadingCreateTeeTimeBlock || loadingUpdateTeeTimeBlock
      }}
      width={540}
    >
      {loadingGetTeeTimeBlock 
        ? <Loading />
        : <TeeSheetBlockTeeTimesTemplate
          note={valueNote}
          onChangeNote={(value) => {
            setValueNote(value);
          }}
          title={titleBlockTeeTime}
        />}
    </Modal>
  );
};

export default TeeSheetBlockTeeTimes;