import { Menu, Select, Radio } from 'antd';
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { PlusOutlined } from '@ant-design/icons';
import Text from 'antd/lib/typography/Text';
import Button from '../atoms/Button';
import './SingleSelect.less';

const { Option } = Select;

const SingleSelect = (props) => {
  const { items, size, valueLabel, value, onSelect, onClickAdd, label, fullWidth, type } = props;

  const renderDropdown = () => {
    return (<>
      <div className="single-select-box">
        <Radio.Group onChange={(e) => onSelect(items?.filter(x => x.value === e.target.value)[0])} id="menu-item-single" value={value}>
          <Menu>
            {items.map(item =>
              <Menu.Item key={item.key}>
                <Radio value={item.value}>{item.label}</Radio>
              </Menu.Item>
            )}
          </Menu>
        </Radio.Group>
      </div>
      { onClickAdd && <div id="btn-wrapper">
        <Button type="guide"><PlusOutlined /> Add new course</Button>
      </div> }
    </>);
  };

  let className = useMemo(() => {
    if (type === 'text') {
      return 'single-select-text';
    }
    return 'single-select-item';
  }, [type]);

  return (
    <>
      { label && <div className="single-select-label"><Text>{label}</Text></div> }
      { type === 'default' ?
        <Select
          {...props}
          onSelect={(e) => onSelect(items?.filter(x => x.value === e)[0])}
          dropdownClassName="single-select"
          className={`${fullWidth ? 'single-select-full-width' : ''} ${className}`}
          placeholder="Select"
        >
          {items.map(item => (
            <Option key={item.value} value={item.value}>{item.label}</Option>
          ))}
        </Select>
        :
        <Select
          {...props}
          size={size}
          value={valueLabel}
          dropdownClassName="single-select"
          className={`${fullWidth ? 'single-select-full-width' : ''} ${className}`}
          dropdownRender={renderDropdown}
          placeholder="Select"
          // defaultValue="Course No. 1 (18 hole)"
        /> }
    </>
  );
};

export default SingleSelect;

SingleSelect.propTypes = {
  size: PropTypes.oneOf(['large', 'middle', 'small']),
  type: PropTypes.oneOf(['text', 'outlined', 'default']),
  items: PropTypes.shape({
    key: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired
  }),
  onClickAdd: PropTypes.func,
  onSelect: PropTypes.func,
  onChange: PropTypes.func,
  label: PropTypes.string,
  fullWidth: PropTypes.bool
};

SingleSelect.defaultProps = {
  type: 'outlined',
  items: {
    key: '1',
    label: 'label',
    value: '1'
  },
  size: 'middle',
  fullWidth: false
};