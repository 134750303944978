/* eslint-disable max-len */
import React from 'react';

function SvgHome() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="none"
      viewBox="0 0 12 14"
    >
      <path
        fill="currentColor"
        d="M11.333 4.333l-4-3.506a2 2 0 00-2.666 0l-4 3.506A2 2 0 000 5.84v5.827a2 2 0 002 2h8a2 2 0 002-2V5.833a1.999 1.999 0 00-.667-1.5zm-4 8H4.667V9a.667.667 0 01.666-.667h1.334A.667.667 0 017.333 9v3.333zm3.334-.666a.666.666 0 01-.667.666H8.667V9a2 2 0 00-2-2H5.333a2 2 0 00-2 2v3.333H2a.667.667 0 01-.667-.666V5.833a.667.667 0 01.227-.5l4-3.5a.667.667 0 01.88 0l4 3.5a.666.666 0 01.227.5v5.834z"
      />
    </svg>
  );
}

export default SvgHome;
