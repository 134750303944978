import { useLazyQuery, useMutation } from '@apollo/client';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Pathname from '../../constants/Pathname';
import QueryGql from '../../constants/QueryGql';
import extractQuery from '../../utils/useQuery';
import Modal from '../organisms/Modal';
import TeeSheetCustomersCheckInDetailTemplate from '../templates/TeeSheetCustomersCheckInDetail';

const TeeSheetCustomerCheckInDetail = () => {
  const history = useHistory();
  const location = useLocation();
  const queryString = extractQuery(location.search);
  const id = queryString.get('id');
  const groupId = queryString.get('group-id');
  const customerId = queryString.get('customer-id');
  const time = queryString.get('time');

  const [ loadingCheckinAllForPayment, setLoadingCheckinAllForPayment ] = useState(false);
  const [ checkinReservationCustomers, setCheckinReservationCustomers ] = useState([]);
  const [ checkinReservationCustomersLength, setCheckinReservationCustomersLength] = useState(0);

  const [getReservation, {
    data: dataGetReservation,
    loading: loadingGetReservation,
  }] = useLazyQuery(QueryGql.GET_RESERVATION, {
    fetchPolicy: 'no-cache'
  });

  const [getReservationGroup, {
    data: dataGetReservationGroup,
    loading: loadingGetReservationGroup,
  }] = useLazyQuery(QueryGql.GET_RESERVATION_GROUP, {
    fetchPolicy: 'no-cache'
  });

  const dataReservation = useMemo(() => {
    if (id) {
      return dataGetReservation?.getReservation?.data;
    }
    if (groupId) {
      return dataGetReservationGroup?.getReservationGroup?.data?.reservations?.find(x => x.date_tee_times === time);
    }
  }, [dataGetReservation, dataGetReservationGroup]);

  const loadingReservation = useMemo(() => {
    if (id) {
      return loadingGetReservation;
    }
    if (groupId) {
      return loadingGetReservationGroup;
    }
  }, [dataGetReservation, dataGetReservationGroup]);

  useEffect(() => {
    if (id) {
      getReservation({
        variables: {
          getReservationId: id
        },
      });
    }
    if (groupId) {
      getReservationGroup({
        variables: {
          getReservationGroupId: groupId
        },
      });
    }
  }, []);

  const [checkinReservation, { loading: loadingCheckinReservation }] = useMutation(
    QueryGql.CHECKIN_RESERVATION,
    {
      onCompleted: () => {
        history.push(  groupId ? 
          Pathname.TEE_SHEET + '/' +
          Pathname.TEE_SHEET_TAKE_PAYMENT_GROUP+
          `?id=${groupId}`
          :
          Pathname.TEE_SHEET + '/' +
              Pathname.TEE_SHEET_TAKE_PAYMENT+
              `?id=${id}`,
        {
          background: true
        }
        );

        setLoadingCheckinAllForPayment(false);
      },
      onError: () => {
        setLoadingCheckinAllForPayment(false);
      }
    }
  );

  const total = useMemo(() => dataReservation?.reservation_customers.filter(item => item.customer_id === customerId)?.[0]?.amount || 0, [dataReservation]);
  const status = useMemo(() => dataReservation?.payment_status || 0, [dataReservation]);
  const operators = useMemo(() => dataReservation?.reservation_operators || [], [dataReservation]);
  const reservation = useMemo(() => {
    if (dataReservation) {
      const reservation = dataReservation;
      return dataReservation.reservation_customers?.filter(item => item.customer_id === customerId).map(item => {
        const reservations = [
          {
            name: `${reservation.tee_times.name} - ${item.membership_name}`,
            price: item.membership_price,
            status: item.status
          },
          ...item.add_ons.map(add_on => ({
            name: add_on.name,
            price: add_on.price,
            status: item.status
          }))
        ];

        if (item.raincheck?.code) {
          reservations.push({
            name: 'Raincheck',
            price: - item.raincheck.amount,
            status: item.status
          });
        }

        return {
          id: item.customer_id,
          reservation_id: item.reservation_id,
          status: item.status,
          bag_id: item.bag_id,
          customer: {
            name: item.customer.full_name
          },
          reservations,
        };
      });
    }
    return [];
  }, [dataReservation]);

  const handleSaveChange = useCallback(() => {
    if (checkinReservationCustomers.length > 0) {
      checkinReservation({
        variables: {
          checkinReservationId: id ? id : reservation?.[0]?.reservation_id,
          checkinReservationCustomers: checkinReservationCustomers.map(x => {
            delete x?.default_status;
            return x;
          }),
        }
      });
    } else {
      history.goBack();
    }
  }, [checkinReservationCustomers]);


  const handleContinueToPayment = useCallback(() => {
    setLoadingCheckinAllForPayment(true);
    const checkins = checkinReservationCustomers.filter(x => (x.status === 'checked_in' && x.default_status !== 'checked_in') || x.status === 'checked_in' && x.default_status === 'checked_in');
    if (checkins.length > 0) {
      checkinReservation({
        variables: {
          checkinReservationId: id ? id : reservation?.[0]?.reservation_id,
          checkinReservationCustomers: checkins.map(x => {
            delete x.default_status;
            return {
              ...x,
              bag_id: x?.bag_id || '',
            };
          }),
        }
      });
    } else {
      setLoadingCheckinAllForPayment(false);
    }
  }, [ checkinReservationCustomers]);

  return (
    <Modal
      title="Check-in customers"
      hiddenLeftFooterButton
      rightFooterButtonExtra={{
        label: 'Save changes',
        type: 'outline',
        disabled: loadingReservation,
        loading: loadingCheckinReservation,
        onClick: handleSaveChange
      }}
      rightFooterButton={{
        label: 'Continue to payment',
        state: loadingReservation || checkinReservationCustomersLength === 0 ? 'disabled' : 'default',
        disabled: loadingReservation,
        loading: loadingCheckinAllForPayment,
        onClick: handleContinueToPayment,
      }}
      width={540}
    >
      <TeeSheetCustomersCheckInDetailTemplate
        type="single"
        data={reservation}
        total={total}
        status={status}
        operators={operators}
        onChangeStatus={(values) => {
          setCheckinReservationCustomersLength(values.filter(x => x.status === 'checked_in').length);
          setCheckinReservationCustomers(values);
        }}
      />
    </Modal>
  );
};

export default TeeSheetCustomerCheckInDetail;