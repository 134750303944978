import React from 'react';
import { Checkbox as AntdCheckbox } from 'antd';
import PropTypes from 'prop-types';
import './Checkbox.less';

const Checkbox = (props) => {
  const { children } = props;
  return (
    <AntdCheckbox {...props} id="checkbox-id">
      {children}
    </AntdCheckbox>
  );
};

export default Checkbox;

Checkbox.propTypes = {
  children: PropTypes.any.isRequired,
};