import React from 'react';
import { DatePicker } from 'antd';
import './DateRangePicker.less';

const { RangePicker } = DatePicker;
const DateRangePicker = (props) => {

  return(
    <RangePicker 
      {...props}
      className="date-range-picker"
    />
  );
};

export default DateRangePicker;