import { Header } from 'antd/lib/layout/layout';
import React, { useCallback, useMemo } from 'react';
import Title from 'antd/lib/typography/Title';
import { Col, Dropdown, Row } from 'antd';
import { useHistory, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import Avatar from 'antd/lib/avatar/avatar';
import { Menu } from 'antd';
import Icon from '@ant-design/icons';

import IconButtonNavBar from '../atoms/IconButtonNavBar';
import './AppBar.less';
import drawerMenu from '../../constants/drawerMenu';
import SvgHelp from '../atoms/SvgHelp';
import IconButton from '../atoms/IconButton';
import { AppContext } from '../../App';
import { authFromLocal } from 'utils/function';
import SvgLogout from 'components/atoms/SvgLogout';
// import Button from '../atoms/Button';


const AppBar = (props) => {
  let { title, type, iconLeft, right } = props;
  const appContext = React.useContext(AppContext);
  const location = useLocation();
  const history = useHistory();
  const auth = authFromLocal();
  const fullName = auth?.login?.user?.full_name;
  const initialName = useMemo(() => {
    let result = '';
    if (fullName) {
      const split = fullName.split(' ');
      result += split[0][0];
      if (split[1]) {
        result += split[1][0];
      }
    }
    return result;
  }, [fullName]);

  if (type === 'home') {
    if (location) {
      const { pathname } = location;
      const activePathname = `/${pathname?.split('/')?.[1]}`;
      title = drawerMenu.filter(menu => menu.pathname === activePathname)?.[0]?.label;
    } else {
      title = 'Dashboard';
    }
    iconLeft = 'menu';
  } else {
    if (!iconLeft) {
      iconLeft = 'close';
    }
  }

  const handleLogout = () => {
    localStorage.clear();
    history.push('/login');
  };

  const contextMenu = useCallback(() => {
    return (
      <Menu>
        <Menu.Item
          key="0"
          onClick={handleLogout}
        >
          <Icon component={SvgLogout} style={{ fontSize: 14, color: '#000D23', marginRight: 4}} /> Logout
        </Menu.Item>
      </Menu>
    );},[]);

  return (
    <Header>
      <Row id="app-bar" align="middle" justify="space-between">
        <Row align="middle">
          <div className="icon-menu">
            <IconButtonNavBar
              type={iconLeft}
              onClick={type === 'home'
                ? () => appContext.dispatch({type: appContext.state.drawer === 'show' ? 'DRAWER_HIDE' : 'DRAWER_SHOW'})
                : history.goBack }
            />
          </div>
          <Title className="title" level={4}>{title}</Title>
        </Row>
        {type === 'home' ? (
          <Col>
            {/* <Button type="guide" className="button-guide">Sello for Golf guide</Button> */}
            <IconButton iconSvg={SvgHelp} />
            <Dropdown trigger={['hover', 'click']} overlay={contextMenu}>
              <Avatar  className="avatar" >{initialName}</Avatar>
            </Dropdown>
          </Col>
        ) : (
          <Col>
            {right}
          </Col>
        )}
      </Row>
    </Header>
  );
};

export default AppBar;

AppBar.propTypes = {
  type: PropTypes.oneOf(['home', 'page']),
  title: PropTypes.string,
  iconLeft: PropTypes.oneOf(['menu', 'close', 'back']),
  right: PropTypes.node,
  onCollapsedMenu: PropTypes.func
};

AppBar.defaultProps = {
  type: 'home',
  iconLeft: 'menu'
};

