import { DownOutlined } from '@ant-design/icons';
import { Col, Dropdown, List, Menu, Row, Checkbox } from 'antd';
import Title from 'antd/lib/typography/Title';
import React, { useCallback, useMemo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import numeral from 'numeral';
import Avatar from '../atoms/Avatar';
import Card from '../atoms/Card';
import RadioButtonTab from '../atoms/RadioButtonTab';
import TeeSheetTakePaymentPaymentMethodItem from '../molecules/TeeSheetTakePaymentPaymentMethodItem';
import './TeeSheetTakePayment.less';

const TeeSheetTakePayment = props => {
  const { optionsPaymentMethods, dataReservation, onChange, onChangeType } = props;
  const [customersPayment, setCustomersPayment] = useState();
  const [values, setValues] = useState([]);

  const [activeTab, setActiveTab] = useState('split');
  const totalAmount = useMemo(() => {
    let total = 0;
    dataReservation.forEach(item => {
      total = total + item.total_amount;
    });
    return total;
  }, [dataReservation]);

  const optionsTab = useMemo(() => {
    return [
      {
        label: 'Split payment',
        value: 'split',
      },
      {
        label: 'Combined payment',
        value: 'combined',
      },
    ];
  }, []);

  const optionsCustomer = useMemo(
    () => (
      <Menu>
        {dataReservation.map(item => (
          <Menu.Item onClick={() => handleChooseCustomerReservation([item.id])} key={item.id}>
            <List.Item id='tee-sheet-take-payment' className='menu-item'>
              <List.Item.Meta
                avatar={<Avatar title={item.customer.full_name} />}
                title={item.customer.full_name}
              />
            </List.Item>
          </Menu.Item>
        ))}
        <Menu.Item key='5' id='tee-sheet-take-payment' className='last-item'>
          Other Customer
        </Menu.Item>
      </Menu>
    ),
    [dataReservation, activeTab, values],
  );

  const getCustomerPaymentCombined = () => {
    let customer = dataReservation?.[0];
    if (customer) {
      if (values.length > 0) {
        customer = dataReservation.filter(x => x.id === values[0].id)?.[0];
      }
    }
    return customer;
  };

  useEffect(() => {
    if (activeTab === 'combined') {
      const reservation = getCustomerPaymentCombined();
      setTimeout(() => {
        handleChooseCustomerReservation([reservation.id]);
      }, 100);
    }
  }, [activeTab, dataReservation, customersPayment]);

  useEffect(() => {
    setValues([]);
  }, [activeTab]);

  const handleChooseCustomerReservation = useCallback(
    e => {
      const currentValues = [];

      if (activeTab === 'combined' && values.length > 0) {
        currentValues.push({
          id: e[0],
          customer_id: dataReservation.find(item => item.id === e[0]).customer.id,
          payment_methods: values[0].payment_methods,
        });
      } else {
        e.map(item => {
          if (customersPayment?.[item]) {
            currentValues.push({
              id: item,
              customer_id: dataReservation.find(i => i.id === item).customer.id,
              payment_methods: customersPayment[item],
            });
          }
        });
      }
      setValues(currentValues);
      onChange(currentValues);
    },
    [customersPayment, values],
  );

  const handleChangePaymentReservation = useCallback(
    id => e => {
      setCustomersPayment(prevState => {
        const afterState = {
          ...prevState,
          [id]: e,
        };
        const currentValues = [];
        values.map(item => {
          if (afterState?.[item.id]) {
            currentValues.push({
              id: item.id,
              payment_methods: afterState[item.id],
            });
          }
        });
        setValues(currentValues);
        onChange(currentValues);
        return afterState;
      });
    },
    [customersPayment, values],
  );

  return (
    <div id='tee-sheet-take-payment'>
      <RadioButtonTab
        options={optionsTab}
        value={activeTab}
        onChange={e => {
          setActiveTab(e.target.value);
          onChangeType(e.target.value);
        }}
      />
      {activeTab === 'split' ? (
        <div className='content-tab'>
          <Checkbox.Group style={{ width: '100%' }} onChange={handleChooseCustomerReservation}>
            {dataReservation.map(item => (
              <>
                <Card>
                  <Row align='middle'>
                    <Col xs={10}>
                      <Row wrap={false} align='middle'>
                        <Col xs={4}>
                          <Checkbox value={item.id} />
                        </Col>
                        <Col xs={20}>
                          <List.Item>
                            <List.Item.Meta
                              avatar={
                                <Avatar title={item.customer.full_name} style={{ marginTop: 2 }} />
                              }
                              title={item.customer.full_name}
                              description={numeral(item.total_amount).format('$0,0[.]00')}
                            />
                          </List.Item>
                        </Col>
                      </Row>
                    </Col>
                    <Col xs={14}>
                      <TeeSheetTakePaymentPaymentMethodItem
                        optionsPaymentMethods={optionsPaymentMethods}
                        totalAmount={item.total_amount}
                        disabledButtonAdd={
                          customersPayment?.[item.id]?.length === optionsPaymentMethods?.length
                        }
                        onChange={handleChangePaymentReservation(item.id)}
                      />
                    </Col>
                  </Row>
                </Card>
                <div className='spacer' />
              </>
            ))}
          </Checkbox.Group>
        </div>
      ) : (
        <div className='content-tab'>
          <Card>
            <Row align='middle'>
              <Col xs={11}>
                <Row wrap={false} align='middle'>
                  <Col xs={7}>
                    <Title level={4}>Paid by:</Title>
                  </Col>
                  <Col xs={17}>
                    <Dropdown overlay={optionsCustomer} trigger={['click']}>
                      <List.Item style={{ paddingRight: 30, cursor: 'pointer' }}>
                        <List.Item.Meta
                          avatar={
                            <Avatar
                              title={getCustomerPaymentCombined().customer.full_name}
                              style={{ marginTop: 2 }}
                            />
                          }
                          title={getCustomerPaymentCombined().customer.full_name}
                          description={numeral(totalAmount).format('$0,0[.]00')}
                        />
                        <DownOutlined className='icon-dropdown' />
                      </List.Item>
                    </Dropdown>
                  </Col>
                </Row>
              </Col>
              <Col xs={13}>
                <TeeSheetTakePaymentPaymentMethodItem
                  optionsPaymentMethods={optionsPaymentMethods}
                  totalAmount={totalAmount}
                  disabledButtonAdd={
                    values?.[0]?.payment_methods?.length === optionsPaymentMethods?.length
                  }
                  onChange={handleChangePaymentReservation(
                    getCustomerPaymentCombined().id,
                  )}
                />
              </Col>
            </Row>
          </Card>
        </div>
      )}
    </div>
  );
};

export default TeeSheetTakePayment;

TeeSheetTakePayment.propTypes = {
  optionsPaymentMethods: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
  ),
  dataReservation: PropTypes.array.isRequired,
  onChange: PropTypes.func,
  onChangeType: PropTypes.func,
};

TeeSheetTakePayment.defaultProps = {
  optionsPaymentMethods: [],
  dataReservation: [],
};
