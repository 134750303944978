import Icon from '@ant-design/icons';
import { Menu, Button, notification } from 'antd';
import React, { useMemo, useCallback, useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import AppWrapper from '../templates/AppWrapper';
import PageEmpty from '../templates/PageEmpty';
import Pathname from '../../constants/Pathname';
import SvgDelete from '../atoms/SvgDelete';
import SvgDuplicate from '../atoms/SvgDuplicate';
import SvgEdit from '../atoms/SvgEdit';
import CardList from '../templates/CardList';
import SvgIllustrationTeeTimes from '../atoms/SvgIllustrationTeeTimes';
import SvgTeeTimeFilled from '../atoms/SvgTeeTimeFilled';
import MultipleSelect from '../molecules/MultipleSelect';
import QueryGql from '../../constants/QueryGql';


const TeeTimes = () => {
  const history = useHistory();
  const [searchValue, setSearchValue] = useState('');
  const [filterValue, setFilterValue] = useState(['standard', 'crossover', 'shotgun', 'custom']);

  let objVariables = {
    search: searchValue,
    column: 'tee_time_types',
    filter: filterValue
  };

  const {
    data: dataSearchTeeTime,
    loading: loadingSearchTeeTime,
    refetch: refecthDataGetTeeTimes
  } = useQuery(QueryGql.SEARCH_TEE_TIMES, {
    variables: objVariables,
  });

  const [deleteTeeTime] = useMutation(QueryGql.DELETE_TEE_TIME, {
    onCompleted: (response) => {
      refecthDataGetTeeTimes();
      const key = `open${Date.now()}`;
      notification.info({
        message: 'Tee times successfully deleted',
        description: `${response?.createTeeTime?.data.name} has been successfully deleted from the rate card list.`,
        btn: <Button type="text" className="notification-button-dismiss-info" onClick={() => notification.close(key)}>Dismiss</Button>,
        key,
        closeIcon: <div />,
        placement: 'bottomLeft'
      });
    },
    onError: () => {
      refecthDataGetTeeTimes();
    },
  });

  const handleSearchTeeTime = (e) => {
    setSearchValue(e.target.value);
  };

  const handleFilterTeeTime = (values) => {
    const filterAll = values.find(x => x === 'all');
    if (filterAll) {
      setFilterValue(['standard', 'crossover', 'shotgun', 'custom']);
    } else {
      setFilterValue(values);
    }
  };

  const handleDelete = (id) => {
    deleteTeeTime({
      variables: {
        deleteTeeTimeId: id
      }
    });
  };

  const data = useMemo(() => dataSearchTeeTime?.searchAndFilterTeeTime?.data.map(item => ({
    ...item,
    key: item.id,
    title: item.name
  })), [dataSearchTeeTime]);

  const contextMenu = useCallback((item) => {
    return (
      <Menu>
        <Menu.Item
          key="0"
          onClick={() =>
            history.push(
              Pathname.TEE_TIMES + '/' + Pathname.TEE_TIMES_SETTINGS + `?act=edit&id=${item.id}`,
              {
                background: true,
              },
            )
          }>
          <Icon component={SvgEdit} style={{ fontSize: 14, color: '#000D23' }} /> Edit tee times
        </Menu.Item>
        <Menu.Item
          key="1"
          onClick={() => 
            history.push(
              Pathname.TEE_TIMES + '/' + Pathname.TEE_TIMES_SETTINGS + `?act=duplicate&id=${item.id}`,
              {
                background: true,
              },
            )
          }>
          <Icon component={SvgDuplicate} style={{ fontSize: 14, color: '#000D23' }} /> Duplicate tee
          times
        </Menu.Item>
        <Menu.Item
          key="2"
          className="second-menu-edit"
          onClick={() => handleDelete(item.id)}>
          <Icon component={SvgDelete} style={{ fontSize: 14 }} /> Delete tee times
        </Menu.Item>
      </Menu>
    );
  }, []);

  return (
    <AppWrapper>
      <CardList
        data={data}
        icon={SvgTeeTimeFilled}
        contextMenuItem={contextMenu}
        loading={loadingSearchTeeTime}
        onClickItem={() => { }}
        buttonAdd={{
          label: 'Add new tee time',
          onClick: () =>
            history.push(Pathname.TEE_TIMES + '/' + Pathname.TEE_TIMES_SETTINGS + '?act=add', {
              background: true,
            }),
        }}
        searchInput={{
          placeholder: 'Search tee times',
          onChange: handleSearchTeeTime,
        }}
        extraLeft={[
          <MultipleSelect
            key="select-all-formats"
            values={[ 'all' ]}
            defaultValue="All formats"
            style={{ width: 140 }}
            handleChange={handleFilterTeeTime}
            items={[
              { key: 1, value: 'all', label: 'All formats' },
              { key: 2, value: 'standard', label: 'Standard' },
              { key: 3, value: 'crossover', label: 'Crossover' },
              { key: 4, value: 'shotgun', label: 'Shotgun' },
              { key: 5, value: 'custom', label: 'Custom' },
            ]}
          />,
        ]}
      />
      {data?.length === 0 && !loadingSearchTeeTime
        ? (
          <PageEmpty
            title="You don’t have any tee times yet!"
            desc="Add a new tee time and insert rate cards for each of them. 
              Then you can start receiving reservations from your customers."
            buttonLabel="Add new tee time"
            illustration={SvgIllustrationTeeTimes}
            buttonOnClick={() =>
              history.push(Pathname.TEE_TIMES + '/' + Pathname.TEE_TIMES_SETTINGS + '?act=add', {
                background: true,
              })
            }
          />
        )
        : null}
    </AppWrapper>
  );
};

export default TeeTimes;
