import Text from 'antd/lib/typography/Text';
import React from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import extractQuery from '../../utils/useQuery';
import ModalCancelAction from '../templates/ModalCancelAction';
import { useMutation } from '@apollo/client';
import QueryGql from '../../constants/QueryGql';
import IconButtonNavBar from '../atoms/IconButtonNavBar';

const CalendarTeeTimeParticularDelete = () => {
  const history = useHistory();
  const queryString = extractQuery(location.search);
  const id = queryString.get('id');
  const name = queryString.get('name');
  const startTime = queryString.get('start-time');
  const endTime = queryString.get('end-time');
  const date = queryString.get('date');
  const redirect = queryString.get('redirect');


  const [deleteCalendar, {
    loading: loadingDeleteCalendar
  }] = useMutation(QueryGql.DELETE_CALENDAR, {
    onCompleted: () => {
      if (redirect === 'calendar') {
        history.push('/calendar');
      } else {
        history.goBack();
        history.goBack();
      }
    },
    onError: () => {
      history.goBack();
    },
    update(cache, { data: { deleteCalendar }}) {
      cache.modify({
        fields: {
          getAllCalendar(existingCalendar) {
            return {
              ...existingCalendar,
              data: existingCalendar?.data.filter(item => !item.__ref.includes(deleteCalendar.data?.id))
            };
          }
        }
      });
    }
  });

  const handleDelete = () => {
    deleteCalendar({
      variables: {
        deleteCalendarId: id
      }
    });
  };

  return (
    <ModalCancelAction
      title='Remove tee time slot?'
      closeIcon={<IconButtonNavBar type="back" />}
      leftFooterButton={{
        label: 'Go back',
        type: 'link',
        onClick: () => history.goBack(),
      }}
      rightFooterButton={{
        label: 'Remove tee time slot',
        type: 'danger',
        loading: loadingDeleteCalendar,
        onClick: handleDelete,
      }}
    >
      <Text>Remove {name} from the slot {moment(startTime, 'hh:mm:dd').format('hh:mm a')} to {moment(endTime, 'hh:mm:dd').format('hh:mm a')} on {moment(date, 'YYYY-MM-DD').format('dddd, D MMMM YYYY')}</Text>
    </ModalCancelAction>
  );
};

export default CalendarTeeTimeParticularDelete;