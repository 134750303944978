import { Divider, Radio, Checkbox, Collapse } from 'antd';
import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import Card from '../atoms/Card';
import './CustomerFilterType.less';
import { useQuery } from '@apollo/client';
import QueryGql from 'constants/QueryGql';

const { Panel } = Collapse;

const CustomerFilterType = ({ valueSpecific, setValueSpecific, valueAll, setValueAll }) => {
  const { data: optionsSpecificCustomers } = useQuery(QueryGql.GET_MEMBERSHIPS, {
    fetchPolicy: 'cache-and-network',
  });

  const optionsSpecificCustomer = useMemo(
    () =>
      optionsSpecificCustomers?.getAllMembership?.data?.map(item => ({
        value: item.id,
        label: item.name,
      })),
    [optionsSpecificCustomers],
  );

  const handleChangeAll = useCallback(e => {
    setValueAll(e.target.value);
  }, []);

  const handleChangeSpecific = useCallback(e => {
    setValueSpecific(e);
  });
  return (
    <div id='customer-filter-type'>
      <Radio.Group onChange={handleChangeAll} value={valueAll} className='radio-group'>
        <Card>
          <Radio value='all_customer'>All Customers type</Radio>
        </Card>
        <div className='spacer' />
        <Card>
          <Collapse activeKey={valueAll === 'specific_customer' ? ['1'] : []} ghost>
            <Panel
              header={<Radio value='specific_customer'>Specific customer types</Radio>}
              key='1'
              showArrow={false}>
              <>
                <Divider />
                <Checkbox.Group
                  options={optionsSpecificCustomer}
                  value={valueSpecific}
                  onChange={handleChangeSpecific}
                  className='checkbox-group'
                />
              </>
            </Panel>
          </Collapse>
        </Card>
      </Radio.Group>
    </div>
  );
};

export default CustomerFilterType;

CustomerFilterType.propTypes = {
  type: PropTypes.oneOf(['filter', 'export']),
};

CustomerFilterType.defaultProps = {
  type: 'filter',
};
