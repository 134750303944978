import Text from 'antd/lib/typography/Text';
import React from 'react';
import PropTypes from 'prop-types';
import InputTextArea from '../atoms/InputTextArea';
import './HorizontalTextInputArea.less';

const HorizontalTextInputArea = (props) => {
  const { label, errorMessage } = props;
  return (
    <div className="antd-horizontal-text-input">
      {label && <Text className="label">{label}</Text>}
      <InputTextArea className="input-text" size="large" {...props} />
      {errorMessage && <Text className="error-message" type="danger">{errorMessage}</Text>}
    </div>
  );
};

export default HorizontalTextInputArea;

HorizontalTextInputArea.propTypes = {
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func,
};

HorizontalTextInputArea.defaultProps = {
  onChange: null,
};

