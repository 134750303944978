import { useMutation, useQuery } from '@apollo/client';
import React, { useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import moment from 'moment';
import Promise from 'bluebird';
import QueryGql from '../../constants/QueryGql';
import Modal from '../organisms/Modal';
import extractQuery from '../../utils/useQuery';
import TemplateCalendarDailyView from '../templates/CalendarDailyView';
import Loading from '../atoms/Loading';

const CalendarDailyView = () => {
  const history = useHistory();
  const location = useLocation();
  const queryString = extractQuery(location.search);
  const date = queryString.get('date');
  const refTemplateCalendarDailyView = useRef();

  
  const [updateCalendar, {
    loading: loadingUpdateCalendar
  }] = useMutation(QueryGql.UPDATE_CALENDAR);

  const { loading: loadingGetAllCalendar, data: dataGetAllCalendar } = useQuery(
    QueryGql.GET_ALL_CALENDAR, {
      variables: {
        getAllCalendarType: 'day',
        getAllCalendarDate: date,
      },
      // fetchPolicy: 'network-only'
    }
  );

  const handleSubmit = () => {
    const newCalendars = refTemplateCalendarDailyView.current.handleSubmit();
    const newBodiesCalendars = [];
    const uniqueNewCalendars = Array.from(new Set(newCalendars.map(a => a.id)))
      .map(id => {
        return newCalendars.find(a => a.id === id);
      });
    dataGetAllCalendar.getAllCalendar.data.forEach(x => {
      uniqueNewCalendars.map(y => {
        if (x.id === y.id && (moment(y.start).format('HH:mm:ss') !== x.start_time || moment(y.end).format('HH:mm:ss') !== x.end_time)) {
          newBodiesCalendars.push({
            variables: {
              updateCalendarId: x.id,
              updateCalendarTeeTimeId: x.tee_times.id,
              updateCalendarRateCardId: x.rate_cards.id,
              updateCalendarStartTime: moment(y.start).format('HH:mm:ss'),
              updateCalendarEndTime: moment(y.end).format('HH:mm:ss'),
              updateCalendarDate: date,
            }
          });
          return false;
        }
      });
    });
    if (newBodiesCalendars.length > 0) {
      Promise.map(newBodiesCalendars, (newBodiesCalendar) => {
        // Promise.map awaits for returned promises as well.
        return updateCalendar(newBodiesCalendar);
      }).then(() => {
        history.goBack();
      }).catch(() => {});
    } else {
      history.goBack();
    }
  };

  return (
    <Modal
      title={moment(date, 'YYYY-MM-DD').format('dddd, D MMMM YYYY')}
      leftFooterButton={{
        label: 'Discard changes',
        onClick: () => history.goBack(),
        type: 'link'
      }}
      rightFooterButton={{
        label: 'Save changes',
        onClick: handleSubmit,
        type: 'primary',
        state: loadingGetAllCalendar ? 'disabled' : 'default',
        loading: loadingUpdateCalendar
      }}
      width={540}
    >
      {loadingGetAllCalendar && !dataGetAllCalendar?.getAllCalendar?.data
        ? <Loading />
        : <TemplateCalendarDailyView
          date={date}
          ref={refTemplateCalendarDailyView}
          data={dataGetAllCalendar.getAllCalendar.data}
        />}
    </Modal>
  );
};

export default CalendarDailyView;