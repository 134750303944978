import React from 'react';
import Title from 'antd/lib/typography/Title';
import Text from 'antd/lib/typography/Text';
import { PieChart, Pie, Cell } from 'recharts';
import PropTypes from 'prop-types';

import './PieChartReport.less';

const PieChartReport = ({ id, title, total, items, isAnimationActive }) => {
  return(
    <div id={id} className="pie-chart-report">
      <Title className="title" level={4}>{title}</Title>
      <div>
        <PieChart width={200} height={200}>
          <Pie
            data={items}
            innerRadius={55}
            outerRadius={80}
            fill="#8884d8"
            paddingAngle={0}
            dataKey="value"
            stroke="none"
            isAnimationActive={isAnimationActive}
          >
            {items.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={entry.color} />
            ))}
          </Pie>
        </PieChart>
      </div>
      {
        items.map(item => (
          <div key={item.name} className="pie-chart-report-item">
            <Text>
              <div
                className="pie-chart-report-circle"
                style={{
                  backgroundColor: item.color
                }}
              />
              {item.name}
            </Text>
            <Text>{item.detail}</Text>
          </div>
        ))
      }
      <div className="pie-chart-report-item">
        <Text className="pie-chart-report-total">TOTAL</Text>
        <Text className="pie-chart-report-total">{total}</Text>
      </div>
    </div>
  );
};

PieChartReport.propTypes = {
  isAnimationActive: PropTypes.bool,
  title: PropTypes.string,
  total: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    value: PropTypes.number,
    color: PropTypes.string,
    detail: PropTypes.string
  }))
};

export default PieChartReport;