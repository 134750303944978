import { useLazyQuery, useMutation } from '@apollo/client';
import Text from 'antd/lib/typography/Text';
import { Formik } from 'formik';
import React, { useEffect, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import * as Yup from 'yup';
import QueryGql from '../../constants/QueryGql';
import useQuery from '../../utils/useQuery';
import Card from '../atoms/Card';
import HorizontalTextInput from '../molecules/HorizontalTextInput';
import Modal from '../organisms/Modal';
import './AddOnsInclusionsAddOns.less';

const AddOnSchemaValidations = Yup.object().shape({
  name: Yup.string().required('Required'),
  price: Yup.number().required('Required'),
});

const AddOnsInclusionsAddOns = () => {
  const history = useHistory();
  const location = useLocation();
  const refFormik = useRef();
  const queryString = useQuery(location.search);
  const act = queryString.get('act');
  const [findAddOnById, {
    loading: loadingFindAddOnById,
    data: dataFindAddOnById 
  }] = useLazyQuery(QueryGql.GET_BY_ID_ADD_ON);
  const [updateAddOn, {
    loading: loadingUpdateAddOn
  }] = useMutation(QueryGql.UPDATE_ADD_ON, {
    onCompleted: () => {
      history?.goBack();
    },
    onError: () => {
      history?.goBack();
    }
  });
  const [createAddOn, {
    loading: loadingCreateAddOn
  }] = useMutation(QueryGql.CREATE_ADD_ON, {
    onCompleted: () => {
      history?.goBack();
    },
    onError: () => {
      history?.goBack();
    },
    update(cache, { data: { createAddOn }}) {
      cache.modify({
        fields: {
          getAllAddOns(existingAddOns = { data : [] }) {
            const newAddOnsRef = cache.writeFragment({
              data: createAddOn.data,
              fragment: QueryGql.FRAGMENT_ADD_ON
            });
            return {
              ...existingAddOns,
              data: [
                ...existingAddOns.data,
                newAddOnsRef,
              ]
            };
          }
        }
      });
    }
  });
  const [deleteAddOn, {
    loading: loadingDeleteAddOn
  }] = useMutation(QueryGql.DESTROY_ADD_ON, {
    onCompleted: () => {
      history?.goBack();
    },
    onError: () => {
      history?.goBack();
    },
    update(cache, { data: { destroyAddOn }}) {
      cache.modify({
        fields: {
          getAllAddOns(existingAddOns) {
            return {
              ...existingAddOns,
              data: existingAddOns?.data.filter(item => !item.__ref.includes(destroyAddOn.data?.id))
            };
          }
        }
      });
    }
  });

  useEffect(() => {
    if (act === 'edit') {
      const id = queryString.get('id');
      findAddOnById({
        variables: {
          getByIdAddOnId: id
        }
      });
    }
  }, []);

  const handleSubmit = (values) => {
    const { price, name } = values;
    if (act === 'edit') {
      const { id } = dataFindAddOnById?.getByIdAddOn?.data;
      updateAddOn({
        variables: {
          updateAddOnId: id,
          updateAddOnName: name,
          updateAddOnPrice: Number(price),
        }
      });
    } else {
      createAddOn({
        variables: {
          createAddOnName: name,
          createAddOnPrice: Number(price),
        }
      });
    }
  };

  const handleDelete = () => {
    const { id } = dataFindAddOnById?.getByIdAddOn?.data;
    deleteAddOn({
      variables: {
        destroyAddOnId: id
      }
    });
  };
  
  return (
    <Modal
      title={act === 'add' ? 'Add add-on' : 'Edit add-on'}
      hiddenLeftFooterButton={act === 'add'}
      leftFooterButton={{
        label: 'Delete add-on',
        type: 'danger',
        state: 'hint',
        loading: loadingDeleteAddOn,
        onClick: handleDelete
      }}
      rightFooterButton={{
        label: 'Save changes',
        state: (
          loadingFindAddOnById || 
          loadingUpdateAddOn || 
          loadingCreateAddOn || 
          loadingDeleteAddOn ) ? 'disabled' : 'default',
        loading: loadingUpdateAddOn || loadingCreateAddOn,
        onClick: () => refFormik.current.handleSubmit()
      }}
      bodyStyle={{ textAlign: 'right' }}
      width={540}
    >
      <Card className="add-ons-inclusions-add-ons">
        <Formik
          enableReinitialize
          innerRef={refFormik}
          validationSchema={AddOnSchemaValidations}
          initialValues={dataFindAddOnById?.getByIdAddOn?.data || {
            name: '',
            price: 0
          }}
          onSubmit={handleSubmit}
        >
          {({
            handleChange,
            values,
            errors
          }) => {
            return (
              <>
                <HorizontalTextInput
                  label="Add-on name"
                  value={values?.name}
                  onChange={handleChange('name')}
                  errorMessage={errors?.name}
                />
                <div className="spacer" />
                <HorizontalTextInput
                  label="Additional charge"
                  value={values?.price}
                  onChange={handleChange('price')}
                  prefix={<Text type="secondary">Rp. </Text>}
                  errorMessage={errors?.price}
                />
              </>
            );
          }}
        </Formik>
      </Card>
    </Modal>
  );
};

export default AddOnsInclusionsAddOns;
