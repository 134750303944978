import React from 'react';

function SvgDownArrow() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        fill="currentColor"
        d="M10.417 13.083l4.666-4.75a.806.806 0 000-1.167.806.806 0 00-1.166 0L9.75 11.25 5.583 7.167a.806.806 0 00-1.166 0 .757.757 0 00-.25.583c0 .25.083.416.25.583l4.666 4.75c.417.333.917.333 1.334 0-.084 0-.084 0 0 0z"
      ></path>
    </svg>
  );
}

export default SvgDownArrow;
