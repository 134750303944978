/* eslint-disable max-len */
import React from 'react';

function SvgInformation() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      fill="none"
      viewBox="0 0 14 14"
    >
      <path
        fill="currentColor"
        d="M7 6.334a.667.667 0 00-.667.667v2.666a.667.667 0 101.334 0V7.001A.667.667 0 007 6.334zm.253-2.613a.667.667 0 00-.506 0 .667.667 0 00-.22.14.767.767 0 00-.14.22.667.667 0 00.14.727c.064.058.139.106.22.14a.667.667 0 00.726-1.087.667.667 0 00-.22-.14zM7 .334a6.667 6.667 0 100 13.333A6.667 6.667 0 007 .334zm0 12A5.334 5.334 0 117 1.667a5.334 5.334 0 010 10.667z"
      ></path>
    </svg>
  );
}

export default SvgInformation;