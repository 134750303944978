/* eslint-disable max-len */
import React from 'react';

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="4"
      height="16"
      fill="none"
      viewBox="0 0 4 16"
    >
      <path
        fill="currentColor"
        d="M2 3.833A1.667 1.667 0 102 .5a1.667 1.667 0 000 3.333zm0 8.334A1.667 1.667 0 102 15.5a1.667 1.667 0 000-3.333zm0-5.834a1.667 1.667 0 100 3.334 1.667 1.667 0 000-3.334z"
      ></path>
    </svg>
  );
}

export default Icon;
