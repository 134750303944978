import React from 'react';
import { useHistory } from 'react-router-dom';
import Pathname from '../../constants/Pathname';
import Modal from '../organisms/Modal';
import CustomerImport from '../templates/CustomerImport';

const CustomerListImport = () => {
  const history = useHistory();
  return (
    <Modal
      title="Import customers"
      leftFooterButton={{
        label: 'Download xlsx template',
        state: 'hint'
      }}
      rightFooterButton={{
        label: 'Import customers',
        onClick: () => history.push(Pathname.CUSTOMER_LIST_IMPORT_CONFIRM)
      }}
      width={540}
    >
      <CustomerImport />
    </Modal>
  );
};

export default CustomerListImport;