import React from 'react';
import { Row, Timeline, Col } from 'antd';
import PropTypes from 'prop-types';
import Text from 'antd/lib/typography/Text';
import './ReservationSteps.less';

ReservationSteps.propTypes = {
  initial: PropTypes.number,
  data: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
  }))
};

export default function ReservationSteps({ data }) {

  return (
    <Timeline style={{ marginBottom: -8 }}>
      {data.map(item => (
        <Timeline.Item key={item.id} color="gray">
          <Row justify="space-between" align="middle">
            <Col>
              <Text type="secondary" className="footer-text-timeline">{item.title}</Text>
            </Col>
            <Col>
              <Text type="secondary" className="footer-text-timeline">{item.description}</Text>
            </Col>
          </Row>
        </Timeline.Item>
      ))}
    </Timeline>
  );
}