import { DatePicker } from 'antd';
import Text from 'antd/lib/typography/Text';
import React from 'react';
import PropTypes from 'prop-types';
import './HorizontalDateRange.less';

const { RangePicker } = DatePicker;

const HorizontalDateRange = props => {
  const { label } = props;
  return (
    <div className='antd-horizontal-date-range'>
      {label && <Text className='label'>{label}</Text>}
      <RangePicker {...props} size='large' className='date-range' />
    </div>
  );
};

export default HorizontalDateRange;

HorizontalDateRange.propTypes = {
  label: PropTypes.string.isRequired,
};
