import { Col, Row } from 'antd';
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import TableReport from '../molecules/TableReport';
import formatter from '../../utils/formatter';

const RevenueDetailed = ({ isPrint, dataRevenueByCustomer, dataRevenueByPayment, dataRevenueByGreenFees, dataRevenueByAddOns }) => {

  const revenueByCustomer = useMemo(() => {
    return {
      columns: [
        {
          title: 'Types & memberships',
          dataIndex: 'name',
          key: 'name',
          width: 350,
        },
        {
          title: 'Average',
          dataIndex: 'average',
          key: 'average',
          total:  dataRevenueByCustomer?.average,
        },
        {
          title: 'Quantity',
          dataIndex: 'quantity',
          key: 'quantity',
          total: dataRevenueByCustomer?.quantity,
        },
        {
          title: 'Total',
          dataIndex: 'total',
          key: 'total',
          total: dataRevenueByCustomer?.total,
        },
      ],
      dataSource: dataRevenueByCustomer?.dataSource
    };
  }, [dataRevenueByCustomer]);

  const revenueByPayment = useMemo(() => {
    return {
      columns: [
        {
          title: 'Payment Methods',
          dataIndex: 'name',
          key: 'name',
          width: 350,
        },
        {
          title: 'Average',
          dataIndex: 'average',
          key: 'average',
          total: dataRevenueByPayment.average,
        },
        {
          title: 'Quantity',
          dataIndex: 'quantity',
          key: 'quantity',
          total: dataRevenueByPayment.quantity,
        },
        {
          title: 'Total',
          dataIndex: 'total',
          key: 'total',
          total: dataRevenueByPayment.total,
        },
      ],
      dataSource: dataRevenueByPayment?.dataSource
    };
  }, [dataRevenueByPayment]);

  const revenueByGreenFeesAndAddOns = useMemo(() => {
    return {
      columns: [
        {
          title: 'Customer type / membership',
          dataIndex: 'name',
          key: 'name',
          width: 350,
        },
        {
          title: 'Average',
          dataIndex: 'average',
          key: 'average',
          total: formatter.currency(dataRevenueByGreenFees.average + dataRevenueByAddOns.average),
          extraTotal: dataRevenueByGreenFees?.extra?.average,
        },
        {
          title: 'Quantity',
          dataIndex: 'quantity',
          key: 'quantity',
          total: dataRevenueByGreenFees.quantity + dataRevenueByAddOns.quantity,
          extraTotal: dataRevenueByGreenFees?.extra?.quantity,
        },
        {
          title: 'Total',
          dataIndex: 'total',
          key: 'total',
          total: formatter.currency(dataRevenueByGreenFees.total + dataRevenueByAddOns.total),
          extraTotal: dataRevenueByGreenFees?.extra?.total,
        },
      ],
      dataSource: dataRevenueByGreenFees.dataSource,
      secondColumns: [
        {
          title: 'Add-ons',
          dataIndex: 'name',
          key: 'name',
          width: 350,
        },
        {
          title: 'Average',
          dataIndex: 'average',
          key: 'average',
          total: 0,
        },
        {
          title: 'Quantity',
          dataIndex: 'quantity',
          key: 'quantity',
          total: 0,
        },
        {
          title: 'Total',
          dataIndex: 'total',
          key: 'total',
          total: 0,
        },
      ],
      secondDataSource: dataRevenueByAddOns.dataSource,
    };
  }, [dataRevenueByGreenFees, dataRevenueByAddOns]);

  return(
    <Row gutter={[32, 32]}>
      <Col span={24}>
        <TableReport
          title="Revenue by green fees and add-ons"
          totalTitle="Total Revenue"
          extraTitle="Cancellation fee"
          isPrint={isPrint}
          {...revenueByGreenFeesAndAddOns}
        />
      </Col>
      <Col span={24}>
        <TableReport
          title="Revenue by customer types & memberships"
          isPrint={isPrint}
          {...revenueByCustomer}
        />
      </Col>
      <Col span={24}>
        <TableReport
          title="Revenue by payment methods"
          isPrint={isPrint}
          {...revenueByPayment}
        />
      </Col>
    </Row>
  );
};

RevenueDetailed.propTypes = {
  isPrint: PropTypes.bool,
  revenueByCustomer: PropTypes.any,
  revenueByPayment: PropTypes.any,
  revenueByGreenFees: PropTypes.any,
  revenueByGreenAddon: PropTypes.any,
};

export default RevenueDetailed;