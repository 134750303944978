import Icon from '@ant-design/icons';
import { useQuery } from '@apollo/client';
import React from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import QueryGql from '../../constants/QueryGql';
import { featuresUserToLocal } from '../../utils/function';
import SvgIcon from '../atoms/SvgLogo';
import './Splash.less';

const Splash = () => {

  const location = useLocation();

  const {
    loading: loadingGetAllFeaturesUser,
    data: dataGetAllFeaturesUser
  } = useQuery(QueryGql.GET_ALL_FEATURES_USER, {
    onCompleted: (res) => {
      const { scopes } = res.getAllFeaturesUser;
      // FOR SPECIFIC FEATURE
      // const allFeatures = [];
      // scopes.forEach(scope => {
      //   scope.account_features.forEach(feature => {
      //     allFeatures.push({
      //       ...scope,
      //       ...feature
      //     });
      //   });
      // });
      featuresUserToLocal(scopes);
    }
  });

  if (!loadingGetAllFeaturesUser && dataGetAllFeaturesUser?.getAllFeaturesUser) {
    return (
      <Redirect
        to={location?.state?.from || { pathname: '/'}}
      />
    );
  }

  if (!loadingGetAllFeaturesUser && !dataGetAllFeaturesUser?.getAllFeaturesUser) {
    return (
      <Redirect
        to={{ pathname: '/login' }}
      />
    );
  }

  return (
    <div id="splash">
      <Icon component={SvgIcon} className="icon" />
    </div>    
  );
};

export default Splash;