import React from 'react';

function SvgTag() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="none"
      viewBox="0 0 48 48"
    >
      <path
        fill="#fff"
        d="M8 22.178l16.98 17 14.18-14.2-17-16.98H11.8l-3.8 3.8v10.38z"
      ></path>
      <path
        fill="currentColor"
        d="M14 12a2 2 0 100 4 2 2 0 000-4zm29.42 11.56L24.46 4.64A2 2 0 0023 4H11a2 2 0 00-1.42.58l-5 4.98A2 2 0 004 10.98v12a2.1 2.1 0 00.58 1.42l18.98 19a2.1 2.1 0 001.42.58 2 2 0 001.42-.58l17.02-17.02a2.002 2.002 0 00.58-1.42 2.1 2.1 0 00-.58-1.4zM24.98 39.18L8 22.18V11.8L11.8 8h10.36l17 16.98-14.18 14.2z"
      ></path>
    </svg>
  );
}

export default SvgTag;
