import Text from 'antd/lib/typography/Text';
import React, { useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import ModalCancelAction from '../templates/ModalCancelAction';
import extractQuery from '../../utils/useQuery';
import { useMutation } from '@apollo/client';
import Pathname from '../../constants/Pathname';
import QueryGql from '../../constants/QueryGql';

const RedeemRaincheck = () => {
  const history = useHistory();
  const location = useLocation();
  const state = history.location.state || {};
  const queryString = extractQuery(location.search);
  const id = queryString.get('id') || '';

  const ids = id.split(',');

  const number = useMemo(() => {
    const items = [];
    if (Array.isArray(state.data)) {
      state.data.forEach(element => {
        items.push(element.code);
      });
    } else if (state.data) {
      items.push(state.data.code);
    }
    return items.join(', ');
  }, [state]);

  const [redeemRaincheck, { loading }] = useMutation(QueryGql.REDEEM_CUSTOMER_CHECKINS, {
    onCompleted: () => {
      history.replace(Pathname.RAINCHECK_LIST + '?refetch=true');
    },
    onError: () => {}
  });

  const handleRedeem = () => {
    for (let index = 0; index < ids.length; index++) {
      const redeemRaincheckId = ids[index];
      redeemRaincheck({ variables: { redeemRaincheckId } });
    }
  };
  

  return (
    <ModalCancelAction
      title={`Redeem ${ids.length} raincheck?`}
      leftFooterButton={{
        label: 'Cancel redeem',
        type: 'link',
      }}
      rightFooterButton={{
        label: 'Continue to redeem',
        state: 'default',
        loading,
        onClick: handleRedeem,
      }}
    >
      <Text>Redeem the selected {number} raincheck?</Text>
    </ModalCancelAction>
  );
};

export default RedeemRaincheck;