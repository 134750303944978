import React, { useEffect, useMemo, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Card } from 'antd';
import * as Yup from 'yup';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import HorizontalTextInput from '../molecules/HorizontalTextInput';
import HorizontalTextInputPin from '../molecules/HorizontalTextInputPin';
import Modal from '../organisms/Modal';
import './AccountsSettings.less';
import extractQuery from '../../utils/useQuery';
import QueryGql from '../../constants/QueryGql';
import HorizontalSelectOptions from '../molecules/HorizontalSelectOptions';
import { Formik } from 'formik';

const AccountsSettingsSchemaValidations = Yup.object().shape({
  full_name: Yup.string().required('Required'),
  email: Yup.string().email('Invalid email').required('Required'),
  id: Yup.string().required('Required'),
  inputPin: Yup.string(),
});

const AccountsSettings = () => {
  const history = useHistory();
  const location = useLocation();
  const refFormik = useRef();
  const queryString = extractQuery(location.search);
  const act = queryString.get('act');

  const {
    loading: loadingGetAllRoles,
    data: dataGetAllRoles
  } = useQuery(QueryGql.GET_ALL_ROLES);

  const [findAccountById, {
    loading: loadingFindAccountById,
    data: dataFindAccountById
  }] = useLazyQuery(QueryGql.FIND_ACCOUNT_BY_ID);
  const [updateAccount, {
    loading: loadingUpdateAccount
  }] = useMutation(QueryGql.UPDATE_ACCOUNT, {
    onCompleted: () => {
      history?.goBack();
    },
    onError: () => {
      history?.goBack();
    }
  });
  const [createAccount, {
    loading: loadingCreateAccount
  }] = useMutation(QueryGql.CREATE_ACCOUNT, {
    onCompleted: () => {
      history?.goBack();
    },
    onError: () => {
      history?.goBack();
    },
    update(cache, { data: { createAccount } }) {
      cache.modify({
        fields: {
          searchAccount(existingAccounts = []) {
            const newAccountsRef = cache.writeFragment({
              data: createAccount.data,
              fragment: QueryGql.FRAGMENT_ACCOUNT
            });
            return {
              ...existingAccounts,
              data: [
                ...existingAccounts.data,
                newAccountsRef,
              ]
            };
          }
        }
      });
    }
  });
  const [deleteAccount, {
    loading: loadingDeleteAccount
  }] = useMutation(QueryGql.DELETE_ACCOUNT, {
    onCompleted: () => {
      history?.goBack();
    },
    onError: () => {
      history?.goBack();
    },
    update(cache, { data: { deleteAccount } }) {
      cache.modify({
        fields: {
          searchAccount(existingAccounts = []) {
            return {
              ...existingAccounts,
              data: existingAccounts?.data.filter(item => !item.__ref.includes(deleteAccount.data?.id))
            };
          }
        }
      });
    }
  });

  useEffect(() => {
    if (act === 'edit') {
      const id = queryString.get('id');
      findAccountById({
        variables: {
          id
        }
      });
    }
  }, []);

  const optionsRoleAccess = useMemo(() => {
    return dataGetAllRoles?.getAllRoles?.data?.map(item => ({
      key: item.id,
      value: item.id,
      label: item.name
    }));
  }, [dataGetAllRoles]);

  const handleDelete = () => {
    const { id } = dataFindAccountById.findAccountById?.data;
    deleteAccount({
      variables: {
        deleteAccountId: id
      }
    });
  };

  const handleSubmit = (values) => {
    if (act === 'edit') {
      const { id } = dataFindAccountById.findAccountById?.data;
      if (values.inputPin === '....') {
        updateAccount({
          variables: {
            updateAccountId: id,
            updateAccountFullname: values.full_name,
            updateAccountEmail: values.email,
            updateAccountRoleId: values.id
          }
        });
      } else {
        updateAccount({
          variables: {
            updateAccountId: id,
            updateAccountFullname: values.full_name,
            updateAccountEmail: values.email,
            updateAccountRoleId: values.id,
            updateAccountPin: values.inputPin
          }
        });
      }
    } else {
      createAccount({
        variables: {
          createAccountFullname: values.full_name,
          createAccountRoleId: values.id,
          createAccountPin: values.inputPin,
          createAccountEmail: values.email
        }
      });
    }
  };

  return (
    <Modal
      title={act === 'add' ? 'Add new account' : 'Edit account'}
      hiddenLeftFooterButton={act === 'add'}
      leftFooterButton={{
        label: 'Remove account',
        type: 'danger',
        state: 'hint',
        loading: loadingDeleteAccount,
        onClick: handleDelete
      }}
      rightFooterButton={{
        label: 'Save changes',
        state: (
          loadingFindAccountById ||
          loadingUpdateAccount ||
          loadingCreateAccount ||
          loadingDeleteAccount) ? 'disabled' : 'default',
        onClick: () => refFormik.current.handleSubmit()
      }}
      width={540}
    >
      <Card className="account-settings">
        <Formik
          enableReinitialize
          innerRef={refFormik}
          initialValues={dataFindAccountById?.findAccountById?.data ? {
            full_name: dataFindAccountById?.findAccountById?.data.full_name,
            email: dataFindAccountById?.findAccountById?.data.email,
            id: dataFindAccountById?.findAccountById?.data.role.id,
            inputPin: '....'
          } : {
            full_name: '',
            email: '',
            id: '',
            inputPin: ''
          }}
          validationSchema={AccountsSettingsSchemaValidations}
          onSubmit={handleSubmit}
        >
          {({
            handleChange,
            values,
            errors
          }) => {
            return (
              <>
                <HorizontalTextInput
                  label="Full name"
                  value={values.full_name}
                  errorMessage={errors.full_name}
                  onChange={handleChange('full_name')}
                  disabled={loadingFindAccountById || loadingCreateAccount || loadingUpdateAccount}
                />
                <div className="spacer" />
                <HorizontalTextInput
                  label="Email"
                  value={values.email}
                  errorMessage={errors.email}
                  onChange={handleChange('email')}
                  disabled={loadingFindAccountById || loadingCreateAccount || loadingUpdateAccount}
                />
                <div className="spacer" />
                <HorizontalSelectOptions
                  label="Role access"
                  loading={loadingGetAllRoles}
                  options={optionsRoleAccess}
                  value={values.id}
                  errorMessage={errors.id}
                  onChange={handleChange('id')}
                  disabled={loadingFindAccountById || loadingCreateAccount || loadingUpdateAccount}
                />
                <div className="spacer" />
                <HorizontalTextInputPin
                  label="Account PIN"
                  value={values.inputPin}
                  isInputSecure
                  placeholder="1234"
                  errorMessage={errors.inputPin}
                  onChange={handleChange('inputPin')}
                  disabled={loadingFindAccountById || loadingCreateAccount}
                />
              </>
            );
          }}
        </Formik>
      </Card>
    </Modal>
  );
};

export default AccountsSettings;
