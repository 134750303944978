import React from 'react';
import PropTypes from 'prop-types';
import numeral from 'numeral';
import Title from 'antd/lib/typography/Title';
import Text from 'antd/lib/typography/Text';
import { LineChart, Line, YAxis, Legend, ResponsiveContainer, Tooltip, CartesianGrid } from 'recharts';

import './ReservationBarChart.less';
import TooltipChart from '../molecules/TooltipChart';
import formatter from '../../utils/formatter';

const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    return (
      <TooltipChart
        title={payload[0].payload.name}
        value={formatter.currency(payload[0].value)}
      />
    );
  }

  return null;
};

const RevenueLineChart = ({ data, course, isPrint }) => {

  return (
    <div id={`${isPrint ? 'print-' : '' }revenue-line-chart`} className="reservation-bar-chart">
      <Title className="title" level={4}>Total Revenue</Title>
      <div>
        <ResponsiveContainer width="100%" height={340}>
          <LineChart
            data={data}
            margin={{
              top: 30,
              right: 20,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid
              vertical={false}
              stroke="#F5F5F5"
              strokeWidth={1}
            />
            <Tooltip
              cursor={false}
              content={<CustomTooltip />}
              allowEscapeViewBox={{ x: true, y: true }}
              position={{ y: -60, }}
              wrapperStyle={{ marginLeft: -106 }}
            />
            <YAxis
              tickLine={false}
              axisLine={false}
              tickFormatter={(value) => numeral(value).format('$0,0[.]00')}
              tick={{ fill: '#8F8F8F', style: { fontSize: 14 } }}
              width={100}
            />
            <Legend
              align="left"
              iconType="circle"
              height={36}
              iconSize={10}
              wrapperStyle={{ height: 40, paddingTop: 30 }}
              formatter={() => <Text>{course || 'All golf courses'}</Text>}
            />
            <Line
              dataKey="value"
              stroke="#1F4BFF"
              dot={false}
              strokeWidth={2}
              activeDot={{
                stroke: 'none',
                color: '#0025B8',
                r: 4,
              }}
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

RevenueLineChart.propTypes = {
  isPrint: PropTypes.bool,
  course: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.number
  }))
};

RevenueLineChart.defaultProps = {
  data: []
};

export default RevenueLineChart;