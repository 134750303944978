import { Col, message, Row } from 'antd';
import React, { useState } from 'react';
import HorizontalDateRange from '../molecules/HorizontalDateRange';
import MultipleSelect from '../molecules/MultipleSelect';
import SingleSelect from '../molecules/SingleSelect';
import Modal from '../organisms/Modal';
import FileSelectExport from '../molecules/FileSelectExport';
import { useFormik } from 'formik';
import useGetCourses from '../../hooks/useGetCourses';
import RevenueReportTemplate from '../templates/RevenueReport';
import moment from 'moment';
import { useMutation, useQuery } from '@apollo/client';
import QueryGql from '../../constants/QueryGql';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import sleep from '../../utils/sleep';
import config from '../../constants/config';

const ExportRevenueReport = () => {
  const { optionsCourses } = useGetCourses();
  const [reportTypes] = useState([
    { key: 'all', value: 'all', label: 'by all report types' },
    { key: 'golfCourses', value: 'golf-course', label: 'by Golf course' },
    { key: 'golfAddons', value: 'add-ons', label: 'by Add-ons' },
    { key: 'costumerTypes', value: 'customer-memberships', label: 'by Customer types & memberships' },
    { key: 'paymentMethods', value: 'payment-methods', label: 'by Payment methods' },
    { key: 'revenueBreakdown', value: 'revenue-breakdown', label: 'by Revenue breakdown' },
    { key: 'paidUnpaid', value: 'paid-unpaid', label: 'by Paid unpaid' },

  ]);
  const [reportMode] = useState([{ value: 'Summary mode', label: 'Summary mode' }, { value: 'Detail mode', label: 'Detail Mode' }]);
  const [dataReport, setDataReport] = useState({});

  const { refetch } = useQuery(QueryGql.GET_REVENUE_REPORT, { skip: true });

  const [exportMutation] = useMutation(QueryGql.EXPORT_REVENUE_REPORT);


  const { values, isSubmitting, setFieldValue, submitForm } = useFormik({
    initialValues: {
      file: { name: 'File', key: 'pdf', label: 'PDF' },
      course: { key: 'all', label: 'All golf courses', value: 'all' },
      mode: ['Summary mode', 'Detail mode'],
      types: ['all']
    },
    onSubmit: async ({ file, course, date, mode, types }, { setSubmitting }) => {
      try {
        const variables = {
          start_date: moment(date[0]).format('YYYY-MM-DD'),
          end_date: moment(date[1]).format('YYYY-MM-DD'),
          course_id: course.value === 'all' ? null : course.value,
          'revenue_by_green_fee': types.includes('all') || types.includes('golf-course'),
          'revenue_by_customer_memberships': types.includes('all') || types.includes('customer-memberships'),
          'revenue_by_payment_methods': types.includes('all') || types.includes('payment-methods'),
          'revenue_by_add_ons': types.includes('all') || types.includes('add-ons'),
        };

        if (file.key === 'xlsx') {
          const res = await exportMutation({
            variables: {
              data: variables,
            }
          });
          const data = res.data.getRevenueExport?.data;
          window.open(config.graphql.replace('graphql', '') + data.link_download, '_target');
        }

        if (file.key === 'pdf') {
          const res = await refetch({
            data: variables,
          });
          
          const data = res.data.getRevenueReport?.data;
          setDataReport(data);

          await sleep(1000);
          const canvasCards = await html2canvas(document.getElementById('print-revenue-cards'));
          const imgCard = canvasCards.toDataURL('image/png');
  
          const canvasCharts = await html2canvas(document.getElementById('print-revenue-line-chart'));
          const imgCharts = canvasCharts.toDataURL('image/png');
            
          const doc = new jsPDF('p', 'mm', 'a4');
          const width = doc.internal.pageSize.getWidth();
          let finalY = 0;
  
          doc.addImage(imgCard, 'PNG', 5, 5, width - 10, 40);
          doc.addImage(imgCharts, 'PNG', 5, 50, width - 10, 70);
  
          finalY += 120;
  
          const showSummary = mode.includes('Summary mode');
          const showDetail = mode.includes('Detail mode');
  
          if (showSummary) {
            let start = 1;
  
            for (let index = 0; index < reportTypes.length; index++) {
              const element = reportTypes[index];
              if (element.value !== 'all') {
                if (types.includes('all') || types.includes(element.value)) {
                  const canvas = await html2canvas(document.getElementById('print-' + element.value));
                  const img = canvas.toDataURL('image/png');
                  const widthElement =  (width / 2) - 8;
                  let yElement = 5;
  
                  if (start % 2 === 0) {
                    yElement = widthElement + 12;
                  }
  
                  if (index === 5) {
                    doc.addPage();
                    finalY = 0;
                  }
                  doc.addImage(img, 'PNG', yElement, finalY + 5, widthElement, 80);
  
                  if (start % 2 === 0) {
                    finalY += 85;
                  }
                  
                  start += 1;

    
                }
              }
            }
            
            if (start % 2 === 0) {
              finalY += 85;
            }

          } 
           
          if (showDetail) {

            const tables = document.getElementById('print-revenue').getElementsByTagName('table');

            doc.autoTable({
              startY: finalY + 20,

              margin: { top: 5, left: 5, right: 5 },
              columns: [],
              body: []
            });

            finalY = doc.lastAutoTable.finalY;

            doc.setFontSize(14);
            doc.text('Revenue by green fees and add-ons', 5, finalY + 5);

            doc.autoTable({
              html: tables[0],
              useCss: true,
              startY: finalY + 10,
              margin: { top: 5, left: 5, right: 5 },
            });

            finalY = doc.lastAutoTable.finalY;

            doc.autoTable({
              html: tables[1],
              useCss: true,
              startY: finalY + 8,
              margin: { top: 5, left: 5, right: 5 },
            });

            finalY = doc.lastAutoTable.finalY;

            doc.setFontSize(14);
            doc.text('Revenue by customer types & memberships', 5, finalY + 10);

            doc.autoTable({
              html: tables[2],
              useCss: true,
              startY: finalY + 15,
              margin: { top: 5, left: 5, right: 5 },
            });

            finalY = doc.lastAutoTable.finalY;


            doc.setFontSize(14);
            doc.text('Revenue by payment methods', 5, finalY + 10);

            doc.autoTable({
              html: tables[3],
              useCss: true,
              startY: finalY + 15,
              margin: { top: 5, left: 5, right: 5 },
            });
          }
  
          doc.save(`${file.name}.pdf`);
        }

        setSubmitting(false);
      } catch (error) {
        setSubmitting(false);
        message.error('Failed export');
      }
    }
  });

  return (
    <Modal
      title='Export Revenue Report'
      width={600}
      hiddenLeftFooterButton
      rightFooterButton={{
        label: 'Export Revenue Report',
        loading: isSubmitting,
        state: values.date ? 'default' : 'disabled',
        onClick: submitForm
      }}
    >
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <FileSelectExport
            value={values.file}
            onChange={(item) => setFieldValue('file', item)}
            items={[
              {
                key: 'pdf',
                label: 'PDF'
              },
              {
                key: 'xlsx',
                label: 'XLSX'
              }
            ]}
          />
        </Col>
        <Col span={24}>
          <SingleSelect
            label="Golf courses"
            items={optionsCourses}
            fullWidth
            type="text"
            size="large"
            value={values.course.value}
            valueLabel={values.course.label}
            onSelect={(v) => setFieldValue('course', v)}
          />
        </Col>
        <Col span={24}>
          <MultipleSelect
            label="Revenue report type"
            defaultValue="by all report types"
            items={reportTypes}
            values={values.types}
            value={values.types.includes('all') ? 'by all report types' : values.types.map(item => item.replace('-', ' ')).join(', ')}
            fullWidth
            type="text"
            size="large"
            handleChange={(v) => setFieldValue('types', v)}
          />
        </Col>
        <Col span={24}>
          <MultipleSelect
            label="Report mode"
            values={values.mode}
            value={values.mode.length === 2 ? 'Summary & detail' : values.mode[0]}
            items={reportMode}
            fullWidth
            type="text"
            size="large"
            handleChange={(v) => setFieldValue('mode', v)}
          />
        </Col>
        <Col span={24}>
          <HorizontalDateRange
            label="Date"
            onChange={(v) => setFieldValue('date', v)}
          />
        </Col>
      </Row>
      <div style={{ position: 'fixed', bottom: -5000 }}>
        <RevenueReportTemplate
          dataReport={dataReport}
          isPrint={true}
          isDaily={values.date ? (moment(values.date[0]).diff(values.date[1], 'day')) === 0 : false}
          course={values.course}
        />
      </div>
    </Modal>
  );
};

export default ExportRevenueReport;