/* eslint-disable max-len */
import React from 'react';

function SvgSettings() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="none"
      viewBox="0 0 14 14"
    >
      <path
        fill="currentColor"
        d="M12.267 7.44a.667.667 0 010-.88l.853-.96a.667.667 0 00.08-.78l-1.333-2.307a.666.666 0 00-.714-.32L9.9 2.447a.667.667 0 01-.767-.44L8.727.787a.667.667 0 00-.634-.454H5.427a.667.667 0 00-.667.454l-.373 1.22a.667.667 0 01-.767.44l-1.287-.254a.667.667 0 00-.666.32L.333 4.82A.667.667 0 00.4 5.6l.847.96a.667.667 0 010 .88L.4 8.4a.667.667 0 00-.067.78l1.334 2.307a.667.667 0 00.713.32l1.253-.254a.667.667 0 01.767.44l.407 1.22a.667.667 0 00.666.454H8.14a.667.667 0 00.633-.454l.407-1.22a.667.667 0 01.767-.44l1.253.254a.667.667 0 00.713-.32l1.334-2.307a.666.666 0 00-.08-.78l-.9-.96zm-.994.893l.534.6-.854 1.48-.786-.16a2 2 0 00-2.3 1.334l-.254.746H5.907l-.24-.76a2 2 0 00-2.3-1.333l-.787.16-.867-1.473.534-.6a2 2 0 000-2.667l-.534-.6.854-1.467.786.16a2 2 0 002.3-1.333l.254-.753h1.706l.254.76a2 2 0 002.3 1.333l.786-.16.854 1.48-.534.6a2 2 0 000 2.653zm-4.513-4a2.667 2.667 0 100 5.334 2.667 2.667 0 000-5.334zm0 4a1.333 1.333 0 110-2.666 1.333 1.333 0 010 2.666z"
      />
    </svg>
  );
}

export default SvgSettings;
