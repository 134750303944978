import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { Button, notification } from 'antd';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import moment from 'moment';
import QueryGql from '../../constants/QueryGql';
import extractQuery from '../../utils/useQuery';
import Loading from '../atoms/Loading';
import Modal from '../organisms/Modal';
import TemplateCalendarTeeTimeParticularForm from '../templates/CalendarTeeTimeParticularForm';
import IconButtonNavBar from '../atoms/IconButtonNavBar';
import Pathname from '../../constants/Pathname';

const CalendarTeeTimeParticularForm = () => {
  const history = useHistory();
  const location = useLocation();
  const queryString = extractQuery(location.search);
  const date = queryString.get('date');
  const [selectedDate, setSelectedDate] = useState(date);
  const startTime = queryString.get('start-time');
  const endTime = queryString.get('end-time');
  const act = queryString.get('act');
  const redirect = queryString.get('redirect');

  const refTemplateCalendarTeeTimeParticularForm = useRef();
  const { loading: loadingFormCalendar, data: dataFormCalendar } = useQuery(
    QueryGql.FORM_CALENDAR, {
      fetchPolicy: 'network-only'
    }
  );
  const { loading: loadingGetAllCalendar, data: dataGetAllCalendar } = useQuery(
    QueryGql.GET_ALL_CALENDAR, {
      variables: {
        getAllCalendarType: 'day',
        getAllCalendarDate: selectedDate,
      }
    }
  );
  const [getCalendarById, {
    loading: loadingGetCalendarById,
    data: dataGetCalendarById
  }] = useLazyQuery(QueryGql.GET_CALENDAR_BY_ID);

  useEffect(() => {
    if (act === 'edit') {
      const id = queryString.get('id');
      getCalendarById({
        variables: {
          getCalendarByIdId: id
        }
      });
    }
  }, []);

  const disabledRangeHours = useMemo(() => {
    if (dataGetAllCalendar?.getAllCalendar.data) {
      const disabledHours = [];
      if (act === 'edit') {
        const id = queryString.get('id');
        dataGetAllCalendar.getAllCalendar.data.filter(item => item.id !== id).forEach(item => {
          const startTime = moment(item.start_time, 'h:mm:ss A');
          const endTime = moment(item.end_time, 'h:mm:ss A');
          for (let i = startTime.hours(); i < endTime.hours(); i++) {
            disabledHours.push(i);
          }
        });
      } else {
        dataGetAllCalendar.getAllCalendar.data.forEach(item => {
          const startTime = moment(item.start_time, 'h:mm:ss A');
          const endTime = moment(item.end_time, 'h:mm:ss A');
          for (let i = startTime.hours(); i < endTime.hours(); i++) {
            disabledHours.push(i);
          }
        });
      }
      return disabledHours;
    }
    return [];
  }, [dataGetAllCalendar]);

  const [createCalendar, {
    loading: loadingCreateCalendar
  }] = useMutation(QueryGql.CREATE_CALENDAR, {
    onCompleted: () => {
      const key = `open${Date.now()}`;
      notification.success({
        message: 'Tee time slot successfully updated',
        description: `Tee time slot for ${moment(date, 'YYYY-MM-DD').format('dddd, D MMMM YYYY')} have been successfully updated`,
        btn: <Button type="text" className="notification-button-dismiss-success" onClick={() => notification.close(key)}>Dismiss</Button>,
        key,
        closeIcon: <div />,
        placement: 'bottomLeft'
      });
      history?.goBack();
    },
    onError: () => {},
    update(cache, { data: { createCalendar }}) {
      cache.modify({
        fields: {
          getAllCalendar(existingCalendar = { data : [] }) {
            const newCalendarRef = cache.writeFragment({
              data: createCalendar.data,
              fragment: QueryGql.FRAGMENT_CALENDAR
            });
            return {
              ...existingCalendar,
              data: [
                ...existingCalendar.data,
                newCalendarRef,
              ]
            };
          }
        }
      });
    }
  });

  const [createCalendarCustom, {
    loading: loadingCreateCalendarCustom
  }] = useMutation(QueryGql.CREATE_CALENDAR_CUSTOM, {
    onCompleted: () => {
      const key = `open${Date.now()}`;
      notification.success({
        message: 'Tee time slot successfully updated',
        description: `Tee time slot for ${moment(date, 'YYYY-MM-DD').format('dddd, D MMMM YYYY')} have been successfully updated`,
        btn: <Button type="text" className="notification-button-dismiss-success" onClick={() => notification.close(key)}>Dismiss</Button>,
        key,
        closeIcon: <div />,
        placement: 'bottomLeft'
      });
      history?.goBack();
    },
    onError: () => {},
    update(cache, { data: { createCalendarCustom }}) {
      cache.modify({
        fields: {
          getAllCalendar(existingCalendar = { data : [] }) {
            const newCalendarRef = cache.writeFragment({
              data: createCalendarCustom.data,
              fragment: QueryGql.FRAGMENT_CALENDAR
            });
            return {
              ...existingCalendar,
              data: [
                ...existingCalendar.data,
                newCalendarRef,
              ]
            };
          }
        }
      });
    }
  });

  const [updateCalendar, {
    loading: loadingUpdateCalendar
  }] = useMutation(QueryGql.UPDATE_CALENDAR, {
    onCompleted: () => {
      history?.goBack();
    },
    onError: () => {},
  });

  return (
    <Modal
      closeIcon={<IconButtonNavBar type="back" />}
      hiddenLeftFooterButton={act !== 'edit'}
      leftFooterButton={{
        label: 'Remove tee time',
        type: 'danger',
        state: 'hint',
        disabled: loadingGetCalendarById,
        onClick: () => {
          const { tee_times, start_time, end_time, id } = dataGetCalendarById?.getCalendarById?.data;
          history.push(
            Pathname.CALENDAR + '/' +
            Pathname.CALENDAR_TEE_TIME_PARTICULAR_DELETE +
            `?name=${tee_times?.name}&date=${date}&start-time=${start_time}&end-time=${end_time}&id=${id}${redirect ? `&redirect=${redirect}` : ''}`,
            {
              background: true
            }
          );
        }
      }}
      rightFooterButton={{
        label: 'Insert tee time slots',
        loading: loadingCreateCalendar || loadingUpdateCalendar || loadingCreateCalendarCustom,
        onClick: async () => {
          const values = await refTemplateCalendarTeeTimeParticularForm.current.handleSubmit();
          if (values) {
            if (act === 'edit') {
              delete values.createType;
              const id = queryString.get('id');
              updateCalendar({
                variables: {
                  updateCalendarId: id,
                  updateCalendarTeeTimeId: values.tee_time_id,
                  updateCalendarRateCardId: values.rate_card_id,
                  updateCalendarStartTime: values.start_time,
                  updateCalendarEndTime: values.end_time,
                  updateCalendarDate: values.date,
                }
              });
            } else {
              if (values.createType === 'saved') {
                createCalendar({
                  variables: {
                    createCalendarTeeTimeId: values.tee_time_id,
                    createCalendarRateCardId: values.rate_card_id,
                    createCalendarStartTime: values.start_time,
                    createCalendarEndTime: values.end_time,
                    createCalendarDate: values.date,
                  }
                });
              } else {
                createCalendarCustom({
                  variables: {
                    createCalendarCustomNewTeeTime: values.new_tee_time,
                    createCalendarCustomNewRateCard: values.new_rate_card,
                    createCalendarCustomTeeTimeId: values?.tee_time_id,
                    createCalendarCustomRateCardId: values?.rate_card_id,
                    createCalendarCustomStartTime: values?.start_time || values?.teeTime?.start_time,
                    createCalendarCustomEndTime: values?.end_time || values?.teeTime?.end_time,
                    createCalendarCustomDate: values.date,
                    createCalendarCustomTeeTime: values?.teeTime,
                    createCalendarCustomRateCard: values?.rateCard,
                    createCalendarCustomBulk: false
                  }
                });
              }
            }
          }
        },
        type: 'primary'
      }}
      title={act === 'edit' ? 'Edit tee time slots' : 'Insert tee time slots'}
      width={540}
    >
      {loadingFormCalendar || loadingGetCalendarById || loadingGetAllCalendar
        ? <Loading />
        : <TemplateCalendarTeeTimeParticularForm
          ref={refTemplateCalendarTeeTimeParticularForm}
          initialValues={act === 'edit'
            ? {
              tee_time_id: dataGetCalendarById?.getCalendarById?.data?.tee_times.id,
              rate_card_id: dataGetCalendarById?.getCalendarById?.data?.rate_cards.id,
              start_time: dataGetCalendarById?.getCalendarById?.data?.start_time,
              end_time: dataGetCalendarById?.getCalendarById?.data?.end_time,
              date: dataGetCalendarById?.getCalendarById?.data?.date,
            }
            : {
              date,
              start_time: startTime,
              end_time: endTime
            }
          }
          courses={dataFormCalendar?.getAllCourses.data}
          memberships={dataFormCalendar?.getAllMembership.data}
          inclusions={dataFormCalendar?.getAllInclusions.data}
          timeBaseRates={dataFormCalendar?.getAllTimeBaseRate.data}
          rateCards={dataFormCalendar?.getAllRateCards.data}
          teeTimes={dataFormCalendar?.getTeeTimes.data}
          onChangeDate={value => {
            setSelectedDate(value);
          }}
          disabledRangeHours={disabledRangeHours}
        />}
    </Modal>
  );
};

export default CalendarTeeTimeParticularForm;