import React from 'react';
import {
  UilGlobe,
  UilInstagram,
  UilFacebookF,
  UilEnvelope,
  UilPhone,
  UilMapMarkerAlt,
} from '@iconscout/react-unicons';

export const contactProfile = [
  {
    icon: <UilGlobe />,
    label: 'Website',
    field: 'website',
  },
  {
    icon: <UilInstagram />,
    label: 'Instagram',
    field: 'instagram',
  },
  {
    icon: <UilFacebookF />,
    label: 'Facebook',
    field: 'facebook',
  },
  {
    icon: <UilEnvelope />,
    label: 'Email',
    field: 'email',
  },
  {
    icon: <UilPhone />,
    label: 'Phone number',
    field: 'phone_number',
  },
  {
    icon: <UilMapMarkerAlt />,
    label: 'Address',
    field: 'address',
  },
];
