import React from 'react';

function SvgEdit() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        fill="currentColor"
        d="M3.333 12H6.16a.666.666 0 00.473-.193l4.613-4.62 1.894-1.854a.666.666 0 000-.946l-2.827-2.86a.667.667 0 00-.947 0l-1.88 1.886-4.626 4.62a.667.667 0 00-.194.474v2.826a.667.667 0 00.667.667zM9.84 2.94l1.886 1.887-.946.946-1.887-1.886.947-.947zM4 8.78l3.953-3.953L9.84 6.713l-3.954 3.954H4V8.78zm10 4.553H2a.667.667 0 000 1.334h12a.667.667 0 000-1.334z"
      ></path>
    </svg>
  );
}

export default SvgEdit;
