import React, { useMemo, useState, useCallback, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import Pathname from '../../constants/Pathname';
import IconButtonNavBar from '../atoms/IconButtonNavBar';
import extractQuery from '../../utils/useQuery';
import Modal from '../organisms/Modal';
import TeeSheetTakePaymentTemplate from '../templates/TeeSheetTakePayment';
import QueryGql from '../../constants/QueryGql';
import PrintReceipt from 'components/templates/PrintReceipt';
import useGetReservation from 'hooks/useGetReservation';
import { useReactToPrint } from 'react-to-print';

const TeeSheetTakePayment = () => {
  const history = useHistory();
  const location = useLocation();
  const queryString = extractQuery(location.search);
  const type = queryString.get('type');
  const id = queryString.get('id');
  const [customers, setCustomers] = useState([]);
  const [paymentType, setPaymentType] = useState('split');

  const { detailReservation } = useGetReservation(id);

  const printComponentRef = useRef();

  const handlePrintReceipt = useReactToPrint({
    content: () => printComponentRef.current,
  });

  const { loading: loadingGetReservation, data: dataGetReservation } = useQuery(
    QueryGql.GET_PAYMENT_INFO,
    {
      variables: {
        getPaymentInfoId: id
      },
      fetchPolicy: 'network-only'
    }
  );

  const { loading: loadingGetAllPaymentMethods, data: dataGetAllPaymentMethods } = useQuery(
    QueryGql.GET_ALL_PAYMENT_METHODS, {
      fetchPolicy: 'cache-and-network'
    }
  );

  const [createPayment, { loading: loadingCreatePayment }] = useMutation(
    QueryGql.CREATE_PAYMENT,
    {
      onCompleted: () => {
        if (type === 'raincheck') {
          history.push(
            Pathname.TEE_SHEET + '/' +
            Pathname.TEE_SHEET_PAYMENT_SUCCESS+
            `?type=raincheck&id=${id}`,
            {
              background: true
            }
          );
        } else {
          history.push(
            Pathname.TEE_SHEET + '/' +
            Pathname.TEE_SHEET_PAYMENT_SUCCESS+
            `?id=${id}&customers=${customers.map(item => item.customer_id).join(',')}`,
            {
              background: true
            }
          );
        }
      },
      onError: () => {
        // history.goBack();
      },
    }
  );

  const optionsPaymentMethods = useMemo(() => {
    return dataGetAllPaymentMethods?.getAllPaymentMethods.data.map(item => ({
      value: item.code,
      label: item.name
    }));
  }, [dataGetAllPaymentMethods]);
  
  const dataReservation = useMemo(() => {
    if (dataGetReservation) {
      return dataGetReservation?.getPaymentInfo.data.reservation_customers.filter(item => item.status === 'checked_in');
    }
    return [];
  }, [dataGetReservation]);

  const handleChange = useCallback((values) => {
    setCustomers(values);
  }, [customers]);

  const handleChangeType = useCallback((value) => {
    setPaymentType(value);
  }, [paymentType]);

  const handleSubmit = useCallback(() => {
    createPayment({
      variables: {
        createPaymentData: {
          payment_type: paymentType,
          reservation_id: id,
          customers: customers.map(item => (
            {
              payment_methods: item.payment_methods,
              id: item.customer_id
            }
          ))
        }
      }
    });
  }, [customers, type, paymentType]);

  return (
    <Modal
      title="Take payment"
      closeIcon={<IconButtonNavBar type="back" />}
      leftFooterButton={type === 'raincheck'
        ? {
          label: 'Cancel payment',
          type: 'link',
          onClick: () => history.goBack()
        }
        : {
          label: 'Take payment later',
          type: 'outline',
          onClick: () => history.replace(
            Pathname.TEE_SHEET,
            {
              background: true
            }
          )
        }
      }
      rightFooterButtonExtra={type === 'raincheck'
        ? null
        : {
          label: 'Print receipt preview',
          type: 'outline',
          state: customers.length === 0 ? 'disabled' : 'default',
          onClick: customers.length === 0  ? null : handlePrintReceipt
        }
      }
      rightFooterButton={{
        label: 'Take payments',
        state: loadingGetReservation || loadingGetAllPaymentMethods || customers.length === 0 ? 'disabled' : 'default',
        onClick: handleSubmit,
        loading: loadingCreatePayment
      }}
      width={964}
    >
      <TeeSheetTakePaymentTemplate
        optionsPaymentMethods={optionsPaymentMethods}
        dataReservation={dataReservation}
        onChange={handleChange}
        onChangeType={handleChangeType}
      />
      <PrintReceipt 
        data={{
          ...(detailReservation || {}),
          reservation_payments:customers.map(item => ({
            id: item.id,
            paid_by: item.customer_id,
            payment_methods: item.payment_methods?.map(pm => ({
              ...pm,
              name: pm.type
            }))
          }))
        }}
        isPreview
        printComponentRef={printComponentRef}
      />
    </Modal>
  );
};

export default TeeSheetTakePayment;