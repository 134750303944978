import useCourseProfile from 'hooks/useCourseProfile';
import React from 'react';

function PrintCardSigns({ hole, name }) {
  const { clubName, courseProfile } = useCourseProfile();
  
  return (
    <div
      style={{
        border: '#F5F5F5 2px solid',
        borderRadius: '16px',
        margin: '24px',
        padding: '48px',
      }}>
      <div
        style={{
          margin: '0px auto',
          position: 'relative',
          width: '96px',
          height: '96px',
          backgroundColor: '#F7F7F7',
          borderRadius: '16px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          overflow: 'hidden'
        }}>
        <img style={{ maxWidth: '100%', maxHeight: '100%' }} alt="Logo" src={courseProfile?.picture_url || '/logo.png'} /> 
      </div>
      <p style={{ fontWeight: 700, fontSize: '28px', marginTop: '24px', textAlign: 'center' }}>
        {clubName}
      </p>
      <div style={{ display: 'flex', justifyContent: 'space-evenly', marginTop: '48px' }}>
        <div>
          <p style={{ color: '#5C5C5C', fontWeight: 400, fontSize: '14px' }}>Cart reserved for:</p>
          <p style={{ fontSize: '20px', color: '#000D23', fontWeight: 600 }}>{name.name}</p>
        </div>
        <div>
          <p style={{ color: '#5C5C5C', fontWeight: 400, fontSize: '14px' }}>Starting hole:</p>
          <p style={{ fontSize: '20px', color: '#000D23', fontWeight: 600 }}>
            {hole.item.reservation.hole}
          </p>
        </div>
      </div>
    </div>
  );
}

export default PrintCardSigns;