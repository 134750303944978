import React, { useMemo } from 'react';
import { Table } from 'antd';
import Title from 'antd/lib/typography/Title';
import PropTypes from 'prop-types';

import './TableReport.less';
import { DownOutlined, RightOutlined } from '@ant-design/icons';

const TableReport = ({ isPrint, title, totalTitle, columns, dataSource, secondColumns, secondDataSource, extraTitle }) => {

  const isMultiple = Array.isArray(secondColumns);

  const footer = () => {
    return(
      <table style={{ width: '100%', marginTop: -35 }}>
        <thead>
          <tr>
            {
              columns.map((column) => {
                return(
                  <td style={{ width: column.width }} className="table-report-cell" key={column.key}>{column.title}</td>
                );
              })
            }
          </tr>          
        </thead>
        <tbody>
          <tr>
            {
              columns.map((column, index) => {
                return(
                  <td className="table-report-cell" key={column.key}>{index === 0 ? totalTitle ? totalTitle : 'Total ' + column.title : column.total}</td>
                );
              })
            }
          </tr>
        </tbody>
      </table>
    );
  };

  const extraFooter = () => {
    return(
      <table style={{ width: '100%', marginTop: -35 }}>
        <thead>
          <tr>
            {
              columns.map((column) => {
                return(
                  <td style={{ width: column.width }} className="table-report-cell" key={column.key}>{column.title}</td>
                );
              })
            }
          </tr>          
        </thead>
        <tbody>
          <tr>
            {
              columns.map((column, index) => {
                return(
                  <td className="table-report-cell" key={column.key}>{index === 0 ? extraTitle : column.extraTotal}</td>
                );
              })
            }
          </tr>
        </tbody>
      </table>
    );
  };

  const expandable = {
    defaultExpandAllRows: isPrint,
    expandIcon: ({ expanded, onExpand, record }) => {
      
      return !record.children ? null : expanded ? 
        <DownOutlined className="expand-icon" onClick={e => onExpand(record, e)} />
        : 
        <RightOutlined className="expand-icon" onClick={e => onExpand(record, e)} />;
    }
  };

  const rowClassName = (record) => {
    if (record.isTotal) {
      return 'row-total';
    }

    if (record.isFooter) {
      return 'row-footer';
    }

    if (Array.isArray(record.children)) {
      return 'row-expandable';
    }

    return 'row-children';
  };
  
  const data = useMemo(() =>  {
    if (!dataSource) {
      return [];
    }

    const items = [...dataSource];

    if (isPrint && !isMultiple) {
      const item = { isFooter: true };

      columns.forEach((column, index) => {
        if (index === 0) {
          item[column.dataIndex] =  totalTitle ? totalTitle : 'Total ' + column.title;
        } else {
          item[column.dataIndex] = column.total;
        }
      });

      items.push(item);
    }

    return items;
  }, [dataSource, totalTitle]);

  const secondData = useMemo(() =>  {
    if (!secondDataSource) {
      return [];
    }

    const items = [...secondDataSource];

    if (isPrint && isMultiple) {
      const item = { isFooter: true };

      columns.forEach((column, index) => {
        if (index === 0) {
          item[column.dataIndex] =  totalTitle ? totalTitle : 'Total ' + column.title;
        } else {
          item[column.dataIndex] = column.total;
        }
      });

      items.push({});
      items.push(item);
    }

    return items;
  }, [secondDataSource, totalTitle]);
  return(
    <div className="table-report">
      <Title className="title table-report-title" level={4}>{title}</Title>
      <div className="table-report-wrapper">
        <Table
          columns={columns}
          dataSource={data}
          pagination={false}
          expandable={expandable}
          rowClassName={rowClassName}
        />
      </div>
      {
        isMultiple && (
          <div style={{ marginTop: 24 }} className="table-report-wrapper">
            <Table
              columns={secondColumns}
              dataSource={secondData}
              pagination={false}
              expandable={expandable}
              rowClassName={rowClassName}
            />
          </div>
        )
      }
      { !isPrint && extraTitle && <div className="table-report-extra-footer">
        {extraFooter()}
      </div> }
      { !isPrint && <div className="table-report-footer">
        
        {footer()}
      </div> }
    </div>
  );
};

TableReport.propTypes = {
  isPrint: PropTypes.bool,
  title: PropTypes.string,
  totalTitle: PropTypes.string,
  extraTitle: PropTypes.string,
  columns: PropTypes.array,
  dataSource: PropTypes.array,
  secondColumns: PropTypes.array,
  secondDataSource: PropTypes.array,
};

export default TableReport;