import { useMutation, useQuery } from '@apollo/client';
import TextArea from 'antd/lib/input/TextArea';
import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import QueryGql from '../../constants/QueryGql';
import Card from '../atoms/Card';
import Modal from '../organisms/Modal';

const RaincheckSettingsTermCondition = () => {
  const history = useHistory();
  const { data: dataGetTermAndCondition } = useQuery(QueryGql.GET_TERM_AND_CONDITION);
  const termAndCondition = dataGetTermAndCondition?.getTermAndCondition.data.terms_and_conditions;

  const [termAndConditionState, setTermAndConditionState] = useState(termAndCondition);

  const [setTermAndCondition, {
    loading: loadingTermAndCondition
  }] = useMutation(QueryGql.SET_TERM_AND_CONDITION, {
    onCompleted: () => {
      history?.goBack();
    }
  });

  const handleChangeName = useCallback((e) => {
    const name = e.target.value;
    setTermAndConditionState(name);
  }, []);

  const handleAdded = () => {
    setTermAndCondition({
      variables: { 
        setTermAndConditionValue: termAndConditionState
      }
    });
  };
  
  return (
    <Modal
      title="Raincheck terms & condition"
      leftFooterButton={{
        label: 'Discard changes',
        state: 'hint',
      }}
      rightFooterButton={{
        label: 'Save changes',
        state: loadingTermAndCondition ? 'disabled' : 'default',
        onClick: handleAdded,
        loading: loadingTermAndCondition
      }}
      // bodyStyle={{ textAlign: 'right' }}
      width={540}
    >
      <Card>
        <TextArea rows={20} onChange={handleChangeName} value={termAndConditionState}/>
      </Card>
    </Modal>
  );
};

export default RaincheckSettingsTermCondition;
