import React, { useMemo, useState } from 'react';
import { Menu, Dropdown } from 'antd';
import Text from 'antd/lib/typography/Text';
import { useHistory } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import Pathname from '../../constants/Pathname';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import SvgMoreOutline from '../atoms/SvgMoreOutline';
import IconButton from '../atoms/IconButton';
import './RolePermissions.less';
import TableContent from '../templates/TableContent';
import QueryGql from '../../constants/QueryGql';

const RolePermissions = () => {
  const history = useHistory();
  const [searchValue, setSearchValue] = useState('');

  const searchRole = (e) => {
    setSearchValue(e.target.value);
  };

  let objVariabless = {
    searchRoleSearch: searchValue
  };

  const { 
    loading: loadingSearchRole,
    data: dataSearchRole,
    refetch: refetchSearchRole,
  } = useQuery(QueryGql.SEARCH_ROLE, {
    variables: objVariabless
  });

  const [ deleteRole ] = useMutation(QueryGql.DESTROY_ROLE, {
    onCompleted: () => {
      refetchSearchRole();
    },
    onError: () => {
    },
  });

  const data = useMemo(() => {
    return dataSearchRole?.searchRole?.data?.map(item => ({
      key: item.id,
      ...item
    }));
  },[dataSearchRole]);

  const menu = (item) => () => {
    return (
      <Menu>
        <Menu.Item
          key="1"
          onClick={() => history.push(
            Pathname.ROLE_ACCESS+'/'+
            Pathname.ROLE_ACCESS_SETTINGS+
            `?act=edit&id=${item.id}`,
            {
              background: true
            }
          )}
        >
          <EditOutlined /> Edit role
        </Menu.Item>
        <Menu.Item
          key="2" 
          className="second-menu-edit"
          onClick={() => deleteRole({
            variables: {
              destroyRoleId: item.id
            }
          })}
        >
          <DeleteOutlined /> Remove role
        </Menu.Item>
      </Menu>
    );
  };


  const columns = [
    {
      title: 'Role name',
      dataIndex: 'name',
      render: text => <Text type="primary" >{text}</Text>,
    },
    {
      title: 'Number of accounts',
      dataIndex: 'total_accounts',
      render: text => <Text type="primary" >{text || 0}</Text>,
    },
    {
      title: 'Last modified',
      dataIndex: 'updated_at',
      render: text => <Text type="primary" >{text}</Text>,
    },
    {
      title: '',
      dataIndex: 'action',
      align: 'right',
      render: (item, record) => {
        return (
          <Dropdown trigger={['hover', 'click']} overlay={menu(record)}>
            <div><IconButton iconSvg={SvgMoreOutline} iconColor="primary" /></div>
          </Dropdown>
        );
      },
    },
  ];

  return (
    <TableContent
      columns={columns}
      data={data}
      loading={loadingSearchRole}
      buttonAdd={{
        label: 'Add new roles',
        onClick: () => history.push(
          Pathname.ROLE_ACCESS+'/'+
          Pathname.ROLE_ACCESS_SETTINGS+
          '?act=add',
          {
            background: true
          }
        )
      }}
      searchInput={{
        placeholder: 'Search roles',
        onChange: searchRole,
        loading: loadingSearchRole
      }}
    />
  );
};

export default RolePermissions;