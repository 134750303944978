import { Col, Row } from 'antd';
import React from 'react';
import PropTypes from 'prop-types';
import Icon from '@ant-design/icons';
import Title from 'antd/lib/typography/Title';
import './EmptyState.less';

const EmptyState = ({ illustration, label }) => {
  return (
    <Row align="middle" justify="center">
      <Col className="empty-state col">
        <Icon className="illustration" component={illustration} />
        <Title level={4} >{label}</Title>
      </Col>
    </Row>
  );
};

export default EmptyState;

EmptyState.propTypes = {
  illustration: PropTypes.node.isRequired,
  label: PropTypes.string.isRequired
};

