import React from 'react';
import Text from 'antd/lib/typography/Text';
import PropTypes from 'prop-types';
import Card from '../atoms/Card';
import './ReportCard.less';
import { Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';

const ReportCard = ({ title, value, info }) => {

  return(
    <Card>
      <div className="report-card">
        <div>
          <Text className="report-card-title">{title}</Text>
          { info && 
            <Tooltip color="#5C5C5C" overlayInnerStyle={{ lineHeight: '20px', padding: '16px' }} title={info}>
              <InfoCircleOutlined className="info-icon" />
            </Tooltip>
          }
        </div>
        <div>
          <Text className="report-card-value">{value}</Text>
        </div>
      </div>
    </Card>
  );
};

ReportCard.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  info: PropTypes.string,
};

export default ReportCard;