import React, { useMemo } from 'react';
import { Space } from 'antd';
import ReservationBarChart from '../organisms/ReservationBarChart';
import ReservationCards from '../organisms/ReservationCards';
import PieChartList from '../organisms/PieChartList';
import generateColor, { COLORS } from '../../utils/generateColor';
import moment from 'moment';
import formatter from 'utils/formatter';

export default function ReservationReportTemplate({ course, isPrint, dataReport, isDaily, hide }) {
  const summary = dataReport?.summary || {};
  const isAnimationActive = !isPrint;
  const barChartData = useMemo(() => {
    if (isDaily) {
      const items = [];

      dataReport?.reservation_by_datetime?.[0]?.details?.map(item => {
        items.push({
          name:
            dataReport?.reservation_by_datetime[0].date +
            ' - ' +
            moment(item.time, 'HH').format('hh A'),
          value: Number(item.total_reservation),
          time: item.time,
        });
      });

      return items.sort((a, b) => {
        return a.time - b.time;
      });
    }

    const items = [];

    dataReport?.reservation_by_datetime?.map(item => {
      items.push({
        name: item.date,
        value: Number(item.total_reservation),
      });
    });
    return items;
  }, [dataReport, isDaily]);

  const pieChartData = useMemo(() => {
    const getTotal = items => {
      if (!Array.isArray(items)) {
        return 0;
      }
      let total = 0;
      items.forEach(item => {
        total += Number(item.total_reservation);
      });
      return total;
    };

    const getPercentage = (value, total) => {
      if (!value) {
        return 0;
      }
      return formatter.roundToTwoDigitsAfterComma((value / total) * 100);
    };

    const reservationByCourses = [];
    const totalReservationByCourses = getTotal(dataReport?.reservation_by_courses);

    dataReport?.reservation_by_courses?.map((item, index) => {
      reservationByCourses.push({
        name: `${item.course_name} (${item.hole})`,
        color: COLORS[index] || generateColor(item.course_name),
        detail: `${item.total_reservation} reservations (${getPercentage(
          item.total_reservation,
          totalReservationByCourses,
        )}%)`,
        value: item.total_reservation,
      });
    });

    const reservationPaidCancelled = [];
    let totalReservationPaidCancelled = 0;

    dataReport?.reservation_by_status?.map(item => {
      if (item.payment_status === 'paid' || item.payment_status === 'cancelled') {
        totalReservationPaidCancelled += item.total_reservation;
      }
    });

    dataReport?.reservation_by_status?.map((item, index) => {
      if (item.payment_status === 'paid' || item.payment_status === 'cancelled') {
        reservationPaidCancelled.push({
          name: item.payment_status,
          color: COLORS[index] || generateColor(item.payment_status),
          detail: `${item.total_reservation} reservations (${getPercentage(
            item.total_reservation,
            totalReservationPaidCancelled,
          )}%)`,
          value: item.total_reservation,
        });
      }
    });
    const reservationByFlight = [];
    const totalReservationByFlight = getTotal(dataReport?.reservation_by_flight);

    dataReport?.reservation_by_flight?.map((item, index) => {
      reservationByFlight.push({
        name: item.tee_time_name,
        color: COLORS[index] || generateColor(item.tee_time_name),
        detail: `${item.total_reservation} reservations (${getPercentage(
          item.total_reservation,
          totalReservationByCourses,
        )}%)`,
        value: item.total_reservation,
      });
    });

    const reservationByPlayer = [];
    const totalReservationByPlayer = getTotal(dataReport?.reservation_by_player);

    dataReport?.reservation_by_player?.map((item, index) => {
      const name = item.player > 1 ? `${item.player} player` : `${item.player} player`;
      reservationByPlayer.push({
        name: name,
        color: COLORS[index] || generateColor(name),
        detail: `${item.total_reservation} reservations (${getPercentage(
          item.total_reservation,
          totalReservationByCourses,
        )}%)`,
        value: item.total_reservation,
      });
    });

    const reservationWalkIn = [];
    const totalReservationWalkIn = getTotal(dataReport?.reservation_walk_in_upcoming);

    dataReport?.reservation_walk_in_upcoming?.map((item, index) => {
      const name = item.status;
      reservationWalkIn.push({
        name: name,
        color: COLORS[index] || generateColor(name),
        detail: `${item.total_reservation} reservations (${getPercentage(
          item.total_reservation,
          totalReservationByCourses,
        )}%)`,
        value: item.total_reservation,
      });
    });

    const reservationByStatus = [];
    let totalReservationByStatus = 0;
    dataReport?.reservation_by_status?.map(item => {
      if (
        item.payment_status == 'reserved' ||
        item.payment_status == 'checked_in' ||
        item.payment_status == 'paid'
      ) {
        totalReservationByStatus += item.total_reservation;
      }
    });

    dataReport?.reservation_by_status?.map((item, index) => {
      if (
        item.payment_status == 'reserved' ||
        item.payment_status == 'checked_in' ||
        item.payment_status == 'paid'
      ) {
        const name = item.payment_status;
        reservationByStatus.push({
          name: name,
          color: COLORS[index] || generateColor(name),
          detail: `${item.total_reservation} reservations (${getPercentage(
            item.total_reservation,
            totalReservationByCourses,
          )}%)`,
          value: item.total_reservation,
        });
      }
    });

    const reservationStandartVsGroup = [];
    const totalReservationStandartVsGroup = getTotal(dataReport?.reservation_by_group);

    dataReport?.reservation_by_group?.map((item, index) => {
      const name = item.reservation_type;
      reservationStandartVsGroup.push({
        name: name,
        color: COLORS[index] || generateColor(name),
        detail: `${item.total_reservation} reservations (${getPercentage(
          item.total_reservation,
          totalReservationByCourses,
        )}%)`,
        value: item.total_reservation,
      });
    });
    return [
      {
        id: `${isPrint ? 'print-' : 'item-'}` + 'golf-course',
        title: 'Reservation by golf courses',
        total: `${totalReservationByCourses} reservations`,
        items: reservationByCourses,
      },
      {
        id: `${isPrint ? 'print-' : 'item-'}` + 'paid-cancelled',
        title: 'Paid vs cancelled reservations',
        total: `${totalReservationPaidCancelled} reservations`,
        items: reservationPaidCancelled,
      },
      {
        id: `${isPrint ? 'print-' : 'item-'}` + 'flight-type',
        title: 'Reservation by flight type',
        total: `${totalReservationByFlight} reservations`,
        items: reservationByFlight,
      },
      {
        id: `${isPrint ? 'print-' : 'item-'}` + 'player-numbers',
        title: 'Reservation by player numbers',
        total: `${totalReservationByPlayer} reservations`,
        items: reservationByPlayer,
      },
      {
        id: `${isPrint ? 'print-' : 'item-'}` + 'walkin-upcoming',
        title: 'Walk-in vs upcoming reservation (same day or not)',
        total: `${totalReservationWalkIn} reservations`,
        items: reservationWalkIn,
      },
      {
        id: `${isPrint ? 'print-' : 'item-'}` + 'status',
        title: 'Reservation by status (reserved, checked-in, paid)',
        total: `${totalReservationByStatus} reservations`,
        items: reservationByStatus,
      },
      {
        id: `${isPrint ? 'print-' : 'item-'}` + 'standart-group',
        title: 'Standard vs Group reservation (group reservation or not)',
        total: `${totalReservationStandartVsGroup} reservations`,
        items: reservationStandartVsGroup,
      },
    ];
  }, [dataReport, hide]);

  return (
    <Space direction='vertical' size={32}>
      <ReservationCards
        reservation={summary.total_reservation}
        averageReservation={summary.average_reservation_per_hour}
      />
      <ReservationBarChart
        course={course?.label}
        isAnimationActive={isAnimationActive}
        data={barChartData}
      />
      <PieChartList isAnimationActive={isAnimationActive} data={pieChartData} />
    </Space>
  );
}
