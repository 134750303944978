import { Button } from 'antd';
import React from 'react';
import PropTypes from 'prop-types';
import Icon from '@ant-design/icons';
import SvgMenu from './SvgMenu';
import SvgClose from './SvgClose';
import './IconButtonNavBar.less';
import SvgBack from './SvgBack';

const IconButtonNavBar = (props) => {
  const { type } = props;
  let icon;
  switch (type) {
    case 'menu':
      icon = <Icon component={SvgMenu} className="icon-action" />;
      break;
    case 'close':
      icon = <Icon component={SvgClose} className="icon-action"/>;
      break;
    case 'back':
      icon = <Icon component={SvgBack} className="icon-action"/>;
      break;
    default:
      icon = <Icon component={SvgClose} className="icon-action"/>;
      break;
  }
  return (
    <Button {...props} type="default" icon={icon} size={20} className="button-action"/>
  );
};

export default IconButtonNavBar;

IconButtonNavBar.propTypes = {
  type: PropTypes.oneOf(['menu', 'close', 'back']).isRequired
};