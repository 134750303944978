import { useLazyQuery, useMutation } from '@apollo/client';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import QueryGql from '../../constants/QueryGql';
import useQuery from '../../utils/useQuery';
import Card from '../atoms/Card';
import HorizontalTextInput from '../molecules/HorizontalTextInput';
import Modal from '../organisms/Modal';

const AddOnsInclusionsInclusions = () => {
  const history = useHistory();
  const location = useLocation();
  const queryString = useQuery(location.search);
  const act = queryString.get('act');
  const [valueName, setValueName] = useState('');
  const [findInclusionById, {
    loading: loadingFindInclusionById,
    data: dataFindInclusionById 
  }] = useLazyQuery(QueryGql.GET_BY_ID_INCLUSION, {
    onCompleted: (res) => {
      const { name } = res.getByIdInclusion?.data;
      setValueName(name);
    }
  });
  const [updateInclusion, {
    loading: loadingUpdateInclusion
  }] = useMutation(QueryGql.UPDATE_INCLUSION, {
    onCompleted: () => {
      history?.goBack();
    },
    onError: () => {
      history?.goBack();
    }
  });
  const [createInclusion, {
    loading: loadingCreateInclusion
  }] = useMutation(QueryGql.CREATE_INCLUSION, {
    onCompleted: () => {
      history?.goBack();
    },
    onError: () => {
      history?.goBack();
    },
    update(cache, { data: { createInclusion }}) {
      cache.modify({
        fields: {
          getAllInclusions(existingInclusions = { data : [] }) {
            const newInclusionsRef = cache.writeFragment({
              data: createInclusion.data,
              fragment: QueryGql.FRAGMENT_INCLUSION
            });
            return {
              ...existingInclusions,
              data: [
                ...existingInclusions.data,
                newInclusionsRef,
              ]
            };
          }
        }
      });
    }
  });
  const [deleteInclusion, {
    loading: loadingDeleteInclusion
  }] = useMutation(QueryGql.DESTROY_INCLUSION, {
    onCompleted: () => {
      history?.goBack();
    },
    onError: () => {
      history?.goBack();
    },
    update(cache, { data: { destroyInclusion }}) {
      cache.modify({
        fields: {
          getAllInclusions(existingInclusions) {
            return {
              ...existingInclusions,
              data: existingInclusions?.data.filter(item => !item.__ref.includes(destroyInclusion.data?.id))
            };
          }
        }
      });
    }
  });

  useEffect(() => {
    if (act === 'edit') {
      const id = queryString.get('id');
      findInclusionById({
        variables: {
          getByIdInclusionId: id
        }
      });
    }
  }, []);

  const handleChangeName = useCallback((e) => {
    const name = e.target.value;
    setValueName(name);
  }, []);

  const handleSubmit = () => {

    if (act === 'edit') {
      const { id } = dataFindInclusionById?.getByIdInclusion?.data;
      updateInclusion({
        variables: {
          updateInclusionId: id,
          updateInclusionName: valueName,
        }
      });
    } else {
      createInclusion({
        variables: {
          createInclusionName: valueName,
        }
      });
    }
  };

  const handleDelete = () => {
    const { id } = dataFindInclusionById?.getByIdInclusion?.data;
    deleteInclusion({
      variables: {
        destroyInclusionId: id
      }
    });
  };
  
  return (
    <Modal
      title={act === 'add' ? 'Add inclusion' : 'Edit inclusion'}
      hiddenLeftFooterButton={act === 'add'}
      leftFooterButton={{
        label: 'Delete inclusion',
        type: 'danger',
        state: 'hint',
        loading: loadingDeleteInclusion,
        onClick: handleDelete
      }}
      rightFooterButton={{
        label: 'Save changes',
        state: (
          valueName === '' ||
          loadingFindInclusionById || 
          loadingUpdateInclusion || 
          loadingCreateInclusion || 
          loadingDeleteInclusion ) ? 'disabled' : 'default',
        onClick: handleSubmit
      }}
      bodyStyle={{ textAlign: 'right' }}
      width={540}
    >
      <Card className="add-ons-inclusions-add-ons">
        <HorizontalTextInput
          label="Inclusion name"
          value={valueName}
          disabled={loadingFindInclusionById}
          onChange={handleChangeName}
        />
      </Card>
    </Modal>
  );
};

export default AddOnsInclusionsInclusions;
