import Text from 'antd/lib/typography/Text';
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import InputText from '../atoms/InputText';
import './HorizontalTextInput.less';

const HorizontalTextInput = props => {
  const { label, right, layout, ...otherProps } = props;

  return (
    <div
      className={cn('wrap-text-input', {
        'antd-horizontal-text-input': layout === 'default',
        'antd-inline-text-input': layout === 'inline',
      })}
      style={props.styleContainer}>
      {label && <Text className='label'>{label}</Text>}
      {right || <InputText className='input-text' size='large' {...otherProps} />}
    </div>
  );
};

export default HorizontalTextInput;

HorizontalTextInput.propTypes = {
  label: PropTypes.string,
  right: PropTypes.arrayOf(PropTypes.node),
  layout: PropTypes.oneOf(['default', 'inline']),
  onChange: PropTypes.func,
};

HorizontalTextInput.defaultProps = {
  onChange: null,
  layout: 'default',
};
