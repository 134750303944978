import React from 'react';

function SvgNote() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        fill="currentColor"
        d="M17.5 10a.833.833 0 00-.833.833v5a.833.833 0 01-.834.833H4.167a.833.833 0 01-.834-.833V4.166a.833.833 0 01.834-.833h5a.833.833 0 000-1.667h-5a2.5 2.5 0 00-2.5 2.5v11.667a2.5 2.5 0 002.5 2.5h11.666a2.5 2.5 0 002.5-2.5v-5A.833.833 0 0017.5 10zM5 10.633v3.534a.833.833 0 00.833.833h3.534a.833.833 0 00.591-.242l5.767-5.775 2.367-2.317a.833.833 0 000-1.183l-3.534-3.575a.834.834 0 00-1.183 0l-2.35 2.358-5.783 5.775a.833.833 0 00-.242.592zm8.967-6.958l2.358 2.358-1.183 1.183-2.359-2.358 1.184-1.183zm-7.3 7.3l4.941-4.942 2.359 2.358-4.942 4.942H6.667v-2.358z"
      ></path>
    </svg>
  );
}

export default SvgNote;
