import React, { useEffect, useState } from 'react';
import Modal from 'components/organisms/Modal';
import HorizontalTextInput from 'components/molecules/HorizontalTextInput';

const CourseProfileAddContact = props => {
  const { act, modalState, onClose, updateCourseProfile } = props;
  const [url, setUrl] = useState('');

  useEffect(() => {
    if (modalState?.value) {
      setUrl(modalState.value);
    }
  }, [modalState]);

  if (!modalState) {
    return null;
  }

  const addData = data => {
    updateCourseProfile({
      variables: {
        data: {
          [modalState.field]: data,
        },
      },
    });

    setUrl('');
  };

  return (
    <Modal
      onCancel={() => {
        onClose();
        setUrl('');
      }}
      leftFooterButton={{
        label: `Remove ${modalState.label}`,
        type: 'outline',
        state: 'hint',
        onClick: () => addData(''),
      }}
      rightFooterButton={{
        state: !url ? 'disabled' : 'default',
        label: act === 'add' ? `Add ${modalState.label}` : `Edit ${modalState.label}`,
        onClick: () => addData(url),
      }}
      title={modalState.label}
      width={540}>
      <HorizontalTextInput
        label={modalState.label}
        value={url}
        onChange={e => setUrl(e.target.value)}
      />
    </Modal>
  );
};

export default CourseProfileAddContact;
