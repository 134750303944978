import React, { createRef, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import moment from 'moment';
import { Tabs } from 'antd';
import Promise from 'bluebird';
import Pathname from '../../constants/Pathname';
import QueryGql from '../../constants/QueryGql';
import Modal from '../organisms/Modal';
import extractQuery from '../../utils/useQuery';
import TeeSheetRaincheckIssueTemplate from '../templates/TeeSheetRaincheckIssue';
import Loading from '../atoms/Loading';

const { TabPane } = Tabs;

const TeeSheetRaincheckGroupIssue = () => {
  const history = useHistory();
  const location = useLocation();
  // const refTeeSheetRaincheckGroupIssueTemplate = useRef();
  const queryString = extractQuery(location.search);
  const [elRefs, setElRefs] = useState([]);

  const id = queryString.get('id');

  const { loading: loadingGetReservation, data: dataGetReservationGroup } = useQuery(
    QueryGql.GET_RESERVATION_GROUP,
    {
      variables: {
        getReservationGroupId: id
      },
      fetchPolicy: 'network-only'
    }
  );

  const [ getReservations, { loading: loadingGetReservations } ] = useLazyQuery(
    QueryGql.GET_RESERVATIONS,
    {
      onCompleted: () => {
        history.replace(
          Pathname.TEE_SHEET
        );
      },
      fetchPolicy: 'network-only'
    }
  );

  const [ createRaincheck, { loading: loadingCreateRaincheck }] = useMutation(
    QueryGql.CREATE_RAINCHECK,
  );

  const needRainchecksGroup = useMemo(() => {
    return dataGetReservationGroup?.getReservationGroup?.data.reservations.map(reservation => {
      return {
        id: reservation.id,
        time: moment(reservation.date_tee_times, 'HH:mm:ss').format('hh:mm A'),
        customers: reservation.reservation_customers.filter(item => !item.is_raincheck).map(item => ({
          customer_id: item.customer.id,
          name: item.customer.full_name,
          amount: item.membership_price,
          status: item.status,
        }))
      };
    }) || [];
  }, [dataGetReservationGroup]);


  useEffect(() => {
    // add or remove refs
    setElRefs(elRefs => (
      Array(needRainchecksGroup.length).fill().map((_, i) => elRefs[i] || createRef())
    ));
  }, [needRainchecksGroup.length]);

  const handleSubmit = async () => {
    const raincheckReservations = [];
    for(const elRef of elRefs) {
      const values = await elRef.current?.handleSubmit();
      if (values) {
        raincheckReservations.push({
          variables: {
            data: {
              ...values,
              customers: values.customers.map(x => ({
                customer_id: x.customer_id,
                amount: x.amount,
                raincheck_code: x.raincheck_code
              }))
            }
          }
        });
      }
    }
    if (raincheckReservations.length > 0) {
      Promise.map(raincheckReservations, (raincheckReservation) => {
        // Promise.map awaits for returned promises as well.
        return createRaincheck(raincheckReservation);
      }).then(() => {
        const { course, date } = dataGetReservationGroup.getReservationGroup.data.reservations[0];
        getReservations({
          variables: {
            getReservationsData: {
              course_id: course.id,
              date: moment(date).format('YYYY-MM-DD'),
              statuses: []
            }
          }
        });
      }).catch(() => {
      });
    } else {
      history.goBack();
    }
  };

  return (
    <Modal
      title="Issue raincheck"
      leftFooterButton={{
        label: 'Cancel',
        type: 'link',
        onClick:() => history.goBack()
      }}
      rightFooterButton={{
        label: 'Issue raincheck',
        state: loadingGetReservation ? 'disabled' : 'default',
        loading: loadingCreateRaincheck || loadingGetReservations,
        onClick: handleSubmit
      }}
      width={964}
    >
      {(loadingGetReservation || !dataGetReservationGroup)
        ? <Loading />
        : (
          <Tabs defaultActiveKey="0">
            {needRainchecksGroup.map((reservation, index) => (
              <TabPane tab={reservation.time} key={index.toString()}>
                <TeeSheetRaincheckIssueTemplate
                  ref={elRefs[index]}
                  reservationId={reservation.id}
                  needRainchecks={reservation.customers}
                />
              </TabPane>
            ))}
          </Tabs>
        )}
    </Modal>
  );
};

export default TeeSheetRaincheckGroupIssue;