import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import Title from 'antd/lib/typography/Title';
import { Formik } from 'formik';
import * as Yup from 'yup';
import HorizontalColorPicker from '../molecules/HorizontalColorPicker';
import HorizontalTextInput from '../molecules/HorizontalTextInput';
import './RateCardsForm.less';

const RateCardFormValidations = Yup.object().shape({
  name: Yup.string().required(),
  color: Yup.string().required(),
});

const RateCardsForm = (props, ref) => {
  const { onSubmit, initialValues } = props;
  const refFormik = useRef();

  useImperativeHandle(ref, () => ({
    handleSubmit: () => {
      refFormik.current.handleSubmit();
      return refFormik.current.values;
    },
  }));

  return (
    <Formik
      innerRef={refFormik}
      initialValues={
        initialValues || {
          name: '',
          color: '#0025B8',
        }
      }
      validationSchema={RateCardFormValidations}
      onSubmit={values => {
        if (onSubmit) {
          onSubmit(values);
        }
      }}>
      {({ handleChange, values, errors }) => {
        return (
          <>
            <Title style={{ marginBottom: 20 }} level={4}>
              Rate card information
            </Title>
            <HorizontalTextInput
              label='Rate card name'
              value={values.name}
              errorMessage={errors.name}
              onChange={e => handleChange('name')(e.target.value)}
            />
            <div className='spacer' />
            <HorizontalColorPicker
              label='Color'
              value={values.color}
              onChange={e => handleChange('color')(e.value)}
            />
          </>
        );
      }}
    </Formik>
  );
};

export default forwardRef(RateCardsForm);
