import { Row, Col, notification, Button } from 'antd';
import { useFormik } from 'formik';
import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import InputText from '../atoms/InputText';
import SingleSelect from '../molecules/SingleSelect';
import Modal from '../organisms/Modal';
import extractQuery from '../../utils/useQuery';
import { useMutation } from '@apollo/client';
import QueryGql from '../../constants/QueryGql';

const SendReceipt = () => {
  const history = useHistory();
  const location = useLocation();
  const queryString = extractQuery(location.search);
  const id = queryString.get('id');
  const type = queryString.get('type');
  const customerId = queryString.get('customerId');
  const state = history.location.state || {};

  const [sendRaincheck, { loading: loadingRaincheck }] = useMutation(QueryGql.SEND_RAINCHECK);

  const [sendReceipt, { loading: loadingReceipt }] = useMutation(QueryGql.CREATE_RECEIPT);

  const { values, setFieldValue, submitForm, handleSubmit } = useFormik({
    initialValues: {
      method: {
        label: 'Email',
        value: 'email'
      }
    },
    onSubmit: async (values) => {
      try {
        if (type === 'raincheck') {
          await sendRaincheck({
            variables: {
              'data': {
                'email': values.email,
                'send_to': values.method.value,
                'phone_number': values.phone,
                'id': id
              }
            }
          });
        }

        if (type === 'customer-check-ins') {
          await sendReceipt({
            variables: {
              createReceiptData: {
                reservation_id: id,
                customers: [
                  {
                    id: customerId,
                    receipt_method: values.method.value,
                  }
                ]
              }
            }
          });
        }

        const key = `open${Date.now()}`;
        notification.success({
          message: `Successfully sent to ${values.method.value}`,
          btn: <Button type="text" className="notification-button-dismiss-success" onClick={() => notification.close(key)}>Dismiss</Button>,
          key,
          closeIcon: <div />,
          placement: 'bottomLeft'
        });
        history.goBack();
      } catch (error) {
        console.log(error);
      }
    }
  });

  const handleChange = (e) => {
    setFieldValue(e.target.id, e.target.value);
  };

  const isActiveButton = type === 'customer-check-ins' ? true : values.method.value == 'email' ? values.email : values.phone;
  const loading = loadingRaincheck || loadingReceipt;
  return (
    <Modal
      title={`${state.title} ${state.number || ''}`}
      rightFooterButton={{
        label: state.title,
        onClick: submitForm,
        type: 'primary',
        loading,
        state: isActiveButton ? 'default' : 'disabled'
      }}
      hiddenLeftFooterButton={true}
      width={540}
    >
      <form onSubmit={handleSubmit}>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <SingleSelect
              label="Method of sending"
              fullWidth
              type="default"
              size="large"
              defaultValue={values.method.label}
              items={[
                { label: 'Email', value: 'email' },
                { label: 'WhatsApp', value: 'whatsapp' },
              ]}
              onSelect={(item) => setFieldValue('method', item)}
            />
          </Col>
          <Col span={24}>
            { type === 'customer-check-ins' ? null :
              values.method.value === 'email' ?
                <InputText
                  label="Email address"
                  size="large"
                  id="email"
                  value={values.email}
                  onChange={handleChange}
                />
                :
                <InputText
                  label="Phone number"
                  size="large"
                  id="phone"
                  placeholder="62"
                  value={values.phone}
                  onChange={handleChange}
                />
            }
          </Col>
        </Row>
      </form>
    </Modal>
  );
};

export default SendReceipt;