import React from 'react';

function SvgEmptyCustomerList() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="408"
      height="172"
      fill="none"
      viewBox="0 0 408 172"
    >
      <g clipPath="url(#clip0)">
        <path
          fill="#62F8B0"
          d="M204 171.665c112.666 0 204-3.26 204-7.28 0-4.02-91.334-7.28-204-7.28s-204 3.26-204 7.28c0 4.02 91.334 7.28 204 7.28z"
        ></path>
        <path
          fill="#3F3D56"
          d="M363.11 123.679a8.582 8.582 0 001.133-4.347c0-3.928-2.426-7.113-5.435-7.113-3.009 0-5.432 3.185-5.432 7.113a8.573 8.573 0 001.134 4.347 8.894 8.894 0 000 8.694 8.884 8.884 0 000 8.69 8.894 8.894 0 000 8.694 8.57 8.57 0 00-1.134 4.347c0 3.927 2.427 7.113 5.432 7.113 3.005 0 5.435-3.186 5.435-7.113a8.58 8.58 0 00-1.133-4.347 8.904 8.904 0 000-8.694 8.899 8.899 0 000-8.69 8.907 8.907 0 000-8.694z"
        ></path>
        <path
          fill="#3F3D56"
          d="M358.794 117.752c3 0 5.432-3.185 5.432-7.113 0-3.929-2.432-7.114-5.432-7.114-3 0-5.432 3.185-5.432 7.114 0 3.928 2.432 7.113 5.432 7.113z"
        ></path>
        <path
          fill="#3F3D56"
          d="M358.794 109.058c3 0 5.432-3.184 5.432-7.113 0-3.928-2.432-7.113-5.432-7.113-3 0-5.432 3.185-5.432 7.113 0 3.929 2.432 7.113 5.432 7.113z"
        ></path>
        <path
          fill="#0AD674"
          d="M338.456 42.454a26.127 26.127 0 01-2.024-2.978l14.278-2.343-15.443.114a26.068 26.068 0 01-.496-20.621l20.716 10.746-19.104-14.043A26.031 26.031 0 01363.703.76a26.025 26.025 0 0115.664 41.688 25.691 25.691 0 012.967 4.75l-18.531 9.632 19.758-6.63a26.04 26.04 0 01-4.197 24.438 26.03 26.03 0 012.96 27.463 26.03 26.03 0 01-37.224 10.691 26.03 26.03 0 01-12.065-24.848 26.031 26.031 0 015.425-13.306 26.024 26.024 0 010-32.183h-.004z"
        ></path>
        <path
          fill="#000D23"
          d="M384.948 58.545a25.914 25.914 0 01-5.57 16.091 26.03 26.03 0 012.96 27.464 26.03 26.03 0 01-23.412 14.658 26.026 26.026 0 01-20.452-42.122c-3.505-4.426 46.474-19.01 46.474-16.091z"
          opacity="0.1"
        ></path>
        <path
          fill="#F2F2F2"
          d="M341.472 2.762H66.528a7.079 7.079 0 00-7.079 7.078v143.913a7.078 7.078 0 007.079 7.078h274.944a7.079 7.079 0 007.079-7.078V9.84a7.08 7.08 0 00-7.079-7.078z"
        ></path>
        <path
          fill="#EBEEFF"
          d="M59.623 10.563h92.9v150.443H66.701a7.08 7.08 0 01-7.078-7.078V10.563z"
        ></path>
        <path
          fill="#3F3D56"
          d="M348.551 9.84v4.018H59.449V9.841a7.074 7.074 0 017.079-7.075h274.944a7.08 7.08 0 016.54 4.366 7.09 7.09 0 01.539 2.709z"
        ></path>
        <path
          fill="#fff"
          d="M66.902 10.562a2.08 2.08 0 100-4.16 2.08 2.08 0 000 4.16zM72.102 10.562a2.08 2.08 0 100-4.16 2.08 2.08 0 000 4.16zM77.302 10.562a2.08 2.08 0 100-4.16 2.08 2.08 0 000 4.16z"
        ></path>
        <path
          fill="#1F4BFF"
          d="M331.482 32.615H212.056a.43.43 0 00-.43.43v2.953c0 .238.193.43.43.43h119.426a.43.43 0 00.43-.43v-2.953a.43.43 0 00-.43-.43z"
          opacity="0.3"
        ></path>
        <path
          fill="#1F4BFF"
          d="M329.749 39.895h-24.446a.43.43 0 00-.43.43v7.806c0 .237.193.43.43.43h24.446a.43.43 0 00.429-.43v-7.807a.43.43 0 00-.429-.43z"
        ></path>
        <path
          fill="#1F4BFF"
          d="M331.482 78.982H212.056a.43.43 0 00-.43.43v2.954c0 .237.193.43.43.43h119.426a.43.43 0 00.43-.43v-2.954a.43.43 0 00-.43-.43z"
          opacity="0.3"
        ></path>
        <path
          fill="#1F4BFF"
          d="M329.749 86.262h-24.446a.43.43 0 00-.43.43v7.806c0 .237.193.43.43.43h24.446a.43.43 0 00.429-.43v-7.806a.43.43 0 00-.429-.43z"
        ></path>
        <path
          fill="#1F4BFF"
          d="M331.482 126.127H212.056a.43.43 0 00-.43.43v2.953c0 .238.193.43.43.43h119.426a.43.43 0 00.43-.43v-2.953a.43.43 0 00-.43-.43z"
          opacity="0.3"
        ></path>
        <path
          fill="#1F4BFF"
          d="M329.749 133.406h-24.446a.43.43 0 00-.43.43v7.807a.43.43 0 00.43.429h24.446a.429.429 0 00.429-.429v-7.807a.43.43 0 00-.429-.43z"
        ></path>
        <path
          fill="#DA85FF"
          d="M185.52 104.121c9.481 0 17.166-7.686 17.166-17.166s-7.685-17.166-17.166-17.166c-9.48 0-17.165 7.686-17.165 17.166s7.685 17.166 17.165 17.166z"
        ></path>
        <path
          fill="#192534"
          d="M200.669 96.01a.48.48 0 01-.073.115c-.187.31-.389.609-.607.898a17.099 17.099 0 01-7.539 5.875c-.298.125-.603.24-.912.347a6.096 6.096 0 01-.79-.759 5.93 5.93 0 01-1.387-2.874 7.28 7.28 0 01-1.386.132.921.921 0 01-.139 0c-3.57-.062-6.635-2.676-8.004-6.406l-.055-.16a12.96 12.96 0 01-.271-7.535c.018-.084.045-.167.069-.247 1.172-4.024 4.264-6.957 7.942-7.189h.794c4.659.17 8.41 4.676 8.583 10.313a5.985 5.985 0 012.638 5.865 6.12 6.12 0 011.137 1.626z"
        ></path>
        <path
          fill="#000D23"
          d="M200.596 96.125c-.187.309-.39.609-.607.897a5.664 5.664 0 00-.925-1.23 5.987 5.987 0 00-2.638-5.865c-.191-5.755-4.105-10.351-8.899-10.351-3.425 0-6.395 2.336-7.889 5.768-.038.09-.073.177-.115.27-.125.298-.235.6-.346.915a12.67 12.67 0 01.509-1.864l.049-.049c1.334-3.678 4.315-6.288 7.817-6.444h.793c4.593.277 8.271 4.763 8.448 10.34a5.973 5.973 0 012.645 5.865c.487.51.879 1.1 1.158 1.748z"
          opacity="0.1"
        ></path>
        <path
          fill="#A0616A"
          d="M182.508 91.202c.043.35.003.707-.118 1.04-.204.474-.693.9-.558 1.386a1.07 1.07 0 00.749.603c1.494.506 3.12.205 4.7.274a15.19 15.19 0 001.585.056 2.521 2.521 0 001.469-.514 12.59 12.59 0 01-2.312-1.452 2.608 2.608 0 01-.468-.447 2.739 2.739 0 01-.405-1.588v-1.65c-1.13 0-2.257-.017-3.387-.017-.347 0-1.466-.087-1.595.305-.062.194.181.766.219.984.058.338.099.678.121 1.02z"
        ></path>
        <path
          fill="#000D23"
          d="M182.508 91.202c.043.35.003.707-.118 1.04-.204.474-.693.9-.558 1.386a1.07 1.07 0 00.749.603c1.494.506 3.12.205 4.7.274a15.19 15.19 0 001.585.056 2.521 2.521 0 001.469-.514 12.59 12.59 0 01-2.312-1.452 2.608 2.608 0 01-.468-.447 2.739 2.739 0 01-.405-1.588v-1.65c-1.13 0-2.257-.017-3.387-.017-.347 0-1.466-.087-1.595.305-.062.194.181.766.219.984.058.338.099.678.121 1.02z"
          opacity="0.1"
        ></path>
        <path
          fill="#A0616A"
          d="M184.83 90.702a3.983 3.983 0 100-7.965 3.983 3.983 0 000 7.965z"
        ></path>
        <path
          fill="#1F4BFF"
          d="M192.384 98.273c0 .107-.021.222-.038.346a10.638 10.638 0 01-.565 1.89 9.288 9.288 0 01-.527 1.206c-.149.267-.347.513-.506.773l-.229.371c-.146.25-.27.512-.371.783-.669.172-1.349.295-2.035.371-.671.081-1.345.121-2.021.122-3.622 0-7.529-.94-10.299-2.86 0 0 .406-.281.347-.347 0-.159-.017-.315-.017-.475-.004-.41.062-.818.197-1.206.118-.372.324-.71.6-.985.347-.346.811-.561 1.182-.894a3.508 3.508 0 00.967-1.681c.087-.278.149-.572.208-.856.053-.39.16-.768.319-1.127.022-.046.048-.09.076-.132a1.39 1.39 0 01.187-.246 4.055 4.055 0 011.221-.818c.377-.194.908-.346 1.171-.024v.024c.087.198.159.402.219.61.211.437.8.493 1.279.482a40.547 40.547 0 005.054-.423l.811-.132c.586-.097.995.416 1.324.916.853 1.286 1.574 2.766 1.446 4.312z"
        ></path>
        <path
          stroke="#000D23"
          strokeMiterlimit="10"
          d="M180.22 98.543c-.37.083-.585.457-.759.797a3.081 3.081 0 00-.412 1.851c-.01.254.017.507.079.753.064.247.221.46.437.596"
          opacity="0.1"
        ></path>
        <path
          fill="#192534"
          d="M190.165 84.962c.301-1.176-1.388-2.623-3.771-3.233-2.384-.609-4.559-.15-4.86 1.026-.301 1.177 1.388 2.624 3.771 3.233 2.384.61 4.56.15 4.86-1.026z"
        ></path>
        <path
          fill="#000D23"
          d="M185.527 85.153c-2.118-.54-3.685-1.733-3.813-2.829a1.119 1.119 0 00-.208.42c-.301 1.175 1.387 2.62 3.768 3.23 2.382.61 4.559.15 4.853-1.04.034-.13.043-.267.028-.402-.634.874-2.551 1.151-4.628.62z"
          opacity="0.1"
        ></path>
        <path
          fill="#A0616A"
          d="M192.488 101.099c0 .603 0 1.203-.038 1.799-.298.125-.603.239-.912.347-.454.158-.917.29-1.386.395-.669.171-1.349.295-2.035.371-.121-1.435-.395-2.818-.26-4.274a6.884 6.884 0 011.116-3.12 2.666 2.666 0 011.009-.974 1.241 1.241 0 011.327.104c.236.246.402.552.479.884.234.65.421 1.316.561 1.993 0 .073.025.153.035.229.096.745.131 1.496.104 2.246z"
        ></path>
        <path
          fill="#FF8589"
          d="M202.683 134.099a17.088 17.088 0 01-2.08 8.219c-.152.277-.312.558-.492.835a.477.477 0 01-.073.115c-.187.309-.389.609-.607.898a16.958 16.958 0 01-4.905 4.547l-.1.07c-.631.379-1.285.718-1.959 1.015-.187.084-.381.163-.575.243-.194.08-.374.153-.569.215-.097.042-.201.076-.301.111a.103.103 0 00-.035 0c-.211.076-.43.142-.645.208l-.162.038a7.023 7.023 0 01-.583.149c-.67.172-1.351.296-2.038.371a16.62 16.62 0 01-2.007.121h-.208a15.54 15.54 0 01-2.773-.256l-.167-.028a5.954 5.954 0 01-.509-.104l-.323-.073a10.162 10.162 0 01-1.012-.277 16.492 16.492 0 01-1.986-.738l-.073-.031a17.051 17.051 0 01-2.7-1.536 1.86 1.86 0 01-.208-.142 17.164 17.164 0 012.103-29.232 17.17 17.17 0 0122.825 6.855 17.176 17.176 0 012.197 8.404l-.035.003z"
        ></path>
        <path
          fill="#192534"
          d="M189.673 129.963a3.643 3.643 0 01-7.279 0v-.173a3.64 3.64 0 117.279 0c-.003.056 0 .115 0 .173z"
        ></path>
        <path
          fill="#000D23"
          d="M189.673 129.963a3.643 3.643 0 01-7.279 0v-.173a5.744 5.744 0 017.279 0c-.003.055 0 .114 0 .173z"
          opacity="0.1"
        ></path>
        <path
          fill="#192534"
          d="M186.03 140.314a5.751 5.751 0 100-11.502 5.751 5.751 0 000 11.502z"
        ></path>
        <path
          fill="#192534"
          d="M182.546 129.159a3.648 3.648 0 01.903-2.398 3.637 3.637 0 012.258-1.21 3.626 3.626 0 00-1.536.121 3.629 3.629 0 00-2.269 1.982 3.643 3.643 0 003.805 5.117 3.64 3.64 0 01-3.161-3.612zM179.541 136.9a3.645 3.645 0 01-.614-4.759 4.035 4.035 0 00-.346.346 3.658 3.658 0 00-.965 2.659 3.649 3.649 0 001.229 2.548 3.638 3.638 0 005.199-.388c.106-.121.204-.249.295-.382a3.642 3.642 0 01-4.798-.024z"
        ></path>
        <path
          fill="#FFC1C7"
          d="M183.021 141.857a3.194 3.194 0 00-.859.302c-.479.346-.461 1.04-.319 1.605.326 1.289 1.123 2.527 2.343 3.054a5.802 5.802 0 002.364.346c.87 0 1.855-.118 2.395-.804.244-.362.41-.77.489-1.199.267-1.04.538-2.115.44-3.189-.526.045-1.116.076-1.532-.257-.416-.333-.509-.967-.693-1.494a.39.39 0 00-.135-.215.45.45 0 00-.243-.035 9.766 9.766 0 01-1.657-.069c-.346-.045-1.185-.402-1.442-.1-.187.218-.069.883-.215 1.192a1.636 1.636 0 01-.936.863z"
        ></path>
        <path
          fill="#000D23"
          d="M183.021 141.857a3.194 3.194 0 00-.859.302c-.479.346-.461 1.04-.319 1.605.326 1.289 1.123 2.527 2.343 3.054a5.802 5.802 0 002.364.346c.87 0 1.855-.118 2.395-.804.244-.362.41-.77.489-1.199.267-1.04.538-2.115.44-3.189-.526.045-1.116.076-1.532-.257-.416-.333-.509-.967-.693-1.494a.39.39 0 00-.135-.215.45.45 0 00-.243-.035 9.766 9.766 0 01-1.657-.069c-.346-.045-1.185-.402-1.442-.1-.187.218-.069.883-.215 1.192a1.636 1.636 0 01-.936.863z"
          opacity="0.05"
        ></path>
        <path
          fill="#FFC1C7"
          d="M185.95 141.36a4.02 4.02 0 100-8.041 4.02 4.02 0 000 8.041z"
        ></path>
        <path
          fill="#1F4BFF"
          d="M194.765 146.052c-.027.034-.055.076-.086.111l-.17.222a13.058 13.058 0 00-2.056 3.414c-.187.083-.381.163-.575.243a8.052 8.052 0 01-.569.215c-.097.041-.201.076-.301.11a.205.205 0 00-.035 0c-.211.077-.43.143-.645.208l-.162.039a7.443 7.443 0 01-.583.149 14.84 14.84 0 01-2.038.371c-.666.08-1.336.121-2.007.121h-.208a15.635 15.635 0 01-2.773-.257l-.167-.027a6.366 6.366 0 01-.509-.104l-.323-.073a10.44 10.44 0 01-1.012-.277 3.056 3.056 0 01.745-1.56c-.78-.933-2.062-1.3-2.891-2.181a2.726 2.726 0 01-.201-.263 1.11 1.11 0 01-.107-.212.578.578 0 01-.045-.183 1.586 1.586 0 01-.031-.389c-.021-.984.024-2.107.787-2.724a3.58 3.58 0 011.241-.555 15.242 15.242 0 002.253-.908c.066.155.11.318.131.485.07.402.063.825.156 1.224a2.955 2.955 0 001.234 1.733c.611.4 1.307.653 2.032.738.714.104 1.282-.266 1.955-.519.298-.096.573-.251.808-.458.303-.351.465-.802.454-1.265 0-.458-.052-.915-.025-1.387a.682.682 0 01.018-.142.574.574 0 01.395-.544c.13-.011.26.013.378.069l1.296.496a8.99 8.99 0 011.616.745 4.472 4.472 0 012.02 3.335z"
        ></path>
        <path
          fill="#000D23"
          d="M182.539 151.001l-.166-.028a5.613 5.613 0 01-.51-.104l-.322-.072c-.342-.075-.68-.168-1.012-.278a16.262 16.262 0 01-1.986-.738 8.572 8.572 0 01-.347-3.266c.042-.472.14-.937.291-1.386.728.693 1.969.301 2.794.856.224.158.407.368.534.61.231.444.388.923.464 1.418.052.27.101.544.142.818v.26c.028.194.052.395.063.593.055.447.055.877.055 1.317z"
          opacity="0.1"
        ></path>
        <path
          fill="#1F4BFF"
          d="M182.373 150.973a6.39 6.39 0 01-.51-.104l-.322-.073c-.342-.075-.68-.167-1.012-.277a16.343 16.343 0 01-1.987-.739l-.072-.031a8.853 8.853 0 01-.441-3.629c.05-.443.145-.88.285-1.304.728.694 1.969.309 2.794.864.317.228.559.547.693.915.135.356.236.724.301 1.099.077.405.15.811.198 1.223a.916.916 0 01.017.174v.273c.066.531.063 1.072.056 1.609z"
        ></path>
        <path
          fill="#000D23"
          d="M194.412 148.783a17.72 17.72 0 01-2.534 1.258c-.194.08-.374.153-.569.215-.097.042-.201.076-.301.111a.205.205 0 00-.035 0c-.211.076-.43.142-.645.208l-.163.038c0-.308 0-.627.035-.939.024-.246.052-.492.087-.739 0-.045 0-.09.017-.135a.31.31 0 000-.086c.042-.236.087-.475.132-.711.072-.496.229-.975.464-1.418.13-.238.314-.442.538-.596.825-.555 2.08-.177 2.773-.856.136.403.23.818.281 1.241a8.077 8.077 0 01-.08 2.409z"
          opacity="0.1"
        ></path>
        <path
          fill="#1F4BFF"
          d="M194.512 148.713l-.1.07a17.63 17.63 0 01-2.534 1.258c-.194.08-.375.152-.569.215-.097.041-.201.076-.301.111a.103.103 0 00-.035 0c-.212.076-.43.142-.645.208 0-.409 0-.815.035-1.22 0-.104 0-.212.028-.316v-.142-.107c.052-.375.114-.739.19-1.106.066-.375.167-.743.302-1.099.134-.368.375-.687.693-.915.825-.555 2.08-.177 2.773-.863.149.435.247.887.291 1.345.094.855.05 1.72-.128 2.561z"
        ></path>
        <path
          fill="#192534"
          d="M185.871 136.776c2.665 0 4.825-1.441 4.825-3.217 0-1.777-2.16-3.217-4.825-3.217s-4.826 1.44-4.826 3.217c0 1.776 2.161 3.217 4.826 3.217z"
        ></path>
        <path
          fill="#000D23"
          d="M181.86 150.384v.458a.066.066 0 000 .028l-.323-.073a1.13 1.13 0 010-.184 2.248 2.248 0 01.773-1.695.499.499 0 01.108-.086c.186-.152.397-.269.624-.347a1.859 1.859 0 01.741-.125 1.55 1.55 0 01.167 0h-.115a2.209 2.209 0 00-1.386.693.751.751 0 00-.108.125 2.215 2.215 0 00-.481 1.206z"
          opacity="0.05"
        ></path>
        <path
          fill="#1F4BFF"
          d="M185.388 150.614c0 .217-.025.433-.076.644a15.54 15.54 0 01-2.773-.256l-.166-.028a5.977 5.977 0 01-.51-.104v-.028-.228-.229c.029-.465.188-.913.458-1.293a.802.802 0 01.107-.132 1.59 1.59 0 01.607-.464 1.35 1.35 0 01.582-.132.2.2 0 01.069 0 .854.854 0 01.143 0c.877.149 1.559 1.099 1.559 2.25z"
        ></path>
        <path
          fill="#000D23"
          d="M191.31 150.257c-.098.041-.201.076-.302.111a2.192 2.192 0 00-.617-1.318l-.104-.104a2.286 2.286 0 00-1.241-.565h-.114c.053-.003.106-.003.159 0a2.067 2.067 0 01.749.125c.167.057.326.134.475.229.038.02.073.045.104.076a2.21 2.21 0 01.891 1.446z"
          opacity="0.05"
        ></path>
        <path
          fill="#1F4BFF"
          d="M191.008 150.367a.103.103 0 00-.035 0c-.212.076-.43.142-.645.208l-.163.038a7.308 7.308 0 01-.582.149 14.84 14.84 0 01-2.038.371 2.563 2.563 0 01-.052-.531c0-1.151.693-2.1 1.563-2.232a.807.807 0 01.139 0 .178.178 0 01.065 0c.204-.001.406.044.59.132.172.076.33.183.464.315a.683.683 0 01.104.097c.348.408.555.918.59 1.453z"
        ></path>
        <path
          fill="#000D23"
          d="M185.871 136.456c-2.586 0-4.697-1.352-4.822-3.054v.16c0 1.778 2.163 3.217 4.829 3.217 2.665 0 4.825-1.439 4.825-3.217v-.16c-.139 1.702-2.25 3.054-4.832 3.054z"
          opacity="0.1"
        ></path>
        <path
          fill="#FFB185"
          d="M168.355 40.591a17.13 17.13 0 002.079 8.216c.156.28.316.561.493.835.02.042.045.08.076.114.191.307.398.603.62.888a16.504 16.504 0 002.475 2.735c.172.165.353.32.541.465a15.39 15.39 0 001.886 1.348l.104.07c.631.377 1.284.718 1.955 1.018l.579.24c.191.08.371.156.568.218.098.038.198.073.302.107h.049c.21.077.425.146.644.208l.16.039c.194.059.391.11.586.149.263.069.523.128.793.176a16.31 16.31 0 003.248.316h.205c.93-.006 1.858-.093 2.773-.26.056-.006.112-.015.167-.028.169-.028.346-.062.509-.104.111-.02.212-.045.322-.073a9.813 9.813 0 001.013-.273c.204-.066.405-.129.606-.198.406-.142.811-.295 1.2-.468.06-.02.119-.044.177-.073l.076-.035a15.751 15.751 0 001.497-.773c.413-.235.815-.492 1.203-.762h.024c.064-.037.124-.079.181-.125a17.157 17.157 0 007.17-15.207 17.169 17.169 0 00-9.258-14.034 17.164 17.164 0 00-25.023 15.271z"
        ></path>
        <path
          fill="#1F4BFF"
          d="M189.33 47.392a1.6 1.6 0 01.503.85c-.5.13-1.009-.115-1.474-.347a18.786 18.786 0 00-4.53-1.46.691.691 0 01-.347-.132.585.585 0 01.021-.727c.173-.18.499-.157.735-.167a6.699 6.699 0 015.092 1.97v.013z"
        ></path>
        <path
          fill="#FF8589"
          d="M184.259 46.014a2.072 2.072 0 01-1.148 1.154c-.281.1-.582.118-.866.198a.96.96 0 00-.673.53 1.13 1.13 0 00-.052.375c-.045 1.196-.08 2.426.399 3.535.412.95 1.168 1.699 1.917 2.41.873.835 1.799 1.68 2.95 2.055.672.219 1.466.243 2.024-.19.219-.19.409-.411.565-.656.389-.51.715-1.065.971-1.653.474-1.22.315-2.586.149-3.886a.31.31 0 00-.084-.208.324.324 0 00-.242-.042 1.428 1.428 0 01-1.446-.832 2.183 2.183 0 01-.079-.762l.024-1.484c.011-.159-.007-.319-.056-.471a1.184 1.184 0 00-.62-.538 15.322 15.322 0 00-2.524-.994c-.381-.111-.672-.298-.842.11-.17.41-.17.92-.367 1.349z"
        ></path>
        <path
          fill="#000D23"
          d="M184.259 46.014a2.072 2.072 0 01-1.148 1.154c-.281.1-.582.118-.866.198a.96.96 0 00-.673.53 1.13 1.13 0 00-.052.375c-.045 1.196-.08 2.426.399 3.535.412.95 1.168 1.699 1.917 2.41.873.835 1.799 1.68 2.95 2.055.672.219 1.466.243 2.024-.19.219-.19.409-.411.565-.656.389-.51.715-1.065.971-1.653.474-1.22.315-2.586.149-3.886a.31.31 0 00-.084-.208.324.324 0 00-.242-.042 1.428 1.428 0 01-1.446-.832 2.183 2.183 0 01-.079-.762l.024-1.484c.011-.159-.007-.319-.056-.471a1.184 1.184 0 00-.62-.538 15.322 15.322 0 00-2.524-.994c-.381-.111-.672-.298-.842.11-.17.41-.17.92-.367 1.349z"
          opacity="0.1"
        ></path>
        <path
          fill="#FF8589"
          d="M188.273 47.217a4.527 4.527 0 100-9.055 4.527 4.527 0 000 9.055z"
        ></path>
        <path
          fill="#F2F2F2"
          d="M182.259 57.438a16.679 16.679 0 003.248.316h.204c.93-.006 1.858-.093 2.773-.26.056-.006.112-.015.167-.028.169-.028.346-.062.509-.104a5.43 5.43 0 00.323-.073 9.862 9.862 0 001.012-.273c.204-.066.405-.129.606-.198.406-.142.812-.295 1.2-.468a8.46 8.46 0 01-.246-.94 10.37 10.37 0 01-.132-2.051c0-.552.024-1.1 0-1.647a4.044 4.044 0 00-.191-1.31 1.665 1.665 0 00-.846-.981c-.516-.23-1.292-.347-1.802-.09a.865.865 0 00-.416.454c-.166.416-.347.835-.506 1.251-.103.29-.239.567-.406.825a2.04 2.04 0 01-2.242.738 1.372 1.372 0 01-.15-.058c-.693-.281-1.327-.888-2.052-.725a1.36 1.36 0 00-.904.783 3.524 3.524 0 00-.274 1.2 15.135 15.135 0 00.125 3.64z"
        ></path>
        <path
          fill="#1F4BFF"
          d="M174.098 53.39c.172.164.353.319.541.464a15.39 15.39 0 001.886 1.348l.104.07c.631.378 1.284.718 1.955 1.019l.579.239c.191.08.371.156.568.218.097.038.198.073.302.108h.048c.211.076.426.145.645.208l.16.038c.194.059.391.11.586.149.263.07.523.128.793.177a16.31 16.31 0 003.248.315h.205c.93-.006 1.858-.093 2.773-.26a1.24 1.24 0 00.166-.027c.17-.028.347-.063.51-.105.111-.02.211-.044.322-.072a9.813 9.813 0 001.013-.274c.204-.066.405-.128.606-.198.406-.142.811-.294 1.2-.468.06-.02.119-.044.176-.072l.077-.035a15.751 15.751 0 001.497-.773c.341-.908.63-1.834.867-2.773a.257.257 0 010-.056c-.541-.96-1.019-1.958-1.557-2.919a2.225 2.225 0 00-1.785-1.344 4.36 4.36 0 00-1.161-.215 4.378 4.378 0 01-.666-.115c-.45-.1-.949-.197-1.116 0a.058.058 0 00-.017.02.95.95 0 00.073.718c.076.219.176.448.228.635.031.09.049.183.052.277.013.406-.001.813-.041 1.217-.097.59-.423 1.11-.576 1.681-.069.27-.104.551-.149.825a8.852 8.852 0 01-.482 1.81c-.04.124-.099.24-.176.346-.285.347-.756.215-1.203.15-.326-.278-.309-.77-.347-1.186a6.226 6.226 0 00-.631-2.004l-.118-.25c-.346-.717-.693-1.431-1.019-2.152-.419-.894-.852-1.917-.506-2.843.101-.277.264-.523.375-.79a1.164 1.164 0 00.097-.437.695.695 0 00-.16-.537.273.273 0 00-.069-.073.823.823 0 00-.797-.062c-.137.05-.27.109-.399.177-.681.383-1.337.808-1.966 1.272a.987.987 0 00-.409.475 3.462 3.462 0 01-.065.392c-.098.246-.392.318-.645.374a7.888 7.888 0 00-5.033 3.698 4.03 4.03 0 00-.43.954c-.062.218-.105.44-.129.665z"
        ></path>
        <path
          fill="#192534"
          d="M190.266 40.134c-.613.628-1.594.652-2.471.638l.027-.347-1.23.44c.32-.236.593-.53.807-.866a.548.548 0 00-.599-.038 1.24 1.24 0 00-.447.444 4.042 4.042 0 00-.448 3.598.794.794 0 01-.793-.052 1.39 1.39 0 01-.51-.645c-.073-.166-.149-.347-.322-.412a.347.347 0 00-.389.173.74.74 0 00-.083.44c.024.347.284.693.347 1.04.062.347.575.624.305.856-.317.12-.648.197-.985.233a.434.434 0 01-.284-.281c-.274-.603-.177-1.307-.346-1.948a4.785 4.785 0 00-.715-1.418 1.929 1.929 0 00-.27-.364.907.907 0 01-.263-.302c-.045-.125.041-.308.173-.284a5.445 5.445 0 00-.693-.569c.242-.305.478-.617.693-.928.08-.111.163-.247.104-.368a.363.363 0 00-.139-.135l-.613-.416c0-.291.579-.184.669-.458a.38.38 0 000-.221l-.215-.784.818.371c.094.054.203.08.312.073.215-.031.319-.285.347-.503-.023-.218.006-.439.083-.645h1.109a.725.725 0 00-.135-.523l2.731-.153c.465-.053.935-.02 1.387.097.144.069.302.104.461.104.156 0 .315-.163.27-.315.41.231.785.52 1.113.856.088.106.195.195.316.26a.344.344 0 00.384-.045c.077-.08.111-.222.222-.229.111-.007.125.056.167.108.156.2.283.422.377.658.197-.026.389-.078.572-.152a.622.622 0 00-.104.693c.091.233.257.43.368.652.109.24.183.494.218.756.056.325.056.738-.232.897a.777.777 0 01-.797-.18c-.662-.471-.784-.333-1.297.194z"
        ></path>
        <path
          fill="#1F4BFF"
          d="M174.639 53.855c.594.496 1.224.947 1.886 1.349l.104.069a18.65 18.65 0 001.955 1.019l.579.24c.19.079.371.155.568.218.097.038.198.072.302.107-.122-.347-.201-.693-.309-1.04a5.871 5.871 0 00-1.487-2.447c-.596-.596-1.546-1.467-2.426-.94-.269.181-.503.408-.694.67a3.077 3.077 0 00-.429.634c-.018.03-.032.072-.049.121zM191.122 56.814c.406-.142.811-.295 1.2-.468.06-.02.119-.044.176-.073l.077-.034a15.751 15.751 0 001.497-.773c.413-.236.815-.493 1.203-.763h.024a7.261 7.261 0 010-1.161.91.91 0 00-.024-.389.924.924 0 00-.194-.266 1.643 1.643 0 01-.142-.163c-.392-.44-.711-.915-1.387-.846-.676.07-1.258.652-1.542 1.3a1.225 1.225 0 00-.077.194 13.53 13.53 0 00-.45 1.84c-.09.385-.205.756-.288 1.141-.021.142-.052.302-.073.461z"
        ></path>
        <path
          fill="url(#paint0_linear)"
          d="M112.982 158.947l-.153.083.153-.083z"
        ></path>
        <path
          fill="#575A89"
          d="M91.688 140.224c-1.338 3.855.225 10.112.95 12.902.48 2.019.797 4.073.95 6.143 1.63.277 3.298.239 4.915-.111l-1.172-5.751c.835-3.408 0-12.511 0-12.511s1.896-3.518 2.08-8.881c.163-5.275 1.463-6.981 1.504-7.036 2.68 1.386 6.087 16.365 6.087 16.365-.281 3.629.056 9.213 1.172 12.229.677 1.916.962 3.948.839 5.976a1.835 1.835 0 001.518.292c1.595-.257 3.508-1.633 3.508-1.633s-.613-16.292-1.061-16.92c-.447-.627 0-4.132 0-4.132l-.728-5.699s.111-9.661-.949-11.335c-.916-1.449-1.127-3.99-1.169-4.659v-.163l-1.615-1.937s-15.637-1.172-16.812 0c-.288.287-.465.942-.572 1.767-.322 2.534.048 6.666.048 6.666s.468 3.072 1.14 6.198a13.613 13.613 0 010 5.699 17.037 17.037 0 01-.633 6.531z"
        ></path>
        <path
          fill="#CBCEDA"
          d="M92.651 164.715c.693.922 4.805.53 5.501.243.427-.174.395-.978.281-1.803-.073-.527-.18-1.064-.225-1.456-.111-1.005.346-3.043.346-3.043s-.922.977-1.144 0a1.071 1.071 0 00-.797-.756 2.08 2.08 0 00-1.449.09c-.728.312-1.938.985-1.733 1.893.305 1.282-.784 3.12-.784 3.12a1.385 1.385 0 000 1.733l.004-.021z"
        ></path>
        <path
          fill="#000D23"
          d="M92.65 164.714c.694.922 4.805.531 5.502.243.426-.173.395-.978.28-1.803-.44.205-1.192.378-2.426.26a7.335 7.335 0 00-.877-.027 20.1 20.1 0 01-2.665-.052 1.329 1.329 0 00.187 1.379zM106.978 141.344c-.281 3.629.055 9.214 1.171 12.23.677 1.915.963 3.948.839 5.976a1.848 1.848 0 001.518.291c-.346-2.354-1.771-12.504-2.693-20.521a33.59 33.59 0 00-5.945-15.416l-.977 1.075c2.679 1.397 6.087 16.365 6.087 16.365z"
          opacity="0.1"
        ></path>
        <path
          fill="#FF8589"
          d="M94.329 82.646l.693 3.352 3.342 5.613 4.118-1.758s5.598-5.865 2.08-6.704a3.037 3.037 0 01-1.168-.52 2.428 2.428 0 01-.912-2.02 6.184 6.184 0 01.572-2.403l-7.207-2.257c.295.947.469 1.928.517 2.919.055 1.962-.676 2.93-1.272 3.39-.226.179-.486.31-.763.388z"
        ></path>
        <path
          fill="#000D23"
          d="M95.847 75.95c.295.946.468 1.927.517 2.918.13.157.27.305.419.444a5.668 5.668 0 005.678 1.296 6.184 6.184 0 01.572-2.402l-7.186-2.257z"
          opacity="0.1"
        ></path>
        <path
          fill="#FF8589"
          d="M95.008 75.023a5.699 5.699 0 105.692-5.695 5.668 5.668 0 00-5.692 5.695z"
        ></path>
        <path
          fill="#000D23"
          d="M94.329 82.646l.693 3.352 3.342 5.612 4.118-1.758s5.598-5.865 2.08-6.704a3.036 3.036 0 01-1.168-.52c.634 1.734-1.986 5.38-1.986 5.38-.306.447-1.006.167-1.006.167-1.691-.257-4.8-5.113-5.307-5.921-.227.18-.487.313-.766.392zM91.101 115.121c.992.225 6.48 1.442 8.559 1.137 2.291-.347 6.742-.558 6.742-.558s2.836.152 3.706-.247v-.163l-1.616-1.937s-15.637-1.172-16.812 0c-.294.298-.471.953-.579 1.768z"
          opacity="0.1"
        ></path>
        <path
          fill="#3F3D56"
          d="M101.393 88.176s2.947-4.08 1.834-5.67l4.978 2.04s2.995-.363 4.076 4.109c.174.743.237 1.508.188 2.27a6.74 6.74 0 00.818 3.394c1.23 2.457.95 8.097-1.564 8.666-2.513.568-3.407.277-3.407.277s.346 10.219 1.789 11.394c1.442 1.175-3.703.895-3.703.895s-4.451.222-6.742.558c-2.291.336-8.711-1.175-8.711-1.175s.95-4.299 1.282-7.037c.333-2.739 1.12-8.562 1.12-8.562l-5.699-7.717s-1.005-4.662 2.513-6.728c3.519-2.066 4.854-2.576 4.854-2.576s3.535 5.758 5.379 6.039c-.01-.007.69.274.995-.177z"
        ></path>
        <path
          fill="#000D23"
          d="M107.928 89.795s.055 1.844-.281 2.572-1.563 4.746-1.563 4.746h2.79s-1.508-1.62-1.386-2.403c.121-.783.44-4.915.44-4.915z"
          opacity="0.1"
        ></path>
        <path
          fill="#FF8589"
          d="M99.66 103.702s6.167-1.508 6.742-2.794c.576-1.286 2.16-4.16 2.16-4.16l-.652-.877-2.998 2.354s-3.574 2.849-5.252 3.12c-1.678.27 0 2.357 0 2.357z"
        ></path>
        <path
          fill="#000D23"
          d="M99.66 103.702s6.167-1.508 6.742-2.794c.576-1.286 2.16-4.16 2.16-4.16l-.652-.877-2.998 2.354s-3.574 2.849-5.252 3.12c-1.678.27 0 2.357 0 2.357z"
          opacity="0.05"
        ></path>
        <path
          fill="#FF8589"
          d="M94.329 96.444v3.345h.537c2.657 0 5.269.676 7.592 1.965l1.009.558.27.153 1.733-3.9-1.619-1.452h-.083c-.398.038-2.111.183-2.835-.063-.725-.246-1.546-.977-3.581-.457-.999.256-1.855 1.386-2.825 1.04l-.198-1.19z"
        ></path>
        <path
          fill="#000D23"
          d="M88.939 90.356l-1.117 1.255v3.057s.95 9.606 3.966 10.666c3.016 1.061 8.399-.613 8.399-.613s1.387-3.685.739-3.91c-.649-.226-5.748-1.57-5.748-1.57s.07-5.633-1.04-7.308c0 0 .728-5.251-1.785-5.362-2.513-.111-3.414 3.785-3.414 3.785z"
          opacity="0.1"
        ></path>
        <path
          fill="#3F3D56"
          d="M88.772 90.356l-1.12 1.255v3.057s.95 9.606 3.966 10.666c3.016 1.061 8.389-.613 8.389-.613s1.387-3.685.742-3.91c-.645-.226-5.751-1.57-5.751-1.57s.073-5.633-1.04-7.308c0 0 .725-5.251-1.789-5.362-2.513-.111-3.397 3.785-3.397 3.785z"
        ></path>
        <path
          fill="#000D23"
          d="M96.572 77.443a2.209 2.209 0 01.128-1.085.843.843 0 01.88-.513c.479.111.694.735 1.155.884a.912.912 0 001.012-.523c.16-.385.227-.802.194-1.217v-1.386c0-.454.017-1.058.329-1.456.392-.496 1.307-.714 1.917-.694.407.004.797.16 1.092.44a1.42 1.42 0 001.151.41.997.997 0 00.804-.694.05.05 0 010-.017 5.697 5.697 0 00-8.329-.806 5.697 5.697 0 00-.143 8.366 14.653 14.653 0 01-.19-1.709z"
          opacity="0.1"
        ></path>
        <path
          fill="#3F3D56"
          d="M104.437 72.136a1.006 1.006 0 00.804-.693c.045-.18.035-.375.087-.552.131-.44.599-.669.911-1.001.631-.67.589-1.768.142-2.569-.346-.648-1.04-1.192-1.785-1.068-.451.08-.842.403-1.3.43a2.719 2.719 0 01-1.244-.402 4.69 4.69 0 00-3.089-.274c-.317.063-.61.212-.85.43a6.973 6.973 0 00-.45.635c-.523.665-1.456.793-2.284.942-.829.15-1.768.479-2.042 1.28-.18.526 0 1.102.097 1.653a.475.475 0 01-.02.347c-.129.215-.455.124-.694.176-.468.097-.59.694-.607 1.186-.02.551-.034 1.102-.045 1.653a1.617 1.617 0 00.524 1.387l1.844 2.215c.818.984 1.685 2.129 1.522 3.397.385.173.846-.163.974-.568a2.51 2.51 0 00-.063-1.259 15.32 15.32 0 01-.29-2.18 2.208 2.208 0 01.128-1.085.839.839 0 01.883-.513c.479.11.694.731 1.155.884a.916.916 0 001.012-.524c.16-.384.227-.801.194-1.216V73.46c0-.506.017-1.04.329-1.456.392-.496 1.307-.71 1.917-.693a1.64 1.64 0 011.092.44 1.412 1.412 0 001.148.385z"
        ></path>
        <path
          fill="#000D23"
          d="M103.151 89.656l-1.734 7.186s-1.393-.01 1.734-7.186zM97.175 88.457s2.291 3.75 1.386 7.626l-.426.031s.773-1.792-.96-7.657zM95.722 109.678s-2.81-.929-3.015 1.227c0 0 .53-1.481 3.015-1.227zM103.851 97.28s.558 3.909-.447 5.473a12.2 12.2 0 004.922.669l.347-6.143h-4.822z"
          opacity="0.1"
        ></path>
        <path
          fill="#000D23"
          d="M103.473 102.311l.271.153 1.733-3.9-1.619-1.453h-.087c.021.188.472 3.547-.298 5.2z"
          opacity="0.1"
        ></path>
        <path
          fill="#3F3D56"
          d="M103.851 97.111s.558 3.91-.447 5.474a12.29 12.29 0 004.922.669l1.955-6.143h-6.43z"
        ></path>
        <path
          fill="#CBCEDA"
          d="M108.673 162.337a1.265 1.265 0 001.085 1.106c1.515.229 5.168.804 6.09 1.244 1.176.558 5.81.167 6.032-.392a.877.877 0 00-.08-.669 1.782 1.782 0 00-1.095-.956c-.874-.291-2.52-.908-2.967-1.557 0 0-2.056-1.799-2.642-2.773a1.347 1.347 0 00-1.074-.669c-.489-.021-1.04.225-1.255 1.297 0 0-2.68 1.528-3.404.187 0 0-.115 2.059-.558 2.624a.75.75 0 00-.132.558z"
        ></path>
        <path
          fill="#000D23"
          d="M108.673 162.336a1.265 1.265 0 001.085 1.106c1.515.228 5.168.804 6.091 1.244 1.175.558 5.809.167 6.031-.392a.881.881 0 00-.08-.669 1.777 1.777 0 00-.748-.807c-.756.502-2.427.995-6.122.416-3.522-.548-5.266-1.089-6.115-1.453a.755.755 0 00-.142.555z"
          opacity="0.1"
        ></path>
        <g fill="#000D23" opacity="0.1">
          <path
            d="M93.441 71.012v-.025a5.343 5.343 0 01-.17-1.268c.027.346.077.69.15 1.03a.629.629 0 01.02.263zM96.7 76.24c-.088.203-.135.42-.139.641a1.931 1.931 0 01.139-.89.842.842 0 01.884-.514c.478.111.693.735 1.154.884a.911.911 0 001.013-.523 2.62 2.62 0 00.194-1.217v-1.386c0-.455.017-1.058.329-1.456.392-.496 1.307-.715 1.917-.694.406.004.797.16 1.092.44a1.418 1.418 0 001.151.41.996.996 0 00.804-.694c.045-.18.035-.374.087-.55.131-.441.599-.67.911-1.003.295-.322.463-.741.472-1.178a1.9 1.9 0 01-.472 1.432c-.312.346-.78.561-.911.998-.052.18-.042.37-.087.55a.998.998 0 01-.804.694 1.412 1.412 0 01-1.151-.412 1.623 1.623 0 00-1.092-.44c-.61-.021-1.525.197-1.917.693-.312.398-.326.95-.33 1.456-.003.506 0 .932 0 1.386.033.414-.033.83-.194 1.213a.905.905 0 01-1.012.524c-.468-.15-.676-.77-1.154-.88a.836.836 0 00-.884.516zM92.11 74.705c.103.287.265.55.475.77l1.845 2.215c.776.932 1.597 2.01 1.535 3.203-.055-1.092-.815-2.08-1.535-2.954l-1.845-2.214a1.62 1.62 0 01-.523-1.387c0 .124.016.248.048.367zM96.925 80.518c.031-.098.05-.199.056-.301a1.55 1.55 0 01-.055.551c-.129.406-.586.742-.975.565.011-.08.017-.162.018-.243a.793.793 0 00.957-.572z"
            opacity="0.1"
          ></path>
        </g>
        <path
          fill="#000D23"
          d="M115.481 158.85s-.582 1.04-2.697.121l2.697-.121zM97.598 159.096s-1.824 2.149-4.122.693l4.122-.693zM92.29 133.693s1.286.805 1.331 1.266c.046.461-.665-.347-.665-.347l-.666-.919zM91.781 139.867a2.684 2.684 0 001.588.621c.963.041.884 1.296.253.922a15.84 15.84 0 01-1.84-1.543zM91.535 140.672s.62 3.04 1.123 2.454l-1.123-2.454z"
          opacity="0.1"
        ></path>
        <path
          stroke="#3F3D56"
          strokeMiterlimit="10"
          strokeWidth="4"
          d="M28.872 159.709c1.04-1.91-.142-4.25-1.487-5.952-1.345-1.702-2.985-3.466-2.95-5.647.052-3.119 3.362-4.963 6.007-6.617a29.111 29.111 0 005.394-4.337 7.975 7.975 0 001.657-2.218c.548-1.22.53-2.607.496-3.941a285.583 285.583 0 00-.659-13.343"
        ></path>
        <path
          fill="#57B894"
          d="M42.36 117.411a4.85 4.85 0 00-2.427-3.987l-1.088 2.153.031-2.607a4.852 4.852 0 103.487 4.441h-.003zM33.683 150.34a4.852 4.852 0 11.236-3.917l-3.04 2.471 3.345-.773a4.854 4.854 0 01-.54 2.219zM31.281 140.894a4.857 4.857 0 01-5.133-4.067 4.852 4.852 0 013.59-5.476l-.027 2.003 1.102-2.18a4.858 4.858 0 014.631 2.787c.272.579.426 1.205.454 1.844a4.85 4.85 0 01-4.617 5.089zM43.091 133.035a4.846 4.846 0 01-3.93-1.768 4.85 4.85 0 011.774-7.515 4.863 4.863 0 014.305.176l-.86 2.358 1.772-1.699a4.854 4.854 0 011.57 3.359c.023.451-.017.902-.121 1.341a4.87 4.87 0 01-4.51 3.748z"
        ></path>
        <path
          fill="#000D23"
          d="M41.705 118.708c-1.123.121-2.215.471-3.342.541-1.126.069-2.364-.201-3.078-1.075-.381-.471-.575-1.071-.898-1.584a3.463 3.463 0 00-1.227-1.154 4.85 4.85 0 002.754 6.667 4.843 4.843 0 003.936-.306 4.844 4.844 0 002.406-3.131 7.392 7.392 0 00-.551.042zM43.092 133.034a4.85 4.85 0 01-4.628-6.932c.386.259.718.591.977.977.347.524.558 1.13.964 1.609.76.89 2.052 1.182 3.227 1.13 1.175-.052 2.309-.389 3.467-.496.163 0 .326-.024.492-.028a4.867 4.867 0 01-4.5 3.74zM31.281 140.893a4.862 4.862 0 01-4.259-2.113 4.857 4.857 0 01-.41-4.737c.404.263.757.598 1.04.988.378.534.613 1.147 1.057 1.64.822.915 2.201 1.237 3.442 1.206 1.241-.031 2.368-.319 3.564-.413a4.852 4.852 0 01-4.434 3.429zM33.683 150.338a4.859 4.859 0 11-8.87-3.969c.402.262.762.583 1.067.953.465.562.77 1.203 1.304 1.734.995.977 2.6 1.386 4.031 1.417.825.004 1.65-.041 2.468-.135z"
          opacity="0.1"
        ></path>
        <path
          fill="#656380"
          d="M21.534 157.51s3.84-.1 4.985-.943c1.143-.842 5.892-1.806 6.19-.485.299 1.321 5.772 6.586 1.436 6.607-4.337.021-10.07-.693-11.228-1.386-1.158-.694-1.383-3.793-1.383-3.793z"
        ></path>
        <path
          fill="#000D23"
          d="M34.238 162.23c-4.336.038-10.073-.673-11.227-1.387-.881-.537-1.231-2.461-1.349-3.345h-.128s.242 3.096 1.386 3.813c1.144.718 6.895 1.414 11.228 1.387 1.252 0 1.685-.454 1.66-1.113-.162.392-.623.638-1.57.645z"
          opacity="0.2"
        ></path>
      </g>
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="112.905"
          x2="112.905"
          y1="159.03"
          y2="158.947"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="gray" stopOpacity="0.25"></stop>
          <stop offset="0.54" stopColor="gray" stopOpacity="0.12"></stop>
          <stop offset="1" stopColor="gray" stopOpacity="0.1"></stop>
        </linearGradient>
        <clipPath id="clip0">
          <path
            fill="#fff"
            d="M0 0H408V171.329H0z"
            transform="translate(0 .336)"
          ></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgEmptyCustomerList;
