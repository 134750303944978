/* eslint-disable max-len */
import React from 'react';

function SvgClose() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="none"
      viewBox="0 0 14 14"
    >
      <path
        fill="currentColor"
        d="M8.175 7l5.25-5.242A.837.837 0 0012.242.575L7 5.825 1.758.575A.837.837 0 00.575 1.758L5.825 7l-5.25 5.242a.832.832 0 000 1.183.833.833 0 001.183 0L7 8.175l5.242 5.25a.833.833 0 001.183 0 .833.833 0 000-1.183L8.175 7z"
      />
    </svg>
  );
}

export default SvgClose;