import { useMutation,  } from '@apollo/client';
import QueryGql from 'constants/QueryGql';
import useNotification from './useNotification';

export default function useMembership() {
  const title = 'Types & memberships';
  const { successCreate, successRemove } = useNotification();

  const [create] = useMutation(QueryGql.CREATE_MEMBERSHIP, {
    onCompleted: (res) => {
      const name = res?.createMembership?.data?.name;
      successCreate({ title, name });
    },
    refetchQueries: [QueryGql.SEARCH_MEMBERSHIPS]
  });

  const [update] = useMutation(QueryGql.UPDATE_MEMBERSHIP, {

  });

  const [remove, { loading: removeLoading }] = useMutation(QueryGql.DELETE_MEMBERSHIP, {
    onCompleted: (res) => {
      const name = res?.updateMembership?.data?.name;
      successRemove({ title, name });
    },
    refetchQueries: [QueryGql.SEARCH_MEMBERSHIPS]
  });

  return {
    create,
    update,
    remove,
    removeLoading
  };
}