import { Col, Row, Space } from 'antd';
import React, { useMemo } from 'react';
import Divider from '../atoms/Divider';
import DashboardHello from '../organisms/DashboardHello';
import DashboardPrepare from '../organisms/DashboardPrepare';
import NotesForToday from '../organisms/NotesForToday';
import StatusInsights from '../organisms/StatusInsights';
import AppWrapper from '../templates/AppWrapper';
import ReservationOverview from '../templates/ReservationOverview';
import RevenueOverview from '../templates/RevenueOverview';
import DashboardFooter from '../organisms/DashboardFooter';
import { authFromLocal } from '../../utils/function';
import { useQuery } from '@apollo/client';
import QueryGql from '../../constants/QueryGql';

const Dashboard = () => {
  const auth = authFromLocal();
  const name = auth?.login?.user?.full_name;

  const { data: dataNoteForToday } = useQuery(
    QueryGql.GET_NOTE_FOR_TODAY,
    {
      fetchPolicy: 'cache-and-network'
    }
  );

  const notesForToday = useMemo(() => {
    const data = dataNoteForToday?.getNoteForToday?.data || [];
    return data.filter(item => item.node !== '').map(item => {

      return {
        id: item.id,
        title: item.name,
        course: `${item.course.name} (${item.course.holes}) ${item.time}`,
        desc: item.note
      };
    });
  }, [dataNoteForToday]);

  const { data: dataStatusInsight } = useQuery(
    QueryGql.GET_STATUS_INSIGHT,
    {
      fetchPolicy: 'cache-and-network'
    }
  );

  const statusInsight = dataStatusInsight?.getStatusInsight?.data || {};

  const { data: dataStepGuide } = useQuery(
    QueryGql.GET_STEP_GUIDE,
    {
      fetchPolicy: 'cache-and-network'
    }
  );
  const step = dataStepGuide?.getStepGuideApp?.data || {};

  return (
    <AppWrapper>
      <Space direction="vertical" style={{ marginTop: 64, marginRight: 20 }} size={64}>
        <DashboardHello name={name} />
        <Divider />
        <DashboardPrepare
          teeSheet={step.tee_sheet}
          teeTime={step.tee_time}
          rateCard={step.rate_card}
          addOns={step.add_ons}
          inclusions={step.inclusions}
          raincheck={step.raincheck}
        />
        <Divider />
        <ReservationOverview />
        <Divider />
        <RevenueOverview />
        <Divider />
        <Row gutter={[64, 64]}>
          <Col span={12}>
            <StatusInsights
              reserved={statusInsight.reserved}
              checkedIn={statusInsight.checked_in}
              noShow={statusInsight.no_show}
              paid={statusInsight.paid}
              canceled={statusInsight.canceled}
              rainchecksIssued={statusInsight.rainchecks_issued}
            />
          </Col>
          <Col span={12}>
            <NotesForToday data={notesForToday} />
          </Col>
        </Row>
        <Divider />
        <DashboardFooter />
        <Divider />
      </Space>
    </AppWrapper>
  );
};

export default Dashboard;