import { Button, Col, Input, List, Row, Table } from 'antd';
import Text from 'antd/lib/typography/Text';
import React, { forwardRef, useImperativeHandle, useMemo, useRef } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import axios from 'axios';
import Avatar from '../atoms/Avatar';
import { authFromLocal } from '../../utils/function';
import QueryGql from '../../constants/QueryGql';

const TeeSheetRaincheckIssueValidations = Yup.object({
  reservation_id: Yup.string().required(),
  customers: Yup.array().of(Yup.object().shape({
    customer_id: Yup.string(),
    raincheck_code: Yup.string().required().label('Raincheck code'),
    amount: Yup.string().required().label('Percentage of amount'),
  })).required()
});

const TeeSheetRaincheckIssue = (props, ref) => {
  
  const { needRainchecks, reservationId } = props;
  const refFormik = useRef();

  const handleGenerateRaincheckCode =  (customer_id) => async () => {
    const auth = authFromLocal();
    const token = auth?.login.token;
    try {
      const generateRaincheckCode = await axios({
        url: 'https://m-api.getswing.app/graphql',
        method: 'post',
        headers: {
          'Authorization': `Bearer ${token}`,
          locationid: '02321053-9015-4554-aa01-8e0b559744a6'
        },
        data: {
          query: QueryGql.GENERATE_RAINCHECK_CODE,
        }
      });
      const { code } = generateRaincheckCode.data.data.generateRaincheckCode.data;
      handleChangeCode(customer_id)(code);
    // eslint-disable-next-line no-empty
    } catch (error) {}
  };

  const handleChangeAmountByPercentage = (percentage, customer_id) => () => {
    const baseAmount = needRainchecks.filter((x) => x.customer_id === customer_id)[0].amount;
    const amount = (baseAmount * percentage) / 100;
    handleChangeAmount(customer_id)(amount);
  };

  const handleChangeAmount = (customer_id) => (e) => {
    let value = e;
    if (typeof e !== 'number') {
      value = e.target.value;
    }
    let customerSelection = refFormik.current.values?.customers || [];
    const isExistCustomerSelection = customerSelection.find((x) => x.customer_id === customer_id);
    if (!isExistCustomerSelection) {
      const customer = needRainchecks.find((x) => x.customer_id === customer_id);
      customerSelection = [
        ...customerSelection,
        {
          customer_id: customer.customer_id,
          amount: value,
        }
      ];
    } else {
      customerSelection = customerSelection.map((item) => {
        if (item.customer_id === customer_id) {
          return {
            ...item,
            amount: value,
          };
        }
        return item;
      });
    }
    refFormik.current.setFieldValue('customers', customerSelection);
  };

  const handleChangeCode = (customer_id) => (e) => {
    let value = e;
    if (typeof e !== 'string') {
      value = e.target.value;
    }
    let customerSelection = refFormik.current.values?.customers || [];
    const isExistCustomerSelection = customerSelection.find((x) => x.customer_id === customer_id);
    if (!isExistCustomerSelection) {
      const customer = needRainchecks.find((x) => x.customer_id === customer_id);
      customerSelection = [
        ...customerSelection,
        {
          customer_id: customer.customer_id,
          raincheck_code: value,
        }
      ];
    } else {
      customerSelection = customerSelection.map((item) => {
        if (item.customer_id === customer_id) {
          return {
            ...item,
            raincheck_code: value,
          };
        }
        return item;
      });
    }
    refFormik.current.setFieldValue('customers', customerSelection);
  };

  const columns = useMemo(() => [
    {
      dataIndex: 'user',
      title: 'Customer name',
      width: 289
    },
    {
      dataIndex: 'render_code',
      title: 'Raincheck code',
      width: 289
    },
    {
      dataIndex: 'render_amount',
      title: 'Percentage or amount'
    },
  ], []);

  useImperativeHandle(ref, () => ({
    handleSubmit: async () => {
      const validateForm = await refFormik.current.validateForm();
      if (Object.keys(validateForm).length === 0) {
        refFormik.current.handleSubmit();
        return refFormik.current.values;  
      }
      return null;
    }
  }));

  return (
    <div id="tee-sheet-payment-success">
      <Formik
        innerRef={refFormik}
        enableReinitialize
        validationSchema={TeeSheetRaincheckIssueValidations}
        initialValues={{
          reservation_id: reservationId,
        }}
      >
        {(
          {
            values,
            errors,
            //   handleChange,
            //   submitCount,
            setFieldValue 
          }
        ) => {
          const data = needRainchecks.map((item) => {
            const customer = values?.customers?.find(x => x.customer_id === item.customer_id);
            return {
              'key': item.customer_id,
              'customer_id': item.customer_id,
              'status': item.status,
              'raincheck_code': customer?.raincheck_code,
              'amount': customer?.amount,
              'user': (
                <List.Item style={item.status !== 'paid' ? { opacity: 0.5 } : null}>
                  <List.Item.Meta
                    avatar={<Avatar title={item.name} style={{marginTop: 2}} />}
                    title={item.name}
                  />
                </List.Item>
              ),
              'render_code': (
                <Row style={item.status !== 'paid' ? { opacity: 0.5 } : null} wrap={false} justify="space-between" align="middle">
                  <Col>
                    <Input disabled={item.status !== 'paid'} value={customer?.raincheck_code} onChange={handleChangeCode(item.customer_id)} bordered={false} placeholder="Enter code" />
                  </Col>
                  <Col>
                    <Button disabled={item.status !== 'paid'} type="link" onClick={handleGenerateRaincheckCode(item.customer_id)}>Autogenerate</Button>
                  </Col>
                  {(customer && !customer?.raincheck_code) && <Text className="error-message error-cell" type="danger">{errors?.customers?.find(x => x?.raincheck_code)?.raincheck_code}</Text>}
                </Row>
              ),
              'render_amount': (
                <Row style={item.status !== 'paid' ? { opacity: 0.5 } : null} wrap={false} justify="space-between" align="middle">
                  <Col>
                    <Input
                      bordered={false}
                      disabled={item.status !== 'paid'}
                      type="number"
                      placeholder="Enter amount"
                      onChange={handleChangeAmount(item.customer_id)}
                      prefix={<Text type="secondary">Rp. </Text>}
                      value={customer?.amount}
                      size="small"
                    />
                  </Col>
                  <Col>
                    <Row wrap={false}>
                      <Col>
                        <Button disabled={item.status !== 'paid'} type="link" onClick={handleChangeAmountByPercentage(50, item.customer_id)}>50%</Button>
                      </Col>
                      <Col>
                        <Button disabled={item.status !== 'paid'} type="link" onClick={handleChangeAmountByPercentage(100, item.customer_id)}>100%</Button>
                      </Col>
                    </Row>
                  </Col>
                  {(customer && !customer?.amount) && <Text className="error-message error-cell" type="danger">{errors?.customers?.find(x => x?.amount)?.amount}</Text>}
                </Row>
              )
            };
          });

          return (
            <Table
              columns={columns}
              dataSource={data}
              bordered
              pagination={false}
              rowSelection={{
                selectedRowKeys: values?.customers?.map(x => x.customer_id),
                type: 'checkbox',
                onChange: (selectedRowKeys, selectedRows) => {
                  setFieldValue('customers', selectedRows.map(item => {
                    let existCustomer = null;
                    values.customers?.forEach(x => {
                      if (item.customer_id === x.customer_id) {
                        existCustomer = x;
                      }
                    });
                    if (existCustomer) {
                      return existCustomer;
                    } 
                    return item;
                  }));
                },
                getCheckboxProps: (record) => {
                  return {
                    disabled: record.status !== 'paid',
                    name: record.customer_id,
                  };
                }
              }}
            />
          );
        }}
      </Formik>
    </div>
  );
};

export default forwardRef(TeeSheetRaincheckIssue);

TeeSheetRaincheckIssue.propTypes = {
  needRainchecks: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    amount: PropTypes.number,
    customer_id: PropTypes.string,
    status: PropTypes.number
  })).isRequired,
  reservationId: PropTypes.string.isRequired
};