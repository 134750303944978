import { Col, Row, Space } from 'antd';
import React, { useState, useMemo, useEffect } from 'react';
import moment from 'moment';
import SingleSelect from '../molecules/SingleSelect';
import AppWrapper from '../templates/AppWrapper';
import { useHistory } from 'react-router-dom';
import Pathname from '../../constants/Pathname';
import DateRangePicker from '../molecules/DateRangePicker';
import ExportButton from '../molecules/ExportButton';
import InputText from '../atoms/InputText';
import MultipleSelect from '../molecules/MultipleSelect';
import { useQuery } from '@apollo/client';
import QueryGql from '../../constants/QueryGql';
import debounce from '../../utils/debounced';
import CancellationReportTemplate from '../templates/CancellationReport';

const CancellationReport = () => {
  const history = useHistory();
  const [date, setDate] = useState([moment().subtract(1, 'M'), moment()]);
  const [course, setCourse] = useState({ key: 'all', label: 'All golf courses', value: 'all' });
  const [statuses, setStatuses] = useState(['all']);
  const [search, setSearch] = useState('');
  const [paymentStatus] = useState([
    { key: 'all', value: 'all', label: 'All reason' },
    {
      key: 'Wrong entry by operator',
      value: 'Wrong entry by operator',
      label: 'Wrong entry by operator',
    },
    { key: 'Customer was sick', value: 'Customer was sick', label: 'Customer was sick' },
    {
      key: 'Customer urgent matter',
      value: 'Customer urgent matter',
      label: 'Customer urgent matter',
    },
    { key: 'Bad weather', value: 'Bad weather', label: 'Bad weather' },
    { key: 'Other reasons', value: 'Other reasons', label: 'Other reasons' },
  ]);

  const { data: dataGetAllCourses } = useQuery(QueryGql.GET_ALL_COURSES);
  const courses = dataGetAllCourses?.getAllCourses.data || [];
  const optionsCourses = useMemo(() => {
    const items = [{ key: 'all', value: 'all', label: 'All golf courses' }];

    courses.forEach(item => {
      items.push({
        key: item.id,
        value: item.id,
        label: `${item.name} (${item.holes})`,
      });
    });
    return items;
  }, [courses]);

  const {
    loading: laodingCustomerCheckInsSummary,
    data,
    refetch: refetchSummary,
  } = useQuery(QueryGql.SEARCH_CANCEL_RESERVATION_REPORT, {
    variables: {
      data: {
        search,
        reason: statuses.includes('all') ? null : statuses,
        start_date: moment(date[0]).startOf('day').toISOString(),
        end_date: moment(date[1]).endOf('day').toISOString(),
        courses_id: course.value === 'all' ? null : course.value,
      },
    },
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    if (history.location.search.includes('refetch')) {
      refetchSummary();
    }
  }, [history.location.key]);

  const dataReport = data?.searchCancelReservationReport?.data || {};

  const handleClickExport = () => {
    history.push(`${Pathname.CANCELATION_REPORT}/${Pathname.EXPORT_CANCELLATION_REPORT}`, {
      background: true,
    });
  };

  const isLoading = laodingCustomerCheckInsSummary;

  const handleChangeCourse = item => {
    setCourse(item);
  };

  const handleChangeDate = item => {
    setDate(item);
  };

  const handleChangeStatuses = values => {
    setStatuses(values);
  };

  const handleChangeSearch = debounce(event => {
    setSearch(event.target.value);
  }, 1000);

  return (
    <AppWrapper>
      <Space style={{ marginTop: 32, paddingBottom: 32 }} direction='vertical' size={16}>
        <Row justify='space-between'>
          <Col>
            <Space>
              <InputText
                placeholder='Search check-ins'
                type='search'
                className='transparent border bold search'
                style={{
                  width: 170,
                }}
                onChange={handleChangeSearch}
              />
              <DateRangePicker allowClear={false} defaultValue={date} onChange={handleChangeDate} />
              <SingleSelect
                items={optionsCourses}
                value={course.value}
                valueLabel={course.label}
                onSelect={handleChangeCourse}
              />
              <MultipleSelect
                items={paymentStatus}
                value={statuses.includes('all') ? 'All reasons' : statuses.join(', ')}
                values={statuses}
                handleChange={handleChangeStatuses}
              />
            </Space>
          </Col>
          <Col>
            <ExportButton onClick={handleClickExport} />
          </Col>
        </Row>
        <CancellationReportTemplate dataReport={dataReport} isLoading={isLoading} />
      </Space>
    </AppWrapper>
  );
};

export default CancellationReport;
