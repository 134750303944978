import { ArrowRightOutlined } from '@ant-design/icons';
import { useQuery } from '@apollo/client';
import { Row, Space } from 'antd';
import Title from 'antd/lib/typography/Title';
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import Pathname from '../../constants/Pathname';
import QueryGql from '../../constants/QueryGql';
import Button from '../atoms/Button';
import RevenueCards from '../organisms/RevenueCards';
import RevenueLineChart from '../organisms/RevenueLineChart';

export default function RevenueOverview() {

  const { data: dataRevenueReport } = useQuery(
    QueryGql.GET_REVENUE_OVERVIEW, {
      variables: {
        data: {
          'revenue_by_green_fee': false,
          'revenue_by_customer_memberships': false,
          'revenue_by_payment_methods': false,
          'revenue_by_add_ons': false
        }
      },
      fetchPolicy: 'cache-and-network'
    }
  );

  const dataReport = dataRevenueReport?.getRevenueReport.data || {};
  const summary = dataReport.summary || {};

  const lineChartData = useMemo(() => {
    const items = [];

    dataReport.chart?.revenue_by_datetime?.map(item => {
      items.push({
        name: item.date,
        value: item.revenue
      });
    });
    return items;
  }, [dataReport]);

  return (
    <Space style={{ width: '100%' }} size={16} direction="vertical">
      <Row justify="space-between">
        <Title level={2}>Revenue overview</Title>
        <Link to={Pathname.REVENUE_REPORT}>
          <Button type="link">
            See complete Revenue report <ArrowRightOutlined style={{ fontSize: 12 }} />
          </Button>
        </Link>
      </Row>
      <RevenueCards
        totalGreenFee={summary.total_green_fee}
        totalAddOns={summary.total_add_ons}
        totalRevenue={summary.total_revenue}
        averageGreenFee={summary.average_green_fee}
        averageAddOns={summary.average_add_ons}
        averageRevenue={summary.average_revenue}
      />
      <RevenueLineChart data={lineChartData} />
    </Space>
  );
}