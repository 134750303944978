import React from 'react';

function SvgIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="none"
      viewBox="0 0 18 14"
    >
      <path
        fill="url(#paint0_linear)"
        d="M.135 7.028a.46.46 0 010-.65L6.377.136a.46.46 0 01.785.324v12.486a.46.46 0 01-.785.325L.135 7.028z"
      ></path>
      <path
        fill="url(#paint1_linear)"
        fillOpacity="0.5"
        d="M.135 7.028a.46.46 0 010-.65L6.377.136a.46.46 0 01.785.324v12.486a.46.46 0 01-.785.325L.135 7.028z"
      ></path>
      <path
        fill="url(#paint2_linear)"
        fillOpacity="0.1"
        d="M.135 7.028a.46.46 0 010-.65L6.377.136a.46.46 0 01.785.324v12.486a.46.46 0 01-.785.325L.135 7.028z"
      ></path>
      <path
        fill="url(#paint3_linear)"
        d="M17.865 7.028a.46.46 0 000-.65L11.623.136a.46.46 0 00-.785.324v12.486c0 .41.495.615.785.325l6.242-6.243z"
      ></path>
      <path
        fill="url(#paint4_linear)"
        fillOpacity="0.5"
        d="M17.865 7.028a.46.46 0 000-.65L11.623.136a.46.46 0 00-.785.324v12.486c0 .41.495.615.785.325l6.242-6.243z"
      ></path>
      <path
        fill="url(#paint5_linear)"
        fillOpacity="0.1"
        d="M17.865 7.028a.46.46 0 000-.65L11.623.136a.46.46 0 00-.785.324v12.486c0 .41.495.615.785.325l6.242-6.243z"
      ></path>
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="-4.763"
          x2="14.175"
          y1="0.335"
          y2="15.08"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.271" stopColor="#5C5C5C"></stop>
          <stop offset="0.573" stopColor="#8F8F8F"></stop>
        </linearGradient>
        <linearGradient
          id="paint1_linear"
          x1="1.608"
          x2="12.011"
          y1="19.626"
          y2="-2.812"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#292929"></stop>
          <stop offset="0.579" stopColor="#8F8F8F"></stop>
        </linearGradient>
        <linearGradient
          id="paint2_linear"
          x1="18.029"
          x2="12.529"
          y1="-0.022"
          y2="14.723"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F5F5F5"></stop>
          <stop offset="0.123" stopColor="#C2C2C2"></stop>
        </linearGradient>
        <linearGradient
          id="paint3_linear"
          x1="-4.763"
          x2="14.175"
          y1="0.335"
          y2="15.08"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.271" stopColor="#5C5C5C"></stop>
          <stop offset="0.573" stopColor="#8F8F8F"></stop>
        </linearGradient>
        <linearGradient
          id="paint4_linear"
          x1="1.608"
          x2="12.011"
          y1="19.626"
          y2="-2.812"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#292929"></stop>
          <stop offset="0.579" stopColor="#8F8F8F"></stop>
        </linearGradient>
        <linearGradient
          id="paint5_linear"
          x1="18.029"
          x2="12.529"
          y1="-0.022"
          y2="14.723"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F5F5F5"></stop>
          <stop offset="0.123" stopColor="#C2C2C2"></stop>
        </linearGradient>
      </defs>
    </svg>
  );
}

export default SvgIcon;
