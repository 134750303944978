import { Col, Row, Divider } from 'antd';
import Title from 'antd/lib/typography/Title';
import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Text from 'antd/lib/typography/Text';
import Card from '../atoms/Card';
import './RoleSettingsCard.less';
// import Checkbox from '../atoms/Checkbox';
import RadioButtonTab from '../atoms/RadioButtonTab';
import { Checkbox } from 'antd';

const RoleSettingsCard = (props) => {
  const { icon, key, title, desc, checkboxObject, onChange, onChangeAccess, categoryId, values } = props;
  const [radioValue, setRadioValue] = useState('is_editable');

  useEffect(() => {
    if (values?.length > 0) {
      setRadioValue(values[0].is_no_access ? 'is_no_access' : values[0].is_see_only ? 'is_see_only' : 'is_editable');
    }
  }, [values]);

  const handleSwitchRadio = useCallback((e) => {
    const value = e.target.value;
    onChangeAccess(value, categoryId);
    setRadioValue(value);
  }, []);

  return (
    <div id="role-setting-card">
      <Card key={key} className="role-setting">
        <Row gutter={5}>
          <Col md={2} id="icon-role">
            {icon}
          </Col>
          <Col md={22} style={{ textAlign: 'left' }}>
            <Row justify="space-between">
              <Col>
                <Title level={5} style={{ marginBottom: 0 }}>{title}</Title>
                <Text className="subtitle">{desc}</Text>
              </Col>
              <Col>
                <RadioButtonTab 
                  options={options}
                  value={radioValue}
                  onChange={handleSwitchRadio}
                />
              </Col>
            </Row>
            {radioValue === 'is_editable' && (
              <>
                <Divider className="divider-role" />
                <Checkbox.Group
                  className="checkbox-group"
                  options={checkboxObject}
                  disabled={radioValue === 'is_no_access'}
                  value={values?.filter(value => {
                    switch (radioValue) {
                      case 'is_no_access':
                        return value.is_no_access === true;
                      case 'is_see_only':
                        return value.is_see_only === true;
                      default:
                        return value.is_editable === true;
                    }
                  })?.[0]?.features}
                  onChange={(value) => onChange({
                    id: categoryId,
                    is_no_access: radioValue === 'is_no_access',
                    is_see_only: radioValue === 'is_see_only',
                    is_editable: radioValue === 'is_editable',
                    features: value
                  })}
                />
              </>
            )}
          </Col>
        </Row>
      </Card>
    </div>
  );
};

const options = [
  { label: 'No-access', value: 'is_no_access' },
  { label: 'See only', value: 'is_see_only' },
  { label: 'Can edit', value: 'is_editable' },
];

export default RoleSettingsCard;

RoleSettingsCard.propTypes = {
  key: PropTypes.string.isRequired,
  icon: PropTypes.any.isRequired,
  title: PropTypes.string.isRequired,
  dessc: PropTypes.string.isRequired,
  checkboxObject: PropTypes.shape({
    key: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired
  }),
  onChange: PropTypes.func,
  values: PropTypes.any
};

RoleSettingsCard.defaultProps = {
  checkboxObject: {
    key: '1',
    label: 'label',
    value: '1'
  },
};