import { Button, Select } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { CheckOutlined } from '@ant-design/icons';
import './ColorPicker.less';

const { Option } = Select;

const ColorPicker = (props) => {

  const colors = useMemo(() => {
    return [
      {
        key: '#EBEEFF',
        label: '#EBEEFF',
        value: '#EBEEFF'
      },
      {
        key: '#F5F5F5',
        label: '#F5F5F5',
        value: '#F5F5F5'
      },
      {
        key: '#ECFEF5',
        label: '#ECFEF5',
        value: '#ECFEF5'
      },
      {
        key: '#FFEBEB',
        label: '#FFEBEB',
        value: '#FFEBEB'
      },
      {
        key: '#FFF3EB',
        label: '#FFF3EB',
        value: '#FFF3EB'
      },
      {
        key: '#FAEBFF',
        label: '#FAEBFF',
        value: '#FAEBFF'
      },
      {
        key: '#FFFFFF',
        label: '#FFFFFF',
        value: '#FFFFFF'
      },
      {
        key: '#0025B8',
        label: '#0025B8',
        value: '#0025B8'
      },
      {
        key: '#5C5C5C',
        label: '#5C5C5C',
        value: '#5C5C5C'
      },
      {
        key: '#06884A',
        label: '#06884A',
        value: '#06884A'
      },
      {
        key: '#B80006',
        label: '#B80006',
        value: '#B80006'
      },
      {
        key: '#B84300',
        label: '#B84300',
        value: '#B84300'
      },
      {
        key: '#8100B8',
        label: '#8100B8',
        value: '#8100B8'
      },
      {
        key: '#000D23',
        label: '#000D23',
        value: '#000D23'
      },
      {
        key: '#06E545',
        label: '#06E545',
        value: '#06E545'
      },
    ];
  }, []);

  const [value, setValue] = useState(props?.value ? colors.find(x => x.value === props.value) : {
    key: '#000D23',
    label: '#000D23',
    value: '#000D23'
  });

  useEffect(() => {
    setValue(props?.value ? colors.find(x => x.value === props.value) : {
      key: '#000D23',
      label: '#000D23',
      value: '#000D23'
    });
  }, [props.value]);

  const handleChange = valueColor => {
    const selectedColor = colors.filter(color => color.value === valueColor)?.[0];
    setValue(selectedColor);
    if (props?.onChange) {
      props.onChange(selectedColor);
    }
  };

  const renderDropdown = (node) => {
    const { options, values, onToggleOpen, onSelect } = node.props;
    const it = values.values();
    const first = it.next();
    const currentValue = first.value?.props?.children;
    return options.map(option => (
      <Button
        key={option.key}
        className="color-item"
        type="text"
        onClick={() => {
          onSelect(option.value);
          onToggleOpen();
        }}
        style={{ backgroundColor: option.children }}
      >
        {currentValue && currentValue === option.children 
          ? <CheckOutlined 
            size={14}
            style={{color : currentValue === '#000D23' ? '#fff' : '#000D23'}}
          />
          : ' '
        }
      </Button>
    ));
  };

  return (
    <Select
      {...props}
      className={['color-picker container', props.className].join(' ')}
      dropdownRender={renderDropdown}
      dropdownClassName="color-picker drop-down"
      onChange={handleChange}
      value={(
        <div>{value?.value}</div>
      )}
    >
      {colors.map(color =>
        <Option
          key={color.key}
          value={color.value}
        >
          {color.label}
        </Option>
      )}
    </Select>
  );
};

export default ColorPicker;

ColorPicker.propTypes = {
  size: PropTypes.oneOf(['large', 'middle', 'small']),
  onChange: PropTypes.func,
  value: PropTypes.string,
  initialValue: PropTypes.shape({
    key: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired
  })
};

ColorPicker.defaultProps = {
  size: 'large',
  onChange: null,
  initialValue: {
    key: '#ECFEF5',
    label: '#ECFEF5',
    value: '#ECFEF5'
  },
};