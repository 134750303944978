import { useMutation, useQuery } from '@apollo/client';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import HorizontalTextInputArea from '../molecules/HorizontalTextInputArea';
import Modal from '../organisms/Modal';
import QueryGql from '../../constants/QueryGql';

const CancellationPolicy = () => {
  const history = useHistory();
  const { data: dataCancelationPolicy } = useQuery(QueryGql.GET_CANCELATION_POLICY, { fetchPolicy: 'cache-and-network' });
  const cancellationPolicy = dataCancelationPolicy?.getCancellationPolicy?.data?.cancellation_policy;

  const [state, setState] = useState(cancellationPolicy);
  
  useEffect(() => {
    if (cancellationPolicy) {
      setState(cancellationPolicy);
    }
  }, [cancellationPolicy]);
  
  const [setCancellationPolicy, {
    loading
  }] = useMutation(QueryGql.SET_CANCELATION_POLICY, {
    onCompleted: () => {
      history?.goBack();
    },
    refetchQueries: [QueryGql.GET_CANCELATION_POLICY]
  });

  const handleChangeName = useCallback((e) => {
    const name = e.target.value;
    setState(name);
  }, []);

  const handleAdded = () => {
    setCancellationPolicy({
      variables: { value: state }
    });
  };

  const handleRemove = () => {
    setCancellationPolicy({
      variables: { value: '' }
    });
  };

  return (
    <Modal
      title="Cancellation policy"
      hiddenLeftFooterButton={!cancellationPolicy}
      rightFooterButton={{
        label: 'Save cancellation policy',
        loading,
        state: state === '' ? 'disabled' : 'default',
        onClick: handleAdded
      }}
      leftFooterButton={{
        label: 'Remove cancellation policy',
        type: 'outline',
        state: state === '' ? 'disabled' : 'default',
        onClick: handleRemove
      }}
      bodyStyle={{ textAlign: 'right' }}
      width={540}
    >
      <HorizontalTextInputArea
        label="Policy"
        value={state}
        onChange={handleChangeName}
      />
    </Modal>
  );
};

export default CancellationPolicy;
