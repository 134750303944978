import Text from 'antd/lib/typography/Text';
import React from 'react';
import PropTypes from 'prop-types';
import './HorizontalColorPicker.less';
import ColorPicker from '../atoms/ColorPicker';

const HorizontalColorPicker = ({ label, onChange, value, initialValue }) => {
  return (
    <div className="antd-horizontal-color-picker">
      {label && <Text className="label">{label}</Text>}
      <ColorPicker className="color-picker" onChange={onChange} value={value} initialValue={initialValue} />
    </div>
  );
};

export default HorizontalColorPicker;

HorizontalColorPicker.propTypes = {
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  value: PropTypes.string,
  initialValue: PropTypes.shape({
    key: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired
  })
};

HorizontalColorPicker.defaultProps = {
  onChange: null,
  value: '',
};

