import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Card, Switch } from 'antd';
import Text from 'antd/lib/typography/Text';
import useQueryParams from '../../utils/useQuery';
import HorizontalTextInput from '../molecules/HorizontalTextInput';
import HorizontalSelectOptions from '../molecules/HorizontalSelectOptions';
// import HorizontalRadioButton from '../molecules/HorizontalRadioButton';
import HorizontalCardInput from '../molecules/HorizontalCardInput';
import Modal from '../organisms/Modal';
import './TypeMembershipsAddMember.less';
import HorizontalDateRange from '../molecules/HorizontalDateRange';
import useMembership from 'hooks/useMembership';
import { useHistory } from 'react-router-dom';
import useSubscriptionType from 'hooks/useSubscriptionType';
import moment from 'moment';

const CustomerSchemaValidations = Yup.object().shape({
  name: Yup.string().required('Required'),
});

const TypeMembershipsAddMember = () => {
  const history = useHistory();
  const location = useLocation();
  const queryString = useQueryParams(location.search);
  const act = queryString.get('act');
  const item = location.state?.item;

  const { create, update } = useMembership();
  const { optionsSubscriptionType } = useSubscriptionType();

  useEffect(() => {
    if ((act === 'edit' || act === 'duplicate') && !item) {
      history.goBack();
    }
  }, []);

  const onSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true);
    const {
      duration_type,
      date,
      name,
      pricing_type,
      price,
      subscription_type_id,
      is_eligible_account,
    } = values;
    let data = { name };

    if (pricing_type === 'paid') {
      data = {
        name,
        price: Number(price),
        pricing_type,
        subscription_type_id,
        duration_type,
        is_eligible_account,
      };

      if (duration_type === 'specified_duration' && date) {
        data.start_date = date[0];
        data.end_date = date[1];
      }

      delete data.date;
    }

    try {
      if (act === 'edit') {
        data.id = item.id;
        await update({
          variables: {
            data,
          },
        });
      } else {
        await create({
          variables: {
            data,
          },
        });
      }
      setSubmitting(false);
      history.goBack();
    } catch (error) {
      setSubmitting(false);
    }
  };

  return (
    <Formik
      enableReinitialize
      initialValues={
        act === 'edit' || act === 'duplicate'
          ? { 
            ...item,
            pricing_type: 'free',
            subscription_type_id: item.subscription_type?.id,
            date: [moment(item.start_date), moment(item.end_date)], 
          } || {}
          : {
            name: '',
            pricing_type: 'free',
          }
      }
      validationSchema={CustomerSchemaValidations}
      onSubmit={onSubmit}>
      {({ handleSubmit, handleChange, values, submitForm, isSubmitting, setFieldValue }) => {
        return (
          <Modal
            title={act === 'add' ? 'Add new customer type' :  act === 'duplicate' ? 'Duplicate new customer type' : 'Edit new customer type'}
            leftFooterButton={{
              label: 'Cancel',
              type: 'danger',
              state: 'hint',
            }}
            rightFooterButton={{
              label: 'Save customer type',
              onClick: submitForm,
              loading: isSubmitting,
            }}
            bodyStyle={{ textAlign: 'right' }}
            width={540}>
            <form id='customer-form' onSubmit={handleSubmit}>
              <Card className='member-settings'>
                <HorizontalTextInput
                  name='name'
                  layout='inline'
                  style={{ width: 256 }}
                  label='Customer type name'
                  value={values?.name}
                  onChange={handleChange('name')}
                />
                <div className='spacer' />
                {/* <HorizontalRadioButton
                  label='Pricing type'
                  className='pricing-type'
                  value={values.pricing_type}
                  onChange={e => setFieldValue('pricing_type', e.target.value)}
                  options={options}
                /> */}
                {values.pricing_type === 'paid' && (
                  <>
                    <div className='spacer' />
                    <HorizontalTextInput
                      style={{ width: 256 }}
                      label='Price'
                      name='price'
                      prefix={<Text type='secondary'>Rp.</Text>}
                      value={values?.price}
                      onChange={handleChange('price')}
                    />
                    <div className='spacer' />
                    <HorizontalSelectOptions
                      name='subscription_type_id'
                      label='Subscription type'
                      options={optionsSubscriptionType}
                      value={values?.subscription_type_id}
                      onChange={handleChange('subscription_type_id')}
                      showSearch
                      placeholder='Select subscription type'
                    />
                    <div className='spacer' />
                    <HorizontalSelectOptions
                      name='duration_type'
                      label='Duration'
                      options={duration}
                      value={values?.duration_type}
                      onChange={handleChange('duration_type')}
                      showSearch
                      placeholder='Select duration'
                    />
                    {values?.duration_type === 'specified_duration' ? (
                      <>
                        <div className='spacer' />
                        <HorizontalDateRange value={values.date} onChange={v => setFieldValue('date', v)} />
                      </>
                    ) : (
                      <></>
                    )}
                  </>
                )}
              </Card>
              {values.pricing_type === 'paid' && (
                <Card className='account-settings'>
                  <HorizontalCardInput
                    label='House account perk'
                    id='house-account'
                    width={256}
                    left={<Text className='value'>Eligible for house account</Text>}
                    right={
                      <Switch
                        onChange={value => setFieldValue('is_eligible_account', value)}
                        checked={Boolean(values.is_eligible_account)}
                      />
                    }
                  />
                </Card>
              )}
            </form>
          </Modal>
        );
      }}
    </Formik>
  );
};

// const options = [
//   { label: 'Free', value: 'free' },
//   { label: 'Paid', value: 'paid' },
// ];

const duration = [
  {
    key: 'always',
    value: 'always',
    label: 'Always',
  },
  {
    key: 'specified_duration',
    value: 'specified_duration',
    label: 'Specified duration',
  },
];

export default TypeMembershipsAddMember;
