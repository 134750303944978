import { Table, InputNumber } from 'antd';
import React, { useState, useMemo, forwardRef, useImperativeHandle } from 'react';
import Title from 'antd/lib/typography/Title';
import Text from 'antd/lib/typography/Text';
import PropTypes from 'prop-types';
import Tabs from '../atoms/Tabs';
import './RateCardsFormTable.less';

const RateCardsFormTable = (props, ref) => {
  const { courses, memberships, timeBaseRates, initialValues, withoutTitle } = props;
  const [ activeTab, setActiveTab ] = useState(courses[0].id);
  const mappingData = [];

  useImperativeHandle(ref, () => ({
    handleSubmit: () => mappingDataState
  }));

  if (initialValues) {
    initialValues.forEach(course => {
      course.memberships.forEach(membership => {
        membership.time_based_rates.forEach(timeBaseRate => {
          mappingData.push({
            courseId: course.id,
            membershipId: membership.id,
            timeBaseRateId: timeBaseRate.id,
            price: timeBaseRate.price
          });
        });
      });
    });
  } else {
    courses.forEach((course) => {
      memberships.forEach((membership) => {
        timeBaseRates.forEach((timeBaseRate) => {
          mappingData.push({
            courseId: course.id,
            membershipId: membership.id,
            timeBaseRateId: timeBaseRate.id,
            price: 0
          });
        });
      });
    });
  }
  let [mappingDataState, setMappingDataState] = useState(mappingData);
  const dataTabs = courses.map(course => ({
    key: course.id,
    label: `${course.name} (${course.holes} hole)`
  }));
  let keyData = {};
  const columns = [
    {
      title: 'Cust. types & memberships',
      dataIndex: 'member',
    },
    ...timeBaseRates.map(basedTimeRate => {
      keyData = {
        ...keyData,
        [basedTimeRate.id] : ''
      };
      return {
        title: basedTimeRate.name,
        className: 'column-money',
        dataIndex: basedTimeRate.id,
        align: 'right',
        render: data => (
          <InputNumber
            prefix={<Text type="secondary">Rp.</Text>}
            value={data.price}
            bordered={false}
            onFocus={e => e.target.select()}
            onChange={handleChangePrice(data)}
            formatter={value => `Rp. ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={value => value.replace(/Rp.\s?|(,*)/g, '')}
          />
        ),
      };
    })
  ];

  const data = useMemo(() => {
    const dataTab = mappingDataState.filter(item => item.courseId === activeTab);
    return memberships.map(membership => {
      const currentTimeBaseRates = dataTab.filter(item => item.membershipId === membership.id);
      Object.keys(keyData).forEach(key => {
        const currentTimeBaseRate = currentTimeBaseRates.filter(item => item.timeBaseRateId === key);
        keyData[key] = currentTimeBaseRate[0];
      });
      return {
        key: membership.id,
        member: membership.name,
        ...keyData
      };
    });
  }, [activeTab, mappingDataState]);

  const handleChangePrice = (itemChanged) => (value) => {
    const indexEditingData = mappingDataState.findIndex(data => data.courseId === itemChanged.courseId && data.membershipId === itemChanged.membershipId && data.timeBaseRateId === itemChanged.timeBaseRateId);
    const newMappingDataState = [...mappingDataState];
    newMappingDataState[indexEditingData].price = value;
    setMappingDataState(newMappingDataState);
  };

  return (
    <div id="rate-cards-form-table" style={{marginTop: withoutTitle ? 0 : 90}}>
      {!withoutTitle && <Title style={{ marginBottom: 10 }} level={4}>Pricing scheme</Title>}
      <Table
        columns={columns}
        dataSource={data}
        className="antd-table"
        bordered
        title={() => <Tabs dataTabs={dataTabs} activeKey={activeTab} onChange={setActiveTab} />}
        pagination={false}
      />
    </div>
  );
};

export default forwardRef(RateCardsFormTable);

RateCardsFormTable.propTypes = {
  courses: PropTypes.array,
  memberships: PropTypes.array,
  timeBaseRates: PropTypes.array,
  withoutTitle: PropTypes.bool
};

RateCardsFormTable.defaultProps = {
  withoutTitle: false
};