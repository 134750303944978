import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Text from 'antd/lib/typography/Text';
import TableContent from '../templates/TableContent';
import Lozenges from '../atoms/Lozenges';
import { Checkbox, Col, Row, Menu, Dropdown } from 'antd';
import IconButton from '../atoms/IconButton';
import SvgMoreOutline from '../atoms/SvgMoreOutline';
import { CloseCircleOutlined, InfoCircleOutlined, MailOutlined, PrinterOutlined } from '@ant-design/icons';

const RaincheckList = ({ onClickMenu, data, setSelectedData, selectedData, loading }) => {
  const contenxMenu = (visible) => (
    <Menu>
      <Menu.Item
        key="0"
        icon={<InfoCircleOutlined />}
        className="second-menu-item"
        onClick={() => onClickMenu('detail', visible)}
      >
        Raincheck details
      </Menu.Item>
      <Menu.Item
        key="1"
        icon={<PrinterOutlined />}
        className="second-menu-item"
        onClick={() => onClickMenu('print', visible)}
      >
        Print Raincheck
      </Menu.Item>
      <Menu.Item
        key="2"
        icon={<MailOutlined />}
        className="second-menu-item"
        onClick={() => onClickMenu('send', visible)}
      >
        Send rainchek
      </Menu.Item>
      {visible.status !== 'void' && (
        <Menu.Item
          key="3"
          className="second-menu-void"
          icon={<CloseCircleOutlined />}
          onClick={() => onClickMenu('void', visible)}
        >
          Void raincheck
        </Menu.Item>
      )}
    </Menu>
  );

  const columns = useMemo(() => {
    return [
      {
        title: 'Customer & date issues',
        dataIndex: 'name',
        width: 300,
        render: (text, record) => (
          <Row align="middle" gutter={24}>
            <Col>
              <Checkbox
                checked={selectedData.find(item => item.key === record.key)}
                onChange={(e) => {
                  if (e.target.checked) {
                    setSelectedData((selected) => {
                      const newData = [...selected];
                      newData.push(record);
                      return newData;
                    });
                  } else {
                    setSelectedData((selected) => {
                      const newData = selected.filter(item => item.key !== record.key);
                      return newData;
                    });
                  }
                }} />
            </Col>
            <Col>
              <Text strong type="primary">{text}</Text>
              <div>
                <Text style={{ fontSize: 12 }}>{record.time}</Text>
              </div>
            </Col>
          </Row>
        ),
      },
      {
        title: 'Status',
        dataIndex: 'status',
        align: 'center',
        render: text => <Row justify="center"><Lozenges status={text} /></Row>,
      },
      {
        title: 'Code',
        dataIndex: 'code',
        render: text => <Text type="primary" >{text}</Text>,
      },
      {
        title: 'Expiry date',
        dataIndex: 'expired',
        render: text => <Text type="primary">{text}</Text>,
      },
      {
        title: 'Amount',
        dataIndex: 'amount',
        render: text => <Text type="primary" >{text}</Text>,
      },
      {
        title: '',
        dataIndex: 'action',
        align: 'right',
        render: (text, record) => (
          <Dropdown trigger={['hover', 'click']} overlay={contenxMenu(record)}>
            <div><IconButton iconSvg={SvgMoreOutline} iconColor="primary" /></div>
          </Dropdown>
        ),
      },
    ];
  }, [setSelectedData, selectedData]);

  return (
    <TableContent
      header={false}
      data={data}
      columns={columns}
      loading={loading}
    />
  );
};

RaincheckList.propsTypes = {
  data: PropTypes.array,
  setSelectedData: PropTypes.func,
  selectedData: PropTypes.array,
  onClickMenu: PropTypes.func,
};

export default RaincheckList;