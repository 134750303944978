/* eslint-disable max-len */
import React from 'react';

function SvgMenu() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="none"
      viewBox="0 0 18 10"
    >
      <path
        fill="currentColor"
        d="M1.5 1.667h15A.833.833 0 1016.5 0h-15a.833.833 0 000 1.667zm15 6.666h-15A.833.833 0 001.5 10h15a.833.833 0 100-1.667zm0-4.166h-15a.833.833 0 100 1.666h15a.833.833 0 100-1.666z"
      />
    </svg>
  );
}

export default SvgMenu;
