import React from 'react';

function SvgIllustration() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="408"
      height="428"
      fill="none"
      viewBox="0 0 408 428"
    >
      <g clipPath="url(#clip0)">
        <path
          fill="#8F8F8F"
          d="M284.592 52.49l-.782.821 53.635 51.064.782-.821-53.635-51.064z"
        ></path>
        <path
          fill="#8F8F8F"
          d="M338.312 98.017l-1.132.069.342 5.578-5.59-.067-.012 1.134 6.807.081-.415-6.795zM290.117 52.133l-.012 1.134-5.589-.067.342 5.579-1.133.068-.414-6.795 6.806.081zM311.018 84.667a6.234 6.234 0 10.001-12.469 6.234 6.234 0 00-.001 12.47zM124.542 52.49l-53.635 51.064.782.821 53.635-51.064-.782-.82z"
        ></path>
        <path
          fill="#8F8F8F"
          d="M70.406 104.812l6.808-.081-.013-1.134-5.589.067.342-5.578-1.132-.069-.416 6.795zM125.823 52.052l-.414 6.795-1.133-.068.341-5.579-5.589.067-.012-1.134 6.807-.081zM98.115 84.667a6.235 6.235 0 100-12.47 6.235 6.235 0 000 12.47z"
        ></path>
        <path
          fill="#5C5C5C"
          d="M251.042 129.594h-94.084a5.1 5.1 0 01-3.605-1.496 5.1 5.1 0 01-1.496-3.605V5.471A5.105 5.105 0 01156.958.37h94.084a5.109 5.109 0 015.101 5.1v119.023a5.103 5.103 0 01-5.101 5.101zM156.958 1.504a3.97 3.97 0 00-3.968 3.967v119.022a3.973 3.973 0 003.968 3.968h94.084a3.969 3.969 0 002.804-1.164 3.969 3.969 0 001.164-2.804V5.471a3.97 3.97 0 00-3.968-3.968h-94.084z"
        ></path>
        <path
          fill="#0025B8"
          d="M196.174 57.58h-20.71a2.552 2.552 0 01-2.551-2.55V30.591a2.555 2.555 0 012.551-2.55h20.71a2.552 2.552 0 012.55 2.55v24.44a2.555 2.555 0 01-2.55 2.55z"
        ></path>
        <path
          fill="#B80006"
          d="M236.415 68.916h-20.71a2.552 2.552 0 01-2.551-2.55v-24.44a2.555 2.555 0 012.551-2.55h20.71a2.552 2.552 0 012.55 2.55v24.44a2.555 2.555 0 01-2.55 2.55z"
        ></path>
        <path
          fill="#06884A"
          d="M204.108 103.49h-20.71a2.554 2.554 0 01-2.55-2.551V76.5a2.555 2.555 0 012.55-2.55h20.71a2.552 2.552 0 012.551 2.55v24.439a2.556 2.556 0 01-2.551 2.551z"
        ></path>
        <path
          fill="#5C5C5C"
          d="M402.899 251.979h-94.085a5.105 5.105 0 01-5.1-5.101V127.855a5.105 5.105 0 015.1-5.101h94.085a5.11 5.11 0 015.101 5.101v119.023a5.106 5.106 0 01-5.101 5.101zm-94.085-128.091a3.972 3.972 0 00-3.967 3.967v119.023a3.974 3.974 0 003.967 3.967h94.085a3.97 3.97 0 003.967-3.967V127.855a3.97 3.97 0 00-3.967-3.967h-94.085z"
        ></path>
        <path
          fill="#5C5C5C"
          d="M372.01 149.109h-32.306a2.266 2.266 0 110-4.534h32.306a2.268 2.268 0 010 4.534z"
        ></path>
        <path
          fill="#C2C2C2"
          d="M387.88 170.647h-64.046a2.275 2.275 0 01-1.606-.663 2.27 2.27 0 010-3.209 2.271 2.271 0 011.606-.663h64.046a2.266 2.266 0 012.098 1.399 2.268 2.268 0 01-2.098 3.136zM387.88 182.549h-64.046a2.275 2.275 0 01-1.606-.663 2.267 2.267 0 011.606-3.871h64.046a2.277 2.277 0 012.098 1.398 2.276 2.276 0 010 1.737 2.267 2.267 0 01-2.098 1.399zM387.88 194.451h-64.046a2.274 2.274 0 01-1.606-.662 2.27 2.27 0 01.737-3.701c.276-.113.571-.172.869-.171h64.046a2.265 2.265 0 012.098 3.136 2.277 2.277 0 01-2.098 1.398zM387.88 206.353h-64.046a2.255 2.255 0 01-1.606-.662 2.27 2.27 0 010-3.209 2.275 2.275 0 011.606-.663h64.046a2.266 2.266 0 012.098 1.399 2.267 2.267 0 01-2.098 3.135zM387.88 218.256h-64.046a2.275 2.275 0 01-1.606-.663 2.27 2.27 0 010-3.209 2.287 2.287 0 011.606-.663h64.046a2.266 2.266 0 012.098 1.399 2.272 2.272 0 010 1.737 2.289 2.289 0 01-.493.736 2.266 2.266 0 01-1.605.663zM387.88 230.158h-64.046a2.274 2.274 0 01-2.271-2.267 2.276 2.276 0 01.665-1.605 2.255 2.255 0 011.606-.662h64.046a2.277 2.277 0 012.098 1.398 2.276 2.276 0 010 1.737 2.267 2.267 0 01-2.098 1.399z"
        ></path>
        <path
          fill="#5C5C5C"
          d="M99.186 251.979H5.1A5.11 5.11 0 010 246.878V127.855a5.106 5.106 0 015.101-5.101h94.085a5.105 5.105 0 015.1 5.101v119.023a5.105 5.105 0 01-5.1 5.101zM5.1 123.888a3.97 3.97 0 00-3.967 3.967v119.023a3.972 3.972 0 003.967 3.967h94.085a3.972 3.972 0 003.967-3.967V127.855a3.974 3.974 0 00-3.968-3.967H5.102z"
        ></path>
        <path
          fill="#5C5C5C"
          d="M84.264 150.855H55.925a2.263 2.263 0 01-2.267-2.267 2.267 2.267 0 012.267-2.267h28.34a2.268 2.268 0 010 4.534zM84.264 162.758H55.925a2.268 2.268 0 010-4.534h28.34a2.263 2.263 0 012.266 2.267 2.267 2.267 0 01-2.267 2.267z"
        ></path>
        <path
          fill="#B84300"
          d="M41.385 169.535h-20.71a2.555 2.555 0 01-2.55-2.551v-24.439a2.552 2.552 0 012.55-2.55h20.71a2.555 2.555 0 012.55 2.55v24.439a2.553 2.553 0 01-2.55 2.551z"
        ></path>
        <path
          fill="#C2C2C2"
          d="M84.068 187.129H20.022a2.271 2.271 0 01-1.605-.663 2.255 2.255 0 01-.666-1.604 2.274 2.274 0 01.666-1.605 2.28 2.28 0 011.605-.662h64.046a2.27 2.27 0 012.098 1.398 2.272 2.272 0 01-.493 2.473 2.267 2.267 0 01-1.605.663zM84.068 199.031H20.022a2.252 2.252 0 01-1.605-.662 2.276 2.276 0 01-.666-1.605 2.265 2.265 0 012.271-2.267h64.046a2.272 2.272 0 012.27 2.267 2.272 2.272 0 01-1.401 2.096c-.276.114-.571.172-.87.171zM84.068 210.934H20.022a2.272 2.272 0 01-2.098-1.399 2.266 2.266 0 012.098-3.136h64.046a2.272 2.272 0 012.27 2.267 2.264 2.264 0 01-2.27 2.268zM84.068 222.836H20.022a2.272 2.272 0 01-2.098-1.399 2.265 2.265 0 012.098-3.135h64.046a2.252 2.252 0 011.605.662 2.275 2.275 0 01.666 1.605 2.263 2.263 0 01-1.402 2.095c-.276.114-.571.172-.87.172zM84.068 234.738H20.022a2.271 2.271 0 01-1.605-.663 2.255 2.255 0 01-.666-1.604 2.265 2.265 0 012.271-2.267h64.046a2.27 2.27 0 011.605.663 2.253 2.253 0 01.666 1.604 2.263 2.263 0 01-1.402 2.096 2.27 2.27 0 01-.87.171z"
        ></path>
        <path
          fill="#8F8F8F"
          d="M134.753 320.963v-24.439a2.554 2.554 0 012.551-2.55h20.71a2.554 2.554 0 012.55 2.55v24.439a2.554 2.554 0 01-2.55 2.551h-20.71a2.556 2.556 0 01-2.551-2.551z"
        ></path>
        <path
          fill="#5C5C5C"
          d="M142.213 312.825v-24.439a2.55 2.55 0 012.551-2.55h20.71a2.553 2.553 0 012.55 2.55v24.439a2.55 2.55 0 01-2.55 2.551h-20.71a2.556 2.556 0 01-2.551-2.551z"
        ></path>
        <path
          fill="#FF8589"
          d="M235.051 305.005a6.145 6.145 0 01-2.985-4.269 6.17 6.17 0 011.358-5.03l-6.419-46.922 13.372.656 2.099 45.768a6.191 6.191 0 01-3.089 10.53 6.187 6.187 0 01-4.336-.733z"
        ></path>
        <path
          fill="#C2C2C2"
          d="M242.212 250.065a2.511 2.511 0 01-1.021.722l-12.017 4.587a2.524 2.524 0 01-3.207-1.333l-10.645-23.923a9.996 9.996 0 01-.489-7.074 9.725 9.725 0 017.4-6.635 9.277 9.277 0 013.994.057 9.683 9.683 0 016.961 6.05l9.465 25.019a2.524 2.524 0 01-.441 2.53z"
        ></path>
        <path
          fill="#FF8589"
          d="M179.59 419.533h-8.315l-3.014-32.222 11.33.152-.001 32.07z"
        ></path>
        <path
          fill="#002952"
          d="M165.336 417.157h16.036v10.096H155.24a10.087 10.087 0 012.957-7.139 10.106 10.106 0 017.139-2.957z"
        ></path>
        <path
          fill="#FF8589"
          d="M218.247 419.533h-8.315l-3.955-32.071 12.271.001-.001 32.07z"
        ></path>
        <path
          fill="#002952"
          d="M203.993 417.157h16.035v10.096h-26.131a10.087 10.087 0 012.957-7.139 10.106 10.106 0 017.139-2.957zM219.292 408.122l-13.058-.966a3.053 3.053 0 01-2.823-2.897l-2.912-60.176a2.372 2.372 0 00-2.189-2.252 2.377 2.377 0 00-2.507 1.89L183.656 403a3.054 3.054 0 01-3.233 2.43l-11.805-.945a3.059 3.059 0 01-2.099-1.086 3.047 3.047 0 01-.694-2.259l10.951-109.512a3.055 3.055 0 012.409-2.683 3.049 3.049 0 011.276.005l41.645 9.052a3.062 3.062 0 012.403 3.04l-1.94 104.094a3.05 3.05 0 01-3.277 2.986z"
        ></path>
        <path
          fill="#FF8589"
          d="M207.868 200.062c9.199 0 16.657-7.457 16.657-16.657 0-9.199-7.458-16.657-16.657-16.657s-16.657 7.458-16.657 16.657c0 9.2 7.458 16.657 16.657 16.657z"
        ></path>
        <path
          fill="#C2C2C2"
          d="M182.024 300.073a14.897 14.897 0 01-4.947-11.439c0-23.94 20.723-77.427 21.372-79.096.064-.402.61-.576 3.479-2.514 2.87-1.939 7.009-2.237 12.302-.885a3.032 3.032 0 012.204 2.25l.985 1.423c.085.36.255.696.493.98.239.283.542.506.883.652 4.268 1.798 17.835 10.09 13.212 41.296-4.295 28.988-6.037 46.036-6.587 52.021a3.046 3.046 0 01-2.761 2.757c-2.093.187-5.685.436-9.963.436-9.916 0-23.517-1.338-30.672-7.881z"
        ></path>
        <path
          fill="#FF8589"
          d="M162.12 291.203a6.162 6.162 0 011.487-7.354 6.151 6.151 0 012.344-1.273l20.816-42.54 10.738 7.996-23.76 39.174a6.181 6.181 0 01-.658 4.348 6.186 6.186 0 01-7.774 2.674 6.19 6.19 0 01-3.193-3.024v-.001z"
        ></path>
        <path
          fill="#C2C2C2"
          d="M198.68 249.574a2.516 2.516 0 01-1.249.031l-12.535-2.888a2.527 2.527 0 01-1.92-2.894l4.491-25.797a9.997 9.997 0 013.536-6.146 9.726 9.726 0 019.841-1.386 9.274 9.274 0 013.286 2.273 9.693 9.693 0 012.408 8.903l-6.082 26.048a2.534 2.534 0 01-1.776 1.856z"
        ></path>
        <path
          fill="#002952"
          d="M203.968 198.455a7.172 7.172 0 01-1.925-6.366c.58-3.038.921-5.025.986-5.749.221-2.473-2.279-4.476-4.754-4.795a5.096 5.096 0 00-.243-.026c.063.062.131.125.19.181.47.442 1.055.991.916 1.558-.084.342-.396.584-.954.74-3.87 1.083-6.85.089-9.38-3.131a7.475 7.475 0 01-1.428-3.118c-.906-4.445 1.509-8.235 3.694-10.631 1.801-1.975 4.722-4.374 8.405-4.579 2.865-.16 6.271 1.488 7.29 4.459a5.53 5.53 0 014.314-2.433 9.946 9.946 0 015.533 1.539c8.077 4.627 11.548 15.648 7.58 24.068-2.496 5.296-7.904 9.239-14.113 10.289a6.868 6.868 0 01-6.111-2.006z"
        ></path>
        <path
          fill="#5C5C5C"
          d="M125.858 427.63h132.247a.679.679 0 100-1.356H125.858a.677.677 0 100 1.356z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0">
          <path
            fill="#fff"
            d="M0 0H408V427.261H0z"
            transform="translate(0 .37)"
          ></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgIllustration;
