import { PlusCircleOutlined } from '@ant-design/icons';
import { Button, Row } from 'antd';
import PropTypes from 'prop-types';
import React, { useState, useImperativeHandle, forwardRef } from 'react';
import IconButton from '../atoms/IconButton';
import SvgDelete from '../atoms/SvgDelete';
import HorizontalTextInput from '../molecules/HorizontalTextInput';

const TeeSheetHolesForm = (props, ref) => {

  const { initialHoles } = props;
  const [holes, setHoles] = useState(initialHoles?.length > 0
    ? initialHoles
    : [
      {
        name: 'Hole #1'
      },
    ]);

  useImperativeHandle(ref, () => ({
    handleSubmit: () => {
      return holes;
    }
  }));

  const handleDelete = (index) => () => {
    holes.splice(index, 1);
    setHoles([...holes]);
  };

  const handleAdd = (index) => () => {
    let name = 'Hole #';
    if (index === 0) {
      name = 'Hole #10';
    }
    holes.splice(index + 1, 0, {
      name
    });
    setHoles([...holes]);
  };

  const handleChangeName = (index) => (e) => {
    holes[index] = {
      ...holes[index],
      name: e.target.value
    };
    setHoles([...holes]);
  };

  return (
    <div>
      {holes.map((hole, index) => {
        if (index === 0) {
          return (
            <Row key={index} align="bottom">
              <HorizontalTextInput value={hole.name} onChange={handleChangeName(index)} label="Hole numbers" styleContainer={{ width: 416}} />
              <IconButton disabled onClick={handleDelete(index)} iconSvg={SvgDelete} />
              <Button onClick={handleAdd(index)} type="text" icon={<PlusCircleOutlined style={{ color: '#8F8F8F'}} />} />
            </Row>
          );
        }
        return (
          <Row key={index} align="bottom">
            <HorizontalTextInput value={hole.name} onChange={handleChangeName(index)} styleContainer={{ width: 416}}/>
            <IconButton onClick={handleDelete(index)} disabled={hole?.disabled} iconSvg={SvgDelete} />
            <Button onClick={handleAdd(index)} disabled={index + 1 < initialHoles.length} type="text" icon={<PlusCircleOutlined style={{ color: '#8F8F8F'}} />} />
          </Row>
        );
      })}
    </div>
  );
};

export default forwardRef(TeeSheetHolesForm);

TeeSheetHolesForm.propTypes = {
  onChange: PropTypes.func,
  initialHoles: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    oldName: PropTypes.string,
    disabled: PropTypes.bool
  }))
};