import Text from 'antd/lib/typography/Text';
import { useMutation, useQuery } from '@apollo/client';
import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import HorizontalTextInput from '../molecules/HorizontalTextInput';
import Modal from '../organisms/Modal';
import QueryGql from '../../constants/QueryGql';

const GolfCourseSettingsPlayer = () => {
  const history = useHistory();
  const { data: dataMaximumPlayer } = useQuery(QueryGql.GET_MAXIMUM_PLAYER);
  const maximumPlayersValue = dataMaximumPlayer?.getMaximumPlayers.data.value;
  const [maximumPlayerState, setMaximumPlayerState] = useState(maximumPlayersValue);
  const [setMaximumPlayers, {
    loading: loadingAddMaximum
  }] = useMutation(QueryGql.SET_MAXIMUM_PLAYER, {
    onCompleted: () => {
      history?.goBack();
    }
  });

  const handleChangeName = useCallback((e) => {
    const name = e.target.value;
    setMaximumPlayerState(name);
  }, []);

  const handleAdded = () => {
    setMaximumPlayers({
      variables: { setMaximumPlayersValue: parseInt(maximumPlayerState) }
    });
  };

  return (
    <Modal
      title="Maximum number of players"
      hiddenLeftFooterButton
      rightFooterButton={{
        label: 'Save changes',
        loading: loadingAddMaximum,
        state: maximumPlayerState === 0 ? 'disabled' : 'default',
        onClick: handleAdded
      }}
      bodyStyle={{ textAlign: 'right' }}
      width={540}
    >
      <HorizontalTextInput
        label="Maximum players"
        value={maximumPlayerState}
        onChange={handleChangeName}
        suffix={<Text type="secondary">players</Text>}
      />
    </Modal>
  );
};

export default GolfCourseSettingsPlayer;
