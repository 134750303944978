import React, { useMemo } from 'react';
import Modal from '../../organisms/Modal';
import Icon from '@ant-design/icons';
import Text from 'antd/lib/typography/Text';
import SvgInfo from '../../atoms/SvgInfo';
import InputText from '../../atoms/InputText';
import { useLocation, useHistory } from 'react-router-dom';
import extractQuery from 'utils/useQuery';
import { useMutation, useQuery } from '@apollo/client';
import QueryGql from 'constants/QueryGql';
import { useFormik } from 'formik';
import formatter from 'utils/formatter';
import Pathname from 'constants/Pathname';
import Loading from 'components/atoms/Loading';
import _ from 'lodash';
import useNotification from 'hooks/useNotification';
import moment from 'moment';

function CollectCancellationFee() {
  const { successCancelReservation } = useNotification();
  const history = useHistory();
  const location = useLocation();
  const queryString = extractQuery(location.search);
  const id = queryString.get('id');
  const groupId = queryString.get('groupId');
  const reason = queryString.get('reason');
  const queryCustomers = queryString.get('customers')
    ? queryString.get('customers').split(',')
    : [];

  const [cancel, { loading }] = useMutation(QueryGql.CANCEL_RESERVATION, {
    refetchQueries: [QueryGql.GET_RESERVATIONS],
  });

  const { data: dataGetReservation, loading: loadingGet } = useQuery(QueryGql.GET_RESERVATION, {
    variables: {
      getReservationId: id,
    },
    skip: !id,
    fetchPolicy: 'cache-and-network',
  });

  const data = dataGetReservation?.getReservation?.data || {};

  const { data: dataGetReservationGroup } = useQuery(QueryGql.GET_RESERVATION_GROUP, {
    variables: {
      getReservationGroupId: groupId,
    },
    skip: !groupId,
    fetchPolicy: 'cache-and-network',
  });

  const dataGroup = dataGetReservationGroup?.getReservationGroup?.data || {};

  const { customers, date, name } = useMemo(() => {
    const results = [];
    let date = '';
    let name = '';

    data?.reservation_customers?.forEach(item => {
      date = item.date;
      name += item.customer.full_name + ', ';
      results.push({
        reservationId: data?.id,
        id: item.customer_id,
        checked: false,
        name: item.customer?.full_name,
        amountOrigin: item.amount,
      });
    });

    dataGroup?.reservations?.forEach(reservation => {
      reservation?.reservation_customers?.forEach(item => {
        date = item.date;
        name += item.customer.full_name + ', ';
        results.push({
          reservationId: reservation?.id,
          id: item.customer_id,
          checked: false,
          name: item.customer?.full_name,
          amountOrigin: item.amount,
        });
      });
    });

    return {
      name,
      date,
      customers: results.filter(item => queryCustomers.includes(item.id)),
    };
  }, [dataGroup, data]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      customers,
    },
  });

  const customerSelected = formik.values.customers.filter(item => item.amount > 0);
  const activeButton = customerSelected.length === 0;

  const grouping = _.chain(formik.values.customers)
    .groupBy('reservationId')
    .map((value, key) => ({ reservationId: key, customers: value }))
    .value();

  const handleCancel = async () => {
    for (let index = 0; index < grouping.length; index++) {
      const element = grouping[index];
      await cancel({
        variables: {
          data: {
            reservation_id: element.reservationId,
            cancellation_reason: reason,
            customers: element.customers.map(item => ({
              customer_id: item.id,
              cancellation_fee: item.amount || 0,
            })),
          },
        },
      });
    }

    successCancelReservation({
      description: `Reservation ${name} ${moment(date).format('LL')} has been cancelled.`,
    });
    history.push(`${Pathname.TEE_SHEET}`);
  };

  return (
    <Modal
      title='Collect cancellation fee'
      width={600}
      rightFooterButton={{
        label: 'Collect cancellation fee',
        state: activeButton ? 'disabled' : 'default',
        loading,
        onClick: handleCancel,
      }}
      hiddenLeftFooterButton
      rightFooterButtonExtra={{
        label: 'Don’t collect fee',
        type: 'outline',
        loading,
        onClick: handleCancel,
      }}>
      <div style={{ minHeight: '82px' }}>
        {loadingGet && (
          <div
            style={{
              height: '200px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            <Loading />
          </div>
        )}
        {formik.values.customers?.map((item, index) => (
          <div key={item.key} style={{ position: 'relative', marginTop: '12px' }}>
            <InputText
              prefix={<Text type='secondary'>Rp.</Text>}
              size='large'
              type='numner'
              name={`customers[${index}].amount`}
              label={`Cancellation fee amount ${customers.length > 1 ? 'for ' + item.name : ''}`}
              onChange={formik.handleChange}
              value={item.amount}
              suffix={
                <div
                  style={{
                    color: '#1F4BFF',
                    fontSize: '14px',
                    fontWeight: 'bold',
                    display: 'flex',
                  }}>
                  <div
                    onClick={() =>
                      formik.setFieldValue(
                        `customers[${index}].amount`,
                        formatter.roundToTwoDigitsAfterComma(item.amountOrigin / 2),
                      )
                    }
                    style={{ cursor: 'pointer' }}>
                    50%
                  </div>
                  <div
                    onClick={() =>
                      formik.setFieldValue(`customers[${index}].amount`, item.amountOrigin)
                    }
                    style={{ marginLeft: '12px', cursor: 'pointer' }}>
                    100%
                  </div>
                </div>
              }
            />
          </div>
        ))}
      </div>
      <div style={{ marginTop: '8px', display: 'flex', alignItems: 'center' }}>
        <div>
          <Icon style={{ color: '#8F8F8F', transform: 'scale(1.2)' }} component={SvgInfo} />
        </div>
        <div style={{ marginLeft: '9.33px' }}>
          <Text style={{ fontSize: '14px', color: '#8F8F8F' }}>
            Cancellation fee collected from customers will be marked as a revenue. Make sure money
            has been collected from customer for this cancellation fee if they are to be charged.
          </Text>
        </div>
      </div>
    </Modal>
  );
}

export default CollectCancellationFee;
