import React from 'react';
import { Checkbox } from 'antd';
import { Avatar } from 'antd';
import { UserOutlined } from '@ant-design/icons';

function ListItem({ customers, setFieldValue }) {
  
  return (
    <div style={{ marginTop: '16px' }}>
      {customers?.map((item, index) => {
        return (
          <div
            key={item.id}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              border: '#F5F5F5 solid 2px',
              borderRadius: '8px',
              padding: '24px',
              marginTop: '4px',
            }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Checkbox
                checked={item.checked}
                onChange={(e) => setFieldValue(`customers[${index}].checked`, e.target.checked)}  
              />
              <div style={{ marginLeft: '16px' }}>
                <Avatar size={39} icon={<UserOutlined />} />
              </div>

              <p
                style={{
                  marginBottom: '0px',
                  marginLeft: '16px',
                  fontSize: '16px',
                  fontWeight: 700,
                }}>
                {item.name}
              </p>
            </div>
            <p style={{ marginBottom: '0px', fontSize: '14px', color: '#5C5C5C' }}>
              {item.price}
            </p>
          </div>
        );
      })}
    </div>
  );
}

export default ListItem;