import { Col, Divider, Row, Timeline } from 'antd';
import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import numeral from 'numeral';
import Card from '../atoms/Card';
import TeeSheetCustomerCheckInItem from '../molecules/TeeSheetCustomerCheckInItem';
import './TeeSheetCustomersCheckInDetail.less';
import Text from 'antd/lib/typography/Text';
import Lozenges from '../atoms/Lozenges';

const TeeSheetCustomersCheckInDetail = React.forwardRef((props, ref) => {
  const { data, type, total, status, operators, onChangeStatus, mode } = props;

  let dataForCheckin = useMemo(() => {
    if (!data) {
      return [];
    }
    return data.map(x => ({
      id: x.id,
      status: x.status,
      bag_id: x.bag_id,
    }));
  }, data);

  const handleChangeStatus = useCallback((id) => (values) => {
    const indexId = dataForCheckin.findIndex(x => x.id === id);
    if (indexId > -1) {
      dataForCheckin[indexId] = {
        id,
        ...values
      };
    } else {
      dataForCheckin = [
        ...dataForCheckin,
        {
          id,
          ...values
        }
      ];
    }
    onChangeStatus(dataForCheckin);
  }, [dataForCheckin]);

  return (
    <div ref={ref} style={{padding: mode === 'print' ? 10 : 0}} id="tee-sheet-detail-reservation">
      {mode === 'default' && (
        <div className="tooltip">
          <Text style={{ color: 'white' }}>{'Change the item status by clicking on the highlighted button on the top right (i.e. Reserved > Checked-in) and fill in bag tag ID if possible.'}</Text>
          <div className="carret" />
        </div>
      )}
      <Card>
        {type === 'group'
          ? data?.map((item, index) => (
            <div key={index.toString()}>
              <TeeSheetCustomerCheckInItem
                customer={item.customer}
                reservations={item.reservations}
                onChangeStatus={handleChangeStatus(item.id)}
                status={item.status}
                bagId={item.bag_id}
              />
              {index !== data.length - 1 && <Divider />}
            </div>
          ))
          : data?.[0]
            ? (
              <TeeSheetCustomerCheckInItem
                customer={data?.[0]?.customer}
                reservations={data?.[0]?.reservations}
                onChangeStatus={handleChangeStatus(data?.[0]?.id)}
                status={data?.[0]?.status}
                bagId={data?.[0]?.bag_id}
              />
            )
            : null}
      </Card>
      {(total >= 0 && status) ? (
        <Card className="card-item">
          <Row justify="space-between" align="middle">
            <Col>
              <Text className="text-total">Total</Text>
            </Col>
            <Col>
              <Row wrap={false}>
                <Lozenges status={status} />
                <Text level={4} className="price-total">{numeral(total).format('$0,0[.]00')}</Text>
              </Row>
            </Col>
          </Row>
        </Card>
      ): null}
      {((mode === 'default' || mode === 'reservation-group') && type === 'group') && (
        <Card className="card-item" bodyStyle={{ paddingBottom: 10 }}>
          <Timeline>
            {operators.map(operator => (
              <Timeline.Item key={operator.id} color="gray">
                <Row justify="space-between" align="middle">
                  <Col>
                    <Text type="secondary" className="footer-text-timeline">Reservation made by {operator.full_name} (Operator)</Text>
                  </Col>
                  <Col>
                    <Text type="secondary" className="footer-text-timeline">{moment(operator.created_at).format('hh:mm A, DD/MM/YYYY')}</Text>
                  </Col>
                </Row>
              </Timeline.Item>
            ))}
          </Timeline>
        </Card>
      )}
    </div>
  );
});

export default TeeSheetCustomersCheckInDetail;

TeeSheetCustomersCheckInDetail.propTypes = {
  type: PropTypes.oneOf(['single', 'group']),
  data: PropTypes.arrayOf(PropTypes.shape({
    customer: PropTypes.shape({
      name: PropTypes.string
    }).isRequired,
    reservations: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string,
      price: PropTypes.string,
      status: PropTypes.string
    })).isRequired,
  })),
  total: PropTypes.number.isRequired,
  status: PropTypes.string.isRequired,
  operators: PropTypes.array,
  onChangeStatus: PropTypes.func,
  mode: PropTypes.oneOf(['default', 'print', 'reservation-group'])
};

TeeSheetCustomersCheckInDetail.defaultProps = {
  operators: [],
  mode: 'default'
};

