import Text from 'antd/lib/typography/Text';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Tooltip } from 'antd';
import Icon from '@ant-design/icons';
import './HorizontalTimePickerRange.less';
import TimePicker from '../atoms/TimePicker';
import SvgInfo from '../atoms/SvgInfo';

const HorizontalTimePickerRange = (props) => {
  const { label, labelInfo, onChangeFrom, onChangeTo, valueFrom, valueTo, disabledHours } = props;
  const [blockTime, setBlockTime] = useState([]);
  const startTime = moment(valueFrom, 'h:mm:ss A');
  const blockTimeEndDate = () => {
    const arrBlock = [];
    for (let i = 0; i < startTime.hours(); i++) {
      arrBlock.push(i);
    }
    setBlockTime(arrBlock);
  };

  useEffect(() => {
    blockTimeEndDate();
  }, []);
  
  return (
    <div className="antd-horizontal-time-picker-range">
      {label && (
        <div className="container-label">
          <Text className="label">{label}</Text>
          {labelInfo && (
            <Tooltip placement="right" title={labelInfo}>
              <Icon component={SvgInfo} className="info" />
            </Tooltip>
          )}
        </div>
      )}
      <div className="time-picker-range">
        <TimePicker
          onSelect={onChangeFrom}
          disabledHours={() => disabledHours}
          onChange={blockTimeEndDate}
          value={valueFrom}
          style={{ marginRight: 4 }}
          allowClear={false}
        />
        <TimePicker
          disabledHours={() => [...disabledHours, ...blockTime]}
          value={valueTo}
          onSelect={onChangeTo}
          allowClear={false}
        />
      </div>
    </div>
  );
};

export default HorizontalTimePickerRange;

HorizontalTimePickerRange.propTypes = {
  label: PropTypes.string.isRequired,
  labelInfo: PropTypes.string,
  onChangeFrom: PropTypes.func,
  valueFrom: PropTypes.string,
  onChangeTo: PropTypes.func,
  valueTo: PropTypes.string,
  disabledHours: PropTypes.arrayOf(PropTypes.number)
};

HorizontalTimePickerRange.defaultProps = {
  onChangeFrom: null,
  valueFrom: '',
  onChangeTo: null,
  valueTo: '',
  disabledHours: []
};

