import React from 'react';
import { Col, Row } from 'antd';
import PropTypes from 'prop-types';
import ReportCard from '../molecules/ReportCard';

const ReservationCards = ({
  reservation,
  // reserved,
  // checkIn,
  // cancelation,
  // noShow,
  averageReservation
}) => {
  const items = [
    {
      title: 'Total reservation',
      value: `${reservation} reservations`
    },
    // {
    //   title: 'Reserved',
    //   value: `${reserved} reserved`
    // },
    // {
    //   title: 'Check-ins',
    //   value: `${checkIn} check-ins`
    // },
    // {
    //   title: 'Cancellations',
    //   value: `${cancelation} cancelations`
    // },
    // {
    //   title: 'No-show',
    //   value: `${noShow} no-show`
    // },
    {
      title: 'Average reservation per hour',
      value: `${averageReservation % 1 === 0 ? averageReservation : Number(averageReservation).toFixed(2)} reservations`,
    }
  ];
  return(
    <div id="reservation-cards">
      <Row gutter={[16, 16]}>
        {
          items.map(item => (
            <Col
              key={item.title}
              span={8}
            >
              <ReportCard
                title={item.title}
                value={item.value}
              />
            </Col>
          ))
        }
      </Row>
    </div>
  );
};

ReservationCards.propTypes = {
  reservation: PropTypes.number,
  reserved: PropTypes.number,
  checkIn: PropTypes.number,
  cancelation: PropTypes.number,
  noShow: PropTypes.number,
  averageReservation: PropTypes.number,
};

ReservationCards.defaultProps = {
  reservation: 0,
  reserved: 0,
  checkIn: 0,
  cancelation: 0,
  noShow: 0,
  averageReservation: 0,
};

export default ReservationCards;