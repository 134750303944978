import React from 'react';
import PropTypes from 'prop-types';
import { Avatar as AntdAvatar } from 'antd';

function hashCode(str) { // java String#hashCode
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  return hash;
}

function intToRGB(i){
  const c = (i & 0x00FFFFFF)
    .toString(16)
    .toUpperCase();

  return '00000'.substring(0, 6 - c.length) + c;
}

const Avatar = (props) => {
  const { title, img } = props;
  let titleAlias;
  let randomColor;
  
  if (!img) {
    titleAlias = title?.split(' ');
    titleAlias = `${titleAlias?.[0]?.[0]?.toUpperCase() || ''}${titleAlias?.[1]?.[0]?.toUpperCase() || ''}`;
    randomColor = `#${intToRGB(hashCode(title || ''))}`;
  }

  return (
    <AntdAvatar {...props} style={{ backgroundColor: randomColor, fontWeight: 600, ...props.style}} src={img}>
      {titleAlias}
    </AntdAvatar>
  );
};

export default Avatar;

Avatar.propTypes = {
  title: PropTypes.string.isRequired,
  img: PropTypes.string
};