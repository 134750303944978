/* eslint-disable max-len */
import React from 'react';

function SvgRightArrow() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="6"
      height="10"
      fill="none"
      viewBox="0 0 6 10"
    >
      <path
        fill="currentColor"
        d="M5.36 4.526L1.587.76a.667.667 0 10-.947.94l3.3 3.333-3.3 3.3A.667.667 0 00.857 9.42a.667.667 0 00.513 0 .667.667 0 00.217-.147L5.36 5.506a.667.667 0 000-.98z"
      ></path>
    </svg>
  );
}

export default SvgRightArrow;
