import React from 'react';
import { Col, Row } from 'antd';
import Text from 'antd/lib/typography/Text';
import { Formik } from 'formik';
import {useHistory} from 'react-router-dom';
import { useMutation } from '@apollo/client';
import * as Yup from 'yup';
import AuthWrapper from '../templates/AuthWrapper';
import SvgLogo from '../atoms/SvgLogo';
import InputText from '../atoms/InputText';
import Button from '../atoms/Button';
import QueryGql from '../../constants/QueryGql';
import './SignIn.less';
import { authToLocal } from '../../utils/function';

const LoginSchemaValidations = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Required'),
  password: Yup.string().required('Required'),
});

const SignIn = () => {
  const history = useHistory();
  const [login, {
    loading: loadingLogin
  }] = useMutation(QueryGql.LOGIN, {
    onCompleted: (data) => {
      authToLocal(data);
      history.replace('/splash');
    },
    onError: () => {}
  });

  return (
    <AuthWrapper>
      <span className="logo-wrapper-login">
        <SvgLogo />
      </span>
      <Formik
        initialValues={{
          email: '',
          password: ''
        }}
        validationSchema={LoginSchemaValidations}
        onSubmit={values => {
          localStorage.clear();
          login({
            variables: {
              loginUsername: values?.email,
              loginPassword: values?.password
            }
          });
        }}
      >
        {({
          handleSubmit,
          handleChange,
          values,
          errors
        }) => {
          return (
            <form id="login-form" onSubmit={handleSubmit}>
              <div className="form-input-text">
                <InputText
                  size="large"
                  name="email"
                  errorMessage={errors?.email}
                  value={values?.email}
                  placeholder="Email"
                  onChange={handleChange('email')}
                />
              </div>
              <div className="form-input-text">
                <InputText
                  size="large"
                  name="password"
                  value={values?.password}
                  errorMessage={errors?.password}
                  type="password"
                  placeholder="Password"
                  onChange={handleChange('password')}
                />
              </div>
              <div className="forgot-account-wrapper form-input-text">
                <Text>Lupa Kata Sandi?</Text>
              </div>
              <Button
                loading={loadingLogin}
                className="button-submit-login"
                htmlType="submit"
              >
                Log in
              </Button>
            </form>
          );
        }}
      </Formik>
      <Row justify="space-between">
        <Col>
          <Text>Belum punya akun?</Text>
        </Col>
        <Col>
          <div className="forgot-account-wrapper">
            <Text>Demo gratis sekarang</Text>
          </div>
        </Col>
      </Row>
    </AuthWrapper >
  );
};

export default SignIn;