import { useQuery } from '@apollo/client';
import { useMemo } from 'react';
import QueryGql from '../constants/QueryGql';

export default function useSubscriptionType () {

  const { data } = useQuery(
    QueryGql.GET_SUBSCRIPTION_TYPE,
  );
  const subscriptionType = data?.getAllSubscriptionType.data || [];
  const optionsSubscriptionType = useMemo(() => {
    const items = [];

    subscriptionType.forEach(item => {
      items.push({
        key: item.id,
        value: item.id,
        label: `${item.name}`
      });
    });
    return items;
  }, [subscriptionType]);

  return {
    optionsSubscriptionType,
    subscriptionType
  };
}