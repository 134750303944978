import React, { useState, useCallback, useRef } from 'react';
import { Button, notification } from 'antd';
import { useLocation, useHistory } from 'react-router-dom';
import useQueryString from 'utils/useQuery';
import Modal from 'components/organisms/Modal';
import CustomerForm from 'components/templates/CustomerForm';
import { useMutation, useQuery } from '@apollo/client';
import Query from './Query';
import uploadFile from 'utils/uploadFile';
import QueryGql from 'constants/QueryGql';

const CustomerListForm = () => {
  const location = useLocation();
  const history = useHistory();
  const queryString = useQueryString(location.search);
  const refCustomerForm = useRef();
  const act = queryString.get('act');
  const id = queryString.get('id');

  const { data: dataCustomer } = useQuery(QueryGql.GET_CUSTOMER, {
    variables: { id },
    skip: !id,
  });

  const [photo, setPhoto] = useState(null);

  const [createCustomer] = useMutation(Query.CREATE_CREATE_CUSTOMER, {
    onCompleted: () => {
      notification.success({
        message: 'Success',
        description: 'successfully create new customer',
        btn: (
          <Button
            type='text'
            className='notification-button-dismiss-success'
            onClick={() => notification.close('create-customer')}>
            Dismiss
          </Button>
        ),
        key: 'create-customer',
        closeIcon: <div />,
      });
      history.goBack();
    },
    refetchQueries: [QueryGql.SEARCH_CUSTOMER],
  });

  const [updateCustomer] = useMutation(QueryGql.UPDATE_CUSTOMER, {
    onCompleted: () => {
      notification.success({
        message: 'Success',
        description: 'successfully update customer',
        btn: (
          <Button
            type='text'
            className='notification-button-dismiss-success'
            onClick={() => notification.close('update-customer')}>
            Dismiss
          </Button>
        ),
        key: 'update-customer',
        closeIcon: <div />,
      });
      history.goBack();
    },
    refetchQueries: [QueryGql.SEARCH_CUSTOMER, QueryGql.GET_CUSTOMER],
  });

  const onSubmit = useCallback(async () => {
    const res = refCustomerForm?.current?.handleSubmit();
    if (photo) {
      const upload = await uploadFile(photo);
      if (upload?.data?.uploadFile?.data) {
        res.image_url = upload.data.uploadFile.data;
      }
    }

    if (res.prefix_phone_number && res.prefix_phone_number.includes('+', '')) {
      res.prefix_phone_number = res.prefix_phone_number.replace('+', '');
    }

    if (act === 'edit') {
      delete res.subscription_memberships;
      delete res.__typename;
      delete res.is_active;
      delete res.created_at;
      delete res.updated_at;
      delete res.deleted_at;
      delete res.location_id;

      updateCustomer({
        variables: {
          data: {
            id,
            ...res,
          },
        },
      });
    } else {
      createCustomer({
        variables: {
          data: {
            ...res,
          },
        },
      });
    }
  }, [photo]);

  return (
    <Modal
      title={act === 'add' ? 'Add new customer' : 'Edit customer'}
      hiddenLeftFooterButton={act === 'edit'}
      leftFooterButton={{
        label: 'Save & add another',
        type: 'outline',
      }}
      rightFooterButton={{
        label: 'Save customer',
        onClick: onSubmit,
      }}
      width={557}>
      <CustomerForm
        ref={refCustomerForm}
        setPhoto={setPhoto}
        photo={photo}
        initialValues={dataCustomer?.getCustomer?.data ? {
          ...dataCustomer?.getCustomer?.data,
          prefix_phone_number: dataCustomer?.getCustomer?.data?.prefix_phone_number ? '+' + dataCustomer?.getCustomer?.data?.prefix_phone_number : '',
          membership_id: dataCustomer?.getCustomer?.data?.subscription_memberships?.[0]?.membership_id
        } : null}
      />
    </Modal>
  );
};

export default CustomerListForm;
