import { Col, DatePicker, Row, Button as AntdButton } from 'antd';
import React, { useState, useEffect, useMemo, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useReactToPrint } from 'react-to-print';
import moment from 'moment';
import Icon from '@ant-design/icons';
import Title from 'antd/lib/typography/Title';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { useSubscription } from '@apollo/client';

import Loading from '../atoms/Loading';
import Button from '../atoms/Button';
import TeeSheetAddButton from '../molecules/TeeSheetAddButton';
import TeeSheetDivider from '../molecules/TeeSheetDivider';
import HeaderContent from '../molecules/HeaderContent';
import MultipleSelect from '../molecules/MultipleSelect';
import SingleSelect from '../molecules/SingleSelect';
import './TeeSheet.less';
import Pathname from '../../constants/Pathname';
import TeeSheetFilledTime from '../molecules/TeeSheetFilledTime';
import InputText from '../atoms/InputText';
import SvgSearch from '../atoms/SvgSearch';
import IconButton from '../atoms/IconButton';
import SvgClose from '../atoms/SvgClose';
import config from 'constants/config';
import QueryGql from 'constants/QueryGql';

const TeeSheet = props => {
  moment.updateLocale('en', {
    weekdaysMin: ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'],
  });
  const {
    courses,
    onChangeFilter,
    teeTimes,
    reservationStatus,
    loading,
    teeTimeBlocks,
    onCancelReservation,
    onCancelReservationGroup,
    onTeeTimeUnblock,
    onTeeTimeDelete,
    refetch
  } = props;

  const refTeeSheet = useRef();
  const history = useHistory();
  const [visibleSelectTeeTimes, setVisibleSelectTeeTimes] = useState(false);
  const [date, setDate] = useState(moment());
  const [course, setCourse] = useState({ label: '', value: '' });
  const [statuses, setStatuses] = useState(['no_show', 'reserved', 'checked_in', 'paid']);
  const [teeTimeSelection, setTeeTimeSelection] = useState([]);
  const [data, setData] = useState([]);
  const [groupHover, setGroupHover] = useState();
  const [visibleSearch, setVisibleSearch] = useState(false);

  const optionsCourses = useMemo(
    () =>
      courses.map(item => ({
        key: item.id,
        value: item.id,
        label: `${item.name} (${item.holes})`,
      })),
    [courses],
  );

  const optionsTeeTime = useMemo(() => {
    const data = [];
    teeTimes.forEach(teeTime => {
      teeTime.forEach((item, index) => {
        if (index !== teeTime.length - 1) {
          data.push({
            id: item.id,
            label: item.label,
            value: moment(item.label, 'hh:mm A').format('HH:mm:ss'),
          });
        }
      });
    });
    return data;
  }, [teeTimes]);

  useEffect(() => {
    if (optionsCourses.length > 0) {
      setCourse(optionsCourses[0]);
      onChangeFilter({
        date: moment(date).format('YYYY-MM-DD'),
        course_id: optionsCourses[0].value,
        statuses: statuses,
      });
    }
  }, [optionsCourses]);

  useEffect(() => {
    setData(teeTimes);
  }, [teeTimes]);

  const { data: dataSubscribe } = useSubscription(QueryGql.SUBSCRIBE_RESERVATION, {
    variables: {
      data: {
        location_id: config.locationid,
        date: date,
        course_id: course?.value
      },
    },
  });

  useEffect(() => {
    if (dataSubscribe?.getReservations) {
      refetch();
    }
  }, [dataSubscribe]);

  const handleTeeTimeSelection = teeTime => {
    if (teeTime.selected === true) {
      setTeeTimeSelection(prevState => {
        return [...prevState, teeTime];
      });
    } else {
      setTeeTimeSelection(prevState => {
        return prevState.filter(x => x.dateTime !== teeTime.dateTime);
      });
    }
  };

  const groupBy = (xs, key) => {
    return xs.reduce((rv, x) => {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  };

  const handleTeeTimeBlock = () => {
    const teeTimeBlockByCalendar = groupBy(teeTimeSelection, 'calendarId');
    const varTeeTimeBlocks = [];
    Object.keys(teeTimeBlockByCalendar).forEach(key => {
      varTeeTimeBlocks.push({
        calendarId: key,
        blockedTimes: teeTimeBlockByCalendar[key].map(x => ({
          time: moment(x.dateTime, 'YYYY-MM-DDTHH:mm:ss.SSS[Z]').format('HH:mm:ss'),
        })),
        deletedTimes: [],
      });
    });
    history.push(
      Pathname.TEE_SHEET +
        '/' +
        Pathname.TEE_SHEET_BLOCK_TEE_TIMES +
        `?date=${date.format('YYYY-MM-DD')}`,
      {
        background: true,
        teeTimeBlocks: varTeeTimeBlocks,
      },
    );
    setTeeTimeSelection([]);
  };

  const handleTeeTimeUnblock = () => {
    const teeTimeBlockByCalendar = groupBy(teeTimeSelection, 'calendarId');
    teeTimeBlocks.forEach(x => {
      if (teeTimeBlockByCalendar[x.calendar_id]) {
        const newTimeBlocks = [];
        const timeBlocksSelected = teeTimeBlockByCalendar[x.calendar_id].map(x => ({
          time: moment(x.dateTime, 'YYYY-MM-DDTHH:mm:ss.SSS[Z]').format('HH:mm:ss'),
        }));
        const existsTimeBlocks = JSON.parse(x.blocked_times);
        existsTimeBlocks.forEach(x => {
          let isTimeBlock = false;
          timeBlocksSelected.forEach(y => {
            if (x.time === y.time) {
              isTimeBlock = true;
            }
          });
          if (!isTimeBlock) {
            newTimeBlocks.push(x);
          }
        });
        onTeeTimeUnblock({
          variables: {
            updateTeeTimeBlockId: x.id,
            calendarId: x.calendar_id,
            deletedTimes: JSON.parse(x.deleted_times),
            blockedTimes: newTimeBlocks,
          },
        });
      }
    });
    setVisibleSelectTeeTimes(false);
    setTeeTimeSelection([]);
  };

  const handleTeeTimeDelete = item => {
    let isExistTeeTimesBlock = false;
    let varTeeTimeBlock = null;
    teeTimeBlocks.forEach(x => {
      if (x?.calendar_id !== null) {
        if (item.calendarId === x.calendar_id) {
          isExistTeeTimesBlock = true;
          const existsTimeBlocks = JSON.parse(x.deleted_times);
          existsTimeBlocks.push({
            time: moment(item.label, 'hh:mm A').format('HH:mm:ss'),
          });
          varTeeTimeBlock = {
            variables: {
              updateTeeTimeBlockId: x.id,
              calendarId: x.calendar_id,
              deletedTimes: existsTimeBlocks,
              blockedTimes: JSON.parse(x.blocked_times),
            },
          };
        }
      }
    });
    if (isExistTeeTimesBlock) {
      onTeeTimeUnblock(varTeeTimeBlock);
    } else {
      onTeeTimeDelete({
        variables: {
          calendarId: item.calendarId,
          deletedTimes: [{ time: moment(item.label, 'hh:mm A').format('HH:mm:ss') }],
          blockedTimes: [],
        },
      });
    }
    setVisibleSelectTeeTimes(false);
    setTeeTimeSelection([]);
  };

  const handleTeeTimeDeleteBulk = () => {
    const teeTimeBlockByCalendar = groupBy(teeTimeSelection, 'calendarId');
    Object.keys(teeTimeBlockByCalendar).forEach(key => {
      const anyTeeTimeBlockedInCalendar = teeTimeBlocks.find(x => x.calendar_id === key);
      if (anyTeeTimeBlockedInCalendar) {
        const existsTeeTimeDeleted = JSON.parse(anyTeeTimeBlockedInCalendar.deleted_times);
        let times = new Set(existsTeeTimeDeleted.map(d => d.time));
        let mergedTeeTimeDeleted = [
          ...existsTeeTimeDeleted,
          ...teeTimeBlockByCalendar[key]
            .map(x => ({
              time: moment(x.dateTime, 'YYYY-MM-DDTHH:mm:ss.SSS[Z]').format('HH:mm:ss'),
            }))
            .filter(d => !times.has(d.time)),
        ];
        onTeeTimeUnblock({
          variables: {
            updateTeeTimeBlockId: anyTeeTimeBlockedInCalendar.id,
            calendarId: anyTeeTimeBlockedInCalendar.calendar_id,
            deletedTimes: mergedTeeTimeDeleted,
            blockedTimes: JSON.parse(anyTeeTimeBlockedInCalendar.blocked_times),
          },
        });
      } else {
        onTeeTimeDelete({
          variables: {
            calendarId: key,
            deletedTimes: teeTimeBlockByCalendar[key].map(x => ({
              time: moment(x.dateTime, 'YYYY-MM-DDTHH:mm:ss.SSS[Z]').format('HH:mm:ss'),
            })),
            blockedTimes: [],
          },
        });
      }
    });
    setVisibleSelectTeeTimes(false);
    setTeeTimeSelection([]);
  };

  const handleSearch = e => {
    const value = e?.target?.value;
    if (value) {
      setData(prevState => {
        return prevState.map(w => {
          return [
            ...w.filter(x => {
              const isFoundCustomerOrBagId = x.customers.find(y => {
                return (
                  y.name?.toLowerCase().includes(value.toLowerCase()) ||
                  x?.holes?.find(y =>
                    y.customers.find(z => z.name?.toLowerCase().includes(value.toLowerCase())),
                  ) ||
                  y.bagId?.toLowerCase().includes(value.toLowerCase()) ||
                  x?.holes?.find(y =>
                    y.customers.find(z => z.bagId?.toLowerCase().includes(value.toLowerCase())),
                  )
                );
              });
              return (
                !!isFoundCustomerOrBagId ||
                x.reservationId?.toLowerCase().includes(value.toLowerCase())
              );
            }),
            w[w.length - 1],
          ];
        });
      });
    } else {
      setData(teeTimes);
    }
  };

  const handlePrintTeeSheet = useReactToPrint({
    content: () => refTeeSheet.current,
    bodyClass: 'body-print',
  });

  const handleDownloadTeeSheet = () => {
    const elementTeeSheet = document.getElementById('download-tee-sheet');
    elementTeeSheet.className = 'body-download';
    html2canvas(elementTeeSheet, {
      width: elementTeeSheet.scrollWidth,
      height: elementTeeSheet.scrollHeight,
    }).then(canvas => {
      const imgData = canvas.toDataURL('image/png');
      const imgWidth = 210;
      const pageHeight = 295;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      let heightLeft = imgHeight;
      const doc = new jsPDF('p', 'mm');
      let position = 0;

      doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        doc.addPage();
        doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }
      doc.save(`TeeSheet${moment(date).format('DD-MM-YYYY')}.pdf`);
      elementTeeSheet.className = '';
    });
  };

  return (
    <div ref={refTeeSheet} id='tee-sheet'>
      <div id='download-tee-sheet'>
        <Title level={4} className='title-print'>
          {moment(date).format('dddd, D MMMM YYYY')}
        </Title>
        <div className='header-sticky header'>
          {visibleSelectTeeTimes ? (
            <HeaderContent
              extraLeft={[
                <Title key='count-tee-times' level={4}>
                  {teeTimeSelection.length} tee times selected
                </Title>,
              ]}
              extraRight={[
                <AntdButton
                  type='text'
                  className='button-grey'
                  key='group-reservation-button'
                  onClick={() => {
                    setVisibleSelectTeeTimes(false);
                    history.push(
                      Pathname.TEE_SHEET +
                        '/' +
                        Pathname.TEE_SHEET_RESERVATION_GROUP_FORM +
                        `?act=add&date=${date.format('YYYY-MM-DD')}`,
                      {
                        background: true,
                        initialTeeTimes: teeTimeSelection,
                        optionsTeeTime: optionsTeeTime,
                        courseId: course.value,
                      },
                    );
                    setTeeTimeSelection([]);
                  }}>
                  Group reservation
                </AntdButton>,
                <AntdButton
                  onClick={handleTeeTimeDeleteBulk}
                  type='text'
                  className='button-grey'
                  key='delete-button'>
                  Delete
                </AntdButton>,
                <div key='spacer-2' className='spacer-horizontal' />,
                <AntdButton
                  type='text'
                  className='button-grey'
                  key='block-button'
                  onClick={() => {
                    setVisibleSelectTeeTimes(false);
                    handleTeeTimeBlock();
                  }}>
                  Block
                </AntdButton>,
                <AntdButton
                  onClick={handleTeeTimeUnblock}
                  type='text'
                  className='button-grey'
                  key='unblock-button'>
                  Unblock
                </AntdButton>,
                <div key='spacer-1' className='spacer-horizontal' />,
                <Button
                  type='outline'
                  key='done-button'
                  onClick={() => setVisibleSelectTeeTimes(false)}>
                  Cancel
                </Button>,
                <Button key='done-button'>Done</Button>,
              ]}
            />
          ) : (
            <HeaderContent
              content={
                visibleSearch && (
                  <InputText
                    key='search'
                    className='search-input'
                    placeholder='Search customer name, ID, or bag tag here'
                    onChange={handleSearch}
                    prefix={<Icon component={SvgSearch} />}
                    suffix={
                      <IconButton
                        onClick={() => {
                          handleSearch();
                          setVisibleSearch(false);
                        }}
                        iconSvg={SvgClose}
                        size='small'
                      />
                    }
                    autoFocus={true}
                  />
                )
              }
              searchInput={{
                placeholder: 'Search customer name, ID, or bag tag here',
                className: 'filter search',
                onFocus: () => setVisibleSearch(true),
              }}
              extraLeft={[
                <DatePicker
                  key='filter-date'
                  size='middle'
                  className='filter'
                  value={date}
                  format={value => value.format('DD/MM/YYYY')}
                  onSelect={value => {
                    setDate(value);
                    onChangeFilter({
                      date: moment(value).format('YYYY-MM-DD'),
                      course_id: course?.value,
                      statuses,
                    });
                  }}
                />,
                <SingleSelect
                  key='select-course'
                  bordered={false}
                  value={course.value}
                  valueLabel={course.label}
                  onSelect={value => {
                    setCourse(value);
                    onChangeFilter({
                      date: moment(date).format('YYYY-MM-DD'),
                      course_id: value.value,
                      statuses,
                    });
                  }}
                  style={{ width: 140 }}
                  items={optionsCourses}
                  onClickAdd={() => {}}
                />,
                <MultipleSelect
                  key='select-reservation'
                  defaultValue='All reservations status'
                  style={{ width: 140 }}
                  handleChange={values => {
                    setStatuses(values);
                    onChangeFilter({
                      date: moment(date).format('YYYY-MM-DD'),
                      course_id: course?.value,
                      statuses: values,
                    });
                  }}
                  values={statuses}
                  items={reservationStatus || []}
                />,
              ]}
              buttonAdd={{
                label: 'Add new reservation',
                onClick: () =>
                  history.push(
                    Pathname.TEE_SHEET +
                      '/' +
                      Pathname.TEE_SHEET_RESERVATION_FORM +
                      `?act=add&date=${date.format('YYYY-MM-DD')}`,
                    {
                      background: true,
                      optionsTeeTime,
                      courseId: course.value,
                    },
                  ),
              }}
              extraRight={[
                <Button
                  key='button-select-time'
                  type='outline'
                  onClick={() => setVisibleSelectTeeTimes(!visibleSelectTeeTimes)}>
                  Select tee times
                </Button>,
              ]}
            />
          )}
        </div>
        {loading && <Loading />}
        <div className='content-tee-sheet'>
          {data.map(teeTime => {
            return teeTime.map((item, index) => {
              let showDivider = false;
              let label = item.label;
              if (index === 0) {
                showDivider = true;
              }
              const hour = item.label.split(':')[0];
              const beforeHour = teeTime[index - 1]?.label.split(':')[0];

              if (index !== teeTime.length - 1 && hour !== beforeHour) {
                showDivider = true;
                label = `${hour}:00 ${item.label.split(' ')[1]}`;
              }

              return (
                <>
                  {showDivider && <TeeSheetDivider label={label} />}
                  {index === teeTime.length - 1 ? (
                    <TeeSheetDivider label={item.label} />
                  ) : item?.customers?.length > 0 || item?.holes?.length > 0 ? (
                    <TeeSheetFilledTime
                      type={item.isGroup === true ? 'group' : 'single'}
                      groupName={item.groupName}
                      groupId={item.groupId}
                      isGroupHeader={item.isGroupHeader}
                      groupIsNotAnyPaid={item.groupIsNotAnyPaid}
                      groupIsHideCancelReservation={item.groupIsHideCancelReservation}
                      label={item.label}
                      holes={item.holes}
                      rateCardId={item.rateCardId}
                      customers={item.customers}
                      note={item.note}
                      reservationId={item?.reservationId}
                      courseId={item.courseId}
                      optionsTeeTime={optionsTeeTime}
                      dateTime={moment(
                        `${date.format('YYYY-MM-DD')} ${item.label}`,
                        'YYYY-MM-DD h:mm A',
                      ).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')}
                      onCancelReservation={onCancelReservation}
                      onCancelReservationGroup={onCancelReservationGroup}
                      initialStartingHoles={item.initialStartingHoles}
                      groupHover={groupHover}
                      setGroupHover={setGroupHover}
                      statuses={statuses}
                    />
                  ) : item.type === 'deleted' ? null : (
                    <TeeSheetAddButton
                      reservationId={item?.reservationId}
                      courseId={item.courseId}
                      rateCardId={item.rateCardId}
                      dateTime={moment(
                        `${date.format('YYYY-MM-DD')} ${item.label}`,
                        'YYYY-MM-DD h:mm A',
                      ).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')}
                      type={
                        visibleSelectTeeTimes ? 'select' : item.type === 'blocked' ? 'block' : 'add'
                      }
                      isBlocked={item.type === 'blocked'}
                      note={item.note}
                      label={item.label}
                      statuses={statuses}
                      onTeeTimeDelete={() => handleTeeTimeDelete(item)}
                      onSelect={
                        visibleSelectTeeTimes
                          ? teeTimeSelection => {
                            handleTeeTimeSelection({
                              ...teeTimeSelection,
                              optionsTeeTime,
                              calendarId: item?.calendarId,
                              courseId: course.value,
                              startingHoleName: item.startingHoles?.[0]?.name,
                            });
                          }
                          : () =>
                            history.push(
                              Pathname.TEE_SHEET +
                                  '/' +
                                  Pathname.TEE_SHEET_RESERVATION_FORM +
                                  `?act=add&date=${date.format('YYYY-MM-DD')}&rate-card-id=${
                                    item.rateCardId
                                  }`,
                              {
                                background: true,
                                optionsTeeTime,
                                courseId: course.value,
                                time: moment(item.label, 'hh:mm A').format('HH:mm:ss'),
                                startingHoleName: item.startingHoles?.[0]?.name,
                              },
                            )
                      }
                    />
                  )}
                </>
              );
            });
          })}
        </div>
        {teeTimes.length > 0 && (
          <div className='footer-tee-sheet'>
            <Row align='middle' justify='center' gutter={4}>
              <Col>
                <Button
                  type='outline'
                  onClick={() =>
                    history.push(
                      Pathname.TEE_SHEET +
                        '/' +
                        Pathname.TEE_SHEET_EDIT_CART_SIGNS +
                        `?date=${date.format('YYYY-MM-DD')}`,
                      {
                        background: true,
                      },
                    )
                  }>
                  Edit cart signs
                </Button>
              </Col>
              <Col>
                <Button
                  type='outline'
                  onClick={() =>
                    history.push(
                      Pathname.TEE_SHEET +
                        '/' +
                        Pathname.TEE_SHEET_EDIT_CART_SIGNS +
                        `?date=${date.format('YYYY-MM-DD')}&print=true`,
                      {
                        background: true,
                      },
                    )
                  }>
                  Print cart signs
                </Button>
              </Col>
              <Col>
                <Button onClick={handlePrintTeeSheet} type='outline'>
                  Print tee sheet
                </Button>
              </Col>
              <Col>
                <Button onClick={handleDownloadTeeSheet} type='outline'>
                  Download tee sheet
                </Button>
              </Col>
            </Row>
          </div>
        )}
      </div>
    </div>
  );
};

export default TeeSheet;

TeeSheet.propTypes = {
  view: PropTypes.oneOf(['standard', 'crossover', 'shotgun']),
  courses: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    holes: PropTypes.number,
  }),
  onChangeFilter: PropTypes.func,
  onCancelReservation: PropTypes.func,
  onCancelReservationGroup: PropTypes.func,
  onTeeTimeUnblock: PropTypes.func,
  onTeeTimeDelete: PropTypes.func,
  teeTimeBlocks: PropTypes.array,
  teeTimes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      isGroup: PropTypes.bool,
      isGroupHeader: PropTypes.bool,
      groupIsNotAnyPaid: PropTypes.bool,
      groupIsHideCancelReservation: PropTypes.bool,
      groupName: PropTypes.string,
      groupId: PropTypes.string,
      label: PropTypes.string,
      note: PropTypes.string,
      reservationId: PropTypes.string,
      courseId: PropTypes.string,
      rateCardId: PropTypes.string,
      calendarId: PropTypes.string,
      type: PropTypes.oneOf(['blocked', 'deleted']),
      startingHoles: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
          value: PropTypes.string,
        }),
      ),
      initialStartingHoles: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
          value: PropTypes.string,
        }),
      ),
      holes: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          isGroup: PropTypes.bool,
          label: PropTypes.string,
          note: PropTypes.string,
          reservationId: PropTypes.string,
          courseId: PropTypes.string,
          rateCardId: PropTypes.string,
          startingHoles: PropTypes.arrayOf(
            PropTypes.shape({
              name: PropTypes.string,
              value: PropTypes.string,
            }),
          ),
          customers: PropTypes.arrayOf(
            PropTypes.shape({
              customerId: PropTypes.string,
              name: PropTypes.string,
              type: PropTypes.string,
              status: PropTypes.string,
              isRaincheck: PropTypes.bool,
              bagId: PropTypes.string,
              raincheck: PropTypes.shape({
                code: PropTypes.string,
                issued_by: PropTypes.string,
                status: PropTypes.string,
              }),
            }),
          ),
        }),
      ),
      customers: PropTypes.shape({
        customerId: PropTypes.string,
        name: PropTypes.string,
        status: PropTypes.string,
        type: PropTypes.string,
        isRaincheck: PropTypes.bool,
        bagId: PropTypes.string,
        raincheck: PropTypes.shape({
          code: PropTypes.string,
          issued_by: PropTypes.string,
          status: PropTypes.string,
        }),
      }),
    }),
  ),
};

TeeSheet.defaultProps = {
  view: 'standard',
  courses: [],
};
