import React from 'react';
import PropTypes from 'prop-types';
import Modal from '../organisms/Modal';
import IconButtonNavBar from '../atoms/IconButtonNavBar';

const defaultRightFooterButton = {
  label: 'Confirm',
  type: 'danger',
};

const defaultLeftFooterButton = {
  label: 'Go Back',
  type: 'primary',
  state: 'hint'
};
const ModalCancelAction = (props) => {
  return (
    <Modal 
      {...props} 
      leftFooterButton={{...defaultLeftFooterButton, ...props.leftFooterButton}}
      rightFooterButton={{...defaultRightFooterButton, ...props.rightFooterButton}}
      width={540}
      closeIcon={<IconButtonNavBar type="back" />}
    >
      {props.children}
    </Modal>
  );
};

export default ModalCancelAction;

ModalCancelAction.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  leftFooterButton: PropTypes.shape({
    label: PropTypes.string,
    type: PropTypes.oneOf(['primary', 'danger', 'guide']),
    state: PropTypes.oneOf(['default', 'hint', 'disabled', 'loading']),
    size: PropTypes.oneOf(['large', 'middle', 'small']),
    onClick: PropTypes.func
  }),
  rightFooterButton: PropTypes.shape({
    label: PropTypes.string,
    type: PropTypes.oneOf(['primary', 'danger', 'guide']),
    state: PropTypes.oneOf(['default', 'hint', 'disabled', 'loading']),
    size: PropTypes.oneOf(['large', 'middle', 'small']),
    onClick: PropTypes.func
  })
};

ModalCancelAction.defaultProps = {
  leftFooterButton: defaultLeftFooterButton,
  rightFooterButton: defaultRightFooterButton
};