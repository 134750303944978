import React, { useMemo, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import moment from 'moment';
import Pathname from '../../constants/Pathname';
import QueryGql from '../../constants/QueryGql';
import Modal from '../organisms/Modal';
import extractQuery from '../../utils/useQuery';
import TeeSheetRaincheckIssueTemplate from '../templates/TeeSheetRaincheckIssue';
import Loading from '../atoms/Loading';

const TeeSheetRaincheckIssue = () => {
  const history = useHistory();
  const location = useLocation();
  const refTeeSheetRaincheckIssueTemplate = useRef();
  const queryString = extractQuery(location.search);

  const id = queryString.get('id');

  const { loading: loadingGetReservation, data: dataGetReservation } = useQuery(
    QueryGql.GET_RESERVATION,
    {
      variables: {
        getReservationId: id
      },
      fetchPolicy: 'network-only'
    }
  );


  const [ getReservations, { loading: loadingGetReservations } ] = useLazyQuery(
    QueryGql.GET_RESERVATIONS,
    {
      onCompleted: () => {
        history.replace(
          Pathname.TEE_SHEET
        );
      },
      fetchPolicy: 'network-only'
    }
  );

  const [ createRaincheck, { loading: loadingCreateRaincheck }] = useMutation(
    QueryGql.CREATE_RAINCHECK,
    {
      onCompleted: () => {
        const { course, date } = dataGetReservation.getReservation.data;
        getReservations({
          variables: {
            getReservationsData: {
              course_id: course.id,
              date: moment(date).format('YYYY-MM-DD'),
              statuses: []
            }
          }
        });
      },
      onError: () => {}
    }
  );

  const needRainchecks = useMemo(() => {
    let data = [];
    if (dataGetReservation) {
      data = dataGetReservation.getReservation.data.reservation_customers.filter(item => !item.is_raincheck).map(item => ({
        customer_id: item.customer.id,
        name: item.customer.full_name,
        amount: item.membership_price,
        status: item.status,
      }));
    }
    return data;
  }, [dataGetReservation]);

  const handleSubmit = async () => {
    const values = await refTeeSheetRaincheckIssueTemplate.current.handleSubmit();
    if (values) {
      createRaincheck({
        variables: {
          data: {
            ...values,
            customers: values.customers.map(x => ({
              customer_id: x.customer_id,
              amount: x.amount,
              raincheck_code: x.raincheck_code
            }))
          }
        }
      });
    }
  };

  return (
    <Modal
      title="Issue raincheck"
      leftFooterButton={{
        label: 'Cancel',
        type: 'link',
        onClick:() => history.goBack()
      }}
      rightFooterButton={{
        label: 'Issue raincheck',
        state: loadingGetReservation ? 'disabled' : 'default',
        loading: loadingCreateRaincheck || loadingGetReservations,
        onClick: handleSubmit
      }}
      width={964}
    >
      {(loadingGetReservation || !dataGetReservation)
        ? <Loading />
        : <TeeSheetRaincheckIssueTemplate
          ref={refTeeSheetRaincheckIssueTemplate}
          reservationId={id}
          needRainchecks={needRainchecks}
        />}
    </Modal>
  );
};

export default TeeSheetRaincheckIssue;