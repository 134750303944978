/* eslint-disable max-len */
import React from 'react';

function SvgCustomer() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="none"
      viewBox="0 0 16 12"
    >
      <path
        fill="currentColor"
        d="M8.2 6.147a3.28 3.28 0 001.133-2.48 3.333 3.333 0 10-6.666 0A3.28 3.28 0 003.8 6.147 5.333 5.333 0 00.667 11 .667.667 0 102 11a4 4 0 018 0 .667.667 0 001.333 0A5.333 5.333 0 008.2 6.147zM6 5.667a2 2 0 110-4 2 2 0 010 4zm6.493.213A3.334 3.334 0 0010 .333a.667.667 0 000 1.334 2 2 0 012 2 2 2 0 01-1 1.726.667.667 0 00-.033 1.134l.26.173.086.047A4.667 4.667 0 0113.98 11a.667.667 0 101.333 0 6 6 0 00-2.82-5.12z"
      />
    </svg>
  );
}

export default SvgCustomer;
