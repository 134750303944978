import Text from 'antd/lib/typography/Text';
import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'antd';
import moment from 'moment';
import HorizontalTextInput from './HorizontalTextInput';
import SvgDelete from '../atoms/SvgDelete';
import TimePicker from '../atoms/TimePicker';
import SvgAlternateAdd from '../atoms/SvgAlternateAdd';
import './ListInputAdditional.less';

const ListInputAdditional = props => {
  const {
    label,
    componentsArr,
    addNewInput,
    delNewInput,
    handleChange,
  } = props;

  
  const getDisabledHours = (index) => () => {
    let hours = [];
    for (let i = 0; i < moment(componentsArr[index - 1]?.time, 'HH:mm:ss').hour(); i++) {
      hours.push(i);
    }

    return hours;
  };

  const getDisabledMinutes = (index) => (selectedHour) => {
    let minutes = [];
    if (selectedHour === moment(componentsArr[index - 1]?.time, 'HH:mm:ss').hour()) {
      for (let i = 0; i < moment(componentsArr[index - 1]?.time, 'HH:mm:ss').add(1, 'minute').minute(); i++) {
        minutes.push(i);
      }
    }

    return minutes;
  };

  const getDisabledSeconds = (index) => (selectedHour, selectedMinute) => {
    let minutes = [];
    const before = moment(componentsArr[index - 1]?.time, 'HH:mm:ss');
    if (selectedHour === before.hour() && selectedMinute === before.minute()) {
      for (let i = 0; i <= before.second(); i++) {
        minutes.push(i);
      }
    }

    return minutes;
  };

  return (
    <div className="antd-list-text-input">
      {label && <Text className="label">{label}</Text>}
      {componentsArr.map((rowset, index) => {
        return (
          <Row gutter={16} data-id={index} key={index}>
            <Col xs={22}>
              {label === 'Times' ? (
                <TimePicker
                  key={index}
                  name="times1"
                  placeholder="Enter time"
                  style={{ width: '100%' }}
                  value={moment(rowset?.time, 'HH:mm:ss')}
                  timeString
                  allowClear={false}
                  disabledMinutes={getDisabledMinutes(index)}
                  disabledHours={getDisabledHours(index)}
                  disabledSeconds={getDisabledSeconds(index)}
                  onSelect={e => handleChange(index, moment(e).format('HH:mm:ss'))}
                />
              ) : (
                <HorizontalTextInput
                  onChange={handleChange}
                  value={rowset?.value}
                  data-id={index}
                  name={rowset.name}
                  style={{ width: 256 }}
                />
              )}
            </Col>
            <Col className="icon-additional" style={{cursor: index === 0 ? 'not-allowed' : 'pointer'}} onClick={index === 0 ? null : () => delNewInput(index)} xs={1}>
              <SvgDelete id={index} />
            </Col>
            <Col className="icon-additional" style={{cursor: 'pointer'}} onClick={() => addNewInput(index)} xs={1}>
              <SvgAlternateAdd />
            </Col>
          </Row>
        );
      }
      )}
    </div>
  );
};

export default ListInputAdditional;

ListInputAdditional.propTypes = {
  label: PropTypes.string,
  componentsArr: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.string,
  })),
  onChange: PropTypes.func,
  handleChange: PropTypes.func,
  addNewInput: PropTypes.func,
  delNewInput: PropTypes.func
};

ListInputAdditional.defaultProps = {
  onChange: null,
};
