import { useQuery, useMutation } from '@apollo/client';
import React, { useMemo, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import Promise from 'bluebird';
import Pathname from '../../constants/Pathname';
import QueryGql from '../../constants/QueryGql';
import extractQuery from '../../utils/useQuery';
import Modal from '../organisms/Modal';
import TeeSheetPaymentSuccessTemplate from '../templates/TeeSheetPaymentSuccess';
import Loading from '../atoms/Loading';
import './TeeSheetPaymentSuccessGroup.less';
import PrintReceipt from 'components/templates/PrintReceipt';
import {  Typography } from 'antd';

const TeeSheetPaymentSuccessGroup = () => {
  const history = useHistory();
  const location = useLocation();
  const queryString = extractQuery(location.search);
  const type = queryString.get('type');
  const id = queryString.get('id');
  const queryCustomers = queryString.get('customers') ? queryString.get('customers').split(',') : [];
  const [values, setValues] = useState([]);
  const printComponentRef = useRef();

  const { loading: loadingGetReservation, data: dataGetReservationGroup } = useQuery(
    QueryGql.GET_RESERVATION_GROUP,
    {
      variables: {
        getReservationGroupId: id
      },
      fetchPolicy: 'network-only'
    }
  );

  const { loading: loadingGetAllReceiptMethods, data: dataGetAllReceiptMethods } = useQuery(
    QueryGql.GET_ALL_RECEIPT_METHODS,
  );

  const [createReceipt, {loading: loadingCreateReceipt}] = useMutation(
    QueryGql.CREATE_RECEIPT, {
      onCompleted: () => {
        if (type === 'raincheck') {
          history.push(
            Pathname.TEE_SHEET+'/'+
            Pathname.TEE_SHEET_RAINCHECK_ISSUE+
            `?id=${id}`,
            {
              background: true
            }
          );
        } else {
          history.replace(
            Pathname.TEE_SHEET,
          );
        }
      },
      onError: () => {}
    }
  );

  const customers = useMemo(() => {
    let data = [];
    if (dataGetReservationGroup?.getReservationGroup) {
      dataGetReservationGroup?.getReservationGroup?.data.reservations.forEach(reservation => {
        const number = reservation.prefix+'-'+reservation.number;
        reservation.reservation_customers.filter(item => item.status === 'paid').forEach(item => {
          data.push({
            name: item.customer.full_name,
            email: item.customer.email,
            phone_number: item.customer.phone_number,
            id: item.customer.id,
            reservation_id: item.reservation_id,
            number
          });
        });
      });
    }
    return data.filter(item => queryCustomers.includes(item.id)).filter(item => item.name !== 'guest');
  }, [dataGetReservationGroup]);

  const receiptTypes = useMemo(() => {
    let data = [];
    if (dataGetAllReceiptMethods?.getAllReceiptMethods) {
      data = dataGetAllReceiptMethods.getAllReceiptMethods.data.map(item => ({
        label: item.name,
        value: item.code
      }));
    }
    return data;
  }, [dataGetAllReceiptMethods]);


  const handleSendReceipt = () => {
    const groupBy = (xs, key) => {
      return xs.reduce((rv, x) => {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
      }, {});
    };
    const varCreateReceipts = [];
    const reservationsReceipts = groupBy(values, 'reservation_id');
    Object.keys(reservationsReceipts).forEach(key => {
      if (reservationsReceipts?.[key].length > 0) {
        varCreateReceipts.push({
          variables: {
            createReceiptData: {
              reservation_id: key,
              customers: reservationsReceipts[key].map(x => ({
                id: x.id,
                receipt_method: x.receipt_method
              }))
            }
          }
        });
      }
    });
    if (varCreateReceipts.length > 0) {
      Promise.map(varCreateReceipts, (varCreateReceipt) => {
        // Promise.map awaits for returned promises as well.
        return createReceipt(varCreateReceipt);
      }).then(() => {
        if (type === 'raincheck') {
          history.push(
            Pathname.TEE_SHEET+'/'+
            Pathname.TEE_SHEET_RAINCHECK_ISSUE+
            `?id=${id}`,
            {
              background: true
            }
          );
        } else {
          history.replace(
            Pathname.TEE_SHEET,
          );
        }
      }).catch(() => {
      });
    } else {
      history.goBack();
    }
  };

  // rebuild print
  const detailReservation = useMemo(() => {
    const reservation_customers = [];
    const reservation_payments = [];
    let  tee_times = {};
    let  course = {};
    let  date_tee_times = '';
    let date = '';
    const data = dataGetReservationGroup?.getReservationGroup?.data || {};

    data.reservations?.forEach(item => {
      tee_times = item.tee_times;
      date_tee_times = item.date_tee_times;
      course = item.course;
      date = item.date;

      item.reservation_customers?.forEach(rc => {
        reservation_customers.push(rc);
      });
    });

    data.reservations?.forEach(item => {
      item.reservation_payments?.forEach(rp => {
        reservation_payments.push(rp);
      });
    });

    return {
      date_tee_times,
      date,
      course,
      tee_times,
      reservation_customers: reservation_customers.filter(item => queryCustomers.includes(item.customer_id)),
      reservation_payments,
    };
  }, [dataGetReservationGroup]);

  const handlePrintReceipt = useReactToPrint({
    content: () => printComponentRef.current,
  });

  return (
    <Modal
      title="Payment successful"
      closeIcon={<div />}
      onCancel={() => history.replace(
        Pathname.TEE_SHEET,
      )}
      leftFooterButton={{
        label: 'No receipt',
        type: 'link',
        onClick: () => history.replace(
          Pathname.TEE_SHEET,
        )
      }}
      rightFooterButtonExtra={{
        label: 'Print',
        type: 'outline',
        onClick: handlePrintReceipt
      }}
      rightFooterButton={{
        label: 'Send receipt',
        state: loadingGetReservation ||
          loadingGetAllReceiptMethods &&
          !dataGetAllReceiptMethods &&
          !dataGetReservationGroup ? 'disabled' : 'default',
        loading: loadingCreateReceipt,
        onClick: handleSendReceipt,
      }}
      hiddenRightFooterButton={customers?.length === 0}
      width={964}
    >
      <div id="tee-sheet-payment-success-group">
        {(loadingGetReservation ||
          loadingGetAllReceiptMethods &&
          !dataGetAllReceiptMethods &&
          !dataGetReservationGroup)
          ? <Loading /> : customers?.length === 0 ? (
            <Typography>Reservation payment data has been saved successfully</Typography>
          )
            : <TeeSheetPaymentSuccessTemplate
              customers={customers}
              receiptTypes={receiptTypes}
              onChange={setValues}
            /> }
      </div>
      <div>
        <PrintReceipt
          data={detailReservation}
          printComponentRef={printComponentRef}
        />
      </div>
    </Modal>
  );
};

export default TeeSheetPaymentSuccessGroup;