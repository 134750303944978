import { useQuery } from '@apollo/client';
import { Button } from 'antd';
import Text from 'antd/lib/typography/Text';
import React from 'react';
import numeral from 'numeral';
import { useHistory } from 'react-router-dom';
import Pathname from '../../constants/Pathname';
import QueryGql from '../../constants/QueryGql';
import CardWithTitle from '../molecules/CardWithTitle';
import HorizontalCardInput from '../molecules/HorizontalCardInput';
import AppWrapper from '../templates/AppWrapper';
import './RaincheckSettings.less';

const RaincheckSettings = () => {
  const history = useHistory();
  
  const { data: dataGetValidityDuration } = useQuery(QueryGql.GET_VALIDITY_DURATION);
  const { data: dataGetLowerPriceAfterRaincheck } = useQuery(QueryGql.GET_LOWER_PRICE_AFTER_RAINCHECK);
  const { data: dataGetTermAndCondition } = useQuery(QueryGql.GET_TERM_AND_CONDITION);
  const validityDuration = dataGetValidityDuration?.getValidityDuration.data.value;
  const lowerPriceAfterRaincheck = dataGetLowerPriceAfterRaincheck?.getLowestPriceAfterRainCheck.data.value;
  const termAndCondition = dataGetTermAndCondition?.getTermAndCondition.data.terms_and_conditions;

  return (
    <AppWrapper>
      <div className="raincheck-settings-container">
        <CardWithTitle
          title="Validity duration"
          subtitle={'Set the time duration for a raincheck to be marked as “expired” after it has been\nissued to customers.'}
        >
          <div className="raincheck-settings">
            <HorizontalCardInput
              label="Validity duration"
              width={516}
              left={<Text className="value">{validityDuration} days</Text>}
              right={<Button
                type="link"
                onClick={() => history.push(
                  Pathname.RAINCHECK_SETTINGS+'/'+
                  Pathname.RAINCHECK_SETTINGS_DURATION,
                  {
                    background: true
                  }
                )}
              >
              Edit
              </Button>}
            />
          </div>
        </CardWithTitle>
        <CardWithTitle
          title="Terms & conditions"
          subtitle={'Include a terms & condition clause for your rainchecks to let your customers\nknow its limitations, usage, and others infromations you need to let them know.'}
          className="raincheck-settings-card"
        >
          <div className="raincheck-settings">
            <HorizontalCardInput
              label="Terms & conditions"
              width={516}
              left={(
                <ol style={{ paddingInlineStart: 0 }}>
                  <Text className="value-term">{termAndCondition}</Text>
                </ol>
              )}
              right={<Button
                type="link"
                onClick={() => history.push(
                  Pathname.RAINCHECK_SETTINGS+'/'+
                  Pathname.RAINCHECK_SETTINGS_TERM_CONDITION,
                  {
                    background: true
                  }
                )}
              >
              Edit
              </Button>}
            />
          </div>
        </CardWithTitle>
        <CardWithTitle
          title="Lowest price after raincheck"
          subtitle={'For rainchecks issued at 100% of the total green fee, you can include the lowest\nprice the fee can go to once when the raincheck is used.'}
          className="raincheck-settings-card last"
        >
          <div className="raincheck-settings">
            <HorizontalCardInput
              label="Lowest price"
              width={516}
              left={<Text className="value">{numeral(lowerPriceAfterRaincheck).format('$0,0[.]00')}</Text>}
              right={<Button
                type="link"
                onClick={() => history.push(
                  Pathname.RAINCHECK_SETTINGS+'/'+
                  Pathname.RAINCHECK_SETTINGS_LOWEST_PRICE,
                  {
                    background: true
                  }
                )}
              >
              Edit
              </Button>}
            />
          </div>
        </CardWithTitle>
      </div>
    </AppWrapper>
  );
};

export default RaincheckSettings;