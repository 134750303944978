/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { Input } from 'antd';
import './InputTextArea.less';

const { TextArea } = Input;

const InputTextArea = (props) => {
  const { size, placeholder, prefix, suffix } = props;
  return (
    <TextArea
      {...props}
      size={size}
      rows={4}
      placeholder={placeholder}
      prefix={prefix}
      suffix={suffix}
    />
  );
};

export default InputTextArea;

InputTextArea.propTypes = {
  size: PropTypes.oneOf(['small', 'middle', 'large']),
  prefix: PropTypes.any,
  suffix: PropTypes.any,
  placeholder: PropTypes.string
};

InputTextArea.defaultProps = {
  placeholder: '',
  prefix: null,
  suffix: null,
  size: 'middle'
};