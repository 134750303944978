import { Col, Row, Tabs } from 'antd';
import Text from 'antd/lib/typography/Text';
import React, { useMemo, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import moment from 'moment';
import numeral from 'numeral';
import QueryGql from '../../constants/QueryGql';
import extractQuery from '../../utils/useQuery';
import Card from '../atoms/Card';
import Lozenges from '../atoms/Lozenges';
import Modal from '../organisms/Modal';
import CustomerDetailReservation from '../templates/CustomerDetailReservation';
import '../templates/TeeSheetCustomersCheckInDetail.less';
import Loading from '../atoms/Loading';
import Pathname from '../../constants/Pathname';

const { TabPane } = Tabs;

const TeeSheetReservationGroupDetail = () => {
  const history = useHistory();
  const location = useLocation();
  const queryString = extractQuery(location.search);
  const id = queryString.get('id');
  const [total, setTotal] = useState(0);

  const optionsTeeTime = location.state?.optionsTeeTime;

  const { loading: loadingGetReservationGroup, data: dataGetReservationGroup } = useQuery(
    QueryGql.GET_RESERVATION_GROUP,
    {
      variables: {
        getReservationGroupId: id,
      },
    },
  );

  const detail = useMemo(
    () => dataGetReservationGroup?.getReservationGroup.data,
    [dataGetReservationGroup],
  );

  const reservationCustomers = useMemo(() => {
    let totalAmount = 0;
    let reservations = [];
    if (detail?.reservations) {
      detail.reservations.forEach(reservation => {
        totalAmount = totalAmount + reservation.total_amount;
        reservations = [
          ...reservations,
          {
            time: moment(reservation.date_tee_times, 'HH:mm:ss').format('hh:mm A'),
            customers: reservation.reservation_customers.map(item => ({
              name: item.customer.full_name,
              reservations: [
                {
                  name: `${reservation.tee_times.name} - ${item.membership_name}`,
                  amount: item.membership_price,
                  status: reservation.payment_status,
                },
                ...item.add_ons.map(x => ({
                  name: x.name,
                  amount: x.price,
                  status: reservation.payment_status,
                })),
                ...(item.raincheck?.code
                  ? [
                    {
                      name: 'Raincheck',
                      price: -reservation.raincheck.amount,
                      status: reservation.status,
                    },
                  ]
                  : []),
              ],
            })),
          },
        ];
      });
    }
    setTotal(totalAmount);
    return reservations;
  }, [detail]);

  return (
    <Modal
      title='Reservation details'
      leftFooterButton={{
        label: 'Cancel reservation',
        type: 'link',
      }}
      rightFooterButtonExtra={{
        label: 'Edit reservation',
        type: 'outline',
        onClick: () =>
          history.push(
            Pathname.TEE_SHEET +
              '/' +
              Pathname.TEE_SHEET_RESERVATION_GROUP_FORM +
              `?act=edit&id=${detail.id}`,
            {
              background: true,
              optionsTeeTime,
            },
          ),
      }}
      rightFooterButton={{
        label: 'Check-in',
        onClick: () =>
          history.push(
            Pathname.TEE_SHEET +
              '/' +
              Pathname.TEE_SHEET_CUSTOMERS_CHECKIN_GROUP_DETAIL +
              `?id=${detail.id}`,
            {
              background: true,
            },
          ),
      }}
      width={540}>
      <div id='tee-sheet-detail-reservation'>
        {loadingGetReservationGroup && !dataGetReservationGroup ? (
          <Loading />
        ) : (
          <>
            <CustomerDetailReservation
              detail={{
                number: `${detail.prefix}-${detail.number}`,
                courseNumber: `${detail?.course?.name} (${detail?.course?.holes} Hole)`,
                teeTime:
                  moment(detail.date_tee_times?.[0], 'HH:mm:ss').format('hh:mm A') +
                  ', ' +
                  moment(detail.date_tee_times?.[1], 'HH:mm:ss').format('hh:mm A'),
                date: moment(detail.date, 'YYYY-MM-DD').format('dddd, DD/MM/YYYY'),
                type: 'detail-header',
              }}
            />
            <Tabs defaultActiveKey='0'>
              {reservationCustomers.map((reservation, index) => (
                <TabPane tab={reservation.time} key={index.toString()}>
                  <CustomerDetailReservation reservationCustomers={reservation.customers} />
                </TabPane>
              ))}
            </Tabs>
            <Card className='card-item'>
              <Row justify='space-between' align='middle'>
                <Col>
                  <Text className='text-total'>Total</Text>
                </Col>
                <Col>
                  <Row wrap={false}>
                    <Lozenges status='unpaid' />
                    <Text level={4} className='price-total'>
                      {numeral(total).format('$0,0[.]00')}
                    </Text>
                  </Row>
                </Col>
              </Row>
            </Card>
          </>
        )}
      </div>
    </Modal>
  );
};

export default TeeSheetReservationGroupDetail;
