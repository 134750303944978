/* eslint-disable max-len */
import React from 'react';

function SvgTeeSheet() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="none"
      viewBox="0 0 14 14"
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M4.529.488A.703.703 0 014.74.374.803.803 0 015 .332h.5c.207 0 .41.055.587.16l2 1.16v.027a1.157 1.157 0 01.41.427 1.18 1.18 0 01.15.573 1.173 1.173 0 01-.56 1l-2 1.16a1.155 1.155 0 01-.42.148v5.479a.465.465 0 01-.119.303.587.587 0 01-.077.073A.758.758 0 015 11a.758.758 0 01-.471-.157.486.486 0 01-.196-.377V9.463c-1.749.341-2.666.97-2.666 1.42 0 .68 2.08 1.67 5.333 1.67s5.333-.99 5.333-1.67c0-.461-.966-1.112-2.806-1.446a.745.745 0 01-.244-.083.623.623 0 01-.186-.156.51.51 0 01-.1-.204.467.467 0 01.003-.22.514.514 0 01.103-.2.629.629 0 01.187-.151.796.796 0 01.503-.077c2.434.445 3.874 1.391 3.874 2.538 0 1.586-2.867 2.783-6.667 2.783S.333 12.47.333 10.884c0-1.147 1.44-2.093 3.874-2.538a.794.794 0 01.126-.013V.865c0-.141.07-.276.196-.377zM5.667 3.54l1.507-.872-1.507-.872v1.744z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        d="M6.953 9.745a.93.93 0 00-.33.21 1.051 1.051 0 00-.29.71c.002.132.03.26.08.381.051.121.122.233.21.33a1 1 0 00.71.29 1 1 0 00.71-.29c.089-.097.16-.209.21-.33.052-.12.079-.25.08-.38a1.051 1.051 0 00-.29-.71 1 1 0 00-1.09-.211z"
      />
    </svg>
  );
}

export default SvgTeeSheet;
