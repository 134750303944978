import React from 'react';

function SvgUpload() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="none"
      viewBox="0 0 41 32"
    >
      <path
        fill="currentColor"
        d="M33.84 8.44a14 14 0 00-26.72 3.78A8 8 0 009 28a2 2 0 100-4 4 4 0 010-8 2 2 0 002-2 10 10 0 0119.46-3.22 2 2 0 001.56 1.34 6 6 0 01.48 11.68 2.003 2.003 0 101 3.88 10 10 0 00.34-19.24zm-11.42 4.14a2 2 0 00-.66-.42 2 2 0 00-1.52 0 2 2 0 00-.66.42l-6 6a2.008 2.008 0 102.84 2.84l2.58-2.6V30a2 2 0 004 0V18.82l2.58 2.6a2.003 2.003 0 002.19.439c.243-.102.464-.25.65-.438a2 2 0 000-2.84l-6-6z"
      ></path>
    </svg>
  );
}

export default SvgUpload;
