import Title from 'antd/lib/typography/Title';
import PropTypes from 'prop-types';
import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import Card from '../atoms/Card';
import MultipleSelectDate from '../molecules/MultipleSelectDate';
import './CalendarTeeTimeForm.less';
import TemplateCalendarTeeTimeParticularForm from './CalendarTeeTimeParticularForm';

const CalendarTeeTimeForm = (props, ref) => {
  const { courses, memberships, inclusions, timeBaseRates, rateCards, teeTimes } = props;
  const refTemplateCalendarTeeTimeParticularForm = useRef();
  const [dates, setDates] = useState([]);

  useImperativeHandle(ref, () => ({
    handleSubmit: async () => {
      const values = await refTemplateCalendarTeeTimeParticularForm.current.handleSubmit();
      if (values && dates.length > 0) {
        return {
          ...values,
          dates
        };
      }
      return null;
    }
  }));

  return (
    <>
      <TemplateCalendarTeeTimeParticularForm
        ref={refTemplateCalendarTeeTimeParticularForm}
        courses={courses}
        memberships={memberships}
        inclusions={inclusions}
        timeBaseRates={timeBaseRates}
        rateCards={rateCards}
        teeTimes={teeTimes}
        disabledRangeHours={[]}
        isBulk
      />
      <div className="spacer-lg" />
      <Card>
        <Title level={4} className="title-select-date">Select the days to insert the slot</Title>
        <MultipleSelectDate
          initialValues={[]}
          onChange={(values) => {
            setDates(values);
          }}
        />
      </Card>
    </>
  );
};

export default forwardRef(CalendarTeeTimeForm);

CalendarTeeTimeForm.propTypes = {
  teeTimes: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    color: PropTypes.string,
    end_time: PropTypes.string,
    start_time: PropTypes.string,
    rate_card: PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      color: PropTypes.string,
      updated_at: PropTypes.string.isRequired,
      created_at: PropTypes.string.isRequired,
    })
  })).isRequired,
  rateCards: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    color: PropTypes.string,
    is_active: PropTypes.bool,
    updated_at: PropTypes.string.isRequired,
    created_at: PropTypes.string.isRequired,
  }))
};

CalendarTeeTimeForm.defaultProps = {
  teeTimes: [],
  rateCards: []
};