import React from 'react';

function SvgInfo() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        fill="currentColor"
        d="M8 7.333A.667.667 0 007.333 8v2.667a.667.667 0 101.334 0V8A.667.667 0 008 7.333zm.253-2.613a.667.667 0 00-.506 0 .667.667 0 00-.22.14.767.767 0 00-.14.22.667.667 0 00.14.727c.064.059.139.106.22.14a.667.667 0 00.92-.614.7.7 0 00-.194-.473.667.667 0 00-.22-.14zM8 1.333a6.667 6.667 0 100 13.334A6.667 6.667 0 008 1.333zm0 12A5.334 5.334 0 118 2.666a5.334 5.334 0 010 10.667z"
      ></path>
    </svg>
  );
}

export default SvgInfo;
