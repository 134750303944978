import React from 'react';
import { Button, Tabs as AntdTabs } from 'antd';
import PropTypes from 'prop-types';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import './Tabs.less';

const { TabPane } = AntdTabs;

const Tabs = ({ dataTabs, noArrow, activeKey, onChange }) => {

  return (
    <AntdTabs
      className="antd-tabs"
      activeKey={activeKey}
      onChange={onChange}
      tabBarExtraContent={!noArrow &&{
        left: <Button type="text" icon={<LeftOutlined className="tab-bar-extra" />} />,
        right: <Button type="text" icon={<RightOutlined className="tab-bar-extra" />} />,
      }}
    >
      {dataTabs.map(tab => (
        <TabPane tab={tab.label} key={tab.key}>
          {tab.content}
        </TabPane>
      ))}
    </AntdTabs>
  );
};

export default Tabs;

Tabs.propTypes = {
  dataTabs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      key: PropTypes.string.isRequired,
      content: PropTypes.node.isRequired
    })
  ),
  noArrow: PropTypes.bool
};

Tabs.defaultProps = {
  noArrow: false
};

