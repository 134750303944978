/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import './IconButton.less';
import Icon from '@ant-design/icons';

const IconButton = ({ iconSvg, iconColor, onClick, size, disabled }) => {
  let className = 'icon-button-svg';
  if (iconColor === 'primary') {
    className = 'icon-button-svg-primary';
  }

  return (
    <Button 
      type="text"
      className="button-svg"
      size={size}
      onClick={onClick}
      disabled={disabled}
      icon={<Icon
        component={iconSvg} 
        className={className}
      />}
    />
  );
};

export default IconButton;

IconButton.propTypes = {
  iconSvg: PropTypes.any.isRequired,
  iconColor: PropTypes.oneOf(['default', 'primary']),
  onClick: PropTypes.func,
  size: PropTypes.oneOf(['small', 'middle', 'large']),
  disabled: PropTypes.bool
};

IconButton.defaultProps = {
  size: 'middle',
  disabled: false
};