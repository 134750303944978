import React from 'react';
import { Modal as AntdModal } from 'antd';
import PropTypes from 'prop-types';
import Button from '../atoms/Button';
import './Modal.less';
import IconButtonNavBar from '../atoms/IconButtonNavBar';
import { useHistory } from 'react-router-dom';

const Modal = props => {
  const {
    onOk,
    onCancel,
    children,
    title,
    leftFooterButton,
    rightFooterButtonExtra,
    rightFooterButton,
    closeIcon,
    width,
    height,
    hiddenLeftFooterButton,
    hiddenRightFooterButton,
  } = props;
  const history = useHistory();
  let footerButton = [];

  if (rightFooterButtonExtra) {
    footerButton.push(
      <Button
        key='extra-right'
        {...rightFooterButtonExtra}
        type={rightFooterButtonExtra.type}
        state={rightFooterButtonExtra.state}
        size={rightFooterButtonExtra.size}
        onClick={rightFooterButtonExtra.onClick || onOk}>
        {rightFooterButtonExtra.label}
      </Button>,
    );
  }

  if (!hiddenRightFooterButton) {
    footerButton.push(
      <Button
        key='right'
        {...rightFooterButton}
        type={rightFooterButton.type}
        state={rightFooterButton.state}
        size={rightFooterButton.size}
        onClick={rightFooterButton.onClick || onOk}
        className='right-footer-button'>
        {rightFooterButton.label}
      </Button>,
    );
  }

  if (!hiddenLeftFooterButton) {
    footerButton.push(
      <Button
        key='left'
        {...leftFooterButton}
        type={leftFooterButton.type}
        state={leftFooterButton.state}
        size={leftFooterButton.size}
        onClick={leftFooterButton.onClick || onCancel || history?.goBack}
        className='left-footer-button'>
        {leftFooterButton.label}
      </Button>,
    );
  }

  return (
    <AntdModal
      {...props}
      centered
      className='antd-modal'
      title={title}
      visible={true}
      onOk={onOk}
      onCancel={onCancel || history?.goBack}
      closeIcon={closeIcon}
      width={width}
      closable
      bodyStyle={{ height, ...props.bodyStyle }}
      footer={footerButton}>
      {children}
    </AntdModal>
  );
};

export default Modal;

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  onOk: PropTypes.func,
  onCancel: PropTypes.func,
  title: PropTypes.string,
  rightFooterButton: PropTypes.shape({
    label: PropTypes.string,
    type: PropTypes.oneOf(['primary', 'danger', 'guide', 'link', 'outline']),
    state: PropTypes.oneOf(['default', 'hint', 'disabled', 'loading']),
    size: PropTypes.oneOf(['large', 'middle', 'small']),
    disabled: PropTypes.bool,
    loading: PropTypes.bool,
    onClick: PropTypes.func,
  }),
  leftFooterButton: PropTypes.shape({
    label: PropTypes.string,
    type: PropTypes.oneOf(['primary', 'danger', 'guide', 'link', 'outline']),
    state: PropTypes.oneOf(['default', 'hint', 'disabled', 'loading']),
    size: PropTypes.oneOf(['large', 'middle', 'small']),
    disabled: PropTypes.bool,
    loading: PropTypes.bool,
    onClick: PropTypes.func,
  }),
  rightFooterButtonExtra: PropTypes.shape({
    label: PropTypes.string,
    type: PropTypes.oneOf(['primary', 'danger', 'guide', 'link', 'outline']),
    state: PropTypes.oneOf(['default', 'hint', 'disabled', 'loading']),
    size: PropTypes.oneOf(['large', 'middle', 'small']),
    disabled: PropTypes.bool,
    loading: PropTypes.bool,
    onClick: PropTypes.func,
  }),
  closeIcon: PropTypes.node,
  width: PropTypes.number,
  height: PropTypes.number,
  hiddenLeftFooterButton: PropTypes.bool,
  hiddenRightFooterButton: PropTypes.bool,
};

Modal.defaultProps = {
  onOk: null,
  onCancel: null,
  title: '',
  rightFooterButton: {
    label: 'Ok',
    type: 'primary',
    state: 'default',
    size: 'middle',
    onClick: null,
  },
  leftFooterButton: {
    label: 'Cancel',
    type: 'primary',
    state: 'hint',
    size: 'middle',
    onClick: null,
  },
  closeIcon: <IconButtonNavBar type='close' />,
  width: 964,
  height: null,
  hiddenLeftFooterButton: false,
  hiddenRightFooterButton: false,
};
