import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import ItemCustomerDetailHistory from '../molecules/ItemCustomerDetailHistory';
import { useQuery } from '@apollo/client';
import QueryGql from 'constants/QueryGql';
import moment from 'moment';
import formatter from 'utils/formatter';
import Loading from 'components/atoms/Loading';

const CustomerDetailHistory = (props) => {
  const { id } = props;
  const { data, loading } = useQuery(QueryGql.GET_HISTORY_CUSTOMER, {
    variables: {
      customerId: id
    },
    fetchPolicy: 'cache-and-network'
  });

  const items = useMemo(() => {
    let results = [];

    data?.getHistoryReservation?.data?.map(item => {
      results.push({
        id: item.id,
        customerId: id,
        number: item.prefix + '-' + item.number,
        courseNumber: `${item.course?.name} (${item.course?.holes})`,
        teeTime: item.date_tee_times,
        date: moment(item.date).format('dddd DD/MM/YYYY'),
        status: item.payment_status,
        total: formatter.currency(item.total_amount),
        type: 'list-item'
      });
    });

    return results;
  }, [data]);
  return (
    <div style={{ minHeight: 200 }}>
      { loading && (
        <div style={{
          height: '200px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}>
          <Loading />
        </div>
      ) }
      {items.map((history, index) => (
        <div key={index.toString()}>
          <ItemCustomerDetailHistory
            {...history}
          />
          <div className="spacer" />
        </div>
      ))}
    </div>
  );
};

export default CustomerDetailHistory;

CustomerDetailHistory.propTypes = {
  histories: PropTypes.arrayOf(PropTypes.instanceOf(ItemCustomerDetailHistory))
};