import { Col, Row } from 'antd';
import React from 'react';
import Card from '../atoms/Card';
import './AuthWrapper.less';

const AuthWrapper = ({ children }) => {
  return (
    <div id="rate-cards-empty" className="container">
      <Row align="middle" justify="center" className="row">
        <Col>
          <Card className="auth-cards">
            {children}
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default AuthWrapper;