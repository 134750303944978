import Title from 'antd/lib/typography/Title';
import React from 'react';
import HorizontalTextInputArea from '../molecules/HorizontalTextInputArea';

const TeeSheetBlockTeeTimes = ({ title, onChangeNote, note }) => {
  return (
    <div>
      <Title style={{ marginBottom: 24 }} level={5}>{title}. Please include blocking notes for internal reference. </Title>
      <HorizontalTextInputArea
        value={note}
        label="Blocking notes"
        onChange={(e) => onChangeNote(e.target.value)}
        placeholder="Blocked because of preparation for a special event."
      />
    </div>
  );
};

export default TeeSheetBlockTeeTimes;