import { Col, Row } from 'antd';
import Text from 'antd/lib/typography/Text';
import React from 'react';
import PropTypes from 'prop-types';
import './HorizontalCardInput.less';
import Card from '../atoms/Card';

const HorizontalCardInput = (props) => {
  const { label, right, left } = props;
  return (
    <div className="antd-horizontal-static-input" {...props}>
      {label !== undefined && (
        <Text className="label">{label}</Text>
      )}
      <Card
        className="input-static"
        bodyStyle={{ padding: '10px 6px 10px 24px' }}
      >
        <Row align="middle" justify="space-between">
          <Col xs={32}>
            {left}
          </Col>
          {right}
        </Row>
      </Card>
    </div>
  );
};

export default HorizontalCardInput;

HorizontalCardInput.propTypes = {
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  widthCard: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  right: PropTypes.node,
  left: PropTypes.node
};

HorizontalCardInput.defaultProps = {
  onChange: null,
  widthCard: 516,
};

