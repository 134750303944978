import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Text from 'antd/lib/typography/Text';
import TableContent from '../templates/TableContent';
import Avatar from '../atoms/Avatar';
import { useHistory } from 'react-router-dom';
import Pathname from '../../constants/Pathname';
import { Row } from 'antd';

export const cancelationReportList = [
  {
    title: 'Customer name',
    width: 180,
    dataIndex: 'name',
    render: text => (
      <Row align='middle' wrap={false}>
        <Avatar title={text} style={{ marginRight: 8 }} />
        <Text style={{ fontSize: 12 }} type='primary'>
          {text}
        </Text>
      </Row>
    ),
  },
  {
    title: 'Reservation ID',
    dataIndex: 'number',
    align: 'center',
    render: text => <Row justify='center'>{text}</Row>,
  },
  {
    title: 'Cancellation time',
    width: 180,
    dataIndex: 'time',
    render: text => (
      <Text style={{ fontSize: 12 }} type='primary'>
        {text}
      </Text>
    ),
  },
  {
    title: 'Canceled by',
    dataIndex: 'cancelBy',
    render: text => (
      <Text style={{ fontSize: 12 }} type='primary'>
        {text}
      </Text>
    ),
  },
  {
    title: 'Reason',
    dataIndex: 'reason',
    render: text => (
      <Text style={{ fontSize: 12 }} type='primary'>
        {text}
      </Text>
    ),
  },
  {
    title: 'Cancellation fees',
    dataIndex: 'total',
    render: text => (
      <Text style={{ fontSize: 12 }} type='primary'>
        {text}
      </Text>
    ),
  },
];

const CancellationReportList = ({ data, loading }) => {
  const history = useHistory();

  const columns = useMemo(() => {
    return cancelationReportList;
  }, []);

  return (
    <TableContent
      header={false}
      loading={loading}
      data={data}
      columns={columns}
      onRow={record => {
        return {
          onClick: () => {
            history.push(
              `${Pathname.CUSTOMER_CHECK_INS}/${Pathname.RESERVATION_DETAIL}?id=${record.id}&customerId=${record.customer.id}&from=cancellation`,
              { background: true, data: record },
            );
          },
        };
      }}
    />
  );
};

CancellationReportList.propsTypes = {
  data: PropTypes.array,
  loading: PropTypes.bool,
};

export default CancellationReportList;
