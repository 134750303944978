import React, { useMemo, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Pathname from '../../constants/Pathname';
import Modal from '../organisms/Modal';
import RaincheckDetailTemplate from '../templates/RaincheckDetail';
import extractQuery from '../../utils/useQuery';
import { useQuery } from '@apollo/client';
import QueryGql from '../../constants/QueryGql';
import { useReactToPrint } from 'react-to-print';

const RaincheckDetail = () => {
  const history = useHistory();
  const location = useLocation();
  const queryString = extractQuery(location.search);
  const id = queryString.get('id');

  const printRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });


  const { data: dataRaincheck } = useQuery(
    QueryGql.GET_RAINCHECK, {
      variables: {
        getRaincheckId: id
      }
    }
  );

  const raincheck = useMemo(() => {
    return dataRaincheck?.getRaincheck?.data || {};
  }, [dataRaincheck]);

  const { data: dataReservation } = useQuery(
    QueryGql.GET_RESERVATION, {
      variables: {
        getReservationId: raincheck?.reservation_id
      },
      skip: Boolean(!raincheck?.reservation_id)
    }
  );

  const reservation = useMemo(() => {
    return dataReservation?.getReservation?.data || {};
  }, [dataReservation]);

  return(
    <Modal
      title='Raincheck details'
      width={600}
      rightFooterButton={{
        label: 'Print raincheck',
        onClick: handlePrint
      }}
      rightFooterButtonExtra={{
        label: 'Send raincheck',
        type: 'outline',
        onClick: () => history.push(Pathname.RAINCHECK_LIST + '/' + Pathname.SEND_EMAIL + '?type=raincheck&id=' + id, { background: true, data: raincheck, title: 'Send raincheck', number: raincheck.code })
      }}
      hiddenLeftFooterButton
    >
      <RaincheckDetailTemplate
        raincheck={raincheck}
        reservation={reservation}  
      />
      <div style={{ display: 'none' }}>
        <div  style={{ padding: '0 20px' }} ref={printRef}>
          <RaincheckDetailTemplate
            raincheck={raincheck}
            reservation={reservation}
            isPrint 
          />
        </div>
      </div>
    </Modal>
  );
};

export default RaincheckDetail;