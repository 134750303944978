import React from 'react';

function SvgFace() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="none"
      viewBox="0 0 138 138"
    >
      <path
        fill="currentColor"
        d="M69 .666a68.333 68.333 0 00-50.225 114.527 68.336 68.336 0 00100.45 0A68.338 68.338 0 00106.302 11.83 68.335 68.335 0 0069 .666zm0 123a54.668 54.668 0 01-37.925-15.375 41 41 0 0175.85 0A54.668 54.668 0 0169 123.666zM55.333 55.333a13.667 13.667 0 1127.334 0 13.667 13.667 0 01-27.334 0zm60.885 41A54.665 54.665 0 0089.5 73.236a27.333 27.333 0 10-41 0 54.666 54.666 0 00-26.718 23.097 54.12 54.12 0 01-7.449-27.334 54.666 54.666 0 11109.334 0 54.12 54.12 0 01-7.449 27.334z"
      ></path>
    </svg>
  );
}

export default SvgFace;
