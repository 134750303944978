import { ArrowRightOutlined } from '@ant-design/icons';
import { useQuery } from '@apollo/client';
import { Row, Space } from 'antd';
import Title from 'antd/lib/typography/Title';
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import Pathname from '../../constants/Pathname';
import QueryGql from '../../constants/QueryGql';
import Button from '../atoms/Button';
import ReservationBarChart from '../organisms/ReservationBarChart';
import ReservationCards from '../organisms/ReservationCards';

export default function ReservationOverview() {

  const { data: dataReservationsReport } = useQuery(
    QueryGql.GET_RESERVATIONS_OVERVIEW, {
      variables: {
        data: {
          'reservation_by_courses': false,
          'reservation_by_flight': false,
          'reservation_by_player': false,
          'reservation_paid_cancelled': false,
          'reservation_by_datetime': true
        }
      },
      fetchPolicy: 'cache-and-network'
    }
  );

  const dataReport = dataReservationsReport?.getReservationsReport.data || {};
  const summary = dataReport.summary || {};
  const barChartData = useMemo(() => {
    const items = [];

    dataReport.reservation_by_datetime?.map(item => {
      items.push({
        name: item.date,
        value: item.total_reservation
      });
    });
    return items;
  }, [dataReport]);

  return (
    <Space style={{ width: '100%' }} size={16} direction="vertical">
      <Row justify="space-between">
        <Title level={2}>Reservations overview</Title>
        <Link to={`${Pathname.RESERVATIONS_REPORT}`}>
          <Button type="link">
            See complete Reservations report <ArrowRightOutlined style={{ fontSize: 12 }} />
          </Button>
        </Link>
      </Row>
      <ReservationCards
        reservation={summary.total_reservation}
        averageReservation={summary.average_reservation_per_hour}
      />
      <ReservationBarChart data={barChartData} />
    </Space>
  );
}