/* eslint-disable no-unused-vars */
import { useMutation, useQuery } from '@apollo/client';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Title from 'antd/lib/typography/Title';
import QueryGql from '../../constants/QueryGql';
import EditSignsForm from '../molecules/EditSignsForm';
import Drawer from '../organisms/Drawer';
import './TeeSheetEditCartSigns.less';
import extractQuery from '../../utils/useQuery';
import moment from 'moment';
import { groupBy, orderBy } from 'lodash';
import { useReactToPrint } from 'react-to-print';
import PrintCardSigns from 'components/templates/PrintCardSigns';

const TeeSheetEditCartSigns = () => {
  const location = useLocation();
  const history = useHistory();
  const queryString = extractQuery(location.search);
  const date = queryString.get('date');
  const print = queryString.get('print');

  const printRef = useRef();

  const [data, setData] = useState([]);
  const [selected, setSelected] = useState([]);

  const { data: response, refetch } = useQuery(QueryGql.GET_RESERVATION_CART, {
    variables: {
      date
    },
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (response?.getReservationCartSign?.data) {
      setData(response.getReservationCartSign.data);
      if (print === 'true') {
        setTimeout(() => {
          handlePrint();
          history.goBack();
        }, 500);
      }
    }
  }, [response?.getReservationCartSign?.data]);

  const [updateReservationCart] = useMutation(QueryGql.UPDATE_RESERVATION_CART, {
    onCompleted: () => {
      // refetch();
    }
  });

  
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });

  const list = useMemo(() => {
    const items = data.map(item => ({
      ...item,
      time: item.reservation.date_tee_time,
      name: item.reservation.id + '=' + moment(item.reservation.date_tee_time, 'HH:mm:ss').format('HH:mm') + ' @ ' + item.reservation.hole,
    }));

    const groupTimeHole = groupBy(orderBy(items, ['time'],['asc']), 'name');
    
    const result = [];

    Object.keys(groupTimeHole).forEach(key => {
      const cartData = groupTimeHole[key].map(item => ({ ...item, name: 'Golf cart #' + item.number }));
      const groupCart = groupBy(cartData, 'name');

      const carts = [];
      Object.keys(groupCart).forEach(keyCart => {
        const customers = groupCart[keyCart].map(item => ({
          item,
          isNew: item.customer.isNew,
          id: key + '=' + item.customer.id,
          name: item.customer.name,
        }));


        carts.push({
          customers,
          id: key + '=' + keyCart,
          name: keyCart,
        });
      });

      const split = key.split('=');
      result.push({
        carts: orderBy(carts, ['name'], ['asc']),
        name: split[1],
        id: split[0]
      });
    });


    return result;
  }, [data]);

  const onDragEnd = (result) => {
    if (result.reason === 'DROP') {
      if (!result.destination) {
        return null;
      }

      const { draggableId, destination, source } = result;
      const splitDraggableId = draggableId.split('=');
      const customerId = splitDraggableId[2];

      // destination
      const splitDestination = destination.droppableId.split('=');
      const splitCartDestination = splitDestination[2].split('#');

      const reservationIdDestination = splitDestination[0];
      const numberDestination = splitCartDestination[1];

      // source
      const splitSource = source.droppableId.split('=');
      const splitCartSource = splitSource[2].split('#');

      const reservationIdSource = splitSource[0];
      const numberSource = splitCartSource[1];

      if (reservationIdDestination !== reservationIdSource) {
        return;
      } 

      if (reservationIdDestination == reservationIdSource && numberDestination == numberSource) {
        return;
      }

      const destinationData = data.filter((item) => !item.isNew && item.reservation.id === reservationIdDestination && item.number === Number(numberDestination));
      if (destinationData.length >= 2) {
        return;
      }

      // update state
      setData((value) => {
        const newData = [...value].map(item => {
          if (item.customer.id === customerId && item.reservation.id === reservationIdSource && Number(numberSource) === item.number) {
            item.number = Number(numberDestination);
            return item;
          }
          return item;
                
        });
        return newData;
      });

      // update api
      updateReservationCart({
        variables: {
          data: {
            reservation_id: reservationIdDestination,
            customer_id: customerId,
            number: Number(numberDestination)
          }
        }
      });
    }
  };

  const handleAddCart = (value) => {
    const item = value.carts[value.carts.length - 1].customers[0].item;
    const newData = [...data];
    newData.push({
      ...item,
      number: item.number + 1,
      isNew: true,
    });
    setData(newData);
  };

  const handleClickDelete = (value) => {
    const newData = [...data].filter(item => {
      const name = item.reservation.id + '=' + moment(item.reservation.date_tee_time, 'HH:mm:ss').format('HH:mm') + ' @ ' + item.reservation.hole + '=Golf cart #' + item.number;
      if (value.id === name) {
        return false;
      } 
      return true;
            
    });
    setData(newData);
  };

  const handleSelected = (checked, value) => {
    if (checked) {
      const newData = [...selected];
      newData.push(value.id);
      
      setSelected(newData);
    } else {
      const newData = [...selected].filter(v => value.id !== v);
      setSelected(newData);
    }
  };

  const printData = useMemo(() => {
    if (print === 'true') {
      return list;
    }

    return list.map(hole => {
      return {
        ...hole,
        carts: hole.carts.map(cart => {
          return {
            ...cart,
            customers: cart.customers.filter(item => selected.includes(item.id))
          };
        }),
      };
    });
  }, [list, selected]);

  const printList = useMemo(() => {
    const customers = [];
    printData.forEach(p => {
      p.carts.forEach(cart => {
        cart.customers.forEach(cus => {
          customers.push(cus);
        });
      });
    });
    return customers;
  }, [printData]);
  
  return (
    <Drawer
      title={`Edit cart signs - ${moment(date).format('dddd, L')}`}
      btnTitle={`Print ${selected.length} cart signs`}
      onClickSubmit={handlePrint}
    >
      <div id="wrapper-component">
        <EditSignsForm
          data={list}
          onDragEnd={onDragEnd}
          onAddCart={handleAddCart}
          onClickDelete={handleClickDelete}
          onSelected={handleSelected}
          selected={selected}
        />
      </div>
      <div style={{ display: 'none' }}>
        <div ref={printRef}>
          {printList.map((customer, index) => (
            <div  key={index} style={{ minHeight: '505px' }}>
              <PrintCardSigns
               
                name={customer}
                hole={customer}
              />
            </div>
          ))}
        </div>
      </div>
    </Drawer>
  );
};


export default TeeSheetEditCartSigns;