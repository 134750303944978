import Icon from '@ant-design/icons';
import { Col, Row } from 'antd';
import Text from 'antd/lib/typography/Text';
import Title from 'antd/lib/typography/Title';
import React from 'react';
import Button from '../atoms/Button';
import './PageEmpty.less';

const PageEmpty = ({ title, desc, buttonLabel, buttonOnClick, illustration, secoundButton }) => {
  return (
    <div id="rate-cards-empty" className="container">
      <Row align="middle" className="row">
        <Col sm={12} xs={32}>
          <div className="left">
            <Title className="title">{title}</Title>
            <Text className="desc">{desc}</Text>
            <Button className="button" onClick={buttonOnClick} type="primary">{buttonLabel}</Button>
            {secoundButton && <Button onClick={secoundButton?.buttonOnClick} type="outline">{secoundButton?.buttonLabel}</Button>}
          </div>
        </Col>
        <Col sm={12} xs={32}>
          <Icon component={illustration} />
        </Col>
      </Row>
    </div>
  );
};

export default PageEmpty;