import { authFromLocal } from 'utils/function';
import config from 'constants/config';

const uploadFile = file => {
  const { login } = authFromLocal();
  const formData = new FormData();
  const headersSetup = new Headers();

  headersSetup.append('locationid', '02321053-9015-4554-aa01-8e0b559744a6');
  headersSetup.append('Authorization', `Bearer ${login.token}`);

  formData.append(
    'operations',
    '{\n"query": "mutation UploadFile($file: Upload!){uploadFile(file:$file){code data}}",\n"operationName": "UploadFile",\n"variables": {\n"file": null\n}\n}',
  );
  formData.append('map', '{ "0": ["variables.file"] }');
  formData.append('0', file);

  const requestOptions = {
    method: 'POST',
    headers: headersSetup,
    body: formData,
    redirect: 'follow',
  };

  return fetch(config.graphql, requestOptions)
    .then(response => response.json())
    .then(result => ({
      success: true,
      data: result.data,
    }))
    .catch(error => ({
      success: false,
      data: error,
    }));
};

export default uploadFile;
