import React from 'react';
import Card from '../atoms/Card';
import Title from 'antd/lib/typography/Title';
import Note from '../molecules/Note';

import './NotesForToday.less';

export default function NotesForToday({ data }) {

  return(
    <div className="notes-today">
      <Title level={2}>Notes for today</Title>      
      <Card>
        <div className="notes-wrapper">
          {
            data?.map(item => (
              <div  key={item.title} className="item">
                <Note {...item} />
              </div>
            ))
          }
        </div>
      </Card>
    </div>
  );
}