import Text from 'antd/lib/typography/Text';
import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import ModalCancelAction from '../templates/ModalCancelAction';
import { useMutation } from '@apollo/client';
import QueryGql from '../../constants/QueryGql';
import Pathname from '../../constants/Pathname';

const VoidCheckIn = () => {
  const history = useHistory();
  const state = history.location.state || {};


  const reservationCustomer = useMemo(() => {
    return state.data?.reservation_customers?.find((item) => item.customer?.id === state.customerId) || {};
  }, [state]);

  const [voidCustomerCheckins, { loading }] = useMutation(QueryGql.VOID_CUSTOMER_CHECKINS, {
    onCompleted: () => {
      history.push(Pathname.CUSTOMER_CHECK_INS + '?refetch=true');
    },
    onError: () => {}
  });

  return (
    <ModalCancelAction
      title='Confirm to void check-in?'
      leftFooterButton={{
        label: 'Cancel void',
        type: 'link',
      }}
      rightFooterButton={{
        label: 'Continue to void',
        state: 'default',
        loading,
        onClick: () => voidCustomerCheckins({ variables: { reservationId: state.data.id, customerId: state.customerId } }),
      }}
    >
      <Text>Are you you would like to void check-in for {reservationCustomer?.customer?.full_name} at {moment(state?.data?.checkin_at).format('LLL')}? The customer status will be reverted back into “checked-in” and any fees of items will be nullified back into zero.</Text>
    </ModalCancelAction>
  );
};

export default VoidCheckIn;