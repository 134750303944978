import React from 'react';

function SvgDraggable() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="6"
      height="10"
      fill="none"
      viewBox="0 0 6 10"
    >
      <path
        fill="currentColor"
        d="M1.2 2.222c.237 0 .47-.065.667-.187.197-.122.35-.296.442-.499.09-.203.114-.426.068-.642a1.088 1.088 0 00-.328-.569 1.232 1.232 0 00-.615-.304 1.29 1.29 0 00-.693.064C.52.169.334.31.202.494A1.054 1.054 0 000 1.11c0 .295.126.577.351.786.226.208.53.325.849.325zm0 5.556c-.237 0-.47.065-.667.187a1.134 1.134 0 00-.442.499c-.09.203-.114.426-.068.642.046.215.16.413.328.569.168.155.382.26.615.304.233.043.474.02.693-.064.22-.084.407-.226.539-.409.132-.183.202-.397.202-.617 0-.295-.126-.577-.351-.786a1.25 1.25 0 00-.849-.325zm0-3.89c-.237 0-.47.066-.667.188a1.134 1.134 0 00-.442.499c-.09.203-.114.426-.068.642.046.215.16.413.328.569.168.155.382.26.615.304.233.043.474.02.693-.063.22-.085.407-.227.539-.41C2.33 5.435 2.4 5.22 2.4 5c0-.295-.126-.577-.351-.786A1.25 1.25 0 001.2 3.89zM4.8 2.222c.237 0 .47-.065.667-.187.197-.122.35-.296.442-.499.09-.203.114-.426.068-.642a1.088 1.088 0 00-.328-.569 1.233 1.233 0 00-.615-.304 1.29 1.29 0 00-.693.064c-.22.084-.407.226-.539.409a1.054 1.054 0 00-.202.617c0 .295.126.577.351.786.226.208.53.325.849.325zm0 5.556c-.237 0-.47.065-.667.187a1.134 1.134 0 00-.442.499c-.09.203-.114.426-.068.642.046.215.16.413.328.569.168.155.382.26.615.304.233.043.474.02.693-.064.22-.084.407-.226.539-.409C5.93 9.323 6 9.11 6 8.89c0-.295-.126-.577-.351-.786a1.25 1.25 0 00-.849-.325zm0-3.89c-.237 0-.47.066-.667.188a1.134 1.134 0 00-.442.499c-.09.203-.114.426-.068.642.046.215.16.413.328.569.168.155.382.26.615.304.233.043.474.02.693-.063.22-.085.407-.227.539-.41C5.93 5.435 6 5.22 6 5c0-.295-.126-.577-.351-.786A1.25 1.25 0 004.8 3.89z"
      ></path>
    </svg>
  );
}

export default SvgDraggable;

