import React from 'react';
import { useHistory } from 'react-router-dom';
import Pathname from '../../constants/Pathname';
import IconButtonNavBar from '../atoms/IconButtonNavBar';
import SvgEmptyInclusions from '../atoms/SvgEmptyInclusions';
import EmptyState from '../molecules/EmptyState';
import Modal from '../organisms/Modal';

const RateCardsSelectInclusions = () => {
  const history = useHistory();
  // const [inclusions, setInclusions] = useState([]);

  // const handleAdded = useCallback((value) => {
  //   setInclusions([...inclusions, value]);
  // }, []);

  return (
    <Modal
      title="Select inclusions"
      closeIcon={<IconButtonNavBar type="back" />}
      leftFooterButton={{
        label: '+ Add new inclusion',
        type: 'guide',
        onClick: () => history?.push(
          Pathname.RATE_CARDS_FORM_INCLUSIONS,
          {
            background: true,
            // onAdded: handleAdded
          }
        )
      }}
      rightFooterButton={{
        label: 'Finish selecting',
      }}
      width={540}
      height={508}
    >
      {/* {inclusions.length === 0 ? ( */}
      <EmptyState
        illustration={SvgEmptyInclusions}
        label="You don’t have any inclusions yet"
      />
      {/* ) : null } */}
    </Modal>
  );
};

export default RateCardsSelectInclusions;
