import React, { useEffect, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Card } from 'antd';
import * as Yup from 'yup';
import { Formik } from 'formik';
import HorizontalTextInput from '../molecules/HorizontalTextInput';
import HorizontalTextInputArea from '../molecules/HorizontalTextInputArea';
import Modal from '../organisms/Modal';
import RoleSettingsCard from '../organisms/RoleSettingsCard';
import './RolePermissionsSettings.less';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import QueryGql from '../../constants/QueryGql';
import Loading from '../atoms/Loading';
import iconFeature from '../../utils/iconFeature';
import extractQuery from '../../utils/useQuery';

const RolePermissionsSettingsValidations = Yup.object().shape({
  name: Yup.string().required('Required')
});

const RolePermissionsSettings = () => {
  const history = useHistory();
  const location = useLocation();
  const refFormik = useRef();
  const queryString = extractQuery(location.search);
  const act = queryString.get('act');

  const {
    loading: loadingGetFeatures,
    data: dataGetFeatures
  } = useQuery(QueryGql.GET_FEATURES);

  const [findRoleById, {
    loading: loadingFindRoleById,
    data: dataFindRoleById,
  }] = useLazyQuery(QueryGql.FIND_ROLE_BY_ID);
  const [createRole, {
    loading: loadingCreateRole
  }] = useMutation(QueryGql.CREATE_ROLE, {
    onCompleted: () => {
      history?.goBack();
    },
    onError: () => {
      history?.goBack();
    },
    update(cache, { data: { createRole }}) {
      cache.modify({
        fields: {
          searchRole(existingRoles = []) {
            const newRolesRef = cache.writeFragment({
              data: createRole.data,
              fragment: QueryGql.FRAGMENT_ROLE
            });
            return {
              ...existingRoles,
              data: [
                ...existingRoles.data,
                newRolesRef,
              ]
            };
          },
          getAllRoles(existingRoles = []) {
            const newRolesRef = cache.writeFragment({
              data: createRole.data,
              fragment: QueryGql.FRAGMENT_ROLE
            });
            return {
              ...existingRoles,
              data: [
                ...existingRoles.data,
                newRolesRef,
              ]
            };
          }
        }
      });
    }
  });
  const [updateRole, {
    loading: loadingUpdateRole
  }] = useMutation(QueryGql.UPDATE_ROLE, {
    onCompleted: () => {
      history?.goBack();
    },
    onError: () => {
      history?.goBack();
    }
  });

  useEffect(() => {
    if (act === 'edit') {
      const id = queryString.get('id');
      findRoleById({
        variables: {
          findRoleByIdId: id
        }
      });
    }
  }, []);

  const handleSubmit = (values) => {
    const { name, descriptions, categoryFeatures } = values;
    const code = name.toLowerCase().replace(/ /g, '_');
    if (act === 'edit') {
      const { id } = dataFindRoleById.findRoleById?.data;
      updateRole({
        variables: {
          updateRoleId: id,
          updateRoleName: name,
          updateRoleCode: code,
          updateRoleIsActive: true,
          updateRoleCategoryFeatures: categoryFeatures.map(item => ({
            is_editable: item.is_editable,
            is_no_access: item.is_no_access,
            is_see_only: item.is_see_only,
            id: item.id,
            features: item.features
          })),
          updateRoleDescriptions: descriptions
        }
      });
    } else {
      createRole({
        variables: {
          createRoleName: name,
          createRoleCode: code,
          createRoleCategoryFeatures: categoryFeatures.map(item => ({
            is_editable: item.is_editable,
            is_no_access: item.is_no_access,
            is_see_only: item.is_see_only,
            id: item.id,
            features: item.features
          })),
          createRoleDescriptions: descriptions
        }
      });
    }
  };

  return (
    <Modal
      title={act === 'add' ? 'Add role' : 'Edit role'}
      hiddenLeftFooterButton={act === 'add'}
      leftFooterButton={{
        label: 'Discard changes',
        type: 'danger',
        state: loadingGetFeatures ? 'disabled' : 'hint',
      }}
      rightFooterButton={{
        label: 'Save changes',
        state: (
          loadingGetFeatures || 
          loadingFindRoleById
        ) ? 'disabled' : 'default',
        loading: loadingCreateRole || loadingUpdateRole,
        onClick: () => refFormik.current.handleSubmit()
      }}
      bodyStyle={{ textAlign: 'right' }}
      width={964}
    >
      {loadingGetFeatures ? (
        <Card bodyStyle={{ textAlign: 'center' }}>
          <Loading />
        </Card>
      ) : (
        <Formik
          enableReinitialize
          innerRef={refFormik}
          validationSchema={RolePermissionsSettingsValidations}
          initialValues={dataFindRoleById?.findRoleById?.data ? {
            name: dataFindRoleById.findRoleById.data.name,
            descriptions: dataFindRoleById.findRoleById.data.descriptions,
            categoryFeatures: dataFindRoleById.findRoleById.data.account_master_features.map(categoryFeature => ({
              ...categoryFeature,
              id: categoryFeature.category_feature_master_id,
              features: categoryFeature.account_features.map(feature => feature.feature_id)
            }))
          } : {
            name: '',
            descriptions: '',
            categoryFeatures: dataGetFeatures?.getFeatures?.data?.map(categoryFeature => ({
              id: categoryFeature.id,
              is_editable: true,
              is_no_access: false,
              is_see_only: false,
              features: categoryFeature.feature_masters.map(feature => feature.id)
            }))
          }}
          onSubmit={handleSubmit}
        >
          {({
            handleChange,
            values,
            errors,
            setFieldValue
          }) => {
            return (
              <>
                <Card id="account-settings">
                  <HorizontalTextInput
                    label="Role name"
                    value={values?.name}
                    disabled={loadingFindRoleById || loadingCreateRole || loadingUpdateRole}
                    onChange={handleChange('name')}
                    errorMessage={errors?.name}
                  />
                  <div className="spacer" />
                  <HorizontalTextInputArea
                    label="Role description"
                    value={values?.descriptions}
                    disabled={loadingFindRoleById || loadingCreateRole || loadingUpdateRole}
                    onChange={handleChange('descriptions')}
                    errorMessage={errors?.descriptions}
                  />
                </Card>
                <div className="spacer" />
                {dataGetFeatures.getFeatures?.data?.map(item => (
                  <RoleSettingsCard
                    key={item.id}
                    categoryId={item.id}
                    icon={iconFeature(item.code)}
                    title={item.name}
                    desc={item.descriptions}
                    values={values.categoryFeatures?.filter(categoryFeature => categoryFeature.id === item.id)}
                    onChangeAccess={(value, categoryFeatureId) => {
                      setFieldValue('categoryFeatures', refFormik.current.values?.categoryFeatures?.map(categoryFeature => {
                        if (categoryFeature.id === categoryFeatureId) {
                          switch (value) {
                            case 'is_no_access':
                              return {
                                ...categoryFeature,
                                is_editable: false,
                                is_no_access: true,
                                is_see_only: false,
                                features: []
                              };
                            case 'is_see_only':
                              return {
                                ...categoryFeature,
                                is_editable: false,
                                is_no_access: false,
                                is_see_only: true,
                                features: []
                              };
                            default:
                              return {
                                ...categoryFeature,
                                is_editable: true,
                                is_no_access: false,
                                is_see_only: false,
                                features: dataGetFeatures?.getFeatures?.data?.filter(categoryFeature => categoryFeature.id === categoryFeatureId)?.[0]?.feature_masters.map(feature => feature.id)
                              };
                          }
                        }
                        return categoryFeature;
                      }));
                    }}
                    onChange={(value) => {
                      if (values.categoryFeatures.length === 0) {
                        setFieldValue('categoryFeatures', [value]);
                      } else {
                        const categoryFeatureIndex = values.categoryFeatures.findIndex(item => item.id === value.id);
                        if (categoryFeatureIndex > -1) {
                          const categoryFeatureIndexAndAccess = values.categoryFeatures.findIndex(item => 
                            item.id === value.id && 
                                item.is_no_access === value.is_no_access && 
                                item.is_see_only === value.is_see_only && 
                                item.is_editable === value.is_editable
                          );
                          if (categoryFeatureIndexAndAccess > -1) {
                            setFieldValue('categoryFeatures', values.categoryFeatures.map(item => {
                              if (
                                item.id === value.id && 
                                    item.is_no_access === value.is_no_access && 
                                    item.is_see_only === value.is_see_only && 
                                    item.is_editable === value.is_editable
                              ) {
                                return value;
                              }
                              return item;
                            }));
                          } else {
                            setFieldValue('categoryFeatures', [
                              ...values.categoryFeatures,
                              value
                            ]);
                          }
                        } else {
                          setFieldValue('categoryFeatures', [
                            ...values.categoryFeatures,
                            value
                          ]);
                        }
                      }
                    }}
                    checkboxObject={item.feature_masters.map(feature => ({
                      key: feature.id,
                      label: feature.name,
                      value: feature.id
                    }))}
                  />
                ))}
              </>
            );
          }}
        </Formik>
      )}
    </Modal>
  );
};

export default RolePermissionsSettings;
