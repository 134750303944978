import { useMutation, useQuery } from '@apollo/client';
import React, { useCallback, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import moment from 'moment';
import { Tabs } from 'antd';
import Promise from 'bluebird';
import Pathname from '../../constants/Pathname';
import QueryGql from '../../constants/QueryGql';
import extractQuery from '../../utils/useQuery';
import Modal from '../organisms/Modal';
import TeeSheetCustomersCheckInDetailTemplate from '../templates/TeeSheetCustomersCheckInDetail';
import './TeeSheetCustomersCheckInGroupDetail.less';

const { TabPane } = Tabs;

const TeeSheetCustomersCheckInGroupDetail = () => {
  const history = useHistory();
  const location = useLocation();
  const queryString = extractQuery(location.search);
  const id = queryString.get('id');

  const [ loadingSaveChange, setLoadingSaveChange ] = useState(false);
  const [ loadingCheckinAllForPayment, setLoadingCheckinAllForPayment ] = useState(false);
  const [ checkinReservationCustomers, setCheckinReservationCustomers ] = useState({});
  const [ checkinReservationCustomersLength, setCheckinReservationCustomersLength] = useState({});

  const { loading: loadingGetReservation, data: dataGetReservationGroup } = useQuery(
    QueryGql.GET_RESERVATION_GROUP,
    {
      variables: {
        getReservationGroupId: id
      },
      fetchPolicy: 'no-cache',
    },
  );

  const [checkinReservation, { loading: loadingCheckinReservation }] = useMutation(
    QueryGql.CHECKIN_RESERVATION,
  );

  const reservations = useMemo(() => {
    return dataGetReservationGroup?.getReservationGroup?.data.reservations.map(reservation => {
      return {
        id: reservation.id,
        time: moment(reservation.date_tee_times, 'HH:mm:ss').format('hh:mm A'),
        customers: reservation.reservation_customers.map(customer => {
          const reservations = [
            {
              name: reservation.tee_times.name+' - '+customer.membership_name,
              price: customer.membership_price,
              status: customer.status,
              statusDefault: customer.status
            },
            ...customer.add_ons.map(add_on => ({
              name: add_on.name,
              price: add_on.price,
              status: customer.status,
              statusDefault: customer.status
            }))
          ];

          if (customer.raincheck?.code) {
            reservations.push({
              name: 'Raincheck',
              price: - customer.raincheck?.code.amount,
              status: customer.status
            });
          }

          return {
            id: customer.customer_id,
            status: customer.status,
            bag_id: customer.bag_id,
            customer: {
              name: customer.customer.full_name
            },
            reservations
          };
        })
      };
    });
  }, [dataGetReservationGroup]);

  const total = useMemo(() => {
    let totalReservations = 0;
    dataGetReservationGroup?.getReservationGroup?.data.reservations.forEach(reservation => {
      totalReservations = totalReservations + reservation.total_amount;
    });
    return totalReservations;
  }, [dataGetReservationGroup]);

  const status = useMemo(() => {
    let statusReservations = '';
    dataGetReservationGroup?.getReservationGroup?.data.reservations.forEach(reservation => {
      statusReservations = reservation.payment_status;
    });
    return statusReservations;
  }, [dataGetReservationGroup]);

  const operators = useMemo(() => {
    let operatorsReservatins = [];
    dataGetReservationGroup?.getReservationGroup?.data.reservations.forEach(reservation => {
      operatorsReservatins = [...operatorsReservatins, ...reservation.reservation_operators];
    });
    return operatorsReservatins;
  }, [dataGetReservationGroup]);

  const handleSaveChange = useCallback(() => {
    setLoadingSaveChange(true);
    const checkinsReservations = [];
    Object.keys(checkinReservationCustomers).forEach(key => {
      if (checkinReservationCustomers?.[key].length > 0) {
        const checkinsReservation = checkinReservationCustomers[key].filter(x => x.default_status !== 'checked_in' && x.default_status !== 'paid');
        if (checkinsReservation.length > 0) {
          checkinsReservations.push({
            variables: {
              checkinReservationId: key,
              checkinReservationCustomers: checkinsReservation.map(x => {
                delete x.default_status;
                return x;
              }),
            }
          });
        }
      }
    });
    if (checkinsReservations.length > 0) {
      Promise.map(checkinsReservations, (checkinsReservation) => {
        // Promise.map awaits for returned promises as well.
        return checkinReservation(checkinsReservation);
      }).then(() => {
        setLoadingSaveChange(false);
        history.goBack();
      }).catch(() => {
        setLoadingSaveChange(false);
      });
    } else {
      history.goBack();
    }
  }, [checkinReservationCustomers]);
  
  const handleContinueToPayment = useCallback(() => {
    setLoadingCheckinAllForPayment(true);
    const checkinsReservations = [];
    Object.keys(checkinReservationCustomers).forEach(key => {
      if (checkinReservationCustomers?.[key].length > 0) {
        const checkinsReservation = checkinReservationCustomers[key].filter(x => x.default_status !== 'checked_in' && x.default_status !== 'paid');
        if (checkinsReservation.length > 0) {
          checkinsReservations.push({
            variables: {
              checkinReservationId: key,
              checkinReservationCustomers: checkinsReservation.map(x => {
                delete x.default_status;
                return {
                  ...x,
                  bag_id: x?.bag_id || '',
                };
              }),
            }
          });
        }
      }
    });
    if (checkinsReservations.length > 0) {
      Promise.map(checkinsReservations, (checkinsReservation) => {
        // Promise.map awaits for returned promises as well.
        return checkinReservation(checkinsReservation);
      }).then(() => {
        setLoadingCheckinAllForPayment(false);
        history.push(
          Pathname.TEE_SHEET + '/' +
          Pathname.TEE_SHEET_TAKE_PAYMENT_GROUP+
            `?id=${id}`,
          {
            background: true
          }
        );
      }).catch(() => {
        setLoadingCheckinAllForPayment(false);
      });
    } else {
      history.push(
        Pathname.TEE_SHEET + '/' +
        Pathname.TEE_SHEET_TAKE_PAYMENT_GROUP+
          `?id=${id}`,
        {
          background: true
        }
      );
    }
  }, [checkinReservationCustomers]);

  const handleCancelReservation = () => {
    const id = queryString.get('id');
    history.push(
      Pathname.TEE_SHEET + '/' +
        Pathname.TEE_SHEET_CANCEL_RESERVATION+
        `?groupId=${id}`,
      {
        background: true
      }
    );
  };

  const isShowCancelReservation = useMemo(() => {
    let result = false;
    reservations?.forEach(item => {
      item.customers?.forEach(cus => {
        if (cus.status === 'reserved') {
          result = true;
        }
      });
    });
    return result;
  }, [reservations]);


  return (
    <Modal
      title="Check-in customers"
      hiddenLeftFooterButton={!isShowCancelReservation}
      leftFooterButton={{
        label: 'Cancel reservation',
        type: 'link',
        disabled: loadingGetReservation || loadingCheckinReservation,
        onClick: handleCancelReservation
      }}
      rightFooterButtonExtra={{
        label: 'Save changes',
        type: 'outline',
        disabled: loadingGetReservation || loadingCheckinReservation,
        loading: loadingSaveChange,
        onClick: handleSaveChange
      }}
      rightFooterButton={{
        label: 'Continue to payment',
        state: loadingGetReservation || checkinReservationCustomersLength === 0 ? 'disabled' : 'default',
        disabled: loadingGetReservation,
        loading: loadingCheckinAllForPayment,
        onClick: handleContinueToPayment,
      }}
      width={540}
    >
      <div id="tee-sheet-customers-checkin-group-detail">
        <Tabs defaultActiveKey="0">
          {reservations?.map((reservation, index) => (
            <TabPane tab={reservation.time} key={index.toString()}>
              <TeeSheetCustomersCheckInDetailTemplate
                type="group"
                mode="reservation-group"
                data={reservation.customers}
                total={total}
                status={status}
                operators={operators}
                onChangeStatus={(values) => {
                  setCheckinReservationCustomersLength({
                    ...checkinReservationCustomersLength,
                    [reservation.id]: values.filter(x => x.status === 'checked_in').length
                  });
                  setCheckinReservationCustomers({
                    ...checkinReservationCustomers,
                    [reservation.id]: values
                  });
                }}
              />
            </TabPane>
          ))}
        </Tabs>
      </div>
    </Modal>
  );
};

export default TeeSheetCustomersCheckInGroupDetail;