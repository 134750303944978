import React from 'react';
import PropTypes from 'prop-types';
import Card from '../atoms/Card';
import './ItemCustomerDetailHistory.less';
import Text from 'antd/lib/typography/Text';
import { Button, Col, Divider, Row } from 'antd';
import Icon from '@ant-design/icons';
import SvgTeeSheet from '../atoms/SvgTeeSheet';
import SvgTeeTimes from '../atoms/SvgTeeTimes';
import SvgCalendar from '../atoms/SvgCalendar';
import Title from 'antd/lib/typography/Title';
import Lozenges from '../atoms/Lozenges';
import { useHistory } from 'react-router-dom';
import Pathname from '../../constants/Pathname';

const ItemCustomerDetailHistory = (props) => {
  const history = useHistory();
  const {
    number,
    courseNumber,
    teeTime,
    date,
    status,
    total,
    type,
    id,
    customerId
  } = props;

  return (
    <dev id="item-customer-detail-history">
      <Card bodyStyle={{ textAlign: 'left' }}>
        <Row align="middle" justify="space-between">
          <Col>
            <Text className="number">Reservation {number}</Text>
          </Col>
          <Col>
            {type === 'detail-header' && status && <Lozenges status={status} />}
          </Col>
        </Row>
        <div className="item" style={{ paddingTop: 8 }}>
          <Icon component={SvgTeeSheet} className="icon" />
          <Text className="label">Course: </Text>
          <Text className="value">{courseNumber}</Text>
        </div>
        <div className="item">
          <Icon component={SvgTeeTimes} className="icon" />
          <Text className="label">Tee time: </Text>
          <Text className="value">{teeTime}</Text>
        </div>
        <div className="item">
          <Icon component={SvgCalendar} className="icon" />
          <Text className="label">Date: </Text>
          <Text className="value">{date}</Text>
        </div>
        {type === 'list-item' && (
          <>
            <Divider />
            <Row align="middle" justify="space-between">
              <Col>
                <Button
                  type="link"
                  onClick={() => history.push(
                    Pathname.CUSTOMER_LIST+'/'+
                    Pathname.CUSTOMER_LIST_DETAIL+'/'+
                    Pathname.RESERVATION_DETAIL+
                    `?customerId=${customerId}&id=${id}`,
                    {
                      background: true
                    }
                  )}
                >
                  See reservation
                </Button>
              </Col>
              <Col className="col-right">
                <Lozenges status={status} />
                <Title style={{ marginLeft: 16 }} level={4}>{total}</Title>
              </Col>
            </Row>
          </>
        )}
      </Card>
    </dev>
  );
};

export default ItemCustomerDetailHistory;

ItemCustomerDetailHistory.propTypes = {
  number: PropTypes.string.isRequired,
  courseNumber: PropTypes.string,
  teeTime: PropTypes.string,
  date: PropTypes.string,
  status: PropTypes.oneOf(['paid', 'partially_paid']),
  total: PropTypes.string,
  type: PropTypes.oneOf(['list-item', 'detail-header'])
};

ItemCustomerDetailHistory.defaultProps = {
  type: 'list-item'
};