import { useMutation } from '@apollo/client';
import Text from 'antd/lib/typography/Text';
import React from 'react';
import { useHistory } from 'react-router-dom';
import Pathname from '../../constants/Pathname';
import QueryGql from '../../constants/QueryGql';
import ModalCancelAction from '../templates/ModalCancelAction';
import extractQuery from '../../utils/useQuery';
import { Button, notification } from 'antd';

const RateCardsDelete = () => {
  const history = useHistory();
  const queryString = extractQuery(location.search);
  const id = queryString.get('id');
  const name = queryString.get('name');

  const [deleteRateCard, {
    loading: loadingDeleteRateCard
  }] = useMutation(QueryGql.DELETE_RATE_CARD, {
    onCompleted: () => {
      const key = `open${Date.now()}`;
      notification.info({
        message: 'Rate card successfully deleted',
        description: `${name} has been successfully removed from the rate card list.`,
        btn: <Button type="text" className="notification-button-dismiss-info" onClick={() => notification.close(key)}>Dismiss</Button>,
        key,
        closeIcon: <div />,
        placement: 'bottomLeft'
      });
      history.replace(Pathname.RATE_CARDS);
    },
    onError: () => {
      history.replace(Pathname.RATE_CARDS);
    },
    update(cache) {
      cache.modify({
        fields: {
          searchRateCard(existingRateCards = []) {
            return {
              ...existingRateCards,
              data: existingRateCards?.data.filter(item => !item.__ref.includes(id))
            };
          }
        }
      });
    }
  });

  const handleDelete = () => {
    deleteRateCard({
      variables: {
        deleteRateCardId: id
      }
    });
  };

  return (
    <ModalCancelAction
      title='Delete rate card?'
      leftFooterButton={{
        label: 'Cancel',
        state: 'hint',
        disabled: loadingDeleteRateCard
      }}
      rightFooterButton={{
        label: 'Delete rate card',
        loading: loadingDeleteRateCard,
        onClick: handleDelete,
      }}
    >
      <Text>Are you sure you would like to delete Weekday rate card? Any saved changes will be permanently deleted and can not be recovered anymore.</Text>
    </ModalCancelAction>
  );
};

export default RateCardsDelete;