import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import React from 'react';
import './Loading.less';

const Loading = () => {
  return (
    <div id="loading">
      <Spin indicator={<LoadingOutlined style={{ fontSize: 20 }} spin className="spinner" />} />
    </div>
  );
};

export default Loading;