
import React from 'react';
import PropTypes from 'prop-types';
import Text from 'antd/lib/typography/Text';
import numeral from 'numeral';
import ItemCustomerDetailHistory from '../molecules/ItemCustomerDetailHistory';
import Card from '../atoms/Card';
import Avatar from '../atoms/Avatar';
import './CustomerDetailReservation.less';
import { Col, Row } from 'antd';
import Lozenges from '../atoms/Lozenges';
import ButtonStatusReservation from 'components/atoms/ButtonStatusReservation';

const CustomerDetailReservation = (props) => {
  const { detail, reservationCustomers, from } = props;
  return (
    <div id="customer-detail-reservation">
      {detail && (
        <ItemCustomerDetailHistory
          type="detail-history"
          {...detail}
        />
      )}
      {reservationCustomers && (
        <Card className="section">
          {reservationCustomers?.map(reservation => (
            <>
              <div>
                <Avatar title={reservation.name} />
                <Text className="name">{reservation.name}</Text>
              </div>
              {reservation.reservations.map((item, index) => (
                <div className="item" key={index} style={{ marginTop: 12 }}>
                  <Row align="middle" justify="space-between">
                    <Col>
                      <div className="info">
                        <Text className="label">{item.name}</Text>
                        <Text className="value">{numeral(item.amount).format('$0,0[.]00')}</Text>
                      </div>
                    </Col>
                    <Col>
                      { from === 'cancellation' ? 
                        <ButtonStatusReservation status="cancelled" />
                        :
                        <Lozenges status={item.status}/ >
                      }
                    </Col>
                  </Row>
                </div>
              ))}
            </>
          ))}
        </Card>
      )}
    </div>
  );
};

export default CustomerDetailReservation;

CustomerDetailReservation.propTypes = {
  from: PropTypes.string,
  detail: PropTypes.instanceOf(ItemCustomerDetailHistory),
  reservationCustomers: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    reservations: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string,
      amount: PropTypes.number,
      status: PropTypes.string
    }))
  }))
};