import React from 'react';
import { useHistory } from 'react-router-dom';
import Pathname from '../../constants/Pathname';
import Modal from '../organisms/Modal';
import CustomerDetail from '../templates/CustomerDetail';
import { useQuery } from '@apollo/client';
import QueryGql from 'constants/QueryGql';
import { useLocation } from 'react-router-dom';
import useQueryString from 'utils/useQuery';

const CustomerListDetail = () => {
  const history = useHistory();
  const location = useLocation();
  const queryString = useQueryString(location.search);
  const id = queryString.get('id');

  const { data } = useQuery(QueryGql.GET_CUSTOMER, {
    variables: { id },
    skip: !id,
    fetchPolicy: 'cache-and-network'
  });

  const detail = data?.getCustomer?.data || {};

  return (
    <Modal
      title={detail.full_name}
      leftFooterButton={{
        label: 'Delete customer',
        type: 'danger',
        state: 'hint',
        onClick: () => history.replace(
          Pathname.CUSTOMER_LIST+'/'+
          Pathname.CUSTOMER_LIST_DELETE+
          `?id=${detail.id}`,
          {
            background: true,
            name: detail.full_name,
          }
        )
      }}
      rightFooterButton={{
        label: 'Edit customer',
        onClick: () => history.push(
          Pathname.CUSTOMER_LIST+'/'+
          Pathname.CUSTOMER_LIST_FORM+
          `?act=edit&id=${detail.id}`,
          {
            background: true
          }
        )
      }}
      bodyStyle={{ textAlign: 'right' }}
      width={540}
    >
      <CustomerDetail detail={detail} id={id} />
    </Modal>
  );
};

export default CustomerListDetail;