import Text from 'antd/lib/typography/Text';
import React from 'react';
import PropTypes from 'prop-types';
import OtpInput from 'react-otp-input';
import './HorizontalTextInputPin.less';

const HorizontalTextInputPin = (props) => {
  const { label } = props;
  return (
    <div className="antd-horizontal-pin-text-input">
      <Text className="label">{label}</Text>
      <OtpInput
        containerStyle="container-text"
        inputStyle="input-text"
        {...props}
      />
    </div>
  );
};

export default HorizontalTextInputPin;

HorizontalTextInputPin.propTypes = {
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func,
};

HorizontalTextInputPin.defaultProps = {
  onChange: null,
};

