/* eslint-disable max-len */
import React from 'react';

function SvgReports() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="none"
      viewBox="0 0 12 14"
    >
      <path
        fill="currentColor"
        d="M8.667 12.333H3.333a2 2 0 01-2-2V3.667a.667.667 0 10-1.333 0v6.666a3.333 3.333 0 003.333 3.334h5.334a.667.667 0 000-1.334zm-4-4.666a.667.667 0 00.666.666h3.334a.667.667 0 000-1.333H5.333a.667.667 0 00-.666.667zM12 4.96a.873.873 0 00-.04-.18v-.06a.713.713 0 00-.127-.187l-4-4a.713.713 0 00-.186-.126.213.213 0 00-.06 0 .587.587 0 00-.22-.074h-2.7a2 2 0 00-2 2V9a2 2 0 002 2H10a2 2 0 002-2V5v-.04zM8 2.607l1.727 1.726h-1.06A.667.667 0 018 3.667v-1.06zM10.667 9a.667.667 0 01-.667.667H4.667A.667.667 0 014 9V2.333a.667.667 0 01.667-.666h2v2a2 2 0 00.12.666H5.333a.667.667 0 000 1.334h5.334V9z"
      />
    </svg>
  );
}

export default SvgReports;
