/* eslint-disable max-len */
import React from 'react';

function SvgHelp() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="none"
      viewBox="0 0 18 18"
    >
      <path
        fill="currentColor"
        d="M8.408 11.742a1.31 1.31 0 00-.1.125.632.632 0 00-.075.15.533.533 0 00-.05.15 1.133 1.133 0 000 .166.7.7 0 00.067.317.75.75 0 00.45.45.783.783 0 00.633 0 .75.75 0 00.45-.45.834.834 0 00.05-.317.833.833 0 00-1.425-.591zM9 .667a8.333 8.333 0 100 16.666A8.333 8.333 0 009 .667zm0 15A6.666 6.666 0 119 2.334a6.666 6.666 0 010 13.333zM9 4.833a2.5 2.5 0 00-2.167 1.25.833.833 0 101.442.834.833.833 0 011.314-.173A.833.833 0 019 8.167.833.833 0 008.167 9v.833a.833.833 0 001.666 0v-.15A2.5 2.5 0 009 4.833z"
      />
    </svg>
  );
}

export default SvgHelp;
