import React from 'react';

function SvgGuest(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      fill="none"
      viewBox="0 0 28 28"
      {...props}
    >
      <path
        fill="currentColor"
        d="M14 .666a13.333 13.333 0 00-9.8 22.347 13.334 13.334 0 0019.6 0A13.334 13.334 0 0014 .666zm0 24a10.667 10.667 0 01-7.4-3 8 8 0 0114.8 0 10.667 10.667 0 01-7.4 3zm-2.667-13.333a2.667 2.667 0 115.334 0 2.667 2.667 0 01-5.334 0zm11.88 8A10.667 10.667 0 0018 14.826a5.334 5.334 0 10-8 0 10.667 10.667 0 00-5.213 4.507 10.56 10.56 0 01-1.454-5.334 10.667 10.667 0 0121.334 0 10.56 10.56 0 01-1.454 5.334z"
      ></path>
    </svg>
  );
}

export default SvgGuest;
