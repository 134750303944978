import React from 'react';
import PropTypes from 'prop-types';
import Card from '../atoms/Card';
import './ItemCustomerDetailHistory.less';
import Text from 'antd/lib/typography/Text';
import { Col, Row } from 'antd';
import Icon, { CloseCircleOutlined } from '@ant-design/icons';
import SvgTeeSheet from '../atoms/SvgTeeSheet';
import SvgTeeTimes from '../atoms/SvgTeeTimes';
import Title from 'antd/lib/typography/Title';
import Lozenges from '../atoms/Lozenges';
import SvgUser from '../atoms/SvgUser';
import SvgCustomer from '../atoms/SvgCustomer';

const ItemRaincheck = (props) => {
  const {
    number,
    courseNumber,
    timeIssued,
    expiryDate,
    status,
    issuedBy,
    customer
  } = props;

  return (
    <dev id="item-customer-detail-history">
      <Card bodyStyle={{ textAlign: 'left' }}>
        <Row align="middle" justify="space-between">
          <Col>
            <Title level={3} className="title">Raincheck {number}</Title>
          </Col>
          <Col>
            {status && <Lozenges status={status} />}
          </Col>
        </Row>
        <div style={{ paddingTop: 8 }} />
        <div className="item">
          <Icon component={SvgUser} className="icon" />
          <Text className="label">Issued by: </Text>
          <Text className="value">{issuedBy}</Text>
        </div>
        { customer && <div className="item">
          <Icon component={SvgCustomer} className="icon" />
          <Text className="label">Customer: </Text>
          <Text className="value">{customer}</Text>
        </div> }
        <div className="item">
          <Icon component={SvgTeeTimes} className="icon" />
          <Text className="label">Time issued: </Text>
          <Text className="value">{timeIssued}</Text>
        </div>
        <div className="item">
          <Icon component={SvgTeeSheet} className="icon" />
          <Text className="label">Course: </Text>
          <Text className="value">{courseNumber}</Text>
        </div>
        <div className="item">
          <CloseCircleOutlined className="icon" />
          <Text className="label">Expiry date: </Text>
          <Text className="value">{expiryDate}</Text>
        </div>
      </Card>
    </dev>
  );
};

export default ItemRaincheck;

ItemRaincheck.propTypes = {
  number: PropTypes.string.isRequired,
  courseNumber: PropTypes.string,
  timeIssued: PropTypes.string,
  expiryDate: PropTypes.string,
  issuedBy: PropTypes.string,
  status: PropTypes.string,
  customer: PropTypes.string,
};

ItemRaincheck.defaultProps = {
};