import Text from 'antd/lib/typography/Text';
import React, { useMemo, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import ModalCancelAction from '../templates/ModalCancelAction';
import PrintRaincheckTemplate from '../templates/PrintRaincheck';


const PrintRaincheck = () => {
  const printComponentRef = useRef();
  const history = useHistory();
  const state = history.location.state || {};
  const data = !state.data ? [] : Array.isArray(state.data) ? state.data : [state.data];
  const number = useMemo(() => {
    const items = [];
    if (Array.isArray(state.data)) {
      state.data.forEach(element => {
        items.push(element.code);
      });
    } else if (state.data) {
      items.push(state.data.code);
    }
    return items.join(', ');
  }, [state]);

  const handlePrintReceipt = useReactToPrint({
    content: () => printComponentRef.current,
  });

  return (
    <ModalCancelAction
      title={`Print ${data.length} raincheck?`}
      hiddenLeftFooterButton
      rightFooterButton={{
        type: 'primary',
        label: 'Print rainchek',
        onClick: handlePrintReceipt,
      }}
    >
      <Text>Print the selected {number} raincheck?</Text>
      <div style={{ display: 'none' }}>
        <div style={{ margin: 20 }} ref={printComponentRef}>
          { 
            data.map(item => (
              <div style={{ marginBottom: 34 }} key={item.id}>
                <PrintRaincheckTemplate raincheck={item} />
              </div>
            ))
          }
        </div>
      </div>
    </ModalCancelAction>
  );
};

export default PrintRaincheck;