import React, { useMemo } from 'react';
import { Col, Row } from 'antd';
import PropTypes from 'prop-types';
import numeral from 'numeral';
import ReportCard from '../molecules/ReportCard';

const RevenueCards = ({
  isPrint,
  totalGreenFee,
  totalAddOns,
  totalRevenue,
  totalCancellationFee,
  averageGreenFee,
  averageAddOns,
  averageRevenue
}) => {
  const items = useMemo(() => ([
    {
      title: 'Total green fee revenue',
      value: numeral(totalGreenFee).format('$0,0[.]00'),
      span: 6,
    },
    {
      title: 'Total add-ons revenue',
      value: numeral(totalAddOns).format('$0,0[.]00'),
      span: 6,
    },
    {
      title: 'Total revenue',
      value: numeral(totalRevenue).format('$0,0[.]00'),
      span: 6,
    },
    {
      title: 'Total cancelation fee',
      value: numeral(totalCancellationFee).format('$0,0[.]00'),
      span: 6,
    },
    {
      title: 'Average green fee revenue per reservation',
      value: numeral(averageGreenFee).format('$0,0[.]00'),
      span: 8,
    },
    {
      title: 'Average add-ons revenue per reservation',
      value: numeral(averageAddOns).format('$0,0[.]00'),
      span: 8,
    },
    {
      title: 'Average revenue per reservation',
      value: numeral(averageRevenue).format('$0,0[.]00'),
      span: 8,
    }
  ]), [
    totalGreenFee,
    totalAddOns,
    totalRevenue,
    averageGreenFee,
    averageAddOns,
    averageRevenue
  ]);

  return(
    <div id={`${isPrint ? 'print-' : ''}revenue-cards`}>
      <Row gutter={[16, 16]}>
        {
          items.map(item => (
            <Col
              key={item.title}
              span={item.span}
            >
              <ReportCard
                title={item.title}
                value={item.value}
              />
            </Col>
          ))
        }
      </Row>
    </div>
  );
};

RevenueCards.propTypes = {
  isPrint: PropTypes.bool,
  totalGreenFee: PropTypes.number,
  totalAddOns: PropTypes.number,
  totalRevenue: PropTypes.number,
  averageGreenFee: PropTypes.number,
  averageAddOns: PropTypes.number,
  averageRevenue: PropTypes.number,
  totalCancellationFee: PropTypes.number,
};

RevenueCards.defaultProps = {
  totalGreenFee: 0,
  totalAddOns: 0,
  totalRevenue: 0,
  averageGreenFee: 0,
  averageAddOns: 0,
  averageRevenue: 0,
  totalCancellationFee: 0,
};

export default RevenueCards;