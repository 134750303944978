import { CaretDownOutlined } from '@ant-design/icons';
import React from 'react';

import './TooltipChart.less';

const TooltipChart = ({ title, value }) => {

  return(
    <div className="tooltip-chart">
      <div className="title">{title}</div>
      <div className="value">{value}</div>
      <CaretDownOutlined className="arrow" />
    </div>
  );
};

export default TooltipChart;