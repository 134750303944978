import { Select, Tooltip } from 'antd';
import Text from 'antd/lib/typography/Text';
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Icon from '@ant-design/icons';
import './HorizontalSelectOptions.less';
import SvgInfo from '../atoms/SvgInfo';

const { Option } = Select;

const HorizontalSelectOptions = props => {
  const { label, labelInfo, layout, options, errorMessage } = props;

  const renderOptions = () => {
    return options?.map(option => (
      <Option key={option.key} value={option.value}>
        {option.label}
      </Option>
    ));
  };

  return (
    <div
      className={cn('wrap-select-options', {
        'antd-horizontal-select-options': layout === 'default',
        'antd-inline-select-options': layout === 'inline',
      })}>
      {layout === 'default' ? (
        label && (
          <div className='container-label'>
            <Text className='label'>{label}</Text>
            {labelInfo && (
              <Tooltip placement='right' title={labelInfo}>
                <Icon component={SvgInfo} className='info' />
              </Tooltip>
            )}
          </div>
        )
      ) : (
        <Text className='label'>{label}</Text>
      )}

      <Select
        {...props}
        className={['select-option', props.className].join(' ')}
        size='large'
        {...(layout === 'default' && { style: { width: '100%' } })}>
        {renderOptions()}
      </Select>

      {errorMessage && (
        <Text className='error-message' type='danger'>
          {errorMessage}
        </Text>
      )}
    </div>
  );
};

export default HorizontalSelectOptions;

HorizontalSelectOptions.propTypes = {
  label: PropTypes.string.isRequired,
  labelInfo: PropTypes.string,
  errorMessage: PropTypes.string,
  onChange: PropTypes.func,
  layout: PropTypes.oneOf(['default', 'inline']),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

HorizontalSelectOptions.defaultProps = {
  onChange: null,
  layout: 'default',
};
