import React, { useState } from 'react';
import { Menu } from 'antd';
import Icon from '@ant-design/icons';
import SubMenu from 'antd/lib/menu/SubMenu';
import Sider from 'antd/lib/layout/Sider';
import { Link, useLocation } from 'react-router-dom';
import './Drawer.less';
import drawerMenu from '../../constants/drawerMenu';
import { AppContext } from '../../App';

const Drawer = () => {
  const { pathname } = useLocation();
  const { state } = React.useContext(AppContext);
  const activePathname = `/${pathname?.split('/')?.[1]}`;
  const openSubmenu = drawerMenu.filter(menu => menu.pathname === activePathname)?.[0]?.children;
  const [activeSubmenu, setActiveSubmenu] = useState(openSubmenu);

  return (
    <Sider width={288} trigger={null} collapsible collapsed={state.drawer === 'hide'}>
      <Menu
        mode="inline"
        id="sider-drawer"
        defaultSelectedKeys={['/']}
        selectedKeys={[activePathname]}
        openKeys={[activeSubmenu]}
        onOpenChange={keys => setActiveSubmenu(keys[keys?.length - 1])}
      >
        {drawerMenu.map(menu => {
          if (menu.isParent === true) {
            return (
              <SubMenu className="drawer-item" key={menu.pathname} icon={<Icon component={menu.icon} />} title={menu.label}>
                {drawerMenu
                  .filter(menuChildren => menuChildren?.children === menu.pathname)
                  .map(menuChildren => (
                    <Menu.Item className="drawer-item" key={menuChildren.pathname}>
                      <Link to={menuChildren.pathname}>
                        {menuChildren.label}
                      </Link>
                    </Menu.Item>
                  ))}
              </SubMenu>
            );
          }
          if (menu.isParent === false && !menu.children) {
            return (
              <Menu.Item
                className="drawer-item"
                key={menu.pathname}
                icon={<Icon component={menu.icon} />}
              >
                <Link to={menu.pathname}>
                  {menu.label}
                </Link>
              </Menu.Item>
            );
          }
          return null;
        })}
      </Menu>
    </Sider>
  );
};

export default Drawer;