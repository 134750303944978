import { LoadingOutlined } from '@ant-design/icons';
import { Col, Row, Button as AntdButton, Spin } from 'antd';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import Text from 'antd/lib/typography/Text';
import Title from 'antd/lib/typography/Title';
import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Pathname from '../../constants/Pathname';
import Avatar from '../atoms/Avatar';
import Button from '../atoms/Button';
import Card from '../atoms/Card';
import AppBar from '../organisms/AppBar';
import './CustomerImportConfirm.less';

const CustomerImportConfirm = () => {
  const history = useHistory();
  const [isPreparingImport, setIsPreparingImport] = useState(false);

  const handleImport = useCallback(() => {
    setIsPreparingImport(true);
    setTimeout(() => {
      history.replace(Pathname.CUSTOMER_LIST);
    }, 3000);
  }, []);

  return (
    <div id="customer-import-confirm">
      <AppBar
        iconLeft="close"
        title="Import customers"
        type="page"
        right={(<Button disabled={isPreparingImport} loading={isPreparingImport} onClick={handleImport} >Import 5 customers</Button>)}
      />
      <div className="content">
        {isPreparingImport ? (
          <div className="spinner">
            <Spin indicator={<LoadingOutlined style={{ fontSize: 100 }} spin className="spinner-animate" />} />
            <Title level={4} className="text-spinner">Preparing data...</Title>
          </div>
        ) : (
          <>
            <Title className="title" level={4}>Make sure the imported customers along with the information are correct. You can also edit the data and select the customers you wish to import.</Title>
            <Card>
              <Row align="middle" justify="space-between">
                <Col>
                  <Row align="middle">
                    <Col>
                      <Avatar title="Alvin Lianto" />
                    </Col>
                    <Col className="item-info">
                      <Title className="item-name" level={5}>Alvin Lianto</Title>
                      <Text className="item-desc" type="secondary">Publish rate&nbsp;•&nbsp;alvin.lianto@gmail.com&nbsp;•&nbsp;+62 8776 8280 439</Text>
                    </Col>
                  </Row>
                </Col>
                <Col>
                  <AntdButton
                    type="link"
                    onClick={() => history.push(
                      Pathname.CUSTOMER_LIST_IMPORT_CONFIRM+'/'+
          Pathname.CUSTOMER_LIST_FORM+
          '?act=edit&id=id_customer',
                      {
                        background: true
                      }
                    )}
                  >
                Edit customer
                  </AntdButton>
                  <Checkbox checked />
                </Col>
              </Row>
            </Card>
            <div className="spacer" />
            <Card>
              <Row align="middle" justify="space-between">
                <Col>
                  <Row align="middle">
                    <Col>
                      <Avatar title="Richard Liking" />
                    </Col>
                    <Col className="item-info">
                      <Title className="item-name" level={5}>Richard Liking</Title>
                      <Text className="item-desc" type="secondary">Publish rate&nbsp;•&nbsp;alvin.lianto@gmail.com&nbsp;•&nbsp;+62 8776 8280 439</Text>
                    </Col>
                  </Row>
                </Col>
                <Col>
                  <AntdButton
                    type="link"
                    onClick={() => history.push(
                      Pathname.CUSTOMER_LIST_IMPORT_CONFIRM+'/'+
          Pathname.CUSTOMER_LIST_FORM+
          '?act=edit&id=id_customer',
                      {
                        background: true
                      }
                    )}
                  >
                Edit customer
                  </AntdButton>
                  <Checkbox checked />
                </Col>
              </Row>
            </Card>
            <div className="spacer" />
            <Card>
              <Row align="middle" justify="space-between">
                <Col>
                  <Row align="middle">
                    <Col>
                      <Avatar title="Mike Hutagalung" />
                    </Col>
                    <Col className="item-info">
                      <Title className="item-name" level={5}>Mike Hutagalung</Title>
                      <Text className="item-desc" type="secondary">Publish rate&nbsp;•&nbsp;alvin.lianto@gmail.com&nbsp;•&nbsp;+62 8776 8280 439</Text>
                    </Col>
                  </Row>
                </Col>
                <Col>
                  <AntdButton
                    type="link"
                    onClick={() => history.push(
                      Pathname.CUSTOMER_LIST_IMPORT_CONFIRM+'/'+
          Pathname.CUSTOMER_LIST_FORM+
          '?act=edit&id=id_customer',
                      {
                        background: true
                      }
                    )}
                  >
                Edit customer
                  </AntdButton>
                  <Checkbox checked />
                </Col>
              </Row>
            </Card>
            <div className="spacer" />
            <Card>
              <Row align="middle" justify="space-between">
                <Col>
                  <Row align="middle">
                    <Col>
                      <Avatar title="Mohamad Jusuf" />
                    </Col>
                    <Col className="item-info">
                      <Title className="item-name" level={5}>Mohamad Jusuf</Title>
                      <Text className="item-desc" type="secondary">Publish rate&nbsp;•&nbsp;alvin.lianto@gmail.com&nbsp;•&nbsp;+62 8776 8280 439</Text>
                    </Col>
                  </Row>
                </Col>
                <Col>
                  <AntdButton
                    type="link"
                    onClick={() => history.push(
                      Pathname.CUSTOMER_LIST_IMPORT_CONFIRM+'/'+
          Pathname.CUSTOMER_LIST_FORM+
          '?act=edit&id=id_customer',
                      {
                        background: true
                      }
                    )}
                  >
                Edit customer
                  </AntdButton>
                  <Checkbox checked />
                </Col>
              </Row>
            </Card>
            <div className="spacer" />
            <Card>
              <Row align="middle" justify="space-between">
                <Col>
                  <Row align="middle">
                    <Col>
                      <Avatar title="David" />
                    </Col>
                    <Col className="item-info">
                      <Title className="item-name" level={5}>David</Title>
                      <Text className="item-desc" type="secondary">Publish rate&nbsp;•&nbsp;alvin.lianto@gmail.com&nbsp;•&nbsp;+62 8776 8280 439</Text>
                    </Col>
                  </Row>
                </Col>
                <Col>
                  <AntdButton
                    type="link"
                    onClick={() => history.push(
                      Pathname.CUSTOMER_LIST_IMPORT_CONFIRM+'/'+
          Pathname.CUSTOMER_LIST_FORM+
          '?act=edit&id=id_customer',
                      {
                        background: true
                      }
                    )}
                  >
                Edit customer
                  </AntdButton>
                  <Checkbox checked />
                </Col>
              </Row>
            </Card>
            <div className="spacer" />
          </>
        )}
      </div>
    </div>
  );
};

export default CustomerImportConfirm;