import React from 'react';

function SvgRaincheck() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        fill="currentColor"
        d="M14 7.493a.145.145 0 010-.053v-.127c0-.153-.04-.307-.066-.46a.5.5 0 010-.107c-.033-.166-.08-.326-.126-.486A5.94 5.94 0 009.9 2.346h-.053a5.022 5.022 0 00-.454-.12h-.12a3.473 3.473 0 00-.366-.053h-.16V2a.667.667 0 00-1.334 0v.04a5.801 5.801 0 00-.666.12 3.14 3.14 0 00-.414.106h-.086c-.167.054-.32.114-.48.174A5.953 5.953 0 002.28 6.186a.206.206 0 000 .054 4.251 4.251 0 00-.127.48v.106a4.35 4.35 0 00-.08.5V8a.667.667 0 00.667.666h4.667v4a.666.666 0 11-1.334 0 .667.667 0 00-1.333 0 2 2 0 104 0v-4h4.667A.667.667 0 0014.074 8c-.073-.174-.073-.34-.073-.507zm-8.666-.16H3.388V7.26c0-.027 0-.187.053-.274.053-.086 0-.086 0-.126 0-.04.053-.214.087-.32v-.054a4.667 4.667 0 012.54-2.726 10.727 10.727 0 00-.734 3.573zm1.334 0c.126-2.607.96-4 1.333-4s1.193 1.393 1.333 4H6.668zm4 0a10.728 10.728 0 00-.734-3.573 4.666 4.666 0 012.487 2.746c.04.116.074.234.1.354a.557.557 0 000 .1c0 .093.04.193.053.286.014.094 0 .047 0 .074l-1.906.013z"
      ></path>
    </svg>
  );
}

export default SvgRaincheck;
