
import React from 'react';
import ItemRaincheck from '../molecules/ItemRaincheck';
import moment from 'moment';
import { Space } from 'antd';
import TotalCard from '../molecules/TotalCard';

export default function PrintRaincheck({ raincheck }) {

  return (
    <div>
      <Space style={{ width: '100%' }} size={24} direction="vertical">
        <ItemRaincheck
          issuedBy={raincheck?.issued_by}
          number={raincheck?.code}
          customer={raincheck?.customer?.full_name}
          status={raincheck?.status}
          timeIssued={moment(raincheck?.created_at).format('L - LT')}
          courseNumber={`${raincheck?.course?.name} (${raincheck?.course?.holes})`}
          expiryDate={moment(raincheck?.expiration_date, 'YYYY-MM-DD').format('L')}
        />
        <TotalCard 
          total={raincheck?.amount}
        />
      </Space>
    </div>
  );
}