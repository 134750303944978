import { Col, Row, Space } from 'antd';
import React, { useEffect, useState } from 'react';
import SingleSelect from '../molecules/SingleSelect';
import AppWrapper from '../templates/AppWrapper';
import { useHistory } from 'react-router-dom';
import Pathname from '../../constants/Pathname';
import DateRangePicker from '../molecules/DateRangePicker';
import ExportButton from '../molecules/ExportButton';
import { useQuery } from '@apollo/client';
import QueryGql from '../../constants/QueryGql';
import moment from 'moment';
import ReservationReportTemplate from '../templates/ReservationReport';
import useGetCourses from '../../hooks/useGetCourses';

const ReservationsReport = () => {
  const history = useHistory();
  const [openDate, setOpenDate] = useState(true);
  const [date, setDate] = useState([moment().subtract(1, 'M'), moment()]);
  const [course, setCourse] = useState({ key: 'all', label: 'All golf courses', value: 'all' });
  const { optionsCourses } = useGetCourses();

  const isDaily = moment(date[0]).diff(date[1], 'day') === 0;

  const { refetch, data: dataReservationsReport } = useQuery(QueryGql.GET_RESERVATIONS_REPORT, {
    variables: {
      data: {
        start_date: moment(date[0]).format('YYYY-MM-DD'),
        end_date: moment(date[1]).format('YYYY-MM-DD'),
        course_id: course.value === 'all' ? null : course.value,
        reservation_by_courses: true,
        reservation_by_flight: true,
        reservation_by_player: true,
        reservation_paid_cancelled: true,
        reservation_by_datetime: true,
      },
    },
    fetchPolicy: 'cache-and-network',
  });

  const dataReport = dataReservationsReport?.getReservationsReport.data || {};

  const handleClickExport = () => {
    history.push(`${Pathname.RESERVATIONS_REPORT}/${Pathname.EXPORT_RESERVATIONS_REPORT}`, {
      background: true,
    });
  };

  const handleChangeCourse = item => {
    setCourse(item);
  };

  const handleChangeDate = item => {
    setDate(item);
  };

  useEffect(() => {
    if (!openDate) {
      refetch();
    }
  }, [openDate]);

  return (
    <AppWrapper>
      <Space style={{ marginTop: 32, paddingBottom: 32 }} direction='vertical' size={32}>
        <Row justify='space-between'>
          <Col>
            <Space>
              <DateRangePicker
                allowClear={false}
                defaultValue={date}
                onChange={handleChangeDate}
                onOpenChange={v => setOpenDate(v)}
              />
              <SingleSelect
                items={optionsCourses}
                value={course.value}
                valueLabel={course.label}
                onSelect={handleChangeCourse}
              />
            </Space>
          </Col>
          <Col>
            <ExportButton onClick={handleClickExport} />
          </Col>
        </Row>

        <div>
          <ReservationReportTemplate course={course} dataReport={dataReport} isDaily={isDaily} />
        </div>
      </Space>
    </AppWrapper>
  );
};

export default ReservationsReport;
