import React, { useCallback, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import QueryGql from '../../constants/QueryGql';
import HorizontalTextInput from '../molecules/HorizontalTextInput';
import Modal from '../organisms/Modal';

const GolfCourseSettingsClub = () => {
  const history = useHistory();
  const { data: dataGetClubName } = useQuery(QueryGql.GET_CLUB_NAME);
  const [valueName, setValueName] = useState(dataGetClubName?.getClubName?.data?.value);
  const [setClubName] = useMutation(QueryGql.SET_CLUB_NAME, {
    onCompleted: () => {
      history?.goBack();
    },
    onError: () => {
      history?.goBack();
    },
  });

  const handleChangeName = useCallback((e) => {
    const name = e.target.value;
    setValueName(name);
  }, []);

  const handleAdded = () => {
    setClubName({
      variables: {
        setClubNameValue: valueName,
      }
    });
  };
  
  return (
    <Modal
      title="Edit golf club"
      hiddenLeftFooterButton
      rightFooterButton={{
        label: 'Save changes',
        state: valueName === '' ? 'disabled' : 'default',
        onClick: handleAdded
      }}
      bodyStyle={{ textAlign: 'right' }}
      width={540}
    >
      <HorizontalTextInput
        label="Golf club name"
        value={valueName}
        onChange={handleChangeName}
      />
    </Modal>
  );
};

export default GolfCourseSettingsClub;
