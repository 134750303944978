import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import Avatar from 'antd/lib/avatar/avatar';
import Modal from '../organisms/Modal';
import Tabs from '../atoms/Tabs';
import './TypeMembershipsMemberDetail.less';
import { Card, Divider } from 'antd';
import Text from 'antd/lib/typography/Text';

const TypeMembershipsMemberDetail = () => {
  const history = useHistory();

  const handleAdded = useCallback(() => {
    history?.goBack();
  }, []);

  return (
    <Modal
      title="Mike Hutagalung"
      leftFooterButton={{
        label: '104 customers',
        type: 'danger',
        state: 'hint',
      }}
      rightFooterButton={{
        label: 'Close',
        onClick: handleAdded
      }}
      bodyStyle={{ textAlign: 'right' }}
      width={540}
    >
      <div className="member-detail">
        <Tabs dataTabs={dataTabs} noArrow />
        <Card className="wrapper-avatar">
          <Avatar className="member-avatar">MH</Avatar>
        </Card>
        <Card>
          <Text className="title-member">Basic Information</Text>
          <Divider className="member-info-divider" />
          <div className="wrapper-all-member-info">
            <div className="wrapper-member-info">
              <Text className="sub-title">Full name</Text>
              <Text>Mike Hutagalung</Text>
            </div>
            <div className="wrapper-member-info">
              <Text className="sub-title">Full name</Text>
              <Text>Mike Hutagalung</Text>
            </div>
          </div>
        </Card>
      </div>
    </Modal >
  );
};

const dataTabs = [
  {
    label: 'Information',
    key: '0',
  },
  {
    label: 'History',
    key: '1',
  },
  {
    label: 'Feedbacks',
    key: '2',
  },
  {
    label: 'House account',
    key: '3',
  }
];

export default TypeMembershipsMemberDetail;
