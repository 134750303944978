import React, { useCallback, useState, useRef, useMemo, useEffect } from 'react';
import { Button, notification } from 'antd';
import { useLocation, useHistory } from 'react-router-dom';
import { useMutation, useLazyQuery } from '@apollo/client';
import { Formik } from 'formik';
import useQuery from '../../utils/useQuery';
import * as Yup from 'yup';
import moment from 'moment';
import Text from 'antd/lib/typography/Text';
import HorizontalTextInput from '../molecules/HorizontalTextInput';
import HorizontalSelectOptions from '../molecules/HorizontalSelectOptions';
import HorizontalRadioButton from '../molecules/HorizontalRadioButton';
import HorizontalTimePickerRange from '../molecules/HorizontalTimePickerRange';
// import Modal from '../organisms/Modal';
import './TeeTimesSettings.less';
import CardWithTitle from '../molecules/CardWithTitle';
import SvgTag from '../atoms/SvgTag';
import Icon from '@ant-design/icons';
import HorizontalColorPicker from '../molecules/HorizontalColorPicker';
import ListInputAdditional from '../molecules/ListInputAdditional';
import Drawer from '../organisms/Drawer';
import QueryGql from '../../constants/QueryGql';

const CustomerSchemaValidations = Yup.object().shape({
  name: Yup.string().required('Required'),
  start_time: Yup.string().required('Required'),
  end_time: Yup.string().required('Required'),
  substype: Yup.string().required('Required'),
  interval_time: Yup.string().required('Required'),
  color: Yup.string().required('Required'),
});

const CustomerSchemaValidationsShotgun = Yup.object().shape({
  name: Yup.string().required('Required'),
  start_time: Yup.string().required('Required'),
  end_time: Yup.string().required('Required'),
  substype: Yup.string().required('Required'),
  color: Yup.string().required('Required'),
});

const CustomerSchemaValidationsCustom = Yup.object().shape({
  name: Yup.string().required('Required'),
  color: Yup.string().required('Required'),
  substype: Yup.string().required('Required'),
});

const TeeTimesSettings = () => {
  const arrayOfHoles = [
    {
      name: 'Hole #1',
      value: 'Hole #1'
    },
  ];
  const arrayOfTimes = [
    {
      time: '00:00:00'
    },
    {
      time: '00:10:00'
    },
  ];
  const history = useHistory();
  const refFormik = useRef();
  const location = useLocation();
  const queryString = useQuery(location.search);
  const act = queryString.get('act');
  const id = queryString.get('id');
  const [radioValue, setRadioValue] = useState('standard');
  const [startingHoles, setStartingHoles] = useState(arrayOfHoles);
  const [startingTime, setStartingTime] = useState(arrayOfTimes);

  const [createTeeTime] = useMutation(QueryGql.CREATE_TEE_TIMES, {
    onCompleted: (response) => {
      const key = `open${Date.now()}`;
      notification.success({
        message: 'Tee times successfully added',
        description: `${response?.createTeeTime?.data.name} has been successfully added to the rate card list.`,
        btn: <Button type="text" className="notification-button-dismiss-success" onClick={() => notification.close(key)}>Dismiss</Button>,
        key,
        closeIcon: <div />,
        placement: 'bottomLeft'
      });
      history?.goBack();
    },
    onError: () => {},
    update(cache, { data: { createTeeTime } }) {
      cache.modify({
        fields: {
          searchAndFilterTeeTime(existingTeeTimes = []) {
            const newTeeTimesRef = cache.writeFragment({
              data: createTeeTime.data,
              fragment: QueryGql.FRAGMENT_TEE_TIMES
            });
            return {
              ...existingTeeTimes,
              data: [
                ...existingTeeTimes.data,
                newTeeTimesRef,
              ]
            };
          }
        }
      });
    }
  });
  const [updateTeeTime] = useMutation(QueryGql.UPDATE_TEE_TIMES, {
    onCompleted: (response) => {
      const key = `open${Date.now()}`;
      notification.success({
        message: 'Tee times successfully edited',
        description: `${response?.updateTeeTime?.data.name}has been successfully edited to the tee times card list.`,
        btn: <Button type="text" className="notification-button-dismiss-success" onClick={() => notification.close(key)}>Dismiss</Button>,
        key,
        closeIcon: <div />,
        placement: 'bottomLeft'
      });
      history?.goBack();
    },
    onError: () => {}
  });

  const [getByIdTeeTime, {
    data: dataGetByIdTeeTimeyId
  }] = useLazyQuery(QueryGql.GET_BY_ID_TEE_TIME);

  const [searchRateCard, {
    data: dataGetRateCardItem
  }] = useLazyQuery(QueryGql.SEARCH_RATE_CARD);

  const handleSwitchRadio = useCallback(e => {
    const value = e.target.value;
    setRadioValue(value);
  }, []);

  useEffect(() => {
    searchRateCard({
      variables: {
        searchRateCardSearch: ''
      }
    });
    if (act === 'edit' || act === 'duplicate') {
      getByIdTeeTime({
        variables: {
          getTeeTimeByIdId: id
        }
      });
    }
    if (dataGetByIdTeeTimeyId) {
      setStartingHoles(dataGetByIdTeeTimeyId?.getTeeTimeById?.data.starting_holes === 'null' ? arrayOfHoles : JSON.parse(dataGetByIdTeeTimeyId?.getTeeTimeById?.data.starting_holes));
      setStartingTime(dataGetByIdTeeTimeyId?.getTeeTimeById?.data.custom_invervals === 'null' ? arrayOfTimes : JSON.parse(dataGetByIdTeeTimeyId?.getTeeTimeById?.data?.custom_invervals));
      setRadioValue(dataGetByIdTeeTimeyId?.getTeeTimeById?.data.tee_time_types);
    }
  }, [dataGetByIdTeeTimeyId]);

  const handleSubmit = (values) => {
    let { name, start_time, end_time, interval_time, color, substype } = values;

    if (radioValue === 'custom') {
      start_time = startingTime[0].time;
      end_time = startingTime[startingTime.length - 1].time;
    }
    if (act === 'add' || act === 'duplicate') {
      createTeeTime({
        variables: {
          createTeeTimeName: name,
          createTeeTimeColor: color,
          createTeeTimeRateCardId: substype,
          createTeeTimeIntervalTime: interval_time,
          createTeeTimeIsActive: null,
          createTeeTimeCustomInverval: startingTime,
          createTeeTimeStartingHoles: radioValue === 'shotgun' ? holesShotgunName : startingHoles,
          updateTeeTimeNumberStartingHoles: radioValue === 'shotgun' ? holesShotgunName.length : startingHoles.length,
          createTeeTimeEndTime: end_time,
          createTeeTimeStartTime: start_time,
          createTeeTimeTeeTimeTypes: radioValue
        }
      });
    } else {
      updateTeeTime({
        variables: {
          updateTeeTimeId: id,
          updateTeeTimeName: name,
          updateTeeTimeColor: color,
          updateTeeTimeRateCardId: substype,
          updateTeeTimeIsActive: true,
          updateTeeTimeCustomInverval: startingTime,
          updateTeeTimeStartingHoles: radioValue === 'shotgun' ? holesShotgunName : startingHoles,
          updateTeeTimeNumberStartingHoles: radioValue === 'shotgun' ? holesShotgunName.length : startingHoles.length,
          updateTeeTimeIntervalTime: interval_time,
          updateTeeTimeEndTime: end_time,
          updateTeeTimeStartTime: start_time,
          updateTeeTimeTeeTimeTypes: radioValue
        }
      });
    }
  };

  const rateCards = useMemo(() => dataGetRateCardItem?.searchRateCard?.data.map(item => ({
    key: item.id,
    value: item.id,
    label: (
      <div>
        <Icon component={SvgTag} style={{ color: item.color }} />
        <Text className="label-option-tee-time">{item.name}</Text>
      </div>
    )
  })), [dataGetRateCardItem]);

  const handleHolesChange = (event) => {
    startingHoles[event.target.dataset.id] = {
      name: event.target.value,
      value: event.target.value
    };
    setStartingHoles([...startingHoles]);
  };

  const handleHolesDelete = (index) => {
    startingHoles.splice(index, 1);
    setStartingHoles([...startingHoles]);
  };

  const handleHolesAdd = (index) => {
    startingHoles.splice(index + 1, 0, {
      name: 'Hole #',
      value: 'Hole #'
    });
    setStartingHoles([...startingHoles]);
  };

  const handleTimeChange = (id, value) => {
    setStartingTime(
      startingTime.map((customTime, i) => {
        if (i === id) {
          return {
            ...customTime,
            time: value,
          };
        }
        return customTime;
      })
    );
  };

  return (
    <Drawer
      title={act === 'add' ? 'Add new tee time' : act === 'duplicate' ? 'Duplicate tee time' : 'Edit tee time'}
      btnTitle={act === 'add' ? 'Save tee time' : 'Save Changes'}
      onClickSubmit={() => refFormik.current.handleSubmit()}>
      <Formik
        enableReinitialize
        initialValues={act === 'edit' || act === 'duplicate' ? {
          name: dataGetByIdTeeTimeyId?.getTeeTimeById?.data?.name,
          start_time: dataGetByIdTeeTimeyId?.getTeeTimeById?.data?.start_time,
          end_time: dataGetByIdTeeTimeyId?.getTeeTimeById?.data?.end_time,
          substype: dataGetByIdTeeTimeyId?.getTeeTimeById?.data?.rate_card?.id,
          interval_time: dataGetByIdTeeTimeyId?.getTeeTimeById?.data?.interval_time,
          color: dataGetByIdTeeTimeyId?.getTeeTimeById?.data?.color
        } : {
          name: '',
          start_time: '00:00:00',
          end_time: '01:00:00',
          color: '#000D23'
        }}
        innerRef={refFormik}
        validationSchema={
          radioValue === 'standard' && CustomerSchemaValidations ||
          radioValue === 'shotgun' && CustomerSchemaValidationsShotgun ||
          radioValue === 'custom' && CustomerSchemaValidationsCustom
        }
        onSubmit={handleSubmit}
      >
        {({ handleChange, values, errors }) => {
          return (
            <div id="customer-form" className="formik-wrapper">
              <CardWithTitle
                title="Tee time format"
                subtitle={
                  'Select the format of the tee time which will affect the fields needed to save the tee time.'
                }
                className="tee-times-settings-card">
                <HorizontalRadioButton
                  className="pricing-type"
                  value={radioValue}
                  onChange={handleSwitchRadio}
                  options={options}
                />
                <>
                  <div className="spacer-top" />
                  <Text className="title-standard-section">
                    {radioValue === 'standard' && 'Standard'}
                    {radioValue === 'crossover' && 'Crossover'}
                    {radioValue === 'shotgun' && 'Shotgun'}
                    {radioValue === 'custom' && 'Custom'} tee time informations
                  </Text>
                  <div className="spacer" />
                  <HorizontalTextInput
                    name="name"
                    style={{ width: 256 }}
                    label="Tee time name"
                    value={values?.name}
                    onChange={handleChange('name')}
                    errorMessage={errors?.name}
                  />

                  {radioValue !== 'custom' && (
                    <>
                      <div className="spacer" />
                      <span id="tee-times-range">
                        <HorizontalTimePickerRange
                          valueFrom={!values.start_time ? null : moment(values.start_time, 'HH:mm:ss')}
                          onChangeFrom={(e) => handleChange('start_time')(moment(e).format('HH:mm:ss'))}
                          valueTo={!values.end_time ? null : moment(values.end_time, 'HH:mm:ss')}
                          onChangeTo={(e) => handleChange('end_time')(moment(e).format('HH:mm:ss'))}
                          label="Time range"
                        />
                      </span>
                    </>
                  )}
                  {radioValue !== 'shotgun' && radioValue !== 'custom' && (
                    <>
                      <div className="spacer" />
                      <HorizontalTextInput
                        name="interval_time"
                        style={{ width: 256 }}
                        type="number"
                        label="Time interval"
                        value={values?.interval_time}
                        suffix={<Text className="suffix-interval">minutes</Text>}
                        onChange={handleChange('interval_time')}
                        errorMessage={errors?.interval_time}
                      />
                    </>
                  )}
                  <div className="spacer" />
                  <HorizontalSelectOptions
                    name="substype"
                    label="Default rate card"
                    options={rateCards}
                    value={values?.substype}
                    onChange={handleChange('substype')}
                    errorMessage={errors?.substype}
                    placeholder={
                      <div>
                        <Icon component={SvgTag} className="icon-tee-time" />
                        <Text className="placeholder"> Select default rate card</Text>
                      </div>
                    }
                  />
                  <div className="spacer" />
                  <HorizontalColorPicker
                    name="color"
                    style={{ width: 256 }}
                    label="Color"
                    initialValue={values?.color}
                    value={values?.color}
                    onChange={(e) => handleChange('color')(e.value)}
                  />
                  {radioValue === 'crossover' && (
                    <>
                      <div className="spacer-top" />
                      <Text className="title-standard-section">Number of starting holes</Text>
                      <div className="spacer" />
                      <ListInputAdditional
                        label="Hole number"
                        componentsArr={startingHoles}
                        handleChange={handleHolesChange}
                        addNewInput={handleHolesAdd}
                        delNewInput={handleHolesDelete}
                      />
                      <div style={{ textAlign: 'center' }}>
                        <Text>{startingHoles.length} custom holes</Text>
                      </div>
                    </>
                  )}
                  {radioValue === 'custom' && (
                    <>
                      <div className="spacer-top" />
                      <Text className="title-standard-section">Custom intervals</Text>
                      <div className="spacer" />
                      <ListInputAdditional
                        label="Times"
                        componentsArr={startingTime}
                        handleChange={handleTimeChange}
                        addNewInput={(e) => {
                          const current = startingTime[e];
                          const next = startingTime[e + 1];
                          let time = moment(current.time, 'HH:mm:ss');
                          const times = [...startingTime];
                          
                          if (next) {
                            const nextTime = moment(next.time, 'HH:mm:ss');
                            const diff = nextTime.diff(time, 'minute');
                            time = time.add(Math.floor(diff / 2), 'minute');

                            const diffSecond = nextTime.diff(time, 'second');
                            if (diffSecond > 1) {
                              times.splice(e + 1, 0, { time: time.format('HH:mm:ss') });
                            }
                          } else {
                            time = time.add(10, 'minute');
                            times.push({ time: time.format('HH:mm:ss') });
                          }

                          setStartingTime(times);
                        }}
                        delNewInput={(e) => {
                          setStartingTime(startingTime.filter((_, index) => index !== parseInt(e)));
                        }}
                      />
                      <div style={{ textAlign: 'center' }}>
                        <Text>{startingTime.length} custom times</Text>
                      </div>
                    </>
                  )}
                </>
              </CardWithTitle>
            </div>
          );
        }}
      </Formik>
    </Drawer>
  );
};

const options = [
  { label: 'Standard', value: 'standard' },
  { label: 'Crossover', value: 'crossover' },
  { label: 'Shotgun', value: 'shotgun' },
  { label: 'Custom', value: 'custom' },
];

export default TeeTimesSettings;


export const holesShotgunName = [
  {
    name: 'Hole #1',
    value: 'Hole #1',
  },
  {
    name: 'Hole #2',
    value: 'Hole #2',
  },
  {
    name: 'Hole #3',
    value: 'Hole #3',
  },
  {
    name: 'Hole #4',
    value: 'Hole #4',
  },
  {
    name: 'Hole #5',
    value: 'Hole #5',
  },
  {
    name: 'Hole #6',
    value: 'Hole #6',
  },
  {
    name: 'Hole #7',
    value: 'Hole #7',
  },
  {
    name: 'Hole #8',
    value: 'Hole #8',
  },
  {
    name: 'Hole #9',
    value: 'Hole #9',
  },
  {
    name: 'Hole #10',
    value: 'Hole #10',
  },
  {
    name: 'Hole #11',
    value: 'Hole #11',
  },
  {
    name: 'Hole #12',
    value: 'Hole #12',
  },
  {
    name: 'Hole #13',
    value: 'Hole #13',
  },
  {
    name: 'Hole #14',
    value: 'Hole #14',
  },
  {
    name: 'Hole #15',
    value: 'Hole #15',
  },
  {
    name: 'Hole #16',
    value: 'Hole #16',
  },
  {
    name: 'Hole #17',
    value: 'Hole #17',
  },
  {
    name: 'Hole #18',
    value: 'Hole #18',
  },
];