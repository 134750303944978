import { useMutation, useQuery } from '@apollo/client';
import { Button, notification } from 'antd';
import React, { useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Pathname from '../../constants/Pathname';
import QueryGql from '../../constants/QueryGql';
import Loading from '../atoms/Loading';
import Modal from '../organisms/Modal';
import TemplateCalendarTeeTimeForm from '../templates/CalendarTeeTimeForm';

const CalendarTeeTimeForm = () => {
  const history = useHistory();
  const refTemplateCalendarTeeTimeForm = useRef();
  const [dates, setDates] = useState([]);
  const { loading: loadingFormCalendar, data: dataFormCalendar } = useQuery(
    QueryGql.FORM_CALENDAR,
    {
      fetchPolicy: 'network-only',
    },
  );

  const [createBulkCalendar, { loading: loadingCreateBulkCalendar }] = useMutation(
    QueryGql.CREATE_BULK_CALENDAR_MUTATION,
    {
      onCompleted: () => {
        notification.success({
          message: 'Success',
          description: 'successfully create new customer',
          btn: (
            <Button
              type='text'
              className='notification-button-dismiss-success'
              onClick={() => notification.close('create-customer')}>
              Dismiss
            </Button>
          ),
          key: 'create-customer',
          closeIcon: <div />,
        });
        history.replace(Pathname.CALENDAR);
      },
      onError: () => {},
      update(cache, { data: { createBulkCalendar } }) {
        cache.modify({
          fields: {
            getAllCalendar(existingCalendar = { data: [] }) {
              const newCalendarRef = [];
              createBulkCalendar.data.forEach(item => {
                newCalendarRef.push(
                  cache.writeFragment({
                    data: item,
                    fragment: QueryGql.FRAGMENT_CALENDAR,
                  }),
                );
              });
              return {
                ...existingCalendar,
                data: [...existingCalendar.data, ...newCalendarRef],
              };
            },
          },
        });
      },
    },
  );

  const [createCalendarCustom, { loading: loadingCreateBulkCalendarCustom }] = useMutation(
    QueryGql.CREATE_CALENDAR_CUSTOM,
    {
      onCompleted: () => {
        const key = `open${Date.now()}`;
        notification.success({
          message: 'Slots successfully inserted',
          description: `${dates.length} days have been successfully inserted the tee times and rate card as chosen.`,
          btn: (
            <Button
              type='text'
              className='notification-button-dismiss-success'
              onClick={() => notification.close(key)}>
              Dismiss
            </Button>
          ),
          key,
          closeIcon: <div />,
          placement: 'bottomLeft',
        });
        history.replace(Pathname.CALENDAR);
      },
      onError: () => {},
      update(cache, { data: { createCalendarCustom } }) {
        cache.modify({
          fields: {
            getAllCalendar(existingCalendar = { data: [] }) {
              const newCalendarRef = cache.writeFragment({
                data: createCalendarCustom.data,
                fragment: QueryGql.FRAGMENT_CALENDAR,
              });
              return {
                ...existingCalendar,
                data: [...existingCalendar.data, newCalendarRef],
              };
            },
          },
        });
      },
    },
  );

  return (
    <Modal
      hiddenLeftFooterButton
      rightFooterButton={{
        label: 'Insert tee time slots',
        loading: loadingCreateBulkCalendar || loadingCreateBulkCalendarCustom,
        onClick: async () => {
          const values = await refTemplateCalendarTeeTimeForm.current.handleSubmit();
          if (values?.dates.length > 0) {
            const createBulkCalendarData = [];
            if (values.createType === 'saved') {
              values.dates?.forEach(date => {
                createBulkCalendarData.push({
                  tee_time_id: values.tee_time_id,
                  rate_card_id: values.rate_card_id,
                  start_time: values.start_time,
                  end_time: values.end_time,
                  date,
                });
              });
              createBulkCalendar({
                variables: {
                  createBulkCalendarData,
                },
              });
              setDates(values.dates);
            } else {
              createCalendarCustom({
                variables: {
                  createCalendarCustomNewTeeTime: values.new_tee_time,
                  createCalendarCustomNewRateCard: values.new_rate_card,
                  createCalendarCustomTeeTimeId: values?.tee_time_id,
                  createCalendarCustomRateCardId: values?.rate_card_id,
                  createCalendarCustomStartTime: values?.start_time || values?.teeTime?.start_time,
                  createCalendarCustomEndTime: values?.end_time || values?.teeTime?.end_time,
                  createCalendarCustomTeeTime: values?.teeTime,
                  createCalendarCustomRateCard: values?.rateCard,
                  createCalendarCustomBulk: true,
                  createCalendarCustomBulkTotal: values.dates.map(x => ({ date: x })),
                },
              });
              setDates(values.dates);
            }
          } else {
            const key = `open${Date.now()}`;
            notification.info({
              message: 'Please select the date first',
              btn: (
                <Button
                  type='text'
                  className='notification-button-dismiss-info'
                  onClick={() => notification.close(key)}>
                  Dismiss
                </Button>
              ),
              key,
              closeIcon: <div />,
              placement: 'bottomLeft',
            });
          }
        },
        type: 'primary',
      }}
      title='Insert tee time slots'
      width={540}>
      {loadingFormCalendar ? (
        <Loading />
      ) : (
        <TemplateCalendarTeeTimeForm
          ref={refTemplateCalendarTeeTimeForm}
          courses={dataFormCalendar?.getAllCourses.data}
          memberships={dataFormCalendar?.getAllMembership.data}
          inclusions={dataFormCalendar?.getAllInclusions.data}
          timeBaseRates={dataFormCalendar?.getAllTimeBaseRate.data}
          rateCards={dataFormCalendar?.getAllRateCards.data}
          teeTimes={dataFormCalendar?.getTeeTimes.data}
        />
      )}
    </Modal>
  );
};

export default CalendarTeeTimeForm;
