import Text from 'antd/lib/typography/Text';
import React from 'react';
import { useHistory } from 'react-router-dom';
import Pathname from '../../constants/Pathname';
import ModalCancelAction from '../templates/ModalCancelAction';

const RateCardsCancelAction = () => {
  const history = useHistory();
  return (
    <ModalCancelAction
      title='Cancel add new rate card?'
      rightFooterButton={{
        label: 'Cancel add new rate card',
        onClick: () => history.replace(Pathname.RATE_CARDS),
      }}
    >
      <Text>Any unsaved changes and progress will be lost if you decide to cancel before finishing the rate card.</Text>
    </ModalCancelAction>
  );
};

export default RateCardsCancelAction;