/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import Text from 'antd/lib/typography/Text';
import { Input } from 'antd';
import './InputText.less';
import Icon from '@ant-design/icons/lib/components/Icon';
import SvgSearch from './SvgSearch';

const InputText = (props) => {
  const { size, placeholder, prefix, suffix, type, errorMessage, label, autoFocus } = props;

  if (type === 'password') {
    return <><Input.Password
      {...props}
      size={size}
      placeholder={placeholder}
    />
    {errorMessage && <Text className="error-message" type="danger">{errorMessage}</Text>}
    </>;
  }
  if (type === 'search') {
    return <><Input
      {...props}
      allowClear
      prefix={(<Icon component={SvgSearch} />)}
    />
    {errorMessage && <Text className="error-message" type="danger">{errorMessage}</Text>}
    </>;
  }
  return (
    <div className="wrapper-input">
      { label && <div className="multi-select-label"><Text>{label}</Text></div> }

      <Input
        {...props}
        size={size}
        placeholder={placeholder}
        prefix={prefix}
        suffix={suffix}
        autoFocus={autoFocus}
      />
      {errorMessage && <Text className="error-message" type="danger">{errorMessage}</Text>}
    </div>
  );
};

export default InputText;

InputText.propTypes = {
  size: PropTypes.oneOf(['small', 'middle', 'large']),
  type: PropTypes.oneOf(['text', 'password', 'search']),
  prefix: PropTypes.any,
  suffix: PropTypes.any,
  label: PropTypes.string,
  password: PropTypes.bool,
  placeholder: PropTypes.string,
  errorMessage: PropTypes.string,
  autoFocus: PropTypes.bool
};

InputText.defaultProps = {
  placeholder: '',
  prefix: null,
  suffix: null,
  password: false,
  size: 'middle',
  message: ''
};