import { RightOutlined } from '@ant-design/icons';
import { Button, Col, Row } from 'antd';
import Text from 'antd/lib/typography/Text';
import React from 'react';
import { useHistory } from 'react-router-dom';
import Pathname from '../../constants/Pathname';
import Card from '../atoms/Card';

const CustomerFilter = () => {
  const history = useHistory();
  return (
    <div>
      <Card>
        <Row align='middle' justify='space-between'>
          <Col>
            <Text strong>Customer type</Text>
          </Col>
          <Col>
            <Button
              type='link'
              onClick={() =>
                history.push(Pathname.CUSTOMER_LIST + '/' + Pathname.CUSTOMER_LIST_FILTER_TYPE, {
                  background: true,
                })
              }>
              All customer type
              <RightOutlined />
            </Button>
          </Col>
        </Row>
      </Card>
      {/* <div className="spacer" /> */}
      {/* <Card>
        <Row align="middle" justify="space-between">
          <Col>
            <Text strong>Credit card ownership</Text>
          </Col>
          <Col>
            <Button
              type="link"
              onClick={() => history.push(
                Pathname.CUSTOMER_LIST+'/'+
                Pathname.CUSTOMER_LIST_FILTER_CREDIT_CARD,
                {
                  background: true
                }
              )}
            >
              All ownership
              <RightOutlined />
            </Button>
          </Col>
        </Row>
      </Card> */}
      <div className='spacer' />
      <Card>
        <Row align='middle' justify='space-between'>
          <Col>
            <Text strong>Birthday</Text>
          </Col>
          <Col>
            <Button
              type='link'
              onClick={() =>
                history.push(
                  Pathname.CUSTOMER_LIST + '/' + Pathname.CUSTOMER_LIST_FILTER_BIRTHDAY,
                  {
                    background: true,
                  },
                )
              }>
              All dates
              <RightOutlined />
            </Button>
          </Col>
        </Row>
      </Card>
      <div className='spacer' />
      <Card>
        <Row align='middle' justify='space-between'>
          <Col>
            <Text strong>Nationality</Text>
          </Col>
          <Col>
            <Button
              type='link'
              onClick={() =>
                history.push(
                  Pathname.CUSTOMER_LIST + '/' + Pathname.CUSTOMER_LIST_FILTER_NATIONALITY,
                  {
                    background: true,
                  },
                )
              }>
              All nationalites
              <RightOutlined />
            </Button>
          </Col>
        </Row>
      </Card>
      <div className='spacer' />
      <Card>
        <Row align='middle' justify='space-between'>
          <Col>
            <Text strong>Gender</Text>
          </Col>
          <Col>
            <Button
              type='link'
              onClick={() =>
                history.push(Pathname.CUSTOMER_LIST + '/' + Pathname.CUSTOMER_LIST_FILTER_GENDER, {
                  background: true,
                })
              }>
              All genders
              <RightOutlined />
            </Button>
          </Col>
        </Row>
      </Card>
      <div className='spacer' />
    </div>
  );
};

export default CustomerFilter;
