import React from 'react';

function SvgAlternateSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        fill="currentColor"
        d="M8 1.333a6.667 6.667 0 100 13.334A6.667 6.667 0 008 1.334zm0 12A5.334 5.334 0 118 2.667a5.334 5.334 0 010 10.668zm2.667-6h-2v-2a.667.667 0 00-1.334 0v2h-2a.667.667 0 100 1.334h2v2a.667.667 0 101.334 0v-2h2a.667.667 0 100-1.333z"
      ></path>
    </svg>
  );
}

export default SvgAlternateSvg;
