import React from 'react';
import { Col, Row } from 'antd';
import PropTypes from 'prop-types';
import PieChartReport from '../molecules/PieChartReport';

const PieChartList = ({ data, isAnimationActive }) => {

  return(
    <div>
      <Row gutter={[24, 24]}>
        {
          data.map(item => item.hide ? null : (
            <Col key={item.title} span={12}>
              <PieChartReport
                id={item.id}
                title={item.title}
                total={item.total}
                items={item.items}
                isAnimationActive={isAnimationActive}
              />
            </Col>
          ))
        }
      </Row>
    </div>
  );
};

PieChartList.propTypes = {
  isPrint: PropTypes.bool,
  data: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    total: PropTypes.string,
    items: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string,
      hide: PropTypes.bool,
      name: PropTypes.string,
      value: PropTypes.number,
      color: PropTypes.string,
      detail: PropTypes.string
    }))
  }))
};

export default PieChartList;