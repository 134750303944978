import { Radio } from 'antd';
import React, { useCallback } from 'react';
import Card from '../atoms/Card';
import HorizontalDateRange from '../molecules/HorizontalDateRange';
import './CustomerFilterBirthday.less';

const CustomerFilterBirthday = ({ valueAll, setValueAll, setFrom, setTo }) => {
  const handleChangeAll = useCallback(e => {
    setValueAll(e.target.value);
  }, []);

  return (
    <div id='customer-filter-birthday'>
      <Radio.Group onChange={handleChangeAll} value={valueAll} className='radio-group'>
        <Card>
          <Radio value='all_birthday'>All birthday dates</Radio>
        </Card>
        <div className='spacer' />
        <Card bodyStyle={{ display: 'grid' }}>
          <Radio value='specific_birthday'>Specific birthday date range</Radio>
          <HorizontalDateRange
            size='large'
            className='date-range'
            onChange={(value, mode) => {
              console.log(value);
              setFrom(mode[0]);
              setTo(mode[1]);
            }}
            disabled={valueAll === 'all_birthday'}
          />
        </Card>
      </Radio.Group>
    </div>
  );
};

export default CustomerFilterBirthday;
