import React from 'react';

function SvgIllustrationTeeTimes() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="408"
      height="354"
      fill="none"
      viewBox="0 0 408 354"
    >
      <g clipPath="url(#clip0)">
        <path
          fill="#06884A"
          d="M8.963 323.507l-.884-.199c9.856-43.828 34.623-83.628 69.74-112.068a201.18 201.18 0 01276.212 22.048 201.18 201.18 0 0146.395 89.485l-.883.201c-20.863-91.601-101.144-155.576-195.23-155.576-92.853 0-175.01 65.653-195.35 156.109z"
        ></path>
        <path
          fill="#62F8B0"
          d="M204 353.228c112.666 0 204-11.304 204-25.248s-91.334-25.247-204-25.247S0 314.036 0 327.98c0 13.944 91.334 25.248 204 25.248z"
        ></path>
        <path
          fill="#859DFF"
          d="M251.97 304.65c0 .435-.031.869-.093 1.299a8.92 8.92 0 01-.847 2.717 9.044 9.044 0 01-2.067 2.711 8.955 8.955 0 01-5.2 2.266 9.031 9.031 0 01-9.778-7.791 9.11 9.11 0 01.048-2.721c.161-.95.475-1.868.929-2.718a9.014 9.014 0 018.898-4.748 8.935 8.935 0 012.718.717 9.03 9.03 0 012.713 1.848 8.978 8.978 0 011.669 2.268c.446.855.749 1.776.898 2.728.075.471.112.947.112 1.424z"
        ></path>
        <path
          fill="#1F4BFF"
          d="M246.556 300.475l-.015 2.722 2.723.015.014-2.723-2.722-.014z"
        ></path>
        <path
          fill="#1F4BFF"
          d="M251.97 304.65c0 .435-.031.869-.093 1.299l-2.628-.015.015-2.723 2.594.015c.075.471.112.947.112 1.424zM251.03 308.666a9.044 9.044 0 01-1.808 2.47l.012-2.479 1.796.009zM238.409 296.834l-.005.874-1.236-.006a8.963 8.963 0 011.241-.868zM243.86 295.665l-.012 2.074-2.723-.016.01-1.925a9.03 9.03 0 012.725-.133zM248.782 297.764l-2.211-.012.007-1.37a9.03 9.03 0 012.204 1.382zM235.672 299.286l-.006 1.131-.704-.004a8.44 8.44 0 01.71-1.127z"
        ></path>
        <path
          fill="#1F4BFF"
          d="M238.403 297.709l-.014 2.722 2.722.015.015-2.723-2.723-.014zM243.848 297.738l-.014 2.722 2.722.015.015-2.723-2.723-.014zM250.96 300.498l-1.681-.008.012-2.26a8.978 8.978 0 011.669 2.268zM235.666 300.416l-.014 2.723 2.722.014.015-2.722-2.723-.015z"
        ></path>
        <path
          fill="#1F4BFF"
          d="M241.114 300.445l-.015 2.723 2.722.014.015-2.722-2.722-.015zM238.374 303.153l-.014 2.723 2.722.014.015-2.722-2.723-.015zM243.821 303.182l-.014 2.723 2.722.014.015-2.722-2.723-.015zM235.652 303.139l-.015 2.722-1.652-.009a9.11 9.11 0 01.048-2.721l1.619.008z"
        ></path>
        <path
          fill="#1F4BFF"
          d="M235.637 305.861l-.015 2.722 2.723.015.015-2.722-2.723-.015zM241.082 305.89l-.015 2.723 2.723.014.014-2.722-2.722-.015zM246.527 305.92l-.015 2.722 2.722.015.015-2.723-2.722-.014zM235.622 308.584l-.006 1.353a8.887 8.887 0 01-.813-1.358l.819.005zM238.345 308.598l-.015 2.723 2.723.014.014-2.722-2.722-.015zM243.792 308.627l-.015 2.722 2.723.015.014-2.722-2.722-.015zM238.33 311.32l-.006 1.096a9.087 9.087 0 01-1.482-1.104l1.488.008zM243.775 311.35l-.012 2.293a8.991 8.991 0 01-2.721-.162l.011-2.146 2.722.015zM248.963 311.377a9.06 9.06 0 01-2.474 1.581l.009-1.594 2.465.013z"
        ></path>
        <path
          fill="#FF8589"
          d="M333.413 187.41l10.884 42.326s.403 29.83 2.821 41.52c2.419 11.69 10.481 39.907 10.481 39.907l9.675 1.613-2.016-39.504s0-23.784-2.015-29.024c-2.016-5.24-6.047-58.853-6.047-58.853l-23.783 2.015z"
        ></path>
        <path
          fill="#5C5C5C"
          d="M333.413 187.41l10.884 42.326s.403 29.83 2.821 41.52c2.419 11.69 10.481 39.907 10.481 39.907l9.675 1.613-2.016-39.504s0-23.784-2.015-29.024c-2.016-5.24-6.047-58.853-6.047-58.853l-23.783 2.015z"
          opacity="0.1"
        ></path>
        <path
          fill="#B80006"
          d="M363.588 10.005c-13.437 0-24.329 12.088-24.329 27v18.946h5.826l3.374-7.02-.844 7.02h37.453l3.067-6.382-.767 6.382h4.217V41.075c0-17.16-12.535-31.07-27.997-31.07z"
        ></path>
        <path
          fill="#FF8589"
          d="M323.134 180.759s-4.838 20.558 1.612 22.574c6.45 2.015 4.837-22.574 4.837-22.574h-6.449z"
        ></path>
        <path
          fill="#C2C2C2"
          d="M331.196 76.355l-3.225 3.628.806 69.737-7.256 33.054 16.124 1.613 8.062-66.513-2.418-33.86-12.093-7.66z"
        ></path>
        <path
          fill="#5C5C5C"
          d="M331.196 76.355l-3.225 3.628.806 69.737-7.256 33.054 16.124 1.613 8.062-66.513-2.418-33.86-12.093-7.66z"
          opacity="0.1"
        ></path>
        <path
          fill="#FF8589"
          d="M361.435 53.303c9.566 0 17.32-7.754 17.32-17.32 0-9.565-7.754-17.32-17.32-17.32-9.565 0-17.32 7.755-17.32 17.32 0 9.566 7.755 17.32 17.32 17.32z"
        ></path>
        <path
          fill="#FF8589"
          d="M351.505 46.375s5.081 26.327 2.771 29.098c-2.309 2.771 24.479-5.08 24.479-5.08s-8.775-18.013-4.157-28.175l-23.093 4.157zM395.289 161.813s4.838 20.558-1.612 22.574c-6.45 2.015-4.837-22.574-4.837-22.574h6.449zM358.002 182.573s-6.853 41.117-6.45 47.163c.403 6.047 0 6.853 0 6.853s-6.852 17.737-8.868 27.814c-2.015 10.078-6.45 22.977-6.45 22.977s-5.24 11.69-.806 11.69 10.481-1.612 10.481-1.612a20.588 20.588 0 014.434-12.834c4.837-6.112 19.349-34.733 19.349-38.361s2.419-14.108 2.419-14.108l9.271-44.745-23.38-4.837z"
        ></path>
        <path
          fill="#292929"
          d="M329.785 155.162s-6.853 34.667-.806 35.473c6.046.806 14.511-5.24 25.395 1.612 10.884 6.853 29.024 5.241 29.024 5.241s12.496-31.846 1.209-39.505c-11.287-7.659-54.822-2.821-54.822-2.821zM337.444 294.233a10.736 10.736 0 015.643-.806 7.367 7.367 0 005.241-1.209s-3.225 11.69-2.822 16.124c.403 4.434-1.209 23.38-9.675 21.364-8.465-2.015-10.883-8.062-7.659-18.543 3.225-10.48 2.419-22.573 2.419-22.573s5.883-6.077 6.973-5.256c1.089.821-.12 10.899-.12 10.899z"
        ></path>
        <path
          fill="#292929"
          d="M364.452 309.148s-4.031-8.868-8.868-.806c-4.838 8.062-5.241 10.077-5.241 10.077s-13.705 12.497-4.031 13.706c9.675 1.209 17.334-.403 17.334-2.419 0-2.015 4.837-1.612 5.24-3.224.403-1.613-.806-16.528-.806-16.528l-3.628-.806z"
        ></path>
        <path
          fill="#C2C2C2"
          d="M373.522 59.827s-16.931 3.225-18.946 2.822c-.454-.08-.913-.129-1.374-.146-3.942-.179-7.851.79-11.252 2.79-5.614 3.261-13.979 9.064-13.979 14.69 0 8.465 10.884 22.977 10.884 22.977s-10.078 34.667-9.272 37.891c.806 3.225-6.046 14.109-1.612 17.737 4.434 3.628 46.76 0 54.419 3.628 7.659 3.628 4.031-48.373 4.031-48.373s20.558-47.163 10.078-51.597a135.79 135.79 0 00-8.727-3.36 18.449 18.449 0 00-14.25.941z"
        ></path>
        <path
          fill="#C2C2C2"
          d="M389.243 62.649l9.249 1.528s4.053 4.115 4.053 6.13c0 2.016-.806 89.087-2.015 91.909-1.21 2.822-14.512 1.612-14.512 1.612l2.419-49.582.806-51.597z"
        ></path>
        <path
          fill="#B80006"
          d="M370.504 17.442c-2.442-3.145-6.045-5.137-10.067-5.137h-.478c-9.21 0-16.675 8.334-16.675 18.614h3.086l.498-3.794.731 3.794h18.302l1.533-3.21-.383 3.21h3.6c1.68 8.344-.734 16.688-7.242 25.032h6.134l3.066-6.419-.766 6.419h11.691l2.3-14.763c0-11.056-6.423-20.43-15.33-23.746z"
        ></path>
        <path
          fill="#B80006"
          d="M373.866 18.997a9.112 9.112 0 009.112-9.112 9.113 9.113 0 10-9.112 9.112z"
        ></path>
        <path
          fill="#5C5C5C"
          d="M140.376 327.98c13.107 0 23.733-3.391 23.733-7.574s-10.626-7.574-23.733-7.574c-13.107 0-23.732 3.391-23.732 7.574s10.625 7.574 23.732 7.574z"
          opacity="0.2"
        ></path>
        <path fill="#0025B8" d="M144.95 140H137v181.149h7.95V140z"></path>
        <path
          fill="#1F4BFF"
          d="M257.594 73.503v-.061l-6.68-.036-6.679-.036-6.68-.036-6.679-.036-6.68-.036-6.679-.036-6.68-.036-6.679-.036-6.68-.036-6.68-.035-6.679-.036-6.681.163a43.456 43.456 0 00-6.673 1.014l-.012.003-.059.014a40.404 40.404 0 00-1.37.345 42.751 42.751 0 00-5.262 1.788 43.173 43.173 0 00-6.271 3.21l-.428.268a43.577 43.577 0 00-7.849 6.368 43.607 43.607 0 00-5.333 6.651l-.283 6.678 6.679.036.036-6.68 6.68.037-.036 6.68 6.679.035.036-6.68 6.68.036-.036 6.68 6.68.036.035-6.68 6.68.036-.036 6.68 6.68.036.036-6.68 6.679.036-.036 6.68 6.68.036.036-6.68 6.679.036-.036 6.68 6.68.035.036-6.68 6.679.037-.035 6.68 6.679.035.036-6.68 6.68.037-.036 6.679 6.679.036.036-6.68 6.68.037-.036 6.679 6.679.036.036-6.68 3.474.02.036-6.68.035-6.68.036-6.618-3.473-.019zM140.617 99.532a43.12 43.12 0 00-2.231 6.668l5.476.029.036-6.68-3.281-.017z"
        ></path>
        <path
          fill="#1F4BFF"
          d="M150.577 99.585l-.036 6.68 6.68.036.036-6.68-6.68-.036zM163.937 99.657l-.036 6.68 6.679.036.036-6.68-6.679-.036zM177.296 99.73l-.036 6.679 6.679.036.036-6.68-6.679-.036zM190.655 99.8l-.036 6.68 6.679.036.036-6.68-6.679-.035zM204.014 99.873l-.036 6.679 6.68.036.035-6.68-6.679-.035zM217.373 99.945l-.036 6.679 6.68.036.036-6.68-6.68-.035zM230.732 100.016l-.036 6.68 6.68.036.036-6.68-6.68-.036zM244.091 100.088l-.036 6.68 6.68.036.036-6.68-6.68-.036zM260.722 106.858a44 44 0 00.186-3.828l.016-2.851-3.474-.019-.036 6.68 3.308.018zM143.862 106.229l-.036 6.68 6.68.036.035-6.68-6.679-.036zM157.221 106.301l-.036 6.679 6.68.036.036-6.679-6.68-.036z"
        ></path>
        <path
          fill="#1F4BFF"
          d="M170.58 106.373l-.036 6.679 6.68.036.036-6.679-6.68-.036zM183.939 106.444l-.036 6.68 6.68.036.036-6.68-6.68-.036zM197.298 106.516l-.035 6.68 6.679.036.036-6.68-6.68-.036zM210.658 106.588l-.036 6.68 6.679.036.036-6.68-6.679-.036zM224.017 106.66l-.036 6.68 6.679.036.036-6.68-6.679-.036zM237.376 106.732l-.036 6.679 6.679.036.036-6.679-6.679-.036zM250.735 106.804l-.036 6.679 6.68.036.036-6.679-6.68-.036zM137.146 112.873l-.035 6.679 6.679.036.036-6.679-6.68-.036zM150.506 112.944l-.036 6.68 6.679.036.036-6.68-6.679-.036zM163.865 113.016l-.036 6.68 6.679.036.036-6.68-6.679-.036z"
        ></path>
        <path
          fill="#1F4BFF"
          d="M177.224 113.088l-.036 6.68 6.679.036.036-6.68-6.679-.036zM190.583 113.16l-.036 6.68 6.68.036.035-6.68-6.679-.036zM203.942 113.232l-.036 6.679 6.68.036.036-6.679-6.68-.036zM217.301 113.304l-.036 6.679 6.68.036.036-6.679-6.68-.036zM230.66 113.376l-.036 6.679 6.68.036.036-6.679-6.68-.036zM244.019 113.447l-.035 6.68 6.679.036.036-6.68-6.68-.036zM259.576 113.531l-2.197-.012-.036 6.564a43.164 43.164 0 002.233-6.552zM143.79 119.588l-.036 6.68 6.68.035.036-6.679-6.68-.036zM157.149 119.66l-.036 6.68 6.68.036.036-6.68-6.68-.036z"
        ></path>
        <path
          fill="#1F4BFF"
          d="M170.508 119.732l-.036 6.679 6.68.036.036-6.679-6.68-.036zM183.868 119.804l-.036 6.679 6.679.036.036-6.679-6.679-.036zM197.227 119.875l-.036 6.68 6.679.036.036-6.68-6.679-.036zM210.586 119.947l-.036 6.68 6.679.036.036-6.68-6.679-.036zM223.945 120.019l-.036 6.68 6.68.036.035-6.68-6.679-.036zM237.304 120.091l-.036 6.68 6.68.035.035-6.679-6.679-.036zM253.664 126.859a43.456 43.456 0 003.628-6.661l-6.629-.035-.036 6.679 3.037.017zM137.075 126.232l-.036 6.679 6.679.036.036-6.679-6.679-.036zM150.434 126.304l-.036 6.679 6.679.036.036-6.68-6.679-.035zM163.793 126.375l-.036 6.68 6.679.036.036-6.68-6.679-.036zM177.152 126.447l-.036 6.68 6.68.036.036-6.68-6.68-.036zM190.511 126.519l-.036 6.68 6.68.036.036-6.68-6.68-.036z"
        ></path>
        <path
          fill="#1F4BFF"
          d="M203.87 126.591l-.036 6.68 6.68.035.036-6.679-6.68-.036zM217.231 126.662l-.036 6.68 6.68.035.035-6.679-6.679-.036zM230.588 126.735l-.035 6.679 6.679.036.036-6.679-6.68-.036zM243.912 133.486l4.322.023 2.371-2.583.022-4.083-6.679-.037-.036 6.68zM143.718 132.948l-.033 6.278-6.68-.036-.002.401-.034 6.279v.045l6.68.036v-.045l.033-6.279 6.68.036.036-6.68-6.68-.035zM157.077 133.019l-.036 6.68 6.68.036.036-6.68-6.68-.036zM170.436 133.091l-.035 6.68 6.679.035.036-6.679-6.68-.036zM183.796 133.163l-.036 6.679 6.679.036.036-6.679-6.679-.036zM197.155 133.234l-.036 6.68 6.679.036.036-6.68-6.679-.036zM210.514 133.307l-.036 6.679 6.68.036.035-6.68-6.679-.035z"
        ></path>
        <path
          fill="#1F4BFF"
          d="M223.875 133.378l-.036 6.679 6.679.036.036-6.68-6.679-.035zM237.196 140.13l2.573.014a43.401 43.401 0 004.122-2.812l.021-3.846-6.68-.036-.036 6.68zM150.362 139.663l-.034 6.324 6.679.036.034-6.324-6.679-.036zM163.721 139.735l-.034 6.324 6.68.036.034-6.324-6.68-.036zM177.08 139.806l-.034 6.325 6.68.036.034-6.325-6.68-.036zM190.439 139.878l-.034 6.324 6.68.036.034-6.324-6.68-.036zM203.798 139.95l-.034 6.324 6.68.036.034-6.324-6.68-.036zM217.124 146.346a44.264 44.264 0 004.59-.214l2.092-.259.031-5.815-6.679-.036-.034 6.324zM230.494 144.33a43.144 43.144 0 006.694-2.751l.008-1.449-6.679-.036-.023 4.236z"
        ></path>
        <path
          fill="#859DFF"
          d="M163.829 119.696l-.036 6.679 6.679.036.036-6.679-6.679-.036zM203.978 106.552l-.036 6.68 6.68.036.036-6.68-6.68-.036zM138.386 106.2a43.675 43.675 0 00-1.098 6.674l6.538.035.036-6.68-5.476-.029zM223.806 145.873a43.168 43.168 0 006.688-1.543l.023-4.236-6.68-.036-.031 5.815zM210.622 113.268l-.036 6.679 6.679.036.036-6.679-6.679-.036zM210.55 126.627l-.036 6.679 6.679.036.036-6.679-6.679-.036zM244.127 93.409l-.036 6.679 6.68.036.036-6.68-6.68-.035zM257.486 93.48l-.036 6.68 3.474.019.036-6.68-3.474-.018zM237.412 100.052l-.036 6.68 6.679.036.036-6.68-6.679-.036z"
        ></path>
        <path
          fill="#859DFF"
          d="M250.771 100.124l-.036 6.68 6.68.036.035-6.68-6.679-.036z"
        ></path>
        <path
          fill="#859DFF"
          d="M244.055 106.768l-.036 6.679 6.68.036.036-6.679-6.68-.036zM259.576 113.531a43.472 43.472 0 001.146-6.674l-3.308-.017-.035 6.679 2.197.012zM237.34 113.412l-.036 6.679 6.679.036.036-6.68-6.679-.035zM250.699 113.483l-.036 6.68 6.68.036.036-6.68-6.68-.036zM243.984 120.127l-.036 6.679 6.679.036.036-6.679-6.679-.036zM237.268 126.771l-.036 6.679 6.68.036.036-6.679-6.68-.036zM253.664 126.859l-3.037-.017-.022 4.084a43.68 43.68 0 003.059-4.067z"
        ></path>
        <path
          fill="#859DFF"
          d="M230.554 133.413l-.036 6.68 6.68.036.036-6.68-6.68-.036zM244.416 136.922a43.794 43.794 0 003.817-3.413l-4.321-.023-.021 3.846c.176-.135.351-.272.525-.41zM163.972 92.978l-.035 6.68 6.679.035.036-6.68-6.68-.035zM177.332 93.05l-.036 6.679 6.679.036.036-6.68-6.679-.036zM190.691 93.121l-.036 6.68 6.679.036.036-6.68-6.679-.036zM204.05 93.193l-.036 6.68 6.679.036.036-6.68-6.679-.036zM217.409 93.265l-.036 6.68 6.68.036.035-6.68-6.679-.036zM230.768 93.337l-.036 6.679 6.68.036.036-6.68-6.68-.035z"
        ></path>
        <path
          fill="#859DFF"
          d="M170.616 99.693l-.036 6.68 6.68.036.036-6.68-6.68-.036zM183.975 99.765l-.036 6.68 6.68.035.036-6.68-6.68-.035zM197.334 99.837l-.036 6.679 6.68.036.036-6.68-6.68-.035zM210.694 99.909l-.036 6.679 6.679.036.036-6.68-6.679-.035zM224.053 99.98l-.036 6.68 6.679.036.036-6.68-6.679-.036zM150.541 106.265l-.035 6.68 6.679.035.036-6.679-6.68-.036zM177.26 106.409l-.036 6.679 6.679.036.036-6.679-6.679-.036zM190.619 106.48l-.036 6.68 6.679.036.036-6.68-6.679-.036z"
        ></path>
        <path
          fill="#859DFF"
          d="M217.337 106.624l-.036 6.68 6.68.036.036-6.68-6.68-.036zM230.696 106.696l-.036 6.679 6.68.036.036-6.679-6.68-.036zM143.826 112.909l-.036 6.679 6.68.036.036-6.68-6.68-.035zM157.185 112.98l-.036 6.68 6.68.036.036-6.68-6.68-.036zM183.903 113.124l-.036 6.68 6.68.036.036-6.68-6.68-.036zM197.262 113.196l-.035 6.679 6.679.036.036-6.679-6.68-.036z"
        ></path>
        <path
          fill="#859DFF"
          d="M223.981 113.339l-.036 6.68 6.679.036.036-6.68-6.679-.036zM137.111 119.552l-.036 6.68 6.679.036.036-6.68-6.679-.036zM150.47 119.624l-.036 6.68 6.679.036.036-6.68-6.679-.036zM140.617 99.532l3.281.017.303-6.678a48.92 48.92 0 00-3.584 6.66zM150.613 92.906l-.036 6.68 6.68.035.036-6.68-6.68-.035zM143.898 99.55l-.036 6.679 6.68.036.035-6.68-6.679-.035z"
        ></path>
        <path
          fill="#859DFF"
          d="M157.257 99.621l-.036 6.68 6.68.036.035-6.68-6.679-.036zM163.901 106.337l-.036 6.679 6.679.036.036-6.679-6.679-.036z"
        ></path>
        <path
          fill="#859DFF"
          d="M170.544 113.052l-.036 6.68 6.68.036.036-6.68-6.68-.036zM177.188 119.768l-.036 6.679 6.68.036.035-6.679-6.679-.036zM190.547 119.84l-.036 6.679 6.68.036.036-6.68-6.68-.035zM203.906 119.911l-.036 6.68 6.68.036.036-6.68-6.68-.036zM217.265 119.983l-.036 6.68 6.68.036.036-6.68-6.68-.036zM230.624 120.055l-.035 6.68 6.679.036.036-6.68-6.68-.036zM143.754 126.268l-.036 6.679 6.68.036.036-6.679-6.68-.036zM157.113 126.339l-.036 6.68 6.68.036.036-6.68-6.68-.036z"
        ></path>
        <path
          fill="#859DFF"
          d="M170.472 126.412l-.035 6.679 6.679.036.036-6.68-6.68-.035zM183.832 126.483l-.036 6.68 6.679.036.036-6.68-6.679-.036zM197.191 126.555l-.036 6.68 6.679.036.036-6.68-6.679-.036zM223.909 126.699l-.036 6.679 6.68.036.036-6.679-6.68-.036zM137.039 132.911l-.036 6.68 6.679.036.036-6.68-6.679-.036zM150.398 132.983l-.036 6.68 6.679.036.036-6.68-6.679-.036zM163.757 133.055l-.036 6.68 6.68.036.035-6.68-6.679-.036z"
        ></path>
        <path
          fill="#859DFF"
          d="M177.116 133.127l-.036 6.679 6.68.036.036-6.679-6.68-.036zM190.475 133.199l-.036 6.679 6.68.036.036-6.679-6.68-.036zM203.834 133.271l-.036 6.679 6.68.036.036-6.679-6.68-.036zM217.193 133.342l-.035 6.68 6.679.036.036-6.68-6.68-.036zM143.682 139.627l-.034 6.324 6.68.036.034-6.324-6.68-.036zM157.041 139.699l-.034 6.324 6.68.036.034-6.324-6.68-.036zM170.401 139.771l-.034 6.324 6.679.036.034-6.325-6.679-.035z"
        ></path>
        <path
          fill="#859DFF"
          d="M183.76 139.842l-.034 6.325 6.679.035.034-6.324-6.679-.036zM197.119 139.914l-.034 6.324 6.679.036.034-6.324-6.679-.036zM210.478 139.986l-.034 6.324 6.68.036.034-6.324-6.68-.036zM239.769 140.143l-2.573-.013-.008 1.449c.879-.449 1.74-.928 2.581-1.436z"
        ></path>
        <path
          fill="#C2C2C2"
          d="M326.163 198.898l-.939-.371-32.882 83.2c-1.56-3.294-7.107-11.712-23.242-12.302a3.1 3.1 0 00-3.186 2.898l-.279 4.198a8.907 8.907 0 007.861 9.451c6.601.764 15.31.901 19.225-2.793l.115.046 33.327-84.327z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0">
          <path
            fill="#fff"
            d="M0 0H408V352.455H0z"
            transform="translate(0 .772)"
          ></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgIllustrationTeeTimes;
