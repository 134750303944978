import { Col, message, Row } from 'antd';
import React, { useState } from 'react';
import HorizontalDateRange from '../molecules/HorizontalDateRange';
import Modal from '../organisms/Modal';
import FileSelectExport from '../molecules/FileSelectExport';
import { useFormik } from 'formik';
import { useQuery } from '@apollo/client';
import QueryGql from '../../constants/QueryGql';
import moment from 'moment';
import { rebuildCancellationReportTemplate } from '../templates/CancellationReport';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { cancelationReportList } from '../organisms/CancellationReportList';
import sleep from '../../utils/sleep';
import { downloadCSV } from '../../utils/csv';
import CancellationReportTemplate from '../templates/CancellationReport';

const ExportCancellationReport = () => {
  const [dataReport, setDataReport] = useState({});

  const { refetch } = useQuery(QueryGql.SEARCH_CANCEL_RESERVATION_REPORT, {
    skip: true,
  });

  const { values, isSubmitting, setFieldValue, submitForm } = useFormik({
    initialValues: {
      file: { name: 'File', key: 'pdf', label: 'PDF' },
    },
    onSubmit: async ({ file, date }, { setSubmitting }) => {
      try {
        const res = await refetch({
          data: {
            start_date: moment(date[0]).startOf('day').toISOString(),
            end_date: moment(date[1]).endOf('day').toISOString(),
          },
        });

        const data = res.data.searchCancelReservationReport.data;
        setDataReport(data);
        const headers = cancelationReportList.map(item => ({
          label: item.title,
          key: item.dataIndex,
        }));
        const body = rebuildCancellationReportTemplate(data.cancel_reservations);

        if (file.key === 'csv') {
          downloadCSV({ headers, body, name: file.name });
        }

        if (file.key === 'pdf') {
          await sleep(1000);
          const columns = cancelationReportList.map(item => ({
            header: item.title,
            dataKey: item.dataIndex,
          }));
          const canvas = await html2canvas(document.getElementById('print-check-ins-cards'));

          const imgData = canvas.toDataURL('image/png');
          const doc = new jsPDF('p', 'mm', 'a4');
          const width = doc.internal.pageSize.getWidth();

          doc.addImage(imgData, 'PNG', 5, 5, width - 10, 0);

          autoTable(doc, {
            headStyles: {
              fillColor: [245, 245, 245],
              textColor: [0, 13, 35],
              fontStyle: 'bold',
              minCellHeight: 12,
              valign: 'middle',
              cellPadding: { left: 2 },
            },
            alternateRowStyles: {
              fillColor: [255, 255, 255],
              cellPadding: { left: 2, top: 2, bottom: 2 },
            },
            margin: { top: 28, left: 5, right: 5 },
            columns: columns,
            body,
          });

          doc.save(`${file.name}.pdf`);
        }

        setSubmitting(false);
      } catch (error) {
        setSubmitting(false);
        message.error('Failed export');
      }
    },
  });

  return (
    <Modal
      title='Export Cancellation Report'
      width={600}
      hiddenLeftFooterButton
      rightFooterButton={{
        label: 'Export Cancellation Report',
        loading: isSubmitting,
        state: values.date ? 'default' : 'disabled',
        onClick: submitForm,
      }}>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <FileSelectExport value={values.file} onChange={item => setFieldValue('file', item)} />
        </Col>
        <Col span={24}>
          <HorizontalDateRange label='Date' onChange={v => setFieldValue('date', v)} />
        </Col>
      </Row>
      <div style={{ position: 'fixed', bottom: -5000, width: '80%' }}>
        <CancellationReportTemplate dataReport={dataReport} isPrint={true} />
      </div>
    </Modal>
  );
};

export default ExportCancellationReport;
