import React, { useState } from 'react';
import { notification } from 'antd';
import Title from 'antd/lib/typography/Title';
import Text from 'antd/lib/typography/Text';
import AppWrapper from 'components/templates/AppWrapper';
import './CourseProfile.less';
import { UilImagePlus } from '@iconscout/react-unicons';
import Button from 'components/atoms/Button';
import { contactProfile } from './Const';
import AddContact from './AddContact';
import { useQuery, useMutation } from '@apollo/client';
import Query from './Query';
import { Space } from 'antd';
import uploadFile from 'utils/uploadFile';

const CourseProfile = () => {
  const [modalState, setModalState] = useState(null);
  const [file, setFile] = useState(null);
  const { data: getCourseProfile, refetch } = useQuery(Query.GET_COURSE_PROFILE);
  const courseProfileData = getCourseProfile?.getCourseProfile?.data || {};

  const [updateCourseProfile] = useMutation(Query.UPDATE_COURSE_PROFILE, {
    onCompleted: () => {
      refetch();
      setModalState(null);
    },
    onError: err => {
      console.log(err);
    },
  });

  const uploadAva = async () => {
    const res = await uploadFile(file);

    if (res.success) {
      const updated = await updateCourseProfile({
        variables: {
          data: {
            picture_url: res.data.uploadFile.data,
          },
        },
      });

      if (updated.data.updateCourseProfile.success) {
        notification.success({
          message: 'Logo Uploaded',
          description: 'Course logo success uploaded',
          btn: (
            <Button
              type='text'
              className='notification-button-dismiss-success'
              onClick={() => notification.close('Logo-Uploaded')}>
              Close
            </Button>
          ),
          key: 'Logo-Uploaded',
          closeIcon: <div />,
        });
      }
    }
  };

  return (
    <AppWrapper>
      <div id='spacer-course-profile'>
        <Title level={3}>Course profile</Title>

        <Text>
          Enter your course contact and social media profile which will appear on receipts,
          invoices, and emails.
        </Text>

        <div className='profile-pict'>
          <div className='image-uploader'>
            <Text strong>Course logo</Text>

            {!courseProfileData?.picture_url ? (
              <label className='drop-area'>
                <input
                  onChange={e => setFile(e.target.files[0])}
                  type='file'
                  style={{ display: 'none' }}
                />

                <UilImagePlus className='add-image-icon' />

                {!!file && (
                  <img src={URL.createObjectURL(file)} alt='image preview' className='drop-area' />
                )}
              </label>
            ) : (
              <img src={courseProfileData?.picture_url} alt='avatar' className='drop-area' />
            )}
          </div>

          <Space>
            {!!courseProfileData?.picture_url && (
              <Button
                onClick={() => {
                  updateCourseProfile({
                    variables: {
                      data: {
                        picture_url: '',
                      },
                    },
                  });
                }}
                type='outline'
                color='blue'>
                Remove image
              </Button>
            )}

            <Button disabled={!file} onClick={uploadAva} type='secondary' color='blue'>
              Add Image
            </Button>
          </Space>
        </div>

        {contactProfile.map((v, i) => (
          <div key={i} className='contact-profile'>
            <Text strong>{v.label}</Text>

            <div className='field'>
              <div className='start'>
                {v.icon}

                {!courseProfileData[v.field] ? (
                  <span>{v.label}</span>
                ) : (
                  <span className='contact-value'>{courseProfileData[v.field]}</span>
                )}
              </div>

              <Button
                type='link'
                onClick={() => setModalState({ ...v, value: courseProfileData[v.field] })}>
                {`Add ${v.label}`}
              </Button>
            </div>
          </div>
        ))}
      </div>

      <AddContact
        act='add'
        modalState={modalState}
        updateCourseProfile={updateCourseProfile}
        onClose={() => setModalState(null)}
      />
    </AppWrapper>
  );
};

export default CourseProfile;
