import React from 'react';

function SvgTeeTimeFilled() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M8 1.333a6.667 6.667 0 100 13.333A6.667 6.667 0 008 1.333zm2.067 7.087l-1.4-.807V4.667a.667.667 0 00-1.334 0V8.08c.004.046.016.09.034.133.013.04.031.078.053.114.018.038.04.073.067.106l.106.087.06.06 1.734 1a.667.667 0 00.333.087.667.667 0 00.333-1.247h.014z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default SvgTeeTimeFilled;
