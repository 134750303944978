import React from 'react';

function SvgDuplicate() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        fill="currentColor"
        d="M5.453 2.667h1.4a.667.667 0 100-1.334h-1.4a.667.667 0 000 1.334zM2 7.52a.667.667 0 00.666-.667v-1.4a.667.667 0 00-1.333 0v1.4A.667.667 0 002 7.52zm7.64-4.853a.667.667 0 101.333 0V2a.666.666 0 00-.667-.667H9.64a.667.667 0 100 1.334zM14 5.027h-3.027a.666.666 0 10-1.333 0H5.693a.667.667 0 00-.667.666V9.64a.667.667 0 000 1.333V14a.667.667 0 00.667.667H14a.667.667 0 00.666-.667V5.693A.667.667 0 0014 5.027zm-.667 8.306H6.36V6.36h6.973v6.973zm-10.667-12H2A.667.667 0 001.333 2v.667a.667.667 0 001.333 0 .667.667 0 000-1.334zm0 8.307a.667.667 0 00-1.333 0v.667a.667.667 0 00.667.666h.666a.667.667 0 100-1.333z"
      ></path>
    </svg>
  );
}

export default SvgDuplicate;
