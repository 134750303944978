import React from 'react';

function BillingInvoice({
  verify,
  clientName,
  clientAddress,
  itemName,
  itemPrice,
  amount,
  billingNo,
  date,
  subTotal,
  grandTotal,
}) {
  return (
    <div style={{ fontFamily: 'tahoma', padding: '20px' }}>
      <div style={{ display: 'flex' }}>
        <svg
          width='151'
          height='32'
          viewBox='0 0 151 32'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'>
          <path
            d='M28.1101 25.2196L0 0C6.62488 6.54545 14.2285 14.5302 20.036 20.6288C22.2571 22.9611 24.2155 25.0176 25.7558 26.6122C22.2926 28.4459 18.4654 29.5768 15.0732 29.5768C9.84199 29.5768 7.15407 26.8873 7.85073 23.0785C6.11771 28.2204 9.25413 32 15.9647 32C19.7558 32 24.0108 30.7938 27.9164 28.8171C28.6487 29.5462 29.1239 29.987 29.2855 30.072C30.392 30.6536 33.7116 30.6948 34.1671 30.6539L30.5007 27.3645C33.8196 25.3026 36.711 22.702 38.5911 19.8755C43.0451 13.1793 40.1363 7.75093 32.0941 7.75093C28.5446 7.75093 24.5883 8.80837 20.8929 10.5655C23.5662 9.49227 26.3271 8.86782 28.8478 8.86782C35.716 8.86782 38.2001 13.5037 34.3964 19.2223C32.9096 21.4576 30.6821 23.5274 28.1101 25.2196Z'
            fill='#28293D'
          />
          <path
            d='M54.2688 30.9341C55.3504 30.9341 56.4214 30.7986 57.4818 30.5274C58.552 30.2562 59.5378 29.8494 60.4393 29.3071C61.3424 28.755 62.103 28.0625 62.7211 27.2296C63.3508 26.387 63.7641 25.404 63.9612 24.2805C64.1464 23.2249 64.0977 22.3532 63.8152 21.6656C63.5425 20.9779 63.133 20.4259 62.5866 20.0094C62.0517 19.5833 61.4703 19.254 60.8424 19.0216C60.2163 18.7794 59.6406 18.5857 59.1154 18.4405L55.6014 17.4526C55.1695 17.3364 54.7525 17.1911 54.3504 17.0168C53.9483 16.8424 53.6294 16.6148 53.3935 16.334C53.1593 16.0434 53.0821 15.6705 53.162 15.2154C53.2486 14.7214 53.4955 14.2953 53.9025 13.9369C54.3193 13.5786 54.8192 13.3074 55.4022 13.1234C55.9966 12.9297 56.6068 12.8425 57.2327 12.8619C58.1912 12.8909 59.0269 13.1718 59.7398 13.7045C60.4526 14.2372 60.8379 15.012 60.8955 16.0289L65.3592 15.3025C65.1928 13.3365 64.4639 11.8304 63.1724 10.7844C61.881 9.73847 60.1365 9.20095 57.939 9.17189C56.4855 9.16221 55.1189 9.38496 53.8395 9.84016C52.56 10.2954 51.4798 10.9878 50.5989 11.9176C49.718 12.8474 49.1543 14.0144 48.908 15.4187C48.733 16.4163 48.7549 17.2444 48.9737 17.9029C49.204 18.5518 49.54 19.0748 49.9816 19.4719C50.4233 19.869 50.8958 20.1741 51.3993 20.3872C51.9125 20.6002 52.3654 20.7649 52.758 20.8811L57.994 22.4791C58.7775 22.7212 59.2746 23.0263 59.4854 23.3944C59.6978 23.7527 59.7667 24.1449 59.6919 24.5711C59.59 25.1522 59.3026 25.6413 58.8298 26.0384C58.3668 26.4355 57.8077 26.7357 57.1526 26.9391C56.4974 27.1425 55.8405 27.2442 55.1816 27.2442C54.0017 27.2442 53.0065 26.9197 52.196 26.2708C51.3856 25.6219 50.9382 24.697 50.8539 23.4961L46.558 24.1062C46.5263 25.5202 46.8235 26.7405 47.4497 27.7671C48.0874 28.7841 48.9872 29.5686 50.149 30.1206C51.3223 30.663 52.6956 30.9341 54.2688 30.9341Z'
            fill='#28293D'
          />
          <path
            d='M71.1704 30.4983H75.1233L81.7155 16.3776L83.3534 30.4983H87.3062L97.2004 9.57866H92.982L86.4767 23.9609L85.0174 9.57866L80.794 9.60772L74.2937 23.9609L72.8344 9.57866H68.6161L71.1704 30.4983Z'
            fill='#28293D'
          />
          <path
            d='M97.3729 30.4983H101.385L105.055 9.57866H101.043L97.3729 30.4983Z'
            fill='#28293D'
          />
          <path
            d='M106.987 30.4983H111.057L113.504 16.5519L120.29 30.4983H124.361L128.031 9.57866H123.96L121.514 23.5251L114.727 9.57866H110.656L106.987 30.4983Z'
            fill='#28293D'
          />
          <path
            d='M138.43 30.9341C140.475 30.9341 142.327 30.4983 143.984 29.6267C145.643 28.7453 147.021 27.5299 148.119 25.9803C149.217 24.4307 149.942 22.6535 150.293 20.6487C150.302 20.6002 150.329 20.4162 150.376 20.0966C150.422 19.777 150.446 19.5542 150.449 19.4283H142.808L142.273 22.4791H145.636C145.021 24.1353 144.16 25.3459 143.052 26.111C141.954 26.8761 140.719 27.2442 139.348 27.2151C137.337 27.186 135.938 26.5275 135.152 25.2394C134.375 23.9512 134.177 22.2176 134.56 20.0385C134.942 17.8594 135.75 16.1112 136.984 14.7941C138.228 13.4769 139.855 12.8328 141.866 12.8619C143.221 12.8716 144.267 13.2444 145.003 13.9805C145.751 14.7069 146.209 15.6802 146.379 16.9005L150.581 16.3194C150.451 14.0338 149.714 12.2663 148.369 11.0169C147.025 9.76752 144.986 9.14284 142.253 9.14284C140.404 9.14284 138.643 9.56898 136.969 10.4213C135.306 11.2735 133.879 12.5132 132.689 14.1403C131.51 15.7577 130.718 17.7238 130.312 20.0385C130.001 21.8109 129.992 23.375 130.285 24.7309C130.588 26.0868 131.121 27.2248 131.884 28.1449C132.657 29.0649 133.606 29.7623 134.732 30.2368C135.86 30.7017 137.093 30.9341 138.43 30.9341Z'
            fill='#28293D'
          />
        </svg>

        <div style={{ marginLeft: '20px', color: '#555770' }}>
          <p style={{ fontSize: '12px', fontWeight: 600 }}>PT. Dari Visi Teknologi</p>
          <p style={{ marginTop: '-10px', maxWidth: '300px', fontSize: '10px', fontWeight: 300 }}>
            Equity Tower 22nd Floor (B,C,D), Jl. Sudirman No. 22, RT.5/RW.3, Senayan, CBD, Jakarta
            Selatan, 12190
          </p>
        </div>
      </div>
      <div style={{ borderBottom: '0.5px solid #555770', marginTop: '16px' }}></div>
      <div style={{ marginTop: '24px', display: 'flex', alignItems: 'center' }}>
        <p style={{ fontSize: '30px', fontWeight: 600, color: '#28293D' }}>Invoice</p>
        <div style={{ marginTop: '-13px', marginLeft: '16px' }}>
          <p
            style={{
              fontSize: '12px',
              fontWeight: 700,
              color: '#fff',
              backgroundColor:
                verify === 'UNPAID' ? '#FF2A12' : verify === 'PAID' ? '#36B37E' : null,
              padding: '4px 6px',
              borderRadius: '100px',
            }}>
            {verify}
          </p>
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          marginTop: '-20px',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}>
        <div>
          <p style={{ fontSize: '12px', fontWeight: 500, color: '#555770' }}>For</p>
          <p style={{ fontSize: '14px', fontWeight: 600, marginTop: '-5px', color: '#28293D' }}>
            {clientName}
          </p>
          <p style={{ fontSize: '12px', fontWeight: 400, marginTop: '-10px', color: '#28293D' }}>
            {clientAddress}
          </p>
        </div>
        <div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ display: 'flex', width: '50px', justifyContent: 'space-between' }}>
              <p style={{ fontSize: '12px', whiteSpace: 'nowrap', fontWeight: 500, marginTop: '-10px', color: '#555770' }}>
                Biling No.
              </p>
              <p style={{ fontSize: '12px', fontWeight: 500, marginTop: '-10px' }}>:</p>
            </div>
            <p
              style={{
                fontSize: '12px',
                fontWeight: 600,
                marginTop: '-10px',
                marginLeft: '20px',
                color: '#28293D',
              }}>
              {billingNo}
            </p>
          </div>
          <div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div style={{ display: 'flex', width: '50px', justifyContent: 'space-between' }}>
                <p
                  style={{
                    fontSize: '12px',
                    fontWeight: 500,
                    marginTop: '-10px',
                    color: '#555770',
                  }}>
                  Date
                </p>
                <p style={{ fontSize: '12px', fontWeight: 500, marginTop: '-10px' }}>:</p>
              </div>
              <p
                style={{
                  fontSize: '12px',
                  fontWeight: 600,
                  marginTop: '-10px',
                  marginLeft: '20px',
                  color: '#28293D',
                }}>
                {date}
              </p>
            </div>
          </div>
        </div>
      </div>

      <table width={'100%'}>
        <tr
          style={{
            color: '#555770',
            borderBottom: '0.5px solid #555770',
            padding: '20px',
          }}>
          <th style={{ textAlign: 'left', fontWeight: 700, fontSize: '12px', paddingBottom: '8px' }}>
            Description
          </th>
          <th
            style={{ textAlign: 'right', fontSize: '12px', fontWeight: 700, paddingBottom: '8px' }}>
            Price
          </th>
          <th
            style={{ textAlign: 'right', fontSize: '12px', fontWeight: 700, paddingBottom: '8px' }}>
            Qty
          </th>
          <th
            style={{ textAlign: 'right', fontSize: '12px', fontWeight: 700, paddingBottom: '8px' }}>
            Amount
          </th>
        </tr>
        <tr style={{ color: '#28293D' }}>
          <td style={{ textAlign: 'left', fontSize: '14px', fontWeight: 700, paddingTop: '14px' }}>
            {itemName}
          </td>
          <td
            style={{
              textAlign: 'right',
              paddingTop: '14px',
              fontWeight: 400,
              fontSize: '12px',
              fontFamily: 'monospace',
            }}>
            {itemPrice}
          </td>
          <td
            style={{
              textAlign: 'right',
              fontSize: '12px',
              fontFamily: 'monospace',
              paddingTop: '14px',
            }}>
            1
          </td>
          <td
            style={{
              textAlign: 'right',
              fontSize: '12px',
              fontFamily: 'monospace',
              fontWeight: 400,
              paddingTop: '14px',
            }}>
            {amount}
          </td>
        </tr>

        <tr style={{ color: '#28293D' }}>
          <td></td>
          <td></td>
          <td style={{ fontSize: '14px', fontWeight: 600, textAlign: 'right', paddingTop: '14px' }}>
            Subtotal
          </td>
          <td style={{ fontSize: '14px', fontWeight: 500, textAlign: 'right', paddingTop: '14px' }}>
            {subTotal}
          </td>
        </tr>
        <tr style={{ borderBottom: '0.5px solid #555770', color: '#28293D' }}>
          <td></td>
          <td></td>
          <td
            style={{
              fontSize: '14px',
              fontWeight: 600,
              textAlign: 'right',
              paddingBottom: '14px',
            }}>
            Discount
          </td>
          <td
            style={{
              fontSize: '14px',
              fontWeight: 500,
              textAlign: 'right',
              paddingBottom: '14px',
            }}>
            Rp0
          </td>
        </tr>
        <tr style={{ color: '#28293D' }}>
          <td></td>
          <td></td>
          <td style={{ fontSize: '14px', fontWeight: 600, textAlign: 'right', paddingTop: '14px' }}>
            GRAND TOTAL
          </td>
          <td
            style={{
              fontSize: '14px',
              fontWeight: 600,
              textAlign: 'right',
              paddingTop: '14px',
              fontFamily: 'monospace',
            }}>
            {grandTotal}
          </td>
        </tr>
      </table>
      <div style={{ marginTop: '24px' }}>
        <p style={{ fontWeight: 600, fontSize: '12px', color: '#555770' }}>Remark</p>
        <ul
          style={{
            marginTop: '-10px',
            marginLeft: '-10px',
            fontWeight: 400,
            fontSize: '12px',
            color: '#555770',
          }}>
          <li>Price inclusive of 10% PPN</li>
          <li>All devices comes with 1 year warranty & support</li>
          <li>Payments are made in cash before delivery</li>
          <li>Prices are valid and guaranteed for 35 days</li>
        </ul>
      </div>
      <div
        style={{
          display: 'flex',
          alignItems: 'stretch',
          justifyContent: 'space-between',
          marginTop: '171px',
        }}>
        <div>
          <p style={{ fontSize: '12px', fontWeight: 500, color: '#555770' }}>
            FOR PAYMENT, PLEASE REMIT TO::
          </p>
          <p style={{ fontSize: '14px', fontWeight: 600, color: '#28293D' }}>
            PT. Dari Visi Teknologi
          </p>
          <p style={{ fontSize: '14px', fontWeight: 400, color: '#28293D' }}>
            Acc. No.: 746-0330-429
          </p>
          <p style={{ fontSize: '14px', fontWeight: 400, color: '#28293D' }}>
            BCA - Agung Sedayu 2, Jakarta
          </p>
        </div>
        <div style={{ position: 'relative', alignItems: 'baseline' }}>
          <div>
            <p style={{ fontWeight: 600, fontSize: '14px', color: '#28293D' }}>
              PT. Dari Visi Teknologi
            </p>
          </div>

          <div style={{ position: 'absolute', bottom: 0, width: '100%' }}>
            <p
              style={{
                textAlign: 'center',
                fontSize: '14px',
                fontWeight: 400,
                marginBottom: '10px',
                color: '#28293D',
              }}>
              Finance Dept.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BillingInvoice;