import { DownOutlined } from '@ant-design/icons';
import { useMutation, useQuery } from '@apollo/client';
import { Button, Dropdown, Menu } from 'antd';
import Text from 'antd/lib/typography/Text';
import React, { useCallback, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import QueryGql from '../../constants/QueryGql';
import HorizontalTextInput from '../molecules/HorizontalTextInput';
import Modal from '../organisms/Modal';

const RaincheckSettingsDuration = () => {
  const history = useHistory();
  const { data: dataGetValidityDuration } = useQuery(QueryGql.GET_VALIDITY_DURATION);
  const validityDuration = dataGetValidityDuration?.getValidityDuration.data.value;

  const [validityDurationState, setValidityDurationState] = useState(validityDuration);

  const [setValidityDuration, {
    loading: loadingValidityDuration
  }] = useMutation(QueryGql.SET_VALIDITY_DURATION, {
    onCompleted: () => {
      history?.goBack();
    }
  });

  const handleChangeName = useCallback((e) => {
    const name = e.target.value;
    setValidityDurationState(name);
  }, []);

  const handleAdded = () => {
    setValidityDuration({
      variables: { 
        setValidityDurationValue: parseInt(validityDurationState) 
      }
    });
  };

  const menu = useMemo(() => (
    <Menu>
      <Menu.Item key="0">
        <Text type="link">days</Text>
      </Menu.Item>
      <Menu.Item key="1" disabled>
        <Text type="link">weeks</Text>
      </Menu.Item>
      <Menu.Item key="3" disabled>
        <Text type="link">months</Text>
      </Menu.Item>
    </Menu>
  ), []);
  
  return (
    <Modal
      title="Raincheck validity duration"
      hiddenLeftFooterButton
      rightFooterButton={{
        label: 'Save changes',
        state: !validityDurationState || loadingValidityDuration ? 'disabled' : 'default',
        onClick: handleAdded,
        loading: loadingValidityDuration
      }}
      bodyStyle={{ textAlign: 'right' }}
      width={540}
    >
      <HorizontalTextInput
        label="Validity duration"
        value={validityDurationState}
        onChange={handleChangeName}
        suffix={(
          <Dropdown trigger={['hover', 'click']} overlay={menu} visible={false}>
            <Button type="link" size="small">days <DownOutlined /></Button>
          </Dropdown>
        )}
      />
    </Modal>
  );
};

export default RaincheckSettingsDuration;
