import { useQuery, useMutation } from '@apollo/client';
import React, { useMemo, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import Pathname from '../../constants/Pathname';
import QueryGql from '../../constants/QueryGql';
import extractQuery from '../../utils/useQuery';
import Modal from '../organisms/Modal';
import TeeSheetPaymentSuccessTemplate from '../templates/TeeSheetPaymentSuccess';
import Loading from '../atoms/Loading';
import { Button, notification, Typography } from 'antd';
import PrintReceipt from 'components/templates/PrintReceipt';

const TeeSheetPaymentSuccess = () => {
  const history = useHistory();
  const location = useLocation();
  const queryString = extractQuery(location.search);
  const type = queryString.get('type');
  const id = queryString.get('id');
  const queryCustomers = queryString.get('customers')
    ? queryString.get('customers').split(',')
    : [];
  const [values, setValues] = useState([]);
  const printComponentRef = useRef();

  const { loading: loadingGetReservation, data: dataGetReservation } = useQuery(
    QueryGql.GET_RESERVATION,
    {
      variables: {
        getReservationId: id,
      },
      fetchPolicy: 'network-only',
    },
  );

  const { loading: loadingGetAllReceiptMethods, data: dataGetAllReceiptMethods } = useQuery(
    QueryGql.GET_ALL_RECEIPT_METHODS,
  );

  const [createReceipt, { loading: loadingCreateReceipt }] = useMutation(QueryGql.CREATE_RECEIPT, {
    onCompleted: () => {
      const key = `open${Date.now()}`;
      notification.success({
        message: 'Successfully sent receipt',
        btn: (
          <Button
            type='text'
            className='notification-button-dismiss-success'
            onClick={() => notification.close(key)}>
            Dismiss
          </Button>
        ),
        key,
        closeIcon: <div />,
        placement: 'bottomLeft',
      });
      if (type === 'raincheck') {
        history.push(Pathname.TEE_SHEET + '/' + Pathname.TEE_SHEET_RAINCHECK_ISSUE + `?id=${id}`, {
          background: true,
        });
      } else {
        history.replace(Pathname.TEE_SHEET);
      }
    },
    onError: () => {},
  });

  const customers = useMemo(() => {
    let data = [];
    if (dataGetReservation?.getReservation) {
      const number = `${dataGetReservation.getReservation.data.prefix}-${dataGetReservation.getReservation.data.number}`;
      data = dataGetReservation.getReservation.data.reservation_customers
        .filter(item => item.status === 'paid')
        .map(item => ({
          name: item.customer.full_name,
          email: item.customer.email,
          phone_number: item.customer.phone_number,
          id: item.customer.id,
          number,
        }));

      if (type !== 'raincheck') {
        data = data
          .filter(item => queryCustomers.includes(item.id))
          .filter(item => item.name !== 'guest');
      }
    }
    return data;
  }, [dataGetReservation]);

  const receiptTypes = useMemo(() => {
    let data = [];
    if (dataGetAllReceiptMethods?.getAllReceiptMethods) {
      data = dataGetAllReceiptMethods.getAllReceiptMethods.data.map(item => ({
        label: item.name,
        value: item.code,
      }));
    }
    return data;
  }, [dataGetAllReceiptMethods]);

  const handleSendReceipt = () => {
    createReceipt({
      variables: {
        createReceiptData: {
          reservation_id: id,
          customers: values.map(x => ({
            id: x.id,
            receipt_method: x.receipt_method,
          })),
        },
      },
    });
  };

  const handlePrintReceipt = useReactToPrint({
    content: () => printComponentRef.current,
  });

  return (
    <Modal
      title='Payment successful'
      closeIcon={<div />}
      onCancel={() => history.replace(Pathname.TEE_SHEET)}
      hiddenRightFooterButton={customers?.length === 0}
      leftFooterButton={{
        label: 'No receipt',
        type: 'link',
        onClick: () => history.replace(Pathname.TEE_SHEET),
      }}
      rightFooterButtonExtra={{
        label: 'Print',
        type: 'outline',
        onClick: handlePrintReceipt,
      }}
      rightFooterButton={{
        label: 'Send receipt',
        state:
          loadingGetReservation ||
          (loadingGetAllReceiptMethods && !dataGetAllReceiptMethods && !dataGetReservation)
            ? 'disabled'
            : 'default',
        loading: loadingCreateReceipt,
        onClick: handleSendReceipt,
      }}
      width={964}>
      {loadingGetReservation ||
      (loadingGetAllReceiptMethods && !dataGetAllReceiptMethods && !dataGetReservation) ? (
          <Loading />
        ) : customers?.length === 0 ? (
          <Typography>Reservation payment data has been saved successfully</Typography>
        ) : (
          <TeeSheetPaymentSuccessTemplate
            customers={customers}
            receiptTypes={receiptTypes}
            onChange={setValues}
          />
        )}
      <div>
        <PrintReceipt
          data={dataGetReservation?.getReservation?.data}
          printComponentRef={printComponentRef}
        />
      </div>
    </Modal>
  );
};

export default TeeSheetPaymentSuccess;
