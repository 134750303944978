import React from 'react';
import SvgEmptyFeedback from '../atoms/SvgEmptyFeedback';
import AppWrapper from '../templates/AppWrapper';
import PageEmpty from '../templates/PageEmpty';

const Feedbacks = () => {
  return (
    <AppWrapper>
      <PageEmpty
        title="Gain feedbacks and improve further"
        desc="It seems that you don’t have any feedback yet. Let your customers know that they can give feedback from their receipts and provide improvement points. Refresh the page once you have told your customers."
        buttonLabel="Refresh page"
        illustration={SvgEmptyFeedback}
        buttonOnClick={() => {}}
      />
    </AppWrapper>
  );
};

export default Feedbacks;