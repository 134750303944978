import React from 'react';
import { Button as AntdButton } from 'antd';
import PropTypes from 'prop-types';
import './Button.less';

const Button = props => {
  const { children, type, state, size, onClick, disabled: disabledProps } = props;
  let danger = false;
  let disabled = disabledProps;
  let buttonType = type;
  let className = '';
  if (type === 'danger') {
    danger = true;
    buttonType = 'primary';
  }

  if (state === 'hint' && type === 'primary') {
    className = 'hint-primary';
    buttonType = 'text';
  }
  if (type === 'danger') {
    if (state === 'hint') {
      className = 'hint-danger';
      buttonType = 'text';
    }
    if (state === 'text') {
      className = 'danger-text';
      buttonType = 'text';
    }
  }
  if (state === 'disabled' && type === 'primary') {
    className = 'disabled-primary';
    buttonType = 'text';
    disabled = true;
  }
  if (type === 'guide') {
    className = 'guide';
    buttonType = 'text';
    if (state === 'text') {
      className = 'guide-text';
    }
    if (state === 'disabled') {
      className = 'disabled-guide';
    }
  }

  if (type === 'outline') {
    className = 'outline';
    buttonType = 'default';
    if (state === 'disabled') {
      className = 'disabled-outline';
      buttonType = 'default';
    }
  }

  if (type === 'default') {
    className = 'default';
  }

  if (type === 'secondary') {
    className = 'secondary';

    if (props.color === 'blue') {
      className = 'secondary blue';
    }
  }

  if (type === 'link') {
    className = '';
    buttonType = type;
  }

  return (
    <AntdButton
      {...props}
      id='antd-button'
      className={[className, props.className].join(' ')}
      disabled={disabled}
      type={buttonType}
      danger={danger}
      size={size}
      onClick={onClick}>
      {children}
    </AntdButton>
  );
};

export default Button;

Button.propTypes = {
  children: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['primary', 'danger', 'guide', 'outline', 'link', 'secondary']),
  state: PropTypes.oneOf(['default', 'hint', 'disabled', 'loading', 'text']),
  size: PropTypes.oneOf(['large', 'middle', 'small']),
  color: PropTypes.oneOf(['blue']),
  onClick: PropTypes.func,
};

Button.defaultProps = {
  type: 'primary',
  state: 'default',
  size: 'middle',
  onClick: null,
};
