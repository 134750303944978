import { Col, Select, Checkbox } from 'antd';
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Text from 'antd/lib/typography/Text';
import './MultipleSelect.less';

const MultipleSelect = (props) => {
  const { items, defaultValue, handleChange, values, label, type, fullWidth } = props;

  const renderDropdown = () => {
    return (
      <Checkbox.Group id="menu-item-multiple" defaultValue={values} onChange={handleChange}>
        {items.map(item =>
          <Col span={24} key={item.key}>
            <Checkbox value={item.value}>{item.label}</Checkbox>
          </Col>
        )}
      </Checkbox.Group>
    );
  };

  let className = useMemo(() => {
    if (type === 'text') {
      return 'multiple-select-text';
    }
    return 'multiple-select-item';
  }, [type]);

  return (
    <>
      { label && <div className="multi-select-label"><Text>{label}</Text></div> }
      <Select
        {...props}
        dropdownClassName="multiple-select"
        className={`${fullWidth ? 'multi-select-full-width' : ''} ${className}`}
        dropdownRender={renderDropdown}
        defaultValue={defaultValue}
      />
    </>
  );
};

export default MultipleSelect;

MultipleSelect.propTypes = {
  type: PropTypes.oneOf(['text', 'outlined']),
  label: PropTypes.string,
  defaultValue: PropTypes.string.isRequired,
  handleChange: PropTypes.func,
  items: PropTypes.shape({
    key: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired
  })
};

MultipleSelect.defaultProps = {
  type: 'outlined',
  items: {
    key: '1',
    label: 'label',
    value: '1'
  },
  handleChange: null,
};