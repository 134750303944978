import { Col, Row, Space } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import SingleSelect from '../molecules/SingleSelect';
import AppWrapper from '../templates/AppWrapper';
import { useHistory } from 'react-router-dom';
import Pathname from '../../constants/Pathname';
import DateRangePicker from '../molecules/DateRangePicker';
import ExportButton from '../molecules/ExportButton';
import Button from '../atoms/Button';
import MultipleSelect from '../molecules/MultipleSelect';
import RaincheckCards from '../organisms/RaincheckCards';
import RaincheckList from '../organisms/RaincheckList';
import Title from 'antd/lib/typography/Title';
import { useQuery } from '@apollo/client';
import QueryGql from '../../constants/QueryGql';
import formatter from '../../utils/formatter';

export function buildRaincheck(items) {
  return items.map(item => {

    return {
      ...item,
      key: item.id,
      amount: formatter.currency(item.amount),
      name: item.customer.full_name,
      expired: moment(item.expiration_date, 'YYYY-MM-DD').format('L'),
      time: moment(item.created_at).format('L - LT')
    };
  });
}

const RaincheckListReport = () => {
  const history = useHistory();
  const [selectedData, setSelectedData] = useState([]);
  const totalSelected = selectedData.length;
  const [date, setDate] = useState([moment().subtract(1, 'M'), moment()]);
  const [course, setCourse] = useState({ key: 'all', label: 'All golf courses', value: 'all' });
  const [paymentStatus] = useState([
    { key: 'all', value: 'all', label: 'All status' },
    { key: 'active', value: 'active', label: 'Active' },
    { key: 'void', value: 'void', label: 'Void' },
    { key: 'redeemed', value: 'redeemed', label: 'Redeemed' },
    { key: 'expired', value: 'expired', label: 'Expired' },
  ]);
  const [statuses, setStatuses] = useState(['all']);

  const { data: dataGetAllCourses } = useQuery(
    QueryGql.GET_ALL_COURSES,
  );
  const courses = dataGetAllCourses?.getAllCourses.data || [];
  const optionsCourses = useMemo(() => {
    const items = [{ key: 'all', value: 'all', label: 'All golf courses' }];

    courses.forEach(item => {
      items.push({
        key: item.id,
        value: item.id,
        label: `${item.name} (${item.holes})`
      });
    });
    return items;
  }, [courses]);

  const { refetch: refetchSummary, loading: loadingRainCheckSummary, data: dataRainCheckSummary } = useQuery(
    QueryGql.GET_RAINCHECKS, {
      variables: {
        data: {
          statuses: statuses.includes('all') ? null : statuses,
          start_date: moment(date[0]).startOf('day').toISOString(),
          end_date: moment(date[1]).endOf('day').toISOString(),
          course_id: course.value === 'all' ? null : course.value,
        }
      },
      fetchPolicy: 'cache-and-network'
    }
  );

  const dataReport = dataRainCheckSummary?.getRainchecks?.data;
  const summary = useMemo(() => dataReport?.summary || {}, [dataReport]);

  const customers = useMemo(() => {
    const items = dataReport?.rainchecks || [];
    return buildRaincheck(items);
  }, [dataReport]);


  useEffect(() => {
    if (history.location.search.includes('refetch')) {
      refetchSummary();
    }
  }, [history.location.key]);

  const handleClickExport = () => {
    history.push(`${Pathname.RAINCHECK_LIST}/${Pathname.EXPORT_RAINCHECK_LIST}`, { background: true });
  };

  const handleClickMenu = (key, detail) => {
    let id = detail.id;

    switch (key) {
      case 'detail':
        history.push(`${Pathname.RAINCHECK_LIST}/${Pathname.RAINCHECK_DETAIL}?id=${id}`, { data: detail, background: true });
        break;

      case 'send':
        history.push(`${Pathname.RAINCHECK_LIST}/${Pathname.SEND_EMAIL}?type=raincheck&id=${id}`, { data: detail, background: true, title: 'Send raincheck', number: detail.code });
        break;

      case 'void':
        if (Array.isArray(detail)) {
          id = detail.map(item => item.id).join(',');
        }
        history.push(`${Pathname.RAINCHECK_LIST}/${Pathname.VOID_RAINCHECK}?id=${id}`, { data: detail, background: true });
        break;
      case 'print':
        if (Array.isArray(detail)) {
          id = detail.map(item => item.id).join(',');
        }
        history.push(`${Pathname.RAINCHECK_LIST}/${Pathname.PRINT_RAINCHECK}?id=${id}`, { data: detail, background: true });
        break;
      case 'redeem':
        if (Array.isArray(detail)) {
          id = detail.map(item => item.id).join(',');
        }
        history.push(`${Pathname.RAINCHECK_LIST}/${Pathname.REDEEM_RAINCHECK}?id=${id}`, { data: detail, background: true });
        break;
      default:
        break;
    }
  };

  const isLoading = loadingRainCheckSummary;

  const handleChangeCourse = (item) => {
    setCourse(item);
  };

  const handleChangeDate = (item) => {
    setDate(item);
  };

  const handleChangeStatus = (item) => {
    setStatuses(item);
  };

  return (
    <AppWrapper>
      {totalSelected === 0 ? null :
        <div style={{ padding: '32px 0', boxShadow: '0px -0.5px 0px #C2C2C2, 0px 0.5px 0px #C2C2C2' }}>
          <Row justify="space-between">
            <Col>
              <Title style={{ marginBottom: 0 }} className="title" level={3}>{totalSelected} rainchecks selected</Title>
            </Col>
            <Col>
              <Space size={4} direction="horizontal">
                <Button
                  type="secondary"
                  onClick={() => handleClickMenu('print', selectedData)}
                >
                  Print
                </Button>
                <Button
                  type="secondary"
                  onClick={() => handleClickMenu('redeem', selectedData)}
                >
                  Redeem
                </Button>
                <Button
                  type="secondary"
                  onClick={() => handleClickMenu('void', selectedData)}
                >
                  Void
                </Button>
                <Button
                  type="outline"
                  onClick={() => setSelectedData([])}
                  style={{ marginLeft: 44 }}
                >
                  Cancel
                </Button>
              </Space>
            </Col>
          </Row>
        </div>
      }
      <Space style={{ marginTop: 32, paddingBottom: 32 }} direction="vertical" size={32}>

        {totalSelected > 0 ? null : <>
          <Row
            justify="space-between"
          >
            <Col>
              <Space>
                <DateRangePicker
                  allowClear={false}
                  defaultValue={date}
                  onChange={handleChangeDate}
                />
                <SingleSelect
                  items={optionsCourses}
                  value={course.value}
                  valueLabel={course.label}
                  onSelect={handleChangeCourse}
                />
                <MultipleSelect
                  items={paymentStatus}
                  value={statuses.includes('all') ? 'All status' : statuses.join(', ')}
                  values={statuses}
                  handleChange={handleChangeStatus}
                />
              </Space>
            </Col>
            <Col>
              <ExportButton
                onClick={handleClickExport}
              />
            </Col>
          </Row>
          <RaincheckCards
            totalRaincheck={summary.total_raincheck}
            totalRedeemed={summary.total_redeemed}
            reservationPrecentage={summary.raincheck_percentage}
          />
        </>}
        <div>
          <RaincheckList
            data={customers}
            selectedData={selectedData}
            setSelectedData={setSelectedData}
            onClickMenu={handleClickMenu}
            loading={isLoading}
          />
        </div>
      </Space>
    </AppWrapper>
  );
};

export default RaincheckListReport;