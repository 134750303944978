import React from 'react';
import { useHistory } from 'react-router-dom';
import IconButtonNavBar from '../atoms/IconButtonNavBar';
import Modal from '../organisms/Modal';
import CustomerDetailReservation from '../templates/CustomerDetailReservation';

const CustomerListDetailReservation = () => {
  const history = useHistory();
  return (
    <Modal
      title="Reservation details"
      closeIcon={<IconButtonNavBar type="back" />}
      hiddenLeftFooterButton
      rightFooterButton={{
        label: 'Go Back',
        onClick: () => history.goBack(),
      }}
    >
      <CustomerDetailReservation
        detail={{
          number: 'IUBFVBO-94',
          courseNumber: 'Course No. 1 (18 Hole)',
          teeTime: '06:00 AM',
          date: 'Monday, 05/04/2021',
          status: 'paid',
          total: 'Rp. 1,650,000',
          type: 'detail-header'
        }}
      />
    </Modal>
  );
};

export default CustomerListDetailReservation;