import { useLazyQuery, useQuery, useMutation } from '@apollo/client';
import React, { useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import Promise from 'bluebird';
import QueryGql from '../../constants/QueryGql';
import AppWrapper from '../templates/AppWrapper';
import TeeSheetTemplate from '../templates/TeeSheet';
import Pathname from 'constants/Pathname';
import { useHistory } from 'react-router-dom';

const TeeSheet = () => {
  const history = useHistory();
  const [courseId, setCourseId] = useState('');
  const [teeTimeBlocks, setTeeTimeBlocks] = useState([]);
  const { loading: loadingGetAllCourses, data: dataGetAllCourses } = useQuery(
    QueryGql.GET_ALL_COURSES,
  );

  const { loading: loadingGetReservationStatus, data: dataGetReservationStatus } = useQuery(
    QueryGql.GET_RESERVATION_STATUS,
  );

  const [getReservations, { loading: loadingGetReservations, data: dataGetReservations, refetch }] = useLazyQuery(
    QueryGql.GET_RESERVATIONS,
    {
      fetchPolicy: 'cache-and-network',
    }
  );

  const [getAllCalendar, { loading: loadingGetAllCalendar, data: dataGetAllCalendar }] = useLazyQuery(
    QueryGql.GET_ALL_CALENDAR,
    {
      fetchPolicy: 'cache-and-network'
    }
  );

  const [getTeeTimeBlock, { data: dataGetTeeTimeBlock}] = useLazyQuery(
    QueryGql.GET_TEE_TIME_BLOCK,
  );


  const [createTeeTimeBlock] = useMutation(
    QueryGql.CREATE_TEE_TIME_BLOCK,
    {
      update(cache, { data: { createTeeTimeBlock } }) {
        cache.writeQuery({
          query: QueryGql.GET_TEE_TIME_BLOCK,
          variables: {
            calendarId: createTeeTimeBlock.data.calendar_id
          },
          data: {
            getTeeTimeBlock: createTeeTimeBlock
          }
        });
      }
    }
  );

  const [updateTeeTimeBlock] = useMutation(
    QueryGql.UPDATE_TEE_TIME_BLOCK,
    {
      update(cache, { data: { updateTeeTimeBlock } }) {
        cache.writeQuery({
          query: QueryGql.GET_TEE_TIME_BLOCK,
          variables: {
            calendarId: updateTeeTimeBlock.data.calendar_id
          },
          data: {
            getTeeTimeBlock: updateTeeTimeBlock
          }
        });
      }
    }
  );

  useEffect(() => {
    if (dataGetAllCalendar?.getAllCalendar.data) {
      const varGetTeeTimeBlocks = [];
      dataGetAllCalendar.getAllCalendar.data.forEach(item => {
        varGetTeeTimeBlocks.push({
          variables: {
            calendarId: item.id
          }          
        });
      });
      Promise.map(varGetTeeTimeBlocks, (varGetTeeTimeBlock) => {
        // Promise.map awaits for returned promises as well.
        return getTeeTimeBlock(varGetTeeTimeBlock);
      });
    }
  }, [dataGetAllCalendar?.getAllCalendar.data]);

  useEffect(() => {
    if (dataGetTeeTimeBlock?.getTeeTimeBlock) {
      const teeTimeBlock = dataGetTeeTimeBlock?.getTeeTimeBlock.data;
      setTeeTimeBlocks(prevState => {
        const existTeeTimeBlock = prevState.find(x => x.calendar_id === teeTimeBlock.calendar_id);
        if (existTeeTimeBlock) {
          return prevState.map(x => {
            if (x.calendar_id === teeTimeBlock.calendar_id) {
              return teeTimeBlock;
            }
            return x;
          });
        } 
        return [
          ...prevState,
          teeTimeBlock
        ];
      });
    }
  }, [dataGetTeeTimeBlock]);

  const reservationStatus = dataGetReservationStatus?.getReservationStatus.data.map(item => ({
    label: item.name,
    value: item.code
  }));

  const teeTimes = useMemo(() => {
    if (dataGetAllCalendar?.getAllCalendar.data.length > 0) {
      let groupTeeTimes = [];
      dataGetAllCalendar.getAllCalendar.data.forEach((item, index) => {
        // block unblock delete tee time
        let blockedTimes = [];
        let deletedTimes = [];
        const teeTimeBlock = teeTimeBlocks.find(x => x.calendar_id === item.id);
        if (teeTimeBlock) {
          blockedTimes = JSON.parse(teeTimeBlock.blocked_times);
          deletedTimes = JSON.parse(teeTimeBlock.deleted_times);
        }
        const getType = (time) => {
          const existDeleteTeeTime = deletedTimes.find(x => x.time === time);
          const existBlockedTeeTime = blockedTimes.find(x => x.time === time);
          if (existDeleteTeeTime) {
            return 'deleted';
          }
          if (existBlockedTeeTime) {
            return 'blocked';
          }
          return null;
        };

        groupTeeTimes.push([]);
        let startingHoles = [];
        if (item.tee_times?.starting_holes) {
          if (typeof item.tee_times.starting_holes === 'string') {
            if (JSON.parse(item.tee_times.starting_holes)?.length > 0) {
              if (item.tee_times.tee_time_types === 'standard') {
                startingHoles = [JSON.parse(item.tee_times.starting_holes)[0]];
              } else {
                startingHoles = JSON.parse(item.tee_times.starting_holes);
              }
            }
          } else {
            if (item.tee_times.tee_time_types === 'standard') {
              startingHoles = [item.tee_times.starting_holes[0]];
            } else {
              startingHoles = item.tee_times.starting_holes;
            }
          }
        }
        switch (item.tee_times.tee_time_types) {
          case 'custom':
          case 'standard': {
            let startTimeStandard = moment(item.start_time, 'HH:mm:ss');
            let temporaryGroupId = '';
            let temporaryGroupIsNotAnyPaid = true;
            let temporaryGroupIsHideCancelReservation = false;
            let indexCustomInterval = 0;
            do {
              const reservationTimes = dataGetReservations?.getReservations.data?.filter(x => x.date_tee_times === startTimeStandard.format('HH:mm:ss'));
              if (reservationTimes?.length > 1) {
                const holes = reservationTimes.map(reservationTime => {
                  const rainchecks = reservationTime?.rainchecks || [];
                  const reservationId = reservationTime?.id || '';
                  const note = reservationTime?.reservation_note || '';
                  const isGroup = reservationTime?.is_group || false;
                  return {
                    note,
                    isGroup,
                    courseId,
                    reservationId,
                    label: reservationTime.reservation_holes.name,
                    customers: reservationTime.reservation_customers.map(x => ({
                      customerId: x.customer_id,
                      name: x.customer.full_name,
                      type: x.membership_name,
                      status: x.status,
                      isRaincheck: x.is_raincheck,
                      bagId: x.bag_id,
                      raincheck: rainchecks.find(y => y.reservation_id === reservationId && y.customer_id === x.customer_id)
                    }))
                  };
                });
                groupTeeTimes[index].push({
                  courseId,
                  startingHoles,
                  id: item.tee_times.id,
                  rateCardId: item.rate_cards.id,
                  calendarId: item.id,
                  label: startTimeStandard.format('hh:mm A'),
                  holes
                });
              } else {
                const customerReservations = reservationTimes?.[0]?.reservation_customers || [];
                const rainchecks = reservationTimes?.[0]?.rainchecks || [];   
                const reservationId = reservationTimes?.[0]?.is_group === true ? reservationTimes?.[0]?.group_id : reservationTimes?.[0]?.id;
                const note = reservationTimes?.[0]?.reservation_note || '';
                const isGroup = reservationTimes?.[0]?.is_group || false;
                const groupName = reservationTimes?.[0]?.name || '';
                const groupId = reservationTimes?.[0]?.group_id || '';
                let isGroupHeader = false;
                if (groupId !== temporaryGroupId) {
                  isGroupHeader = true;
                  temporaryGroupId = groupId;
                  const reservationGroupChekingStatusPayment = dataGetReservations?.getReservations.data?.filter(x => x.group_id === groupId);
                  reservationGroupChekingStatusPayment.forEach(x => {
                    x.reservation_customers.forEach(y => {
                      if (y.status === 'paid') {
                        temporaryGroupIsNotAnyPaid = false;
                      }
                      if (y.status === 'checked_in' || y.status === 'paid') {
                        temporaryGroupIsHideCancelReservation = true;
                      }
                    });
                  });
                } else {
                  isGroupHeader = false;
                  temporaryGroupIsNotAnyPaid = true;
                  temporaryGroupIsHideCancelReservation = false;
                }
                groupTeeTimes[index].push({
                  note: note || teeTimeBlock?.note,
                  isGroup,
                  isGroupHeader,
                  groupIsNotAnyPaid: temporaryGroupIsNotAnyPaid,
                  groupIsHideCancelReservation: temporaryGroupIsHideCancelReservation,
                  groupName,
                  groupId,
                  courseId,
                  reservationId,
                  startingHoles,
                  calendarId: item.id,
                  id: item.tee_times.id,
                  rateCardId: item.rate_cards.id,
                  label: startTimeStandard.format('hh:mm A'),
                  type: getType(startTimeStandard.format('HH:mm:ss')),
                  customers: customerReservations.map(x => ({
                    customerId: x.customer_id,
                    name: x.customer.full_name,
                    type: x.membership_name,
                    status: x.status,
                    isRaincheck: x.is_raincheck,
                    bagId: x.bag_id,
                    raincheck: rainchecks.find(y => y.reservation_id === reservationId && y.customer_id === x.customer_id)
                  }))
                });
              }
              let interval = item.tee_times.interval_time || 10;
              if (item.tee_times.tee_time_types === 'custom' && item.tee_times.custom_invervals) {
                const customIntervals = JSON.parse(item.tee_times.custom_invervals);
                const currentInterval = customIntervals[indexCustomInterval];
                const nextInterval = customIntervals[indexCustomInterval + 1];

                if (nextInterval) {
                  const diff = moment(nextInterval.time, 'HH:mm:ss').diff(moment(currentInterval.time, 'HH:mm:ss'), 'minute');
                  interval = diff || 1;
                  indexCustomInterval += 1;
                } else {
                  indexCustomInterval = 0;
                }
              }
              
              startTimeStandard = startTimeStandard.add(interval, 'minutes');
            }
            while (startTimeStandard.format('HH:mm:ss') <= item.end_time);
            break;
          }
          case 'crossover': {
            let startTimeCrossover = moment(item.start_time, 'HH:mm:ss');
            do {
              const reservationTimesCrossover = dataGetReservations?.getReservations.data?.filter(x => x.date_tee_times === startTimeCrossover.format('HH:mm:ss')) || [];
              if (reservationTimesCrossover.length > startingHoles.length) {
                const holes = reservationTimesCrossover.map(reservationTime => {
                  const rainchecks = reservationTime?.rainchecks || [];
                  const reservationId = reservationTime?.id || '';
                  const note = reservationTime?.reservation_note || '';
                  const isGroup = reservationTime?.is_group || false;    
                  return {
                    note,
                    isGroup,
                    courseId,
                    reservationId,
                    label: reservationTime.reservation_holes.name,
                    customers: reservationTime.reservation_customers.map(x => ({
                      customerId: x.customer_id,
                      name: x.customer.full_name,
                      type: x.membership_name,
                      status: x.status,
                      isRaincheck: x.is_raincheck,
                      bagId: x.bag_id,
                      raincheck: rainchecks.find(y => y.reservation_id === reservationId && y.customer_id === x.customer_id)
                    }))
                  };
                });
                groupTeeTimes[index].push({
                  courseId,
                  startingHoles,
                  initialStartingHoles: holes.map(hole => ({
                    value: hole.label,
                    name: hole.label,
                  })),
                  id: item.tee_times.id,
                  rateCardId: item.rate_cards.id,
                  label: startTimeCrossover.format('hh:mm A'),
                  holes
                });
              } else {
                const holesCrossover = startingHoles.map(startingHole => {
                  const reservationTimeCrossover = dataGetReservations?.getReservations.data
                    ?.find(x => x.date_tee_times === startTimeCrossover.format('HH:mm:ss') && 
                      x.reservation_holes.name === startingHole.value);
                  const rainchecks = reservationTimeCrossover?.rainchecks || [];
                  const reservationId = reservationTimeCrossover?.id || '';
                  const note = reservationTimeCrossover?.reservation_note || '';
                  const isGroup = reservationTimeCrossover?.is_group || false;
                  const label = reservationTimeCrossover?.reservation_holes?.name || startingHole.value;
                  return {
                    note,
                    isGroup,
                    courseId,
                    reservationId,
                    label,
                    customers: reservationTimeCrossover?.reservation_customers.map(x => ({
                      customerId: x.customer_id,
                      name: x.customer.full_name,
                      type: x.membership_name,
                      status: x.status,
                      isRaincheck: x.is_raincheck,
                      bagId: x.bag_id,
                      raincheck: rainchecks.find(y => y.reservation_id === reservationId && y.customer_id === x.customer_id)
                    }))
                  };
                });
                groupTeeTimes[index].push({
                  courseId,
                  startingHoles,
                  initialStartingHoles: startingHoles,
                  id: item.tee_times.id,
                  rateCardId: item.rate_cards.id,
                  label: startTimeCrossover.format('hh:mm A'),
                  holes: holesCrossover
                });
              }
              startTimeCrossover = startTimeCrossover.add(item.tee_times.interval_time, 'minutes');
            }
            while (startTimeCrossover.format('HH:mm:ss') <= item.end_time);
            break;
          }
          case 'shotgun': {
            let startTimeShotgun = moment(item.start_time, 'HH:mm:ss');
            let endTime = moment(item.end_time, 'HH:mm:ss');
            const holesShotgun = startingHoles.map(startingHole => {
              const reservationTimeShotgun = dataGetReservations?.getReservations.data
                ?.find(x => x.date_tee_times === startTimeShotgun.format('HH:mm:ss') && 
                  x.reservation_holes.name === startingHole.value);
              const rainchecks = reservationTimeShotgun?.rainchecks || [];
              const reservationId = reservationTimeShotgun?.id || '';
              const note = reservationTimeShotgun?.reservation_note || '';
              const isGroup = reservationTimeShotgun?.is_group || false;
              const label = reservationTimeShotgun?.reservation_holes?.name || startingHole.value;
              return {
                note,
                isGroup,
                courseId,
                reservationId,
                label,
                customers: reservationTimeShotgun?.reservation_customers.map(x => ({
                  customerId: x.customer_id,
                  name: x.customer.full_name,
                  type: x.membership_name,
                  status: x.status,
                  isRaincheck: x.is_raincheck,
                  bagId: x.bag_id,
                  raincheck: rainchecks.find(y => y.reservation_id === reservationId && y.customer_id === x.customer_id)
                }))
              };
            });
            groupTeeTimes[index] = [
              {
                courseId,
                id: item.tee_times.id,
                label: startTimeShotgun.format('hh:mm A'),
                startingHoles,
                initialStartingHoles: startingHoles,
                rateCardId: item.rate_cards.id,
                holes: holesShotgun
              },
              {
                id: item.tee_times.id,
                label: endTime.format('hh:mm A')
              }
            ];
            break;
          }

          
          default:
            break;
        }
      });
      return groupTeeTimes;
    }
    return [];
  }, [dataGetReservations, dataGetAllCalendar, teeTimeBlocks]);

  const stortAscTeeTimes = useMemo(() => {
    if (teeTimes.length > 1) {
      return teeTimes.sort((a, b) => {
        const timeA = moment(a[a.length - 1]?.label, 'h:mm A');
        const timeB = moment(b[b.length - 1]?.label, 'h:mm A');
        if (timeA.diff(timeB) > 0) {
          return 1;
        }
        if (timeA.diff(timeB) < 0) {
          return -1;
        } 
        return 0;
      });
    }
    return teeTimes;
  }, [teeTimes]);

  const handleCancelReservation = (reservationId) => {
    history.push(
      Pathname.TEE_SHEET + '/' +
        Pathname.TEE_SHEET_CANCEL_RESERVATION+
        `?id=${reservationId}`,
      {
        background: true
      }
    );
  };

  const handleCancelReservationGroup = (reservationId) => {
    history.push(
      Pathname.TEE_SHEET + '/' +
        Pathname.TEE_SHEET_CANCEL_RESERVATION+
        `?groupId=${reservationId}`,
      {
        background: true
      }
    );
  };

  const handleTeeTimeUnblock = (teeTimeUnblock) => {
    updateTeeTimeBlock(teeTimeUnblock);
  };

  const handleTeeTimeDelete = (teeTimeUnblock) => {
    createTeeTimeBlock(teeTimeUnblock);
  };
  
  return (
    <AppWrapper>
      <TeeSheetTemplate
        loading={(loadingGetAllCourses || 
          loadingGetReservations || 
          loadingGetAllCalendar ||
          loadingGetReservationStatus
        )}
        courses={dataGetAllCourses?.getAllCourses.data}
        teeTimes={stortAscTeeTimes}
        teeTimeBlocks={teeTimeBlocks}
        reservationStatus={reservationStatus}
        onCancelReservation={handleCancelReservation}
        onCancelReservationGroup={handleCancelReservationGroup}
        onTeeTimeUnblock={handleTeeTimeUnblock}
        onTeeTimeDelete={handleTeeTimeDelete}
        refetch={refetch}
        onChangeFilter={values => {
          setCourseId(values?.course_id);
          getAllCalendar({
            variables: {
              getAllCalendarType: 'day',
              getAllCalendarDate: values.date,
            },
          });
          getReservations({
            variables: {
              getReservationsData: {
                ...values
              }
            },
          });
        }}
      />
    </AppWrapper>
  );
};

export default TeeSheet;