import { gql } from '@apollo/client';

class QueryGql {
  static LOGIN = gql`
    mutation LoginMutation($loginUsername: String!, $loginPassword: String!) {
      login(username: $loginUsername, password: $loginPassword) {
        ... on LoginSuccess {
          user {
            id
            full_name
            role
            email
            is_active
          }
          token
          last_activity
        }
        ... on LoginProblem {
          success
          message
          code
        }
      }
    }
  `;

  // =============SETTING COURSES=============
  static GET_ALL_COURSES = gql`
    query Query {
      getAllCourses {
        ... on ResponseCourseArray {
          data {
            id
            name
            holes
            is_active
          }
          success
          code
        }
        ... on ErrorResponse {
          success
          message
          code
          errors
        }
      }
    }
  `;

  static UPDATE_COURSE = gql`
    mutation Mutation(
      $updateCourseId: ID!
      $updateCourseName: String!
      $updateCourseHoles: Int!
      $updateCourseIsActive: Boolean!
    ) {
      updateCourse(
        id: $updateCourseId
        name: $updateCourseName
        holes: $updateCourseHoles
        is_active: $updateCourseIsActive
      ) {
        ... on ResponseCourse {
          data {
            id
            is_active
            holes
            name
          }
          code
          success
        }
        ... on ErrorResponse {
          success
          message
          code
          errors
        }
      }
    }
  `;

  static FIND_COURSE_BY_ID = gql`
    query Query($findCourseByIdId: ID!) {
      findCourseById(id: $findCourseByIdId) {
        ... on ResponseCourse {
          success
          data {
            id
            name
            holes
            is_active
          }
          code
        }
        ... on ErrorResponse {
          success
          message
          code
          errors
        }
      }
    }
  `;

  static CREATE_COURSE = gql`
    mutation CreateCourseMutation(
      $createCourseName: String!
      $createCourseHoles: Int!
      $createCourseIsActive: Boolean!
    ) {
      createCourse(
        name: $createCourseName
        holes: $createCourseHoles
        is_active: $createCourseIsActive
      ) {
        ... on ResponseCourse {
          success
          data {
            id
            name
            holes
            is_active
          }
        }
        ... on ErrorResponse {
          success
          message
          code
          errors
        }
      }
    }
  `;

  static DELETE_COURSE = gql`
    mutation CreateCourseMutation($deleteCourseId: ID!) {
      deleteCourse(id: $deleteCourseId) {
        ... on ResponseCourse {
          success
          data {
            id
            name
            holes
            is_active
          }
          code
        }
        ... on ErrorResponse {
          success
          message
          code
          errors
        }
      }
    }
  `;

  static FRAGMENT_COURSE = gql`
    fragment createCourse on Course {
      id
      name
      holes
      is_active
    }
  `;
  // =============END SETTING COURSES=============

  // =============SETTING GET_MAXIMUM_PLAYER=============
  static GET_MAXIMUM_PLAYER = gql`
    query Query {
      getMaximumPlayers {
        ... on ResponseSetting {
          code
          success
          data {
            id
            key
            value
            updated_at
            created_at
            terms_and_conditions
          }
        }
        ... on ErrorResponse {
          success
          message
          code
        }
      }
    }
  `;

  static SET_MAXIMUM_PLAYER = gql`
    mutation Mutation($setMaximumPlayersValue: Int!) {
      setMaximumPlayers(value: $setMaximumPlayersValue) {
        ... on ResponseSetting {
          success
          data {
            id
            key
            value
            terms_and_conditions
            created_at
            updated_at
          }
          code
        }
        ... on ErrorResponse {
          success
          message
          code
          errors
        }
      }
    }
  `;
  // =============END SETTING GET_MAXIMUM_PLAYER=============

  // =============SETTING ADD_ONS=============
  static GET_ALL_ADD_ONS = gql`
    query Query {
      getAllAddOns {
        ... on ResponseAddOnArray {
          success
          data {
            id
            name
            price
            is_active
            created_at
            updated_at
          }
          code
        }
        ... on ErrorResponse {
          success
          message
          code
          errors
        }
      }
    }
  `;

  static CREATE_ADD_ON = gql`
    mutation CreateAddOnMutation($createAddOnName: String!, $createAddOnPrice: Float!) {
      createAddOn(name: $createAddOnName, price: $createAddOnPrice) {
        ... on ResponseAddOn {
          success
          data {
            id
            name
            price
            is_active
            created_at
            updated_at
          }
          code
        }
        ... on ErrorResponse {
          success
          message
          code
          errors
        }
      }
    }
  `;

  static UPDATE_ADD_ON = gql`
    mutation UpdateAddOnMutation(
      $updateAddOnId: ID!
      $updateAddOnName: String!
      $updateAddOnPrice: Float!
    ) {
      updateAddOn(id: $updateAddOnId, name: $updateAddOnName, price: $updateAddOnPrice) {
        ... on ResponseAddOn {
          success
          data {
            id
            name
            price
            is_active
            created_at
            updated_at
          }
          code
        }
        ... on ErrorResponse {
          success
          message
          code
          errors
        }
      }
    }
  `;

  static DESTROY_ADD_ON = gql`
    mutation DestroyAddOnMutation($destroyAddOnId: ID!) {
      destroyAddOn(id: $destroyAddOnId) {
        ... on ResponseAddOn {
          success
          data {
            id
            name
            price
            is_active
            created_at
            updated_at
          }
          code
        }
        ... on ErrorResponse {
          success
          message
          code
          errors
        }
      }
    }
  `;

  static GET_BY_ID_ADD_ON = gql`
    query Query($getByIdAddOnId: ID!) {
      getByIdAddOn(id: $getByIdAddOnId) {
        ... on ResponseAddOn {
          success
          data {
            id
            name
            price
            is_active
            created_at
            updated_at
          }
          code
        }
        ... on ErrorResponse {
          success
          message
          code
          errors
        }
      }
    }
  `;

  static FRAGMENT_ADD_ON = gql`
    fragment createAddOn on AddOn {
      id
      name
      price
      is_active
      created_at
      updated_at
    }
  `;
  // =============END SETTING ADD_ONS=============

  // =============SETTING INCLUSION=============
  static CREATE_INCLUSION = gql`
    mutation CreateInclusionMutation($createInclusionName: String!) {
      createInclusion(name: $createInclusionName) {
        success
        data {
          id
          name
          is_active
          created_at
          updated_at
        }
        code
      }
    }
  `;

  static UPDATE_INCLUSION = gql`
    mutation UpdateInclusionMutation($updateInclusionId: ID!, $updateInclusionName: String!) {
      updateInclusion(id: $updateInclusionId, name: $updateInclusionName) {
        success
        data {
          id
          name
          is_active
          created_at
          updated_at
        }
        code
      }
    }
  `;

  static GET_ALL_INCLUSIONS = gql`
    query Query {
      getAllInclusions {
        success
        code
        data {
          id
          name
          is_active
          created_at
          updated_at
        }
      }
    }
  `;

  static DESTROY_INCLUSION = gql`
    mutation DestroyInclusionMutation($destroyInclusionId: ID!) {
      destroyInclusion(id: $destroyInclusionId) {
        success
        data {
          id
          name
          is_active
          created_at
          updated_at
        }
        code
      }
    }
  `;

  static GET_BY_ID_INCLUSION = gql`
    query Query($getByIdInclusionId: ID!) {
      getByIdInclusion(id: $getByIdInclusionId) {
        success
        data {
          id
          name
          is_active
          created_at
          updated_at
        }
        code
      }
    }
  `;

  static FRAGMENT_INCLUSION = gql`
    fragment createInclusion on Inclusion {
      id
      name
      is_active
      created_at
      updated_at
    }
  `;
  // =============END SETTING INCLUSION=============

  // =============SETTING VALIDITY DURATION=============
  static GET_VALIDITY_DURATION = gql`
    query Query {
      getValidityDuration {
        ... on ResponseSetting {
          success
          data {
            id
            key
            value
            terms_and_conditions
            created_at
            updated_at
          }
          code
        }
      }
    }
  `;

  static SET_VALIDITY_DURATION = gql`
    mutation SetValidityDurationMutation($setValidityDurationValue: Int!) {
      setValidityDuration(value: $setValidityDurationValue) {
        ... on ResponseSetting {
          success
          data {
            id
            key
            value
            terms_and_conditions
            created_at
            updated_at
          }
          code
        }
        ... on ErrorResponse {
          success
          message
          code
          errors
        }
      }
    }
  `;
  // =============END SETTING VALIDITY DURATION=============

  // =============SETTING TERM AND CONDITION=============
  static GET_TERM_AND_CONDITION = gql`
    query Query {
      getTermAndCondition {
        ... on ResponseSetting {
          success
          data {
            id
            key
            value
            terms_and_conditions
            created_at
            updated_at
          }
          code
        }
        ... on ErrorResponse {
          success
          message
          code
          errors
        }
      }
    }
  `;

  static SET_TERM_AND_CONDITION = gql`
    mutation SetTermAndConditionMutation($setTermAndConditionValue: String!) {
      setTermAndCondition(value: $setTermAndConditionValue) {
        ... on ResponseSetting {
          success
          data {
            id
            key
            value
            terms_and_conditions
            created_at
            updated_at
          }
          code
        }
        ... on ErrorResponse {
          success
          message
          code
          errors
        }
      }
    }
  `;
  // =============END SETTING TERM AND CONDITION=============

  // =============SETTING LOWER PRICE AFTER RAINCHECK=============
  static GET_LOWER_PRICE_AFTER_RAINCHECK = gql`
    query Query {
      getLowestPriceAfterRainCheck {
        ... on ResponseSetting {
          success
          data {
            id
            key
            value
            terms_and_conditions
            created_at
            updated_at
          }
          code
        }
        ... on ErrorResponse {
          success
          message
          code
          errors
        }
      }
    }
  `;

  static SET_LOWER_PRICE_AFTER_RAINCHECK = gql`
    mutation SetLowestPriceAfterRainCheckMutation($setLowestPriceAfterRainCheckValue: Float!) {
      setLowestPriceAfterRainCheck(value: $setLowestPriceAfterRainCheckValue) {
        ... on ResponseSetting {
          success
          data {
            id
            key
            value
            terms_and_conditions
            created_at
            updated_at
          }
          code
        }
        ... on ErrorResponse {
          success
          message
          code
          errors
        }
      }
    }
  `;
  // =============END SETTING LOWER PRICE AFTER RAINCHECK=============

  // =============SETTING TIME BASED RATE=============

  static GET_ALL_TIME_BASE_RATE = gql`
    query Query {
      getAllTimeBaseRate {
        ... on ResponseTimeBaseRateArray {
          success
          data {
            id
            name
            end_time
            start_time
            is_active
            created_at
            updated_at
          }
          code
        }
        ... on ErrorResponse {
          success
          message
          code
          errors
        }
      }
    }
  `;

  static GET_BY_ID_TIME_BASE_RATE = gql`
    query Query($getByIdTimeBaseRateId: ID!) {
      getByIdTimeBaseRate(id: $getByIdTimeBaseRateId) {
        ... on ResponseTimeBaseRate {
          success
          data {
            id
            start_time
            end_time
            created_at
            is_active
            updated_at
            name
            price
          }
          code
        }
      }
    }
  `;

  static CREATE_TIME_BASE_RATE = gql`
    mutation Mutation(
      $createTimeBaseRateName: String!
      $createTimeBaseRateStartTime: String!
      $createTimeBaseRateEndTime: String!
    ) {
      createTimeBaseRate(
        name: $createTimeBaseRateName
        start_time: $createTimeBaseRateStartTime
        end_time: $createTimeBaseRateEndTime
      ) {
        ... on ResponseTimeBaseRate {
          success
          data {
            id
            name
            start_time
            end_time
            is_active
            updated_at
            created_at
            price
          }
          code
        }
      }
    }
  `;

  static UPDATE_TIME_BASE_RATE = gql`
    mutation Mutation(
      $updateTimeBaseRateId: ID!
      $updateTimeBaseRateName: String!
      $updateTimeBaseRateStartTime: String!
      $updateTimeBaseRateEndTime: String!
    ) {
      updateTimeBaseRate(
        id: $updateTimeBaseRateId
        name: $updateTimeBaseRateName
        start_time: $updateTimeBaseRateStartTime
        end_time: $updateTimeBaseRateEndTime
      ) {
        ... on ResponseTimeBaseRate {
          success
          code
          data {
            id
            name
            start_time
            is_active
            updated_at
            end_time
            created_at
            price
          }
        }
      }
    }
  `;

  static DELETE_TIME_BASE_RATE = gql`
    mutation Mutation($destroyTimeBaseRateId: ID!) {
      destroyTimeBaseRate(id: $destroyTimeBaseRateId) {
        ... on ResponseTimeBaseRate {
          success
          data {
            id
            name
            end_time
            start_time
            is_active
            created_at
            updated_at
          }
          code
        }
      }
    }
  `;

  static FRAGMENT_TIME_BASE_RATE = gql`
    fragment createTimeBaseRate on TimeBaseRate {
      id
      name
      start_time
      end_time
      is_active
      created_at
      updated_at
      price
    }
  `;

  // =============END SETTING TIME BASED RATE=============

  // =============END SETTING ACCOUNT =============
  static GET_ALL_ACCOUNTS = gql`
    query getAllAccounts {
      getAllAccounts {
        success
        message
        data {
          id
          role {
            id
            name
            code
            descriptions
            is_active
            total_accounts
            account_master_features {
              id
              is_editable
              is_see_only
              is_no_access
              account_features {
                id
                feature_id
              }
            }
          }
          full_name
          email
          pin
          password
          is_active
          created_at
          updated_at
          last_activity
        }
        code
      }
    }
  `;

  static FIND_ACCOUNT_BY_ID = gql`
    query Query($id: String!) {
      findAccountById(id: $id) {
        success
        message
        data {
          id
          role {
            id
            name
            code
            descriptions
            is_active
            account_master_features {
              id
              is_editable
              is_see_only
              is_no_access
              account_features {
                id
                feature_id
              }
            }
          }
          full_name
          email
          pin
          password
          is_active
          created_at
          updated_at
          last_activity
        }
        code
      }
    }
  `;

  static SEARCH_ACCOUNT = gql`
    query searchAccount($search: String, $roles: [String]) {
      searchAccount(search: $search, roles: $roles) {
        success
        message
        data {
          id
          role {
            id
            name
            code
            descriptions
            is_active
            account_master_features {
              id
              is_editable
              is_see_only
              is_no_access
              account_features {
                id
                feature_id
              }
            }
          }
          full_name
          email
          pin
          password
          is_active
          created_at
          updated_at
          last_activity
        }
        code
      }
    }
  `;

  static CREATE_ACCOUNT = gql`
    mutation CreateAccountMutation(
      $createAccountFullname: String!
      $createAccountRoleId: String!
      $createAccountPin: String!
      $createAccountEmail: String!
    ) {
      createAccount(
        fullname: $createAccountFullname
        roleID: $createAccountRoleId
        pin: $createAccountPin
        email: $createAccountEmail
      ) {
        data {
          id
          role {
            id
            name
            code
          }
          full_name
          email
          is_active
          created_at
          updated_at
          last_activity
        }
      }
    }
  `;

  static UPDATE_ACCOUNT = gql`
    mutation UpdateAccountMutation(
      $updateAccountId: String!
      $updateAccountFullname: String!
      $updateAccountEmail: String!
      $updateAccountRoleId: String!
      $updateAccountPin: String
    ) {
      updateAccount(
        id: $updateAccountId
        fullname: $updateAccountFullname
        email: $updateAccountEmail
        roleID: $updateAccountRoleId
        pin: $updateAccountPin
      ) {
        success
        message
        data {
          id
          role {
            id
            name
          }
          full_name
          email
          is_active
          created_at
          updated_at
          last_activity
        }
        code
      }
    }
  `;

  static DELETE_ACCOUNT = gql`
    mutation DeleteAccountMutation($deleteAccountId: String!) {
      deleteAccount(id: $deleteAccountId) {
        success
        message
        data {
          id
          role {
            id
            name
          }
          full_name
          email
          is_active
          created_at
          updated_at
          last_activity
        }
        code
      }
    }
  `;

  static FRAGMENT_ACCOUNT = gql`
    fragment createAccount on Account {
      id
      role {
        id
        name
      }
      full_name
      email
      last_activity
      is_active
      created_at
      updated_at
      last_activity
    }
  `;
  // =============END SETTING ACCOUNT =============

  // =============SETTING ROLE =============
  static GET_ALL_ROLES = gql`
    query Query {
      getAllRoles {
        success
        data {
          id
          name
          total_accounts
          code
          updated_at
          created_at
        }
        code
      }
    }
  `;

  static SEARCH_ROLE = gql`
    query searchRole($searchRoleSearch: String) {
      searchRole(search: $searchRoleSearch) {
        success
        data {
          id
          name
          total_accounts
          created_at
          updated_at
        }
        code
      }
    }
  `;

  static CREATE_ROLE = gql`
    mutation CreateRoleMutation(
      $createRoleName: String!
      $createRoleCode: String!
      $createRoleCategoryFeatures: [accountMasterFeaturesPayload!]!
      $createRoleDescriptions: String
    ) {
      createRole(
        name: $createRoleName
        code: $createRoleCode
        categoryFeatures: $createRoleCategoryFeatures
        descriptions: $createRoleDescriptions
      ) {
        ... on ResponseRole {
          success
          data {
            code
            id
            name
            descriptions
            is_active
            total_accounts
            account_master_features {
              id
              is_editable
              is_see_only
              is_no_access
              account_features {
                id
                feature_id
              }
            }
            created_at
            updated_at
          }
          code
        }
        ... on ErrorResponse {
          success
          message
          code
          errors
        }
      }
    }
  `;

  static UPDATE_ROLE = gql`
    mutation UpdateRoleMutation(
      $updateRoleId: ID!
      $updateRoleName: String!
      $updateRoleCode: String!
      $updateRoleIsActive: Boolean!
      $updateRoleCategoryFeatures: [accountMasterFeaturesPayload!]!
      $updateRoleDescriptions: String
    ) {
      updateRole(
        id: $updateRoleId
        name: $updateRoleName
        code: $updateRoleCode
        is_active: $updateRoleIsActive
        categoryFeatures: $updateRoleCategoryFeatures
        descriptions: $updateRoleDescriptions
      ) {
        ... on ResponseRole {
          success
          data {
            id
            name
            code
            descriptions
            is_active
            total_accounts
            account_master_features {
              id
              is_editable
              is_see_only
              is_no_access
              account_features {
                id
                feature_id
              }
            }
            created_at
            updated_at
          }
          code
        }
        ... on ErrorResponse {
          success
          message
          code
          errors
        }
      }
    }
  `;

  static FIND_ROLE_BY_ID = gql`
    query Query($findRoleByIdId: ID!) {
      findRoleById(id: $findRoleByIdId) {
        ... on ResponseRole {
          success
          data {
            id
            name
            code
            descriptions
            is_active
            total_accounts
            account_master_features {
              id
              is_editable
              is_see_only
              is_no_access
              category_feature_master_id
              account_features {
                id
                feature_id
              }
            }
            created_at
            updated_at
          }
          code
        }
        ... on ErrorResponse {
          success
          message
          code
          errors
        }
      }
    }
  `;

  static DESTROY_ROLE = gql`
    mutation DestroyRoleMutation($destroyRoleId: ID!) {
      destroyRole(id: $destroyRoleId) {
        ... on ResponseRole {
          success
          data {
            code
            name
            id
            descriptions
            is_active
            total_accounts
            account_master_features {
              id
              is_editable
              is_see_only
              is_no_access
              account_features {
                id
                feature_id
              }
            }
            created_at
            updated_at
          }
          code
        }
        ... on ErrorResponse {
          success
          message
          code
          errors
        }
      }
    }
  `;

  static FRAGMENT_ROLE = gql`
    fragment createRole on Role {
      id
      name
      total_accounts
      updated_at
      created_at
    }
  `;
  // =============END SETTING ROLE =============

  // ============= FEATURES =============
  static GET_FEATURES = gql`
    query Query {
      getFeatures {
        success
        data {
          id
          name
          code
          descriptions
          icon
          is_active
          feature_masters {
            id
            name
            code
          }
        }
        code
      }
    }
  `;

  static GET_ALL_FEATURES_USER = gql`
    query Query {
      getAllFeaturesUser {
        id
        role
        full_name
        email
        is_active
        created_at
        updated_at
        last_activity
        scopes {
          is_editable
          is_see_only
          is_no_access
          category_id
          category_name
          category_code
          account_features {
            id
            name
            code
          }
        }
      }
    }
  `;
  // ============= END FEATURES =============

  // ============= SETTING AUTO SEND RESERVATION =============

  static CREATE_SETTING_AUTO_SEND = gql`
    mutation DataMutation($setAutoSendReservationInput: [setAutoSendReservationPayload!]!) {
      setAutoSendReservation(input: $setAutoSendReservationInput) {
        ... on ResponseSettingArray {
          data {
            key
            value
            terms_and_conditions
            created_at
            updated_at
            id
          }
          success
          code
        }
        ... on ErrorResponse {
          success
          message
          code
          errors
        }
      }
    }
  `;

  static GET_SETTING_AUTO_SEND = gql`
    query Query {
      getAutoSendReservation {
        ... on ResponseSettingArray {
          success
          data {
            id
            key
            value
            terms_and_conditions
            created_at
            updated_at
          }
          code
        }
        ... on ErrorResponse {
          success
          message
          code
          errors
        }
      }
    }
  `;

  // ============= END SETTING AUTO SEND RESERVATION =============

  // ============= SETTING CLUB NAME =============

  static GET_CLUB_NAME = gql`
    query Query {
      getClubName {
        ... on ResponseSetting {
          success
          data {
            id
            key
            value
            terms_and_conditions
            created_at
            updated_at
          }
          code
        }
        ... on ErrorResponse {
          success
          message
          code
          errors
        }
      }
    }
  `;

  static SET_CLUB_NAME = gql`
    mutation SetClubNameMutation($setClubNameValue: String!) {
      setClubName(value: $setClubNameValue) {
        ... on ResponseSetting {
          success
          data {
            id
            key
            value
            terms_and_conditions
            created_at
            updated_at
          }
          code
        }
        ... on ErrorResponse {
          message
          success
          code
          errors
        }
      }
    }
  `;
  // ============= END CLUB NAME =============

  // ============= RATE CARD =============
  static SEARCH_RATE_CARD = gql`
    query Query($searchRateCardSearch: String!) {
      searchRateCard(search: $searchRateCardSearch) {
        code
        message
        data {
          id
          name
          color
          is_active
          courses {
            id
            name
            memberships {
              id
              name
              time_based_rates {
                id
                name
                start_time
                end_time
                price
              }
            }
          }
          created_at
          updated_at
        }
      }
    }
  `;

  static FORM_RATE_CARD = gql`
    query Query {
      getAllCourses {
        ... on ResponseCourseArray {
          success
          data {
            id
            name
            holes
          }
        }
      }
      getAllMembership {
        code
        message
        data {
          id
          name
          pricing_type
          price
          duration_type
          start_date
          end_date
          is_eligible_house_account
          created_at
          updated_at
        }
      }
      getAllInclusions {
        data {
          id
          name
          is_active
          created_at
          updated_at
        }
        success
        code
      }
      getAllTimeBaseRate {
        ... on ResponseTimeBaseRateArray {
          success
          data {
            id
            name
            start_time
            end_time
            is_active
            created_at
            updated_at
            price
          }
          code
        }
        ... on ErrorResponse {
          success
          message
          errors
          code
        }
      }
      getAllInclusions {
        data {
          id
          name
          is_active
          created_at
          updated_at
        }
        success
        code
      }
    }
  `;

  static CREATE_RATE_CARD = gql`
    mutation CreateRateCardMutation($createRateCardRateCard: RateCardRead) {
      createRateCard(rateCard: $createRateCardRateCard) {
        code
        message
        data {
          id
          name
          color
          is_active
          created_at
          updated_at
        }
      }
    }
  `;

  static UPDATE_RATE_CARD = gql`
    mutation UpdateRateCardMutation($updateRateCardRateCard: RateCardRead) {
      updateRateCard(rateCard: $updateRateCardRateCard) {
        code
        message
        data {
          id
          name
          color
          is_active
          created_at
          updated_at
        }
      }
    }
  `;

  static DUPLICATE_RATE_CARD = gql`
    mutation DuplicateRateCardMutation($duplicateRateCardId: String!) {
      duplicateRateCard(id: $duplicateRateCardId) {
        code
        message
        data {
          id
          name
          is_active
          color
          created_at
          updated_at
        }
      }
    }
  `;

  static DELETE_RATE_CARD = gql`
    mutation DeleteRateCardMutation($deleteRateCardId: String!) {
      deleteRateCard(id: $deleteRateCardId) {
        code
        message
        data {
          id
          name
          color
          is_active
          created_at
          updated_at
        }
      }
    }
  `;

  static GET_BY_ID_RATE_CARD = `
    query Query($getByIdRateCardId: String!) {
      getByIdRateCard(id: $getByIdRateCardId) {
        code
        message
        data {
          id
          name
          color
          is_active
          created_at
          updated_at
          courses {
            id
            is_active
            holes
            name
            memberships {
              id
              name
              pricing_type
              price
              subscription_type {
                id
                name
                code
                created_at
                updated_at
              }
              total_membership
              duration_type
              start_date
              end_date
              is_eligible_house_account
              created_at
              updated_at
              time_based_rates {
                id
                name
                start_time
                end_time
                created_at
              is_active
                updated_at
                price
              }
            }
          }
          inclusions
        }
      }
    }`;

  static FRAGMENT_RATE_CARD = gql`
    fragment createRateCard on RateCard {
      id
      name
      color
      is_active
      created_at
      updated_at
    }
  `;
  // ============= END RATE CARD =============

  // ============= CALENDAR =============
  static FORM_CALENDAR = gql`
    query Query {
      getTeeTimes {
        ... on ResponseTeeTimeArray {
          success
          data {
            id
            name
            end_time
            start_time
            color
            starting_holes
            rate_card {
              id
              name
              color
              updated_at
              created_at
            }
          }
          code
        }
      }
      getAllRateCards {
        message
        data {
          id
          name
          color
          is_active
          created_at
          updated_at
        }
        code
      }
      getAllCourses {
        ... on ResponseCourseArray {
          success
          data {
            id
            name
            holes
          }
        }
      }
      getAllMembership {
        code
        message
        data {
          id
          name
          pricing_type
          price
          duration_type
          start_date
          end_date
          is_eligible_house_account
          created_at
          updated_at
        }
      }
      getAllInclusions {
        data {
          id
          name
          is_active
          created_at
          updated_at
        }
        success
        code
      }
      getAllTimeBaseRate {
        ... on ResponseTimeBaseRateArray {
          success
          data {
            id
            name
            start_time
            end_time
            is_active
            created_at
            updated_at
            price
          }
          code
        }
        ... on ErrorResponse {
          success
          message
          errors
          code
        }
      }
      getAllInclusions {
        data {
          id
          name
          is_active
          created_at
          updated_at
        }
        success
        code
      }
    }
  `;

  static CREATE_BULK_CALENDAR_MUTATION = gql`
    mutation CreateBulkCalendarMutation($createBulkCalendarData: [CalendarRead]) {
      createBulkCalendar(data: $createBulkCalendarData) {
        success
        data {
          id
          tee_times
          rate_cards
          start_time
          date
          end_time
          created_at
          updated_at
        }
        code
      }
    }
  `;

  static CREATE_CALENDAR = gql`
    mutation CreateCalendarMutation(
      $createCalendarTeeTimeId: ID!
      $createCalendarRateCardId: ID!
      $createCalendarStartTime: String
      $createCalendarEndTime: String
      $createCalendarDate: Date
    ) {
      createCalendar(
        tee_time_id: $createCalendarTeeTimeId
        rate_card_id: $createCalendarRateCardId
        start_time: $createCalendarStartTime
        end_time: $createCalendarEndTime
        date: $createCalendarDate
      ) {
        success
        data {
          id
          updated_at
          created_at
          date
          end_time
          start_time
          rate_cards
          tee_times
        }
        code
      }
    }
  `;

  static CREATE_CUSTOM_CALENDAR = gql`
    mutation CreateCalendarCustomMutation(
      $createCalendarCustomNewTeeTime: Boolean!
      $createCalendarCustomNewRateCard: Boolean!
      $createCalendarCustomTeeTimeId: ID
      $createCalendarCustomRateCardId: ID
      $createCalendarCustomStartTime: String
      $createCalendarCustomEndTime: String
      $createCalendarCustomDate: Date
      $createCalendarCustomTeeTime: TeeTimePayload
      $createCalendarCustomRateCard: RateCardPayload
    ) {
      createCalendarCustom(
        new_tee_time: $createCalendarCustomNewTeeTime
        new_rate_card: $createCalendarCustomNewRateCard
        tee_time_id: $createCalendarCustomTeeTimeId
        rate_card_id: $createCalendarCustomRateCardId
        start_time: $createCalendarCustomStartTime
        end_time: $createCalendarCustomEndTime
        date: $createCalendarCustomDate
        teeTime: $createCalendarCustomTeeTime
        rateCard: $createCalendarCustomRateCard
      ) {
        success
        data {
          id
          rate_cards
          tee_times
          end_time
          start_time
          date
          updated_at
          created_at
        }
        code
      }
    }
  `;

  static UPDATE_CALENDAR = gql`
    mutation UpdateCalendarMutation(
      $updateCalendarId: ID!
      $updateCalendarTeeTimeId: ID!
      $updateCalendarRateCardId: ID!
      $updateCalendarStartTime: String
      $updateCalendarEndTime: String
      $updateCalendarDate: Date
    ) {
      updateCalendar(
        id: $updateCalendarId
        tee_time_id: $updateCalendarTeeTimeId
        rate_card_id: $updateCalendarRateCardId
        start_time: $updateCalendarStartTime
        end_time: $updateCalendarEndTime
        date: $updateCalendarDate
      ) {
        success
        data {
          id
          tee_times
          start_time
          rate_cards
          end_time
          date
          created_at
          updated_at
        }
        code
      }
    }
  `;

  static CREATE_CALENDAR_CUSTOM = gql`
    mutation CreateCalendarCustomMutation(
      $createCalendarCustomNewTeeTime: Boolean!
      $createCalendarCustomNewRateCard: Boolean!
      $createCalendarCustomTeeTimeId: ID
      $createCalendarCustomRateCardId: ID
      $createCalendarCustomStartTime: String
      $createCalendarCustomEndTime: String
      $createCalendarCustomDate: Date
      $createCalendarCustomTeeTime: TeeTimePayload
      $createCalendarCustomRateCard: RateCardPayload
      $createCalendarCustomBulk: Boolean!
      $createCalendarCustomBulkTotal: [bulkPayload]
    ) {
      createCalendarCustom(
        new_tee_time: $createCalendarCustomNewTeeTime
        new_rate_card: $createCalendarCustomNewRateCard
        tee_time_id: $createCalendarCustomTeeTimeId
        rate_card_id: $createCalendarCustomRateCardId
        start_time: $createCalendarCustomStartTime
        end_time: $createCalendarCustomEndTime
        date: $createCalendarCustomDate
        teeTime: $createCalendarCustomTeeTime
        rateCard: $createCalendarCustomRateCard
        bulk: $createCalendarCustomBulk
        bulk_total: $createCalendarCustomBulkTotal
      ) {
        success
        data {
          id
          tee_times
          rate_cards
          start_time
          end_time
          date
          created_at
          updated_at
        }
        code
      }
    }
  `;

  static DELETE_CALENDAR = gql`
    mutation DeleteCalendarMutation($deleteCalendarId: ID!) {
      deleteCalendar(id: $deleteCalendarId) {
        success
        code
        data {
          id
          tee_times
          rate_cards
          start_time
          end_time
          date
          created_at
          updated_at
        }
      }
    }
  `;

  static GET_ALL_CALENDAR = gql`
    query Query($getAllCalendarType: String, $getAllCalendarDate: String) {
      getAllCalendar(type: $getAllCalendarType, date: $getAllCalendarDate) {
        success
        data {
          id
          rate_cards
          tee_times
          start_time
          end_time
          date
          created_at
          updated_at
        }
        code
      }
    }
  `;

  static GET_CALENDAR_BY_ID = gql`
    query Query($getCalendarByIdId: ID!) {
      getCalendarById(id: $getCalendarByIdId) {
        success
        data {
          tee_times
          id
          end_time
          start_time
          rate_cards
          date
          updated_at
          created_at
        }
        code
      }
    }
  `;
  static FRAGMENT_CALENDAR = gql`
    fragment createCalendar on Calendar {
      id
      rate_cards
      tee_times
      start_time
      end_time
      date
      created_at
      updated_at
    }
  `;
  // ============= END CALENDAR =============

  // ============= TEE TIME =============
  static SEARCH_TEE_TIMES = gql`
    query Query($column: String!, $filter: [String!], $search: String) {
      searchAndFilterTeeTime(column: $column, filter: $filter, search: $search) {
        success
        data {
          id
          tee_time_types
          name
          start_time
          end_time
          interval_time
          color
          number_starting_holes
          starting_holes
          custom_invervals
          is_active
          rate_card {
            id
            name
            color
            is_active
            created_at
            updated_at
            courses {
              id
              name
              holes
              is_active
            }
            inclusions
          }
        }
      }
    }
  `;

  static GET_TEE_TIMES = gql`
    query Query {
      getTeeTimes {
        ... on ResponseTeeTimeArray {
          success
          data {
            id
            tee_time_types
            name
            start_time
            end_time
            interval_time
            color
            number_starting_holes
            starting_holes
            custom_invervals
            is_active
            rate_card {
              id
              name
              color
              is_active
              created_at
              updated_at
              inclusions
            }
          }
          code
        }
        ... on ErrorResponse {
          success
          message
          code
          errors
        }
      }
    }
  `;

  static CREATE_TEE_TIMES = gql`
    mutation CreateTeeTimeMutation(
      $createTeeTimeName: String!
      $createTeeTimeColor: String!
      $createTeeTimeRateCardId: ID!
      $createTeeTimeTeeTimeTypes: String
      $createTeeTimeStartTime: String
      $createTeeTimeEndTime: String
      $createTeeTimeIntervalTime: Int
      $createTeeTimeStartingHoles: [startingHolesPayload]
      $createTeeTimeNumberStartingHoles: Int
      $createTeeTimeCustomInverval: [customInvervalsPayload]
      $createTeeTimeIsActive: Boolean
    ) {
      createTeeTime(
        name: $createTeeTimeName
        color: $createTeeTimeColor
        rate_card_id: $createTeeTimeRateCardId
        tee_time_types: $createTeeTimeTeeTimeTypes
        start_time: $createTeeTimeStartTime
        end_time: $createTeeTimeEndTime
        interval_time: $createTeeTimeIntervalTime
        starting_holes: $createTeeTimeStartingHoles
        number_starting_holes: $createTeeTimeNumberStartingHoles
        custom_inverval: $createTeeTimeCustomInverval
        is_active: $createTeeTimeIsActive
      ) {
        ... on ResponseTeeTime {
          success
          data {
            id
            tee_time_types
            name
            start_time
            end_time
            interval_time
            number_starting_holes
            color
            starting_holes
            custom_invervals
            is_active
            rate_card {
              id
              name
              color
              is_active
              updated_at
              created_at
              inclusions
            }
          }
          code
        }
        ... on ErrorResponse {
          success
          code
          message
          errors
        }
      }
    }
  `;

  static DELETE_TEE_TIME = gql`
    mutation Mutation($deleteTeeTimeId: ID!) {
      deleteTeeTime(id: $deleteTeeTimeId) {
        ... on ResponseTeeTime {
          success
          data {
            id
            tee_time_types
            name
            start_time
            end_time
            interval_time
            color
            number_starting_holes
            starting_holes
            custom_invervals
            is_active
            rate_card {
              id
              name
            }
          }
          code
        }
        ... on ErrorResponse {
          success
          message
          code
          errors
        }
      }
    }
  `;

  static UPDATE_TEE_TIMES = gql`
    mutation UpdateTeeTimeMutation(
      $updateTeeTimeId: ID!
      $updateTeeTimeName: String!
      $updateTeeTimeColor: String!
      $updateTeeTimeRateCardId: ID!
      $updateTeeTimeTeeTimeTypes: String
      $updateTeeTimeStartTime: String
      $updateTeeTimeEndTime: String
      $updateTeeTimeIntervalTime: Int
      $updateTeeTimeNumberStartingHoles: Int
      $updateTeeTimeStartingHoles: [startingHolesPayload]
      $updateTeeTimeCustomInverval: [customInvervalsPayload]
      $updateTeeTimeIsActive: Boolean
    ) {
      updateTeeTime(
        id: $updateTeeTimeId
        name: $updateTeeTimeName
        color: $updateTeeTimeColor
        rate_card_id: $updateTeeTimeRateCardId
        tee_time_types: $updateTeeTimeTeeTimeTypes
        start_time: $updateTeeTimeStartTime
        end_time: $updateTeeTimeEndTime
        interval_time: $updateTeeTimeIntervalTime
        number_starting_holes: $updateTeeTimeNumberStartingHoles
        starting_holes: $updateTeeTimeStartingHoles
        custom_inverval: $updateTeeTimeCustomInverval
        is_active: $updateTeeTimeIsActive
      ) {
        ... on ResponseTeeTime {
          success
          data {
            id
            tee_time_types
            name
            start_time
            end_time
            color
            interval_time
            number_starting_holes
            starting_holes
            custom_invervals
            is_active
            rate_card {
              id
              name
              color
              is_active
              created_at
              updated_at
              inclusions
            }
          }
          code
        }
        ... on ErrorResponse {
          success
          message
          code
          errors
        }
      }
    }
  `;

  static GET_BY_ID_TEE_TIME = gql`
    query Query($getTeeTimeByIdId: ID!) {
      getTeeTimeById(id: $getTeeTimeByIdId) {
        ... on ResponseTeeTime {
          success
          data {
            id
            tee_time_types
            name
            start_time
            end_time
            interval_time
            color
            number_starting_holes
            starting_holes
            custom_invervals
            is_active
            rate_card {
              id
              name
            }
          }
          code
        }
        ... on ErrorResponse {
          success
          code
          message
          errors
        }
      }
    }
  `;

  static FRAGMENT_TEE_TIMES = gql`
    fragment createTeeTime on TeeTimes {
      id
      tee_time_types
      name
      start_time
      end_time
      interval_time
      color
      number_starting_holes
      starting_holes
      custom_invervals
      is_active
      rate_card {
        id
        name
        color
        is_active
        created_at
        updated_at
        courses {
          id
          name
          holes
          is_active
          memberships {
            id
            name
            pricing_type
            price
            total_membership
            membership_type {
              id
              name
              code
              created_at
              updated_at
            }
            duration_type
            start_date
            end_date
            is_eligible_house_account
            created_at
            updated_at
            time_based_rates {
              id
              name
              start_time
              end_time
              is_active
              created_at
              updated_at
              price
            }
          }
        }
        inclusions
      }
    }
  `;

  // ============== END TEE TIMES ==========

  // ============== TEE SHEET ==========
  static DATA_RESERVATION = `
    id
    prefix
    number
    name
    date
    date_tee_times
    reservation_note
    reservation_message
    total_amount
    payment_status
    is_group
    group_id
    is_block_tee_time
    block_tee_times_note
    block_tee_time_by
    location_id
    checkin_at
    created_at
    updated_at
    deleted_at
    course {
      id
      name
      holes
    }
    tee_times {
      id
      name
      starting_holes
    }
    rate_cards {
      id
      name
    }
    reservation_operators {
      id
      reservation_id
      step
      full_name
      email
      created_at
      created_by
    }
    reservation_customers {
      id
      reservation_id
      customer_id
      membership_id
      membership_name
      membership_pricing_type
      membership_price
      membership_duration_type
      membership_start_date
      membership_end_date
      membership_is_eligible_house_account
      bag_id
      amount
      status
      receipts_method
      is_raincheck
      discount
      created_at
      updated_at
      is_void
      add_ons {
        id
        price
        name
        is_active
        created_at
        updated_at
      }
      customer {
        id
        full_name
        email
        phone_number
        subscription_memberships {
          id
          membership_name
          membership_id
        }
      }
      promotion {
        code
        id
      }
      raincheck {
        code
        id
       amount
       amount_type
       percentage_value
     }
    }
    rainchecks {
      issued_by
      status
      expiration_date
      id
      reservation_id
      customer_id
      code
    }
    reservation_holes {
      name
      date_time
    }
    reservation_payments {
      total
      payment_amount
      status
      paid_by
      code
      payment_methods {
        code
        name
        amount
      }
    }
  `;
  static GET_RESERVATION_STATUS = gql`
    query Query {
      getReservationStatus {
        code
        message
        data {
          name
          code
        }
      }
    }
  `;

  static GET_RESERVATIONS = gql`
    query Query($getReservationsData: GetReservationsRead) {
      getReservations(data: $getReservationsData) {
        code
        message
        data {
          ${QueryGql.DATA_RESERVATION}
        }
      }
    }
  `;

  static DELETE_RESERVATION = gql`
    mutation DeleteReservationMutation($deleteReservationId: ID!) {
      deleteReservation(id: $deleteReservationId) {
        code
        message
        data {
          id
        }
      }
    }
  `;

  static CANCEL_RESERVATION = gql`
    mutation CancelReservation($data: CancelReservationRead!) {
      cancelReservation(data: $data) {
        code
        message
        data {
          id
        }
      }
    }
  `;

  static CANCEL_RESERVATION_GROUP = gql`
    mutation CancelReservationGroupMutation($cancelReservationGroupId: ID!) {
      cancelReservationGroup(id: $cancelReservationGroupId) {
        code
        message
        data {
          id
          name
          date
          date_tee_times
          prefix
          number
          course {
            id
            name
            holes
            is_active
          }
          reservations {
            ${QueryGql.DATA_RESERVATION}
          }
        }
      }
    }
  `;

  static CREATE_RESERVATION = gql`
    mutation CreateReservationMutation($createReservationData: ReservationRead!) {
      createReservation(data: $createReservationData) {
        code
        message
        data {
          ${QueryGql.DATA_RESERVATION}
        }
      }
    }
  `;

  static CREATE_RESERVATION_GROUP = gql`
    mutation CreateReservationGroupMutation($data: ReservationGroupRead) {
      createReservationGroup(data: $data) {
        code
        message
        data {
          id
          name
          date
          date_tee_times
          prefix
          number
          course {
            id
            name
            holes
            is_active
          }
          reservations {
            ${QueryGql.DATA_RESERVATION}
          }
        }
      }
    }
  `;

  static UPDATE_RESERVATION = gql`
    mutation UpdateReservationMutation($updateReservationData: ReservationRead!) {
      updateReservation(data: $updateReservationData) {
        code
        message
        data {
          ${QueryGql.DATA_RESERVATION}
        }
      }
    }
  `;

  static UPDATE_RESERVATION_GROUP = gql`
    mutation UpdateReservationGroupMutation($data: ReservationGroupRead) {
      updateReservationGroup(data: $data) {
        code
        message
        data {
          id
          name
          date
          date_tee_times
          prefix
          number
          course {
            id
            name
            holes
            is_active
          }
          reservations {
            ${QueryGql.DATA_RESERVATION}
          }
        }
      }
    }
  `;

  static FORM_CUSTOMER_RESERVATION = gql`
    query Query {
      getAllMembership {
        code
        message
        data {
          id
          name
          price
          time_based_rates {
            id
            name
            price
            start_time
            end_time
          }
        }
      }
      getAllAddOns {
        ... on ResponseAddOnArray {
          success
          data {
            id
            name
            price
          }
          code
        }
        ... on ErrorResponse {
          success
          message
          code
          errors
        }
      }
      getMaximumPlayers {
        ... on ResponseSetting {
          code
          success
          data {
            id
            key
            value
            updated_at
            created_at
            terms_and_conditions
          }
        }
        ... on ErrorResponse {
          success
          message
          code
        }
      }
    }
  `;

  static GET_RESERVATION = gql`
    query Query($getReservationId: ID!) {
      getReservation(id: $getReservationId) {
        code
        message
        data {
          ${QueryGql.DATA_RESERVATION}
        }
      }
    }
  `;

  static GET_RESERVATION_GROUP = gql`
    query Query($getReservationGroupId: ID!) {
      getReservationGroup(id: $getReservationGroupId) {
        code
        message
        data {
          id
          name
          date
          date_tee_times
          prefix
          number
          course {
            id
            name
            holes
            is_active
          }
          reservations {
            ${QueryGql.DATA_RESERVATION}
          }
        }
      }
    }
  `;

  static CHECKIN_RESERVATION = gql`
    mutation CheckinReservationMutation($checkinReservationId: ID!, $checkinReservationCustomers: [CheckinReservationRead!]!) {
      checkinReservation(id: $checkinReservationId, customers: $checkinReservationCustomers) {
        code
        message
        data {
          ${QueryGql.DATA_RESERVATION}
        }
      }
    }
  `;

  static FRAGMENT_RESERVATION = gql`
    fragment createReservation on Reservation {
      ${QueryGql.DATA_RESERVATION}
    }
  `;

  static UPDATE_RESERVATION_TEE_SHEET_HOLE = gql`
    mutation UpdateReservationTeeSheetHoleMutation(
      $reservationId: ID
      $name: String
      $dateTime: DateTime
    ) {
      updateReservationTeeSheetHole(
        reservation_id: $reservationId
        name: $name
        date_time: $dateTime
      ) {
        code
        message
        data {
          name
          date_time
          reservation {
            id
            prefix
            number
            name
            date
            date_tee_times
            reservation_note
            reservation_message
            total_amount
            payment_status
            is_group
            group_id
            is_block_tee_time
            block_tee_times_note
            block_tee_time_by
            location_id
            checkin_at
            created_at
            updated_at
            deleted_at
            course {
              id
              name
              holes
            }
            tee_times {
              id
              name
            }
            rate_cards {
              id
              name
            }
            reservation_operators {
              id
              reservation_id
              step
              full_name
              email
              created_at
              created_by
            }
            reservation_customers {
              id
              reservation_id
              customer_id
              membership_id
              membership_name
              membership_pricing_type
              membership_price
              membership_duration_type
              membership_start_date
              membership_end_date
              membership_is_eligible_house_account
              bag_id
              amount
              status
              receipts_method
              is_raincheck
              discount
              created_at
              updated_at
              add_ons {
                id
                price
                name
                is_active
                created_at
                updated_at
              }
              customer {
                id
                full_name
                email
                phone_number
                subscription_memberships {
                  id
                  membership_name
                  membership_id
                }
              }
              promotion {
                code
                id
              }
            }
            rainchecks {
              issued_by
              status
              expiration_date
              id
              reservation_id
              customer_id
              code
            }
          }
        }
      }
    }
  `;

  // PAYMENT
  static CREATE_PAYMENT = gql`
    mutation CreatePaymentMutation($createPaymentData: PaymentRead) {
      createPayment(data: $createPaymentData) {
        code
        message
        data {
          id
          name
          created_at
          updated_at
          reservation_customers {
            id
            status
            discount
            total_amount
            split_amount
            created_at
            updated_at
            customer {
              id
              full_name
            }
          }
        }
      }
    }
  `;

  static GET_PAYMENT_INFO = gql`
    query Query($getPaymentInfoId: ID!) {
      getPaymentInfo(id: $getPaymentInfoId) {
        code
        message
        data {
          id
          name
          created_at
          updated_at
          reservation_customers {
            id
            status
            discount
            total_amount
            split_amount
            created_at
            updated_at
            customer {
              id
              full_name
            }
          }
        }
      }
    }
  `;

  static GET_ALL_PAYMENT_METHODS = gql`
    query Query {
      getAllPaymentMethods {
        data {
          id
          name
          code
          is_active
          created_at
          updated_at
        }
        code
        message
      }
    }
  `;

  // RECEIPT
  static GET_ALL_RECEIPT_METHODS = gql`
    query Query {
      getAllReceiptMethods {
        code
        message
        data {
          code
          name
        }
      }
    }
  `;

  static CREATE_RECEIPT = gql`
    mutation Mutation($createReceiptData: ReceiptRead) {
      createReceipt(data: $createReceiptData) {
        code
        message
        data {
          code
          name
        }
      }
    }
  `;
  // ============== END TEE SHEET ==========

  // ============== CUSTOMER ==========
  static SEARCH_CUSTOMER = gql`
    query Query($searchCustomerSearch: String, $filter: filterCustomer) {
      searchCustomer(search: $searchCustomerSearch, filter: $filter) {
        success
        data {
          id
          customer_id
          full_name
          phone_number
          prefix_phone_number
          phone_country_iso
          email
          image_url
          subscription_memberships {
            id
            customer_id
            membership_id
            membership_name
          }
        }
      }
    }
  `;

  static GET_CUSTOMER = gql`
    query GetCustomer($id: ID!) {
      getCustomer(id: $id) {
        data {
          id
          customer_id
          full_name
          prefix_phone_number
          phone_number
          phone_country_iso
          cc_number
          email
          cc_date
          cc_cardholder_name
          birthday
          country
          province
          city
          address
          nationality
          gender
          company
          image_url
          is_active
          location_id
          created_at
          updated_at
          deleted_at
          subscription_memberships {
            id
            customer_id
            membership_id
            membership_name
            membership_pricing_type
            membership_price
            membership_end_date
            membership_start_date
            membership_duration_type
            membership_is_eligible_house_account
            subscription_type_id
            subscription_type_name
            subscription_type_code
            start_time
            end_time
            is_active
          }
        }
      }
    }
  `;

  static GET_RESERVATIONS_CUSTOMER = gql`
    query GetReservationCustomer($reservationId: ID!, $customerId: ID!) {
      getReservationCustomer(reservation_id: $reservationId, customer_id: $customerId) {
        data {
          id
          prefix
          number
          name
          date
          date_tee_times
          reservation_note
          reservation_message
          total_amount
          payment_status
          is_group
          group_id
          is_block_tee_time
          block_tee_times_note
          block_tee_time_by
          location_id
          checkin_at
          created_at
          updated_at
          deleted_at
          course {
            name
            id
            holes
            is_active
          }
        }
      }
    }
  `;

  static GET_HISTORY_CUSTOMER = gql`
    query GetHistoryReservation($customerId: ID!) {
      getHistoryReservation(customer_id: $customerId) {
        data {
          id
          prefix
          number
          name
          date
          date_tee_times
          reservation_note
          reservation_message
          total_amount
          payment_status
          course {
            name
            holes
            id
          }
        }
      }
    }
  `;

  static UPDATE_CUSTOMER = gql`
    mutation UpdateCustomer($data: CustomerRead!) {
      updateCustomer(data: $data) {
        code
        message
      }
    }
  `;
  // ============== END CUSTOMER ==========

  // ============== RAINCHECK ISSUE ==========
  static GENERATE_RAINCHECK_CODE = `
    query Query {
      generateRaincheckCode {
        code
        message
        data {
          code
        }
      }
    }
  `;

  static CREATE_RAINCHECK = gql`
    mutation CreateRaincheckMutation($data: RaincheckRead!) {
      createRaincheck(data: $data) {
        code
        message
        data {
          rainchecks {
            id
            reservation_id
            customer_id
            code
            total_billed
            amount
            amount_type
            percentage_value
            duration
            expiration_date
            status
            issued_by
            course {
              id
              name
              holes
              is_active
            }
            reservations {
              id
              prefix
              number
              name
              date
              date_tee_times
              reservation_note
              reservation_message
              total_amount
              payment_status
              is_group
              group_id
              is_block_tee_time
              block_tee_times_note
              block_tee_time_by
              location_id
              checkin_at
              created_at
              updated_at
              deleted_at
              course {
                id
                name
                holes
              }
              tee_times {
                id
                name
              }
              rate_cards {
                id
                name
              }
              reservation_operators {
                id
                reservation_id
                step
                full_name
                email
                created_at
                created_by
              }
              reservation_customers {
                id
                reservation_id
                customer_id
                membership_id
                membership_name
                membership_pricing_type
                membership_price
                membership_duration_type
                membership_start_date
                membership_end_date
                membership_is_eligible_house_account
                bag_id
                amount
                status
                receipts_method
                is_raincheck
                discount
                created_at
                updated_at
                add_ons {
                  id
                  price
                  name
                  is_active
                  created_at
                  updated_at
                }
                customer {
                  id
                  full_name
                  email
                  phone_number
                  subscription_memberships {
                    id
                    membership_name
                    membership_id
                  }
                }
                promotion {
                  code
                  id
                }
              }
              rainchecks {
                issued_by
                status
                expiration_date
                id
                reservation_id
                customer_id
                code
              }
            }
            customer {
              id
              full_name
              phone_number
              prefix_phone_number
              phone_country_iso
              email
            }
            location_id
          }
          summary {
            total_raincheck
            total_redeemed
            raincheck_percentage
          }
        }
      }
    }
  `;
  // ============== END RAINCHECK ISSUE ==========

  // ============== RESERVATION REPORT ==========
  static GET_RESERVATIONS_REPORT = gql`
    query Query($data: ReservationReportRead) {
      getReservationsReport(data: $data) {
        code
        message
        data {
          summary {
            total_reservation
            average_reservation_per_hour
          }
          reservation_by_player {
            player
            total_reservation
          }
          reservation_by_flight {
            tee_time_name
            total_reservation
          }
          reservation_by_status {
            payment_status
            total_reservation
          }
          reservation_by_courses {
            course_id
            course_name
            hole
            total_reservation
          }
          reservation_by_datetime {
            date
            total_reservation
            details {
              total_reservation
              time
            }
          }
          reservation_by_group {
            reservation_type
            total_reservation
          }
          reservation_walk_in_upcoming {
            status
            total_reservation
          }
        }
      }
    }
  `;

  static EXPORT_RESERVATION_REPORT = gql`
    mutation GetReservationExportMutation($data: ReservationReportRead) {
      getReservationExport(data: $data) {
        data {
          link_download
        }
      }
    }
  `;
  // ============== END RESERVATION REPORT ==========

  // ============== REVENUE REPORT ==========
  static GET_REVENUE_REPORT = gql`
    query Query($data: RevenueReportRead) {
      getRevenueReport(data: $data) {
        code
        message
        data {
          summary {
            total_green_fee
            total_add_ons
            total_revenue
            total_cancellation_fee
            average_green_fee
            average_add_ons
            average_revenue
          }
          chart {
            revenue_by_datetime {
              date
              revenue
              details {
                revenue
                time
              }
            }
            revenue_by_courses {
              total
              courses {
                course_id
                course_name
                holes
                revenue
              }
            }
            revenue_by_addons {
              total
              addons {
                add_ons_name
                revenue
              }
            }
            revenue_by_customer_memberships {
              total
              customer_memberships {
                name
                revenue
              }
            }
            revenue_by_payment_methods {
              total
              payment_methods {
                payment_method
                revenue
              }
            }
            revenue_by_paid_unpaid {
              paid_unpaid {
                status
                total_qty
                average
                total_revenue
              }
              total
            }
          }
          details {
            cancellation_fee {
              total_qty
              subtotal_cancellation_fee
              total_cancellation_fee
            }
            revenue_by_green_fee_addons {
              green_fee {
                id
                name
                subtotal_revenue
                total_qty
                total_revenue
                memberships {
                  id
                  name
                  price
                  qty
                  total
                }
              }
              add_ons {
                subtotal_revenue
                total_qty
                total_revenue
                addons {
                  add_ons_name
                  revenue
                  average
                  qty
                }
              }
            }
            revenue_by_customer_memberships {
              subtotal_revenue
              total_qty
              total_revenue
              customer_memberships {
                name
                revenue
                average
                qty
              }
            }
            revenue_by_payment_methods {
              subtotal_revenue
              total_qty
              total_revenue
              payment_methods {
                payment_method
                average
                qty
                revenue
              }
            }
          }
        }
      }
    }
  `;

  static EXPORT_REVENUE_REPORT = gql`
    mutation GetRevenueExportMutation($data: RevenueReportRead) {
      getRevenueExport(data: $data) {
        data {
          link_download
        }
      }
    }
  `;
  // ============== END REVENUE REPORT ==========

  // ============== CUSTOMER CHECKINS ==========
  static GET_CUSTOMER_CHECKINS = gql`
    query Query($data: CustomerCheckinRead, $search: String) {
      getCustomerCheckins(data: $data, search: $search) {
        code
        message
        data {
          summary {
            total_checkins
            total_paid
            total_unpaid
          }
          customers {
            id
            status
            amount
            is_void
            is_raincheck
            customer {
              id
              full_name
            }
            reservations {
              id
              number
              prefix
              reservation_operators {
                full_name
                created_at
                step
              }
              payment_status
              checkin_at
              total_amount
            }
          }
        }
      }
    }
  `;

  static VOID_CUSTOMER_CHECKINS = gql`
    mutation VoidCustomerCheckinsMutation($reservationId: ID!, $customerId: ID!) {
      voidCustomerCheckins(reservation_id: $reservationId, customer_id: $customerId) {
        code
        message
      }
    }
  `;

  static REDEEM_CUSTOMER_CHECKINS = gql`
    mutation RedeemRaincheckMutation($redeemRaincheckId: ID!) {
      redeemRaincheck(id: $redeemRaincheckId) {
        code
        message
      }
    }
  `;
  // ============== END CUSTOMER CHECKINS ==========

  // ============== RAINCHECK LIST ==========
  static GET_RAINCHECKS = gql`
    query Query($data: FilterRaincheck) {
      getRainchecks(data: $data) {
        data {
          summary {
            total_raincheck
            total_redeemed
            raincheck_percentage
          }
          rainchecks {
            id
            created_at
            customer {
              id
              full_name
            }
            issued_by
            status
            expiration_date
            amount
            code
            reservation_id
            course {
              id
              name
              holes
            }
          }
        }
      }
    }
  `;

  static GET_RAINCHECK = gql`
    query Query($getRaincheckId: ID!) {
      getRaincheck(id: $getRaincheckId) {
        code
        message
        data {
          code
          amount
          expiration_date
          status
          issued_by
          reservation_id
          course {
            name
            holes
          }
          created_at
        }
      }
    }
  `;
  static VOID_RAINCHECK = gql`
    mutation VoidRaincheckMutation($voidRaincheckId: ID!) {
      voidRaincheck(id: $voidRaincheckId) {
        code
        message
      }
    }
  `;

  static SEND_RAINCHECK = gql`
    mutation SendRaincheck($data: SendRaincheckRead!) {
      sendRaincheck(data: $data) {
        code
        message
      }
    }
  `;
  // ============== END RAINCHECK LIST ==========

  // ============== DASHBOARD ==========
  static GET_RESERVATIONS_OVERVIEW = gql`
    query Query($data: ReservationReportRead) {
      getReservationsReport(data: $data) {
        code
        message
        data {
          summary {
            total_reservation
            average_reservation_per_hour
          }
          reservation_by_datetime {
            date
            total_reservation
          }
        }
      }
    }
  `;

  static GET_REVENUE_OVERVIEW = gql`
    query Query($data: RevenueReportRead) {
      getRevenueReport(data: $data) {
        code
        message
        data {
          summary {
            total_green_fee
            total_add_ons
            total_revenue
            total_cancellation_fee
            average_green_fee
            average_add_ons
            average_revenue
          }
          chart {
            revenue_by_datetime {
              date
              revenue
            }
          }
        }
      }
    }
  `;

  static GET_NOTE_FOR_TODAY = gql`
    query Query {
      getNoteForToday {
        code
        message
        data {
          id
          name
          note
          time
          course {
            name
            holes
          }
        }
      }
    }
  `;

  static GET_STATUS_INSIGHT = gql`
    query Query {
      getStatusInsight {
        data {
          reserved
          checked_in
          paid
          no_show
          canceled
          rainchecks_issued
        }
        code
        message
      }
    }
  `;

  static GET_STEP_GUIDE = gql`
    query Data {
      getStepGuideApp {
        data {
          tee_time
          rate_card
          tee_sheet
          add_ons
          inclusions
          raincheck
        }
      }
    }
  `;
  // ============== END DASHBOARD ==========

  // ============== TEE TIME BLOCK ==========
  static FRAGMENT_TEE_TIME_BLOCK = gql`
    fragment createTeeTimeBlock on TeeTimeBlock {
      id
      blocked_times
      deleted_times
      calendar_id
      note
    }
  `;

  static GET_TEE_TIME_BLOCK = gql`
    query Query($calendarId: ID!) {
      getTeeTimeBlock(calendar_id: $calendarId) {
        ... on ResponseTeeTimeBlock {
          success
          data {
            id
            blocked_times
            deleted_times
            calendar_id
            note
          }
          code
        }
        ... on ErrorResponse {
          success
          message
          code
          errors
        }
      }
    }
  `;

  static CREATE_TEE_TIME_BLOCK = gql`
    mutation CreateTeeTimeBlockMutation(
      $blockedTimes: [blockTimePayload]!
      $deletedTimes: [deleteTimePayload]!
      $calendarId: ID!
      $note: String
    ) {
      createTeeTimeBlock(
        blocked_times: $blockedTimes
        deleted_times: $deletedTimes
        calendar_id: $calendarId
        note: $note
      ) {
        ... on ResponseTeeTimeBlock {
          success
          data {
            id
            blocked_times
            deleted_times
            calendar_id
            note
          }
          code
        }
        ... on ErrorResponse {
          success
          message
          code
          errors
        }
      }
    }
  `;

  static UPDATE_TEE_TIME_BLOCK = gql`
    mutation UpdateTeeTimeBlockMutation(
      $blockedTimes: [blockTimePayload]!
      $deletedTimes: [deleteTimePayload]!
      $calendarId: ID!
      $updateTeeTimeBlockId: ID!
      $note: String
    ) {
      updateTeeTimeBlock(
        blocked_times: $blockedTimes
        deleted_times: $deletedTimes
        calendar_id: $calendarId
        id: $updateTeeTimeBlockId
        note: $note
      ) {
        ... on ResponseTeeTimeBlock {
          success
          data {
            id
            blocked_times
            deleted_times
            calendar_id
            note
          }
          code
        }
        ... on ErrorResponse {
          success
          message
          code
          errors
        }
      }
    }
  `;
  // ============== END TEE TIME BLOCK ==========

  // ============== END CART ==========
  static GET_RESERVATION_CART = gql`
    query Query($date: String) {
      getReservationCartSign(date: $date) {
        data {
          id
          number
          customer {
            id
            name
          }
          reservation {
            id
            prefix
            number
            date_tee_time
            date
            hole
          }
        }
      }
    }
  `;

  static UPDATE_RESERVATION_CART = gql`
    mutation SuccessMutation($data: CartSignRead!) {
      updateReservationCartSign(data: $data) {
        success
        data {
          number
          id
        }
      }
    }
  `;
  // ============== END CART ==========

  static GET_MEMBERSHIPS = gql`
    query GetAllMembership {
      getAllMembership {
        data {
          id
          name
          pricing_type
          price
          total_membership
          duration_type
          start_date
          end_date
          is_eligible_house_account
          total_user
          created_at
          updated_at
        }
      }
    }
  `;

  static SEARCH_MEMBERSHIPS = gql`
    query SearchMembership($search: String) {
      searchMembership(search: $search) {
        message
        data {
          id
          name
          pricing_type
          price
          total_membership
          start_date
          duration_type
          end_date
          is_eligible_house_account
          total_user
          time_based_rates {
            id
            name
            start_time
            end_time
            is_active
            price
          }
          subscription_type {
            id
            name
            code
            total_user
          }
        }
      }
    }
  `;

  static CREATE_MEMBERSHIP = gql`
    mutation CreateMembership($data: MembershipRead) {
      createMembership(data: $data) {
        code
        message
        data {
          id
          name
        }
      }
    }
  `;

  static UPDATE_MEMBERSHIP = gql`
    mutation UpdateMembership($data: MembershipRead) {
      updateMembership(data: $data) {
        code
        message
        data {
          id
          name
        }
      }
    }
  `;

  static DELETE_MEMBERSHIP = gql`
    mutation DeleteMembership($id: ID!) {
      deleteMembership(id: $id) {
        message
        code
        data {
          id
          name
        }
      }
    }
  `;

  static GET_SUBSCRIPTION_TYPE = gql`
    query Data {
      getAllSubscriptionType {
        data {
          id
          name
          code
        }
      }
    }
  `;

  // ============== SUBSCRIBE ==========
  static SUBSCRIBE_RESERVATION = gql`
    subscription GetReservations($data: SubscribeGetReservationsRead) {
      getReservations(data: $data) {
        code
        message
      }
    }
  `;

  static GET_SUBSCRIPTION_TYPE = gql`
    query Data {
      getAllSubscriptionType {
        data {
          id
          name
          code
        }
      }
    }
  `;

  static SEARCH_CANCEL_RESERVATION_REPORT = gql`
    query SearchCancelReservationReport($data: cancelReservationReportRead) {
      searchCancelReservationReport(data: $data) {
        code
        message
        data {
          summary {
            total_cancellation
            total_cancellation_fee
          }
          cancel_reservations {
            customer {
              id
              full_name
            }
            reservation {
              number
              cancellation_date
              id
              cancellation_reason
              cancellation_fee
              cancellation_by_id
              cancellation_by_name
            }
          }
        }
      }
    }
  `;

  static GET_CANCELATION_POLICY = gql`
    query GetCancellationPolicy {
      getCancellationPolicy {
        ... on ResponseSetting {
          data {
            terms_and_conditions
            cancellation_policy
            value
            key
            id
          }
        }
      }
    }
  `;

  static SET_CANCELATION_POLICY = gql`
    mutation SetCancellationPolicy($value: String!) {
      setCancellationPolicy(value: $value) {
        ... on ResponseSetting {
          success
        }
      }
    }
  `;

  static SEARCH_BILLING_STATEMENT = gql`
    query SearchBillingStatement($data: BillingStatementRead) {
      searchBillingStatement(data: $data) {
        message
        code
        data {
          id
          billing_statement_id
          status
          date
          amount
        }
      }
    }
  `;

  static VALIDATE_RAINCHECK_CODE = gql`
    mutation ValidateRaincheckCode($code: String!) {
      validateRaincheckCode(code: $code) {
        code
        message
        data {
          code
        }
      }
    }
  `;

  static GET_VOUCHER_CODE = gql`
  query GetVoucherByCode($data: VoucherRead) {
    getVoucherByCode(data: $data) {
      code
      message
      data {
        code
        amount
        amount_type
        expiration_date
        is_raincheck
      }
    }
  }
  `
}

export default QueryGql;
