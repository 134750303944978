import React from 'react';

function SvgDelete({ id }) {
  return (
    <svg
      id={id}
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="none"
      viewBox="0 0 16 18"
    >
      <path
        fill="currentColor"
        d="M6.333 14.001a.833.833 0 00.834-.833v-5a.833.833 0 00-1.667 0v5a.833.833 0 00.833.833zm8.334-10h-3.334v-.833a2.5 2.5 0 00-2.5-2.5H7.167a2.5 2.5 0 00-2.5 2.5v.833H1.333a.833.833 0 000 1.667h.834v9.167a2.5 2.5 0 002.5 2.5h6.666a2.5 2.5 0 002.5-2.5V5.668h.834a.833.833 0 000-1.667zm-8.334-.833a.833.833 0 01.834-.833h1.666a.833.833 0 01.834.833v.833H6.333v-.833zm5.834 11.667a.833.833 0 01-.834.833H4.667a.833.833 0 01-.834-.833V5.668h8.334v9.167zM9.667 14a.833.833 0 00.833-.833v-5a.833.833 0 10-1.667 0v5a.833.833 0 00.834.833z"
      ></path>
    </svg>
  );
}

export default SvgDelete;
