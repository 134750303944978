import {
  DeleteOutlined,
  DownloadOutlined,
  DownOutlined,
  EditOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons';
import { Dropdown, Menu } from 'antd';
import Text from 'antd/lib/typography/Text';
import React, { useCallback, useMemo, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import Pathname from 'constants/Pathname';
import Avatar from 'components/atoms/Avatar';
import IconButton from 'components/atoms/IconButton';
import SvgEmptyCustomerList from 'components/atoms/SvgEmptyCustomerList';
import AppWrapper from 'components/templates/AppWrapper';
import PageEmpty from 'components/templates/PageEmpty';
import TableContent from 'components/templates/TableContent';
import SvgMoreOutline from 'components/atoms/SvgMoreOutline';
// import SvgUpload from 'components/atoms/SvgUpload';
import Button from 'components/atoms/Button';
import QueryGql from 'constants/QueryGql';
import { useQuery } from '@apollo/client';
import { AppContext } from 'App';

const CustomerList = () => {
  const history = useHistory();
  const [search, setSearch] = useState('');
  const { store } = useContext(AppContext);

  const { data, loading: loadingSearchCustomer } = useQuery(QueryGql.SEARCH_CUSTOMER, {
    fetchPolicy: 'cache-and-network',
    variables: {
      searchCustomerSearch: search,
      filter: store.searchCustomerFilter,
    },
  });

  const dataCustomer = useMemo(
    () =>
      data?.searchCustomer?.data?.map(item => ({
        ...item,
      })),
    [data],
  );
  const contenxMenu = data => (
    <Menu>
      <Menu.Item
        key='0'
        onClick={() =>
          history.push(
            Pathname.CUSTOMER_LIST + '/' + Pathname.CUSTOMER_LIST_DETAIL + `?id=${data.id}`,
            {
              background: true,
            },
          )
        }>
        <InfoCircleOutlined /> View customer
      </Menu.Item>
      <Menu.Item
        key='1'
        onClick={() =>
          history.push(
            Pathname.CUSTOMER_LIST + '/' + Pathname.CUSTOMER_LIST_FORM + `?act=edit&id=${data.id}`,
            {
              background: true,
            },
          )
        }>
        <EditOutlined /> Edit customer
      </Menu.Item>
      { data.name === 'guest' ? null : <>
        <Menu.Item
          key='2'
          className='second-menu-edit'
          onClick={() =>
            history.push(
              Pathname.CUSTOMER_LIST + '/' + Pathname.CUSTOMER_LIST_DELETE + `?id=${data.id}`,
              {
                background: true,
                name: data.name,
              },
            )
          }>
          <DeleteOutlined /> Delete customer
        </Menu.Item>
      </> }
    </Menu>
  );

  const columns = useMemo(
    () => [
      {
        title: 'Customer name',
        dataIndex: 'full_name',
        render: text => (
          <div>
            <Avatar title={text} style={{ marginRight: 8 }} />
            <Text type='primary'>{text}</Text>
          </div>
        ),
      },
      {
        title: 'Type / membership',
        dataIndex: 'subscription_memberships',
        render: d =>
          d?.map((v, i) => (
            <Text key={i} type='primary' style={{ textTransform: 'capitalize' }}>
              {v.membership_name}
            </Text>
          )),
      },
      {
        title: 'Phone number',
        render: row => (
          <Text type='primary'>
            {row.phone_number && '+'}{row.prefix_phone_number} {row.phone_number}
          </Text>
        ),
      },
      {
        title: 'Email',
        dataIndex: 'email',
        render: text => <Text type='primary'>{text}</Text>,
      },
      {
        title: '',
        align: 'right',
        width: 100,
        render: row => (
          <Dropdown trigger={['hover', 'click']} overlay={contenxMenu({ id: row.id, name: row.full_name })}>
            <div>
              <IconButton iconSvg={SvgMoreOutline} iconColor='primary' />
            </div>
          </Dropdown>
        ),
      },
    ],
    [],
  );

  const handleAction = useCallback(e => {
    if (e.key === 'import') {
      history.push(Pathname.CUSTOMER_LIST + '/' + Pathname.CUSTOMER_LIST_IMPORT, {
        background: true,
      });
    } else {
      history.push(Pathname.CUSTOMER_LIST + '/' + Pathname.CUSTOMER_LIST_EXPORT, {
        background: true,
      });
    }
  });

  const action = useMemo(
    () => (
      <Menu onClick={handleAction}>
        {/* <Menu.Item key='import' icon={<Icon component={SvgUpload} />}>
          Import customers
        </Menu.Item> */}
        <Menu.Item key='export' icon={<DownloadOutlined />}>
          Export customers
        </Menu.Item>
      </Menu>
    ),
    [],
  );

  return (
    <AppWrapper>
      {!dataCustomer?.length && !loadingSearchCustomer && search === '' ? (
        <PageEmpty
          title='Your business is in need of customers'
          desc='With Sello, we make sure you are always in close touch with your customers. Add your customers and have access to learn and analyze more about them to further grow your businesses.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;'
          buttonLabel='Add new customer'
          secoundButton={{
            buttonLabel: 'Import customers',
            buttonOnClick: () =>
              history.push(Pathname.CUSTOMER_LIST_IMPORT, {
                background: true,
              }),
          }}
          illustration={SvgEmptyCustomerList}
          buttonOnClick={() =>
            history.push(Pathname.CUSTOMER_LIST_FORM, {
              background: true,
            })
          }
        />
      ) : (
        <TableContent
          columns={columns}
          data={dataCustomer}
          buttonAdd={{
            label: 'Add new customer',
            onClick: () =>
              history.push(
                Pathname.CUSTOMER_LIST + '/' + Pathname.CUSTOMER_LIST_FORM + '?act=add',
                {
                  background: true,
                },
              ),
          }}
          searchInput={{
            placeholder: 'Search customer',
            onChange: e => setSearch(e.target.value),
            loading: loadingSearchCustomer,
          }}
          loading={loadingSearchCustomer}
          buttonFilter={{
            onClick: () =>
              history.push(Pathname.CUSTOMER_LIST + '/' + Pathname.CUSTOMER_LIST_FILTER, {
                background: true,
              }),
          }}
          extraRight={[
            <Dropdown trigger={['hover', 'click']} key='action' overlay={action}>
              <Button type='outline' style={{ marginRight: 4 }}>
                Action <DownOutlined />
              </Button>
            </Dropdown>,
          ]}
        />
      )}
    </AppWrapper>
  );
};

export default CustomerList;
