import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import Text from 'antd/lib/typography/Text';
import Lozenges from '../../atoms/Lozenges';
import { Row } from 'antd';
import { useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import BillingInvoice from './BillingInvoice';
import TableContent from 'components/templates/TableContent';

const BillingTableContent = ({ data, loading }) => {
  const [detail, setDetail] = useState([]);
  const printRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });
  const columnsBillingStatements = [
    {
      title: 'Statement ID',
      width: 180,
      dataIndex: 'statementId',
      render: text => (
        <Row align='middle' wrap={false}>
          <Text style={{ fontSize: 14 }} type='primary'>
            {text}
          </Text>
        </Row>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      width: 180,
      align: 'center',
      render: text => (
        <Row justify='center'>
          <Lozenges status={text} />
        </Row>
      ),
    },
    {
      title: 'For the month',
      width: 180,
      dataIndex: 'forTheMonth',
      render: text => (
        <Text style={{ fontSize: 14 }} type='primary'>
          {text}
        </Text>
      ),
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      render: text => (
        <Text style={{ fontSize: 14 }} type='primary'>
          {text}
        </Text>
      ),
    },
    {
      title: '',
      dataIndex: 'statement',
      render: (text, item) => (
        <button
          style={{
            color: '#1F4BFF',
            fontSize: '14px',
            fontWeight: 'bold',
            cursor: 'pointer',
            border: 'none',
            backgroundColor: 'transparent',
          }}
          onClick={() => {
            setDetail(item);

            setTimeout(() => {
              handlePrint();
            }, 500);
          }}
          type='primary'>
          {text}
        </button>
      ),
    },
  ];
  const columns = useMemo(() => {
    return columnsBillingStatements;
  }, []);

  return (
    <>
      <TableContent header={false} loading={loading} data={data} columns={columns} />
      <div style={{ display: 'none' }}>
        <div ref={printRef}>
          <BillingInvoice
            verify={detail.status === 'paid' ? 'PAID' : 'UNPAID'}
            itemName='Subscribe'
            itemPrice={detail.amount}
            amount={detail.amount}
            clientAddress=''
            clientName='Customer'
            billingNo={detail.statementId}
            date={detail.forTheMonth}
            subTotal={detail.amount}
            grandTotal={detail.amount}
          />
        </div>
      </div>
    </>
  );
};

BillingTableContent.propsTypes = {
  data: PropTypes.array,
  loading: PropTypes.bool,
};

export default BillingTableContent;