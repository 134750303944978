import { useMutation, useQuery } from '@apollo/client';
import { Button, notification } from 'antd';
import axios from 'axios';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
// import Pathname from '../../constants/Pathname';
import QueryGql from '../../constants/QueryGql';
import { authFromLocal } from '../../utils/function';
import extractQuery  from '../../utils/useQuery';
import Loading from '../atoms/Loading';
// import Modal from '../organisms/Modal';
import Drawer from '../organisms/Drawer';
import RateCardsForm from '../organisms/RateCardsForm';
import RateCardsFormInclusions from '../organisms/RateCardsFormInclusions';
import RateCardsFormTable from '../organisms/RateCardsFormTable';
import './RateCardsCreate.less';

const RateCardsCreate = () => {
  const history = useHistory();
  const queryString = extractQuery(location.search);
  const [loadingGetByIdRateCard, setLoadingGetByIdRateCard] = useState(false);
  const [dataGetByIdRateCard, setDataGetByIdRateCard] = useState(null);
  const act = queryString.get('act');
  const refRateCardForm = useRef();
  const refRateCardsFormTable = useRef();
  const refRateCardsFormInclusions = useRef();

  const { loading: loadingFormRateCard, data: dataFormRateCard } = useQuery(
    QueryGql.FORM_RATE_CARD
  );
  const [createRateCard] = useMutation(QueryGql.CREATE_RATE_CARD, {
    onCompleted: () => {
      const key = `open${Date.now()}`;
      notification.success({
        message: 'Rate card successfully added',
        description: `${refRateCardForm.current.handleSubmit().name} has been successfully added to the rate card list.`,
        btn: <Button type="text" className="notification-button-dismiss-success" onClick={() => notification.close(key)}>Dismiss</Button>,
        key,
        closeIcon: <div />,
        placement: 'bottomLeft'
      });
      history?.goBack();
    },
    onError: () => {
      history?.goBack();
    },
    update(cache, { data: { createRateCard }}) {
      cache.modify({
        fields: {
          searchRateCard(existingRateCards = { data : [] }) {
            const newRateCardsRef = cache.writeFragment({
              data: createRateCard.data,
              fragment: QueryGql.FRAGMENT_INCLUSION
            });
            return {
              ...existingRateCards,
              data: [
                ...existingRateCards.data,
                newRateCardsRef,
              ]
            };
          }
        }
      });
    }
  });
  const [updateRateCard] = useMutation(QueryGql.UPDATE_RATE_CARD, {
    onCompleted: () => {
      history?.goBack();
    },
    onError: () => {
      history?.goBack();
    }
  });
  // const [duplicateRateCard] = useMutation(QueryGql.DUPLICATE_RATE_CARD, {
  //   onCompleted: () => {
  //     const key = `open${Date.now()}`;
  //     notification.success({
  //       message: 'Rate card successfully duplicated',
  //       description: `${dataGetByIdRateCard.getByIdRateCard.data.name} has been successfully added to the inclusion list.`,
  //       btn: <Button type="text" className="notification-button-dismiss-success" onClick={() => notification.close(key)}>Dismiss</Button>,
  //       key,
  //       closeIcon: <div />,
  //       placement: 'bottomLeft'
  //     });
  //     history?.goBack();
  //   },
  //   onError: () => {
  //     history?.goBack();
  //   },
  //   update(cache, { data: { duplicateRateCard }}) {
  //     cache.modify({
  //       fields: {
  //         searchRateCard(existingRateCards = { data : [] }) {
  //           const newRateCardsRef = cache.writeFragment({
  //             data: duplicateRateCard.data,
  //             fragment: QueryGql.FRAGMENT_INCLUSION
  //           });
  //           return {
  //             ...existingRateCards,
  //             data: [
  //               ...existingRateCards.data,
  //               newRateCardsRef,
  //             ]
  //           };
  //         }
  //       }
  //     });
  //   }
  // });

  const getByIdRateCard = async () => {
    const auth = authFromLocal();
    const token = auth?.login.token;
    const id = queryString.get('id');
    setLoadingGetByIdRateCard(true);
    try {
      const resGetByIdRateCard = await axios({
        url: 'https://m-api.getswing.app/graphql',
        method: 'post',
        headers: {
          'Authorization': `Bearer ${token}`,
          locationid: '02321053-9015-4554-aa01-8e0b559744a6'
        },
        data: {
          query: QueryGql.GET_BY_ID_RATE_CARD,
          variables: {
            getByIdRateCardId: id
          },
        }
      });
      setDataGetByIdRateCard(resGetByIdRateCard.data.data);
      setLoadingGetByIdRateCard(false);
    } catch (error) {
      setLoadingGetByIdRateCard(false);
    }
  };

  useEffect(() => {
    if (act === 'edit' || act === 'duplicate') {
      getByIdRateCard();
    }
  }, []);

  const handleSubmit = useCallback(() => {
    const info = refRateCardForm.current.handleSubmit();
    const timeBaseRates = refRateCardsFormTable.current.handleSubmit();
    const inclusions = refRateCardsFormInclusions.current.handleSubmit();
    if (info.name !== '') {
      if (act === 'add') {
        createRateCard({
          variables: {
            createRateCardRateCard: {
              ...info,
              data: timeBaseRates.map(item => ({
                course_id: item.courseId,
                membership_id: item.membershipId,
                time_based_rate_id: item.timeBaseRateId,
                price: Number(item.price)
              })),
              inclusions: inclusions || []
            },
          }
        });
      } else if (act === 'duplicate') {
        // const id = queryString.get('id');
        // duplicateRateCard({
        //   variables: {
        //     duplicateRateCardId: id
        //   }
        // });
        createRateCard({
          variables: {
            createRateCardRateCard: {
              ...info,
              data: timeBaseRates.map(item => ({
                course_id: item.courseId,
                membership_id: item.membershipId,
                time_based_rate_id: item.timeBaseRateId,
                price: Number(item.price)
              })),
              inclusions: inclusions || []
            },
          }
        });
      } else {
        const id = queryString.get('id');
        updateRateCard({
          variables: {
            updateRateCardRateCard: {
              id,
              ...info,
              data: timeBaseRates.map(item => ({
                course_id: item.courseId,
                membership_id: item.membershipId,
                time_based_rate_id: item.timeBaseRateId,
                price: Number(item.price)
              })),
              inclusions: inclusions || []
            },
          }
        });
      }
    }
  }, []);

  return (
    <Drawer
      title={act === 'add' ? 'Add new rate card' : act === 'duplicate' ? 'Duplicate rate card' : 'Edit rate card'}
      btnTitle={act === 'add' ? 'Save rate card' : act === 'duplicate' ? 'Duplicate rate card' : 'Save Changes'}
      onClickSubmit={handleSubmit}
    >
      <div id="rate-card-form">
        {loadingFormRateCard || loadingGetByIdRateCard
          ? <Loading />
          : (
            <>
              <RateCardsForm
                ref={refRateCardForm}
                initialValues={dataGetByIdRateCard?.getByIdRateCard.data
                  ? {
                    name: dataGetByIdRateCard.getByIdRateCard.data.name,
                    color: dataGetByIdRateCard.getByIdRateCard.data.color
                  }
                  : null}
              />
              <RateCardsFormTable
                ref={refRateCardsFormTable}
                initialValues={dataGetByIdRateCard?.getByIdRateCard.data.courses}
                className="rate-cards-form rate-cards-form-table"
                courses={dataFormRateCard.getAllCourses.data}
                memberships={dataFormRateCard.getAllMembership.data}
                timeBaseRates={dataFormRateCard.getAllTimeBaseRate.data}
              />
              <RateCardsFormInclusions
                ref={refRateCardsFormInclusions}
                inclusions={dataFormRateCard.getAllInclusions.data}
                initialValues={dataGetByIdRateCard?.getByIdRateCard.data.inclusions}
              />
            </>
          )}
        {/* {act === 'duplicate' && <div className="block-view" />} */}
      </div>
    </Drawer>
  );
};

export default RateCardsCreate;