/* eslint-disable max-len */
import React from 'react';

function SvgTeeTimes() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="none"
      viewBox="0 0 14 14"
    >
      <path
        fill="currentColor"
        d="M7 .333a6.667 6.667 0 100 13.334A6.667 6.667 0 007 .333zm0 12A5.333 5.333 0 117 1.668a5.333 5.333 0 010 10.665zM9.067 7.42l-1.4-.807V3.667a.667.667 0 00-1.334 0V7.08c.004.046.016.09.034.133.013.04.031.078.053.114.018.037.04.073.067.106l.106.087.06.06 1.734 1a.667.667 0 00.333.087.667.667 0 00.333-1.247h.014z"
      />
    </svg>
  );
}

export default SvgTeeTimes;
