import React, { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import IconButtonNavBar from 'components/atoms/IconButtonNavBar';
import Modal from 'components/organisms/Modal';
import CustomerFilter from 'components/templates/CustomerFilter';
import CustomerFilterBirthday from 'components/templates/CustomerFilterBirthday';
import CustomerFilterCreditCard from 'components/templates/CustomerFilterCreditCard';
import CustomerFilterGender from 'components/templates/CustomerFilterGender';
import CustomerFilterNationality from 'components/templates/CustomerFilterNationality';
import CustomerFilterType from 'components/templates/CustomerFilterType';
import KeyStorage from 'constants/KeyStorage';
import { AppContext } from 'App';

const useFilterBuilder = () => {
  const { actions } = useContext(AppContext);
  const existence = localStorage.getItem(KeyStorage.FILTER_CUSTOMER) || null;

  const applyFilter = () => {
    actions.createCustomerFilter(JSON.parse(existence));
  };

  const resetFilter = () => {
    localStorage.removeItem(KeyStorage.FILTER_CUSTOMER);

    actions.createCustomerFilter({
      customer_types: null,
      birthday: {
        from: null,
        to: null,
      },
      nationality: null,
      gender: null,
    });
  };

  const createFilter = (field, value) => {
    const data = { ...JSON.parse(existence), [field]: value };
    localStorage.setItem(KeyStorage.FILTER_CUSTOMER, JSON.stringify(data));
  };

  return { createFilter, resetFilter, applyFilter };
};

const CustomerListFilter = () => {
  const history = useHistory();
  const { applyFilter, resetFilter } = useFilterBuilder();

  return (
    <Modal
      title='Filter customers'
      leftFooterButton={{
        label: 'Reset all filters',
        state: 'hint',
        onClick: () => {
          resetFilter();
          history.goBack();
        },
      }}
      rightFooterButton={{
        label: 'Apply filters',
        onClick: () => {
          applyFilter();
          history.goBack();
        },
      }}
      width={540}>
      <CustomerFilter />
    </Modal>
  );
};

export default CustomerListFilter;

export const CustomerListFilterType = () => {
  const history = useHistory();
  const { createFilter } = useFilterBuilder();
  const [valueAll, setValueAll] = useState('all_customer');
  const [valueSpecific, setValueSpecific] = useState([]);

  const filter = () => {
    createFilter('customer_types', valueAll === 'all_customer' ? null : valueSpecific);
    history.goBack();
  };

  return (
    <Modal
      title='Select customer type'
      hiddenLeftFooterButton
      closeIcon={<IconButtonNavBar type='back' />}
      rightFooterButton={{
        label: 'Done selecting',
        onClick: filter,
      }}
      width={540}>
      <CustomerFilterType
        valueSpecific={valueSpecific}
        setValueSpecific={setValueSpecific}
        valueAll={valueAll}
        setValueAll={setValueAll}
      />
    </Modal>
  );
};

export const CustomerListFilterNationality = () => {
  const history = useHistory();
  const { createFilter } = useFilterBuilder();
  const [valueAll, setValueAll] = useState(true);
  const [valueSpecific, setValueSpecific] = useState(null);

  const filter = () => {
    createFilter('nationality', valueAll ? null : valueSpecific);
    history.goBack();
  };

  return (
    <Modal
      title='Select nationalities'
      hiddenLeftFooterButton
      closeIcon={<IconButtonNavBar type='back' />}
      rightFooterButton={{
        label: 'Done selecting',
        onClick: filter,
      }}
      width={540}>
      <CustomerFilterNationality
        valueAll={valueAll}
        setValueAll={setValueAll}
        valueSpecific={valueSpecific}
        setValueSpecific={setValueSpecific}
      />
    </Modal>
  );
};

export const CustomerListFilterBirthday = () => {
  const history = useHistory();
  const { createFilter } = useFilterBuilder();
  const [valueAll, setValueAll] = useState('all_birthday');
  const [from, setFrom] = useState(null);
  const [to, setTo] = useState(null);

  const filter = () => {
    createFilter(
      'birthday',
      valueAll === 'all_birthday'
        ? {
            from: null,
            to: null,
          }
        : {
            from,
            to,
          },
    );

    history.goBack();
  };

  return (
    <Modal
      title='Select birthday dates'
      hiddenLeftFooterButton
      closeIcon={<IconButtonNavBar type='back' />}
      rightFooterButton={{
        label: 'Done selecting',
        onClick: filter,
      }}
      width={540}>
      <CustomerFilterBirthday
        valueAll={valueAll}
        setValueAll={setValueAll}
        setFrom={setFrom}
        setTo={setTo}
      />
    </Modal>
  );
};

export const CustomerListFilterCreditCard = () => {
  const history = useHistory();
  return (
    <Modal
      title='Select credit card ownership'
      hiddenLeftFooterButton
      closeIcon={<IconButtonNavBar type='back' />}
      rightFooterButton={{
        label: 'Done selecting',
        onClick: () => history.goBack(),
      }}
      width={540}>
      <CustomerFilterCreditCard />
    </Modal>
  );
};

export const CustomerListFilterGender = () => {
  const history = useHistory();
  const { createFilter } = useFilterBuilder();
  const [valueAll, setValueAll] = useState(true);
  const [valueSpecific, setValueSpecific] = useState(null);

  const filter = () => {
    createFilter('gender', valueAll ? null : valueSpecific);
    history.goBack();
  };

  return (
    <Modal
      title='Select genders'
      hiddenLeftFooterButton
      closeIcon={<IconButtonNavBar type='back' />}
      rightFooterButton={{
        label: 'Done selecting',
        onClick: filter,
      }}
      width={540}>
      <CustomerFilterGender
        valueAll={valueAll}
        setValueAll={setValueAll}
        valueSpecific={valueSpecific}
        setValueSpecific={setValueSpecific}
      />
    </Modal>
  );
};
