import { Col, message, Row } from 'antd';
import React, { useRef, useState } from 'react';
import HorizontalDateRange from '../molecules/HorizontalDateRange';
import MultipleSelect from '../molecules/MultipleSelect';
import SingleSelect from '../molecules/SingleSelect';
import Modal from '../organisms/Modal';
import FileSelectExport from '../molecules/FileSelectExport';
import { useFormik } from 'formik';
import useGetCourses from '../../hooks/useGetCourses';
import ReservationReportTemplate from '../templates/ReservationReport';
import moment from 'moment';
import { useMutation, useQuery } from '@apollo/client';
import QueryGql from '../../constants/QueryGql';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import sleep from '../../utils/sleep';
import config from '../../constants/config';

const ExportReservationsReport = () => {
  const printRef = useRef();
  const { optionsCourses } = useGetCourses();
  const { refetch } = useQuery(QueryGql.GET_RESERVATIONS_REPORT, { skip: true });
  const [dataReport, setDataReport] = useState({});
  const [reportTypes] = useState([
    { key: 'all', value: 'all', label: 'by all report types' },
    { key: 'golfCourse', value: 'golf-course', label: 'by Gold course' },
    { key: 'paidCancel', value: 'paid-cancelled', label: 'by Paid vs cancelled' },
    { key: 'flightType', value: 'flight-type', label: 'by Flight type' },
    { key: 'playerNumber', value: 'player-numbers', label: 'by Player numbers' },
    { key: 'walkinUpcoming', value: 'walkin-upcoming', label: 'by Walk-in vs upcoming' },
    { key: 'status', value: 'status', label: 'by Status' },
    { key: 'standartGroup', value: 'standart-group', label: 'by Standart vs Group' },
  ]);

  const [exportMutation] = useMutation(QueryGql.EXPORT_RESERVATION_REPORT);

  const { isSubmitting, values, setFieldValue, submitForm } = useFormik({
    initialValues: {
      types: ['all'],
      file: { name: 'File', key: 'pdf', label: 'PDF' },
      course: { key: 'all', label: 'All golf courses', value: 'all' },
    },
    onSubmit: async (values, { setSubmitting }) => {
      try {
        const { date, course, file, types } = values;
        const variables = {
          start_date: moment(date[0]).format('YYYY-MM-DD'),
          end_date: moment(date[1]).format('YYYY-MM-DD'),
          course_id: course.value === 'all' ? null : course.value,
          reservation_by_courses: types.includes('all') || types.includes('golf course'),
          reservation_by_flight: types.includes('all') || types.includes('flight type'),
          reservation_by_player: types.includes('all') || types.includes('player numbers'),
          reservation_paid_cancelled: types.includes('all') || types.includes('paid cancelled'),
          reservation_by_datetime: true,
        };

        if (file.key === 'xlsx') {
          const res = await exportMutation({
            variables: {
              data: variables,
            },
          });
          const data = res.data.getReservationExport?.data;
          window.open(config.graphql.replace('graphql', '') + data.link_download, '_target');
        }

        if (file.key === 'pdf') {
          const res = await refetch({
            data: variables,
          });

          setDataReport(res.data.getReservationsReport.data);

          sleep(1000);

          const canvasCards = await html2canvas(document.getElementById('reservation-cards'));
          const imgCard = canvasCards.toDataURL('image/png');

          const canvasCharts = await html2canvas(
            document.getElementById('reservation-bar-chart'),
          );
          const imgCharts = canvasCharts.toDataURL('image/png');

          const doc = new jsPDF('p', 'mm', 'a4');
          const width = doc.internal.pageSize.getWidth();
          let finalY = 0;

          doc.addImage(imgCard, 'PNG', 5, 5, width - 10, 30);
          doc.addImage(imgCharts, 'PNG', 5, 40, width - 10, 70);

          finalY += 110;

          let start = 1;

          for (let index = 0; index < reportTypes.length; index++) {
            const element = reportTypes[index];
            if (element.value !== 'all') {
              if (types.includes('all') || types.includes(element.value)) {
                const canvas = await html2canvas(document.getElementById('print-' + element.value));
                const img = canvas.toDataURL('image/png');
                const widthElement = width / 2 - 8;
                let yElement = 5;

                if (start % 2 === 0) {
                  yElement = widthElement + 12;
                }

                if (index === 5) {
                  doc.addPage();
                  finalY = 0;
                }
                doc.addImage(img, 'PNG', yElement, finalY + 5, widthElement, 80);

                if (start % 2 === 0) {
                  finalY += 85;
                }

                start += 1;
              }
            }
          }

          if (start % 2 === 0) {
            finalY += 85;
          }

          const name = file.name;
          doc.save(`${name}.pdf`);
        }

        setSubmitting(false);
      } catch (error) {
        console.log(error);
        setSubmitting(false);
        message.error('Failed export');
      }
    },
  });

  let hide = {};
  if (!values.types.includes('all')) {
    reportTypes.forEach(item => {
      if (values.types.includes(item.value)) {
        hide[item.key] = false;
      } else {
        hide[item.key] = true;
      }
    });
  } else {
    hide = null;
  }

  return (
    <Modal
      title='Export Reservations Report'
      width={600}
      hiddenLeftFooterButton
      rightFooterButton={{
        label: 'Export Reservations Report',
        loading: isSubmitting,
        state: values.date ? 'default' : 'disabled',
        onClick: submitForm,
      }}>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <FileSelectExport
            value={values.file}
            onChange={item => setFieldValue('file', item)}
            items={[
              {
                key: 'pdf',
                label: 'PDF',
              },
              {
                key: 'xlsx',
                label: 'XLSX',
              },
            ]}
          />
        </Col>
        <Col span={24}>
          <SingleSelect
            label='Golf courses'
            items={optionsCourses}
            fullWidth
            type='text'
            size='large'
            value={values.course.value}
            valueLabel={values.course.label}
            onSelect={v => setFieldValue('course', v)}
          />
        </Col>
        <Col span={24}>
          <MultipleSelect
            label='Reservation report type'
            defaultValue='by all report types'
            items={reportTypes}
            values={values.types}
            value={values.types.includes('all') ? 'by all report types' : values.types.join(', ')}
            fullWidth
            type='text'
            size='large'
            handleChange={v => setFieldValue('types', v)}
          />
        </Col>
        <Col span={24}>
          <HorizontalDateRange label='Date' onChange={v => setFieldValue('date', v)} />
        </Col>
      </Row>
      <div style={{ position: 'fixed', bottom: -5000 }}>
        <div id='print' ref={printRef} style={{ margin: 20 }}>
          <ReservationReportTemplate
            isPrint={true}
            course={values.course}
            dataReport={dataReport}
            hide={hide}
            isDaily={values.date ? moment(values.date[0]).diff(values.date[1], 'day') === 0 : false}
          />
        </div>
      </div>
    </Modal>
  );
};

export default ExportReservationsReport;
