import Text from 'antd/lib/typography/Text';
import React from 'react';
import { Button, notification } from 'antd';
import { useHistory, useLocation } from 'react-router-dom';
import Pathname from '../../constants/Pathname';
import ModalCancelAction from '../templates/ModalCancelAction';
import { useMutation, gql } from '@apollo/client';
import useQuery from 'utils/useQuery';
import QueryGql from 'constants/QueryGql';

const DELETE_CUSTOMER = gql`
  mutation DeleteCustomer($deleteCustomerId: ID!) {
    deleteCustomer(id: $deleteCustomerId) {
      code
      message
    }
  }
`;

const CustomerListDelete = () => {
  const history = useHistory();
  const location = useLocation();
  const queryString = useQuery(location.search);

  const [deleteCustomer] = useMutation(DELETE_CUSTOMER, {
    onCompleted: () => {
      notification.success({
        message: 'Success',
        description: 'successfully delete customer data!',
        btn: (
          <Button
            type='text'
            className='notification-button-dismiss-success'
            onClick={() => notification.close('delete-customer')}>
            Dismiss
          </Button>
        ),
        key: 'delete-customer',
        closeIcon: <div />,
      });

      history?.goBack();
    },
    refetchQueries: [QueryGql.SEARCH_CUSTOMER],
    onError: () => {
      history?.goBack();
    },
  });

  return (
    <ModalCancelAction
      title='Delete customer?'
      leftFooterButton={{
        label: 'Cancel',
        type: 'hint',
      }}
      rightFooterButton={{
        label: 'Delete customer',
        onClick: () => {
          history.replace(Pathname.CUSTOMER_LIST);
          deleteCustomer({
            variables: {
              deleteCustomerId: queryString.get('id'),
            },
          });
        },
      }}>
      <Text>
        Are you sure you would like to delete <b>{location?.state?.name || 'User'}</b>? Any
        infromation, reservation history, feedbacks, and existing house accounts will be deleted as
        well and can not be recovered.
      </Text>
    </ModalCancelAction>
  );
};

export default CustomerListDelete;
