import Icon, { CheckOutlined, CloseCircleOutlined, MailOutlined, PhoneOutlined } from '@ant-design/icons';
import { Col, Input, Row, Select, Table, Checkbox, Menu, Dropdown, AutoComplete } from 'antd';
import Text from 'antd/lib/typography/Text';
import Title from 'antd/lib/typography/Title';
import React, { useMemo, useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import numeral from 'numeral';
import moment from 'moment';
import IconButton from '../atoms/IconButton';
import SvgAlternateSvg from '../atoms/SvgAlternateAdd';
import SvgDelete from '../atoms/SvgDelete';
import SvgFace from '../atoms/SvgFace';
import SvgMoreOutline from '../atoms/SvgMoreOutline';
import Avatar from '../atoms/Avatar';
import SvgTag from '../atoms/SvgTag';
import SvgVoucher from '../atoms/SvgVoucher';
import './TeeSheetReservationCustomerForm.less';
import { useLazyQuery, useQuery } from '@apollo/client';
import QueryGql from '../../constants/QueryGql';
import SvgGuest from 'components/atoms/SvgGuest';
import SvgRaincheck from 'components/atoms/SvgRaincheck';
import _ from 'lodash';

const { Option } = Select;
const { Option: OptionAutoComplete } = AutoComplete;

const TeeSheetReservationCustomerForm = props => {
  const { values, onChange, onDelete, errors, memberships, valueDateTeeTime, customersSelected } =
    props;
  const { data: dataFormCustomerReservation } = useQuery(QueryGql.FORM_CUSTOMER_RESERVATION);
  const [searchCustomer, { data: dataSearchCustomer }] = useLazyQuery(QueryGql.SEARCH_CUSTOMER);

  const [validateRaincheckCode] = useLazyQuery(QueryGql.GET_VOUCHER_CODE);

  let dataCustomer = dataSearchCustomer?.searchCustomer?.data || [];
  dataCustomer = dataCustomer.filter(x => !customersSelected.find(y => y.customer_id === x.id));
  const dataPublishRates = dataFormCustomerReservation?.getAllMembership?.data || [];
  const dataAddOns = dataFormCustomerReservation?.getAllAddOns?.data || [];

  const disabled = values?.status === 'checked_in' || values?.status === 'paid';
  const disabledEdit = values.act === 'edit';
  const [value, setValue] = useState(values);
  let [selectedCustomer, setSelectedCustomer] = useState(null);
  const [total, setTotal] = useState(0);
  const [voucherCode, setVoucherCode] = useState({});
  const [voucherCodeInput, setVoucherCodeInput] = useState('');

  useEffect(() => {
    setValue(values);
    if (values.voucher_code) {
      setVoucherCodeInput(values.voucher_code);
    }
  }, [values]);

  useEffect(() => {
    onChange(value);
  }, [value, total]);

  useEffect(() => {
    let price = 0;
    let priceAddons = 0;
    const membership = memberships.find(item => item.id === value?.membership_id);
    membership?.time_based_rates?.forEach(x => {
      if (
        moment(valueDateTeeTime, 'HH:mm:ss').isBetween(
          moment(x.start_time, 'HH:mm:ss'),
          moment(x.end_time, 'HH:mm:ss'),
        ) ||
        moment(valueDateTeeTime, 'HH:mm:ss').isSame(moment(x.start_time, 'HH:mm:ss')) ||
        moment(valueDateTeeTime, 'HH:mm:ss').isSame(moment(x.end_time, 'HH:mm:ss'))
      ) {
        price = x.price;
      }
    });
    dataAddOns?.forEach(item => {
      value?.add_ons_id?.forEach(x => {
        if (item.id === x) {
          priceAddons = priceAddons + item.price;
        }
      });
    });
    setTotal(price + priceAddons);
  }, [value?.membership_id, value.add_ons_id, memberships, valueDateTeeTime]);

  const columns = useMemo(
    () => [
      {
        dataIndex: '0',
        width: 236,
      },
      {
        dataIndex: '1',
        width: 236,
      },
      {
        dataIndex: '2',
        width: 236,
      },
      {
        dataIndex: '3',
        width: 160,
        className: 'cell-total',
        render: (value, row, index) => {
          const obj = {
            children: value,
            props: {},
          };
          if (index === 0) {
            obj.props.rowSpan = 2;
          }
          if (index === 1) {
            obj.props.rowSpan = 0;
          }
          return obj;
        },
      },
    ],
    [],
  );

  const handleCheckCode = _.debounce(e => {
    if (e.target.value === '') {
      setValue(v => ({
        ...v,
        voucher_code_error: false,
        voucher_code: e.target.value,
        voucher_code_data: null,
        discount: 0
      }));
      setVoucherCode({});
    } else {
      validateRaincheckCode({
        variables: {
          data: {
            code: e.target.value,
          },
        },
      }).then(res => {
        const message = res?.data?.getVoucherByCode?.message;
        const voucher = res?.data?.getVoucherByCode?.data;
        if (message === 'voucher not available') {
          setVoucherCode({
            error: true
          });
          setValue(v => ({
            ...v,
            voucher_code_error: true,
            voucher_code: e.target.value,
            voucher_code_data: null,
            discount: 0
          }));
        } else {
          setVoucherCode(voucher);
          setValue(v => ({
            ...v,
            voucher_code_error: false,
            voucher_code: e.target.value,
            voucher_code_data: voucher,
            discount: voucher?.amount
          }));
        }
      });
    }
  }, 0);

  const handleSearchCustomer = type => valueInput => {
    setValue({
      ...value,
      [type]: valueInput,
    });
    return searchCustomer({
      variables: {
        searchCustomerSearch: valueInput,
      },
    });
  };

  const handleSelectGuest = () => {
    handleSearchCustomer('name')('guest').then(res => {
      const data = res.data?.searchCustomer?.data?.[0];
      if (data) {
        selectedCustomer = data;
        setSelectedCustomer({
          ...selectedCustomer,
          name: selectedCustomer.full_name,
          membership_id: selectedCustomer.subscription_memberships?.[0].membership_id,
        });
        setValue({
          ...value,
          customer_id: selectedCustomer.id,
          name: selectedCustomer.full_name,
          email: selectedCustomer.email,
          phone_number: selectedCustomer.phone_number,
          membership_id: selectedCustomer.subscription_memberships?.[0].membership_id,
        });
      }
    });
  };

  const handleOnblurCustomer = (type, valueInput) => {
    if (value.customer_id !== '' && valueInput !== selectedCustomer?.[type]) {
      setValue({
        ...value,
        customer_id: '',
      });
    }
  };

  const handleSelectCustomer = (id, options) => {
    selectedCustomer = dataCustomer.filter(item => item.id === options.key)?.[0];
    if (selectedCustomer) {
      setSelectedCustomer({
        ...selectedCustomer,
        name: selectedCustomer.full_name,
        membership_id: selectedCustomer.subscription_memberships?.[0].membership_id,
      });
      setValue({
        ...value,
        customer_id: selectedCustomer.id,
        name: selectedCustomer.full_name,
        email: selectedCustomer.email,
        phone_number: selectedCustomer.phone_number,
        membership_id: selectedCustomer.subscription_memberships?.[0].membership_id,
      });
    }
  };

  const onClear = useCallback(() => {
    setTotal(0);
    setValue({
      customer_id: '',
      name: '',
      email: '',
      phone_number: '',
      membership_id: null,
      add_ons_id: [],
      voucher_code: '',
    });
  }, []);

  const isGuest = value.name === 'guest';
  const data = useMemo(() => {
    return [
      {
        0: (
          <div style={{ display: 'flex', alignItems: 'center', paddingLeft: 8 }}>
            <Icon component={SvgFace} className='icon-prefix-input' />
            <AutoComplete
              style={{ width: '100%' }}
              onSelect={handleSelectCustomer}
              onSearch={handleSearchCustomer('name')}
              placeholder='Customer name'
              bordered={false}
              size='small'
              value={value.name}
              searchValue={value.name}
              onBlur={e => handleOnblurCustomer('name', e.target.value)}
              dropdownClassName='drop-down-width-500'
              disabled={disabled || disabledEdit || isGuest}>
              {dataCustomer.map(item => (
                <OptionAutoComplete key={item.id} value={item.full_name}>
                  {item.full_name}
                  {item.full_name === 'guest' ? '' : ','} {item.email}
                  {item.full_name === 'guest' ? '' : ','} {item.phone_number}
                </OptionAutoComplete>
              ))}
            </AutoComplete>
            {errors?.name && (
              <Text className='error-message error-cell' type='danger'>
                {errors.name}
              </Text>
            )}
          </div>
        ),
        1: (
          <div style={{ display: 'flex', alignItems: 'center', paddingLeft: 8 }}>
            <MailOutlined className='icon-prefix-input' />
            <AutoComplete
              style={{ width: '100%' }}
              onSelect={handleSelectCustomer}
              onSearch={handleSearchCustomer('email')}
              onBlur={e => handleOnblurCustomer('email', e.target.value)}
              placeholder={isGuest ? 'Guest' : 'Email'}
              bordered={false}
              size='small'
              value={value.email}
              searchValue={value.email}
              dropdownClassName='drop-down-width-500'
              disabled={disabled || disabledEdit || isGuest}>
              {dataCustomer.map(item => (
                <OptionAutoComplete key={item.id} value={item.email}>
                  {item.full_name}
                  {item.full_name === 'guest' ? '' : ','} {item.email}
                  {item.full_name === 'guest' ? '' : ','} {item.phone_number}
                </OptionAutoComplete>
              ))}
            </AutoComplete>
            {errors?.email && (
              <Text className='error-message error-cell' type='danger'>
                {errors.email}
              </Text>
            )}
          </div>
        ),
        2: (
          <div style={{ display: 'flex', alignItems: 'center', paddingLeft: 8 }}>
            <PhoneOutlined className='icon-prefix-input' />
            <AutoComplete
              style={{ width: '100%' }}
              onSelect={handleSelectCustomer}
              onSearch={handleSearchCustomer('phone_number')}
              onBlur={e => handleOnblurCustomer('phone_number', e.target.value)}
              placeholder={isGuest ? 'Guest' : 'Phone number'}
              bordered={false}
              size='small'
              value={value.phone_number}
              searchValue={value.phone_number}
              dropdownClassName='drop-down-width-500'
              disabled={disabled || disabledEdit || isGuest}>
              {dataCustomer.map(item => (
                <OptionAutoComplete key={item.id} value={item.phone_number}>
                  {item.full_name}
                  {item.full_name === 'guest' ? '' : ','} {item.email}
                  {item.full_name === 'guest' ? '' : ','} {item.phone_number}
                </OptionAutoComplete>
              ))}
            </AutoComplete>
            {errors?.phone_number && (
              <Text className='error-message error-cell' type='danger'>
                {errors.phone_number}
              </Text>
            )}
          </div>
        ),
        3: <Title level={5}>{numeral(total - ( values?.discount ?  values?.discount : 0 )).format('$0,0[.]00')}</Title>,
      },
      {
        0: (
          <>
            <Row wrap={false}>
              <Icon component={SvgTag} className='icon-select-option' />
              <Select
                size='small'
                className='select-option'
                bordered={false}
                placeholder='Select customer type'
                value={value.membership_id}
                disabled={disabled || disabledEdit}
                onSelect={e => {
                  if (
                    value.customer_id !== '' &&
                    e !== selectedCustomer.subscription_memberships?.[0].membership_id
                  ) {
                    setValue({
                      ...value,
                      customer_id: '',
                      membership_id: e,
                    });
                  } else {
                    if (
                      value.customer_id === '' &&
                      e === selectedCustomer?.subscription_memberships?.[0].membership_id &&
                      value.name === selectedCustomer?.full_name &&
                      value.email === selectedCustomer?.email &&
                      value.phone_number === selectedCustomer?.phone_number
                    ) {
                      setValue({
                        ...value,
                        customer_id: selectedCustomer.id,
                        membership_id: e,
                      });
                    } else {
                      setValue({
                        ...value,
                        membership_id: e,
                      });
                    }
                  }
                }}>
                {dataPublishRates.map(item => (
                  <Option key={item.id} value={item.id}>
                    {item.name}
                  </Option>
                ))}
              </Select>
            </Row>
            {errors?.membership_id && (
              <Text style={{ marginLeft: 8 }} className='error-message error-cell' type='danger'>
                {errors.membership_id}
              </Text>
            )}
          </>
        ),
        1: (
          <Row wrap={false}>
            <Icon component={SvgAlternateSvg} className='icon-select-option' />
            <Select
              bordered={false}
              placeholder='Select add-ons'
              className='select-option'
              size='small'
              dropdownClassName='dropdown-multiple-select-option'
              value={`${value.add_ons_id?.length > 0 ? value.add_ons_id.length : ''} add-ons`}
              disabled={disabled}
              dropdownRender={() => (
                <Checkbox.Group
                  value={value.add_ons_id}
                  onChange={e => {
                    setValue({
                      ...value,
                      add_ons_id: e,
                    });
                  }}
                  className='checkbox-multiple-select-option'>
                  {dataAddOns.map(item => (
                    <Col key={item.id} span={24}>
                      <Checkbox value={item.id} className='item-multiple-select-option'>
                        <Row justify='space-between' className='row-multiple-select-option'>
                          <Col>
                            <Text>{item.name}</Text>
                          </Col>
                          <Col>
                            <Text type='secondary'>
                              + {numeral(item.price).format('$0,0[.]00')}
                            </Text>
                          </Col>
                        </Row>
                      </Checkbox>
                    </Col>
                  ))}
                </Checkbox.Group>
              )}
            />
          </Row>
        ),
        2: (
          <div style={{ display: 'flex' }}>
            <Input
              placeholder='Voucher code'
              prefix={
                value?.voucher_code_data?.is_raincheck && value?.voucher_code ? (
                  <Icon component={SvgRaincheck} className='icon-prefix-input' />
                ) : (
                  <Icon component={SvgVoucher} className='icon-prefix-input' />
                )
              }
              suffix={
                voucherCode?.code && value.voucher_code ? (
                  <CheckOutlined  style={{ color: 'green' }} />
                ) : null
              }
              bordered={false}
              size='small'
              disabled={disabled || disabledEdit}
              onChange={ (e) => {
                setVoucherCodeInput(e.target.value);
                handleCheckCode(e);
              }}
              value={voucherCodeInput}
            />
            {voucherCode?.error && (
              <Text className='error-message error-cell' type='danger'>
                Voucher not available
              </Text>
            )}
          </div>
        ),
        3: '',
      },
    ];
  }, [dataCustomer, value, total]);

  const contextMenu = useMemo(
    () => (
      <Menu>
        <Menu.Item key='0' className='context-menu-clear' onClick={onClear}>
          <CloseCircleOutlined /> Clear information
        </Menu.Item>
        <Menu.Item key='1' className='context-menu-clear' onClick={handleSelectGuest}>
          <SvgGuest style={{ height: 14, width: 14, marginBottom: -2 }} /> Add a guest
        </Menu.Item>
        <Menu.Item key='2' className='second-menu-edit' onClick={onDelete}>
          <Icon component={SvgDelete} /> Remove customer
        </Menu.Item>
      </Menu>
    ),
    [],
  );

  return (
    <div id='tee-sheet-reservation-customer-form'>
      <Table
        columns={columns}
        dataSource={data}
        bordered
        showHeader={false}
        title={() => (
          <Row className='title-container' justify='space-between' align='middle'>
            <Col xs={12}>
              {value.customer_id !== '' ? (
                value.name !== '' && !isGuest ? (
                  <div className='title-user'>
                    <Avatar title={value.name} />
                    <Text className='title-user-name' style={{ marginLeft: 11 }}>
                      {value.name}
                    </Text>
                  </div>
                ) : (
                  <div className='title-user'>
                    <Icon component={SvgFace} className='avatar' />
                    <Text className='title-user-name'>Guest</Text>
                  </div>
                )
              ) : value.name === '' ? (
                <div className='title-user'>
                  <Icon component={SvgFace} className='avatar' />
                  <Text className='title-user-name'>Enter details</Text>
                </div>
              ) : (
                <div className='title-user'>
                  <Icon component={SvgFace} className='avatar' />
                  <Text className='title-user-name'>Guest</Text>
                </div>
              )}
            </Col>
            <Col>
              {disabled ? null : (
                <Dropdown trigger={['hover', 'click']} overlay={contextMenu}>
                  <div>
                    <IconButton iconSvg={SvgMoreOutline} />
                  </div>
                </Dropdown>
              )}
            </Col>
          </Row>
        )}
        pagination={false}
      />
    </div>
  );
};

export default TeeSheetReservationCustomerForm;

TeeSheetReservationCustomerForm.propTypes = {
  values: PropTypes.shape({
    customer_id: PropTypes.string,
    name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    phone_number: PropTypes.string.isRequired,
    membership_id: PropTypes.string.isRequired,
    add_ons_id: PropTypes.arrayOf(PropTypes.string),
    voucher_code: PropTypes.string,
    status: PropTypes.string,
  }).isRequired,
  memberships: PropTypes.array,
  valueDateTeeTime: PropTypes.string,
  errors: PropTypes.object,
  onChange: PropTypes.func,
};

TeeSheetReservationCustomerForm.defaultProps = {};
