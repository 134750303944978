import React from 'react';
import { Drawer as AntdDrawer } from 'antd';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import Button from '../atoms/Button';
import './Drawer.less';

const Drawer = props => {
  const { onCancel, children, title, btnTitle, onClickSubmit } = props;
  const history = useHistory();
  return (
    <AntdDrawer
      {...props}
      title={title}
      className="antd-drawer-class"
      placement="bottom"
      height="100%"
      getContainer={false}
      onClose={onCancel || history?.goBack}
      visible={true}>
      <Button className="button-action-drawer" onClick={onClickSubmit} type="primary" key="extra-right">
        {btnTitle}
      </Button>
      {children}
    </AntdDrawer>
  );
};

Drawer.propTypes = {
  children: PropTypes.node.isRequired,
  onCancel: PropTypes.func,
  onClickSubmit: PropTypes.func,
  title: PropTypes.string,
  btnTitle: PropTypes.string
};

Drawer.defaultProps = {
  onCancel: null,
};

export default Drawer;
