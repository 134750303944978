import Icon, { LockOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Col, Dropdown, Menu, Row, Tooltip, Typography } from 'antd';
import React, { useMemo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import SvgDelete from '../atoms/SvgDelete';
import SvgKebabMenu from '../atoms/SvgKebabMenu';
import SvgNote from '../atoms/SvgNote';
import SvgTeeSheet from '../atoms/SvgTeeSheet';
import './TeeSheetAddButton.less';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import Pathname from '../../constants/Pathname';

const TeeSheetAddButton = (props) => {
  const { label, type, holeLabel, onSelect, dateTime, courseId, initialStartingHoles, rateCardId, onTeeTimeDelete, isBlocked, note, statuses } = props;
  const [isChecked, setIsChecked] = useState(false);
  const history = useHistory();
  const contenxMenu = useMemo(() => (
    <Menu>
      <Menu.Item
        key="0"
        onClick={onSelect}
      >
        <PlusOutlined /> Add new reservation
      </Menu.Item>
      <Menu.Item
        key="1"
        onClick={() => history.push(
          Pathname.TEE_SHEET+'/'+
          Pathname.TEE_SHEET_HOLES_FORM+
          `?date-time=${dateTime}&course-id=${courseId}&statuses=${JSON.stringify(statuses)}`,
          {
            background: true,
            initialStartingHoles,
          }
        )}
      >
        <Icon component={SvgTeeSheet} /> Add new holes
      </Menu.Item>
      <Menu.Item
        key="2" 
        className="second-menu-edit"
        onClick={onTeeTimeDelete}
      >
        <Icon component={SvgDelete} /> Delete tee time
      </Menu.Item>
    </Menu>
  ),[onTeeTimeDelete]);

  useEffect(() => {
    if (type !== 'select') {
      setIsChecked(false);
    }
  }, [type]);

  const handleChecked = () => {
    setIsChecked(!isChecked);
    onSelect({
      dateTime,
      selected: !isChecked,
      rateCardId
    });
  };

  const handleSelect = () => {
    if (type === 'select') {
      handleChecked();
    } 
    if (type === 'add') {
      onSelect();
    }
  };

  return (
    <div id="button-add-tee-time">
      <Typography className="time">{label}</Typography>
      <Button onClick={handleSelect} className={type === 'block' ? 'button-plus-blocked' : 'button-plus'} disabled={type === 'block'}>
        {type === 'select' && <Checkbox checked={isChecked}/>}
        {/* {type === 'add' && <PlusOutlined className="icon-plus" />} */}
        {type === 'block' && <Typography className="time" style={{color: '#C2C2C2', fontWeight: 'normal'}}>Blocked</Typography>}
        {holeLabel ? (
          <Row>
            <Col xs={4}>
              <Typography className="time" style={{ textAlign: 'left', marginLeft: 10, color: '#5C5C5C', fontWeight: 'normal', marginTop: 2}}>{holeLabel}</Typography>
            </Col>
            <Col xs={20}>
              <PlusOutlined className="icon-plus" />
            </Col>
          </Row>
        ) : type === 'add' ? <PlusOutlined className="icon-plus" /> : null}
      </Button>
      {type === 'block' || isBlocked
        ? (
          <Tooltip
            placement="bottomRight"
            title={note}
          >
            <div>
              <Button className="button-blocked" type="text" disabled icon={<LockOutlined className="button-icon" />} />
            </div>
          </Tooltip>
        )
        : (
          <>
            <Button type="text" icon={<Icon component={SvgNote} className="button-icon" />} />
            <Dropdown trigger={['hover', 'click']} overlay={contenxMenu}>
              <Button type="text" icon={<Icon component={SvgKebabMenu} className="button-icon"  />} />
            </Dropdown>
          </>
        )}
    </div>
  );
};

export default TeeSheetAddButton;


TeeSheetAddButton.propTypes = {
  label: PropTypes.string.isRequired,
  holeLabel: PropTypes.string,
  courseId: PropTypes.string,
  rateCardId: PropTypes.string,
  type: PropTypes.oneOf(['select', 'add', 'block']),
  reservationId: PropTypes.string,
  dateTime: PropTypes.string,
  onTeeTimeDelete: PropTypes.func,
  isBlocked: PropTypes.bool,
  note: PropTypes.string,
  statuses: PropTypes.arrayOf(PropTypes.string),
  initialStartingHoles: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.string
  })),
};

TeeSheetAddButton.defaultProps = {
  type: 'add'
};