import React from 'react';

function SvgVoucher() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        fill="currentColor"
        d="M6 6.667a.667.667 0 00-.667.667v1.333a.667.667 0 101.334 0V7.334A.667.667 0 006 6.667zm8 .667a.666.666 0 00.667-.667V4A.666.666 0 0014 3.333H2A.667.667 0 001.333 4v2.667A.667.667 0 002 7.334a.667.667 0 110 1.333.667.667 0 00-.667.667V12a.667.667 0 00.667.667h12a.667.667 0 00.667-.667V9.334A.666.666 0 0014 8.667a.667.667 0 010-1.333zm-.667-1.214a2 2 0 000 3.76v1.454H6.667a.667.667 0 10-1.334 0H2.667V9.88a2 2 0 000-3.76V4.667h2.666a.667.667 0 101.334 0h6.666V6.12z"
      ></path>
    </svg>
  );
}

export default SvgVoucher;
