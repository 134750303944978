import Icon, { LoginOutlined } from '@ant-design/icons';
import { Button, Col, Dropdown, List, Menu, Row, Tooltip, Typography } from 'antd';
import Text from 'antd/lib/typography/Text';
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import SvgDelete from '../atoms/SvgDelete';
import SvgKebabMenu from '../atoms/SvgKebabMenu';
import SvgNote from '../atoms/SvgNote';
import SvgTeeSheet from '../atoms/SvgTeeSheet';
import './TeeSheetFilledTime.less';
import Pathname from '../../constants/Pathname';
import Card from '../atoms/Card';
import Avatar from '../atoms/Avatar';
import SvgInfo from '../atoms/SvgInfo';
import SvgEdit from '../atoms/SvgEdit';
import SvgRaincheck from '../atoms/SvgRaincheck';
import TeeSheetAddButton from './TeeSheetAddButton';
import ButtonStatusReservation from '../atoms/ButtonStatusReservation';

const TeeSheetFilledTime = (props) => {
  const { 
    label,
    customers,
    type,
    holes,
    reservationId,
    optionsTeeTime,
    note,
    dateTime,
    courseId,
    onCancelReservation,
    onCancelReservationGroup,
    rateCardId,
    initialStartingHoles,
    groupName,
    groupId,
    isGroupHeader,
    groupIsNotAnyPaid,
    groupIsHideCancelReservation,
    groupHover,
    setGroupHover,
    statuses,
  } = props;

  const history = useHistory();

  const isHideCancelReservation = useMemo(() => customers?.filter(item => item.status === 'checked_in' || item.status === 'paid')?.length > 0, [customers]);
  const isNotAnyPaid = useMemo(() => customers?.filter(item => item.status === 'paid')?.length === 0, [customers]);

  const contenxMenu = (reservationFromHoleId) => (
    <Menu>
      <Menu.Item
        key="0"
        onClick={() => history.push(
          Pathname.TEE_SHEET+'/'+
          Pathname.TEE_SHEET_CUSTOMERS_CHECKIN_DETAIL+
          `?id=${reservationFromHoleId || reservationId}`,
          {
            background: true
          }
        )}
      >
        <LoginOutlined /> Check-in customers
      </Menu.Item>
      <Menu.Item
        key="1"
        onClick={() => history.push(
          Pathname.TEE_SHEET+'/'+
          Pathname.TEE_SHEET_RESERVATION_DETAIL+
          `?id=${reservationFromHoleId || reservationId}`,
          {
            background: true,
            optionsTeeTime
          }
        )}
      >
        <Icon component={SvgInfo} /> Reservation details
      </Menu.Item>
      <Menu.Item
        key="2"
        onClick={() => history.push(
          Pathname.TEE_SHEET+'/'+
          Pathname.TEE_SHEET_RESERVATION_FORM+
          `?act=edit&id=${reservationFromHoleId || reservationId}`,
          {
            background: true,
            optionsTeeTime
          }
        )}
      >
        <Icon component={SvgEdit} /> Edit reservation
      </Menu.Item>
      <Menu.Item
        key="3"
        onClick={() => history.push(
          Pathname.TEE_SHEET+'/'+
          Pathname.TEE_SHEET_HOLES_FORM+
          `?reservation-id=${reservationFromHoleId || reservationId}&date-time=${dateTime}&course-id=${courseId}&statuses=${JSON.stringify(statuses)}`,
          {
            background: true,
            initialStartingHoles
          }
        )}
      >
        <Icon component={SvgTeeSheet} /> Add new holes
      </Menu.Item>
      {isHideCancelReservation
        ? (
          <Menu.Item
            key="3"
            onClick={() => isNotAnyPaid
              ? history.push(
                Pathname.TEE_SHEET+'/'+
                Pathname.TEE_SHEET_RAINCHECK+
                `?id=${reservationFromHoleId || reservationId}`,
                {
                  background: true
                }
              )
              : history.push(
                Pathname.TEE_SHEET+'/'+
                Pathname.TEE_SHEET_RAINCHECK_ISSUE+
                `?id=${reservationFromHoleId || reservationId}`,
                {
                  background: true
                }
              )
            }
          >
            <Icon component={SvgRaincheck} /> Issue raincheck
          </Menu.Item>
        ) 
        : (
          <Menu.Item
            key="2" 
            className="second-menu-edit"
            onClick={() => onCancelReservation(reservationFromHoleId || reservationId)}
          >
            <Icon component={SvgDelete} /> Cancel reservation
          </Menu.Item>
        )}
    </Menu>
  );

  const contenxMenuGroup = useMemo(() => (
    <Menu>
      <Menu.Item
        key="0"
        onClick={() => history.push(
          Pathname.TEE_SHEET+'/'+
          Pathname.TEE_SHEET_CUSTOMERS_CHECKIN_GROUP_DETAIL+
          `?id=${reservationId}`,
          {
            background: true
          }
        )}
      >
        <LoginOutlined /> Check-in customers
      </Menu.Item>
      <Menu.Item
        key="1"
        onClick={() => history.push(
          Pathname.TEE_SHEET+'/'+
          Pathname.TEE_SHEET_RESERVATION_GROUP_DETAIL+
          `?id=${reservationId}`,
          {
            background: true,
            optionsTeeTime
          }
        )}
      >
        <Icon component={SvgInfo} /> Group Reservation details
      </Menu.Item>
      <Menu.Item
        key="2"
        onClick={() => history.push(
          Pathname.TEE_SHEET+'/'+
          Pathname.TEE_SHEET_RESERVATION_GROUP_FORM+
          `?act=edit&id=${reservationId}`,
          {
            background: true,
            optionsTeeTime
          }
        )}
      >
        <Icon component={SvgEdit} /> Edit group reservation
      </Menu.Item>
      <Menu.Item
        key="3"
        // onClick={() => history.push(
        //   Pathname.TEE_SHEET+'/'+
        //   Pathname.TEE_SHEET_HOLES_GROUP_FORM+
        //   `?reservation-group-id=${reservationId}&date-time=${dateTime}&course-id=${courseId}`,
        //   {
        //     background: true,
        //   }
        // )}
      >
        <Icon component={SvgTeeSheet} /> Add new holes
      </Menu.Item>
      {groupIsHideCancelReservation
        ? (
          <Menu.Item
            key="3"
            onClick={() => groupIsNotAnyPaid
              ? history.push(
                Pathname.TEE_SHEET+'/'+
                Pathname.TEE_SHEET_RAINCHECK+
                `?id=${reservationId}`,
                {
                  background: true
                }
              )
              : history.push(
                Pathname.TEE_SHEET+'/'+
                Pathname.TEE_SHEET_RAINCHECK_GROUP_ISSUE+
                `?id=${reservationId}`,
                {
                  background: true
                }
              )
            }
          >
            <Icon component={SvgRaincheck} /> Issue raincheck
          </Menu.Item>
        ) 
        : (
          <Menu.Item
            key="2" 
            className="second-menu-edit"
            onClick={() => onCancelReservationGroup(reservationId)}
          >
            <Icon component={SvgDelete} /> Cancel group reservation
          </Menu.Item>
        )}
    </Menu>
  ),[isHideCancelReservation, isNotAnyPaid]);

  return (
    <div
      id="filled-tee-time"
      onMouseEnter={() => setGroupHover(groupId)}
      onMouseLeave={() => setGroupHover(null)}
    >
      <Typography className="time">{label}</Typography>
      {holes?.length > 0 
        && (
          <div style={{ display: 'flex', flexDirection: 'column', flex: 1}} >
            {holes.map((hole, index) => (
              <div key={index} style={{ display: 'flex', flexDirection: 'row', flex: 1, marginBottom: 2}}>
                {hole?.customers?.length > 0
                  ? (
                    <>
                      <Card className="button-filled" onClick={(e) => {
                        e.preventDefault();
                        history.push(
                          Pathname.TEE_SHEET+'/'+
                          Pathname.TEE_SHEET_CUSTOMERS_CHECKIN_DETAIL+
                          `?id=${hole.reservationId}`,
                          {
                            background: true
                          }
                        );
                      }}>
                        <Row align="middle">
                          <Col xs={4}>
                            <Typography className="time">{hole?.label}</Typography>
                          </Col>
                          <Col xs={20}>
                            {hole?.customers?.map((customer, index) => (
                              <Card key={index} className="item-customer">
                                <Row justify="space-between" align="middle">
                                  <Col xs={16}>
                                    <List.Item onClick={(e) => {
                                      e.stopPropagation();
                                      history.push(
                                        Pathname.TEE_SHEET+'/'+
                                        Pathname.TEE_SHEET_CUSTOMER_CHECKIN_DETAIL+
                                        '?id='+hole.reservationId+'&customer-id='+customer.customerId,
                                        {
                                          background: true
                                        }
                                      );
                                    }}>
                                      <List.Item.Meta
                                        avatar={<Avatar title={customer.name} style={{marginTop: 2}} />}
                                        title={customer.name}
                                        description={customer.type}
                                      />
                                    </List.Item>
                                  </Col>
                                  <Col xs={8}>
                                    <ButtonStatusReservation status={customer.status} />
                                  </Col>
                                </Row>
                              </Card>
                            ))}
                          </Col>
                        </Row>
                      </Card>
                      <Tooltip
                        placement="bottomRight"
                        title={type === 'single'
                          ? hole.note
                          : 'Astra Group Golf Session\nGroup reservation for Astra International. Please provide the best cart and caddie for them.'}
                      >
                        <Button type="text" className="button-icon-note" icon={<Icon component={SvgNote} />} />
                      </Tooltip>
                      <Dropdown trigger={['hover', 'click']} overlay={type === 'single' ? contenxMenu(hole.reservationId) : contenxMenuGroup}>
                        <Button type="text" icon={<Icon component={SvgKebabMenu} className="button-icon"  />} />
                      </Dropdown>
                    </>
                  )
                  : (
                    <TeeSheetAddButton
                      label=""
                      dateTime={dateTime}
                      courseId={courseId}
                      holeLabel={hole.label}
                      initialStartingHoles={initialStartingHoles}
                      statuses={statuses}
                      onSelect={() => history.push(
                        Pathname.TEE_SHEET + '/' +
                        Pathname.TEE_SHEET_RESERVATION_FORM +
                        `?act=add&date=${moment(dateTime, 'YYYY-MM-DDTHH:mm:ss.SSS[Z]').format('YYYY-MM-DD')}&rate-card-id=${rateCardId}`,
                        {
                          background: true,
                          optionsTeeTime,
                          courseId: hole.courseId,
                          time: moment(label, 'hh:mm A').format('HH:mm:ss'),
                          startingHoleName: hole.label,
                          isSelectionHoles: true,
                        }
                      )}
                    />
                  )}
              </div>
            ))}
          </div>
        )}
      {customers?.length > 0 
          && (
            <>
              <Card
                className="button-filled"
                style={{
                  borderColor: groupHover === groupId ? '#859DFF' : '#F5F5F5',
                  backgroundColor: groupHover === groupId ? '#EBEEFF' : 'transparent',
                }}
                onClick={(e) => {
                  e.preventDefault();
                  if (type === 'group') {
                    history.push(
                      Pathname.TEE_SHEET+'/'+
                    Pathname.TEE_SHEET_CUSTOMERS_CHECKIN_GROUP_DETAIL+
                    `?id=${reservationId}`,
                      {
                        background: true
                      }
                    );
                  } else {
                    history.push(
                      Pathname.TEE_SHEET+'/'+
                    Pathname.TEE_SHEET_CUSTOMERS_CHECKIN_DETAIL+
                    `?id=${reservationId}`,
                      {
                        background: true
                      }
                    );
                  }
                }}>
                {customers.map((customer, index) => (
                  <Card 
                    key={index} 
                    className="item-customer"
                    onClick={(e) => {
                      e.stopPropagation();
                      history.push(
                        Pathname.TEE_SHEET+'/'+
                        Pathname.TEE_SHEET_CUSTOMER_CHECKIN_DETAIL+
                        `?${type === 'single' ? 'id' : 'group-id'}=${reservationId}&customer-id=${customer.customerId}${type === 'group' ? `&time=${moment(label, 'hh:mm A').format('HH:mm:ss')}`: ''}`,
                        {
                          background: true
                        }
                      );
                    }}
                  >
                    <Row justify="space-between" align="middle">
                      <Col xs={16}>
                        <List.Item>
                          <List.Item.Meta
                            avatar={<Avatar title={customer.name} style={{marginTop: 2}} />}
                            title={customer.name}
                            description={customer.type}
                          />
                        </List.Item>
                      </Col>
                      <Col>
                        <Row align="middle">
                          {customer.bagId && <Text className="bag-id">{customer.bagId}</Text>}
                          <ButtonStatusReservation status={customer.status} />
                          {customer.isRaincheck && (
                            <Tooltip color="#086336" overlayClassName="tooltip-raincheck" placement="bottom" title={`${customer?.raincheck?.code}\nIssued by ${customer?.raincheck?.issued_by}`}>
                              <Button type="text" className="raincheck-icon" icon={<Icon component={SvgRaincheck} />} />
                            </Tooltip>
                          )}
                        </Row>
                      </Col>
                    </Row>
                  </Card>
                ))}
              </Card>
              <Tooltip
                placement="bottomRight"
                title={type === 'single'
                  ? note
                  : `${groupName}\n${note || ''}`}
              >
                <Button
                  type="text"
                  className={ note ? 'button-icon-note' : 'button-icon' }
                  style={isGroupHeader === false && type === 'group' ? {visibility: 'hidden'} : null}
                  icon={<Icon component={SvgNote} />}
                />
              </Tooltip>
              <Dropdown trigger={['hover', 'click']} overlay={type === 'single' ? contenxMenu() : contenxMenuGroup}>
                <Button
                  type="text"
                  style={isGroupHeader === false && type === 'group' ? {visibility: 'hidden'} : null}
                  icon={<Icon component={SvgKebabMenu} className="button-icon" />}
                />
              </Dropdown>
            </>
          )}
    </div>
  );
};

export default TeeSheetFilledTime;


TeeSheetFilledTime.propTypes = {
  reservationId: PropTypes.string,
  note: PropTypes.string,
  label: PropTypes.string.isRequired,
  dateTime: PropTypes.string,
  courseId: PropTypes.string,
  statuses: PropTypes.arrayOf(PropTypes.string),
  holes: PropTypes.arrayOf(PropTypes.shape({
    isGroup: PropTypes.bool,
    label: PropTypes.string,
    note: PropTypes.string,
    reservationId: PropTypes.string,
    courseId: PropTypes.string,
    customers: PropTypes.arrayOf(PropTypes.shape({
      customerId: PropTypes.string,
      name: PropTypes.string,
      type: PropTypes.string,
      status: PropTypes.string,
      isRaincheck: PropTypes.bool,
      bagId: PropTypes.string,
      raincheck: PropTypes.shape({
        code: PropTypes.string,
        issued_by: PropTypes.string,
        status: PropTypes.string
      })
    }))
  })),
  customers: PropTypes.arrayOf(PropTypes.shape({
    customerId: PropTypes.string,
    name: PropTypes.string,
    type: PropTypes.string,
    status: PropTypes.string,
    isRaincheck: PropTypes.bool,
    bagId: PropTypes.string,
    raincheck: PropTypes.shape({
      code: PropTypes.string,
      issued_by: PropTypes.string,
      status: PropTypes.string
    })
  })),
  rateCardId: PropTypes.string,
  type: PropTypes.oneOf(['single', 'group']),
  groupName: PropTypes.string,
  isGroupHeader: PropTypes.bool,
  groupIsNotAnyPaid: PropTypes.bool,
  groupIsHideCancelReservation: PropTypes.bool,
  groupId: PropTypes.string,
  optionsTeeTime: PropTypes.array,
  onCancelReservation: PropTypes.func,
  onCancelReservationGroup: PropTypes.func,
  initialStartingHoles: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.string
  })),
  groupHover: PropTypes.string,
  setGroupHover: PropTypes.func,
};

TeeSheetFilledTime.defaultProps = {
  type: 'single',
  customers: [],
  holes: [],
  isGroup: false
};
