import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Text from 'antd/lib/typography/Text';
import TableContent from '../templates/TableContent';
import Avatar from '../atoms/Avatar';
import { useHistory } from 'react-router-dom';
import Pathname from '../../constants/Pathname';
import Lozenges from '../atoms/Lozenges';
import { Row } from 'antd';

export const columnsCustomerCheckIns = [
  {
    title: 'Customer name',
    width: 180,
    dataIndex: 'name',
    render: text => (
      <Row align="middle" wrap={false}>
        <Avatar title={text} style={{ marginRight: 8 }} />
        <Text style={{ fontSize: 12 }} type="primary">{text}</Text>
      </Row>
    ),
  },
  {
    title: 'Payment status',
    dataIndex: 'status',
    align: 'center',
    render: text => <Row justify="center"><Lozenges status={text} /></Row>,
  },
  {
    title: 'Check-in time',
    width: 180,
    dataIndex: 'time',
    render: text => <Text style={{ fontSize: 12 }} type="primary" >{text}</Text>,
  },
  {
    title: 'Reservation ID',
    dataIndex: 'reservationId',
    render: text => <Text style={{ fontSize: 12, color: '#1F4BFF' }} type="primary" strong>{text}</Text>,
  },
  {
    title: 'Checked-in by',
    dataIndex: 'checkInBy',
    render: text => <Text style={{ fontSize: 12 }} type="primary" >{text}</Text>,
  },
  {
    title: 'Total fees',
    dataIndex: 'total',
    render: text => <Text style={{ fontSize: 12 }} type="primary" >{text}</Text>,
  },
];

const CustomerCheckInsList = ({ data, loading }) => {
  const history = useHistory();

  const columns = useMemo(() => {
    return columnsCustomerCheckIns;
  }, []);

  return(
    <TableContent
      header={false}
      loading={loading}
      data={data}
      columns={columns}
      onRow={(record) => {
        return {
          onClick: () => {
            history.push(`${Pathname.CUSTOMER_CHECK_INS}/${Pathname.RESERVATION_DETAIL}?id=${record.id}&customerId=${record.customer.id}`, { background: true, data: record });
          },
        };
      }}
    />
  );
};

CustomerCheckInsList.propsTypes = {
  data: PropTypes.array,
  loading: PropTypes.bool,
};

export default CustomerCheckInsList;