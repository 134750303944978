import { Col, Row, Space } from 'antd';
import React, { useMemo } from 'react';
import moment from 'moment';

import Text from 'antd/lib/typography/Text';
import TotalCard from '../molecules/TotalCard';
import Card from '../atoms/Card';
import Button from '../atoms/Button';

import CustomerDetailReservation from './CustomerDetailReservation';
import ReservationSteps from '../organisms/ReservationSteps';

import PropTypes from 'prop-types';
import formatter from '../../utils/formatter';
import stepFormatter from '../../utils/stepFormatter';
import { useLocation } from 'react-router-dom';

ReservationDetailTemplate.propTypes = {
  data: PropTypes.any,
  onPrintReceipt: PropTypes.func,
};

export default function ReservationDetailTemplate({ data, onPrintReceipt, from }) {
  const detail = data || {};
  const { state } = useLocation();

  const customerDetail = {
    number: `${detail.prefix}-${detail.number}`,
    courseNumber: `${detail?.course?.name} (${detail?.course?.holes} Hole)`,
    teeTime: moment(detail.date_tee_times, 'HH:mm:ss').format('hh:mm A'),
    date: moment(detail.date).format('dddd, DD/MM/YYYY'),
    status: detail.payment_status,
    total: formatter.currency(detail?.total_amount),
    type: 'detail-header',
  };

  const reservationCustomers = useMemo(() => {
    let customers = [];
    if (detail?.reservation_customers) {
      detail.reservation_customers.forEach(item => {
        customers = [
          ...customers,
          {
            name: item.customer?.full_name,
            reservations: [
              {
                name: `${detail.tee_times.name} - ${item.membership_name}`,
                amount: item.membership_price,
                status:
                  item.status === 'cancelled'
                    ? 'cancelled'
                    : item.is_void
                      ? 'void'
                      : item.status === 'paid'
                        ? 'paid'
                        : 'unpaid',
              },
              ...item.add_ons.map(x => ({
                name: x.name,
                amount: x.price,
                status:
                  item.status === 'cancelled'
                    ? 'cancelled'
                    : item.is_void
                      ? 'void'
                      : item.status === 'paid'
                        ? 'paid'
                        : 'unpaid',
              })),
              ...(item.raincheck?.code ?[{
                name: 'Raincheck',
                amount: -item.raincheck.amount,
                status:
                  item.status === 'cancelled'
                    ? 'cancelled'
                    : item.is_void
                      ? 'void'
                      : item.status === 'paid'
                        ? 'paid'
                        : 'unpaid',
              }] :[])
            ],
          },
        ];
      });
    }
    return customers;
  }, [detail]);

  const receipt = useMemo(() => {
    return detail?.reservation_payments?.length
      ? {
        name: `Receipt ${detail?.reservation_payments?.[0]?.code}`,
        payment: `Paid by ${detail?.reservation_payments?.[0]?.payment_methods?.[0]?.name}`,
      }
      : null;
  }, [detail]);

  const reservations = useMemo(() => {
    return detail?.reservation_operators?.map(item => {
      let step = stepFormatter(item.step);

      return {
        title: `${step} by ${item.full_name} (Operator)`,
        description: moment(item.created_at).format('hh:mm A, DD/MM/YYYY'),
      };
    });
  }, [detail]);

  return (
    <div>
      <Space style={{ width: '100%' }} direction='vertical' size={24}>
        <CustomerDetailReservation
          detail={from === 'cancellation' ? null : customerDetail}
          reservationCustomers={reservationCustomers}
          from={from}
        />
        <TotalCard status={detail.payment_status} total={detail.total_amount} />
        {from === 'cancellation' && (
          <Card>
            <Row justify='space-between' align='middle'>
              <Col>
                <Text style={{ fontSize: 16 }} strong>
                  Cancellation Fee
                </Text>
              </Col>
              <Col>
                <Text style={{ fontSize: 20 }} strong>
                  {state?.data?.total}
                </Text>
              </Col>
            </Row>
            <div style={{ borderTop: '1px solid #F5F5F5', margin: '12px 0' }} />
            <div>
              <Text strong>Reason:</Text>
            </div>
            <div>
              <Text>{state?.data?.reason}</Text>
            </div>
          </Card>
        )}
        {receipt && from !== 'cancellation' && (
          <Card>
            <Row justify='space-between' align='middle'>
              <Col>
                <div>
                  <Text strong>{receipt.name}</Text>
                </div>
                <Text style={{ fontSize: 12 }}>{receipt.payment}</Text>
              </Col>
              <Col>
                <Button type='outline' onClick={onPrintReceipt}>
                  View receipt
                </Button>
              </Col>
            </Row>
          </Card>
        )}
        <Card>
          <ReservationSteps data={reservations || []} />
        </Card>
      </Space>
    </div>
  );
}
