import React, { useMemo } from 'react';
import CancellationReportList from '../organisms/CancellationReportList';
import CancellationReportCards from '../organisms/CancellationReportCards';
import moment from 'moment';
import formatter from '../../utils/formatter';

export function rebuildCancellationReportTemplate(data) {
  const items = [];

  data?.forEach(item => {
    const operators =
      item?.reservations?.reservation_operators?.filter(item => item.step === 'checked_in') || [];
    const checkInBy = operators[operators.length - 1] || {};

    items.push({
      ...item,
      id: item.reservation.id,
      checkInBy: checkInBy?.full_name || '',
      name: item.customer.full_name,
      cancelBy: item.reservation.cancellation_by_name,
      reason: item.reservation.cancellation_reason,
      number: `${item.reservation.number}`,
      time: item.reservation.cancellation_date
        ? moment(item.reservation.cancellation_date).format('ll')
        : '',
      total: formatter.currency(item.reservation.cancellation_fee),
    });
  });

  return items;
}

export default function CancellationReportTemplate({ dataReport, isLoading, isPrint }) {
  const dataCustomer = useMemo(() => {
    const items = rebuildCancellationReportTemplate(dataReport?.cancel_reservations);

    return items;
  }, [dataReport]);

  const summary = useMemo(() => dataReport?.summary || {}, [dataReport]);

  return (
    <div>
      <div id='check-ins-cards'>
        <CancellationReportCards
          isPrint={isPrint}
          totalCancellation={summary?.total_cancellation}
          totalCancellationFee={summary?.total_cancellation_fee}
        />
      </div>
      <div style={{ marginTop: 32 }}>
        <CancellationReportList data={dataCustomer} loading={isLoading} />
      </div>
    </div>
  );
}
