import { gql } from '@apollo/client';

class Query {
  static CREATE_CREATE_CUSTOMER = gql`
    mutation CreateCustomer($data: CustomerRead!) {
      createCustomer(data: $data) {
        code
        message
      }
    }
  `;
}

export default Query;
