import { Checkbox, Divider } from 'antd';
import React, { useCallback, useMemo, useState } from 'react';
import Card from '../atoms/Card';
import './CustomerFilterNationality.less';
import countriesNationality from '../../constants/countriesNationality.json';
import InputText from '../atoms/InputText';

const CustomerFilterNationality = ({ valueAll, setValueAll, valueSpecific, setValueSpecific }) => {
  const formatingCountriesNationality = useMemo(
    () =>
      countriesNationality.map(nationality => ({
        key: nationality?.key,
        value: nationality?.nationality,
        label: nationality?.nationality,
      })),
    [],
  );

  const [optionsSpecificNationality, setOptionsSpecificNationality] = useState(
    formatingCountriesNationality,
  );

  // const [valueSpecific, setValueSpecific] = useState(
  //   optionsSpecificNationality.map(option => option.value),
  // );

  useMemo(() => {
    setValueSpecific(optionsSpecificNationality.map(option => option.value));
  }, [optionsSpecificNationality]);

  const handleChangeSearch = useCallback(e => {
    let found = formatingCountriesNationality.filter(option => {
      return option.label.toLocaleLowerCase().includes(e.target.value.toLocaleLowerCase());
    });
    setOptionsSpecificNationality(found);
  });

  const handleChangeAll = useCallback(e => {
    setValueAll(e.target.checked);
    if (e.target.checked) {
      setValueSpecific(optionsSpecificNationality.map(option => option.value));
    } else {
      setValueSpecific([]);
    }
  });

  const handleChangeSpecific = useCallback(e => {
    const unchecked = optionsSpecificNationality
      .filter(x => !e.includes(x.value))
      .map(y => y.value);
    setValueSpecific(valueSpecific.filter(x => !unchecked.includes(x)));
    if (e.length === optionsSpecificNationality.length) {
      setValueAll(true);
    } else {
      setValueAll(false);
    }
  });

  return (
    <div id='customer-filter-nationality'>
      <InputText
        allowClear
        type='search'
        placeholder='Search nationalities'
        className='search'
        onChange={handleChangeSearch}
      />
      <Card>
        <Checkbox onChange={handleChangeAll} checked={valueAll}>
          All nationalities
        </Checkbox>
        <Divider />
        <Checkbox.Group
          options={optionsSpecificNationality}
          value={valueSpecific}
          onChange={handleChangeSpecific}
          className='checkbox-group'
        />
      </Card>
    </div>
  );
};

export default CustomerFilterNationality;
