import Icon from '@ant-design/icons';
import { Button, Col, Row } from 'antd';
import Text from 'antd/lib/typography/Text';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import './DropFile.less';
import SvgDelete from './SvgDelete';
import SvgUpload from './SvgUpload';

const DropFile = (props) => {
  const { onChange } = props;
  const [files, setFiles] = useState([]);
  const onDrop = useCallback(acceptedFiles => {
    onChange(acceptedFiles);
    setFiles(acceptedFiles);
    // Do something with the files
  }, []);
  const {getRootProps, getInputProps} = useDropzone({
    onDrop,
    accept: [
      'text/csv',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'application/vnd.ms-excel'
    ],
    multiple: false
  });

  const handleDelete = () => {
    setFiles([]);
  };

  return (
    <div {...getRootProps()} id="drop-file" className={files?.length > 0 ? 'file-exists' : ''}>
      <input {...getInputProps()} />
      {files?.length === 0 ? (
        <>
          <Icon component={SvgUpload} className="icon-upload" />
          <Text className="text">Drag and drop your file here or press the box to directly select files from your computer</Text>
        </>
      ) : (
        <Row align="middle" justify="space-between">
          <Col>
            <Text className="text-file">{files?.[0]?.name}</Text>
          </Col>
          <Col>
            <Button
              onClick={handleDelete}
              type="text"
              icon={<Icon component={SvgDelete}className="icon-delete" />}
            />
          </Col>
        </Row>
      )}
    </div>
  );
};

export default DropFile;

DropFile.propTypes = {
  onChange: PropTypes.func
};