import React from 'react';
import PropTypes from 'prop-types';
import Button from '../atoms/Button';
import { DownloadOutlined } from '@ant-design/icons';

const ExportButton = ({ onClick }) => {

  return (
    <Button
      type="outline"
      onClick={onClick}
    >
      <DownloadOutlined />
      Export
    </Button>
  );
};

ExportButton.propTypes = {
  onClick: PropTypes.func,
};

export default ExportButton;