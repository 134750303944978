import { Table } from 'antd';
import React from 'react';
import PropTypes from 'prop-types';
import './TableContent.less';
import Loading from '../atoms/Loading';
import HeaderContent from '../molecules/HeaderContent';

const TableContent = (props) => {
  const {
    columns,
    data,
    buttonAdd,
    buttonFilter,
    searchInput,
    extraLeft,
    extraRight,
    loading,
    header,
    onRow,
  } = props;

  const tableLoading = {
    spinning: loading,
    indicator: <Loading />,
  };
  
  return (
    <div id="table-content">
      { header && <HeaderContent
        buttonAdd={buttonAdd}
        buttonFilter={buttonFilter}
        searchInput={searchInput}
        extraLeft={extraLeft}
        extraRight={extraRight}
      /> }
      <Table
        columns={columns}
        dataSource={data}
        className={`antd-table ${onRow ? 'clickable' : ''}`}
        pagination={false}
        scroll={{ y: 360 }}
        loading={tableLoading}
        onRow={onRow}
      />
    </div>
  );
};

export default TableContent;

TableContent.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.any).isRequired,
  data: PropTypes.arrayOf(PropTypes.any).isRequired,
  loading: PropTypes.bool,
  header: PropTypes.bool,
  onRow: PropTypes.func,
  ...PropTypes.instanceOf(HeaderContent)
};

TableContent.defaultProps = {
  loading: false,
  header: true,
};