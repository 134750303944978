import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import Promise from 'bluebird';
import moment from 'moment';
import QueryGql from '../../constants/QueryGql';
import extractQuery from '../../utils/useQuery';
import Modal from '../organisms/Modal';
import TeeSheetReservationFormGroupTemplate from '../templates/TeeSheetReservationGroupForm';
import Pathname from 'constants/Pathname';

const TeeSheetReservationForm = () => {
  const history = useHistory();
  const location = useLocation();
  const refTemplateTeeSheetReservationGroupForm = useRef();
  const queryString = extractQuery(location.search);
  const act = queryString.get('act');
  const date = queryString.get('date');

  // const courseId = location.state?.courseId;
  const initialTeeTimes = location.state?.initialTeeTimes;
  const teeTimes = location.state?.optionsTeeTime;

  let [isLoadingSaveAndCheckin, setIsLoadingSaveAndCheckin] = useState(false);

  const { loading: loadingSearchRateCard, data: dataSearchRateCard } = useQuery(
    QueryGql.SEARCH_RATE_CARD,
    {
      variables: {
        searchRateCardSearch: '',
      },
      fetchPolicy: 'no-cache',
    },
  );

  const [
    getReservationGroup,
    { loading: loadingGetReservationGroup, data: dataGetReservationGroup },
  ] = useLazyQuery(QueryGql.GET_RESERVATION_GROUP);

  const [checkinReservation, { loading: loadingCheckinReservation }] = useMutation(
    QueryGql.CHECKIN_RESERVATION,
  );

  const [createReservationGroup, { loading: loadingCreateReservationGroup }] = useMutation(
    QueryGql.CREATE_RESERVATION_GROUP,
    {
      onCompleted: res => {
        if (res && isLoadingSaveAndCheckin === true) {
          const varCheckinReservations = [];
          const reservations = res.createReservationGroup.data.reservations;
          reservations.forEach(x => {
            varCheckinReservations.push({
              variables: {
                checkinReservationId: x.id,
                checkinReservationCustomers: x.reservation_customers.map(y => {
                  return {
                    id: y.customer_id,
                    bag_id: '',
                    status: 'checked_in',
                  };
                }),
              },
            });
          });
          if (varCheckinReservations.length > 0) {
            Promise.map(varCheckinReservations, varCheckinReservation => {
              // Promise.map awaits for returned promises as well.
              return checkinReservation(varCheckinReservation);
            })
              .then(() => {
                history.goBack();
              })
              .catch(() => {});
          } else {
            history.goBack();
          }
        } else {
          history?.goBack();
        }
      },
      onError: () => {},
      update: async (cache, { data: { createReservationGroup } }) => {
        cache.modify({
          fields: {
            getReservations(existingReservations = []) {
              let newReservationsGroupRef = [];
              createReservationGroup.data.reservations.forEach(item => {
                newReservationsGroupRef.push(
                  cache.writeFragment({
                    data: item,
                    fragment: QueryGql.FRAGMENT_RESERVATION,
                  }),
                );
              });
              return {
                ...existingReservations,
                data: [...existingReservations.data, ...newReservationsGroupRef],
              };
            },
          },
        });
      },
    },
  );

  const [updateReservationGroup, { loading: loadingUpdateReservationGroup }] = useMutation(
    QueryGql.UPDATE_RESERVATION_GROUP,
    {
      onCompleted: () => {
        history?.goBack();
      },
      onError: () => {
        history.goBack();
      },
      // update: async (cache, { data: { updateReservationGroup } }) => {
      //   cache.modify({
      //     fields: {
      //       getReservations(existingReservations = []) {
      //         const newReservations = existingReservations.data.map(x => {
      //           let newReservation = x;
      //           updateReservationGroup.data.reservations.forEach(item => {
      //             if (x.__ref.includes(item.id)) {
      //               newReservation = cache.writeFragment({
      //                 data: item,
      //                 fragment: QueryGql.FRAGMENT_RESERVATION
      //               });
      //             }
      //           });
      //           return newReservation;
      //         });
      //         return {
      //           ...existingReservations,
      //           data: newReservations
      //         };
      //       }
      //     }
      //   });
      // }
    },
  );

  const initialValues = useMemo(() => {
    const reservationGroup = dataGetReservationGroup?.getReservationGroup.data;
    if (reservationGroup) {
      return {
        id: reservationGroup.id,
        date: reservationGroup.date,
        name: reservationGroup.name,
        reservations: reservationGroup.reservations.map(reservation => ({
          id: reservation.id,
          date: reservation.date,
          date_tee_time: reservation.date_tee_times,
          course_id: reservation.course.id,
          tee_time_id: reservation.tee_times.id,
          rate_card_id: reservation.rate_cards.id,
          hole_name: reservation.reservation_holes.name,
          note: reservation.reservation_note,
          message: reservation.reservation_message,
          customers: reservation.reservation_customers.map(reservationCustomer => ({
            customer_id: reservationCustomer.customer.id,
            name: reservationCustomer.customer.full_name,
            email: reservationCustomer.customer.email,
            phone_number: reservationCustomer.customer.phone_number,
            membership_id: reservationCustomer.membership_id,
            add_ons_id: reservationCustomer.add_ons.map(ax => ax.id),
            voucher_code: reservationCustomer.raincheck?.code
              ? reservationCustomer.raincheck.code
              : reservationCustomer.promotion?.code,
            voucher_code_data: reservationCustomer.raincheck?.code
              ? { ...reservationCustomer.raincheck, is_raincheck: true }
              : null,
            act: act,
          })),
        })),
      };
    }
  }, [dataGetReservationGroup]);

  useEffect(() => {
    if (act === 'edit') {
      const id = queryString.get('id');
      getReservationGroup({
        variables: {
          getReservationGroupId: id,
        },
      });
    }
  }, []);

  const handleSubmit = async isAndCheckinAll => {
    const values = await refTemplateTeeSheetReservationGroupForm.current.handleSubmit();
    if (isAndCheckinAll === true) {
      isLoadingSaveAndCheckin = true;
      setIsLoadingSaveAndCheckin(isLoadingSaveAndCheckin);
    }
    if (values) {
      values?.reservations?.forEach((res, index) => {
        res?.customers.forEach((cus, indexCus) => {
          delete values.reservations[index].customers[indexCus].act;
          delete values.reservations[index].customers[indexCus].voucher_code_error;
          delete values.reservations[index].customers[indexCus].voucher_code_data;
          delete values.reservations[index].customers[indexCus].discount;
        });
      });
      if (act === 'add') {
        let valuesReservationsWithCustomers = values.reservations.filter(
          x => x.customers.length > 0,
        );
        if (valuesReservationsWithCustomers.length > 0) {
          valuesReservationsWithCustomers = valuesReservationsWithCustomers.map(reservation => {
            const rateCards = dataSearchRateCard.searchRateCard.data;
            const membership = rateCards
              .find(x => x.id === reservation.rate_card_id)
              .courses.find(x => x.id === reservation.course_id).memberships?.[0];
            let timeBasedRatesId = '';
            membership.time_based_rates.forEach(x => {
              if (
                moment(reservation.date_tee_time, 'HH:mm:ss').isBetween(
                  moment(x.start_time, 'HH:mm:ss'),
                  moment(x.end_time, 'HH:mm:ss'),
                ) ||
                moment(reservation.date_tee_time, 'HH:mm:ss').isSame(
                  moment(x.start_time, 'HH:mm:ss'),
                ) ||
                moment(reservation.date_tee_time, 'HH:mm:ss').isSame(moment(x.end_time, 'HH:mm:ss'))
              ) {
                timeBasedRatesId = x.id;
              }
            });
            return {
              ...reservation,
              time_based_rate_id: timeBasedRatesId,
            };
          });
          createReservationGroup({
            variables: {
              data: {
                ...values,
                reservations: valuesReservationsWithCustomers,
              },
            },
          });
        }
      } else {
        const id = queryString.get('id');
        let valuesReservationsWithCustomers = values.reservations;
        valuesReservationsWithCustomers = valuesReservationsWithCustomers.map(reservation => {
          const rateCards = dataSearchRateCard.searchRateCard.data;
          const membership = rateCards
            .find(x => x.id === reservation.rate_card_id)
            .courses.find(x => x.id === reservation.course_id).memberships?.[0];
          let timeBasedRatesId = '';
          membership.time_based_rates.forEach(x => {
            if (
              moment(reservation.date_tee_time, 'HH:mm:ss').isBetween(
                moment(x.start_time, 'HH:mm:ss'),
                moment(x.end_time, 'HH:mm:ss'),
              ) ||
              moment(reservation.date_tee_time, 'HH:mm:ss').isSame(
                moment(x.start_time, 'HH:mm:ss'),
              ) ||
              moment(reservation.date_tee_time, 'HH:mm:ss').isSame(moment(x.end_time, 'HH:mm:ss'))
            ) {
              timeBasedRatesId = x.id;
            }
          });
          return {
            ...reservation,
            time_based_rate_id: timeBasedRatesId,
          };
        });
        updateReservationGroup({
          variables: {
            data: {
              id,
              ...values,
              reservations: valuesReservationsWithCustomers,
            },
          },
        });
      }
    }
  };

  const handleCancelReservation = () => {
    const id = queryString.get('id');
    history.push(
      Pathname.TEE_SHEET + '/' + Pathname.TEE_SHEET_CANCEL_RESERVATION + `?groupId=${id}`,
      {
        background: true,
      },
    );
  };

  return (
    <Modal
      title={act === 'add' ? 'Add new group reservation' : 'Edit group reservation'}
      hiddenLeftFooterButton={act === 'add'}
      leftFooterButton={{
        label: 'Cancel reservation',
        type: 'link',
        onClick: handleCancelReservation,
      }}
      rightFooterButtonExtra={{
        label: 'Save and check-in',
        type: 'outline',
        loading:
          isLoadingSaveAndCheckin || loadingCheckinReservation || loadingCreateReservationGroup,
        onClick: () => handleSubmit(true),
      }}
      rightFooterButton={{
        label: 'Save reservation',
        loading: loadingCreateReservationGroup || loadingUpdateReservationGroup,
        state: loadingGetReservationGroup ? 'disabled' : 'default',
        onClick: () => handleSubmit(),
      }}
      width={964}>
      <TeeSheetReservationFormGroupTemplate
        date={date}
        ref={refTemplateTeeSheetReservationGroupForm}
        initialTeeTimes={initialTeeTimes}
        initialValues={initialValues}
        teeTimes={teeTimes}
        rateCards={
          !loadingSearchRateCard && dataSearchRateCard?.searchRateCard?.data?.length > 0
            ? dataSearchRateCard.searchRateCard.data.map(item => ({
                value: item.id,
                color: item.color,
                label: item.name,
                courses: item.courses,
              }))
            : []
        }
      />
    </Modal>
  );
};

export default TeeSheetReservationForm;
