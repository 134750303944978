import React from 'react';
import Button from '../atoms/Button';
import { ArrowRightOutlined } from '@ant-design/icons';
import Text from 'antd/lib/typography/Text';

import './Note.less';
import { useHistory } from 'react-router-dom';
import Pathname from '../../constants/Pathname';

export default function Note({ title, course, desc, id }) {

  const { push } = useHistory();
  return (
    <div className="note">
      <div>
        <Text strong>{title}</Text>
      </div>
      <div>
        <Text className="course">{course}</Text>
      </div>
      <div style={{ marginTop: 24, marginBottom: 24 }}>
        <Text>{desc}</Text>W
      </div>
      <Button onClick={() => push(Pathname.TEE_SHEET_RESERVATION_DETAIL + '?id=' + id, { background: true })} type="link">
          See reservation <ArrowRightOutlined style={{ fontSize: 12 }} />
      </Button>
    </div>
  );
}