import Text from 'antd/lib/typography/Text';
import React from 'react';
import ModalCancelAction from '../templates/ModalCancelAction';
import extractQuery from '../../utils/useQuery';
import useMembership from 'hooks/useMembership';
import { useHistory } from 'react-router-dom';

const TypeMembershipsDelete = () => {
  const history = useHistory();

  const queryString = extractQuery(location.search);
  const id = queryString.get('id');
  const name = queryString.get('name');

  const { remove, removeLoading } = useMembership();

  const handleDelete = () => {
    remove({
      variables: {
        id
      }
    }).then(() => {
      history.goBack();
    });
  };

  return (
    <ModalCancelAction
      title='Delete type & membership?'
      leftFooterButton={{
        label: 'Cancel',
        state: 'hint',
        disabled: removeLoading
      }}
      rightFooterButton={{
        label: 'Delete type & membership',
        loading: removeLoading,
        onClick: handleDelete,
      }}
    >
      <Text>Are you sure you would like to delete {name}? Any saved changes will be permanently deleted and can not be recovered anymore.</Text>
    </ModalCancelAction>
  );
};

export default TypeMembershipsDelete;