export default function stepFormatter(value) {
  let step = value;
  if (value === 'reservation') {
    step = 'Reservaton made';
  }

  if (value === 'update_reservation') {
    step = 'Reservaton updated';
  }

  if (value === 'checked_in') {
    step = 'Checked-in';
  }

  if (value === 'payment') {
    step = 'Payment taken';
  }

  return step;
}