import Icon from '@ant-design/icons';
import { Col, DatePicker, Row } from 'antd';
import Text from 'antd/lib/typography/Text';
import { Formik } from 'formik';
import * as Yup from 'yup';
import React, { forwardRef, useImperativeHandle, useMemo, useRef, useCallback } from 'react';
import moment from 'moment';
import numeral from 'numeral';
import PropTypes from 'prop-types';
import Button from '../atoms/Button';
import Card from '../atoms/Card';
// import SvgSearch from '../atoms/SvgSearch';
// import SvgTeeTimeFilled from '../atoms/SvgTeeTimeFilled';
import HorizontalSelectOptions from '../molecules/HorizontalSelectOptions';
import HorizontalTextInput from '../molecules/HorizontalTextInput';
import HorizontalTextInputArea from '../molecules/HorizontalTextInputArea';
import TeeSheetReservationCustomerForm from '../molecules/TeeSheetReservationCustomerForm';
import './TeeSheetReservationForm.less';
import extractQuery from '../../utils/useQuery';
import SvgTeeTimes from '../atoms/SvgTeeTimes';
import SvgTag from '../atoms/SvgTag';
import { useQuery } from '@apollo/client';
import QueryGql from '../../constants/QueryGql';
import SvgTeeSheet from '../atoms/SvgTeeSheet';
// import { useQuery } from '@apollo/client';
// import QueryGql from '../../constants/QueryGql';

const TeeSheetReservationFormValidations = Yup.object({
  date: Yup.mixed().required().label('Date'),
  date_tee_time: Yup.mixed().required().label('Tee time'),
  rate_card_id: Yup.mixed().required().label('Active rate card'),
  customers: Yup.array().of(
    Yup.object().shape({
      customer_id: Yup.string(),
      name: Yup.string().required().label('Customer name'),
      membership_id: Yup.mixed().required().label('Customer type'),
      phone_number: Yup.string().when('name', {
        is: value => {
          return value !== 'guest';
        },
        then: Yup.string().required('Required'),
        otherwise: Yup.string(),
      }),
      voucher_code: Yup.string().when('voucher_code_error', {
        is: value => {
          return value;
        },
        then: Yup.string().min(1000),
        otherwise: Yup.string(),
      }),
    }),
  ),
});

const TeeSheetReservationForm = (props, ref) => {
  const { holes, teeTimes, rateCards, time, courseId, initialValues, isSelectionHoles } = props;
  const refFormik = useRef();

  const { data: dataFormCustomerReservation } = useQuery(QueryGql.FORM_CUSTOMER_RESERVATION);
  const { data: dataLowerPriceAfterRaincheck } = useQuery(
    QueryGql.GET_LOWER_PRICE_AFTER_RAINCHECK,
    { fetchPolicy: 'cache-and-network' },
  );

  const maximumPlayers = dataFormCustomerReservation?.getMaximumPlayers?.data.value
    ? Number(dataFormCustomerReservation?.getMaximumPlayers?.data.value)
    : 0;
  const queryString = extractQuery(location.search);
  const date = queryString.get('date');

  useImperativeHandle(ref, () => ({
    handleSubmit: async () => {
      const validateForm = await refFormik.current.validateForm();
      refFormik.current.handleSubmit();
      if (Object.keys(validateForm).length === 0) {
        return refFormik.current.values;
      }
      return null;
      // return refFormik.current.values;
    },
  }));

  const optionsTeeTime = useMemo(
    () =>
      teeTimes.map(item => ({
        key: item.value,
        value: item.value,
        label: (
          <div>
            <Icon component={SvgTeeTimes} />
            <Text className='label-option-tee-time'>{item.label}</Text>
          </div>
        ),
      })),
    [teeTimes],
  );

  const optionsHoles = useMemo(
    () =>
      holes.map(item => ({
        key: item.value,
        value: item.value,
        label: (
          <div>
            <Icon component={SvgTeeSheet} />
            <Text className='label-option-tee-time'>{item.label}</Text>
          </div>
        ),
      })),
    [holes],
  );

  const optionsRateCard = useMemo(
    () =>
      rateCards.map(item => ({
        key: item.value,
        value: item.value,
        label: (
          <div>
            <Icon component={SvgTag} style={{ color: item.color }} />
            <Text className='label-option-tee-time'>{item.label}</Text>
          </div>
        ),
      })),
    [rateCards],
  );

  const memberships = useCallback(() => {
    const rateCardId = refFormik.current?.values?.rate_card_id || initialValues?.rate_card_id;
    if (rateCards?.length > 0) {
      return (
        rateCards.find(x => x.value === rateCardId)?.courses.find(x => x.id === courseId)
          ?.memberships || []
      );
    }
    return [];
  }, [refFormik.current?.values, rateCards, initialValues]);

  const handleDelete = useCallback(
    indexItem => {
      const customers = refFormik.current.values.customers;
      customers.splice(indexItem, 1);
      refFormik.current.setFieldValue('customers', customers);
    },
    [refFormik.current?.values],
  );

  const getTotal = useCallback(() => {
    let total = 0;
    let isRaincheckCode = false;
    if (
      refFormik.current?.values?.customers &&
      dataFormCustomerReservation?.getAllMembership?.data &&
      dataFormCustomerReservation?.getAllAddOns?.data
    ) {
      const dateTeeTime = refFormik.current?.values.date_tee_time;
      refFormik.current.values.customers.forEach(item => {
        let membershipTypePrice = 0;
        const membership = memberships().find(x => x.id === item.membership_id);
        membership?.time_based_rates?.forEach(x => {
          if (
            moment(dateTeeTime, 'HH:mm:ss').isBetween(
              moment(x.start_time, 'HH:mm:ss'),
              moment(x.end_time, 'HH:mm:ss'),
            ) ||
            moment(dateTeeTime, 'HH:mm:ss').isSame(moment(x.start_time, 'HH:mm:ss')) ||
            moment(dateTeeTime, 'HH:mm:ss').isSame(moment(x.end_time, 'HH:mm:ss'))
          ) {
            membershipTypePrice = x.price;
          }
        });
        let addOnsPrice = 0;
        dataFormCustomerReservation.getAllAddOns.data.forEach(x => {
          item.add_ons_id.forEach(y => {
            if (x.id === y) {
              addOnsPrice = addOnsPrice + x.price;
            }
          });
        });
        total = total + (membershipTypePrice + addOnsPrice);
        if (item?.discount) {
          total -= item.discount;
        }

        if (item.voucher_code_data?.is_raincheck) {
          isRaincheckCode = true;
        }
      });
    }
    if (isRaincheckCode) {
      const lowerPriceAfterRaincheck = dataLowerPriceAfterRaincheck?.getLowestPriceAfterRainCheck
        ?.data?.value
        ? Number(dataLowerPriceAfterRaincheck?.getLowestPriceAfterRainCheck?.data?.value)
        : 0;
      if (lowerPriceAfterRaincheck && total < lowerPriceAfterRaincheck) {
        return lowerPriceAfterRaincheck;
      }
    }
    return total;
  }, [
    refFormik.current?.values,
    dataFormCustomerReservation,
    rateCards,
    dataLowerPriceAfterRaincheck,
  ]);

  return (
    <Formik
      innerRef={refFormik}
      enableReinitialize
      validationSchema={TeeSheetReservationFormValidations}
      initialValues={
        initialValues || {
          date,
          course_id: '',
          tee_time_id: '',
          rate_card_id: null,
          date_tee_time: time,
          note: '',
          message: '',
          customers: [
            {
              customer_id: '',
              name: '',
              email: '',
              phone_number: '',
              membership_id: null,
              add_ons_id: [],
              voucher_code: '',
            },
          ],
        }
      }>
      {({ values, errors, handleChange, submitCount, setFieldValue, setFieldError }) => {
        return (
          <form id='tee-sheet-reservation-form'>
            <Card>
              <Row gutter={16}>
                <Col xs={isSelectionHoles ? 6 : 8}>
                  <HorizontalTextInput
                    label='Reservation date'
                    right={
                      <DatePicker
                        value={values.date ? moment(values.date, 'YYYY-MM-DD') : ''}
                        format={value => value.format('dddd, DD/MM/YYYY')}
                        className='date-picker'
                        size='large'
                        onSelect={value => handleChange('date')(value.format('YYYY-MM-DD'))}
                      />
                    }
                  />
                  {submitCount > 0 && errors?.date && (
                    <Text className='error-message' type='danger'>
                      {errors.date}
                    </Text>
                  )}
                </Col>
                <Col xs={isSelectionHoles ? 6 : 8}>
                  <HorizontalSelectOptions
                    options={optionsTeeTime}
                    value={values.date_tee_time}
                    onSelect={e => {
                      handleChange('date_tee_time')(e);
                      const idTeeTime = teeTimes.filter(x => x.value === e)?.[0]?.id;
                      handleChange('tee_time_id')(idTeeTime);
                    }}
                    errorMessage={submitCount > 0 ? errors?.date_tee_time : null}
                    placeholder={
                      <div>
                        <Icon component={SvgTeeTimes} className='icon-tee-time' />
                        <Text className='placeholder'>Select tee time</Text>
                      </div>
                    }
                    label='Tee time'
                  />
                </Col>
                {isSelectionHoles && (
                  <Col xs={6}>
                    <HorizontalSelectOptions
                      options={optionsHoles}
                      showSearch={false}
                      value={values.hole_name}
                      onSelect={value => {
                        handleChange('hole_name')(value);
                      }}
                      placeholder={
                        <div>
                          <Icon component={SvgTeeSheet} className='icon-tee-time' />
                          <Text className='placeholder'>Select hole</Text>
                        </div>
                      }
                      label='Starting hole'
                    />
                  </Col>
                )}
                <Col xs={isSelectionHoles ? 6 : 8}>
                  <HorizontalSelectOptions
                    options={optionsRateCard}
                    placeholder={
                      <div>
                        <Icon component={SvgTag} className='icon-tee-time' />
                        <Text className='placeholder'>Select rate card</Text>
                      </div>
                    }
                    value={
                      optionsRateCard?.find(item => item.value === values.rate_card_id)
                        ? values.rate_card_id
                        : null
                    }
                    errorMessage={submitCount > 0 ? errors?.rate_card_id : null}
                    onSelect={value => {
                      handleChange('rate_card_id')(value);
                    }}
                    label='Active rate card'
                  />
                </Col>
              </Row>
            </Card>
            <Card className='card-item'>
              {values.customers.map((item, index) => {
                return (
                  <>
                    <TeeSheetReservationCustomerForm
                      key={index}
                      values={values.customers[index]}
                      customersSelected={values.customers}
                      valueDateTeeTime={values.date_tee_time}
                      errors={submitCount > 0 ? errors?.customers?.[index] : null}
                      onChange={values => {
                        setFieldValue(`customers[${index}]`, values);
                      }}
                      onDelete={() => handleDelete(index)}
                      memberships={memberships()}
                      index={index}
                      name='customers'
                      setFieldError={setFieldError}
                    />
                    <div className='spacer-lg' />
                  </>
                );
              })}
              <div className='button-add-container'>
                <Button
                  onClick={() => {
                    setFieldValue(`customers[${values.customers.length}]`, {
                      customer_id: '',
                      name: '',
                      email: '',
                      phone_number: '',
                      membership_id: null,
                      add_ons_id: [],
                      voucher_code: '',
                    });
                  }}
                  state={values.customers.length >= maximumPlayers ? 'disabled' : 'default'}
                  disabled={values.customers.length >= maximumPlayers}
                  type='guide'>
                  + Add customer
                </Button>
              </div>
            </Card>
            <Card className='card-item'>
              <HorizontalTextInputArea
                value={values.note}
                onChange={handleChange('note')}
                label='Reservation notes (for internal reference)'
              />
              <div className='spacer' />
              <HorizontalTextInputArea
                value={values.message}
                onChange={handleChange('message')}
                label='Reservation message (sent to customer)'
              />
            </Card>
            <Card className='card-item'>
              <Row justify='space-between' align='middle'>
                <Col>
                  <Text className='text-total'>Total</Text>
                </Col>
                <Col>
                  <Text level={4} className='price-total'>
                    {numeral(getTotal()).format('$0,0[.]00')}
                  </Text>
                </Col>
              </Row>
            </Card>
          </form>
        );
      }}
    </Formik>
  );
};

export default forwardRef(TeeSheetReservationForm);

TeeSheetReservationForm.propTypes = {
  teeTimes: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
  ).isRequired,
  holes: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
  ).isRequired,
  rateCards: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
      color: PropTypes.string,
      courses: PropTypes.array,
    }),
  ),
  courseId: PropTypes.string,
  initialValues: PropTypes.object,
  isSelectionHoles: PropTypes.bool,
};

TeeSheetReservationForm.defaultProps = {
  rateCards: [],
};
