import React from 'react';
import PropTypes from 'prop-types';
import HeaderContent from '../molecules/HeaderContent';
import { Col, Row } from 'antd';
import CardListItem from '../molecules/CardListItem';
import Loading from '../atoms/Loading';
import './CardList.less';

const CardList = (props) => {
  const {
    data,
    buttonAdd,
    buttonFilter,
    searchInput,
    extraLeft,
    extraRight,
    loading,
    contextMenuItem,
    onClickItem,
    icon,
    noIcon
  } = props;
  return (
    <div id="card-list">
      <HeaderContent
        buttonAdd={buttonAdd}
        buttonFilter={buttonFilter}
        searchInput={searchInput}
        extraLeft={extraLeft}
        extraRight={extraRight}
      />
      <div>
        {loading && (
          <div className="loading">
            <Loading />
          </div>
        )}
        {noIcon ? (
          <Row gutter={[20, 20]}>
            {data?.map((item) => (
              <Col key={item.id} sm={12} lg={6}>
                <CardListItem icon={null} {...item} contextMenu={contextMenuItem(item)} onClick={onClickItem} />
              </Col>
            ))}
          </Row>
        ) : (
          <Row gutter={[20, 20]}>
            {data?.map((item) => (
              <Col key={item.id}  sm={12} lg={6}>
                <CardListItem icon={icon} {...item} contextMenu={contextMenuItem(item)} onClick={onClickItem} />
              </Col>
            ))}
          </Row>
        )}
      </div>
    </div>
  );
};

export default CardList;

CardList.propTypes = {
  loading: PropTypes.bool,
  contextMenuItem: PropTypes.node,
  onClickItem: PropTypes.func,
  noIcon: PropTypes.bool,
  icon: PropTypes.node,
  data: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    icon: PropTypes.node,
    color: PropTypes.string,
  })),
  ...PropTypes.instanceOf(HeaderContent),
};