import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { cx } from '@emotion/css';
import { Col, Divider, Row, Tooltip } from 'antd';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import './EditSignsForm.less';
import Title from 'antd/lib/typography/Title';
import Avatar from '../atoms/Avatar';
import Checkbox from '../atoms/Checkbox';
import Text from 'antd/lib/typography/Text';
import Icon from '@ant-design/icons';
import SvgDelete from '../atoms/SvgDelete';
import SvgDraggable from '../atoms/SvgDraggable';
import Button from '../atoms/Button';
import SvgCart from '../atoms/SvgCart';

const EditSignsForm = (props) => {
  const { data, onAddCart, onClickDelete, onSelected, isPrint, selected } = props;


  const onDragEnd = useCallback((result) => {
    if (result.reason === 'DROP') {
      if (!result.destination) {
        return;
      }
      props.onDragEnd?.(result);
    }
  }, [data]);

  return (
    <div style={isPrint ? { paddingTop: 20, paddingLeft: 20, paddingRight: 20 } : {}}>
      <DragDropContext onDragEnd={onDragEnd}>
        {data?.map((obj) => {
          if (isPrint && obj.carts.filter(item => item.customers.length !== 0).length == 0) {
            return null;
          }

          return (
            <div id="edit-signs-form" key={obj.id}>
              <Title level={5} className="title-edit-signs">{obj.name}</Title>
              <div style={{ display: 'block' }}>
                {obj.carts.map((cart) => {
                  const realCustomer = cart.customers.filter((v) => !v.item.isNew);
                  const isEmpty = realCustomer.length === 0;

                  if (isPrint && isEmpty) {
                    return null;
                  } 
              
                  return (
                    <Droppable key={cart.id} droppableId={cart.id} type="cart">
                      {(provided, snapshot) => {
                        return (
                          <div
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                            className={cx(
                              snapshot.isDraggingOver
                            )}
                          >
                            <Row key={cart.id} gutter={5} className="card-edit-signs-p">
                              <Col className="icon-edit-signs">
                                <Tooltip title={isEmpty ? 'Delete' : 'Move customer before delete this cart'}>
                                  <Icon onClick={isEmpty ? () => onClickDelete(cart) : null} component={SvgDelete} style={{ cursor: 'pointer' }} className="icon-upload" />
                                </Tooltip>
                              </Col>
                              <Col className="title-edit-signs">
                                <Title level={5}>{cart.name}</Title>
                              </Col>
                              <Col>
                                {cart.customers?.map((customer, index) => {
                                  if (customer?.item?.isNew) {
                                    return null;
                                  }

                                  return (
                                    <Draggable
                                      key={customer.id}
                                      draggableId={customer.id}
                                      index={index}
                                    >
                                      {(provided, snapshot) => {
                                        return (
                                          <div
                                            key={customer.id}
                                            className={cx(
                                              snapshot.isDragging,
                                              'card-edit-signs-c'
                                            )}
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                          >
                                            <Row
                                              gutter={5}
                                            >
                                              <Col className="title-edit-signs form-width-signs">
                                                <Checkbox
                                                  onChange={(v) => onSelected(v.target.checked, customer)}
                                                  checked={selected.includes(customer.id)}
                                                >
                                                  <Avatar title={customer.name} />
                                                  <Text style={{ marginLeft: 8 }}>{customer.name}</Text>
                                                </Checkbox>
                                              </Col>
                                              <Col>
                                                {!isPrint && <Icon component={SvgDraggable} className="icon-upload" />}
                                              </Col>
                                            </Row>
                                          </div>
                                        );
                                      }}
                                    </Draggable>
                                  );
                                })}
                                {provided.placeholder}
                              </Col>
                            </Row>
                          </div>
                        );
                      }}
                    </Droppable>
    
                  );
                })}
                <div style={{ textAlign: 'center', margin: 24 }}>
                  {
                    isPrint ? null : (
                      <Button
                        type="guide"
                        onClick={() => onAddCart(obj)}
                      >
                        <Icon component={SvgCart} className="icon-upload" />
                  Add another cart</Button>
                    )
                  }
                </div>
                <Divider style={{ marginTop: 48, marginBottom: 48, border: '0.5px solid #C2C2C' }} />
              </div>
            </div>
          );
        })}
      </DragDropContext>
    </div>
  );
};

EditSignsForm.propTypes = {
  isPrint: PropTypes.bool,
  onDragEnd: PropTypes.func,
  onAddCart: PropTypes.func,
  onSelected: PropTypes.func,
  onClickDelete: PropTypes.func,
  data: PropTypes.any,
  selected: PropTypes.arrayOf(PropTypes.string),
};

export default EditSignsForm;