import Receipt from 'components/organisms/Receipt';
import moment from 'moment';
import React, { useMemo } from 'react';
import useCourseProfile from 'hooks/useCourseProfile';
import _ from 'lodash';

export default function PrintReceipt({ data, printComponentRef, isPreview }) {
  const { courseProfile, clubName } = useCourseProfile();

  const total = useMemo(() => data?.total_amount || 0, [data]);
  const reservations = useMemo(() => {
    if (data) {
      const reservation = data;
      const reservation_customers = data.reservation_customers
        ?.filter(item => (isPreview ? true : item.status === 'paid'))
        .map(item => {
          let subtotal = 0;
          const reservations = [
            {
              name: `${reservation.tee_times?.name} - ${item.membership_name}`,
              price: item.membership_price,
              status: item.status,
            },
            ...item.add_ons.map(add_on => ({
              name: add_on.name,
              price: add_on.price,
              status: item.status,
            })),
          ];

          if (item.raincheck?.code) {
            reservations.push({
              name: 'Raincheck',
              price: -item.raincheck.amount,
              status: item.status,
            });
          }

          reservations.forEach(reser => {
            subtotal += reser.price;
          });

          const payments = [];

          const customerPayments =
            data.reservation_payments?.filter(pay => pay.paid_by === item.customer_id) || [];

          let total_payment = 0;
          customerPayments.forEach(pay => {
            pay.payment_methods?.map(pm => {
              total_payment += pm.amount;
              payments.push(pm);
            });
          });



          return {
            subtotal,
            id: item.id,
            status: item.status,
            bag_id: item.bag_id,
            course: data.course,
            date: `${data.date_tee_times} ${moment(data.date).format('DD/MM/YYYY')}`,
            customer: {
              id: item.customer_id,
              name: item.customer.full_name,
            },
            reservations,
            payments,
            total_payment,
          };
        });

      const results = [];

      const find = reservation_customers?.[0] || {};

      const items = [];
      let subtotal = 0;
      const reservation_payment_ids = data.reservation_payments.map(item => item.id);


      reservation_customers?.forEach(rc => {
        if (!isPreview || find.total_payment === total) {
          // combined
          rc.reservations.forEach(r => {
            subtotal += r.price;
            items.push(r);
          });
        } else {
          // split
          if (reservation_payment_ids.includes(rc.id)) {
            rc.reservations.forEach(r => {
              subtotal += r.price;
              items.push(r);
            });
          }
        }
      });

      const grouping = _.groupBy(items, 'name');
      const lineItems = [];

      Object.keys(grouping).forEach(key => {
        let price = 0;

        grouping[key].forEach(item => {
          price += item.price;
        });

        lineItems.push({
          price,
          name: key,
          quantity: grouping[key].length,
        });
      });
      results.push({
        ...find,
        subtotal,
        reservations: lineItems,
      });

      return results;
    }
    return [];
  }, [data]);

  return (
    <div style={{ display: 'none' }}>
      <div ref={printComponentRef}>
        {reservations?.map(item =>
          [0, 1, 2].map(() => (
            <Receipt
              key={item.id}
              date={item.date}
              user={item.customer?.name}
              reservationId={item.id}
              receiptId={item.bag_id}
              total={item.subtotal}
              subTotal={item.subtotal}
              items={item.reservations}
              phoneNumber={courseProfile?.phone_number}
              email={courseProfile?.email}
              website={courseProfile?.website}
              facebook={courseProfile?.facebook}
              logo={courseProfile?.picture_url}
              paymentStatus={item.status === 'paid' ? 'PAID' : isPreview ? 'PREVIEW' : 'UNPAID'}
              courseName={clubName}
              courseAddress={courseProfile?.address}
              payments={item.payments}
            />
          )),
        )}
      </div>
    </div>
  );
}
