import { gql } from '@apollo/client';

class Query {
  static DATA_COURSE_PROFILE = `
    website
    picture_url
    instagram
    facebook
    email
    phone_number
    address
  `;

  static UPDATE_COURSE_PROFILE = gql`
    mutation Mutation($data: CourseProfileRead) {
      updateCourseProfile(data: $data) {
        code
        success
        data {
          ${Query.DATA_COURSE_PROFILE}
        }
      }
    }
  `;

  static GET_COURSE_PROFILE = gql`
    query GetCourseProfile {
      getCourseProfile {
        data {
          ${Query.DATA_COURSE_PROFILE}
        }
        success
        code
      }
    }
  `;
}

export default Query;
