import React from 'react';
import AccountsTabs from './AccountsTabs';
import RolePermissions from './RolePermissions';
import Tabs from '../atoms/Tabs';
import AppWrapper from '../templates/AppWrapper';
import './RoleAccess.less';

const RoleAccess = () => (
  <AppWrapper>
    <div id="spacer-role" />
    <Tabs dataTabs={dataTabs} noArrow={true} />
  </AppWrapper>
);

export default RoleAccess;

const dataTabs = [
  {
    label: 'Accounts',
    key: '0',
    content: <AccountsTabs />
  },
  {
    label: 'Roles & permissions',
    key: '1',
    content: <RolePermissions />
  },
];