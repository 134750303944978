import { Col, Row } from 'antd';
import Text from 'antd/lib/typography/Text';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';
import moment from 'moment';
import React, { useState, useMemo, useEffect, useImperativeHandle, forwardRef } from 'react';
import { useHistory } from 'react-router-dom';
import Pathname from '../../constants/Pathname';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';
// import 'moment/locale/id';
import './CalendarDailyView.less';

const DragAndDropCalendar = withDragAndDrop(Calendar);
// moment.locale('id');
const localizer = momentLocalizer(moment);

const CustomEvent = (props) => {
  const { event, title } = props;
  return (
    <div>
      <Text className={`title-event-${event.resourceId}`}>{title}</Text>
    </div>
  );
};

const CustomEventWrapper = (props) => {
  const { event } = props;
  return (
    <div {...props} className={event.resourceId === 1 ? 'time-event-1' : 'time-event-2'} />
  );
};

let countClickEvent = 0;

const CalendarDailyView = (props, ref) => {
  const { data, date } = props;
  const history = useHistory();

  const currentYear = moment(date, 'YYYY-MM-DD').year();
  const currentMonth = moment(date, 'YYYY-MM-DD').month();
  const currentDate = moment(date, 'YYYY-MM-DD').date();

  const resourceEvents = useMemo(() => {
    const tempResourceEvents = [];
    if (data?.length > 0) {
      data.forEach(item => {
        const year = moment(item.date, 'YYYY-MM-DD').year();
        const month = moment(item.date, 'YYYY-MM-DD').month();
        const date = moment(item.date, 'YYYY-MM-DD').date();

        if (item?.tee_times) {
          const startHour = moment(item.start_time, 'HH:mm:ss').hour();
          const startMinute = moment(item.start_time, 'HH:mm:ss').minute();
          const startSecond = moment(item.start_time, 'HH:mm:ss').second();
          const endHour = moment(item.end_time, 'HH:mm:ss').hour();
          const endMinute = moment(item.end_time, 'HH:mm:ss').minute();
          const endSecond = moment(item.end_time, 'HH:mm:ss').second();
          tempResourceEvents.push({
            id: item.id,
            title: item.tee_times.name,
            start: new Date(year, month, date, startHour, startMinute, startSecond),
            end: new Date(year, month, date, endHour, endMinute, endSecond),
            backgroundColor: item.tee_times.color,
            resourceId: 1,
          });
        }
        if (item?.rate_cards) {
          const startHour = moment(item.start_time, 'HH:mm:ss').hour();
          const startMinute = moment(item.start_time, 'HH:mm:ss').minute();
          const startSecond = moment(item.start_time, 'HH:mm:ss').second();
          const endHour = moment(item.end_time, 'HH:mm:ss').hour();
          const endMinute = moment(item.end_time, 'HH:mm:ss').minute();
          const endSecond = moment(item.end_time, 'HH:mm:ss').second();
          tempResourceEvents.push({
            id: item.id,
            title: item.rate_cards.name,
            start: new Date(year, month, date, startHour, startMinute, startSecond),
            end: new Date(year, month, date, endHour, endMinute, endSecond),
            backgroundColor: item.rate_cards.color,
            resourceId: 2,
          });
        }
      });
    }
    return tempResourceEvents;
  }, [data]);

  const [events, setEvents] = useState(resourceEvents);


  useImperativeHandle(ref, () => ({
    handleSubmit: () => {
      return events;
    }
  }));

  useEffect(() => {
    setEvents(resourceEvents);
  }, [resourceEvents]);

  const moveEvent = ({ event, start, end, resourceId, isAllDay: droppedOnAllDaySlot }) => {
    const isSameResourceId = events.filter(item => item.resourceId === resourceId && item.id === event.id)?.length > 0;
    if (isSameResourceId) {
      const idx = events.indexOf(event);
      let allDay = event.allDay;

      if (!event.allDay && droppedOnAllDaySlot) {
        allDay = true;
      } else if (event.allDay && !droppedOnAllDaySlot) {
        allDay = false;
      }

      const updatedEvent = { ...event, start, end, resourceId, allDay };
      const anotherEvent = events.filter(item => item.resourceId !== resourceId && item.id === event.id)?.[0];
      const idxAnotherEvent = events.indexOf(anotherEvent);
      const updatedAnotherEvent = { ...anotherEvent, start, end, resourceId: anotherEvent.resourceId, allDay };

      const nextEvents = [...events];
      nextEvents.splice(idx, 1, updatedEvent);
      nextEvents.splice(idxAnotherEvent, 1, updatedAnotherEvent);

      setEvents(nextEvents);
    }
  };

  const resizeEvent = ({ event, start, end }) => {
    const nextEvents = events.map(existingEvent => {
      return existingEvent.id == event.id
        ? { ...existingEvent, start, end }
        : existingEvent;
    });
    setEvents(nextEvents);
  };

  return (
    <div id="calendar-daily-view">
      <Row align="middle" className="column">
        <Col className="time">
          <Text>Time</Text>
        </Col>
        <Col className="tee-times">
          <Text>Tee times</Text>
        </Col>
        <Col className="rate-cards">
          <Text>Rate cards</Text>
        </Col>
      </Row>
      <DragAndDropCalendar
        selectable="ignoreEvents"
        localizer={localizer}
        events={events}
        onEventDrop={moveEvent}
        longPressThreshold={10}
        onSelectEvent={(event) => {
          countClickEvent++;
          if (countClickEvent === 2) {
            history.push(
              Pathname.CALENDAR + '/' +
              Pathname.CALENDAR_TEE_TIME_PARTICULAR_FORM +
              `?act=edit&id=${event.id}&date=${date}&redirect=calendar`,
              {
                background: true
              }
            );
            countClickEvent=0;
          }
        }}
        onSelectSlot={(slotInfo) => {
          const startTime = moment(slotInfo.start).startOf('hour').format('HH:mm:ss');
          const endTime = moment(slotInfo.start).startOf('hour').add(1, 'hours').format('HH:mm:ss');
          history.push(
            Pathname.CALENDAR + '/' +
            Pathname.CALENDAR_TEE_TIME_PARTICULAR_FORM +
            `?date=${date}&start-time=${startTime}&end-time=${endTime}`,
            {
              background: true
            }
          );
        }}
        resizable
        resources={resourceMap}
        min={new Date(currentYear, currentMonth, currentDate, 0, 0, 0)}
        resourceIdAccessor="resourceId"
        resourceTitleAccessor={null}
        onEventResize={resizeEvent}
        defaultView="day"
        step={1}
        timeslots={60}
        toolbar={false}
        showMultiDayTimes={true}
        defaultDate={new Date(currentYear, currentMonth, currentDate)}
        eventPropGetter={(event) => {
          const textColor = (hex) => {
            let r = 0; let g = 0; let b = 0; let a = 1;
            if (hex.length == 5) {
              r = '0x' + hex[1] + hex[1];
              g = '0x' + hex[2] + hex[2];
              b = '0x' + hex[3] + hex[3];
              a = '0x' + hex[4] + hex[4];
            } else if (hex.length == 9) {
              r = '0x' + hex[1] + hex[2];
              g = '0x' + hex[3] + hex[4];
              b = '0x' + hex[5] + hex[6];
              a = '0x' + hex[7] + hex[8];
            }
            a = +(a / 255).toFixed(3);
            let rgba = 'rgba(' + +r + ',' + +g + ',' + +b + ',' + a + ')';
            rgba = rgba.match(/\d+/g);
            if((rgba[0]*0.299)+(rgba[1]*0.587)+(rgba[2]*0.114)>186) {
              return 'black';
            } 
            return 'white';
          };
          const style = {
            backgroundColor: event.backgroundColor,
            padding: 8,
            borderRadius: 4,
            border: 'none',
            marginLeft: 4,
            color: textColor(event.backgroundColor)
          };
          return {
            style
          };
        }}
        components={{
          event: CustomEvent,
          eventWrapper: CustomEventWrapper,
          // timeSlotWrapper: CustomTimeSlotWrapper
        }}
      />
    </div>
  );
};

export default forwardRef(CalendarDailyView);

// const resourceEvents = [
//   {
//     id: 0,
//     title: 'Weekday tee time',
//     start: new Date(2018, 0, 29, 6, 0, 0),
//     end: new Date(2018, 0, 29, 13, 0, 0),
//     resourceId: 1,
//   },
//   {
//     id: 1,
//     title: 'Weekday rate card',
//     start: new Date(2018, 0, 29, 6, 0, 0),
//     end: new Date(2018, 0, 29, 13, 0, 0),
//     resourceId: 2,
//   },
// ];

const resourceMap = [
  { resourceId: 1, resourceTitle: 'Tee Times' },
  { resourceId: 2, resourceTitle: 'Rate Cards' },
];