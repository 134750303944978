import { Button } from 'antd';
import Text from 'antd/lib/typography/Text';
import React, { useState } from 'react';
import Icon from '@ant-design/icons';
import numeral from 'numeral';
import CardWithTitle from '../molecules/CardWithTitle';
import HorizontalCardInput from '../molecules/HorizontalCardInput';
import AppWrapper from '../templates/AppWrapper';
import ButtonCustom from '../atoms/Button';
import './AddOnsInclusions.less';
import SvgDelete from '../atoms/SvgDelete';
import Pathname from '../../constants/Pathname';
import { useHistory } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import QueryGql from '../../constants/QueryGql';
import Loading from '../atoms/Loading';

const AddOnsInclusions = () => {
  const history = useHistory();
  const [addOnIdsDeleting, setAddOnIdsDeleting] = useState([]);
  const [inclusionIdsDeleting, setInclusionIdsDeleting] = useState([]);

  const { 
    loading: loadingGetAllAddOns,
    data: dataGetAllAddOns
  } = useQuery(QueryGql.GET_ALL_ADD_ONS);
  const { 
    loading: loadingGetAllInclusions,
    data: dataGetAllInclusions
  } = useQuery(QueryGql.GET_ALL_INCLUSIONS);

  const [deleteAddOn] = useMutation(QueryGql.DESTROY_ADD_ON, {
    onCompleted: (response) => {
      const { id } = response.destroyAddOn?.data;
      setAddOnIdsDeleting(addOnIdsDeleting.filter(addOnId => addOnId != id));
    },
    onError: () => {
      setAddOnIdsDeleting([]);
    },
    update(cache, { data: { destroyAddOn }}) {
      cache.modify({
        fields: {
          getAllAddOns(existingAddOns) {
            return {
              ...existingAddOns,
              data: existingAddOns?.data.filter(item => !item.__ref.includes(destroyAddOn.data?.id))
            };
          }
        }
      });
    }
  });
  const [deleteInclusion] = useMutation(QueryGql.DESTROY_INCLUSION, {
    onCompleted: (response) => {
      const { id } = response.destroyInclusion?.data;
      setInclusionIdsDeleting(addOnIdsDeleting.filter(addOnId => addOnId != id));
    },
    onError: () => {
      setInclusionIdsDeleting([]);
    },
    update(cache, { data: { destroyInclusion }}) {
      cache.modify({
        fields: {
          getAllInclusions(existingInclusions) {
            return {
              ...existingInclusions,
              data: existingInclusions?.data.filter(item => !item.__ref.includes(destroyInclusion.data?.id))
            };
          }
        }
      });
    }
  });

  const handleDeleteAddOn = (id) => {
    setAddOnIdsDeleting([...addOnIdsDeleting, id]);
    deleteAddOn({
      variables: {
        destroyAddOnId: id
      }
    });
  };
  const handleDeleteInclusion = (id) => {
    setInclusionIdsDeleting([...addOnIdsDeleting, id]);
    deleteInclusion({
      variables: {
        destroyInclusionId: id
      }
    });
  };

  return (
    <AppWrapper>
      <div className="add-ons-inclusions-container">
        <CardWithTitle
          title="Add-ons"
          subtitle={'Add-ons are optional items which can be purchased by customers for additional\ncharge. Manage your add-ons which can be selected later during reservations,\nconfiguring promotions, and more.'}
        >
          <div className="add-ons-inclusions">
            {loadingGetAllAddOns ? (
              <Loading />
            ) : (
              <>
                {dataGetAllAddOns?.getAllAddOns?.data?.map((item, index) => {
                  if (index === 0) {
                    return (
                      <div key={item.id}>
                        <HorizontalCardInput
                          label="List of add-ons"
                          width={516}
                          left={(
                            <div className="time-based">
                              <Text className="value">{item.name}</Text>
                              <Text type="secondary">+{numeral(item.price).format('$0,0[.]00')}</Text>
                            </div>
                          )}
                          right={(
                            <div>
                              <Button
                                type="link"
                                onClick={() => history.push(
                                  Pathname.ADD_ONS_INCLUSIONS+'/'+
                                  Pathname.ADD_ONS_INCLUSIONS_ADD_ONS+
                                  '?act=edit&id='+item.id,
                                  {
                                    background: true
                                  }
                                )}
                              >
                                Edit
                              </Button>
                              <Button
                                onClick={() => handleDeleteAddOn(item.id)}
                                type="text"
                                icon={addOnIdsDeleting.findIndex(id => id === item.id) > -1 
                                  ? <Loading /> 
                                  : <Icon className="icon-delete"component={SvgDelete} />}
                              />
                            </div>
                          )}
                        />
                        <div className="spacer" />
                      </div>
                    );
                  }
                  return (
                    <div key={item.id}>
                      <HorizontalCardInput
                        // label=""
                        width={516}
                        left={(
                          <div className="time-based">
                            <Text className="value">{item.name}</Text>
                            <Text type="secondary">+{numeral(item.price).format('$0,0[.]00')}</Text>
                          </div>
                        )}
                        right={(
                          <div>
                            <Button
                              type="link"
                              onClick={() => history.push(
                                Pathname.ADD_ONS_INCLUSIONS+'/'+
                                Pathname.ADD_ONS_INCLUSIONS_ADD_ONS+
                                '?act=edit&id='+item.id,
                                {
                                  background: true
                                }
                              )}
                            >
                              Edit
                            </Button>
                            <Button
                              onClick={() => handleDeleteAddOn(item.id)}
                              type="text"
                              icon={addOnIdsDeleting.findIndex(id => id === item.id) > -1 
                                ? <Loading /> 
                                : <Icon className="icon-delete"component={SvgDelete} />}
                            />
                          </div>
                        )}
                      />
                      <div className="spacer" />
                    </div>
                  );
                })}
                <ButtonCustom
                  type="guide"
                  state="text"
                  size="large"
                  className="button-add-setting-time-based"
                  onClick={() => history.push(
                    Pathname.ADD_ONS_INCLUSIONS+'/'+
                    Pathname.ADD_ONS_INCLUSIONS_ADD_ONS+
                    '?act=add',
                    {
                      background: true
                    }
                  )}
                >
                + Add new add-on
                </ButtonCustom>
              </>
            )}
          </div>
        </CardWithTitle>
        <CardWithTitle
          title="Inclusions"
          subtitle={'Inclusions are items which are usually included in a pricing provided for\ncustomers without extra charge. Manage your inclusions which can be selected\nlater for configuring pricings and promotions.'}
          className="golf-cource-settings-card last"
        >
          <div className="add-ons-inclusions">
            {loadingGetAllInclusions ? (
              <Loading />
            ) : (
              <>
                {dataGetAllInclusions?.getAllInclusions?.data?.map((item, index) => {
                  if (index === 0) {
                    return (
                      <div key={item.id}>
                        <HorizontalCardInput
                          label="List of inclusions"
                          width={516}
                          left={<Text className="value">{item.name}</Text>}
                          right={(
                            <div>
                              <Button
                                type="link"
                                onClick={() => history.push(
                                  Pathname.ADD_ONS_INCLUSIONS+'/'+
                                  Pathname.ADD_ONS_INCLUSIONS_INCLUSIONS+
                                  '?act=edit&id='+item.id,
                                  {
                                    background: true
                                  }
                                )}
                              >
                              Edit
                              </Button>
                              <Button
                                onClick={() => handleDeleteInclusion(item.id)}
                                type="text"
                                icon={inclusionIdsDeleting.findIndex(id => id === item.id) > -1 
                                  ? <Loading /> 
                                  : <Icon className="icon-delete"component={SvgDelete} />}
                              />
                            </div>
                          )}
                        />
                        <div className="spacer" />
                      </div>
                    );
                  }
                  return (
                    <div key={item.id}>
                      <HorizontalCardInput
                        // label=""
                        width={516}
                        left={<Text className="value">{item.name}</Text>}
                        right={(
                          <div>
                            <Button
                              type="link"
                              onClick={() => history.push(
                                Pathname.ADD_ONS_INCLUSIONS+'/'+
                                Pathname.ADD_ONS_INCLUSIONS_INCLUSIONS+
                                '?act=edit&id='+item.id,
                                {
                                  background: true
                                }
                              )}
                            >
                            Edit
                            </Button>
                            <Button
                              onClick={() => handleDeleteInclusion(item.id)}
                              type="text"
                              icon={inclusionIdsDeleting.findIndex(id => id === item.id) > -1 
                                ? <Loading /> 
                                : <Icon className="icon-delete"component={SvgDelete} />}
                            />
                          </div>
                        )}
                      />
                      <div className="spacer" />
                    </div>
                  );
                })}
                <ButtonCustom
                  type="guide"
                  state="text"
                  size="large"
                  className="button-add-setting-time-based"
                  onClick={() => history.push(
                    Pathname.ADD_ONS_INCLUSIONS+'/'+
                    Pathname.ADD_ONS_INCLUSIONS_INCLUSIONS+
                    '?act=add',
                    {
                      background: true
                    }
                  )}
                >
                + Add new inclusion
                </ButtonCustom>
              </>
            )}
          </div>
        </CardWithTitle>
      </div>
    </AppWrapper>
  );
};

export default AddOnsInclusions;