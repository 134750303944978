import React, { useMemo } from 'react';
import Tabs from '../atoms/Tabs';
import CustomerDetailHistory from '../organisms/CustomerDetailHistory';
import CustomerDetailInformation from '../organisms/CustomerDetailInformation';

const CustomerDetail = ({ detail, id }) => {

  const histories = useMemo(() => [{
    number: 'IUBFVBO-94',
    courseNumber: 'Course No. 1 (18 Hole)',
    teeTime: '06:00 AM',
    date: 'Monday, 05/04/2021',
    status: 'paid',
    total: 'Rp. 1,650,000'
  }, {
    number: 'IUBFVBO-74',
    courseNumber: 'Course No. 1 (18 Hole)',
    teeTime: '06:00 AM',
    date: 'Monday, 05/04/2021',
    status: 'paid',
    total: 'Rp. 1,650,000'
  }, {
    number: 'IUBFVBO-84',
    courseNumber: 'Course No. 1 (18 Hole)',
    teeTime: '06:00 AM',
    date: 'Monday, 05/04/2021',
    status: 'partially_paid',
    total: 'Rp. 1,650,000'
  }],[]);

  const dataTabs = useMemo(() => [
    {
      label: 'Information',
      key: '0',
      content: <CustomerDetailInformation detail={detail} />
    },
    {
      label: 'History',
      key: '1',
      content: <CustomerDetailHistory id={id} histories={histories} />
    },
    // {
    //   label: 'Feedbacks',
    //   key: '2',
    // },
    // {
    //   label: 'House account',
    //   key: '3',
    // },
  ], [detail]);

  return (
    <div>
      <Tabs dataTabs={dataTabs} noArrow={true} />
    </div>
  );
};

export default CustomerDetail;
