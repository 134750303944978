import { Col, Divider, Row } from 'antd';
import Text from 'antd/lib/typography/Text';
import Title from 'antd/lib/typography/Title';
import React from 'react';
import Avatar from '../atoms/Avatar';
import Card from '../atoms/Card';
import ItemDetailText from '../molecules/ItemDetailText';
import './CustomerDetailInformation.less';

import moment from 'moment';

const CustomerDetailInformation = ({ detail }) => {

  return (
    <div id='customer-detail-information'>
      <Card className='section' bodyStyle={{ textAlign: 'center' }}>
        <Avatar img={detail.image_url} title={detail.full_name} className='avatar' />
      </Card>
      <Card className='section' bodyStyle={{ textAlign: 'left' }}>
        <Title level={4}>Basic information</Title>
        <Divider />
        <ItemDetailText label='Full name' value={detail.full_name} />
        <ItemDetailText label='Customer ID' value={detail.customer_id} />
        <ItemDetailText
          label='Phone number'
          value={'+' + (detail.prefix_phone_number || '') + (detail.phone_number || '')}
        />
        <ItemDetailText label='Email address' value={detail.email} />
      </Card>

      <Card className='section' bodyStyle={{ textAlign: 'left' }}>
        <Title level={4}>Customer type / membership</Title>
        <Divider />
        {detail?.subscription_memberships?.map(item => (
          <ItemDetailText
            key={item.id}
            label={`${item.is_active ? 'Current' : 'Previous'} type / membership`}
            value={
              <>
                <Row align='middle' justify='space-between'>
                  <Col>
                    <Text className='value-left'>{item.membership_name}</Text>
                  </Col>
                  <Col>
                    <Text className='value-right'>{moment(item.start_time).format('LL')} - {item.is_active ? 'Today' : moment(item.end_time).format('LL')}</Text>
                  </Col>
                </Row>
              </>
            }
          />
        ))}
      </Card>

      {/* <Card className='section' bodyStyle={{ textAlign: 'left' }}>
        <Title level={4}>Credit card information</Title>
        <Divider />
        <ItemDetailText label='Credit card number' value={detail.cc_number} />
        <Row gutter={32}>
          <Col>
            <ItemDetailText label='Expiry date' value={detail.cc_date} />
          </Col>
          <Col>
            <ItemDetailText label='CVV' value='***' />
          </Col>
        </Row>
        <ItemDetailText label='Cardholder name' value={detail.cc_cardholder_name} />
      </Card> */}
      <Card className='section' bodyStyle={{ textAlign: 'left' }}>
        <Title level={4}>Additional information</Title>
        <Divider />
        <ItemDetailText label='Birthday' value={detail.birthday ? moment(detail.birthday).format('LL') : ''} />
        <ItemDetailText
          label='Address'
          value={detail.address}
        />
        <ItemDetailText label='Nationality' value={detail.nationality} />
        <ItemDetailText label='Gender' value={detail?.gender === 'm' ? 'Male' : 'Female'} />
        <ItemDetailText label='Company' value={detail.company} />
      </Card>
    </div>
  );
};

export default CustomerDetailInformation;
