import React, { useMemo } from 'react';
import CustomerCheckInsList from '../organisms/CustomerCheckInsList';
import CustomerCheckInsCards from '../organisms/CustomerCheckInsCards';
import moment from 'moment';
import formatter from '../../utils/formatter';

export function rebuildCustomerCheckIns(data) {

  const items = [];

  data?.forEach(item => {
    const operators = item?.reservations?.reservation_operators?.filter(item => item.step === 'checked_in') || [];
    const checkInBy = operators[operators.length - 1] || {};
    
    items.push({
      ...item,
      id: item.reservations.id,
      checkInBy : checkInBy?.full_name || '',
      name: item.customer.full_name,
      reservationId: `${item.reservations.prefix}-${item.reservations.number}`,
      time: item.reservations.checkin_at ? moment(item.reservations.checkin_at).format('lll') : '',
      total: formatter.currency(item.amount),
      status:  item.is_void ? 'void' : item.status === 'paid' ? 'paid' : 'unpaid',
    });
  });
  
  return items;
}

export default function CustomerCheckinsTemplate({ dataReport, isLoading , isPrint }) {

  const dataCustomer = useMemo(() => {
    const items = rebuildCustomerCheckIns(dataReport?.customers);
    
    return items;
  }, [dataReport]);

  const summary = useMemo(() => dataReport?.summary || {}, [dataReport]);

  return (
    <div>
      <div id="check-ins-cards">
        <CustomerCheckInsCards
          isPrint={isPrint}
          totalCheckIns={summary.total_checkins}
          totalPaidCheckIns={summary.total_paid}
          totalUnpaidCheckIns={summary.total_unpaid}
        />
      </div>
      <div style={{ marginTop: 32 }}>
        <CustomerCheckInsList
          data={dataCustomer}
          loading={isLoading}
        />
      </div>
    </div>
  );
}