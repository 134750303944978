import React, { useMemo } from 'react';
import Modal from '../../organisms/Modal';
import Pathname from '../../../constants/Pathname';
import { useHistory, useLocation } from 'react-router-dom';
import { useFormik } from 'formik';
import { useQuery } from '@apollo/client';
import ListCustomer from './ListCustomer';
import Text from 'antd/lib/typography/Text';
import QueryGql from 'constants/QueryGql';
import extractQuery from 'utils/useQuery';
import formatter from 'utils/formatter';
import { Radio, Space } from 'antd';
import Divider from 'components/atoms/Divider';

const reasons = [
  'Wrong entry by operator',
  'Customer was sick',
  'Customer urgent matter',
  'Bad weather',
  'Other reasons'
];

const CancelReservation = () => {
  const history = useHistory();
  const location = useLocation();
  const queryString = extractQuery(location.search);
  const id = queryString.get('id');
  const groupId = queryString.get('groupId');

  const { data: dataGetReservation } = useQuery(QueryGql.GET_RESERVATION, {
    variables: {
      getReservationId: id,
    },
    skip: !id,
    fetchPolicy: 'cache-and-network',
  });

  const data = dataGetReservation?.getReservation?.data || {};

  const { data: dataGetReservationGroup } = useQuery(QueryGql.GET_RESERVATION_GROUP, {
    variables: {
      getReservationGroupId: groupId,
    },
    skip: !groupId,
    fetchPolicy: 'cache-and-network',
  });

  const dataGroup = dataGetReservationGroup?.getReservationGroup?.data || {};


  const customers = useMemo(() => {
    const results = [];
    
    data?.reservation_customers?.filter(item => item.status === 'reserved').forEach(item => {
      results.push({
        id: item.customer_id,
        checked: false,
        name: item.customer?.full_name,
        price: formatter.currency(item.amount),
      });
    });

    dataGroup?.reservations?.forEach(reservation => {
      reservation?.reservation_customers?.filter(item => item.status === 'reserved').forEach(item => {
        results.push({
          id: item.customer_id,
          checked: false,
          name: item.customer?.full_name,
          price: formatter.currency(item.amount),
        });
      });
    });

    return results;
  }, [dataGroup, data]);
    
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      customers,
      reason: 'Wrong entry by operator'
    },
  });

  const customerSelected = formik.values.customers.filter(item => item.checked);
  const disableButton = customerSelected.length === 0;

  return (
    <Modal
      title='Cancel reservation?'
      width={600}
      rightFooterButton={{
        label: 'Cancel reservation',
        type: 'danger',
        disabled: disableButton,
        onClick: () =>
          history.push(
            Pathname.TEE_SHEET +
              '/' +
              Pathname.COLLECT_CANCELLATION_FEE + `?${id ? `id=${id}` : ''}${groupId ? `groupId=${groupId}` : ''}&customers=${customerSelected.map(item => item.id).join(',')}&reason=${formik.values.reason}`,
            {
              background: true,
            },
          ),
      }}
      leftFooterButton={{
        label: 'Go back',
        type: 'link',
      }}>
      <Text style={{ fontSize: '14px' }}>
        Are you sure to cancel this reservation? Any information you put inside will be deleted as
        well. To continue with the cancellation, please select at least a customer to be cancelled
        and a reason as well.
      </Text>
      <ListCustomer customers={formik.values.customers} setFieldValue={formik.setFieldValue} />

      <div style={{ marginTop: 20, marginBottom: 8 }}>
        <Text type='secondary' strong>
          Select cancellation reason
        </Text>
      </div>
      <Divider />
      <Radio.Group
        style={{ marginTop: 12 }}
        name='reason'
        onChange={formik.handleChange}
        value={formik.values.reason}>
        <Space direction='vertical'>
          {reasons.map(item => (
            <Radio key={item} value={item}>
              {item}
            </Radio>
          ))}
        </Space>
      </Radio.Group>
    </Modal>
  );
};

export default CancelReservation;
