import React from 'react';
import PropTypes from 'prop-types';
import Icon from '@ant-design/icons';
import { TimePicker as AntdTimePicker } from 'antd';
import SvgDownArrow from '../atoms/SvgDownArrow';
import './TimePicker.less';

const TimePicker = (props) => {
  const { onSelect, onChange } = props;
  return (
    <AntdTimePicker
      {...props}
      format="h:mm A"
      className="time-picker-naomi"
      onSelect={onSelect}
      onBlur={onChange}
      suffixIcon={<Icon component={SvgDownArrow} className="down-arrow" />}
    />
  );
};

export default TimePicker;

TimePicker.propTypes = {
  onSelect: PropTypes.func,
  onChange: PropTypes.func,
};

TimePicker.defaultProps = {
  size: 'large'
};
