import { Card, List } from 'antd';
import React, { useCallback, useState, useMemo } from 'react';
import Text from 'antd/lib/typography/Text';
import Icon from '@ant-design/icons';
import { useLocation, useHistory } from 'react-router-dom';
import extractQuery from '../../utils/useQuery';
import { useQuery } from '@apollo/client';

import SvgRightArrow from '../atoms/SvgRightArrow';
import SvgSearch from '../atoms/SvgSearch';
import SvgClose from '../atoms/SvgClose';
import Modal from '../organisms/Modal';
import './TypeMembershipsMemberList.less';
import InputText from '../atoms/InputText';
import Pathname from '../../constants/Pathname';
import SvgGuest from '../atoms/SvgGuest';
import QueryGql from 'constants/QueryGql';
import _ from 'lodash';

// const data = [
//   {
//     subTitle: 'A',
//     data: [
//       {
//         title: 'Guest #1',
//       },
//       {
//         title: 'Guest #2',
//       }
//     ]
//   },
//   {
//     subTitle: 'B',
//     data: [
//       {
//         title: 'Guest #1',
//       },
//       {
//         title: 'Guest #2',
//       }
//     ]
//   },
//   {
//     subTitle: 'C',
//     data: [
//       {
//         title: 'Guest #1',
//       },
//       {
//         title: 'Guest #2',
//       },
//       {
//         title: 'Guest #3',
//       }
//     ]
//   },
// ];

const TypeMembershipsMemberList = () => {
  const history = useHistory();
  const location = useLocation();
  const queryString = extractQuery(location.search);
  const id = queryString.get('id');
  const act = queryString.get('act');
  const [search, setSearch] = useState('');

  const { data } = useQuery(QueryGql.SEARCH_CUSTOMER, {
    fetchPolicy: 'cache-and-network',
    variables: {
      searchCustomerSearch: search,
      filter: {
        customer_types: [id],
      },
    },
  });

  const dataCustomer = useMemo(() => {
    const items =
      data?.searchCustomer?.data?.map(item => ({
        ...item,
      })) || [];

    const sort = _.orderBy(items, ['full_name'], ['asc']);
    return _(sort)
      .groupBy(o => o.full_name?.[0].toUpperCase())
      .map((data, letter) => ({ letter, data }))
      .value();
  }, [data]);


  const handleAdded = useCallback(() => {
    history?.goBack();
  }, []);

  return (
    <Modal
      title={act}
      leftFooterButton={{
        label:  `${data?.searchCustomer?.data?.length || ''} customers`,
        type: 'text',
        state: 'hint',
      }}
      rightFooterButton={{
        label: 'Close',
        onClick: handleAdded,
      }}
      bodyStyle={{ textAlign: 'right' }}
      width={540}>
      <div className='member-list'>
        <InputText
          name='name'
          placeholder='Search for customer'
          onChange={e => setSearch(e.target.value)}
          value={search}
          prefix={<Icon component={SvgSearch} style={{ fontSize: 14 }} />}
          suffix={<Icon component={SvgClose} onClick={() => setSearch('')} style={{ fontSize: 14 }} />}
        />
        {dataCustomer?.map(value => {
          return (
            <>
              <div className='wrapper-title'>
                <Text className='initial-title'>{value.letter}</Text>
              </div>
              <List
                itemLayout='horizontal'
                dataSource={value.data}
                renderItem={item => (
                  <Card
                    className='list-card-item'
                    onClick={() =>
                      history.push(
                        Pathname.TYPES_MEMBERSHIP +
                          '/' +
                          Pathname.CUSTOMER_LIST_DETAIL +
                          `?id=${item.id}`,
                        {
                          background: true,
                        },
                      )
                    }>
                    <List.Item
                      actions={[
                        <Icon
                          component={SvgRightArrow}
                          key='svg-right-arrow'
                          style={{ fontSize: 14 }}
                        />,
                      ]}>
                      <List.Item.Meta
                        avatar={
                          item.image_url ? (
                            <img
                              style={{ maxHeight: '30px', maxWidth: '30px', borderRadius: '50%' }}
                              alt='Photo'
                              src={item.image_url}
                            />
                          ) : (
                            <div style={{ color: '#8F8F8F' }}>
                              <SvgGuest />
                            </div>
                          )
                        }
                        title={<Text>{item.full_name}</Text>}
                        description={'#' + item.customer_id}
                      />
                    </List.Item>
                  </Card>
                )}
              />
            </>
          );
        })}
      </div>
    </Modal>
  );
};

export default TypeMembershipsMemberList;
