import React, { useMemo } from 'react';
import BillingTableContent from './BillingTableContent';
import formatter from '../../../utils/formatter';
import moment from 'moment';

export function rebuildBillingList(data) {
  const items = [];

  data?.forEach(item => {
    items.push({
      ...item,
      statementId: item.billing_statement_id,
      status: item.status,
      forTheMonth: moment(item.date).format('MMMM YYYY'),
      amount: formatter.currency(item.amount),
      statement: 'View Statement',
    });
  });

  return items;
}

export default function BillingStatementsTemplate({ dataReport, isLoading }) {
  const dataCustomer = useMemo(() => {
    const items = rebuildBillingList(dataReport);

    return items;
  }, [dataReport]);

  return (
    <div>
      <div style={{ marginTop: 32 }}>
        <BillingTableContent data={dataCustomer} loading={isLoading} />
      </div>
    </div>
  );
}