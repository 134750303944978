import Icon from '@ant-design/icons';
import { Collapse, DatePicker } from 'antd';
import React, { useMemo, forwardRef, useImperativeHandle, useRef } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Text from 'antd/lib/typography/Text';
import Card from '../atoms/Card';
import SvgFace from '../atoms/SvgFace';
import HorizontalSelectOptions from '../molecules/HorizontalSelectOptions';
import HorizontalTextInput from '../molecules/HorizontalTextInput';
import './CustomerForm.less';
import countries from '../../constants/countries.json';
import provinces from '../../constants/provinces.json';
import cities from '../../constants/cities.json';
import countriesNationality from '../../constants/countriesNationality.json';
import HorizontalTextInputArea from '../molecules/HorizontalTextInputArea';
import moment from 'moment';
import QueryGql from 'constants/QueryGql';
import { useQuery } from '@apollo/client';

const { Panel } = Collapse;

moment.updateLocale('en', {
  weekdaysMin: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
});

const CustomerSchemaValidations = Yup.object().shape({
  fullName: Yup.string().required('Required'),
  customerId: Yup.string().required('Required'),
  phoneNumber: Yup.string().required('Required'),
  email: Yup.string().email('Invalid email').required('Required'),
});

const CustomerForm = (props, ref) => {
  const { setPhoto, photo, initialValues } = props;
  const refFormik = useRef();

  const { data: allMemberships } = useQuery(QueryGql.GET_MEMBERSHIPS, {
    fetchPolicy: 'cache-and-network'
  });

  const customerTypes = useMemo(
    () =>
      allMemberships?.getAllMembership?.data?.map(v => ({
        value: v.id,
        label: v.name,
      })),
    [allMemberships],
  );

  const formatingCountriesDialCode = useMemo(
    () =>
      countries.map((country, index) => ({
        key: `${country?.dialCode}${index}`,
        value: country?.dialCode,
        label: `${country?.name} (${country?.dialCode})`,
      })),
    [],
  );

  const formatingCountriesNationality = useMemo(
    () =>
      countriesNationality.map(nationality => ({
        key: nationality?.key,
        value: nationality?.num_code,
        label: nationality?.nationality,
      })),
    [],
  );

  const formatingCountries = useMemo(
    () =>
      countries.map(country => ({
        key: country?.isoCode,
        value: country?.isoCode,
        label: country?.name,
      })),
    [],
  );

  const formatingProvinces = useMemo(
    () =>
      provinces.map(province => ({
        key: province?.key_id,
        value: province?.id,
        label: province?.name,
      })),
    [],
  );

  const formatingCities = useMemo(
    () =>
      cities.map(city => ({
        key: city?.key_id,
        value: city?.id,
        label: city?.name,
        province_id: city?.province_id,
      })),
    [],
  );

  const genders = useMemo(
    () => [
      {
        key: 'male',
        value: 'm',
        label: 'Male',
      },
      {
        key: 'female',
        value: 'f',
        label: 'Female',
      },
      {
        key: 'others',
        value: 'o',
        label: 'Others',
      },
    ],
    [],
  );

  useImperativeHandle(ref, () => ({
    handleSubmit: () => {
      refFormik?.current?.handleSubmit();
      return refFormik.current.values;
    },
  }));

  return (
    <Formik
      innerRef={refFormik}
      enableReinitialize
      initialValues={ initialValues || {
        prefix_phone_number: '+62',
        country: 'ID',
        nationality: '360',
        gender: 'm',
      }}
      validationSchema={CustomerSchemaValidations}>
      {({ handleSubmit, handleChange, values }) => {
        const countriesByProvince = formatingCities.filter(
          city => city.province_id === values?.province,
        );
        return (
          <form id='customer-form' onSubmit={handleSubmit}>
            <Card bodyStyle={{ textAlign: 'center' }}>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                {(photo || values?.image_url) ? (
                  <img src={photo ? URL.createObjectURL(photo) : values?.image_url} alt='image preview' className='user-icon' />
                ) : (
                  <Icon component={SvgFace} className='user-icon' />
                )}

                <label style={{ cursor: 'pointer' }}>
                  <input
                    type='file'
                    style={{ display: 'none' }}
                    onChange={e => setPhoto(e.target.files[0])}
                  />
                  <Text className='btn-upload'>Choose image</Text>
                </label>
              </div>
            </Card>

            <Card className='section-form'>
              <HorizontalTextInput
                layout='inline'
                name='full_name'
                label='Full name'
                value={values?.full_name}
                onChange={handleChange('full_name')}
                style={{ width: 256 }}
              />

              <div className='spacer' />

              <HorizontalTextInput
                layout='inline'
                name='customer_id'
                label='Customer ID'
                placeholder='#00001'
                value={values?.customer_id}
                onChange={handleChange('customer_id')}
                style={{ width: 256 }}
              />

              <div className='spacer' />

              <HorizontalSelectOptions
                layout='inline'
                name='prefix_phone_number'
                label='Phone number'
                options={formatingCountriesDialCode}
                value={values?.prefix_phone_number}
                onChange={handleChange('prefix_phone_number')}
                showSearch
                placeholder='Select dial code'
                style={{ width: 256 }}
              />

              <div className='spacer' />

              <HorizontalTextInput
                layout='inline'
                name='phone_number'
                prefix={<Text type='secondary'>{values?.prefix_phone_number}</Text>}
                value={values?.phone_number}
                onChange={handleChange('phone_number')}
                style={{ width: 256 }}
              />

              <div className='spacer' />

              <HorizontalTextInput
                layout='inline'
                name='email'
                label='Email Address'
                value={values?.email}
                onChange={handleChange('email')}
                style={{ width: 256 }}
              />
            </Card>

            <Card className='section-form'>
              <HorizontalSelectOptions
                layout='inline'
                name='membership_id'
                label='Default customer type'
                options={customerTypes}
                value={values?.membership_id}
                onChange={handleChange('membership_id')}
                showSearch
                placeholder='Select customer type'
                style={{ width: 271 }}
              />
            </Card>

            <Collapse ghost expandIconPosition='right' style={{ marginTop: 4 }}>
              <Panel
                header={
                  <div style={{ color: '#8F8F8F', fontWeight: 700, flex: 'auto' }}>
                    Additional information
                  </div>
                }
                key='1'
                showArrow>
                <Card className='section-form'>
                  <HorizontalTextInput
                    layout='inline'
                    label='Birthday'
                    right={
                      <DatePicker
                        size='large'
                        className='date-picker'
                        name='birthday'
                        value={values.birthday ? moment(values.birthday, 'YYYY-MM-DD') : ''}
                        onChange={value => {
                          handleChange('birthday')(moment(value).format('YYYY-MM-DD'));
                        }}
                        style={{ width: 256 }}
                      />
                    }
                  />

                  <div className='spacer' />

                  <HorizontalSelectOptions
                    layout='inline'
                    name='country'
                    label='Address'
                    options={formatingCountries}
                    value={values?.country}
                    onChange={handleChange('country')}
                    placeholder='Select country'
                    style={{ width: 256 }}
                  />

                  <div className='spacer' />

                  {values?.country === 'ID' && (
                    <>
                      <HorizontalSelectOptions
                        layout='inline'
                        name='province'
                        options={formatingProvinces}
                        value={values?.province}
                        onChange={handleChange('province')}
                        // showSearch
                        placeholder='Select province'
                        style={{ width: 256 }}
                      />

                      <div className='spacer' />

                      <HorizontalSelectOptions
                        layout='inline'
                        name='city'
                        options={countriesByProvince}
                        value={values?.city}
                        onChange={handleChange('city')}
                        // showSearch
                        disabled={!values?.province}
                        placeholder='Select city'
                        style={{ width: 256 }}
                      />
                    </>
                  )}

                  <div className='spacer' />

                  <HorizontalTextInputArea
                    rows={20}
                    placeholder='Enter address'
                    name='address'
                    onChange={handleChange('address')}
                    value={values?.address}
                    style={{ width: 256, marginLeft: 'auto', display: 'block' }}
                  />

                  <div className='spacer' />

                  <HorizontalSelectOptions
                    layout='inline'
                    name='nationality'
                    label='Nationality'
                    options={formatingCountriesNationality}
                    value={values?.nationality}
                    onChange={handleChange('nationality')}
                    // showSearch
                    placeholder='Select nationality'
                    style={{ width: 256 }}
                  />

                  <div className='spacer' />

                  <HorizontalSelectOptions
                    layout='inline'
                    name='gender'
                    label='Gender'
                    options={genders}
                    value={values?.gender}
                    onChange={handleChange('gender')}
                    placeholder='Select gender'
                    style={{ width: 256 }}
                  />

                  <div className='spacer' />

                  <HorizontalTextInput
                    layout='inline'
                    name='company'
                    label='Company'
                    value={values?.company}
                    onChange={handleChange('company')}
                    style={{ width: 256 }}
                  />
                </Card>
              </Panel>
            </Collapse>
          </form>
        );
      }}
    </Formik>
  );
};

export default forwardRef(CustomerForm);
