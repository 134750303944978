import { useQuery } from '@apollo/client';
import Query from 'components/pages/CourseProfile/Query';
import QueryGql from 'constants/QueryGql';

export default function useCourseProfile () {
  const { data: getCourseProfile } = useQuery(Query.GET_COURSE_PROFILE);
  const courseProfile = getCourseProfile?.getCourseProfile?.data || {};
  const { data: dataGetClubName } = useQuery(QueryGql.GET_CLUB_NAME);
  const clubName = dataGetClubName?.getClubName?.data?.value || '';

  return {
    courseProfile,
    clubName
  };
}