import numeral from 'numeral';

const currency = (value) => {
  const currencyFormat = '$0,0[.]00';

  return numeral(value).format(currencyFormat);
};

function roundToTwoDigitsAfterComma(floatNumber) {
  return parseFloat((Math.round(floatNumber * 100) / 100).toFixed(2));
}

const formatter = {
  currency,
  roundToTwoDigitsAfterComma
};

export default formatter;