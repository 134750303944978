import { useQuery } from '@apollo/client';
import { useMemo } from 'react';
import QueryGql from '../constants/QueryGql';

export default function useGetCourses () {

  const { data: dataGetAllCourses } = useQuery(
    QueryGql.GET_ALL_COURSES,
  );
  const courses = dataGetAllCourses?.getAllCourses.data || [];
  const optionsCourses = useMemo(() => {
    const items = [{ key: 'all', value: 'all', label: 'All golf courses' }];

    courses.forEach(item => {
      items.push({
        key: item.id,
        value: item.id,
        label: `${item.name} (${item.holes})`
      });
    });
    return items;
  }, [courses]);

  return {
    optionsCourses,
    courses
  };
}