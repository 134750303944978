import React from 'react';
import Icon from '@ant-design/icons';
import SvgPromotions from '../components/atoms/SvgPromotions';
import SvgCustomer from '../components/atoms/SvgCustomer';
import SvgSettings from '../components/atoms/SvgSettings';
import SvgReports from '../components/atoms/SvgReports';
import SvgTeeSheet from '../components/atoms/SvgTeeSheet';
import SvgTeeTimes from '../components/atoms/SvgTeeTimes';
import SvgCalendar from '../components/atoms/SvgCalendar';
import SvgRateCards from '../components/atoms/SvgRateCards';
import SvgHelp from '../components/atoms/SvgHelp';

export default (feature) => {
  switch (feature) {
    case 'promotions':
      return <Icon component={SvgPromotions} />;
    case 'customers':
      return <Icon component={SvgCustomer} />;
    case 'settings':
      return <Icon component={SvgSettings} />;
    case 'reports':
      return <Icon component={SvgReports} />;
    case 'tee_sheet':
      return <Icon component={SvgTeeSheet} />;
    case 'tee_times':
      return <Icon component={SvgTeeTimes} />;
    case 'calendar':
      return <Icon component={SvgCalendar} />;
    case 'rate_cards':
      return <Icon component={SvgRateCards} />;
    default:
      return <Icon component={SvgHelp} />;
  }
};