import { Calendar, Col, Row, Checkbox } from 'antd';
import Text from 'antd/lib/typography/Text';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useMemo, useState, useEffect } from 'react';
import IconButton from '../atoms/IconButton';
import SvgDownArrow from '../atoms/SvgDownArrow';
import SvgUpArrow from '../atoms/SvgUpArrow';
import './MultipleSelectDate.less';

const MultipleSelectDate = (props) => {
  const { onChange, initialValues } = props;
  const [valueCalendar, setValueCalendar] = useState();
  const [values, setValues] = useState(initialValues);

  useEffect(() => {
    onChange(values);
  }, [values]);

  const daysInMonth = useMemo(() => {
    const monthDate = moment(moment(valueCalendar).format('YYYY-MM'), 'YYYY-MM');
    return monthDate.daysInMonth();
  }, [valueCalendar]);

  moment.updateLocale('en', {
    weekdaysMin : ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT']
  });

  const headerRender = ({ value, onChange }) => {
    if (!valueCalendar || !moment(moment(value).format('YYYY-MM-DD')).isSame(moment(valueCalendar).format('YYYY-MM-DD'), 'month')) {
      setValueCalendar(value);
    }
    return (
      <div id="header-multiple-select-date">
        <Row className="header" align="middle" justify="space-between">
          <Col>
            <Row align="middle" gutter={8}>
              <Col><Text className="title" level={4}>{moment(value).format('MMMM YYYY')}</Text></Col>
              <Col><IconButton onClick={() => onChange(moment(value).add(1, 'M'))} iconSvg={SvgUpArrow} /></Col>
              <Col><IconButton onClick={() => onChange(moment(value).subtract(1, 'M'))} iconSvg={SvgDownArrow} /></Col>
            </Row>
          </Col>
          <Col>
            <Checkbox
              checked={daysInMonth === values.filter(value => value.includes(moment(valueCalendar).format('YYYY-MM'))).length}
              skipGroup
              onChange={(e) => {
                if (e.target.checked === true) {
                  getAllDaysOfMonth();
                } else {
                  setValues([]);
                }
              }}
            >
              Select all days
            </Checkbox>
          </Col>
        </Row>
      </div>
    );
  };

  const dateCellRender = (date) => {
    const value = moment(date).format('YYYY-MM-DD');
    if (!valueCalendar || !moment(value).isSame(moment(valueCalendar).format('YYYY-MM-DD'), 'month')) {
      return null;
    }
    return (
      <div id="content-multiple-select-date" className="date-cell">
        <Checkbox value={value} />
      </div>
    );
  };

  const getAllDaysOfMonth = () => {
    let days = daysInMonth;
    const dates = [];
    while(days) { 
      let current = moment().date(days);
      dates.push(current.format('YYYY-MM-DD'));
      days--;
    }
    setValues(dates);
  };
  
  const getDaysOfMonth = (day) => {
    const dates = values;
    let days = moment(valueCalendar)
      .startOf('month')
      .day(day);
    if (days.date() > 7) {days.add(7,'d');}
    let month = days.month();
    while(month === days.month()){
      const date = moment(days).format('YYYY-MM-DD');
      dates.push(date);
      days.add(7,'d');
    }
    setValues(dates.filter((item, index) => dates.indexOf(item) === index));
  };

  return (
    <div id="multiple-select-date">
      <div className="select-all-day">
        <Row justify="space-around">
          <Col>
            <IconButton size="small" onClick={() => getDaysOfMonth(0)} iconSvg={SvgDownArrow} iconColor="primary" />
          </Col>
          <Col>
            <IconButton size="small" onClick={() => getDaysOfMonth(1)} iconSvg={SvgDownArrow} iconColor="primary" />
          </Col>
          <Col>
            <IconButton size="small" onClick={() => getDaysOfMonth(2)} iconSvg={SvgDownArrow} iconColor="primary" />
          </Col>
          <Col>
            <IconButton size="small" onClick={() => getDaysOfMonth(3)} iconSvg={SvgDownArrow} iconColor="primary" />
          </Col>
          <Col>
            <IconButton size="small" onClick={() => getDaysOfMonth(4)} iconSvg={SvgDownArrow} iconColor="primary" />
          </Col>
          <Col>
            <IconButton size="small" onClick={() => getDaysOfMonth(5)} iconSvg={SvgDownArrow} iconColor="primary" />
          </Col>
          <Col>
            <IconButton size="small" onClick={() => getDaysOfMonth(6)} iconSvg={SvgDownArrow} iconColor="primary" />
          </Col>
        </Row>
      </div>
      <Checkbox.Group
        style={{ width: '100%' }}
        value={values}
        onChange={(e) => {
          setValues(e);
        }}
      >
        <Calendar
          headerRender={headerRender}
          dateCellRender={dateCellRender}
        />
      </Checkbox.Group>
    </div>
  );
};

export default MultipleSelectDate;

MultipleSelectDate.propTypes = {
  onChange: PropTypes.func,
  initialValues: PropTypes.array
};

MultipleSelectDate.defaultProps = {
  initialValues: []
};