/* eslint-disable max-len */
import React from 'react';

function SvgPromotions() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="none"
      viewBox="0 0 14 10"
    >
      <path
        fill="currentColor"
        d="M5 3.667a.667.667 0 00-.667.666v1.334a.667.667 0 101.334 0V4.333A.667.667 0 005 3.667zm8 .666a.666.666 0 00.667-.666V1A.666.666 0 0013 .333H1A.667.667 0 00.333 1v2.667A.667.667 0 001 4.333a.667.667 0 010 1.334.667.667 0 00-.667.666V9A.667.667 0 001 9.667h12A.666.666 0 0013.667 9V6.333A.666.666 0 0013 5.667a.667.667 0 010-1.334zm-.667-1.213a2 2 0 000 3.76v1.453H5.667a.667.667 0 00-1.334 0H1.667V6.88a2 2 0 000-3.76V1.667h2.666a.667.667 0 101.334 0h6.666V3.12z"
      />
    </svg>
  );
}

export default SvgPromotions;
