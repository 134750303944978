import { Col, Divider, Input, List, Row } from 'antd';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import numeral from 'numeral';
import Avatar from '../atoms/Avatar';
import './TeeSheetCustomerCheckInItem.less';
import ButtonStatusReservation from '../atoms/ButtonStatusReservation';

const TeeSheetCustomerCheckInItem = (props) => {
  const { customer, reservations, onChangeStatus, status : statusProps, bagId : bagIdProps } = props;
  const inputRef = useRef(null);
  const [ status, setStatus ] = useState(statusProps);
  const [ bagId, setBagId ] = useState(bagIdProps);
  
  useEffect(() => {
    onChangeStatus({
      status,
      default_status: statusProps,
      bag_id: bagId
    });
  }, [status, bagId]);

  const handleClickButtonStatus = useCallback(() => {
    if (status === 'reserved') {
      setTimeout(() => {
        inputRef.current.focus({
          cursor: 'all',
        });
      }, 0);
      setStatus('checked_in');
    }
    if (status === 'checked_in') {
      setStatus('reserved');
    }
  }, [status, inputRef]);

  return (
    <div id="tee-sheet-item-customer-checkin">
      <Row justify="space-between" align="middle" wrap={false}>
        <Col xs={16}>
          <List.Item>
            <List.Item.Meta
              avatar={<Avatar title={customer.name} style={{marginTop: 2}} />}
              title={customer.name}
            />
          </List.Item>
        </Col>
        <Col>
          <Input ref={inputRef} value={bagId} onChange={e => setBagId(e.target.value)} placeholder="Bag tag ID" className="button-tag-id" />
        </Col>
      </Row>
      <Divider style={{ margin: '4px 0px' }} />
      {reservations?.map((reservation, index) => (
        <List.Item key={index.toString()}>
          <List.Item.Meta
            title={reservation.name}
            description={numeral(reservation.price).format('$0,0[.]00')}
          />
          <ButtonStatusReservation onClickStatus={handleClickButtonStatus} status={status} />
        </List.Item>
      ))}
    </div>
  );
};

export default TeeSheetCustomerCheckInItem;

TeeSheetCustomerCheckInItem.proptypes = {
  customer: PropTypes.shape({
    name: PropTypes.string
  }).isRequired,
  reservations: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    price: PropTypes.string,
    status: PropTypes.string
  })).isRequired,
  onChangeStatus: PropTypes.func
};