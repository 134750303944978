import React from 'react';
import AppWrapper from '../../templates/AppWrapper';
import BillingData from './BillingData';

const BillingStatements = () => (
  <AppWrapper>
    <BillingData />
  </AppWrapper>
);

export default BillingStatements;