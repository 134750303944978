import { useQuery } from '@apollo/client';
import React, { useMemo, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Pathname from '../../constants/Pathname';
import QueryGql from '../../constants/QueryGql';
import Modal from '../organisms/Modal';
import ReservationDetailTemplate from '../templates/ReservationDetail';
import extractQuery from '../../utils/useQuery';
import TeeSheetCustomersCheckInDetailTemplate from '../templates/TeeSheetCustomersCheckInDetail';
import { useReactToPrint } from 'react-to-print';

const ReservationDetail = () => {
  const history = useHistory();
  const location = useLocation();
  const queryString = extractQuery(location.search);
  const id = queryString.get('id');
  const customerId = queryString.get('customerId');
  const from = queryString.get('from');

  const printComponentRef = useRef();

  const { data: dataGetReservation } = useQuery(
    QueryGql.GET_RESERVATION,
    {
      variables: {
        getReservationId: id
      },
      fetchPolicy: 'network-only'
    }
  );

  const data = dataGetReservation?.getReservation?.data || {};

  const total = useMemo(() => dataGetReservation?.getReservation?.data?.total_amount || 0, [dataGetReservation]);
  const status = useMemo(() => dataGetReservation?.getReservation?.data?.payment_status || 0, [dataGetReservation]);
  const operators = useMemo(() => dataGetReservation?.getReservation?.data?.reservation_operators || [], [dataGetReservation]);
  const reservation = useMemo(() => {
    if (dataGetReservation?.getReservation?.data) {
      const reservation = dataGetReservation.getReservation.data;
      return dataGetReservation.getReservation.data.reservation_customers.filter(item => item.status === 'paid').map(item => {
        const reservations = [
          {
            name: `${reservation.tee_times.name} - ${item.membership_name}`,
            price: item.membership_price,
            status: item.status === 'paid' ? 'paid' : 'unpaid'
          },
          ...item.add_ons.map(add_on => ({
            name: add_on.name,
            price: add_on.price,
            status: item.status === 'paid' ? 'paid' : 'unpaid'
          }))
        ];

        if (item.raincheck?.code) {
          reservations.push({
            name: 'Raincheck',
            price: - item.raincheck.amount,
            status: item.status === 'paid' ? 'paid' : 'unpaid'
          });
        }


        return {
          id: item.customer_id,
          status: item.status,
          bag_id: item.bag_id,
          customer: {
            name: item.customer?.full_name
          },
          reservations
        };
      });
    }
    return [];
  }, [dataGetReservation]);

  const handlePrintReceipt = useReactToPrint({
    content: () => printComponentRef.current,
  });

  return(
    <Modal
      title={ from === 'cancellation' ? 'Cancellation details'  : 'Reservation details'}
      width={600}
      hiddenLeftFooterButton={from === 'cancellation'}
      rightFooterButton={from === 'cancellation' ? {
        label: 'Done',
        onClick: () => history.goBack() 
      } : {
        label: 'Send receipt',
        onClick: () => history.push(Pathname.CUSTOMER_CHECK_INS + '/' + Pathname.SEND_EMAIL + '?type=customer-check-ins&id=' + id + '&customerId=' + customerId, { background: true, title: 'Send receipt' })
      }}
      leftFooterButton={{
        label: 'Void check-in',
        type: 'danger',
        state: 'text',
        onClick: () => history.push(Pathname.CUSTOMER_CHECK_INS + '/' + Pathname.VOID_CHECK_IN, { background: true, data, customerId })
      }}
    >
      <ReservationDetailTemplate
        data={data}
        onPrintReceipt={handlePrintReceipt} 
        from={from}
      />
      <div style={{ display: 'none' }}>
        <TeeSheetCustomersCheckInDetailTemplate
          ref={printComponentRef}
          type="group"
          mode="print"
          data={reservation}
          total={total}
          status={status}
          operators={operators}
          onChangeStatus={() => {}}
        />
      </div>
    </Modal>
  );
};

export default ReservationDetail;