import Text from 'antd/lib/typography/Text';
import React from 'react';
import PropTypes from 'prop-types';
import './Lozenges.less';

const Lozenges = (props) => {
  const { status } = props;
  let label;
  let className;
  if (status === 'paid') {
    label = 'Paid';
    className = 'paid';
  } else if (status === 'unpaid') {
    label = 'Unpaid';
    className = 'unpaid';
  } else if (status === 'active') {
    label = 'Active';
    className = 'paid';
  } else if (status === 'inactive') {
    label = 'Inactive';
    className = 'unpaid';
  } else if (status === 'void') {
    label = 'Voided';
    className = 'partially-paid';
  } else if (status === 'cancelled') {
    label = 'Cancelled';
    className = 'partially-paid';
  } else {
    label = status;
    className = 'partially-paid';
  } 
  return (
    <div id="lozenges" className={className}>
      <Text>{label}</Text>
    </div>
  );
};

export default Lozenges;

Lozenges.propTypes = {
  status: PropTypes.oneOf(['paid', 'partially_paid', 'unpaid', 'active', 'inactive'])
};

